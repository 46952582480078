import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PolicyClass } from 'src/app/core/enums';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IReportsService } from 'src/app/core/services/api/reports';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { RisksInsuredReportMapper } from '../mappers';
import { RisksInsuredReportViewModel } from '../models';

@Component({
  selector: 'howden-risks-insured-report',
  templateUrl: './risks-insured-report.component.html',
  styleUrls: ['./risks-insured-report.component.scss']
})
export class RisksInsuredReportComponent implements OnInit {
  model = new RisksInsuredReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private policySrv: IPolicyService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const policyFilter = {
      fleetId: fleetId,
      policyClasses: [PolicyClass.Colective.toString(), PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize
    } as IPolicySearchRequest;

    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.search(policyFilter)
    ]).subscribe(([
      rootPolicies
    ]) => {
      this.model.rootPolicies = rootPolicies.data.map(x => <IItemSelection>{ id: x.policyId, description: `${x.policyNumber}-${x.riskDescription}` });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = RisksInsuredReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.risksInsured(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_RIESGOS_ASEGURADOS_POR_POLIZA', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
