@if (this.segment && this.group) {
  <div>
    @if (!isDefaultSegment) {
      <div class="flex">
        <div class="f-basis--12">
          <h4>{{ group.description }}</h4>
        </div>
      </div>
    }
    <div class="flex">
      <div class="f-basis--12">
        <table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td class="text-center text-bold" i18n="@app.billing.group.segment.vehicleCount.label">Nº. Vehículos</td>
              <td class="text-center text-bold" i18n="@app.billing.group.segment.totalDayCount.label">Total Días</td>
              <td class="text-center text-bold" i18n="@app.billing.group.segment.yearlyVehicleCount.label">Vehículos / año</td>
              @for (receipt of receipts; track receipt; let i = $index) {
                <td class="text-center text-bold gray-background">
                  @if (i === 0) {
                    <span i18n="@app.billing.group.segment.firstReceipt.label">1er. Recibo</span>
                  }
                  @if (i !== 0) {
                    <span i18n="@app.billing.group.segment.nextReceipts.label">sucesivos</span>
                  }
                </td>
              }
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td class="text-center text-bold yellow-background">{{ group.vehicleCount }}</td>
              <td class="text-center text-bold yellow-background">{{ group.totalDayCount }}</td>
              <td class="text-center text-bold yellow-background">{{ group.yearlyVehicleCount | number : '1.2-2' }}</td>
              @for (receipt of receipts; track receipt) {
                <td class="text-center text-bold gray-background">
                  {{ receipt.percentage | percent : '1.2-2' }}
                </td>
              }
            </tr>
            @for (subtotal of segment.subtotals; track subtotal) {
              <tr>
                <td>{{ subtotal.description }}</td>
                <td class="text-right text-italic">
                  @if (subtotal.percentage) {
                    <span>{{ subtotal.percentage | percent : '1.2-2' }}</span>
                  }
                </td>
                <td class="text-right">{{ subtotal.subTotalAmount| number : '1.2-2' }}</td>
                <td></td>
                <td class="text-right">{{ subtotal.totalAmount | number : '1.2-2' }}</td>
                @for (receipt of subtotal.receipts; track receipt) {
                  <td class="text-right gray-background">
                    {{ receipt.totalAmount | number : '1.2-2' }}
                  </td>
                }
              </tr>
            }
            <tr>
              <td class="text-bold">{{ segment.totals.description }}</td>
              <td></td>
              <td class="text-right text-bold">{{ segment.totals.subTotalAmount | number : '1.2-2' }}</td>
              <td></td>
              <td class="text-right text-bold">{{ segment.totals.totalAmount | number : '1.2-2' }}</td>
              @for (receipt of segment.totals.receipts; track receipt) {
                <td class="text-right text-bold gray-background">
                  {{ receipt.totalAmount | number : '1.2-2' }}
                </td>
              }
            </tr>
            @for (commission of segment.commissions; track commission) {
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right text-bold">{{ commission.description }} {{ commission.percentage | percent : '1.2-2' }}</td>
                <td class="text-right">{{ commission.totalAmount | number : '1.2-2' }}</td>
                @for (receipt of commission.receipts; track receipt) {
                  <td class="text-right gray-background">
                    {{ receipt.totalAmount | number : '1.2-2' }}
                  </td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
}
