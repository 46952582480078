import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, map, pairwise } from 'rxjs';
import { IPolicyBilling } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicyEditInitialStateViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-initial-state',
  templateUrl: './policy-edit-initial-state.component.html',
  styleUrls: ['./policy-edit-initial-state.component.scss']
})
export class PolicyEditInitialStateComponent implements OnInit, OnDestroy {
  model = new PolicyEditInitialStateViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onFilter(): void {
    this.uiBlockerSrv.block();
    this.policySrv.getInitialState(this.policyId, this.model.year ?? new Date().getFullYear()).subscribe({
      next: (data: IPolicyBilling) => this.updateData(data),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  get hasData(): boolean {
    return this.model.history.length > 0 || this.model.groups.length > 0;
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.form.valueChanges.pipe(
      pairwise(),
      map(([oldState, newState]) => {
        const changes: any = {};
        for (const key in newState) {
          if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
            changes[key] = newState[key];
          }
        }
        return changes;
      }),
      filter((changes: any) => Object.keys(changes).length !== 0)
    ).subscribe(() => this.clearData());
  }

  private updateData(data: IPolicyBilling | null | undefined) {
    this.model.history = data?.history ?? [];
    this.model.groups = data?.groups ?? [];
  }

  private clearData() {
    this.model.history = [];
    this.model.groups = [];
  }
}
