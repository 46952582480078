import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HowdenAlertService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { ISessionService } from '../services/storage/session';

@Injectable({
  providedIn: 'root'
})
export class ActiveFleetGuard  {
  constructor(
    private sessionSrv: ISessionService,
    private alertsSrv: HowdenAlertService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.verifyActivation(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.verifyActivation(childRoute, state);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private verifyActivation(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.sessionSrv.hasActiveFleet) {
      const title = $localize`:@@app.guards.alerts.active.fleet.title:Información`;
      const message = $localize`:@@app.guards.alerts.active.fleet.message:Debe activar una flota para acceder a la opción seleccionada.`;

      this.alertsSrv.info(title, message);
    }

    return this.sessionSrv.hasActiveFleet;
  }
}
