<div class="howden-dialog-content">
  <div mat-dialog-title>
    <span class="title-text">{{model.alias}} - {{model.endorsementType}}</span>
    <br />
    <span class="subtitle-text">{{model.requestDate | date}} ({{model.endorsementStatus}})</span>
  </div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Cliente"
        i18n-labelText="@@app.endorsement.summary.client.label"
        value="{{model.client}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start text-wrap"
        labelText="Descripción"
        i18n-labelText="@@app.endorsement.summary.description.label"
        value="{{model.description | slice:0:50}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha recepción"
        i18n-labelText="@@app.endorsement.summary.receptionDate.label"
        value="{{model.receptionDate | date}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha efecto"
        i18n-labelText="@@app.endorsement.summary.effectDate.label"
        value="{{model.effectDate | date}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha vencimiento"
        i18n-labelText="@@app.endorsement.summary.expirationDate.label"
        value="{{model.expirationDate | date}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha anulación"
        i18n-labelText="@@app.endorsement.summary.cancellationDate.label"
        value="{{model.cancellationDate | date}}"
      ></howden-label-value>
    </div>

    @if (model.receiptId) {
      <div class="flex f-gap--10px">
        <h4 i18n="@@app.endorsement.summary.sections.receipt">Recibo</h4>
      </div>

      <div class="flex f-gap--10px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Número recibo"
          i18n-labelText="@@app.endorsement.summary.receiptNumber.label"
          value="{{model.receiptNumber}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Nº. Recibo aseguradora"
          i18n-labelText="@@app.endorsement.summary.insuranceCompanyReceiptNumber.label"
          value="{{model.insuranceCompanyReceiptNumber}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Concepto"
          i18n-labelText="@@app.endorsement.summary.receiptConcept.label"
          value="{{model.receiptConcept}}"
        ></howden-label-value>
      </div>

      <div class="flex f-gap--10px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Fecha efecto"
          i18n-labelText="@@app.endorsement.summary.receiptEffectDate.label"
          value="{{model.receiptEffectDate | date}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Fecha vencimiento"
          i18n-labelText="@@app.endorsement.summary.receiptExpirationDate.label"
          value="{{model.receiptExpirationDate | date}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Fecha anulación"
          i18n-labelText="@@app.endorsement.summary.receiptCancellationDate.label"
          value="{{model.receiptCancellationDate | date}}"
        ></howden-label-value>
      </div>
    }

    <div class="flex f-gap--10px">
      <h4 i18n="@@app.endorsement.summary.sections.audit">Auditoría</h4>
    </div>

    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--6 f-as-start"
        labelText="Creado por"
        i18n-labelText="@@app.endorsement.summary.createdBy.label"
        value="{{model.createdBy}} ({{model.createdAt}})"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--6 f-as-start"
        labelText="Modificado por"
        i18n-labelText="@@app.endorsement.summary.modifiedBy.label"
        value="{{model.modifiedBy}} ({{model.modifiedAt}})"
      ></howden-label-value>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button color="success" (click)="onClose()">
      <span i18n="@@app.core.actions.close">Cerrar</span>
    </button>
  </mat-dialog-actions>
</div>
