<div class="flex margin-top-10">
  <mat-card appearance="outlined" i18n="@@app.policies.edit.import.header">
    <mat-card-content>
      Utilice los filtros para seleccionar las pólizas a importar en la póliza madre actual.
    </mat-card-content>
  </mat-card>
</div>

<div class="flex margin-top-10">
  <howden-table
    #policyImportTable
    class="small-font"
    keyField="policyId"
    paginable="local"
    sortable="local"
    filterable="global-local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.data"
    [multiselect]="true"
    [hideToggleSelectionCheckbox]="hideToggleSelectionCheckbox"
    (genericFilterChanged)="onFiltersChanged($event)"
    (selectedRowsChange)="onSelectionChanged($event)"
  >
  </howden-table>
</div>

<div class="flex f-jc--center f-gap--10px margin-top-10">
  <button type="button" mat-raised-button color="success" [disabled]="disableImport" (click)="onImport()">
    <span i18n="@@app.core.actions.import">Importar</span>
  </button>
</div>
