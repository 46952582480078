import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IClaimPaymentDate } from 'src/app/core/models/api/lta';
import { Limits } from 'src/app/shared/utils';

export class OperatingCompaniesReportViewModel {
  private _form: FormGroup;
  private _claimPaymentDates: Array<IClaimPaymentDate> = [];

  readonly C_CLAIM_PAYMENT_DATE_ID = 'paymentDateId';
  readonly C_YEAR = 'year';
  readonly C_INCLUDE_POLICIES_WITH_INDIVIDUAL_BONUSES = 'includePoliciesWithIndividualBonuses';

  constructor() {
    this._form = new FormGroup({
      [this.C_CLAIM_PAYMENT_DATE_ID]: new FormControl(null, [Validators.required]),
      [this.C_YEAR]: new FormControl(null, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_INCLUDE_POLICIES_WITH_INDIVIDUAL_BONUSES]: new FormControl(false, [Validators.required])
    });
  }

  get claimPaymentDates(): Array<IClaimPaymentDate> {
    return this._claimPaymentDates;
  }

  set claimPaymentDates(value: Array<IClaimPaymentDate>) {
    this._claimPaymentDates = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get claimPaymentId(): string | null {
    return this.getControl(this.C_CLAIM_PAYMENT_DATE_ID).value;
  }

  set claimPaymentId(value: string | null) {
    this.getControl(this.C_CLAIM_PAYMENT_DATE_ID).setValue(value);
  }

  get year(): number | null {
    return this.getControl(this.C_YEAR).value;
  }

  set year(value: number | null) {
    this.getControl(this.C_YEAR).setValue(value);
  }

  get includePoliciesWithIndividualBonuses(): boolean {
    return this.getControl(this.C_INCLUDE_POLICIES_WITH_INDIVIDUAL_BONUSES).value;
  }

  set includePoliciesWithIndividualBonuses(value: boolean) {
    this.getControl(this.C_INCLUDE_POLICIES_WITH_INDIVIDUAL_BONUSES).setValue(value);
  }
}
