import { IPolicyRisk } from 'src/app/core/models/api/policies';
import { PolicyEditRiskViewModel } from '../models';

export abstract class PolicyEditRiskMapper {
  static mapForEdit(source: IPolicyRisk, target: PolicyEditRiskViewModel) {
    target.bookingModeId = source.bookingModeId;
    target.activationDate = source.activationDate;
    target.deactivationDate = source.deactivationDate;
    target.deactivationNotificationDate = source.deactivationNotificationDate;
    target.insuredClientId = source.insuredClientId;
    target.insuredInclusionDate = source.insuredInclusionDate;
    target.insuredExclusionDate = source.insuredExclusionDate;
    target.creditorName = source.creditorName;
    target.creditorFiscalCode = source.creditorFiscalCode;
    target.creditorEmail = source.creditorEmail;
    target.creditorComments = source.creditorComments;
  }

  static mapForUpdate(source: PolicyEditRiskViewModel): IPolicyRisk {
    return {
      bookingModeId: source.bookingModeId,
      activationDate: source.activationDate,
      deactivationDate: source.deactivationDate,
      deactivationNotificationDate: source.deactivationNotificationDate,
      insuredClientId: source.insuredClientId,
      insuredInclusionDate: source.insuredInclusionDate,
      insuredExclusionDate: source.insuredExclusionDate,
      creditorName: source.creditorName,
      creditorFiscalCode: source.creditorFiscalCode,
      creditorEmail: source.creditorEmail,
      creditorComments: source.creditorComments
    } as IPolicyRisk;
  }
}
