import { IPolicyBillingGroup } from 'src/app/core/models/api/policies';
import { PolicyEditBillingGroupViewModel } from '../models';

export abstract class PolicyEditBillinGroupMapper {
  static mapForEdit(source: IPolicyBillingGroup, target: PolicyEditBillingGroupViewModel) {
    target.billingGroupId = source.billingGroupId;
    target.name = source.name;
    target.priority = source.priority;
  }

  static mapForUpdate(source: PolicyEditBillingGroupViewModel): IPolicyBillingGroup {
    return {
      billingGroupId: source.billingGroupId,
      name: source.name,
      priority: source.priority
    } as IPolicyBillingGroup;
  }
}
