import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUploadComponent, IPageOf } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { CollectionManagerStatus, MediationCodeStatus, PolicyClass, PolicyStatus, PolicyTransferMode, ProductStatus } from 'src/app/core/enums';
import { IBookingModeSearchRequest, IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IPolicyReplacerFileItem } from 'src/app/core/models/api/fleets';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IProduct } from 'src/app/core/models/ida/products';
import { IMediationCode } from 'src/app/core/models/ida/providers';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IProductService } from 'src/app/core/services/api/products';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FormGroupUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyReplacerViewModel } from '../models';

@Component({
  selector: 'howden-policy-replacer',
  templateUrl: './policy-replacer.component.html',
  styleUrls: ['./policy-replacer.component.scss']
})
export class PolicyReplacerComponent implements OnInit, OnDestroy {
  model = new PolicyReplacerViewModel();

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('file') fileRef?: FileUploadComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private productSrv: IProductService,
    private masterSrv: IMasterService,
    private bookingModeSrv: IBookingModeService,
    private insuranceCompanySrv: IInsuranceCompanyService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    const policyFilter = {
      pageNumber: 0,
      fleetId: fleetId,
      policyClasses: [PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize,
      statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString()]
    } as IPolicySearchRequest;

    forkJoin([
      this.policySrv.search(policyFilter),
      this.fleetSrv.getInsuranceCompanies(fleetId),
      this.fleetSrv.getIndustries(fleetId),
      this.masterSrv.getPaymentTypes(),
      this.masterSrv.getPolicyDurations(),
      this.masterSrv.getCollectionManagers(),
      this.policySrv.getTransferModes(),
      this.policySrv.getHolderReferences(),
      this.policySrv.getInsuredReferences()
    ]).subscribe(([
      rootPolicies,
      insuranceCompanies,
      industries,
      paymentTypes,
      policyDurations,
      collectionManagers,
      transferModes,
      holderReferences,
      insuredReferences
    ]) => {
      this.model.rootPolicies = rootPolicies.data;
      this.model.insuranceCompanies = insuranceCompanies;
      this.model.industries = industries;
      this.model.collectionManagers = collectionManagers.filter(x => x.status === CollectionManagerStatus.Active);
      this.model.paymentTypes = paymentTypes;
      this.model.policyDurations = policyDurations;
      this.model.transferModes = transferModes;
      this.model.holderReferences = holderReferences;
      this.model.insuredReferences = insuredReferences;

      this.model.transferModeId = transferModes[0].key;

      this.onChanges();
      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.fleetSrv.policyReplacer(fleetId, request).pipe(
      tap((response) => {
        this.uiBlockerSrv.unblock();

        if (response && response.updates && response.updates.length > 0) {
          this.model.updaterResults = response.updates;
        } else {
          const title = $localize`:@@app.policies.replacer.confirm.dialog.title:Traspaso/Reemplazo de pólizas`;
          const subtitle = $localize`:@@app.policies.replacer.confirm.dialog.subtitle:Finalizado correctamente, recuerde que no todos los cambios son efectivos de inmediato.`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reset());
        }
      })
    ).subscribe();
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef?.file
    });
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);
    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries?.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getProductName(productId: string): string {
    const product = this.model.products?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  getMediationCodeDescription(mediationCodeId: string): string {
    const mediationCode = this.model.mediationCodes?.find(x => x.mediationCodeId === mediationCodeId);
    return mediationCode ? `${mediationCode.code}-${mediationCode.description}` : '';
  }

  getBookingModeDescription(bookingModeId: string): string {
    return this.model.bookingModes?.find(x => x.bookingModeId === bookingModeId)?.description ?? '';
  }

  getCollectionManagerName(collectionManagerId: string): string {
    const collectionManager = this.model.collectionManagers?.find(x => x.collectionManagerId === collectionManagerId);
    return collectionManager ? `${collectionManager.alias}-${collectionManager.name}` : '';
  }

  get hasUpdaterResults(): boolean {
    return this.model.updaterResults && this.model.updaterResults.length > 0;
  }

  onGoBack(): void {
    this.reset();
  }

  private reset(): void {
    this.model.form.reset();
    this.fileRef?.clearFiles();
    this.model.cancelWithoutEffect = false;
    this.model.updaterResults = new Array<IPolicyReplacerFileItem>();
    this.model.products = new Array<IProduct>();
    this.model.mediationCodes = new Array<IMediationCode>();
    this.model.bookingModes = new Array<IBookingModeSearchResult>();
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_TRANSFER_MODE_ID).valueChanges.subscribe(() => {
      this.onTransferModeChanged();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_TARGET_ROOT_POLICY_ID).valueChanges.subscribe(() => {
      this.onLoadMediationCodes();
      this.onLoadBookingModes();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
      this.onLoadMediationCodes();
      this.onLoadBookingModes();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INDUSTRY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
      this.onLoadBookingModes();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_PRODUCT_ID).valueChanges.subscribe(() => {
      this.onLoadBookingModes();
    });
  }

  private onTransferModeChanged(): void {
    const currentMode = this.model.transferModeId;
    const insuranceCompanyCtl = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID);
    const industryCtl = this.model.getControl(this.model.C_INDUSTRY_ID);
    const productCtl = this.model.getControl(this.model.C_PRODUCT_ID);
    const bookingModeCtl = this.model.getControl(this.model.C_BOOKING_MODE_ID);

    if (currentMode === PolicyTransferMode.TemporaryReplacement) {
      insuranceCompanyCtl.reset();
      insuranceCompanyCtl.disable();

      industryCtl.reset();
      industryCtl.disable();

      productCtl.reset();
      productCtl.disable();

      bookingModeCtl.reset();
      bookingModeCtl.disable();
    } else {
      insuranceCompanyCtl.enable();
      industryCtl.enable();
      productCtl.enable();
      bookingModeCtl.enable();
    }
  }

  private onLoadProducts(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId ?? '';
    const industryId: string = this.model.industryId ?? '';

    this.model.products = new Array<IProduct>();
    this.model.productId = null;

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        this.model.products = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private onLoadMediationCodes(): void {
    const rootPolicy = this.model.rootPolicies.find(x => x.policyId === this.model.targetRootPolicyId);
    const insuranceCompanyId: string = this.model.insuranceCompanyId && this.model.insuranceCompanyId !== ''
      ? this.model.insuranceCompanyId
      : rootPolicy?.insuranceCompanyId ?? '';

    this.model.mediationCodes = new Array<IMediationCode>();
    this.model.mediationCodeId = null;

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);

    if (insuranceCompany) {
      this.insuranceCompanySrv.getMediationCodes(insuranceCompanyId).subscribe((data: Array<IMediationCode>) => {
        this.model.mediationCodes = data.filter(x => x.status === MediationCodeStatus.Active);
        this.model.mediationCodeId = rootPolicy?.mediationCodeId ?? null;
        if (insuranceCompanyId !== rootPolicy?.insuranceCompanyId) {
          const defaultMediationCode = this.model.mediationCodes?.find(x => x.isDefault === true);
          if (defaultMediationCode) {
            this.model.mediationCodeId = defaultMediationCode.mediationCodeId;
          }
        }
      });
    }
  }

  private onLoadBookingModes(): void {
    const rootPolicy = this.model.rootPolicies.find(x => x.policyId === this.model.targetRootPolicyId);
    const insuranceCompanyId: string = this.model.insuranceCompanyId && this.model.insuranceCompanyId !== ''
      ? this.model.insuranceCompanyId
      : rootPolicy?.insuranceCompanyId ?? '';
    const industryId: string = this.model.industryId && this.model.industryId !== ''
      ? this.model.industryId
      : rootPolicy?.industryId ?? '';
    const productId: string = this.model.productId && this.model.productId !== ''
      ? this.model.productId
      : rootPolicy?.productId ?? '';

    this.model.bookingModes = new Array<IBookingModeSearchResult>();
    this.model.bookingModeId = null;

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);
    const product = this.model.products.find(x => x.productId === productId);

    if (insuranceCompany && industry && product) {
      const fleetId = this.sessionSrv.activeFleet;
      const filter = {
        pageNumber: 0,
        pageSize: Limits.maxPageSize,
        fleetId: fleetId,
        insuranceCompanyId: insuranceCompanyId,
        industryId: industryId,
        productId: productId
      } as IBookingModeSearchRequest;

      this.bookingModeSrv.search(filter).subscribe((searchResult: IPageOf<IBookingModeSearchResult>) => {
        this.model.bookingModes = searchResult.data;
      });
    }
  }
}
