import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { BillingGroupRuleType } from 'src/app/core/enums';
import { IFleetService } from 'src/app/core/services/api/fleets/fleet.contract';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicyEditBillingGroupDialogComponent } from '../../../dialogs/policy-edit-billing-group-dialog/pages/policy-edit-billing-group-dialog.component';
import { PolicyEditBillingGroupRuleDialogComponent } from '../../../dialogs/policy-edit-billing-group-rule-dialog/pages/policy-edit-billing-group-rule-dialog.component';
import { PolicyEditBillingGroupRuleViewModel, PolicyEditBillingGroupsViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-billing-groups',
  templateUrl: './policy-edit-billing-groups.component.html',
  styleUrls: ['./policy-edit-billing-groups.component.scss']
})
export class PolicyEditBillingGroupsComponent implements OnInit, OnChanges, OnDestroy {
  model = new PolicyEditBillingGroupsViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private unitSrv: IUnitService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.refreshBillingGroups();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onRuleAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEditBillingGroupRule(event.row.ruleId);
    } else {
      this.onDeleteBillingGroupRule(event.row.ruleId);
    }
  }

  onNewBillingGroup(): void {
    this.openBillingGroupDialog();
  }

  onEditBillingGroup(): void {
    this.openBillingGroupDialog(this.model.billingGroupId as string);
  }

  onDeleteBillingGroup(): void {
    const title: string = $localize`:@@app.policy.edit.billing.groups.delete.title:Borrado de grupos de facturación`;
    const question: string = $localize`:@@app.policy.edit.billing.groups.delete.subtitle:Se borrará el grupo de facturación, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.policy.edit.billing.groups.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();

          this.policySrv.deleteBillingGroup(this.policyId, this.model.billingGroupId as string).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.model.billingGroupId = null;
                  this.model.billingGroups = [];
                  this.refreshBillingGroups();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onNewBillingGroupRule(): void {
    this.openBillingGroupRuleDialog();
  }

  getBillingGroupDescription(billingGroupId: string): string {
    return this.model.billingGroups?.find(x => x.billingGroupId === billingGroupId)?.name ?? '';
  }

  get hasValidSelection(): boolean {
    const billingGroupId = this.model.billingGroupId;

    if (billingGroupId) {
      const billingGroup = this.model.billingGroups.find(x => x.billingGroupId === billingGroupId);

      if (billingGroup) {
        return true;
      }
    }

    return false;
  }

  private refreshBillingGroups(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.getBillingGroups(this.policyId),
      this.policySrv.getBookingModes(this.policyId),
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.getVehicleTypes(fleetId),
      this.fleetSrv.getVehicleUsages(fleetId),
      this.unitSrv.getMovementAreas(),
      this.unitSrv.getCirculationAreas(),
      this.unitSrv.getGoodsTypes()
    ]).subscribe(([
      billingGroups,
      bookingModes,
      fleetCompanies,
      vehicleTypes,
      vehicleUsages,
      movementAreas,
      circulationAreas,
      goodsTypes
    ]) => {
      this.model.billingGroups = billingGroups;
      this.model.bookingModes = bookingModes;
      this.model.fleetCompanies = fleetCompanies;
      this.model.vehicleTypes = vehicleTypes;
      this.model.vehicleUsages = vehicleUsages;
      this.model.movementAreas = movementAreas;
      this.model.circulationAreas = circulationAreas;
      this.model.goodsTypes = goodsTypes;

      if (billingGroups && billingGroups.length === 1) {
        this.model.billingGroupId = billingGroups[0].billingGroupId;
      }

      this.model.getControl(this.model.C_BILLING_GROUP_ID).reset(this.model.billingGroupId);

      this.uiBlockerSrv.unblock();
    });
  }

  private onEditBillingGroupRule(ruleId: string): void {
    this.openBillingGroupRuleDialog(ruleId);
  }

  private openBillingGroupDialog(billingGroupId?: string): void {
    const inputData = {
      policyId: this.policyId,
      billingGroupId: billingGroupId
    };
    const dialogRef = this.dialog.open(PolicyEditBillingGroupDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refreshBillingGroups());
  }

  private openBillingGroupRuleDialog(ruleId?: string): void {
    const inputData = {
      policyId: this.policyId,
      billingGroupId: this.model.billingGroupId,
      ruleId: ruleId
    };
    const dialogRef = this.dialog.open(PolicyEditBillingGroupRuleDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.onLoadRules());
  }

  private onDeleteBillingGroupRule(ruleId: string): void {
    const title: string = $localize`:@@app.policy.edit.billing.groups.delete.rule.title:Grupos de facturación`;
    const question: string = $localize`:@@app.policy.edit.billing.groups.delete.rule.subtitle:Se borrará la regla, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.policy.edit.billing.groups.delete.rule.confirm.subtitle:Operacion finalizada correctamente`;

          this.uiBlockerSrv.block();
          this.policySrv.deleteBillingGroupRule(this.policyId, this.model.billingGroupId as string, ruleId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.onLoadRules();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_BILLING_GROUP_ID).valueChanges.subscribe(() => {
      const billingGroupId = this.model.billingGroupId;

      this.model.rules = [];

      if (billingGroupId) {
        const billingGroup = this.model.billingGroups.find(x => x.billingGroupId === billingGroupId);

        if (billingGroup) {
          this.onLoadRules();
        }
      }
    });
  }

  private onLoadRules(): void {
    const billingGroupId = this.model.billingGroupId;

    this.model.rules = [];

    if (typeof billingGroupId !== 'undefined' && billingGroupId !== null) {
      this.policySrv.getBillingGroupRules(this.policyId, this.model.billingGroupId as string).subscribe((rules) => {
        this.model.rules = rules.map(rule => {
          const ruleId = rule.ruleId;
          const typeId = rule.type;

          let value = '';

          switch (typeId) {
            case BillingGroupRuleType.BookingMode:
              value = this.model.bookingModes.find(x => x.bookingModeId === rule.valueId)?.description ?? '';
              break;
            case BillingGroupRuleType.PolicyHolder:
            case BillingGroupRuleType.PolicyInsured:
              value = this.model.fleetCompanies.find(x => x.companyId === rule.valueId)?.clientName ?? '';
              break;
            case BillingGroupRuleType.MovementArea:
              value = this.model.movementAreas.find(x => x.movementAreaId === rule.valueId)?.description ?? '';
              break;
            case BillingGroupRuleType.UnitBonusGroup:
              value = rule.directValue;
              break;
            case BillingGroupRuleType.VehicleType:
              value = this.model.vehicleTypes.find(x => x.erpId === rule.valueId)?.description ?? '';
              break;
            case BillingGroupRuleType.VehicleUsage:
              value = this.model.vehicleUsages.find(x => x.erpId === rule.valueId)?.description ?? '';
              break;
            case BillingGroupRuleType.CirculationArea:
              value = this.model.circulationAreas.find(x => x.circulationAreaId === rule.valueId)?.description ?? '';
              break;
            case BillingGroupRuleType.GoodsType:
              value = this.model.goodsTypes.find(x => x.goodTypeId === rule.valueId)?.description ?? '';
              break;
          }

          return new PolicyEditBillingGroupRuleViewModel(ruleId, typeId, value);
        });
      });
    }
  }
}
