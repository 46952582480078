import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IPolicyBillingGroup } from 'src/app/core/models/api/policies';
import { ICirculationArea, IGoodsType, IMovementArea } from 'src/app/core/models/api/units';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { PolicyEditBillingGroupRuleViewModel } from './policy-edit-billing-group.rule.viewmodel';

export class PolicyEditBillingGroupsViewModel {
  private _form: FormGroup;
  private _billingGoups: Array<IPolicyBillingGroup> = [];
  private _bookingModes: Array<IBookingModeSearchResult> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _vehicleTypes: Array<IMasterValue> = [];
  private _vehicleUsages: Array<IMasterValue> = [];
  private _movementAreas: Array<IMovementArea> = [];
  private _circulationAreas: Array<ICirculationArea> = [];
  private _goodsTypes: Array<IGoodsType> = [];

  private _filteredBillingGroups: Observable<Array<IPolicyBillingGroup>>;

  readonly C_BILLING_GROUP_ID = 'billingGroupId';

  constructor() {
    this._form = new FormGroup({
      [this.C_BILLING_GROUP_ID]: new FormControl(null, [])
    });

    this._filteredBillingGroups = this.getControl(this.C_BILLING_GROUP_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterBillingGroups(value || ''))
    );
  }

  rules: PolicyEditBillingGroupRuleViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'type',
      fieldHeader: $localize`:@@app.policies.edit.billing.group.rules.type.column:Tipo`,
      canSort: true
    },
    {
      fieldName: 'value',
      fieldHeader: $localize`:@@app.policies.edit.billing.group.rules.value.column:Valor`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.edit.billing.group.rules.actions.delete.tooltip:Borrar`
  }, {
    name: 'edit',
    icon: 'edit',
    tooltip: $localize`:@@app.policies.edit.billing.group.rules.actions.edit.tooltip:Editar`
  }];

  get billingGroups(): Array<IPolicyBillingGroup> {
    return this._billingGoups;
  }

  set billingGroups(value: Array<IPolicyBillingGroup>) {
    this._billingGoups = value;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingModeSearchResult>) {
    this._bookingModes = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(value: Array<IFleetCompany>) {
    this._fleetCompanies = value;
  }

  get vehicleTypes(): Array<IMasterValue> {
    return this._vehicleTypes;
  }

  set vehicleTypes(value: Array<IMasterValue>) {
    this._vehicleTypes = value;
  }

  get vehicleUsages(): Array<IMasterValue> {
    return this._vehicleUsages;
  }

  set vehicleUsages(value: Array<IMasterValue>) {
    this._vehicleUsages = value;
  }

  get movementAreas(): Array<IMovementArea> {
    return this._movementAreas;
  }

  set movementAreas(value: Array<IMovementArea>) {
    this._movementAreas = value;
  }

  get circulationAreas(): Array<ICirculationArea> {
    return this._circulationAreas;
  }

  set circulationAreas(value: Array<ICirculationArea>) {
    this._circulationAreas = value;
  }

  get goodsTypes(): Array<IGoodsType> {
    return this._goodsTypes;
  }

  set goodsTypes(value: Array<IGoodsType>) {
    this._goodsTypes = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get billingGroupId(): string | undefined | null {
    return this.getControl(this.C_BILLING_GROUP_ID).value;
  }

  set billingGroupId(value: string | undefined | null) {
    this.getControl(this.C_BILLING_GROUP_ID).setValue(value);
  }

  get filteredBillingGroups(): Observable<Array<IPolicyBillingGroup>> {
    return this._filteredBillingGroups;
  }

  private filterBillingGroups(value: string): Array<IPolicyBillingGroup> {
    return value !== ''
      ? this._billingGoups.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this._billingGoups;
  }
}
