import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IInsuranceDayViewModel } from './insurance-day.viewmodel';

export class InsuranceDaysViewModel {
  data: Array<IInsuranceDayViewModel> = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.insurance.days.insuranceCompany.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'dayCount',
      fieldHeader: $localize`:@@app.insurance.days.riskDescription.column:Número de días de seguro`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    tooltip: $localize`:@@app.insurance.days.actions.edit.tooltip:Editar`
  }];
}
