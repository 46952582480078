<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex margin-bottom-25">
      <button type="button" class="f-basis--4" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.lta.baseFrequency.label">Frecuencia base</mat-label>
        <mat-select [formControlName]="model.C_BASE_FREQUENCY_ID" [howdenControlError]="baseFrequencyIdError">
          @for (item of model.baseFrequencies | howdenArraySort: 'description'; track item.historyId) {
            <mat-option [value]="item.historyId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #baseFrequencyIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.lta.claimPaymentDate.label">Carga siniestral</mat-label>
        <mat-select [formControlName]="model.C_CLAIM_PAYMENT_DATE_ID" [howdenControlError]="claimPaymentDateIdError">
          @for (item of model.claimPaymentDates | howdenArraySort: 'description'; track item.paymentDateId) {
            <mat-option [value]="item.paymentDateId">
              {{ item.date | date }}
            </mat-option>
          }
        </mat-select>
        <mat-error #claimPaymentDateIdError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.lta.startDate.label">Desde</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          [formControlName]="model.C_START_DATE"
          [howdenControlError]="startDateError"
          [min]="minDate"
        />
        <mat-error #startDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.lta.endDate.label">Hasta</mat-label>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          [formControlName]="model.C_END_DATE"
          [howdenControlError]="endDateError"
          [min]="minDate"
        />
        <mat-error #endDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--4"
        [formControlName]="model.C_IPC1"
        [allowNegative]="false"
        [precision]="2"
        i18n-label="@@app.reports.lta.ipc1.label"
        label="IPC 1"
      ></howden-decimal>

      <howden-decimal
        class="f-basis--4"
        [formControlName]="model.C_IPC2"
        [allowNegative]="false"
        [precision]="2"
        i18n-label="@@app.reports.lta.ipc2.label"
        label="IPC 2"
      ></howden-decimal>
    </div>

    <div class="flex f-gap--10px">
      <mat-slide-toggle
        class="f-basis--3 f-as--center"
        [formControlName]="model.C_GROUP_DETAILS"
        i18n="@@app.reports.lta.groupDetails.label">Agrupar detalles</mat-slide-toggle>
    </div>
  </form>
</div>
