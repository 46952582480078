import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class PolicyEditBillingGroupViewModel {
  private _form: FormGroup;

  readonly C_BILLING_GROUP_ID = 'bonusId';
  readonly C_NAME = 'name';
  readonly C_PRIORITY = 'priority';

  constructor() {
    this._form = new FormGroup({
      [this.C_BILLING_GROUP_ID]: new FormControl(null, []),
      [this.C_NAME]: new FormControl('', [Validators.required]),
      [this.C_PRIORITY]: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(32767)])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get billingGroupId(): string | undefined {
    return this.getControl(this.C_BILLING_GROUP_ID).value;
  }

  set billingGroupId(value: string | undefined) {
    this.getControl(this.C_BILLING_GROUP_ID).setValue(value);
  }

  get name(): string {
    return this.getControl(this.C_NAME).value;
  }

  set name(value: string) {
    this.getControl(this.C_NAME).setValue(value);
  }

  get priority(): number {
    return this.getControl(this.C_PRIORITY).value;
  }

  set priority(value: number) {
    this.getControl(this.C_PRIORITY).setValue(value);
  }
}
