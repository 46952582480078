import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidators } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IBookingMode } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany, IFleetSearchResult } from 'src/app/core/models/api/fleets';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class UnitFleetTransferViewModel {
  private _form: FormGroup;
  private _isBookingModeRequired = false;

  private _fleets: Array<IFleetSearchResult> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _bookingModes: Array<IBookingMode> = [];

  private _filteredFleets: Observable<Array<IFleetSearchResult>>;
  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;
  private _filteredHolders: Observable<Array<IFleetCompany>>;
  private _filteredInsureds: Observable<Array<IFleetCompany>>;

  readonly C_TARGET_FLEET_ID = 'targetFleetId';
  readonly C_TARGET_ROOT_POLICY_ID = 'targetRootPolicyId';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_INSURED_ID = 'insuredId';
  readonly C_EFFECT_DATE = 'effectDate';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';
  readonly C_COVERAGE_ORDER_TO = 'coverageOrderEmailTo';

  constructor() {
    this._form = new FormGroup({
      [this.C_TARGET_FLEET_ID]: new FormControl('', [Validators.required]),
      [this.C_TARGET_ROOT_POLICY_ID]: new FormControl('', [Validators.required]),
      [this.C_HOLDER_ID]: new FormControl('', [Validators.required, forbiddenNamesValidator(() => this.fleetCompanies, 'companyId')]),
      [this.C_INSURED_ID]: new FormControl('', [Validators.required, forbiddenNamesValidator(() => this.fleetCompanies, 'companyId')]),
      [this.C_EFFECT_DATE]: new FormControl('', [Validators.required]),
      [this.C_EXPIRATION_DATE]: new FormControl('', [Validators.required]),
      [this.C_BOOKING_MODE_ID]: new FormControl('', []),
      [this.C_COVERAGE_ORDER_TO]: new FormControl('', [EmailValidators.emailListValidator])
    });

    this._filteredFleets = this.getControl(this.C_TARGET_FLEET_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterFleets(value || ''))
    );

    this._filteredRootPolicies = this.getControl(this.C_TARGET_ROOT_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );

    this._filteredHolders = this.getControl(this.C_HOLDER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCompanies(value || ''))
    );

    this._filteredInsureds = this.getControl(this.C_INSURED_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCompanies(value || ''))
    );
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get isBookingModeRequired(): boolean {
    return this._isBookingModeRequired;
  }

  set isBookingModeRequired(value: boolean) {
    this._isBookingModeRequired = value;
  }

  get fleets(): Array<IFleetSearchResult> {
    return this._fleets;
  }

  set fleets(fleets: Array<IFleetSearchResult>) {
    this._fleets = fleets;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(policies: Array<IPolicySearchResult>) {
    this._rootPolicies = policies;
  }

  get bookingModes(): Array<IBookingMode> {
    return this._bookingModes;
  }

  set bookingModes(bookingModes: Array<IBookingMode>) {
    this._bookingModes = bookingModes;
  }

  get targetFleetId(): string {
    return this._form.get(this.C_TARGET_FLEET_ID)?.value;
  }

  set targetFleetId(value: string) {
    this._form.get(this.C_TARGET_FLEET_ID)?.setValue(value);
  }

  get targetRootPolicyId(): string | null {
    return this._form.get(this.C_TARGET_ROOT_POLICY_ID)?.value;
  }

  set targetRootPolicyId(value: string | null) {
    this._form.get(this.C_TARGET_ROOT_POLICY_ID)?.setValue(value);
  }

  get holderId(): string | null {
    return this._form.get(this.C_HOLDER_ID)?.value;
  }

  set holderId(value: string | null) {
    this._form.get(this.C_HOLDER_ID)?.setValue(value);
  }

  get insuredId(): string | null {
    return this._form.get(this.C_HOLDER_ID)?.value;
  }

  set insuredId(value: string | null) {
    this._form.get(this.C_INSURED_ID)?.setValue(value);
  }

  get effectDate(): Date {
    return this._form.get(this.C_EFFECT_DATE)?.value;
  }

  set effectDate(value: Date) {
    this._form.get(this.C_EFFECT_DATE)?.setValue(value);
  }

  get expirationDate(): Date {
    return this._form.get(this.C_EXPIRATION_DATE)?.value;
  }

  set expirationDate(value: Date) {
    this._form.get(this.C_EXPIRATION_DATE)?.setValue(value);
  }

  get bookingModeId(): string | null {
    return this._form.get(this.C_BOOKING_MODE_ID)?.value;
  }

  set bookingModeId(value: string | null) {
    this._form.get(this.C_BOOKING_MODE_ID)?.setValue(value);
  }

  get coverageOrderEmailTo(): string | undefined {
    return this._form.get(this.C_COVERAGE_ORDER_TO)?.value;
  }

  set coverageOrderEmailTo(value: string | undefined) {
    this._form.get(this.C_COVERAGE_ORDER_TO)?.setValue(value);
  }

  get filteredFleets(): Observable<Array<IFleetSearchResult>> {
    return this._filteredFleets;
  }

  get filteredHolders(): Observable<Array<IFleetCompany>> {
    return this._filteredHolders;
  }

  get filteredInsureds(): Observable<Array<IFleetCompany>> {
    return this._filteredInsureds;
  }

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  private filterFleets(value: string): Array<IFleetSearchResult> {
    return value !== ''
      ? this._fleets.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this._fleets;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }

  private filterCompanies(value: string): Array<IFleetCompany> {
    return value !== ''
      ? this._fleetCompanies.filter(c => c.clientName?.toLowerCase().includes(value.toLowerCase()))
      : this._fleetCompanies;
  }
}
