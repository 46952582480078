import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { NgxEchartsModule } from 'ngx-echarts';
import { DashboardService, IDashboardService } from 'src/app/core/services/api/dashboards';
import { BreakPointObserverSrv, ConfirmationDialogSrv, IBreakPointObserverSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { BonusBoardComponent } from './bonus-board/pages/bonus-board.component';
import { ClaimEvolutionChartComponent } from './claims-dashboard/components/claim-evolution-chart/claim-evolution-chart.component';
import { ClaimsDashboardComponent } from './claims-dashboard/pages/claims-dashboard.component';
import { DashboardBarChartComponent } from './components/dashboard-bar-chart/dashboard-bar-chart.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { DashboardMiniCardComponent } from './components/dashboard-mini-card/dashboard-mini-card.component';
import { DashboardPieChartComponent } from './components/dashboard-pie-chart/dashboard-pie-chart.component';
import { PivotTableComponentComponent } from './components/pivot-table-component/pivot-table-component.component';
import { DashboardsRoutingModule } from './dashboards-routing.module';
import { FleetDashboardComponent } from './fleet-dashboard/pages/fleet-dashboard.component';
import { GeneralDashboardComponent } from './general-dashboard/pages/general-dashboard.component';
import { InitialStateDashboardComponent } from './initial-state-dashboard/pages/initial-state-dashboard.component';
import { ReceiptsExpirationsChartComponent } from './receipts-dashboard/components/receipts-expirations-chart/receipts-expirations-chart.component';
import { ReceiptsDashboardComponent } from './receipts-dashboard/pages/receipts-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatGridListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule,
    NgxEchartsModule,
    HowdenAutocompleteModule,
    HowdenFileUploadModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    DashboardsRoutingModule
  ],
  declarations: [
    BonusBoardComponent,
    FleetDashboardComponent,
    DashboardCardComponent,
    DashboardMiniCardComponent,
    DashboardBarChartComponent,
    DashboardPieChartComponent,
    ReceiptsDashboardComponent,
    ReceiptsExpirationsChartComponent,
    ClaimsDashboardComponent,
    ClaimEvolutionChartComponent,
    PivotTableComponentComponent,
    GeneralDashboardComponent,
    InitialStateDashboardComponent,
    DashboardHeaderComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: IBreakPointObserverSrv, useClass: BreakPointObserverSrv },
    { provide: IDashboardService, useClass: DashboardService }
  ]
})
export class DashboardsModule {
}
