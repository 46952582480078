import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IClient } from 'src/app/core/models/ida/clients';

export class FleetEditClientsViewModel {
  private _form: FormGroup;
  private _notAssignedCompanies: Array<IClient> = [];

  readonly C_CLIENT_ID = 'clientId';

  constructor() {
    this._form = new FormGroup({
      [this.C_CLIENT_ID]: new FormControl('', [Validators.required])
    });
  }

  get notAssignedCompanies(): Array<IClient> {
    return this._notAssignedCompanies;
  }

  set notAssignedCompanies(value: Array<IClient>) {
    this._notAssignedCompanies = value;
  }

  get hasNotAssignedCompanies() {
    return typeof this._notAssignedCompanies !== 'undefined'
      && this._notAssignedCompanies !== null
      && this._notAssignedCompanies.length > 0;
  }

  get form(): FormGroup {
    return this._form;
  }

  get clientId(): string | null {
    return this._form.get(this.C_CLIENT_ID)?.value;
  }

  set clientId(value: string | null) {
    this._form.get(this.C_CLIENT_ID)?.setValue(value);
  }

  currentCompanies: IFleetCompany[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'clientAlias',
      fieldHeader: $localize`:@@app.fleets.edit.clients.clientAlias.column:Alias`,
      width: '10rem',
      canSort: true
    },
    {
      fieldName: 'clientName',
      fieldHeader: $localize`:@@app.fleets.edit.clients.clientName.column:Nombre`,
      canSort: true
    },
    {
      fieldName: 'clientFiscalCode',
      fieldHeader: $localize`:@@app.fleets.edit.clients.fiscalCode.column:NIF/CIF`,
      width: '10rem',
      canSort: true
    },
    {
      fieldName: 'clientGroupAlias',
      fieldHeader: $localize`:@@app.fleets.edit.clients.clientGroupAlias.column:Alias Grupo Cliente`,
      width: '15rem',
      canSort: true
    },
    {
      fieldName: 'clientGroupName',
      fieldHeader: $localize`:@@app.fleets.edit.clients.clientGroupName.column:Nombre Grupo Cliente`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
