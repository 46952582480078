import { IFleetTax } from 'src/app/core/models/api/fleets';
import { FleetEditTaxViewModel } from '../models';

export abstract class FleetEditTaxMapper {
  static mapForEdit(source: IFleetTax, target: FleetEditTaxViewModel): void {
    target.name = source.name;
    target.startDate = source.startDate;
    target.endDate = source.endDate;
    target.paymentTypeId = source.paymentTypeId;
    target.valueType = source.valueType.toString();
    target.value = source.value;
    target.rcso = source.rcso;
    target.rc = source.rc;
    target.sovi = source.sovi;
  }

  static mapForUpdate(taxId: string | null, source: FleetEditTaxViewModel): IFleetTax {
    return {
      taxId: taxId,
      startDate: source.startDate,
      endDate: source.endDate,
      name: source.name,
      paymentTypeId: source.paymentTypeId,
      valueType: parseInt(source.valueType as string),
      value: source.value,
      rcso: source.rcso,
      rc: source.rc,
      sovi: source.sovi
    } as IFleetTax;
  }
}
