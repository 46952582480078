import { IFleetSearchResult } from 'src/app/core/models/api/fleets';
import { FleetSearchResultViewModel } from '../models';

export abstract class FleetSearchMapper {
  static mapForSearch(source: IFleetSearchResult): FleetSearchResultViewModel {
    return {
      fleetId: source.fleetId,
      name: source.name,
      description: source.description,
      clientName: source.clientName,
      clientFiscalCode: source.clientFiscalCode
    } as FleetSearchResultViewModel;
  }
}
