import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyEditRiskViewModel {
  private _form: FormGroup;
  private _policyId!: string;
  private _policyRiskId!: string;
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _bookingModes: Array<IBookingModeSearchResult> = [];

  private _filteredFleetCompanies: Observable<Array<IFleetCompany>>;
  private _filteredBookingModes: Observable<Array<IBookingModeSearchResult>>;

  readonly C_BOOKING_MODE_ID = 'bookingModeId';
  readonly C_ACTIVATION_DATE = 'activationDate';
  readonly C_DEACTIVATION_DATE = 'deactivationDate';
  readonly C_DEACTIVATION_NOTIFICATION_DATE = 'deactivationNotificationDate';
  readonly C_INSURED_ID = 'insuredId';
  readonly C_INSURED_INCLUSION_DATE = 'insuredInclusionDate';
  readonly C_INSURED_EXCLUSION_DATE = 'insuredExclusionDate';
  readonly C_CREDITOR_NAME = 'creditorName';
  readonly C_CREDITOR_FISCAL_CODE = 'creditorFiscalCode';
  readonly C_CREDITOR_EMAIL = 'creditorEmail';
  readonly C_CREDITOR_COMMENTS = 'creditorComments';

  constructor() {
    this._form = new FormGroup({
      [this.C_BOOKING_MODE_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.bookingModes, 'bookingModeId')]),
      [this.C_ACTIVATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_DEACTIVATION_DATE]: new FormControl(null, []),
      [this.C_DEACTIVATION_NOTIFICATION_DATE]: new FormControl(null, []),
      [this.C_INSURED_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.fleetCompanies, 'companyId')]),
      [this.C_INSURED_INCLUSION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_INSURED_EXCLUSION_DATE]: new FormControl(null, []),
      [this.C_CREDITOR_NAME]: new FormControl(null, []),
      [this.C_CREDITOR_FISCAL_CODE]: new FormControl(null, []),
      [this.C_CREDITOR_EMAIL]: new FormControl(null, [Validators.email]),
      [this.C_CREDITOR_COMMENTS]: new FormControl(null, [])
    });

    this._filteredFleetCompanies = this.getControl(this.C_INSURED_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterFleetCompanies(value || ''))
    );

    this._filteredBookingModes = this.getControl(this.C_BOOKING_MODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterBookingModes(value || ''))
    );
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get policyId(): string {
    return this._policyId;
  }

  set policyId(value: string) {
    this._policyId = value;
  }

  get policyRiskId(): string {
    return this._policyRiskId;
  }

  set policyRiskId(value: string) {
    this._policyRiskId = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(bookingModes: Array<IBookingModeSearchResult>) {
    this._bookingModes = bookingModes;
  }

  get bookingModeId(): string | null | undefined {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | null | undefined) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }

  get activationDate(): Date | null | undefined {
    return this.getControl(this.C_ACTIVATION_DATE).value;
  }

  set activationDate(value: Date | null | undefined) {
    this.getControl(this.C_ACTIVATION_DATE).setValue(value);
  }

  get deactivationDate(): Date | null | undefined {
    return this.getControl(this.C_DEACTIVATION_DATE).value;
  }

  set deactivationDate(value: Date | null | undefined) {
    this.getControl(this.C_DEACTIVATION_DATE).setValue(value);
  }

  get deactivationNotificationDate(): Date | null | undefined {
    return this.getControl(this.C_DEACTIVATION_NOTIFICATION_DATE).value;
  }

  set deactivationNotificationDate(value: Date | null | undefined) {
    this.getControl(this.C_DEACTIVATION_NOTIFICATION_DATE).setValue(value);
  }

  get insuredClientId(): string | null | undefined {
    return this.getControl(this.C_INSURED_ID).value;
  }

  set insuredClientId(value: string | null | undefined) {
    this.getControl(this.C_INSURED_ID).setValue(value);
  }

  get insuredInclusionDate(): Date | null | undefined {
    return this.getControl(this.C_INSURED_INCLUSION_DATE).value;
  }

  set insuredInclusionDate(value: Date | null | undefined) {
    this.getControl(this.C_INSURED_INCLUSION_DATE).setValue(value);
  }

  get insuredExclusionDate(): Date | null | undefined {
    return this.getControl(this.C_INSURED_EXCLUSION_DATE).value;
  }

  set insuredExclusionDate(value: Date | null | undefined) {
    this.getControl(this.C_INSURED_EXCLUSION_DATE).setValue(value);
  }

  get creditorName(): string | null | undefined {
    return this.getControl(this.C_CREDITOR_NAME).value;
  }

  set creditorName(value: string | null | undefined) {
    this.getControl(this.C_CREDITOR_NAME).setValue(value);
  }

  get creditorFiscalCode(): string | null | undefined {
    return this.getControl(this.C_CREDITOR_FISCAL_CODE).value;
  }

  set creditorFiscalCode(value: string | null | undefined) {
    this.getControl(this.C_CREDITOR_FISCAL_CODE).setValue(value);
  }

  get creditorEmail(): string | null | undefined {
    return this.getControl(this.C_CREDITOR_EMAIL).value;
  }

  set creditorEmail(value: string | null | undefined) {
    this.getControl(this.C_CREDITOR_EMAIL).setValue(value);
  }

  get creditorComments(): string | null | undefined {
    return this.getControl(this.C_CREDITOR_COMMENTS).value;
  }

  set creditorComments(value: string | null | undefined) {
    this.getControl(this.C_CREDITOR_COMMENTS).setValue(value);
  }

  get filteredFleetCompanies(): Observable<Array<IFleetCompany>> {
    return this._filteredFleetCompanies;
  }

  get filteredBookingModes(): Observable<Array<IBookingModeSearchResult>> {
    return this._filteredBookingModes;
  }

  private filterFleetCompanies(value: string): Array<IFleetCompany> {
    return value !== ''
      ? this.fleetCompanies.filter(c => c.clientName?.toLowerCase().includes(value.toLowerCase()))
      : this.fleetCompanies;
  }

  private filterBookingModes(value: string): Array<IBookingModeSearchResult> {
    return value !== ''
      ? this.bookingModes.filter(c => c.description?.toLowerCase().includes(value.toLowerCase()))
      : this.bookingModes;
  }
}
