import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IBookingModeSearchRequest, IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class BookingModeSearchViewModel {
  private _form: FormGroup;
  private _searchRequest: IBookingModeSearchRequest;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _products: Array<IProduct> = [];

  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _filteredIndustries: Observable<Array<IIndustry>>;
  private _filteredProducts: Observable<Array<IProduct>>;

  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_INDUSTRY_ID = 'industryId';
  readonly C_PRODUCT_ID = 'productId';
  readonly C_DESCRIPTION = 'description';

  constructor() {
    this._form = new FormGroup({
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_INDUSTRY_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_PRODUCT_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.products, 'productId')]),
      [this.C_DESCRIPTION]: new FormControl(null, [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this.sortedBy,
      sortDirection: this.sortDirection
    } as IBookingModeSearchRequest;

    this.updateServerSideFilters();

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._filteredIndustries = this.getControl(this.C_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._filteredProducts = this.getControl(this.C_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterProducts(value || ''))
    );
  }

  data: IBookingModeSearchResult[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.booking.mode.search.insuranceCompany.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.booking.mode.search.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.booking.mode.search.product.column:Producto`,
      canSort: true
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.booking.mode.search.description.column:Descripción`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.booking.mode.search.actions.delete.tooltip:Borrar`
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get searchRequest(): IBookingModeSearchRequest {
    return this._searchRequest;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get products(): Array<IProduct> {
    return this._products;
  }

  set products(value: Array<IProduct>) {
    this._products = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  get insuranceCompanyId(): string | null | undefined {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string | null | undefined) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get industryId(): string | null | undefined {
    return this.getControl(this.C_INDUSTRY_ID).value;
  }

  set industryId(value: string | null | undefined) {
    this.getControl(this.C_INDUSTRY_ID).setValue(value);
  }

  get productId(): string | null | undefined {
    return this.getControl(this.C_PRODUCT_ID).value;
  }

  set productId(value: string | null | undefined) {
    this.getControl(this.C_PRODUCT_ID).setValue(value);
  }

  get description(): string | null | undefined {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string | null | undefined) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  get filteredIndustries(): Observable<Array<IIndustry>> {
    return this._filteredIndustries;
  }

  get filteredProducts(): Observable<Array<IProduct>> {
    return this._filteredProducts;
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.insuranceCompanyId = this.insuranceCompanyId;
    this.searchRequest.industryId = this.industryId;
    this.searchRequest.productId = this.productId;
    this.searchRequest.description = this.description;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this.insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this.industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.industries;
  }

  private filterProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this.products.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.products;
  }
}
