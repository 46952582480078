import { Observable } from 'rxjs';
import {
  IBonusHistoryReportRequest,
  IClaimsByCompanyReportRequest,
  IFleetControlReportRequest,
  IFleetStateReportRequest,
  IOperatingCompaniesReportRequest,
  IPolicyHistoryReportRequest,
  IPolicyReportRequest,
  IRegularizationReportRequest,
  IRisksComparedReportRequest,
  IRisksInsuredReportRequest,
  IRootPolicyReportRequest,
  IUnitReportRequest
} from 'src/app/core/models/api/reports';
import { ILTAReportRequest } from 'src/app/core/models/api/reports/lta-report-request';

export abstract class IReportsService {
  abstract fleets(): Observable<Blob>;
  abstract fleetsControl(request: IFleetControlReportRequest): Observable<Blob>;
  abstract rootPolicies(request: IRootPolicyReportRequest): Observable<Blob>;
  abstract units(request: IUnitReportRequest): Observable<Blob>;
  abstract policies(request: IPolicyReportRequest): Observable<Blob>;
  abstract policyHistory(request: IPolicyHistoryReportRequest): Observable<Blob>;
  abstract bonusHistory(request: IBonusHistoryReportRequest): Observable<Blob>;
  abstract fleetState(request: IFleetStateReportRequest): Observable<Blob>;
  abstract regularization(request: IRegularizationReportRequest): Observable<Blob>;
  abstract risksCompared(request: IRisksComparedReportRequest): Observable<Blob>;
  abstract risksInsured(request: IRisksInsuredReportRequest): Observable<Blob>;
  abstract claimsByCompany(request: IClaimsByCompanyReportRequest): Observable<Blob>;
  abstract operatingCompanies(request: IOperatingCompaniesReportRequest): Observable<Blob>;
  abstract lta(request: ILTAReportRequest): Observable<Blob>;
}
