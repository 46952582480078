import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogResultTypes,
  IButtonActionData,
  IDialogResult
} from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IDocument } from 'src/app/core/models/ida/document-manager';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyEditDocumentDialogComponent } from '../../../dialogs/policy-edit-document-dialog/pages/policy-edit-document-dialog.component';
import { PolicyEditDocumentsViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-documents',
  templateUrl: './policy-edit-documents.component.html',
  styleUrls: ['./policy-edit-documents.component.scss']
})
export class PolicyEditDocumentsComponent implements OnChanges, OnDestroy {
  model = new PolicyEditDocumentsViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private policySrv: IPolicyService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.onDelete(event.row.documentId);
    } else {
      this.onDownload(event.row.documentId, event.row.name);
    }
  }

  onNewDocument(): void {
    const inputData = { policyId: this.policyId, documentId: undefined };
    const dialogRef = this.dialog.open(PolicyEditDocumentDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refresh());
  }

  private onDelete(documentId: string): void {
    const title: string = $localize`:@@app.policies.edit.documents.delete.title:Borrado de documentos`;
    const question: string = $localize`:@@app.policies.edit.documents.delete.subtitle:Se borrará el documento, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.policies.edit.documents.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();

          this.policySrv.deleteDocument(this.policyId, documentId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onDownload(documentId: string, filename: string) {
    this.policySrv.downloadDocument(this.policyId, documentId).subscribe((data: Blob) => {
      FileUtils.downloadRawFile(filename, data);
    });
  }

  private refresh() {
    this.policySrv.getDocuments(this.policyId).subscribe((data: Array<IDocument>) => this.model.data = data);
  }
}
