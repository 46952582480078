<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.paralization.title">Paralizaciones</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.policies.edit.paralization.startDate.label">Fecha incio</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            [formControlName]="model.C_START_DATE"
            [howdenControlError]="startDateError"
            [min]="minDate"
          />
          <mat-error #startDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.policies.edit.paralization.endDate.label">Fecha fin</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            [formControlName]="model.C_END_DATE"
            [howdenControlError]="endDateError"
            [min]="minDate"
          />
          <mat-error #endDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px margin-bottom-10">
        @if (model.form.hasError('dateRange')) {
          <mat-error
          i18n="@@app.policies.edit.paralization.dateRange.error">El rango de fechas no es válido.</mat-error>
        }
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
