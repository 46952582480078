<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <!--Filters-->

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.dashboards.receipts.startDate.label">Desde</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          [formControlName]="model.C_START_DATE"
          [howdenControlError]="startDateError"
          [min]="minDate"
        />
        <mat-error #startDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.dashboards.receipts.endDate.label">Hasta</mat-label>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          [formControlName]="model.C_END_DATE"
          [howdenControlError]="endDateError"
          [min]="minDate"
        />
        <mat-error #endDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.dashboards.receipts.insuranceCompanyId.label">Aseguradora</mat-label>
        <input
          #insuranceCompany
          matInput
          [formControlName]="model.C_INSURANCE_COMPANY_ID"
          [matAutocomplete]="insuranceCompanySelect"
          [howdenControlError]="insuranceCompanyIdError"
        />
        <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
          @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
            <mat-option [value]="item.insuranceCompanyId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #insuranceCompanyIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.dashboards.receipts.policyNumber.label">Nº.Póliza</mat-label>
        <input
          matInput
          [formControlName]="model.C_POLICY_NUMBER"
          [howdenControlError]="policyNumberError"
          maxlength="20"
          i18n-placeholder="@@app.dashboards.receipts.policyNumber.placeholder"
          placeholder="Número de póliza"
        />
        <mat-error #policyNumberError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center">
      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onFilter()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </div>
  </form>

  <mat-divider class="margin-top-10 margin-bottom-10"></mat-divider>

  <!--Header-->

  @if (model.fleet) {
    <howden-dashboard-header
      [fleetName]="model.fleet.name"
      [clientName]="model.fleet.clientName"
      [clientFiscalCode]="model.fleet.clientFiscalCode"
      [cnaeDescription]="model.fleet.cnaeDescription"
    >
    </howden-dashboard-header>
  }

  <!--Dashboard-->
  @if (model.response) {
    <mat-grid-list cols="{{(cardLayout | async)?.columns }}" rowHeight="200px">
      <!--Mini Cards-->

      @for (mc of model.miniCardData; track mc) {
        <mat-grid-tile
          [colspan]="( cardLayout | async )?.miniCard?.cols"
          [rowspan]="( cardLayout | async )?.miniCard?.rows"
        >
          <howden-dashboard-mini-card
            [isCurrency]="mc.isCurrency"
            [isIncrease]="mc.isIncrease"
            [duration]="mc.duration"
            [icon]="mc.icon"
            [title]="mc.title"
            [value]="mc.value"
            [color]="mc.color"Graph2
            [percentValue]="mc.percentValue"
          >
          </howden-dashboard-mini-card>
        </mat-grid-tile>
      }

      <!--Charts-->

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Vencimientos'"
          i18n-title="@@app.dashboards.receipts.receipt.expirations.chart.title"
          [subtitle]="'Vencimientos de recibos en el periodo seleccionado'"
          i18n-subtitle="@@app.dashboards.receipts.receipt.expirations.chart.subtitle"
        >
          <howden-receipts-expirations-chart [data]="model.response.expirationsByDate"></howden-receipts-expirations-chart>
        </howden-dashboard-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Comisiones correduría'"
          i18n-title="@@app.dashboards.receipts.broker.commissions.chart.title"
          [subtitle]="'Comisiones sobre cobros de recibos por fecha con vencimiento en el periodo seleccionado'"
          i18n-subtitle="@@app.dashboards.receipts.broker.commissions.chart.subtitle"
        >
          <howden-dashboard-bar-chart [data]="model.response.brokerCommissionsByDate"></howden-dashboard-bar-chart>
        </howden-dashboard-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Cobros por fecha'"
          i18n-title="@@app.dashboards.receipts.collections.by.date.chart.title"
          [subtitle]="'Cobros de recibos con vencimiento en el periodo seleccionado'"
          i18n-subtitle="@@app.dashboards.receipts.collections.by.date.chart.subtitle"
        >
          <howden-dashboard-bar-chart [data]="model.response.collectionsByDate"></howden-dashboard-bar-chart>
        </howden-dashboard-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Cobros por pagador'"
          i18n-title="@@app.dashboards.receipts.collections.by.payer.chart.title"
          [subtitle]="'Cobros de recibos por pagador con vencimiento en el periodo seleccionado'"
          i18n-subtitle="@@app.dashboards.receipts.collections.by.payer.chart.subtitle"
        >
          <howden-dashboard-bar-chart [data]="model.response.collectionsByPayer"></howden-dashboard-bar-chart>
        </howden-dashboard-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Recibos por situación'"
          i18n-title="@@app.dashboards.receipts.by.situation.chart.title"
          [subtitle]="'Recibos por situación con vencimiento en el periodo seleccionado'"
          i18n-subtitle="@@app.dashboards.receipts.by.situation.chart.subtitle"
        >
          <howden-dashboard-pie-chart [data]="model.response.receiptsBySituation"></howden-dashboard-pie-chart>
        </howden-dashboard-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Recibos por tipo'"
          i18n-title="@@app.dashboards.receipts.by.type.chart.title"
          [subtitle]="'Recibos por tipo con vencimiento en el periodo seleccionado'"
          i18n-subtitle="@@app.dashboards.receipts.by.type.chart.subtitle"
        >
          <howden-dashboard-pie-chart [data]="model.response.receiptsByType"></howden-dashboard-pie-chart>
        </howden-dashboard-card>
      </mat-grid-tile>

      <!--Table-->

      <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
        <howden-dashboard-card [title]="'Recibos'" i18n-title="@@app.dashboards.receipts.table.title">
          <howden-table
            class="small-font"
            keyField="receiptId"
            paginable="local"
            sortable="local"
            filterable="global-local"
            [pageSize]="10"
            [cols]="model.columns"
            [data]="model.data"
            [buttons]="model.buttons"
          >
          </howden-table>
          <div class="flex f-gap--10px f-jc--center margin-bottom-10">
            <button type="button" mat-raised-button color="primary" (click)="onTableExport()" [disabled]="!canExport">
              <span i18n="@@app.core.actions.export">Exportar</span>
            </button>
          </div>
        </howden-dashboard-card>
      </mat-grid-tile>
    </mat-grid-list>
  }
</div>
