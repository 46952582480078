import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { IPolicySummary } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicySummaryDialogComponent } from 'src/app/features/policies/policy-summary-dialog/pages/policy-summary-dialog.component';
import { SubSink } from 'subsink';

@Component({
  selector: 'howden-policy-edit-header',
  templateUrl: './policy-edit-header.component.html',
  styleUrls: ['./policy-edit-header.component.scss']
})
export class PolicyEditHeaderComponent implements OnChanges, OnDestroy {
  policySummary?: IPolicySummary;
  hasWaitingProcesses = false;

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(private dialog: MatDialog, private policySrv: IPolicyService, private uiBlockerSrv: IUIBlockerService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    forkJoin([
      this.policySrv.getSummaryHeader(this.policyId),
      this.policySrv.hasWaitingProcesses(this.policyId)
    ]).subscribe(([
      summary,
      hasWaitingProcesses
    ]) => {
      this.policySummary = summary;
      this.hasWaitingProcesses = typeof hasWaitingProcesses?.result === 'boolean' && hasWaitingProcesses.result;
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onShowInfo(): void {
    const inputData = { id: this.policyId };
    const dialogRef = this.dialog.open(PolicySummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  refresh() {
    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.getSummaryHeader(this.policyId),
      this.policySrv.hasWaitingProcesses(this.policyId)
    ]).subscribe(([
      summary,
      hasWaitingProcesses
    ]) => {
      this.policySummary = summary;
      this.hasWaitingProcesses = typeof hasWaitingProcesses?.result === 'boolean' && hasWaitingProcesses.result;

      this.uiBlockerSrv.unblock();
    });
  }
}
