import { IPolicyRiskWarranty, IUpsertRiskWarranty } from 'src/app/core/models/ida/production';
import { PolicyEditRiskWarrantiesViewModel } from '../models';

export abstract class PolicyEditRiskWarrantyMapper {
  static mapForEdit(source: IPolicyRiskWarranty, target: PolicyEditRiskWarrantiesViewModel) {
    target.policyRiskId = source.policyRiskId;
    target.warrantyId = source.warrantyId;
    target.franchise = source.franchise;
    target.currencyId = source.currencyId;
    target.inclusionDate = source.inclusionDate;
    target.exclusionDate = source.exclusionDate;
    target.order = source.order;
  }

  static mapForUpdate(source: PolicyEditRiskWarrantiesViewModel): IUpsertRiskWarranty {
    return {
      policyRiskId: source.policyRiskId,
      warrantyId: source.warrantyId,
      franchise: source.franchise,
      currencyId: source.currencyId,
      inclusionDate: source.inclusionDate,
      exclusionDate: source.exclusionDate,
      order: source.order
    } as IUpsertRiskWarranty;
  }
}
