import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IClaimDashboardPivotResponse, IClaimDashboardResponse } from 'src/app/core/models/api/dashboards';
import { IFleetSearchResult } from 'src/app/core/models/api/fleets';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';

export class ClaimsDashboardViewModel {
  private _form: FormGroup;
  private _fleet: IFleetSearchResult | null = null;
  private _dashboardData: IClaimDashboardResponse | null = null;
  private _pivotData: IClaimDashboardPivotResponse | null = null;

  readonly C_YEAR = 'year';

  constructor() {
    this._form = new FormGroup({
      [this.C_YEAR]: new FormControl(null, [])
    });
  }

  miniCardData: Array<IMiniCardData> = [];

  get dashboardData(): IClaimDashboardResponse | null {
    return this._dashboardData;
  }
  set dashboardData(value: IClaimDashboardResponse | null) {
    this._dashboardData = value;
  }

  get pivotData(): IClaimDashboardPivotResponse | null {
    return this._pivotData;
  }
  set pivotData(value: IClaimDashboardPivotResponse | null) {
    this._pivotData = value;
  }

  get fleet(): IFleetSearchResult | null {
    return this._fleet;
  }
  set fleet(value: IFleetSearchResult | null) {
    this._fleet = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get year(): number | null {
    return this.getControl(this.C_YEAR).value;
  }

  set year(value: number | null) {
    this.getControl(this.C_YEAR).setValue(value);
  }
}
