import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IProcessSearchRequest, IProcessSearchResult } from 'src/app/core/models/api/processes';
import { IProcessService } from './process.contract';

@Injectable({
  providedIn: 'root'
})
export class ProcessService implements IProcessService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getWaitingProcessCount(): Observable<IResponse<number>> {
    return this.httpClient.get<IResponse<number>>(APP_CONSTANTS.API_NET_FLOTAS, `/processes/waiting-process-count`);
  }

  search(filter: IProcessSearchRequest): Observable<IPageOf<IProcessSearchResult>> {
    return this.httpClient.post<IPageOf<IProcessSearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, `/processes/search`, filter);
  }

  delete(processId: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/processes/${processId}`);
  }

  relaunch(processId: string): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/processes/${processId}/relaunch`, {});
  }
}
