import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { CirculationAreaSearchComponent } from './circulation-areas/pages/circulation-area-search.component';
import { FunctionalTypeSearchComponent } from './functional-types/pages/functional-type-search.component';
import { UnitCreateNewComponent } from './unit-create-new/pages/unit-create-new.component';
import { UnitEditComponent } from './unit-edit/pages/unit-edit.component';
import { UnitLoaderComponent } from './unit-loader/pages/unit-loader.component';
import { UnitSearchComponent } from './unit-search/pages/unit-search.component';
import { UnitUpdaterComponent } from './unit-updater/pages/unit-updater.component';

export const UnitRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'units',
    title: $localize`:@@app.route.units:Unidades`,
    data: {
      icon: 'ad_units',
      linkText: $localize`:@@app.route.units:Unidades`
    },
    children: [
      {
        canActivate: [ActiveFleetGuard],
        path: 'create',
        component: UnitCreateNewComponent,
        title: $localize`:@@app.route.units.create.new:Alta unidad`,
        data: {
          linkText: $localize`:@@app.route.units.create.new:Alta unidad`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'search',
        component: UnitSearchComponent,
        title: $localize`:@@app.route.units.search:Consulta global`,
        data: {
          linkText: $localize`:@@app.route.units.search:Consulta global`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'edit/:id',
        component: UnitEditComponent,
        title: $localize`:@@app.route.units.edit:Modificar unidad`
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'unit-loader',
        component: UnitLoaderComponent,
        title: $localize`:@@app.route.units.loader:Carga de unidades`,
        data: {
          linkText: $localize`:@@app.route.units.loader:Carga de unidades`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'unit-updater',
        component: UnitUpdaterComponent,
        title: $localize`:@@app.route.units.updater:Actualización de unidades`,
        data: {
          linkText: $localize`:@@app.route.units.updater:Actualización de unidades`
        }
      },
      {
        path: 'functional-types',
        component: FunctionalTypeSearchComponent,
        title: $localize`:@@app.route.units.functional.types.search:Tipos funcionales`,
        data: {
          linkText: $localize`:@@app.route.units.functional.types.search:Tipos funcionales`
        }
      },
      {
        path: 'circulation-areas',
        component: CirculationAreaSearchComponent,
        title: $localize`:@@app.route.units.circulation.areas.search:Recintos de circulación`,
        data: {
          linkText: $localize`:@@app.route.units.circulation.areas.search:Recintos de circulación`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(UnitRoutes)],
  exports: [RouterModule]
})
export class UnitsRoutingModule {
}
