<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.units.search.plateNumber.label">Matrícula</mat-label>
        <input #plateNumberInput  matInput [formControlName]="model.C_PLATE_NUMBER" maxlength="15" />
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.units.search.carNumber.label">Nº. Coche</mat-label>
        <input matInput [formControlName]="model.C_CAR_NUMBER" maxlength="20" />
      </mat-form-field>

      <mat-slide-toggle
        class="f-basis--4 f-as--center"
        [formControlName]="model.C_WITHOUT_POLICIES"
        i18n="@@app.units.search.withoutPolicies.label">Sin póliza</mat-slide-toggle>
    </div>
  </form>
  <div>
    <howden-table
      class="small-font"
      keyField="unitId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
