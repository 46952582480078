<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <!--Header-->

  @if (model.fleet) {
    <howden-dashboard-header
      [fleetName]="model.fleet.name"
      [clientName]="model.fleet.clientName"
      [clientFiscalCode]="model.fleet.clientFiscalCode"
      [cnaeDescription]="model.fleet.cnaeDescription"
    >
    </howden-dashboard-header>
  }

  <!--Dashboard-->

  <mat-grid-list cols="{{(cardLayout | async)?.columns }}" rowHeight="200px">

    <!--Mini Cards-->

    @for (mc of model.miniCardData; track mc) {
      <mat-grid-tile
        [colspan]="( cardLayout | async )?.miniCard?.cols"
        [rowspan]="( cardLayout | async )?.miniCard?.rows"
      >
        <howden-dashboard-mini-card
          [isCurrency]="mc.isCurrency"
          [isIncrease]="mc.isIncrease"
          [duration]="mc.duration"
          [icon]="mc.icon"
          [title]="mc.title"
          [value]="mc.value"
          [color]="mc.color"Graph2
          [percentValue]="mc.percentValue"
        >
        </howden-dashboard-mini-card>
      </mat-grid-tile>
    }

    <!--Charts-->

    <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
      <howden-dashboard-card [title]="'Unidades por tipo de vehículo'" i18n-title="@@app.dashboards.fleet.charts.1.title">
        <howden-dashboard-bar-chart [data]="model.unitsByVehicleType"></howden-dashboard-bar-chart>
      </howden-dashboard-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
      <howden-dashboard-card [title]="'Unidades por póliza'" i18n-title="@@app.dashboards.fleet.charts.2.title">
        <howden-dashboard-bar-chart [data]="model.unitsByPolicy"></howden-dashboard-bar-chart>
      </howden-dashboard-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
      <howden-dashboard-card [title]="'Polizas por forma de pago'" i18n-title="@@app.dashboards.fleet.charts.3.title">
        <howden-dashboard-bar-chart [data]="model.policiesByPaymentType"></howden-dashboard-bar-chart>
      </howden-dashboard-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
      <howden-dashboard-card [title]="'Prima Media por tipo de vehículo'" i18n-title="@@app.dashboards.fleet.charts.4.title">
        <howden-dashboard-bar-chart [data]="model.averageBonusByVehicleType" [valueFormat]="'1.2-2'"></howden-dashboard-bar-chart>
      </howden-dashboard-card>
    </mat-grid-tile>

  </mat-grid-list>
</div>
