import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { CollectionManagerStatus, ProductStatus } from 'src/app/core/enums';
import { IProduct } from 'src/app/core/models/ida/products';
import { IMediationCode } from 'src/app/core/models/ida/providers';
import { ICurrencyService } from 'src/app/core/services/api/currencies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IOfficeService } from 'src/app/core/services/api/offices';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IProductService } from 'src/app/core/services/api/products';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { PolicyNumberValidator } from 'src/app/shared/validators';
import { SubSink } from 'subsink';
import { PolicyEditColectiveReplacementMapper } from '../mappers';
import { PolicyEditColectiveReplacementViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-colective-replacement-dialog',
  templateUrl: './policy-edit-colective-replacement-dialog.component.html',
  styleUrls: ['./policy-edit-colective-replacement-dialog.component.scss']
})
export class PolicyEditColectiveReplacementDialogComponent implements OnInit, OnDestroy {
  model: PolicyEditColectiveReplacementViewModel;
  isPolicyNumberAvailable = true;

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditColectiveReplacementDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private currencySrv: ICurrencyService,
    private fleetSrv: IFleetService,
    private masterSrv: IMasterService,
    private officeSrv: IOfficeService,
    private policySrv: IPolicyService,
    private productSrv: IProductService,
    private insuranceCompanySrv: IInsuranceCompanyService
  ) {
    const validator = new PolicyNumberValidator(this.policySrv, this.insuranceCompanyId.bind(this));

    this.model = new PolicyEditColectiveReplacementViewModel(validator);
  }

  insuranceCompanyId(): string | null {
    return this.model.insuranceCompanyId;
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.masterSrv.getCollectionManagers(),
      this.masterSrv.getPaymentTypes(),
      this.masterSrv.getPolicyDurations(),
      this.policySrv.getBillingModels(),
      this.currencySrv.getCurrencies(),
      this.officeSrv.getOffices(),
      this.fleetSrv.getInsuranceCompanies(fleetId),
      this.fleetSrv.getIndustries(fleetId),
      this.fleetSrv.getCompanies(fleetId)
    ]).subscribe(([
      collectionManagers,
      paymentTypes,
      policyDurations,
      billingModels,
      currencies,
      offices,
      insuranceCompanies,
      industries,
      fleetCompanies
    ]) => {
      this.model.collectionManagers = collectionManagers.filter(x => x.status === CollectionManagerStatus.Active);
      this.model.paymentTypes = paymentTypes;
      this.model.regularizationPeriods = paymentTypes;
      this.model.policyDurations = policyDurations;
      this.model.billingModels = billingModels;
      this.model.currencies = currencies;
      this.model.offices = offices;
      this.model.insuranceCompanies = insuranceCompanies;
      this.model.industries = industries;
      this.model.fleetCompanies = fleetCompanies;

      this.onChanges();

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const id = this.inputData.id as string;
    const request = PolicyEditColectiveReplacementMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.colectiveReplacement(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getFleetCompanyName(fleetCompanyId: string): string {
    return this.model.fleetCompanies.find(x => x.companyId === fleetCompanyId)?.clientName ?? '';
  }

  getCollectionManagerName(collectionManagerId: string): string {
    const collectionManager = this.model.collectionManagers.find(x => x.collectionManagerId === collectionManagerId);
    return collectionManager ? `${collectionManager.alias}-${collectionManager.name}` : '';
  }

  getOfficeDescription(officeCode: string): string {
    return this.model.offices.find(x => x.code === officeCode)?.name ?? '';
  }

  getCurrencyDescription(currencyId: string): string {
    return this.model.currencies.find(x => x.currencyId === currencyId)?.description ?? '';
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getProductName(productId: string): string {
    const product = this.model.products?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  getMediationCodeDescription(mediationCodeId: string): string {
    const mediationCode = this.model.mediationCodes?.find(x => x.mediationCodeId === mediationCodeId);
    return mediationCode ? `${mediationCode.code}-${mediationCode.description}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID).valueChanges.subscribe(() => {
      this.onInsuranceCompanyChanged();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INDUSTRY_ID).valueChanges.subscribe(() => {
      this.onIndustryChanged();
    });
  }

  private onInsuranceCompanyChanged(): void {
    this.onLoadProducts();
    this.onLoadMediationCodes();
  }

  private onIndustryChanged(): void {
    this.onLoadProducts();
  }

  private onLoadProducts(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;
    const industryId: string = this.model.industryId;

    this.model.productId = '';
    this.model.products = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        this.model.products = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private onLoadMediationCodes(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;

    this.model.mediationCodeId = '';
    this.model.mediationCodes = new Array<IMediationCode>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);

    if (insuranceCompany) {
      this.insuranceCompanySrv.getMediationCodes(insuranceCompanyId).subscribe((data: Array<IMediationCode>) => {
        this.model.mediationCodes = data;
        const defaultMediationCode = this.model.mediationCodes?.find(x => x.isDefault === true);
        if (defaultMediationCode) {
          this.model.mediationCodeId = defaultMediationCode.mediationCodeId;
        }
      });
    }
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
