import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IFleetSearchRequest } from 'src/app/core/models/api/fleets';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IReportsService } from 'src/app/core/services/api/reports';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { FleetControlReportMapper } from '../mappers';
import { FleetControlReportViewModel } from '../models';

@Component({
  selector: 'howden-fleet-control-report',
  templateUrl: './fleet-control-report.component.html',
  styleUrls: ['./fleet-control-report.component.scss']
})
export class FleetControlReportComponent implements OnInit {
  model = new FleetControlReportViewModel();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private fleetSrv: IFleetService,
    private mastersSrv: IMasterService,
    private reportsSrv: IReportsService) { }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    const filter = {
      pageNumber: 0,
      pageSize: 99999
    } as IFleetSearchRequest;

    forkJoin([
      this.fleetSrv.search(filter),
      this.mastersSrv.getFleetControlReportItemTypes()
    ]).subscribe(([fleets, itemTypes]) => {
      this.model.fleets = fleets.data;
      this.model.itemTypes = itemTypes.map(x => <IItemSelection>{ id: x.key, description: x.value });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = FleetControlReportMapper.mapForGeneration(this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.fleetsControl(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_CONTROL_FLOTAS', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
