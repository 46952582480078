<a
  mat-mini-fab
  color="primary"
  href="http://comunidadartai/Lists/Incidencias%20IT/AllItems.aspx"
  target="_blank"
  matTooltip="Comunidad ARTAI"
  i18n-matTooltip="@@app.header.artai.community.tooltip.label"
  aria-label="Comunidad ARTAI"
  i18n-aria-label="@@app.header.artai.community.arial.label"
>
  <mat-icon>public</mat-icon>
</a>
