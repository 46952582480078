import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IEmptyRootPolicyNotification, IPolicyExpirationNotification } from 'src/app/core/models/api/notifications';
import { IFleetNotificationsService } from './fleet-notifications.contract';

@Injectable({
  providedIn: 'root'
})
export class FleetNotificationsService implements IFleetNotificationsService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getNotificationCount(): Observable<IResponse<number>> {
    return this.httpClient.get<IResponse<number>>(APP_CONSTANTS.API_NET_FLOTAS, `/notifications/notification-count`);
  }

  getPolicyExpirationNotifications(): Observable<Array<IPolicyExpirationNotification>> {
    return this.httpClient.get<Array<IPolicyExpirationNotification>>(APP_CONSTANTS.API_NET_FLOTAS, `/notifications/policy-expirations`);
  }

  exportPolicyExpirationNotifications(): Observable<Blob> {
    const uri = `/notifications/export-policy-expirations`;
    return this.httpClient.getBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, {
      responseType: 'blob'
    });
  }

  getEmptyRootPolicyNotifications(): Observable<Array<IEmptyRootPolicyNotification>> {
    return this.httpClient.get<Array<IEmptyRootPolicyNotification>>(APP_CONSTANTS.API_NET_FLOTAS, `/notifications/empty-root-policies`);
  }

  exportEmptyRootPolicyNotifications(): Observable<Blob> {
    const uri = `/notifications/export-empty-root-policies`;
    return this.httpClient.getBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, {
      responseType: 'blob'
    });
  }
}
