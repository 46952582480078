import { IPolicyLoadFileTemplateRequest } from 'src/app/core/models/api/templates';
import { PolicyLoadViewModel } from '../models';

export abstract class PolicyLoadMapper {
  static mapForUpdate(source: PolicyLoadViewModel): IPolicyLoadFileTemplateRequest {
    return {
      createSection: {
        clientGroupId: source.clientGroupId,
        insuranceCompanyId: source.mainInsuranceCompanyId,
        industryId: source.mainIndustryId,
        productId: source.mainProductId,
        mediationCodeId: source.mainMediationCodeId,
        officeId: source.mainOfficeId,
        accountManagerId: source.mainAccountManagerId,
        technicianId: source.mainTechnicianId,
        auxiliarTechnicianId: source.mainAuxiliarTechnicianId
      },
      replace: source.mainReplace,
      replaceSection: source.mainReplace === true ? {
        clientGroupId: source.clientGroupId,
        insuranceCompanyId: source.secondaryInsuranceCompanyId,
        industryId: source.secondaryIndustryId,
        productId: source.secondaryProductId,
        mediationCodeId: source.secondaryMediationCodeId,
        officeId: source.secondaryOfficeId,
        accountManagerId: source.secondaryAccountManagerId,
        technicianId: source.secondaryTechnicianId,
        auxiliarTechnicianId: source.secondaryAuxiliarTechnicianId,
        operationDate: source.secondaryOperationDate
      } : null,
      import: source.mainImport,
      importAll: source.mainImportAll
    } as IPolicyLoadFileTemplateRequest;
  }
}
