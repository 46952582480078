import { IOperatingCompaniesReportRequest } from 'src/app/core/models/api/reports';
import { OperatingCompaniesReportViewModel } from '../models';

export abstract class OperatingCompaniesReportMapper {
  static mapForGeneration(activeFleet: string, source: OperatingCompaniesReportViewModel): IOperatingCompaniesReportRequest {
    return {
      fleetId: activeFleet,
      claimPaymentId: source.claimPaymentId,
      year: source.year,
      includePoliciesWithIndividualBonuses: source.includePoliciesWithIndividualBonuses
    } as IOperatingCompaniesReportRequest;
  }
}
