<form class="margin-top-10" [formGroup]="model.form">
  <div class="flex f-gap--10px margin-bottom-10">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.billing.date.label">Fecha</mat-label>
      <input matInput [matDatepicker]="dateDatePicker" [formControlName]="model.C_DATE"
        [howdenControlError]="dateDatePickerError" [min]="minDate" />
        <mat-error #dateDatePickerError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dateDatePicker></mat-datepicker>
      </mat-form-field>

      <div class="mat-form-field inline">
        <div class="mat-form-field-wrapper">
          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onFilter()">
            <span i18n="@@app.core.actions.accept">Aceptar</span>
          </button>
        </div>
      </div>
    </div>
  </form>

  @if (hasData) {
    <div class="flex">
      <h4 i18n="@@app.policies.edit.billing.summary.section.label">Resúmenes</h4>
    </div>
  }

  @for (group of model.groups; track group) {
    <mat-expansion-panel class="margin-bottom-10">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ group.description }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <howden-policy-edit-billing-info [group]="group"></howden-policy-edit-billing-info>
    </mat-expansion-panel>
  }

  @if (hasData) {
    <div class="flex">
      <h4 i18n="@@app.policies.edit.billing.details.section.label">Detalles</h4>
    </div>

    <div class="flex margin-top-10">
      <howden-table
        class="small-font"
        keyField="policyId"
        paginable="local"
        sortable="local"
        filterable="global-local"
        [pageSize]="10"
        [cols]="model.columns"
        [data]="model.history"
        [buttons]="model.buttons"
        >
      </howden-table>
    </div>
  }
