import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { IResponse } from '@howdeniberia/core-front';
import { Observable, catchError, map, of } from 'rxjs';
import { IIsPlateNumberAvailable } from 'src/app/core/models/api/units';
import { IUnitService } from 'src/app/core/services/api/units';

@Injectable({ providedIn: 'root' })
export class PlateNumberValidator implements AsyncValidator {
  constructor(private unitsSrv: IUnitService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of({ notAvailable: true });
    }
    const request = { plateNumber: control.value } as IIsPlateNumberAvailable;
    return this.unitsSrv.isPlateNumberAvailable(request).pipe(
      map((response: IResponse<boolean>) => response.result ? null : { notAvailable: true }),
      catchError(() => of({ notAvailable: true }))
    );
  }
}
