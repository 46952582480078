<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <div>
    <howden-table
      #fleetsTable
      class="small-font"
      keyField="fleetId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.pageIndex"
      [pageSize]="model.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (filtersChanged)="onFiltersChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
