import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IPolicyIncidence } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicyIncidenceMapper } from '../mappers';
import { PolicyEditIncidencesViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-incidences',
  templateUrl: './policy-edit-incidences.component.html',
  styleUrls: ['./policy-edit-incidences.component.scss']
})
export class PolicyEditIncidencesComponent implements OnChanges {
  model = new PolicyEditIncidencesViewModel();

  @Input() policyId = '';

  constructor(private uiBlockerSrv: IUIBlockerService, private policySrv: IPolicyService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.uiBlockerSrv.block();
    this.policySrv.getIncidences(this.policyId).subscribe({
      next: (data: Array<IPolicyIncidence>) => this.model.data = PolicyIncidenceMapper.map(data),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
