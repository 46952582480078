<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.bonus.title">Primas</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--6">
          <mat-label i18n="@@app.policies.edit.bonus.effectDate.label">Desde</mat-label>
          <input
            matInput
            [matDatepicker]="effectDatePicker"
            [formControlName]="model.C_EFFECT_DATE"
            [howdenControlError]="effectDateError"
            [min]="minDate"
          />
          <mat-error #effectDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #effectDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="f-basis--6">
          <mat-label i18n="@@app.policies.edit.bonus.expirationDate.label">Hasta</mat-label>
          <input
            matInput
            [matDatepicker]="expirationDatePicker"
            [formControlName]="model.C_EXPIRATION_DATE"
            [howdenControlError]="expirationDateError"
            [min]="minDate"
          />
          <mat-error #expirationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <howden-decimal
          class="f-basis--6"
          [formControlName]="model.C_NET_BONUS_RC"
          i18n-label="@@app.policies.edit.bonus.netBonusRC.label"
          label="Prima neta anual"
        ></howden-decimal>

        <howden-decimal
          class="f-basis--6"
          [formControlName]="model.C_TOTAL_RC"
          i18n-label="@@app.policies.edit.bonus.totalRC.label"
          label="Prima total anual"
        ></howden-decimal>
      </div>

      <div class="flex f-gap--10px">
        <howden-decimal
          class="f-basis--6"
          [formControlName]="model.C_COMMISSION_RATE"
          i18n-label="@@app.policies.edit.bonus.commissionRate.label"
          label="Comisión (%)"
        ></howden-decimal>

        <howden-decimal
          class="f-basis--6"
          [formControlName]="model.C_EXTRA_COMMISSION_RATE"
          i18n-label="@@app.policies.edit.bonus.extraCommissionRate.label"
          label="Comisión adicional (%)"
        ></howden-decimal>
      </div>

      <div class="flex f-gap--10px margin-bottom-10">
        @if (model.form.hasError('dateRange')) {
          <mat-error i18n="@@app.policies.edit.bonus.dateRange.error">El rango de fechas no es válido.</mat-error>
        }
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
