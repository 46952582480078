import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUploadComponent } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FormGroupUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyAssignationViewModel } from '../models';

@Component({
  selector: 'howden-policy-assignation',
  templateUrl: './policy-assignation.component.html',
  styleUrls: ['./policy-assignation.component.scss']
})
export class PolicyAssignationComponent implements OnInit, OnDestroy {
  model = new PolicyAssignationViewModel();

  @ViewChild('rootPolicyInput') rootPolicyInputRef!: ElementRef;
  @ViewChild('file') fileRef!: FileUploadComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    const policyFilter = {
      pageNumber: 0,
      fleetId: fleetId,
      policyClasses: [PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize,
      statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString()]
    } as IPolicySearchRequest;

    forkJoin([
      this.policySrv.search(policyFilter)
    ]).subscribe(([rootPolicies]) => {
      this.model.rootPolicies = rootPolicies.data;

      this.onChanges();

      this.uiBlockerSrv.unblock();

      this.rootPolicyInputRef.nativeElement.focus();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const id = this.model.rootPolicyId as string;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.policySrv.policyAssignation(id, request).pipe(
      tap((response) => {
        this.uiBlockerSrv.unblock();

        if (response && response.assignations && response.assignations.length > 0) {
          this.model.assignationResults = response.assignations;
        } else {
          const title = $localize`:@@app.policies.assignation.confirm.dialog.title:Asignación de pólizas`;
          const subtitle = $localize`:@@app.policies.assignation.confirm.dialog.subtitle:Finalizada correctamente, recuerde que no todos los cambios son efectivos de inmediato.`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reset());
        }
      })
    ).subscribe();
  }

  onGoBack(): void {
    this.reset();
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef.file
    });
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);
    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  get hasAssignationResults(): boolean {
    return this.model.assignationResults && this.model.assignationResults.length > 0;
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_CONCAT_POLICY_NUMBER).valueChanges.subscribe((val: boolean) => {
      if (val && val === true) {
        this.model.getControl(this.model.C_POLICY_NUMBER_SEPARATOR).enable();
      } else {
        this.model.getControl(this.model.C_POLICY_NUMBER_SEPARATOR).disable();
      }
    });
  }

  private reset(): void {
    this.model.form.reset();
    this.model.concatPolicyNumber = false;
    this.model.assignationResults = [];
    this.fileRef?.clearFiles();
  }
}
