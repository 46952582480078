<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title>
      <span class="title-text" i18n="@@app.units.plate.number.change.title">Cambio de matrícula</span>
      <br />
      <span class="subtitle-text">
        <div>
          <b i18n="@@app.units.plate.number.change.subtitle.header">ATENCIÓN</b>
        </div>
        <div i18n="@@app.units.plate.number.change.subtitle.body">
          Se actualizarán los datos de todos los vehículos/riesgos y pólizas asociadas a la matrícula <b>{{ model.originalPlateNumber }}.</b>
        </div>
      </span>
    </div>

    <mat-dialog-content>
      <mat-form-field>
        <mat-label i18n="@@app.units.plate.number.change.plateNumber.label">Indique la nueva matrícula del vehículo</mat-label>
        <input
          matInput
          [formControlName]="model.C_PLATE_NUMBER"
          [howdenControlError]="plateNumberError"
          i18n-placeholder="@@app.units.plate.number.change.plateNumber.placeholder"
          placeholder="Matrícula"
          maxlength="15"
        />
        <mat-error #plateNumberError></mat-error>
        @if (model.plateNumberControl.hasError('notAvailable')) {
          <mat-error
          i18n="@@app.units.plate.number.change.not.available.error">La matrícula indicada no está disponible.</mat-error>
        }
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
