import { IRisksComparedReportRequest } from 'src/app/core/models/api/reports';
import { RisksComparedReportViewModel } from '../models';

export abstract class RisksComparedReportMapper {
  static mapForGeneration(activeFleet: string, source: RisksComparedReportViewModel): IRisksComparedReportRequest {
    return {
      fleetId: activeFleet,
      startDate: source.startDate,
      endDate: source.endDate,
      insuranceCompanies: source.insuranceCompanies.filter(x => x.selected === true).map(x => x.id),
      policyClasses: source.policyClasses.filter(x => x.selected === true).map(x => x.id),
      bookingModes: source.bookingModes.filter(x => x.selected === true).map(x => x.id),
      vehicleTypes: source.vehicleTypes.filter(x => x.selected === true).map(x => x.id)
    } as IRisksComparedReportRequest;
  }
}
