<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.units.edit.plateNumber.label">Matrícula</mat-label>
        <input matInput [formControlName]="model.C_PLATE_NUMBER" />
      </mat-form-field>

      <howden-autocomplete
        #vehicleTypeSelectInput
        class="f-basis--4"
        i18n-label="@@app.units.edit.vehicleTypeId.label"
        label="Tipo de vehículo"
        displayField="description"
        keyField="erpId"
        [selectableItems]="model.vehicleTypes"
        [formControlName]="model.C_VEHICLE_TYPE_ID"
      >
      </howden-autocomplete>

      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.units.edit.vehicleUsage.label"
        label="Uso vehículo"
        displayField="description"
        keyField="erpId"
        [selectableItems]="model.vehicleUsages"
        [formControlName]="model.C_VEHICLE_USAGE_ID"
      >
      </howden-autocomplete>

    </div>

    <mat-tab-group (selectedTabChange)="onTabChanged($event)" dynamicHeight>
      <mat-tab label="Datos unidad" i18n-label="@@app.units.edit.pages.unit.label">
        <div class="flex f-gap--10px margin-top-10">
          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.edit.owner.label"
            label="Propietario"
            displayField="clientName"
            keyField="clientName"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_OWNER"
          >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.edit.userId.label"
            label="Usuario"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_USER_ID"
          >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.edit.lesseeId.label"
            label="Arrendatario"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_LESSEE_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.movementArea.label">Ámbito de circulación</mat-label>
            <mat-select [formControlName]="model.C_MOVEMENT_AREA" [howdenControlError]="movementAreaError">
              @for (item of model.movementAreas | howdenArraySort: 'description'; track item.movementAreaId) {
                <mat-option [value]="item.description">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #movementAreaError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.circulationArea.label">Recinto de circulación</mat-label>
            <mat-select [formControlName]="model.C_CIRCULATION_AREA_ID" [howdenControlError]="circulationAreaError">
              <mat-option></mat-option>
              @for (item of model.circulationAreas | howdenArraySort: 'description'; track item.circulationAreaId) {
                <mat-option [value]="item.circulationAreaId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #circulationAreaError></mat-error>
          </mat-form-field>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.functionalTypeId.label"
            label="Tipo funcional"
            displayField="description"
            keyField="functionalTypeId"
            [selectableItems]="model.functionalTypes"
            [formControlName]="model.C_FUNCTIONAL_TYPE_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.registrationClassification.label">Tipificación matrícula</mat-label>
            <mat-select [formControlName]="model.C_REGISTRATION_CLASSIFICATION" [howdenControlError]="registrationClassificationError">
              <mat-option></mat-option>
              <mat-option value="1" i18n="@@app.units.create.new.registrationClassification.plateNumber">Matrícula</mat-option>
              <mat-option value="2" i18n="@@app.units.create.new.registrationClassification.chassis">Bastidor</mat-option>
            </mat-select>
            <mat-error #registrationClassificationError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.registrationate.label">F. Matriculación</mat-label>
            <input
              matInput
              [matDatepicker]="registrationDatePicker"
              [formControlName]="model.C_REGISTRATION_DATE"
              [howdenControlError]="registrationDateError"
              [min]="minDate"
            />
            <mat-error #registrationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="registrationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #registrationDatePicker></mat-datepicker>
          </mat-form-field>

          <div class="f-basis--4">
            <button
              type="button"
              mat-raised-button
              color="accent"
              (click)="onGuessRegistrationDate()"
              i18n="@@app.units.edit.calc.date"
            >
              Calcular fecha
            </button>
          </div>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.brand.label">Marca</mat-label>
            <input matInput [formControlName]="model.C_BRAND" [howdenControlError]="brandError" maxlength="15" />
            <mat-error #brandError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.model.label">Modelo</mat-label>
            <input matInput [formControlName]="model.C_MODEL" [howdenControlError]="modelError" maxlength="50" />
            <mat-error #modelError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.version.label">Version</mat-label>
            <input matInput [formControlName]="model.C_VERSION" [howdenControlError]="versionError" maxlength="50" />
            <mat-error #versionError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.carNumber.label">Nº. Coche</mat-label>
            <input matInput [formControlName]="model.C_CAR_NUMBER" [howdenControlError]="carNumberError" maxlength="20" />
            <mat-error #carNumberError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.seatNumber.label">Plazas</mat-label>
            <input matInput [formControlName]="model.C_SEAT_NUMBER" [howdenControlError]="seatNumberError" maxlength="20" />
            <mat-error #seatNumberError></mat-error>
          </mat-form-field>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_SEATS"
            [precision]="0"
            i18n-label="@@app.units.edit.seats.label"
            label="Total plazas"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_VALUE"
            i18n-label="@app.units.edit.value.label"
            label="Valor"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_VALUE_EXTRAS"
            i18n.label="@@app.units.edit.valueExtras.label"
            label="Valor extras"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_MAX_ADDMITTED_WEIGHT"
            [precision]="0"
            i18n-label="@@app.units.edit.maxAddmittedWeight.label"
            label="PMA"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.chasisNumber.label">Nº. de chasis</mat-label>
            <input matInput [formControlName]="model.C_CHASIS_NUMBER" [howdenControlError]="chasisNumberError" maxlength="30" />
            <mat-error #chasisNumberError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.goodsTypeId.label">Tipo de mercancía</mat-label>
            <mat-select [formControlName]="model.C_GOODS_TYPE_ID" [howdenControlError]="goodsTypeError">
              <mat-option></mat-option>
              @for (item of model.goodsTypes | howdenArraySort: 'description'; track item.goodTypeId) {
                <mat-option [value]="item.goodTypeId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #goodsTypeError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.bonusGroup.label">Grupo primas</mat-label>
            <input matInput [formControlName]="model.C_BONUS_GROUP" [howdenControlError]="bonusGroupError" maxlength="10" />
            <mat-error #bonusGroupError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.classificationCodeCL.label">Código clasficación CL</mat-label>
            <input matInput [formControlName]="model.C_CLASSIFICATION_CODE_CL" [howdenControlError]="classificationCodeCLError" maxlength="10" />
            <mat-error #classificationCodeCLError></mat-error>
          </mat-form-field>

          <div class="f-basis--4"></div>
          <div class="f-basis--4"></div>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.units.edit.comments.label">Observaciones</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_OBSERVATIONS"
              [howdenControlError]="observationsError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="15"
            >
            </textarea>
            <mat-error #observationsError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.units.edit.sections.renting">Datos renting</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.rentingCompanyFiscalCode.label">Empresa renting</mat-label>
            <input
              matInput
              [formControlName]="model.C_RENTING_COMPANY_FISCAL_CODE"
              [howdenControlError]="rentingCompanyFiscalCodeError"
              maxlength="15"
            />
            <mat-error #rentingCompanyFiscalCodeError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.rentingStartDate.label">Fecha inicio renting</mat-label>
            <input
              matInput
              [matDatepicker]="rentingStartDatePicker"
              [formControlName]="model.C_RENTING_START_DATE"
              [howdenControlError]="rentingStartDatePickerError"
              [min]="minDate"
            />
            <mat-error #rentingStartDatePickerError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="rentingStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #rentingStartDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.rentingExpirationDate.label">Fecha vencimiento renting</mat-label>
            <input
              matInput
              [matDatepicker]="rentingExpirationDatePicker"
              [formControlName]="model.C_RENTING_EXPIRATION_DATE"
              [howdenControlError]="rentingExpirationDatePickerError"
              [min]="minDate"
            />
            <mat-error #rentingExpirationDatePickerError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="rentingExpirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #rentingExpirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.units.edit.sections.misc">Otros datos</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.division.label">División</mat-label>
            <input matInput [formControlName]="model.C_DIVISION" [howdenControlError]="divisionError" maxlength="30" />
            <mat-error #divisionError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.zone.label">Zona</mat-label>
            <input matInput [formControlName]="model.C_ZONE" [howdenControlError]="zoneError" maxlength="30" />
            <mat-error #zoneError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.userZone.label">Zona usuario</mat-label>
            <input matInput [formControlName]="model.C_USER_ZONE" [howdenControlError]="userZoneError" maxlength="30" />
            <mat-error #userZoneError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_POWER"
            [precision]="0"
            i18n-label="@@app.units.edit.power.label"
            label="Potencia"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_LENGTH"
            i18n-label="@@app.units.edit.length.label"
            label="Logitud"
          ></howden-decimal>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.contract.label">Contrato</mat-label>
            <input matInput [formControlName]="model.C_CONTRACT" [howdenControlError]="contractError" maxlength="30" />
            <mat-error #contractError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_AGE"
            i18n-label="@@app.units.edit.age.label"
            label="Edad"
          ></howden-decimal>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.manufacturingMonth.label">Mes fabricación</mat-label>
            <mat-select [formControlName]="model.C_MANUFACTURING_MONTH" [howdenControlError]="manufacturingMonthError">
              <mat-option></mat-option>
              @for (item of months(); track item; let i = $index) {
                <mat-option [value]="i + 1">
                  {{ item }}
                </mat-option>
              }
            </mat-select>

            <mat-error #manufacturingMonthError></mat-error>
          </mat-form-field>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_KILOMETERS"
            [precision]="0"
            i18n-label="@@app.units.edit.kilometers.label"
            label="Kilométros año"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.category.label">Categoría</mat-label>
            <input matInput [formControlName]="model.C_CATEGORY" [howdenControlError]="categoryError" maxlength="30" />
            <mat-error #categoryError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.class.label">Clase</mat-label>
            <input matInput [formControlName]="model.C_CLASS" [howdenControlError]="classError" maxlength="20" />
            <mat-error #classError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.euroClass.label">Clase euro</mat-label>
            <input matInput [formControlName]="model.C_EUROCLASS" [howdenControlError]="euroClassError" maxlength="20" />
            <mat-error #euroClassError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.workshop.label">Taller</mat-label>
            <input matInput [formControlName]="model.C_WORKSHOP" [howdenControlError]="workshopError" maxlength="30" />
            <mat-error #workshopError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.bodyWork.label">Carrocería</mat-label>
            <input matInput [formControlName]="model.C_BODY_WORK" [howdenControlError]="bodyWorkError" maxlength="30" />
            <mat-error #bodyWorkError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.bodyWorkNumber.label">Nº. Carrocería</mat-label>
            <input matInput [formControlName]="model.C_BODY_WORK_NUMBER" [howdenControlError]="bodyWorkNumberError" maxlength="30" />
            <mat-error #bodyWorkNumberError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle class="f-basis--4" [formControlName]="model.C_TD" i18n="@@app.units.edit.td.label">TD</mat-slide-toggle>

          <mat-slide-toggle class="f-basis--4" [formControlName]="model.C_CONTRACTED_MAINTENANCE" i18n="@@app.units.edit.mtocdto.label">MTO_CTDO</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.platform.label">Plataforma</mat-label>
            <input matInput [formControlName]="model.C_PLATFORM" [howdenControlError]="platformError" maxlength="15" />
            <mat-error #platformError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_SCHOOL_BUS"
            i18n="@@app.units.edit.schoolBus.label">Autobús escolar</mat-slide-toggle>

          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_WIFI"
            i18n="@@app.units.edit.wifi.label">WIFI</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.wifiName.label">Nombre WIFI</mat-label>
            <input matInput [formControlName]="model.C_WIFI_NAME" [howdenControlError]="wifiNameError" maxlength="30" />
            <mat-error #wifiNameError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_ENTERTAIMENT_BUS"
            i18n="@@app.units.edit.entertaimentBus.label">Ocio bus</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.busType.label">Tipo de autobús</mat-label>
            <input matInput [formControlName]="model.C_BUS_TYPE" [howdenControlError]="busTypeError" maxlength="30" />
            <mat-error #busTypeError></mat-error>
          </mat-form-field>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_BUS_NUMBER"
            [precision]="0"
            i18n-label="@@app.units.edit.busNumber.label"
            label="Nº. Autobús"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_INDIVIDUAL_ENTERTAIMENT"
            i18n="@@app.units.edit.individualEntertaiment.label">Ocio individual</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.configuration.label">Configuración</mat-label>
            <input matInput [formControlName]="model.C_CONFIGURATION" [howdenControlError]="schoolBusError" maxlength="30" />
            <mat-error #schoolBusError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.cameras.label">Cámaras</mat-label>
            <input matInput [formControlName]="model.C_CAMERAS" [howdenControlError]="camerasError" maxlength="30" />
            <mat-error #camerasError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.safeBelts.label">Cinturones de seguridad</mat-label>
            <input matInput [formControlName]="model.C_SAFE_BELTS" [howdenControlError]="safeBeltsError" maxlength="15" />
            <mat-error #safeBeltsError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.pmrsr.label">PMRSR</mat-label>
            <input matInput [formControlName]="model.C_PMRSR" [howdenControlError]="pmrsrError" maxlength="15" />
            <mat-error #pmrsrError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.edit.concession.label">Concesión</mat-label>
            <input matInput [formControlName]="model.C_CONCESSION" [howdenControlError]="concessionError" maxlength="100" />
            <mat-error #concessionError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
          <button
            type="button"
            class="f-as--start"
            mat-raised-button
            (click)="onUnitFleetTransfer()"
            [disabled]="!model.allowFleetTransfer"
            i18n="@@app.units.edit.actions.fleet.change"
          >
            Cambio de flota
          </button>

          <button
            type="button"
            class="f-as--start"
            mat-raised-button
            (click)="onUnitPlateNumberChange()"
            [disabled]="!model.allowPlateNumberChange"
            i18n="@@app.units.edit.actions.plateNumber.change"
          >
            Cambiar matrícula
          </button>

          <mat-divider [vertical]="true"></mat-divider>

          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
            <span i18n="@@app.core.actions.save">Guardar</span>
          </button>
        </div>
      </mat-tab>

      <mat-tab label="Pólizas" i18n-label="@@app.units.edit.pages.policies.label">
        <ng-template matTabContent>
          <howden-unit-edit-policies [unitId]="model.unitId"></howden-unit-edit-policies>
        </ng-template>
      </mat-tab>

      <mat-tab label="Siniestros" i18n-label="@@app.units.edit.pages.claims.label">
        <ng-template matTabContent>
          <howden-claim-list [claims]="model.claims"></howden-claim-list>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
