import { ICreateBookingMode } from 'src/app/core/models/api/booking-modes';
import { CreateBookingModeViewModel } from '../models';

export abstract class CreateBookingModeMapper {
  static mapForUpdate(fleetId: string, source: CreateBookingModeViewModel): ICreateBookingMode {
    return {
      fleetId: fleetId,
      insuranceCompanyId: source.insuranceCompanyId,
      industryId: source.industryId,
      productId: source.productId,
      description: source.description
    } as ICreateBookingMode;
  }
}
