<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <!--Filters-->

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--2"
        [formControlName]="model.C_YEAR"
        [allowNegative]="false"
        [precision]="0"
        i18n-label="@@app.dashboards.initial.state.year.label"
        label="Año"
      ></howden-decimal>

      <div class="mat-form-field inline">
        <div class="mat-form-field-wrapper">
          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onFilter()">
            <span i18n="@@app.core.actions.accept">Aceptar</span>
          </button>
        </div>
      </div>
    </div>
  </form>

  <mat-divider class="margin-bottom-10"></mat-divider>

  <!--Header-->

  @if (model.fleet) {
    <howden-dashboard-header
      [fleetName]="model.fleet.name"
      [clientName]="model.fleet.clientName"
      [clientFiscalCode]="model.fleet.clientFiscalCode"
      [cnaeDescription]="model.fleet.cnaeDescription"
    >
    </howden-dashboard-header>
  }

  <!--Dashboard-->

  @if (model.dashboardData) {
    <mat-grid-list cols="{{(cardLayout | async)?.columns }}" rowHeight="200px">

      <!--Charts-->

      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Unidades por póliza'"
          i18n-title="@@app.dashboards.initial.state.units.by.policy.chart.title"
          [subtitle]="'Nº. de unidades (únicas) por póliza'"
          i18n-subtitle="@@app.dashboards.initial.state.units.by.policy.chart.subtitle"
        >
          @if (model.dashboardData.unitsByPolicy) {
            <howden-dashboard-bar-chart [data]="model.dashboardData.unitsByPolicy"></howden-dashboard-bar-chart>
          }
        </howden-dashboard-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
        <howden-dashboard-card
          [title]="'Valoración por póliza'"
          i18n-title="@@app.dashboards.initial.state.assessment.by.policy.chart.title"
          [subtitle]="'Valoración de la foto inicial por póliza'"
          i18n-subtitle="@@app.dashboards.initial.state.assessment.by.policy.chart.subtitle"
        >
          @if (model.dashboardData.assessmentByPolicy) {
            <howden-dashboard-bar-chart [data]="model.dashboardData.assessmentByPolicy"></howden-dashboard-bar-chart>
          }
        </howden-dashboard-card>
      </mat-grid-tile>

      <!--Table-->

      <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
        <howden-dashboard-card [title]="'Foto inicial'" i18n-title="@@app.dashboards.initial.state.table.title">
          <howden-table
            class="small-font"
            keyField="receiptId"
            paginable="local"
            sortable="local"
            filterable="global-local"
            [pageSize]="10"
            [cols]="model.columns"
            [data]="model.dashboardData.details"
            [buttons]="model.buttons"
          >
          </howden-table>
          <div class="flex f-gap--10px f-jc--center margin-bottom-10">
            <button type="button" mat-raised-button color="primary" (click)="onTableExport()"  [disabled]="!canExport">
              <span i18n="@@app.core.actions.export">Exportar</span>
            </button>
          </div>
        </howden-dashboard-card>
      </mat-grid-tile>
    </mat-grid-list>
  }
</div>
