<div class="flex f-gap--10px f-ai--center margin-top-10">
  <div class="f-basis--6">
    <form [formGroup]="model.form">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.edit.billing.groups.billingGroupId.label">Grupo de facturación</mat-label>
        <input
          matInput
          [formControlName]="model.C_BILLING_GROUP_ID"
          [matAutocomplete]="billingGroupSelect"
          [howdenControlError]="billingGroupError"
        />
        <mat-autocomplete #billingGroupSelect="matAutocomplete" [displayWith]="getBillingGroupDescription.bind(this)">
          @for (item of model.filteredBillingGroups | async; track item.billingGroupId) {
            <mat-option [value]="item.billingGroupId">
              {{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #billingGroupError></mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="f-basis--5">
    <div class="flex f-ai--top f-gap--10px margin-bottom-10">
      <button
        type="button"
        mat-mini-fab
        color="primary"
        matTooltip="Nuevo grupo"
        i18n-matTooltip="@@app.policies.edit.billing.groups.actions.create"
        (click)="onNewBillingGroup()"
      >
        <mat-icon>add</mat-icon>
      </button>

      <button
        type="button"
        mat-mini-fab
        color="secondary"
        matTooltip="Modificar grupo"
        i18n-matTooltip="@@app.policies.edit.billing.groups.actions.edit"
        (click)="onEditBillingGroup()"
        [disabled]="!hasValidSelection"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        type="button"
        mat-mini-fab
        color="cancel"
        matTooltip="Borrar grupo"
        i18n-matTooltip="@@app.policies.edit.billing.groups.actions.delete"
        (click)="onDeleteBillingGroup()"
        [disabled]="!hasValidSelection"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <mat-divider vertical="true"></mat-divider>

      <button
        type="button"
        class="f-as--end"
        mat-mini-fab
        matTooltip="Nueva regla"
        i18n-matTooltip="@@app.policies.edit.billing.groups.actions.create.rule"
        (click)="onNewBillingGroupRule()"
        [disabled]="!hasValidSelection"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

@if (model.billingGroupId && model.rules.length > 0) {
  <div class="flex f-gap--10px">
    <h4 i18n-matTooltip="@@app.policies.edit.billing.groups.sections rules">Reglas</h4>
  </div>

  <div class="flex">
    <howden-table
      class="small-font"
      keyField="ruleId"
      paginable="local"
      sortable="local"
      [pageSize]="10"
      [cols]="model.columns"
      [data]="model.rules"
      [buttons]="model.buttons"
      (buttonClicked)="onRuleAction($event)"
    >
    </howden-table>
  </div>
}
