<form [formGroup]="model.form">
  <div class="flex f-gap--10px margin-top-10">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.description.label">Denominación</mat-label>
      <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
      <mat-error #descriptionError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.collectionManagerId.label">Gestor de cobro</mat-label>
      <input
        matInput
        [formControlName]="model.C_COLLECTION_MANAGER_ID"
        [matAutocomplete]="collectionManagerSelect"
        [howdenControlError]="collectionManagerIdError"
      />
      <mat-autocomplete #collectionManagerSelect="matAutocomplete" [displayWith]="getCollectionManagerName.bind(this)">
        @for (item of model.filteredCollectionManagers | async; track item.collectionManagerId) {
          <mat-option [value]="item.collectionManagerId">
            {{ item.alias }}-{{ item.name }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error #collectionManagerIdError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.currencyId.label">Divisa</mat-label>
      <input
        matInput
        [formControlName]="model.C_CURRENCY_ID"
        [matAutocomplete]="currencySelect"
        [howdenControlError]="currencyIdError"
      />
      <mat-autocomplete #currencySelect="matAutocomplete" [displayWith]="getCurrencyDescription.bind(this)">
        @for (item of model.filteredCurrencies | async; track item.currencyId) {
          <mat-option [value]="item.currencyId">
            {{ item.description }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error #currencyIdError></mat-error>
    </mat-form-field>
  </div>

  <div class="flex f-gap--10px">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.insuranceCompanyId.label">Aseguradora</mat-label>
      <input
        matInput
        [formControlName]="model.C_INSURANCE_COMPANY_ID"
        [matAutocomplete]="insuranceCompanySelect"
        [howdenControlError]="insuranceCompanyIdError"
      />
      <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
        @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
          <mat-option [value]="item.insuranceCompanyId">
            {{ item.alias }}-{{ item.name }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error #insuranceCompanyIdError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.industryId.label">Ramo</mat-label>
      <input
        matInput
        [formControlName]="model.C_INDUSTRY_ID"
        [matAutocomplete]="industrySelect"
        [howdenControlError]="industryIdError"
      />
      <mat-autocomplete #industrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
        @for (item of model.filteredIndustries | async; track item.industryId) {
          <mat-option [value]="item.industryId">
            {{ item.alias }}-{{ item.name }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error #industryIdError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.productId.label">Producto</mat-label>
      <input
        matInput
        [formControlName]="model.C_PRODUCT_ID"
        [matAutocomplete]="productSelect"
        [howdenControlError]="productIdError"
      />
      <mat-autocomplete #productSelect="matAutocomplete" [displayWith]="getProductName.bind(this)">
        @for (item of model.filteredProducts | async; track item.productId) {
          <mat-option [value]="item.productId">
            {{ item.alias }}-{{ item.name }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error #productIdError></mat-error>
    </mat-form-field>
  </div>

  <div class="flex f-gap--10px">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.firstEffectDate.label">Fecha primer efecto</mat-label>
      <input
        matInput
        [matDatepicker]="firstEffectDatePicker"
        [formControlName]="model.C_FIRST_EFFECT_DATE"
        [howdenControlError]="firstEffectDateError"
        [min]="minDate"
      />
      <mat-error #firstEffectDateError></mat-error>
      <mat-datepicker-toggle matSuffix [for]="firstEffectDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #firstEffectDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.effectDate.label">Fecha efecto</mat-label>
      <input
        matInput
        [matDatepicker]="effectDatePicker"
        [formControlName]="model.C_EFFECT_DATE"
        [howdenControlError]="effectDateError"
        [min]="minDate"
      />
      <mat-error #effectDateError></mat-error>
      <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #effectDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.expirationDate.label">Fecha vencimiento</mat-label>
      <input
        matInput
        [matDatepicker]="expirationDatePicker"
        [formControlName]="model.C_EXPIRATION_DATE"
        [howdenControlError]="expirationDateError"
        [min]="minDate"
      />
      <mat-error #expirationDateError></mat-error>
      <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expirationDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="flex f-gap--10px">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.mediationCodeId.label">Código mediación</mat-label>
      <input
        matInput
        [formControlName]="model.C_MEDIATION_CODE_ID"
        [matAutocomplete]="mediationCodeSelect"
        [howdenControlError]="mediationCodeIdError"
      />
      <mat-autocomplete #mediationCodeSelect="matAutocomplete" [displayWith]="getMediationCodeDescription.bind(this)">
        @for (item of model.filteredMediationCodes | async; track item.mediationCodeId) {
          <mat-option [value]="item.mediationCodeId">
            {{ item.code }}-{{ item.description }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error #mediationCodeIdError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.create.root.duration.label">Duración</mat-label>
      <mat-select [formControlName]="model.C_DURATION" [howdenControlError]="durationError">
        @for (item of model.policyDurations | howdenArraySort: 'description'; track item.erpId) {
          <mat-option [value]="item.erpId">
            {{ item.description }}
          </mat-option>
        }
      </mat-select>
      <mat-error #durationError></mat-error>
    </mat-form-field>

    @if (model.canEditCommissionRate) {
      <howden-decimal
        class="f-basis--4"
        [formControlName]="model.C_COMMISSION_RATE"
        i18n-label="@@app.policies.edit.commissionRate.label"
        label="Comisión"
      ></howden-decimal>
    } @else {
      <div class="f-basis--4"></div>
    }
  </div>

  <div class="flex f-gap--10px">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.paymentTypeId.label">Forma de pago</mat-label>
      <mat-select [formControlName]="model.C_PAYMENT_TYPE_ID" [howdenControlError]="paymentTypeIdError">
        @for (item of model.paymentTypes | howdenArraySort: 'description'; track item.erpId) {
          <mat-option [value]="item.erpId">
            {{ item.description }}
          </mat-option>
        }
      </mat-select>
      <mat-error #paymentTypeIdError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.billingModelId.label">Modelo facturación</mat-label>
      <mat-select [formControlName]="model.C_BILLING_MODEL_ID" [howdenControlError]="billingModelIdError">
        @for (item of model.billingModels | howdenArraySort: 'description'; track item.billingModelId) {
          <mat-option [value]="item.billingModelId">
            {{ item.description }}
          </mat-option>
        }
      </mat-select>
      <mat-error #billingModelIdError></mat-error>
    </mat-form-field>

    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.regularizationPeriodId.label">Periodo reg.</mat-label>
      <mat-select [formControlName]="model.C_REGULARIZATION_PERIOD_ID" [howdenControlError]="regularizationPeriodId">
        @for (item of model.regularizationPeriods | howdenArraySort: 'description'; track item.erpId) {
          <mat-option [value]="item.erpId">
            {{ item.description }}
          </mat-option>
        }
      </mat-select>
      <mat-error #regularizationPeriodId></mat-error>
    </mat-form-field>
  </div>

  @if (model.isRoot) {
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.edit.receiptsOn.label">Recibos en</mat-label>
        <mat-select [formControlName]="model.C_RECEIPTS_ON" [howdenControlError]="receiptTargetError">
          @for (item of model.receiptTargets | howdenArraySort: 'description'; track item.receiptTargetId) {
            <mat-option [value]="item.receiptTargetId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #receiptTargetError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.edit.rootPolicyUpdateMode.label">Modo actualización</mat-label>
        <mat-select [formControlName]="model.C_ROOT_POLICY_UPDATE_MODE" [howdenControlError]="rootPolicyUpdateModeError">
          <mat-option value="1" i18n="@@app.policies.edit.rootPolicyUpdateMode.1.label">Póliza madre</mat-option>
          <mat-option value="2" i18n="@@app.policies.edit.rootPolicyUpdateMode.2.label">Póliza madre + hijas</mat-option>
        </mat-select>
        <mat-error #rootPolicyUpdateModeError></mat-error>
      </mat-form-field>

      @if (model.showIndividualBonusGrouping) {
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.individualBonusGrouping.label">Agrupación primas individuales</mat-label>
          <input
            matInput
            [formControlName]="model.C_INDIVIDUAL_BONUS_GROUPING"
            [howdenControlError]="individualBonusGroupingError"
            maxlength="100"
          />
          <mat-error #individualBonusGroupingError></mat-error>
        </mat-form-field>
      } @else {
        <div class="f-basis--4"></div>
      }
    </div>
  }

  <div class="flex f-jc--spacearound f-w--wrap f-gap--5px">
    @if (model.canAssignNumber) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.update.policy.number"
        [disabled]="!model.policyId"
        (click)="onUpdatePolicyNumber()"
      >
        Asignar número
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canAddNewEndorsement) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.create.endorsement"
        [disabled]="!model.policyId"
        (click)="onCreateEndorsement()"
      >
        Nuevo suplemento
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canUpdateHolder) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.update.holder"
        [disabled]="!model.policyId"
        (click)="onUpdateHolder()"
      >
        Cambio tomador
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canUpdateOffice) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.update.office"
        [disabled]="!model.policyId"
        (click)="onUpdateOffice()"
      >
        Cambio oficina
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canReplace) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.replacement"
        [disabled]="!model.policyId"
        (click)="onReplacement()"
      >
        Reemplazo
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canCancelReplacement) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.cancel.replacement"
        [disabled]="!model.policyId"
        (click)="onCancelReplacement()"
      >
        Anular reemplazo
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canChangeRisk) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.change.risk"
        [disabled]="!model.policyId"
        (click)="onChangeRisk()"
      >
        Cambio riesgo
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canCancel) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.cancel"
        [disabled]="!model.policyId"
        (click)="onCancel()"
      >
        Dar de baja
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canReactivate) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.reactivate"
        [disabled]="!model.policyId"
        (click)="onReactivate()"
      >
        Reactivar
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canDelete) {
      <button
        type="button"
        mat-button mat-stroked-button
        color="cancel"
        i18n="@@app.policies.edit.actions.delete"
        [disabled]="!model.policyId"
        (click)="onDelete()"
      >
        Borrar
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canUpdateBonusType) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.edit.actions.update.bonus.type"
        [disabled]="!model.policyId"
        (click)="onUpdateBonusType()"
      >
        Cambiar tipo de prima
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    @if (model.canShowRootPolicyInfo) {
      <button
        type="button"
        mat-button mat-stroked-button
        i18n="@@app.policies.actions.root.policy"
        [disabled]="!model.policyId"
        (click)="onNavigateToRootPolicy()"
      >
        P. Madre
      </button>
      <mat-divider [vertical]="true"></mat-divider>
    }

    <button
      type="button"
      mat-button mat-stroked-button
      i18n="@@app.policies.edit.actions.contract"
      [disabled]="!model.policyId"
      (click)="onShowContract()"
    >
      Contrato
    </button>
    <mat-divider [vertical]="true"></mat-divider>

    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
      <span i18n="@@app.core.actions.save">Guardar</span>
    </button>
  </div>
</form>
