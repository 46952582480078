import { IStatistic } from 'src/app/core/models/api/core';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';

export class GeneralDashboardViewModel {
  private _unitsByVehicleType: Array<IStatistic> = [];
  private _policiesByInsuranceCompany: Array<IStatistic> = [];
  private _policiesByOffice: Array<IStatistic> = [];
  private _policiesByPaymentType: Array<IStatistic> = [];

  miniCardData: Array<IMiniCardData> = [];

  get unitsByVehicleType(): Array<IStatistic> {
    return this._unitsByVehicleType;
  }
  set unitsByVehicleType(value: Array<IStatistic>) {
    this._unitsByVehicleType = value;
  }

  get policiesByInsuranceCompany(): Array<IStatistic> {
    return this._policiesByInsuranceCompany;
  }
  set policiesByInsuranceCompany(value: Array<IStatistic>) {
    this._policiesByInsuranceCompany = value;
  }

  get policiesByOffice(): Array<IStatistic> {
    return this._policiesByOffice;
  }
  set policiesByOffice(value: Array<IStatistic>) {
    this._policiesByOffice = value;
  }

  get policiesByPaymentType(): Array<IStatistic> {
    return this._policiesByPaymentType;
  }
  set policiesByPaymentType(value: Array<IStatistic>) {
    this._policiesByPaymentType = value;
  }
}
