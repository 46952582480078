<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.endorsements.create.title">Alta de suplementos</div>

    <mat-dialog-content>
      <mat-stepper #stepper linear>

        <mat-step>

          <ng-template matStepLabel i18n="@app.endorsements.create.sections.endorsement">Suplemento</ng-template>

          <div class="flex f-gap--10px margin-bottom-10 margin-top-10">
            <mat-slide-toggle
              [formControlName]="model.C_ENDORSEMENT_CREATES_RECEIPT"
              i18n="@@app.endorsements.create.createsReceipt.label">Genera Recibo</mat-slide-toggle>
          </div>

          <div class="flex f-gap--10px">
            <howden-autocomplete
              class="f-basis--6"
              i18n-label="@@app.endorsements.create.endorsementType.label"
              label="Tipo"
              displayField="description"
              keyField="erpId"
              [selectableItems]="model.endorsementTypes"
              [formControlName]="model.C_ENDORSEMENT_TYPE_ID"
            >
            </howden-autocomplete>

            <howden-autocomplete
              class="f-basis--6"
              i18n-label="@@app.endorsements.create.endorsementStatus.label"
              label="Estado"
              displayField="description"
              keyField="erpId"
              [selectableItems]="model.endorsementStatuses"
              [formControlName]="model.C_ENDORSEMENT_STATUS_ID"
            >
            </howden-autocomplete>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.endorsement.references">Referencias</h4>
          </div>

          <div class="flex f-gap--10px">

            <mat-form-field class="f-basis--6">
              <mat-label i18n="@@app.endorsements.create.endorsementAlias.label">Propia</mat-label>
              <input
                matInput
                [formControlName]="model.C_ENDORSEMENT_ALIAS"
                [howdenControlError]="endorsementAliasError"
                maxlength="20"
              />
              <mat-error #endorsementAliasError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--6">
              <mat-label i18n="@@app.endorsements.create.endorsementInsuranceCompanyReference.label">Ref. Aseguradora</mat-label>
              <input
                matInput
                [formControlName]="model.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE"
                [howdenControlError]="endorsementInsuranceCompanyReferenceError"
                maxlength="20"
              />
              <mat-error #endorsementInsuranceCompanyReferenceError></mat-error>
            </mat-form-field>

          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.endorsement.dates">Fechas</h4>
          </div>

          <div class="flex f-gap--10px">

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.endorsementRequestDate.label">Fecha solicitud</mat-label>
              <input
                matInput
                [matDatepicker]="endorsementRequestDatePicker"
                [formControlName]="model.C_ENDORSEMENT_REQUEST_DATE"
                [howdenControlError]="endorsementRequestDateError"
                [min]="minDate"
              />
              <mat-error #endorsementRequestDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="endorsementRequestDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endorsementRequestDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.endorsementDateSentToInsuranceCompany.label">Fecha de envío a cia.</mat-label>
              <input
                matInput
                [matDatepicker]="dateSentToInsuranceCompanyPicker"
                [formControlName]="model.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY"
                [howdenControlError]="dateSentToInsuranceCompanyError"
                [min]="minDate"
              />
              <mat-error #dateSentToInsuranceCompanyError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="dateSentToInsuranceCompanyPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateSentToInsuranceCompanyPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.endorsementReceiptionDate.label">Fecha recepción</mat-label>
              <input
                matInput
                [matDatepicker]="endosementReceptionDatePicker"
                [formControlName]="model.C_ENDORSEMENT_RECEPTION_DATE"
                [howdenControlError]="endosementReceptionDateError"
                [min]="minDate"
              />
              <mat-error #endosementReceptionDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="endosementReceptionDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endosementReceptionDatePicker></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="flex f-gap--10px">

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.endorsementEffectDate.label">Fecha efecto</mat-label>
              <input
                matInput
                [matDatepicker]="endorsementEffectDatePicker"
                [formControlName]="model.C_ENDORSEMENT_EFFECT_DATE"
                [howdenControlError]="endorsementEffectDateError"
                [min]="minDate"
              />
              <mat-error #endorsementEffectDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="endorsementEffectDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endorsementEffectDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.endorsementExpirationDate.label">Fecha vencimiento</mat-label>
              <input
                matInput
                [matDatepicker]="endorsementExpirationDatePicker"
                [formControlName]="model.C_ENDORSEMENT_EXPIRATION_DATE"
                [howdenControlError]="endorsementExpirationDateError"
                [min]="minDate"
              />
              <mat-error #endorsementExpirationDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="endorsementExpirationDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endorsementExpirationDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.endorsementCancellationDate.label">Fecha baja</mat-label>
              <input
                matInput
                [matDatepicker]="endosementExpirationDatePicker"
                [formControlName]="model.C_ENDORSEMENT_CANCELLATION_DATE"
                [howdenControlError]="endosementExpirationDateError"
                [min]="minDate"
              />
              <mat-error #endosementExpirationDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="endosementExpirationDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endosementExpirationDatePicker></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.endorsement.observations">Observaciones</h4>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field>
              <mat-label i18n="@@app.endorsements.create.endorsementDescription.label">Descripción</mat-label>
              <input
                matInput
                [formControlName]="model.C_ENDORSEMENT_DESCRIPTION"
                [howdenControlError]="endorsementDescriptionError"
                maxlength="255"
              />
              <mat-error #endorsementDescriptionError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field>
              <mat-label i18n="@@app.endorsements.create.endorsementObservations.label">Observaciones</mat-label>
              <textarea
                matInput
                [formControlName]="model.C_ENDORSEMENT_OBSERVATIONS"
                [howdenControlError]="endorsementObservationsError"
                cdkTextareaAutosize
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="15"
              >
              </textarea>
              <mat-error #endorsementObservationsError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.endorsement.changes">Descripción cambios</h4>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field>
              <mat-label i18n="@@app.endorsements.create.endorsementOldInfo.label">Información anterior</mat-label>
              <textarea
                matInput
                [formControlName]="model.C_ENDORSEMENT_OLD_INFO"
                [howdenControlError]="endorsementOldInfoError"
                cdkTextareaAutosize
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="15"
                maxlength="255"
              >
              </textarea>
              <mat-error #endorsementOldInfoError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field>
              <mat-label i18n="@@app.endorsements.create.endorsementNewInfo.label">Información nueva</mat-label>
              <textarea
                matInput
                [formControlName]="model.C_ENDORSEMENT_NEW_INFO"
                [howdenControlError]="endorsementNewInfoError"
                cdkTextareaAutosize
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="15"
                maxlength="255"
              >
              </textarea>
              <mat-error #endorsementNewInfoError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-gap--10px">

            <div class="f-basis--1">
            </div>

            <div class="f-basis--10">
              <div class="flex f-jc--center">
                <button type="button" mat-raised-button (click)="onCancel()">
                  <span i18n="@@app.core.actions.cancel">Cancelar</span>
                </button>
              </div>
            </div>

            <div class="f-basis--1">
              <button
                type="button"
                mat-mini-fab matStepperNext
                matTooltip="Siguiente"
                i18n-matTooltip="@@app.core.stepper.actions.next"
              >
                <mat-icon>navigate_next</mat-icon>
              </button>
            </div>
          </div>

        </mat-step>

        <mat-step>

          <ng-template matStepLabel i18n="@app.enorsement.create.sections.receipt">Recibo</ng-template>

          <div class="flex f-gap--10px">
            <mat-form-field>
              <mat-label i18n="@@app.endorsements.create.receiptConcept.label">Concepto</mat-label>
              <input
                matInput
                [formControlName]="model.C_RECEIPT_CONCEPT"
                [howdenControlError]="receiptConceptError"
                maxlength="255"
              />
              <mat-error #receiptConceptError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-gap--10px">
            <howden-autocomplete
              class="f-basis--4"
              i18n-label="@@app.endorsements.create.receiptType.label"
              label="Tipo"
              displayField="description"
              keyField="erpId"
              [selectableItems]="model.receiptTypes"
              [formControlName]="model.C_RECEIPT_TYPE_ID"
            >
            </howden-autocomplete>

            <howden-autocomplete
              class="f-basis--4"
              i18n-label="@@app.endorsements.create.receiptSituation.label"
              label="Situación"
              displayField="description"
              keyField="erpId"
              [selectableItems]="model.receiptSituations"
              [formControlName]="model.C_RECEIPT_SITUATION_ID"
            >
            </howden-autocomplete>

            <howden-autocomplete
              class="f-basis--4"
              i18n-label="@@app.endorsements.create.receiptCurrency.label"
              label="Divisa"
              displayField="description"
              keyField="currencyId"
              [selectableItems]="model.currencies"
              [formControlName]="model.C_RECEIPT_CURRENCY_ID"
            >
            </howden-autocomplete>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.receiptNumber.label">Número recibo</mat-label>
              <input
                matInput
                [formControlName]="model.C_RECEIPT_NUMBER"
                [howdenControlError]="receiptNumberError"
                maxlength="10"
              />
              <mat-error #receiptNumberError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.receiptInsuranceCompanyReceiptNumber.label">Número recibo aseguradora</mat-label>
              <input
                matInput
                [formControlName]="model.C_RECEIPT_INSURANCE_COMPANY_RECEIPT_NUMBER"
                [howdenControlError]="insuranceCompanyReceiptNumberError"
                maxlength="30"
              />
              <mat-error #insuranceCompanyReceiptNumberError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.endorsements.create.receiptPaymentType.label">Forma de pago</mat-label>
              <input
                matInput
                [formControlName]="model.C_RECEIPT_PAYMENT_TYPE"
                [howdenControlError]="receiptPaymentTypeError"
                maxlength="30"
              />
              <mat-error #receiptPaymentTypeError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.receipt.dates">Fechas</h4>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptBrokerIssueDate.label">Fecha emisión</mat-label>
              <input
                matInput
                [matDatepicker]="receiptBrokerIssueDatePicker"
                [formControlName]="model.C_RECEIPT_BROKER_ISSUE_DATE"
                [howdenControlError]="receiptBrokerIssueDateError"
                [min]="minDate"
              />
              <mat-error #receiptBrokerIssueDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptBrokerIssueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptBrokerIssueDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptInsuranceCompanyIssueDate.label">Fecha emisión cia.</mat-label>
              <input
                matInput
                [matDatepicker]="receiptInsurancCompanyIssueDatePicker"
                [formControlName]="model.C_RECEIPT_INSURANCE_COMPANY_ISSUE_DATE"
                [howdenControlError]="receiptInsurancCompanyIssueDateError"
                [min]="minDate"
              />
              <mat-error #receiptInsurancCompanyIssueDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptInsurancCompanyIssueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptInsurancCompanyIssueDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptEffectDate.label">Fecha efecto</mat-label>
              <input
                matInput
                [matDatepicker]="receiptEffectDatePicker"
                [formControlName]="model.C_RECEIPT_EFFECT_DATE"
                [howdenControlError]="receiptEffectDateError"
                [min]="minDate"
              />
              <mat-error #receiptEffectDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptEffectDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptEffectDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptExpirationDate.label">Fecha vencimiento</mat-label>
              <input
                matInput
                [matDatepicker]="receiptExpirationDatePicker"
                [formControlName]="model.C_RECEIPT_EXPIRATION_DATE"
                [howdenControlError]="receiptExpirationDateError"
                [min]="minDate"
              />
              <mat-error #receiptExpirationDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptExpirationDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptExpirationDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="flex f-gap--10px">
            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptCollectionDate.label">Fecha cobro</mat-label>
              <input
                matInput
                [matDatepicker]="receiptCollectionDatePicker"
                [formControlName]="model.C_RECEIPT_COLLECTION_DATE"
                [howdenControlError]="receiptCollectionDateError"
                [min]="minDate"
              />
              <mat-error #receiptCollectionDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptCollectionDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptCollectionDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptRebateDate.label">Fecha devolución</mat-label>
              <input
                matInput
                [matDatepicker]="receiptRebateDatePicker"
                [formControlName]="model.C_RECEIPT_REBATE_DATE"
                [howdenControlError]="receiptRebateDateError"
                [min]="minDate"
              />
              <mat-error #receiptRebateDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptRebateDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptRebateDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptSettlementDate.label">Fecha liquidación</mat-label>
              <input
                matInput
                [matDatepicker]="receiptSettlementDatePicker"
                [formControlName]="model.C_RECEIPT_SETTLEMENT_DATE"
                [howdenControlError]="receiptSettlementDateError"
                [min]="minDate"
              />
              <mat-error #receiptSettlementDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptSettlementDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptSettlementDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="f-basis--3">
              <mat-label i18n="@@app.endorsements.create.receiptResolutionDate.label">Fecha resolución</mat-label>
              <input
                matInput
                [matDatepicker]="receiptResolutionDatePicker"
                [formControlName]="model.C_RECEIPT_RESOLUTION_DATE"
                [howdenControlError]="receiptResolutionDateError"
                [min]="minDate"
              />
              <mat-error #receiptResolutionDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="receiptResolutionDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #receiptResolutionDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.bonuses">Prima neta y total recibo</h4>
          </div>

          <div class="flex f-gap--10px">
            <howden-decimal
              class="f-basis--6"
              [formControlName]="model.C_RECEIPT_NET_BONUS"
              i18n-label="@@app.endorsements.create.receiptNetBonus.label"
              label="Prima neta"
            ></howden-decimal>

            <howden-decimal
              class="f-basis--6"
              [formControlName]="model.C_RECEIPT_TOTAL_BONUS"
              i18n-label="@@app.endorsements.create.receiptTotalBonus.label"
              label="Prima total"
            ></howden-decimal>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.taxes">Impuestos</h4>
          </div>

          <div class="flex f-gap--10px">
            <howden-decimal
              class="f-basis--6"
              [formControlName]="model.C_RECEIPT_TAXES"
              i18n-label="@@app.endorsements.create.receiptTaxes.label"
              label="Impuestos"
            ></howden-decimal>

            <howden-decimal
              class="f-basis--6"
              [formControlName]="model.C_RECEIPT_CONSORTIUM_TAXES"
              i18n-label="@@app.endorsements.create.receiptConsortiumTaxes.label"
              label="Consorcio"
            ></howden-decimal>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.surcharges">Recargos</h4>
          </div>

          <div class="flex f-gap--10px">
            <howden-decimal
              class="f-basis--6"
              [formControlName]="model.C_RECEIPT_SURCHARGE"
              i18n-label="@@app.endorsements.create.receiptSurcharge.label"
              label="Recargo"
            ></howden-decimal>

            <howden-decimal
              class="f-basis--6"
              [formControlName]="model.C_RECEIPT_EXTERNAL_SURCHARGE"
              i18n-label="@@app.endorsements.create.receiptExternalSurcharge.label"
              label="Recargo externo"
            ></howden-decimal>
          </div>

          <div class="flex">
            <h4 i18n="@@app.endorsements.create.sections.bonusPayments">Bonificaciones</h4>
          </div>

          <div class="flex f-gap--10px">
            <howden-decimal
              [formControlName]="model.C_RECEIPT_BONUS_PAY_AMOUNT"
              i18n-label="@@app.endorsements.create.receiptBonusPayAmount.label"
              label="Bonificación"
            ></howden-decimal>
          </div>

          <div class="flex f-gap--10px">
            <div class="f-basis--1">
              <button
                type="button"
                mat-mini-fab matStepperPrevious
                matTooltip="Anterior"
                i18n-matTooltip="@@app.core.stepper.actions.previous"
              >
                <mat-icon>navigate_before</mat-icon>
              </button>
            </div>

            <div class="f-basis--10">
              <div class="flex f-jc--center f-gap--10px">
                <button type="button" mat-raised-button (click)="onCancel()">
                  <span i18n="@@app.core.actions.cancel">Cancelar</span>
                </button>

                <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
                  <span i18n="@@app.core.actions.accept">Aceptar</span>
                </button>
              </div>
            </div>

            <div class="f-basis--1"></div>
          </div>

        </mat-step>

      </mat-stepper>
    </mat-dialog-content>

  </div>
</form>
