<mat-card appearance="outlined" class="dashboard-card">
  <mat-card-header class="card-header">
    <mat-card-title>
      <h1 class="value-text">@if (isCurrency) { {{ value | number : '1.2-2' }} } @else { {{ value }} }</h1>
    </mat-card-title>
    @if (icon) {
      <div mat-card-avatar class="card-icon">
        <mat-icon [color]="color" class="avatar-icon">{{ icon }}</mat-icon>
      </div>
    }
  </mat-card-header>
  <h3 class="title-text">{{ title }}</h3>
  <p class="difference-text">
    @if (percentValue) {
      <span [ngClass]="{ 'green-text': isIncrease, 'red-text': !isIncrease }">
        {{ isIncrease ? '+' : '-' }}{{ percentValue | percent:'2.1-2' }}
        @if (isIncrease) {
          <mat-icon>@if (isIncrease) { arrow_upward } @else { arrow_downward }</mat-icon>
        }
      </span>
    }
    <span class="duration">{{ duration }}</span>
  </p>
</mat-card>
