import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IDetailedClaimView } from '../models';

export abstract class DetailedClaimMapper {
  static map(source: Array<IDetailedClaim>): Array<IDetailedClaimView> {
    return source.map(value => ({
        claimId: value.claimId,
        claimDate: value.claimDate,
        clientName: value.clientName,
        insuranceCompanyName: value.insuranceCompanyName,
        policyNumber: value.policyNumber,
        claimTypeDescription: value.claimTypeDescription,
        cause: value.cause,
        statusDescription: value.statusDescription,
        subStatus: value.subStatus
      } as IDetailedClaimView));
  }
}
