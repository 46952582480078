import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { EmptyRootPolicyNotificationsComponent } from './empty-root-policy-notifications/pages/empty-root-policy-notifications.component';
import { PolicyExpirationNotificationsComponent } from './policy-expiration-notifications/pages/policy-expiration-notifications.component';

export const NotificationsRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'notifications',
    title: $localize`:@@app.route.notifications:Notificaciones`,
    data: {
      icon: 'notifications',
      linkText: $localize`:@@app.route.notifications:Notificaciones`
    },
    children: [
      {
        path: 'policy-expirations',
        component: PolicyExpirationNotificationsComponent,
        title: $localize`:@@app.route.notifications.policy.expirations.column:Vencimientos de póliza (a 90 días)`,
        data: { linkText: $localize`:@@app.route.notifications.policy.expirations:Vencimientos de póliza` }
      },
      {
        path: 'empty-root-policies',
        component: EmptyRootPolicyNotificationsComponent,
        title: $localize`:@@app.route.notifications.empty.root.policies.column:Pólizas madre sin pólizas hijas o con todas anuladas`,
        data: { linkText: $localize`:@@app.route.notifications.empty.root.policies.linkText:Pólizas madre vacías` }
      }

    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(NotificationsRoutes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule {
}
