import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogResultTypes,
  IButtonActionData,
  IDialogResult
} from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IPolicyParalization } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicyEditParalizationDialogComponent } from '../../../dialogs/policy-edit-paralization-dialog/pages/policy-edit-paralization-dialog.component';
import { PolicyEditParalizationsViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-paralizations',
  templateUrl: './policy-edit-paralizations.component.html',
  styleUrls: ['./policy-edit-paralizations.component.scss']
})
export class PolicyEditParalizationsComponent implements OnChanges, OnDestroy {
  model = new PolicyEditParalizationsViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private policySrv: IPolicyService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.openDialog(event.row.paralizationId);
    } else {
      this.onDelete(event.row.paralizationId);
    }
  }

  onNewParalization(): void {
    this.openDialog();
  }

  private openDialog(paralizationId?: string): void {
    const inputData = {
      policyId: this.policyId,
      paralizationId: paralizationId
    };
    const dialogRef = this.dialog.open(PolicyEditParalizationDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refresh());
  }

  private onDelete(paralizationId: string): void {
    const title: string = $localize`:@@app.policies.edit.paralizations.delete.title:Borrado de paralizaciones`;
    const question: string = $localize`:@@app.policies.edit.paralizations.delete.subtitle:Se borrarán los días de paralización, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.policies.edit.paralizations.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();

          this.policySrv.deleteParalization(this.policyId, paralizationId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private refresh() {
    this.policySrv.getParalizations(this.policyId).subscribe((data: Array<IPolicyParalization>) => {
      this.model.data = data;
    });
  }
}
