import { BillingModelType, PolicyBonusType, PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IPolicy } from 'src/app/core/models/api/policies';

export class PolicyEditLayoutViewModel {
  private _policy?: IPolicy;

  get policy(): IPolicy | undefined { return this._policy; }

  set policy(policy: IPolicy | undefined) { this._policy = policy; }

  get showRisks(): boolean { return this.isIndividual || this.isColective; }

  get showCertificates(): boolean { return this.isRoot && !this.isColective; }

  get showBillingGroups(): boolean { return this.hasFixedBonus && !this.isIndividual; }

  get showBonuses(): boolean { return this.allowBonusModification; }

  get showInitialState(): boolean { return this.isRegularized; }

  get showBilling(): boolean { return this.isRoot && this.hasExpirationDate; }

  get showRegularization(): boolean { return this.isRegularized && this.hasExpirationDate; }

  get showParalizations(): boolean { return this.isIndividual; }

  get showClaims(): boolean { return true; }

  get showAssignRisks(): boolean { return this.isRoot && !this.isCancelled; }

  get showImport(): boolean { return this.isRoot && !this.isColective; }

  private get isRoot(): boolean { return this._policy?.policyClass !== PolicyClass.Individual; }

  private get isIndividual(): boolean { return this._policy?.policyClass === PolicyClass.Individual; }

  private get isColective(): boolean { return this._policy?.policyClass === PolicyClass.Colective; }

  private get isRegularized(): boolean { return this._policy?.billingModel === BillingModelType.Regularized; }

  private get isCancelled(): boolean { return this._policy?.status === PolicyStatus.Cancelled; }

  private get hasFixedBonus(): boolean { return this._policy?.policyBonusType === PolicyBonusType.Fixed; }

  private get hasIndividualBonus(): boolean { return this._policy?.policyBonusType === PolicyBonusType.Individual; }

  private get hasExpirationDate(): boolean {
    const expirationDate = this._policy?.expirationDate;

    return typeof expirationDate !== 'undefined' && expirationDate !== null;
  }

  private get allowBonusModification(): boolean {
    return ((this.isRoot && this.hasFixedBonus)
      || (!this.isRoot && !this.hasFixedBonus)
      || (this.isIndividual && this.hasIndividualBonus) && this.hasExpirationDate);
  }
}
