import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IItemSelection } from 'src/app/core/models/api/core';

export class RisksComparedReportViewModel {
  private _form: FormGroup;
  private _insuranceCompanies: Array<IItemSelection> = [];
  private _policyClasses: Array<IItemSelection> = [];
  private _bookingModes: Array<IItemSelection> = [];
  private _vehicleTypes: Array<IItemSelection> = [];

  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_START_DATE]: new FormControl(null, []),
      [this.C_END_DATE]: new FormControl(null, [])
    });
  }

  get insuranceCompanies(): Array<IItemSelection> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IItemSelection>) {
    this._insuranceCompanies = value;
  }

  get policyClasses(): Array<IItemSelection> {
    return this._policyClasses;
  }

  set policyClasses(value: Array<IItemSelection>) {
    this._policyClasses = value;
  }

  get bookingModes(): Array<IItemSelection> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IItemSelection>) {
    this._bookingModes = value;
  }

  get vehicleTypes(): Array<IItemSelection> {
    return this._vehicleTypes;
  }

  set vehicleTypes(value: Array<IItemSelection>) {
    this._vehicleTypes = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get startDate(): Date | null {
    return this.getControl(this.C_START_DATE).value;
  }

  set startDate(value: Date | null) {
    this.getControl(this.C_START_DATE).setValue(value);
  }

  get endDate(): Date | null {
    return this.getControl(this.C_END_DATE).value;
  }

  set endDate(value: Date | null) {
    this.getControl(this.C_END_DATE).setValue(value);
  }
}
