<mat-card appearance="outlined" class="dashboard-card">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle>
      <small>{{subtitle}}</small>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
