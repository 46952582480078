import { Observable } from 'rxjs';
import { IFileTemplateRequest } from 'src/app/core/models/api/templates';

export abstract class IFileTemplatesService {
  abstract getUpdateFleetTemplate(): Observable<Blob>;
  abstract getInitialStateTemplate(): Observable<Blob>;
  abstract getCreateUnitsTemplate(): Observable<Blob>;
  abstract getUpdateUnitsTemplate(): Observable<Blob>;
  abstract getParalizationsTemplate(): Observable<Blob>;
  abstract getPolicyAssignationTemplate(): Observable<Blob>;
  abstract getPolicyRenumberingTemplate(): Observable<Blob>;
  abstract getPolicyLoadTemplate(request: IFileTemplateRequest): Observable<Blob>;
  abstract getPolicyUpdateTemplate(): Observable<Blob>;
  abstract getRootPolicyUpdateTemplate(): Observable<Blob>;
  abstract getPolicyTransferTemplate(): Observable<Blob>;
  abstract getPolicyCancellationTemplate(): Observable<Blob>;
  abstract getPolicyBonusUpdateTemplate(): Observable<Blob>;
  abstract getPolicyHolderUpdateTemplate(): Observable<Blob>;
  abstract getPolicyRiskUpdateTemplate(): Observable<Blob>;
  abstract getRateChangeTemplate(): Observable<Blob>;
  abstract getPolicyRiskBookingModeChangeTemplate(): Observable<Blob>;
  abstract getEndorsementLoaderTemplate(): Observable<Blob>;
}
