import { IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IFunctionalType, IFunctionalTypeSearchRequest } from 'src/app/core/models/api/units';

export abstract class IFunctionalTypeService {
  abstract get(id: string): Observable<IFunctionalType>;
  abstract search(request: IFunctionalTypeSearchRequest): Observable<IPageOf<IFunctionalType>>;
  abstract upsert(request: IFunctionalType): Observable<void>;
  abstract delete(id: string): Observable<void>;
}
