import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IBookingModeSearchRequest } from 'src/app/core/models/api/booking-modes';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IReportsService } from 'src/app/core/services/api/reports';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { RisksComparedReportMapper } from '../mappers';
import { RisksComparedReportViewModel } from '../models';

@Component({
  selector: 'howden-risks-compared-report',
  templateUrl: './risks-compared-report.component.html',
  styleUrls: ['./risks-compared-report.component.scss']
})
export class RisksComparedReportComponent implements OnInit {
  model = new RisksComparedReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private bookingModeSrv: IBookingModeService,
    private policySrv: IPolicyService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const bookingModeFilter = <IBookingModeSearchRequest>{ pageSize: 99999, fleetId: fleetId };

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getInsuranceCompanies(fleetId),
      this.policySrv.getPolicyClasses(),
      this.bookingModeSrv.search(bookingModeFilter),
      this.fleetSrv.getVehicleTypes(fleetId)
    ]).subscribe(([
      insuranceCompanies,
      policyClasses,
      bookingModes,
      vehicleTypes
    ]) => {
      this.model.insuranceCompanies = insuranceCompanies.map(x => <IItemSelection>{ id: x.insuranceCompanyId, description: x.name });
      this.model.policyClasses = policyClasses.map(x => <IItemSelection>{ id: x.policyClassId, description: x.description });
      this.model.bookingModes = bookingModes.data.map(x => <IItemSelection>{
        id: x.bookingModeId,
        description: x.description,
        notes: `${x.insuranceCompany}-${x.industry}-${x.product}`
      });
      this.model.vehicleTypes = vehicleTypes.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = RisksComparedReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.risksCompared(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_RIESGOS_COMPARADOS', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
