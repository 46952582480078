import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { ProductStatus } from 'src/app/core/enums';
import { IProduct } from 'src/app/core/models/ida/products';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IProductService } from 'src/app/core/services/api/products';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreateBookingModeMapper } from '../mappers';
import { CreateBookingModeViewModel } from '../models';

@Component({
  selector: 'howden-create-booking-mode-dialog',
  templateUrl: './create-booking-mode-dialog.component.html',
  styleUrls: ['./create-booking-mode-dialog.component.scss']
})
export class CreateBookingModeDialogComponent implements OnInit, OnDestroy {
  model = new CreateBookingModeViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialogRef: MatDialogRef<CreateBookingModeDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private productSrv: IProductService,
    private bookingModeSrv: IBookingModeService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getInsuranceCompanies(fleetId),
      this.fleetSrv.getIndustries(fleetId)
    ]).subscribe(([
      insuranceCompanies,
      industries
    ]) => {
      this.model.insuranceCompanies = insuranceCompanies;
      this.model.industries = industries;

      this.onChanges();

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const fleetId = this.sessionSrv.activeFleet;
    const request = CreateBookingModeMapper.mapForUpdate(fleetId, this.model);

    this.uiBlockerSrv.block();
    this.bookingModeSrv.create(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries?.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getProductName(productId: string): string {
    const product = this.model.products?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INDUSTRY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
    });
  }

  private onLoadProducts(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;
    const industryId: string = this.model.industryId;

    this.model.productId = '';
    this.model.products = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        this.model.products = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
