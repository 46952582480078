import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogResultTypes, HowdenTableComponent, IButtonActionData, IDialogResult, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IFleetSearchRequest, IFleetSearchResult } from 'src/app/core/models/api/fleets';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { FleetSearchMapper } from '../mappers';
import { FleetSearchViewModel } from '../models';

@Component({
  selector: 'howden-fleet-search',
  templateUrl: './fleet-search.component.html',
  styleUrls: ['./fleet-search.component.scss']
})
export class FleetSearchComponent implements OnInit, OnDestroy {
  model = new FleetSearchViewModel();

  @ViewChild('fleetsTable') tableRef!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService
  ) {
  }

  ngOnInit(): void {
    this.refresh(true);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onFiltersChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'activate') {
      this.sessionSrv.activateFleet(event.row.fleetId as string);
    } else if (event.buttonName === 'delete') {
      this.onDelete(event);
    }
  }

  private onDelete(event: IButtonActionData) {
    const fleetId = event.row.fleetId;
    const title: string = $localize`:@@app.fleets.delete.title:Borrado de flota`;
    const question: string = $localize`:@@app.fleets.delete.subtitle:Se borrará la flota, recuerde que no será posible borrar una flota con pólizas y/o unidades registradas, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.fleets.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();
          this.fleetSrv.delete(fleetId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private refresh(setFocus?: boolean): void {
    const request: IFleetSearchRequest = this.buildSearchRequest();

    this.uiBlockerSrv.block();

    this.fleetSrv.search(request).subscribe({
      next: (searchResult: IPageOf<IFleetSearchResult>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => FleetSearchMapper.mapForSearch(source));

        if (setFocus === true) {
          this.tableRef.focus();
        }
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  private buildSearchRequest(): IFleetSearchRequest {
    return {
      pageNumber: this.model.pageIndex,
      pageSize: this.model.pageSize,
      sortedBy: this.model.sortedBy,
      sortDirection: this.model.sortDirection,
      name: this.model.getFilterValue<string>(0),
      clientName: this.model.getFilterValue<string>(1),
      clientFiscalCode: this.model.getFilterValue<string>(2)
    } as IFleetSearchRequest;
  }
}
