<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <mat-stepper #stepper linear>
      <mat-step>
        <ng-template matStepLabel i18n="@app-unit-create-new-data-section">Datos unidad</ng-template>

        <div class="flex f-gap--10px margin-top-10">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.unitPlateNumber.label">Matrícula</mat-label>
            <input
              #plateNumberInput
              matInput
              [formControlName]="model.C_UNIT_PLATE_NUMBER"
              [howdenControlError]="plateNumberError"
              maxlength="15"
            />
            <mat-error #plateNumberError></mat-error>
            @if (model.getControl(model.C_UNIT_PLATE_NUMBER).hasError('notAvailable')) {
              <mat-error
              i18n="@@app.units.create.new.plateNumber.not.available.error">La matrícula indicada no está disponible.</mat-error>
            }
          </mat-form-field>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.unitVehicleTypeId.label"
            label="Tipo de vehículo"
            displayField="description"
            keyField="erpId"
            [selectableItems]="model.vehicleTypes"
            [formControlName]="model.C_UNIT_VEHICLE_TYPE_ID"
          >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.unitVehicleUsageId.label"
            label="Uso vehículo"
            displayField="description"
            keyField="erpId"
            [selectableItems]="model.vehicleUsages"
            [formControlName]="model.C_UNIT_VEHICLE_USAGE_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.owner.label"
            label="Propietario"
            displayField="clientName"
            keyField="clientName"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_UNIT_OWNER"
          >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.userId.label"
            label="Usuario"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_UNIT_USER_ID"
          >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.lesseeId.label"
            label="Arrendatario"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_UNIT_LESSEE_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.movementArea.label">Ámbito de circulación</mat-label>
            <mat-select [formControlName]="model.C_UNIT_MOVEMENT_AREA" [howdenControlError]="movementAreaError">
              @for (item of model.movementAreas | howdenArraySort: 'description'; track item.movementAreaId) {
                <mat-option [value]="item.description">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #movementAreaError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.circulationArea.label">Recinto de circulación</mat-label>
            <mat-select [formControlName]="model.C_UNIT_CIRCULATION_AREA_ID" [howdenControlError]="circulationAreaError">
              @for (item of model.circulationAreas | howdenArraySort: 'description'; track item.circulationAreaId) {
                <mat-option [value]="item.circulationAreaId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #circulationAreaError></mat-error>
          </mat-form-field>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.units.create.new.functionalTypeId.label"
            label="Tipo funcional"
            displayField="description"
            keyField="functionalTypeId"
            [selectableItems]="model.functionalTypes"
            [formControlName]="model.C_UNIT_FUNCTIONAL_TYPE_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.registrationClassification.label">Tipificación matrícula</mat-label>
            <mat-select [formControlName]="model.C_UNIT_REGISTRATION_CLASSIFICATION" [howdenControlError]="registrationClassificationError">
              <mat-option></mat-option>
              <mat-option value="1" i18n="@@app.units.create.new.registrationClassification.plateNumber">Matrícula</mat-option>
              <mat-option value="2" i18n="@@app.units.create.new.registrationClassification.chassis">Bastidor</mat-option>
            </mat-select>
            <mat-error #registrationClassificationError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.registrationate.label">F. Matriculación</mat-label>
            <input
              matInput
              [matDatepicker]="registrationDatePicker"
              [formControlName]="model.C_UNIT_REGISTRATION_DATE"
              [howdenControlError]="registrationDateError"
              [min]="minDate"
            />
            <mat-error #registrationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="registrationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #registrationDatePicker></mat-datepicker>
          </mat-form-field>

          <div class="f-basis--4">
            <button
              type="button"
              mat-raised-button
              color="accent"
              [disabled]="!model.unitPlateNumber"
              (click)="onGuessRegistrationDate()"
              i18n="@@app.units.create.new.calc.date"
            >
              Calcular fecha
            </button>
          </div>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.brand.label">Marca</mat-label>
            <input matInput [formControlName]="model.C_UNIT_BRAND" [howdenControlError]="brandError" maxlength="15" />
            <mat-error #brandError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.model.label">Modelo</mat-label>
            <input matInput [formControlName]="model.C_UNIT_MODEL" [howdenControlError]="modelError" maxlength="50" />
            <mat-error #modelError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.version.label">Version</mat-label>
            <input matInput [formControlName]="model.C_UNIT_VERSION" [howdenControlError]="versionError" maxlength="50" />
            <mat-error #versionError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.carNumber.label">Nº. Coche</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CAR_NUMBER" [howdenControlError]="carNumberError" maxlength="20" />
            <mat-error #carNumberError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.seatNumber.label">Plazas</mat-label>
            <input matInput [formControlName]="model.C_UNIT_SEAT_NUMBER" [howdenControlError]="seatNumberError" maxlength="20" />
            <mat-error #seatNumberError></mat-error>
          </mat-form-field>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_SEATS"
            [precision]="0"
            i18n-label="@@app.units.create.new.seats.label"
            label="Total plazas"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_VALUE"
            i18n-label="@app.units.create.new.value.label"
            label="Valor"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_VALUE_EXTRAS"
            i18n.label="@@app.units.create.new.valueExtras.label"
            label="Valor extras"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_MAX_ADDMITTED_WEIGHT"
            [precision]="0"
            i18n-label="@@app.units.create.new.maxAddmittedWeight.label"
            label="PMA"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.chasisNumber.label">Nº. de chasis</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CHASIS_NUMBER" [howdenControlError]="chasisNumberError" maxlength="30" />
            <mat-error #chasisNumberError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.goodsTypeId.label">Tipo de mercancía</mat-label>
            <mat-select [formControlName]="model.C_UNIT_GOODS_TYPE_ID" [howdenControlError]="goodsTypeError">
              <mat-option></mat-option>
              @for (item of model.goodsTypes | howdenArraySort: 'description'; track item.goodTypeId) {
                <mat-option [value]="item.goodTypeId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #goodsTypeError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.bonusGroup.label">Grupo primas</mat-label>
            <input matInput [formControlName]="model.C_UNIT_BONUS_GROUP" [howdenControlError]="bonusGroupError" maxlength="10" />
            <mat-error #bonusGroupError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.classificationCodeCL.label">Código clasficación CL</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CLASSIFICATION_CODE_CL" [howdenControlError]="classificationCodeCLError" maxlength="10" />
            <mat-error #classificationCodeCLError></mat-error>
          </mat-form-field>

          <div class="f-basis--4"></div>
          <div class="f-basis--4"></div>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.units.create.new.comments.label">Observaciones</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_UNIT_OBSERVATIONS"
              [howdenControlError]="observationsError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="15"
            >
            </textarea>
            <mat-error #observationsError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.units.create.new.sections.renting">Datos renting</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.rentingCompanyFiscalCode.label">Empresa renting</mat-label>
            <input
              matInput
              [formControlName]="model.C_UNIT_RENTING_COMPANY_FISCAL_CODE"
              [howdenControlError]="rentingCompanyFiscalCodeError"
              maxlength="15"
            />
            <mat-error #rentingCompanyFiscalCodeError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.rentingStartDate.label">Fecha inicio renting</mat-label>
            <input
              matInput
              [matDatepicker]="rentingStartDatePicker"
              [formControlName]="model.C_UNIT_RENTING_START_DATE"
              [howdenControlError]="rentingStartDatePickerError"
              [min]="minDate"
            />
            <mat-error #rentingStartDatePickerError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="rentingStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #rentingStartDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.rentingExpirationDate.label">Fecha vencimiento renting</mat-label>
            <input
              matInput
              [matDatepicker]="rentingExpirationDatePicker"
              [formControlName]="model.C_UNIT_RENTING_EXPIRATION_DATE"
              [howdenControlError]="rentingExpirationDatePickerError"
              [min]="minDate"
            />
            <mat-error #rentingExpirationDatePickerError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="rentingExpirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #rentingExpirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.units.create.new.sections.misc">Otros datos</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.division.label">División</mat-label>
            <input matInput [formControlName]="model.C_UNIT_DIVISION" [howdenControlError]="divisionError" maxlength="30" />
            <mat-error #divisionError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.zone.label">Zona</mat-label>
            <input matInput [formControlName]="model.C_UNIT_ZONE" [howdenControlError]="zoneError" maxlength="30" />
            <mat-error #zoneError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.userZone.label">Zona usuario</mat-label>
            <input matInput [formControlName]="model.C_UNIT_USER_ZONE" [howdenControlError]="userZoneError" maxlength="30" />
            <mat-error #userZoneError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_POWER"
            [precision]="0"
            i18n-label="@@app.units.create.new.power.label"
            label="PMA"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_LENGTH"
            i18n-label="@@app.units.create.new.length.label"
            label="Logitud"
          ></howden-decimal>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.contract.label">Contrato</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CONTRACT" [howdenControlError]="contractError" maxlength="30" />
            <mat-error #contractError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_AGE"
            i18n-label="@@app.units.create.new.age.label"
            label="Edad"
          ></howden-decimal>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.manufacturingMonth.label">Mes fabricación</mat-label>
            <mat-select [formControlName]="model.C_UNIT_MANUFACTURING_MONTH" [howdenControlError]="manufacturingMonthError">
              <mat-option></mat-option>
              @for (item of months(); track item; let i = $index) {
                <mat-option [value]="i + 1">
                  {{ item }}
                </mat-option>
              }
            </mat-select>

            <mat-error #manufacturingMonthError></mat-error>
          </mat-form-field>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_KILOMETERS"
            [precision]="0"
            i18n-label="@@app.units.create.new.kilometers.label"
            label="Kilométros año"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.category.label">Categoría</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CATEGORY" [howdenControlError]="categoryError" maxlength="30" />
            <mat-error #categoryError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.class.label">Clase</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CLASS" [howdenControlError]="classError" maxlength="20" />
            <mat-error #classError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.euroClass.label">Clase euro</mat-label>
            <input matInput [formControlName]="model.C_UNIT_EUROCLASS" [howdenControlError]="euroClassError" maxlength="20" />
            <mat-error #euroClassError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.workshop.label">Taller</mat-label>
            <input matInput [formControlName]="model.C_UNIT_WORKSHOP" [howdenControlError]="workshopError" maxlength="30" />
            <mat-error #workshopError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.bodyWork.label">Carrocería</mat-label>
            <input matInput [formControlName]="model.C_UNIT_BODY_WORK" [howdenControlError]="bodyWorkError" maxlength="30" />
            <mat-error #bodyWorkError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.bodyWorkNumber.label">Nº. Carrocería</mat-label>
            <input matInput [formControlName]="model.C_UNIT_BODY_WORK_NUMBER" [howdenControlError]="bodyWorkNumberError" maxlength="30" />
            <mat-error #bodyWorkNumberError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle class="f-basis--4" [formControlName]="model.C_UNIT_TD" i18n="@@app.units.create.new.td.label">TD</mat-slide-toggle>

          <mat-slide-toggle class="f-basis--4" [formControlName]="model.C_UNIT_CONTRACTED_MAINTENANCE" i18n="@@app.units.create.new.mtocdto.label">MTO_CTDO</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.platform.label">Plataforma</mat-label>
            <input matInput [formControlName]="model.C_UNIT_PLATFORM" [howdenControlError]="platformError" maxlength="15" />
            <mat-error #platformError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_UNIT_SCHOOL_BUS"
            i18n="@@app.units.create.new.schoolBus.label">Autobús escolar</mat-slide-toggle>

          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_UNIT_WIFI"
            i18n="@@app.units.create.new.wifi.label">WIFI</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.wifiName.label">Nombre WIFI</mat-label>
            <input matInput [formControlName]="model.C_UNIT_WIFI_NAME" [howdenControlError]="wifiNameError" maxlength="30" />
            <mat-error #wifiNameError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_UNIT_ENTERTAIMENT_BUS"
            i18n="@@app.units.create.new.entertaimentBus.label">Ocio bus</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.busType.label">Tipo de autobús</mat-label>
            <input matInput [formControlName]="model.C_UNIT_BUS_TYPE" [howdenControlError]="busTypeError" maxlength="30" />
            <mat-error #busTypeError></mat-error>
          </mat-form-field>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_UNIT_BUS_NUMBER"
            [precision]="0"
            i18n-label="@@app.units.create.new.busNumber.label"
            label="Nº. Autobús"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--4"
            [formControlName]="model.C_UNIT_INDIVIDUAL_ENTERTAIMENT"
            i18n="@@app.units.create.new.individualEntertaiment.label">Ocio individual</mat-slide-toggle>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.configuration.label">Configuración</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CONFIGURATION" [howdenControlError]="schoolBusError" maxlength="30" />
            <mat-error #schoolBusError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.cameras.label">Cámaras</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CAMERAS" [howdenControlError]="camerasError" maxlength="30" />
            <mat-error #camerasError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.safeBelts.label">Cinturones de seguridad</mat-label>
            <input matInput [formControlName]="model.C_UNIT_SAFE_BELTS" [howdenControlError]="safeBeltsError" maxlength="15" />
            <mat-error #safeBeltsError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.pmrsr.label">PMRSR</mat-label>
            <input matInput [formControlName]="model.C_UNIT_PMRSR" [howdenControlError]="pmrsrError" maxlength="15" />
            <mat-error #pmrsrError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.units.create.new.concession.label">Concesión</mat-label>
            <input matInput [formControlName]="model.C_UNIT_CONCESSION" [howdenControlError]="concessionError" maxlength="100" />
            <mat-error #concessionError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-fd--row f-gap--10px margin-top-20">
          <div class="f-basis--1"></div>

          <div class="f-basis--10">
            <div class="flex f-jc--center">
              <button type="button" mat-raised-button (click)="onCancel()">
                <span i18n="@@app.core.actions.cancel">Cancelar</span>
              </button>
            </div>
          </div>

          <div class="f-basis--1">
            <button
              type="button"
              mat-mini-fab matStepperNext
              matTooltip="Siguiente"
              i18n-matTooltip="@@app.core.stepper.actions.next"
              >
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel i18n="@app-unit-create-new-assignment-section">Asignación póliza</ng-template>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.unit.create.new.assignment.targetRootPolicyId.label">Seleccione la póliza madre de destino</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_TARGET_POLICY_ID"
              [matAutocomplete]="assignmentTargetRootPolicySelect"
              [howdenControlError]="assignmentTargetRootPolicyIdError"
            />
            <mat-autocomplete
              #assignmentTargetRootPolicySelect="matAutocomplete"
              [displayWith]="getRootPolicyDescription.bind(this)"
            >
              @for (item of model.filteredRootPolicies | async; track item.policyId) {
                <mat-option [value]="item.policyId">
                  {{ item.policyNumber }}-{{ item.riskDescription }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #assignmentTargetRootPolicyIdError></mat-error>
          </mat-form-field>

        </div>

        <mat-divider class="margin-bottom-10"></mat-divider>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.unit.create.new.assignment.policyNumber.label">Nº. Póliza</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_POLICY_NUMBER"
              [howdenControlError]="policyNumberError"
              maxlength="20"
            />
            <mat-error #policyNumberError></mat-error>
            @if (model.getControl(this.model.C_ASSIGNMENT_POLICY_NUMBER).hasError('notAvailable')) {
              <mat-error
              i18n="@@app.unit.create.new.assignment.policyNumber.not.available.error">El número de póliza indicado no está disponible.</mat-error>
            }
          </mat-form-field>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.unit.create.new.assignment.holderId.label"
            label="Tomador"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_ASSIGNMENT_HOLDER_ID"
            >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.unit.create.new.assignment.insuredId.label"
            label="Asegurado"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_ASSIGNMENT_INSURED_ID"
            >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.unit.create.new.assignment.firstEffectDate.label">Fecha primer efecto</mat-label>
            <input
              matInput
              [matDatepicker]="assignmentFirstEffectDatePicker"
              [formControlName]="model.C_ASSIGNMENT_FIRST_EFFECT_DATE"
              [howdenControlError]="assignmentFirstEffectDateError"
              [min]="minDate"
            />
            <mat-error #assignmentFirstEffectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="assignmentFirstEffectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #assignmentFirstEffectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.unit.create.new.assignment.effectDate.label">Fecha efecto</mat-label>
            <input
              matInput
              [matDatepicker]="assignmentEffectDatePicker"
              [formControlName]="model.C_ASSIGNMENT_EFFECT_DATE"
              [howdenControlError]="assignmentEffectDateError"
              [min]="minDate"
            />
            <mat-error #assignmentEffectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="assignmentEffectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #assignmentEffectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.unit.create.new.assignment.expirationDate.label">Fecha vencimiento</mat-label>
            <input
              matInput
              [matDatepicker]="assignmentExpirationDatePicker"
              [formControlName]="model.C_ASSIGNMENT_EXPIRATION_DATE"
              [howdenControlError]="assignmentExpirationDateError"
              [min]="minDate"
            />
            <mat-error #assignmentExpirationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="assignmentExpirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #assignmentExpirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.policies.create.root.mediationCodeId.label">Código mediación</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_MEDIATION_CODE_ID"
              [matAutocomplete]="assignmentMediationCodeSelect"
              [howdenControlError]="assignmentMediationCodeIdError"
            />
            <mat-autocomplete #assignmentMediationCodeSelect="matAutocomplete" [displayWith]="getMediationCodeDescription.bind(this)">
              @for (item of model.filteredMediationCodes | async; track item.mediationCodeId) {
                <mat-option [value]="item.mediationCodeId">
                  {{ item.code }}-{{ item.description }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #assignmentMediationCodeIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.policies.create.root.mediationCodeId.sections.bookingMode">Modo de contratación</h4>
        </div>

        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--12"
            i18n-label="@@app.unit.create.new.assignment.bookingModeId.label"
            label="Modo de contratación"
            displayField="description"
            keyField="bookingModeId"
            [selectableItems]="model.bookingModes"
            [formControlName]="model.C_ASSIGNMENT_BOOKING_MODE_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--3">
            <mat-label i18n="@@app.unit.create.new.assignment.franchiseDP.label">Franquicia (DP)</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_FRANCHISE_DP"
              [howdenControlError]="assignmentFranchiseDPError"
              maxlength="20"
            />
            <mat-error #assignmentFranchiseDPError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--3">
            <mat-label i18n="@@app.unit.create.new.assignment.franchiseI.label">Franquicia (I)</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_FRANCHISE_I"
              [howdenControlError]="assignmentFranchiseIError"
              maxlength="20"
            />
            <mat-error #assignmentFranchiseIError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--3">
            <mat-label i18n="@@app.unit.create.new.assignment.franchiseR.label">Franquicia (R)</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_FRANCHISE_R"
              [howdenControlError]="assignmentFranchiseRError"
              maxlength="20"
            />
            <mat-error #assignmentFranchiseRError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--3">
            <mat-label i18n="@@app.unit.create.new.assignment.franchiseTR.label">Franquicia (TR)</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_FRANCHISE_TR"
              [howdenControlError]="assignmentFranchiseTRError"
              maxlength="20"
            />
            <mat-error #assignmentFranchiseTRError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.policies.create.root.mediationCodeId.sections.bonuses">Primas</h4>
        </div>

        @if (model.isAssignedToRootPolicy) {
          <div class="flex f-gap--10px margin-bottom-10">
            <button
              type="button"
              class="f-basis--12"
              mat-raised-button
              color="accent"
              [disabled]="model.form.invalid"
              (click)="onSearchDefaultBonus()"
            >
              Buscar primas por defecto
            </button>
          </div>
        }

        @if (model.isAssignedToColectivePolicy) {
          <div class="flex f-gap--10px">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.units.create.new.billingGroupPattern.label">Grupo Facturación</mat-label>
              <input
                matInput
                [formControlName]="model.C_ASSIGNMENT_BILLING_GROUP_PATTERN"
                [howdenControlError]="billingGroupPatternError"
              />
              <mat-error #billingGroupPatternError></mat-error>
            </mat-form-field>
          </div>
        }

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_ASSIGNMENT_NET_BONUS"
            i18n-label="@@app.unit.create.new.assignment.netBonus.label"
            label="Prima neta anual"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_ASSIGNMENT_TOTAL_BONUS"
            i18n-label="@@app.unit.create.new.assignment.totalBonus.label"
            label="Prima total anual"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--4"
            [formControlName]="model.C_ASSIGNMENT_COMMISSION_RATE"
            i18n-label="@@app.unit.create.new.assignment.commissionRate.label"
            label="Comisión (%)"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <h4 i18n="@@app.policies.create.root.mediationCodeId.sections.creditor">Beneficiario</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--6">
            <mat-label i18n="@@app.unit.create.new.assignment.creditorName.label">Nombre</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_CREDITOR_NAME"
              [howdenControlError]="assignmentCreditorNameError"
              maxlength="100"
            />
            <mat-error #assignmentCreditorNameError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--6">
            <mat-label i18n="@@app.unit.create.new.assignment.creditorFiscalCode.label">NIF/CIF</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_CREDITOR_FISCAL_CODE"
              [howdenControlError]="assignmentCreditorFiscalCodeError"
              maxlength="10"
            />
            <mat-error #assignmentCreditorFiscalCodeError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.unit.create.new.assignment.creditorEmail.label">Email</mat-label>
            <input
              matInput
              [formControlName]="model.C_ASSIGNMENT_CREDITOR_EMAIL"
              [howdenControlError]="assignmentCreditorEmailError"
              maxlength="60"
            />
            <mat-error #assignmentCreditorEmailError></mat-error>
          </mat-form-field>

        </div>

        <div class="flex f-gap--10px">

          <mat-form-field>
            <mat-label i18n="@@app.endorsements.create.assignmentObservations.label">Observaciones</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_ASSIGNMENT_CREDITOR_OBSERVATIONS"
              [howdenControlError]="assignmentObservationsError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="15"
              >
            </textarea>
            <mat-error #assignmentObservationsError></mat-error>
          </mat-form-field>

        </div>

        <div class="flex f-fd--row f-gap--10px margin-top-20">

          <div class="f-basis--1">
            <button
              type="button"
              mat-mini-fab matStepperPrevious
              matTooltip="Anterior"
              i18n-matTooltip="@@app.core.stepper.actions.previous"
            >
              <mat-icon>navigate_before</mat-icon>
            </button>
          </div>

          <div class="f-basis--10">
            <div class="flex f-jc--center f-gap--10px">
              <button type="button" mat-raised-button (click)="onCancel()">
                <span i18n="@@app.core.actions.cancel">Cancelar</span>
              </button>

              <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
                <span i18n="@@app.core.actions.accept">Aceptar</span>
              </button>
            </div>
          </div>

          <div class="f-basis--1"> </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
