import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogResultTypes,
  IButtonActionData,
  IDialogResult,
  IPageOf,
  ITableEventData
} from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { ProductStatus } from 'src/app/core/enums';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IProduct } from 'src/app/core/models/ida/products';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IProductService } from 'src/app/core/services/api/products';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreateBookingModeDialogComponent } from '../dialogs/create-booking-mode-dialog/pages/create-booking-mode-dialog.component';
import { BookingModeSearchMapper } from '../mappers';
import { BookingModeSearchViewModel } from '../models';

@Component({
  selector: 'howden-booking-mode-search',
  templateUrl: './booking-mode-search.component.html',
  styleUrls: ['./booking-mode-search.component.scss']
})
export class BookingModeSearchComponent implements OnInit, OnDestroy {
  model = new BookingModeSearchViewModel();

  @ViewChild('insuranceCompany') insuranceCompanyRef!: ElementRef;

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private bookingModeSrv: IBookingModeService,
    private productSrv: IProductService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getInsuranceCompanies(fleetId),
      this.fleetSrv.getIndustries(fleetId)
    ]).pipe(
      tap(([
        insuranceCompanies,
        industries
      ]) => {
        this.model.insuranceCompanies = insuranceCompanies;
        this.model.industries = industries;
        this.model.searchRequest.fleetId = fleetId;

        this.refresh();
        this.onChanges();

        this.uiBlockerSrv.unblock();

        this.insuranceCompanyRef.nativeElement.focus();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    const bookingModeId = event.row.bookingModeId;
    const title: string = $localize`:@@app.booking.modes.delete.title:Borrado de modo de contratación`;
    const question: string = $localize`:@@app.booking.modes.delete.subtitle:Se borrará el modo de contratación, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.booking.modes.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();
          this.bookingModeSrv.delete(bookingModeId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onNewBookingMode(): void {
    const dialogRef = this.dialog.open(CreateBookingModeDialogComponent, { minWidth: '400px', width: 'auto' });
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refresh());
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries?.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getProductName(productId: string): string {
    const product = this.model.products?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INDUSTRY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
    });
    this._subscriptions.sink = this.model.form.valueChanges.subscribe(() => {
      const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === this.model.insuranceCompanyId);
      const insuranceCompanyId = insuranceCompany ? this.model.insuranceCompanyId : null;
      const industry = this.model.industries.find(x => x.industryId === this.model.industryId);
      const industryId = industry ? this.model.industryId : null;
      const product = this.model.products.find(x => x.productId === this.model.productId);
      const productId = product ? this.model.productId : null;

      this.model.searchRequest.pageNumber = 0;
      this.model.searchRequest.insuranceCompanyId = insuranceCompanyId;
      this.model.searchRequest.industryId = industryId;
      this.model.searchRequest.productId = productId;
      this.model.searchRequest.description = this.model.description;
      this.refresh();
    });
  }

  private onLoadProducts(): void {
    const insuranceCompanyId = this.model.insuranceCompanyId;
    const industryId = this.model.industryId;

    this.model.productId = '';
    this.model.products = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId as string, industryId as string).subscribe((data: Array<IProduct>) => {
        this.model.products = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private refresh(): void {
    this.bookingModeSrv.search(this.model.searchRequest).subscribe((searchResult: IPageOf<IBookingModeSearchResult>) => {
      this.model.length = searchResult.totalCount;
      this.model.data = searchResult.data?.map((source) => BookingModeSearchMapper.mapForSearch(source));
    });
  }
}
