import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { CertificateType } from 'src/app/core/enums';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { CertificatesComponent } from './certificates/pages/certificates.component';

export const CertificatesRoutes = [
  {
    canActivate: [AuthGuard, ActiveFleetGuard],
    path: 'certificates',
    title: $localize`:@@app.route.certificates:Certificados`,
    data: {
      icon: 'workspace_premium',
      linkText: $localize`:@@app.route.certificates:Certificados`
    },
    children: [
      {
        path: 'howden',
        component: CertificatesComponent,
        title: $localize`:@@app.route.certificates.howden:Howden`,
        data: {
          certificateType: CertificateType.Howden,
          linkText: $localize`:@@app.route.certificates.howden:Howden`
        }
      },
      {
        path: 'insuranceCompany',
        component: CertificatesComponent,
        title: $localize`:@@app.route.certificates.insuranceCompany:Compañía`,
        data: {
          certificateType: CertificateType.InsuranceCompany,
          linkText: $localize`:@@app.route.certificates.insuranceCompany:Compañía`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(CertificatesRoutes)],
  exports: [RouterModule]
})
export class CertificatesRoutingModule {
}
