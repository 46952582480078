import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { MediationCodeStatus, ProductStatus } from 'src/app/core/enums';
import { IProduct } from 'src/app/core/models/ida/products';
import { IMediationCode } from 'src/app/core/models/ida/providers';
import { IClientGroupsService } from 'src/app/core/services/api/clients';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IOfficeService } from 'src/app/core/services/api/offices';
import { IProductService } from 'src/app/core/services/api/products';
import { IFileTemplatesService } from 'src/app/core/services/api/templates';
import { IUsersService } from 'src/app/core/services/api/users/users.contract';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyLoadMapper } from '../mappers';
import { PolicyLoadViewModel } from '../models';

@Component({
  selector: 'howden-generate-policy-load-template-dialog',
  templateUrl: './generate-policy-load-template-dialog.component.html',
  styleUrls: ['./generate-policy-load-template-dialog.component.scss']
})
export class GeneratePolicyLoadTemplateDialogComponent implements OnInit, OnDestroy {
  model = new PolicyLoadViewModel();

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('clientGroupInput') clientGroupInputRef!: ElementRef;

  private _subscriptions = new SubSink();

  constructor(
    private dialogRef: MatDialogRef<GeneratePolicyLoadTemplateDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private fleetSrv: IFleetService,
    private officeSrv: IOfficeService,
    private insuranceCompanySrv: IInsuranceCompanyService,
    private productSrv: IProductService,
    private clientGroupSrv: IClientGroupsService,
    private userSrv: IUsersService,
    private templateSrv: IFileTemplatesService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.clientGroupSrv.getClientGroups(),
      this.fleetSrv.getInsuranceCompanies('0'),
      this.fleetSrv.getIndustries('0'),
      this.userSrv.getUsers()
    ]).subscribe(([
      clientGroups,
      insuranceCompanies,
      industries,
      users
    ]) => {
      this.model.clientGroups = clientGroups;
      this.model.insuranceCompanies = insuranceCompanies;
      this.model.industries = industries;
      this.model.users = users;

      this.onChanges();
      this.uiBlockerSrv.unblock();

      this.clientGroupInputRef.nativeElement.focus();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const request = PolicyLoadMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.templateSrv.getPolicyLoadTemplate(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_PLANTILLA_CARGA_POLIZAS', blob);

        this.closeDialog(DialogResultTypes.OK);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getClientGroupName(clientGroupId: string): string {
    return this.model.clientGroups?.find(x => x.groupId === clientGroupId)?.name ?? '';
  }

  getOfficeDescription(officeId: string): string {
    return this.model.offices?.find(x => x.officeId === officeId)?.name ?? '';
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries?.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getMainProductName(productId: string): string {
    const product = this.model.mainProducts?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  getSecondaryProductName(productId: string): string {
    const product = this.model.secondaryProducts?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  getMainMediationCodeDescription(mediationCodeId: string): string {
    const mediationCode = this.model.mainMediationCodes?.find(x => x.mediationCodeId === mediationCodeId);
    return mediationCode ? `${mediationCode.code}-${mediationCode.description}` : '';
  }

  getSecondaryMediationCodeDescription(mediationCodeId: string): string {
    const mediationCode = this.model.secondaryMediationCodes?.find(x => x.mediationCodeId === mediationCodeId);
    return mediationCode ? `${mediationCode.code}-${mediationCode.description}` : '';
  }

  getUserName(userId: string): string {
    return this.model.users?.find(x => x.userId === userId)?.name ?? '';
  }

  private onReplaceChanged(val: boolean) {
    if (val === true) {
      this.model.disableSecondarySection();
    } else {
      this.model.enableSecondarySection();
    }
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_MAIN_REPLACE)
      .valueChanges
      .subscribe((val: boolean) => this.onReplaceChanged(!val));

    this._subscriptions.sink = this.model.getControl(this.model.C_MAIN_INSURANCE_COMPANY_ID)
      .valueChanges
      .subscribe(() => this.onMainInsuranceCompanyChanged());

    this._subscriptions.sink = this.model.getControl(this.model.C_MAIN_INDUSTRY_ID)
      .valueChanges
      .subscribe(() => this.onMainIndustryChanged());

    this._subscriptions.sink = this.model.getControl(this.model.C_SECONDARY_INSURANCE_COMPANY_ID)
      .valueChanges
      .subscribe(() => this.onSecondaryInsuranceCompanyChanged());

    this._subscriptions.sink = this.model.getControl(this.model.C_SECONDARY_INDUSTRY_ID)
      .valueChanges
      .subscribe(() => this.onSecondaryIndustryChanged());

    this._subscriptions.sink = this.model.getControl(this.model.C_CLIENT_GROUP_ID)
      .valueChanges
      .subscribe(() => this.onClientGroupChanged());
  }

  onClientGroupChanged() {
    const clientGroupId: string = this.model.clientGroupId;

    this.model.getControl(this.model.C_MAIN_OFFICE_ID).reset();
    this.model.getControl(this.model.C_SECONDARY_OFFICE_ID).reset();
    this.model.offices = [];

    const clientGroup = this.model.clientGroups.find(x => x.groupId === clientGroupId);

    if (clientGroup) {
      forkJoin([
        this.officeSrv.getOffices(),
        this.clientGroupSrv.getOffices(clientGroupId)
      ]).subscribe(([
        offices,
        clientGroupOffices
      ]) => {
        this.model.offices = offices.filter(x => clientGroupOffices.filter(y => y.officeId === x.officeId).length > 0);

        if (clientGroupOffices.length === 1) {
          this.model.mainOfficeId = clientGroupOffices[0].officeId;
          this.model.secondaryOfficeId = clientGroupOffices[0].officeId;
        }
      });
    }
  }

  private onMainInsuranceCompanyChanged(): void {
    this.onLoadMainProducts();
    this.onLoadMainMediationCodes();
  }

  private onMainIndustryChanged(): void {
    this.onLoadMainProducts();
  }

  private onLoadMainProducts(): void {
    const insuranceCompanyId: string = this.model.mainInsuranceCompanyId;
    const industryId: string = this.model.mainIndustryId;

    this.model.mainProductId = '';
    this.model.mainProducts = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        this.model.mainProducts = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private onLoadMainMediationCodes(): void {
    const insuranceCompanyId: string = this.model.mainInsuranceCompanyId;

    this.model.mainMediationCodeId = '';
    this.model.mainMediationCodes = new Array<IMediationCode>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);

    if (insuranceCompany) {
      this.insuranceCompanySrv.getMediationCodes(insuranceCompanyId).subscribe((data: Array<IMediationCode>) => {
        this.model.mainMediationCodes = data.filter(x => x.status === MediationCodeStatus.Active);
        const defaultMediationCode = this.model.mainMediationCodes?.find(x => x.isDefault === true);
        if (defaultMediationCode) {
          this.model.mainMediationCodeId = defaultMediationCode.mediationCodeId;
        }
      });
    }
  }

  private onSecondaryInsuranceCompanyChanged(): void {
    this.onLoadSecondaryProducts();
    this.onLoadSecondaryMediationCodes();
  }

  private onSecondaryIndustryChanged(): void {
    this.onLoadSecondaryProducts();
  }

  private onLoadSecondaryProducts(): void {
    const insuranceCompanyId: string = this.model.secondaryInsuranceCompanyId;
    const industryId: string = this.model.secondaryIndustryId;

    this.model.secondaryProductId = '';
    this.model.secondaryProducts = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        const products = data.filter(x => x.status === ProductStatus.Active);
        this.model.secondaryProducts = products;
      });
    }
  }

  private onLoadSecondaryMediationCodes(): void {
    const insuranceCompanyId: string = this.model.secondaryInsuranceCompanyId;

    this.model.secondaryMediationCodeId = '';
    this.model.secondaryMediationCodes = new Array<IMediationCode>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);

    if (insuranceCompany) {
      this.insuranceCompanySrv.getMediationCodes(insuranceCompanyId).subscribe((data: Array<IMediationCode>) => {
        this.model.secondaryMediationCodes = data.filter(x => x.status === MediationCodeStatus.Active);
        const defaultMediationCode = this.model.secondaryMediationCodes?.find(x => x.isDefault === true);
        if (defaultMediationCode) {
          this.model.secondaryMediationCodeId = defaultMediationCode.mediationCodeId;
        }
      });
    }
  }
}
