import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { PolicySearchResultViewModel } from '../models';

export abstract class PolicySearchMapper {
  static mapForSearch(source: IPolicySearchResult): PolicySearchResultViewModel {
    return {
      policyId: source.policyId,
      riskAlias: source.riskAlias,
      riskDescription: source.riskDescription,
      policyNumber: source.policyNumber,
      insuranceCompanyId: source.insuranceCompanyId,
      insuranceCompany: source.insuranceCompany,
      industryId: source.industryId,
      industry: source.industry,
      productId: source.productId,
      product: source.product,
      holder: source.holder,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      status: source.status
    } as PolicySearchResultViewModel;
  }
}
