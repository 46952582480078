import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IDashboardService } from 'src/app/core/services/api/dashboards';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { BonusBoardViewModel } from '../models';

@Component({
  selector: 'howden-bonus-board',
  templateUrl: './bonus-board.component.html',
  styleUrls: ['./bonus-board.component.scss']
})
export class BonusBoardComponent implements OnInit {
  model = new BonusBoardViewModel();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private dashboardSrv: IDashboardService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.dashboardSrv.getBonusBoard(fleetId)
    ]).subscribe(([
      bonusBoard
    ]) => {
      this.model.data = bonusBoard;

      this.uiBlockerSrv.unblock();
    });
  }
}
