import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PolicyClass } from 'src/app/core/enums';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IReportsService } from 'src/app/core/services/api/reports';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { RegularizationReportMapper } from '../mappers';
import { RegularizationReportViewModel } from '../models';

@Component({
  selector: 'howden-regularization-report',
  templateUrl: './regularization-report.component.html',
  styleUrls: ['./regularization-report.component.scss']
})
export class RegularizationReportComponent implements OnInit {
  model = new RegularizationReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private policySrv: IPolicyService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const policyFilter = {
      fleetId: fleetId,
      policyClasses: [PolicyClass.Colective.toString(), PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize
    } as IPolicySearchRequest;

    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.search(policyFilter),
      this.fleetSrv.getVehicleTypes(fleetId),
      this.fleetSrv.getVehicleUsages(fleetId)
    ]).subscribe(([
      rootPolicies,
      vehicleTypes,
      vehicleUsages
    ]) => {
      this.model.rootPolicies = rootPolicies.data.map(x => <IItemSelection>{ id: x.policyId, description: `${x.policyNumber}-${x.riskDescription}` });
      this.model.vehicleTypes = vehicleTypes.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.model.vehicleUsages = vehicleUsages.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = RegularizationReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.regularization(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_REGULARIZACION', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
