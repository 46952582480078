import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IDocument } from 'src/app/core/models/ida/document-manager';

export class PolicyEditDocumentsViewModel {
  data: IDocument[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'alias',
      fieldHeader: $localize`:@@app.policies.edit.documents.alias.column:Alias`,
      canSort: true
    },
    {
      fieldName: 'reference',
      fieldHeader: $localize`:@@app.policies.edit.documents.reference.column:Referencia`,
      canSort: true
    },
    {
      fieldName: 'isClientVisible',
      fieldHeader: $localize`:@@app.policies.edit.documents.isClientVisible.column:Publicable cliente`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.CHECK
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.edit.documents.actions.delete.tooltip:Borrar`
  }, {
    name: 'download',
    icon: 'download',
    tooltip: $localize`:@@app.policies.edit.documents.actions.download.tooltip:Descargar`
  }];
}
