import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HowdenUserService } from '@howdeniberia/core-front';
import { EChartsOption } from 'echarts';
import { IStatistic } from 'src/app/core/models/api/core';

@Component({
  selector: 'howden-dashboard-bar-chart',
  templateUrl: './dashboard-bar-chart.component.html',
  styleUrls: ['./dashboard-bar-chart.component.scss']
})
export class DashboardBarChartComponent implements OnChanges {
  @Input() data: Array<IStatistic> = [];
  @Input() valueFormat = '1.0-2';

  options: EChartsOption = {};

  constructor(private _decimalPipe: DecimalPipe, private _userSrv: HowdenUserService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const textColor = this._userSrv.selectedTheme === 'dark' ? 'white' : 'black';

    if (changes['data'].currentValue && changes['data'].currentValue.length > 0) {
      this.options = {
        color: [
          'rgba(124,224,211,1)',
          'rgba(141,116,30,1)',
          'rgba(255,191,63,1)',
          'rgba(244,67,54,1)',
          'rgba(233,30,99,1)',
          'rgba(156,39,176,1)',
          'rgba(103,58,183,1)',
          'rgba(63,81,181,1)',
          'rgba(33,150,243,1)',
          'rgba(3,169,244,1)',
          'rgba(0,188,212,1)',
          'rgba(0,150,136,1)',
          'rgba(76,175,80,1)',
          'rgba(139,195,74,1)',
          'rgba(205,220,57,1)',
          'rgba(255,235,59,1)',
          'rgba(255,193,7,1)',
          'rgba(255,152,0,1)',
          'rgba(255,87,34,1)',
          'rgba(121,85,72,1)',
          'rgba(158,158,158,1)',
          'rgba(96,125,139,1)'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params: any) => params[0].name + ' - <strong>' + this._decimalPipe.transform(params[0].data, this.valueFormat) + ' </strong>'
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          data: this.data.map(x => x.description),
          axisTick: { alignWithLabel: true },
          axisLabel: {
            color: textColor,
            fontSize: 10,
            width: 100,
            overflow: 'truncate',
            rotate: 30
          },
          splitLine: { show: false }
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            color: textColor,
            fontSize: 10,
            formatter: (value: any): any => this._decimalPipe.transform(value, this.valueFormat)
          },
          splitLine: { show: false }

        }],
        series: [{
          type: 'bar',
          data: this.data.map(x => x.value)
        }]
      };
    }
  }
}
