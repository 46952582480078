import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyBillingGroupResult, IPolicyHistory } from 'src/app/core/models/api/policies';

export class PolicyEditInitialStateViewModel {
  private _form: FormGroup;

  readonly C_YEAR = 'year';

  constructor() {
    this._form = new FormGroup({
      [this.C_YEAR]: new FormControl(null, [Validators.required])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get year(): number | null {
    return this.getControl(this.C_YEAR).value;
  }

  set year(value: number | null) {
    this.getControl(this.C_YEAR).setValue(value);
  }

  groups: Array<IPolicyBillingGroupResult> = [];
  history: Array<IPolicyHistory> = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.policyNumber.column:Nº.Póliza`,
      canSort: true
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'riskPlateNumber',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.riskPlateNumber.column:Riesgo`,
      canSort: true
    },
    {
      fieldName: 'riskCarNumber',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.riskCarNumber.column:Nº. Coche`,
      canSort: true
    },
    {
      fieldName: 'startDate',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.startDate.column:Desde`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'endDate',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.endDate.column:Hasta`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'dayCount',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.dayCount.column:Nº.Días`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'paralizationStart',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.paralizationStart.column:I.Para.`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'paralizationEnd',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.paralizationEnd.column:F.Para.`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'paralizationDayCount',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.paralizationDayCount.column:D.Para.`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'totalDayCount',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.totalDayCount.column:Total días`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'totalAmount',
      fieldHeader: $localize`:@@app.policies.edit.initial.state.totalAmount.column:Importe`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
