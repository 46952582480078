import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IPolicyBillingGroup } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicyEditBillinGroupMapper } from '../mappers';
import { PolicyEditBillingGroupViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-billing-group-dialog',
  templateUrl: './policy-edit-billing-group-dialog.component.html',
  styleUrls: ['./policy-edit-billing-group-dialog.component.scss']
})
export class PolicyEditBillingGroupDialogComponent implements OnInit {
  model = new PolicyEditBillingGroupViewModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditBillingGroupDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const policyId = this.inputData.policyId as string;
    const billingGroupId = this.inputData.billingGroupId;

    if (typeof billingGroupId !== 'undefined' && billingGroupId !== null) {
      this.policySrv.getBillingGroup(policyId, billingGroupId).subscribe((data: IPolicyBillingGroup) => {
        PolicyEditBillinGroupMapper.mapForEdit(data, this.model);
      });
    }
  }

  onAccept() {
    const policyId = this.inputData.policyId as string;
    const request = PolicyEditBillinGroupMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.saveBillingGroup(policyId, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
