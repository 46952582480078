import { IUnit } from 'src/app/core/models/api/units/unit';
import { UnitEditViewModel } from '../models';

export abstract class UnitEditMapper {
  static mapForEdit(id: string, source: IUnit, target: UnitEditViewModel) {
    target.unitId = id;
    target.bonusGroup = source.bonusGroup;
    target.observations = source.observations;

    if (source.vehicle) {
      target.carNumber = source.vehicle.carNumber;
      target.plateNumber = source.vehicle.plateNumber;
      target.registrationDate = source.vehicle.registrationDate;
      target.vehicleTypeId = source.vehicle.vehicleTypeId;
      target.vehicleUsageId = source.vehicle.vehicleUsageId;
      target.movementArea = source.vehicle.movementArea;
      target.brand = source.vehicle.brand;
      target.model = source.vehicle.model;
      target.version = source.vehicle.version;
      target.maxAdmittedWeight = source.vehicle.maxAdmittedWeight;
      target.value = source.vehicle.value;
      target.valueExtras = source.vehicle.valueExtras;
      target.busNumber = source.vehicle.busNumber;
      target.bodyworkNumber = source.vehicle.bodyworkNumber;
      target.bodywork = source.vehicle.bodywork;
      target.busType = source.vehicle.busType;
      target.entertaimentBus = source.vehicle.entertaimentBus;
      target.seats = source.vehicle.seats;
      target.seatNumber = source.vehicle.seatNumber;
      target.rentingCompanyFiscalCode = source.vehicle.rentingCompanyFiscalCode;
      target.rentingStartDate = source.vehicle.rentingStartDate;
      target.rentingExpirationDate = source.vehicle.rentingExpirationDate;
      target.power = source.vehicle.power;
      target.division = source.vehicle.division;
      target.zone = source.vehicle.zone;
      target.contract = source.vehicle.contract;
      target.owner = source.vehicle.owner;
      target.lesseeId = source.vehicle.lesseeId;
      target.userId = source.vehicle.userId;
      target.userZone = source.vehicle.userZone;
      target.age = source.vehicle.age;
      target.configuration = source.vehicle.configuration;
      target.length = source.vehicle.length;
      target.kilometers = source.vehicle.kilometers;
      target.category = source.vehicle.category;
      target.class = source.vehicle.class;
      target.euroClass = source.vehicle.euroClass;
      target.workshop = source.vehicle.workshop;
      target.chassisNumber = source.vehicle.chassisNumber;
      target.td = source.vehicle.td;
      target.wifi = source.vehicle.wifi;
      target.wifiName = source.vehicle.wifiName;
      target.contractedMaintenance = source.vehicle.contractedMaintenance;
      target.schoolBus = source.vehicle.schoolBus;
      target.safeBelts = source.vehicle.safeBelts;
      target.individualEntertaiment = source.vehicle.individualEntertaiment;
      target.platform = source.vehicle.platform;
      target.pmrsr = source.vehicle.pmrsr;
      target.cameras = source.vehicle.cameras;
      target.manufacturingMonth = source.vehicle.manufacturingMonth;
      target.manufacturingYear = source.vehicle.manufacturingYear;
      target.concession = source.vehicle.concession;
      target.goodsTypeId = source.vehicle.goodsTypeId;
      target.registrationClassification = source.vehicle.registrationClassification?.toString();
      target.classificationCodeCL = source.vehicle.classificationCodeCL;
      target.functionalTypeId = source.vehicle.functionalTypeId;
      target.circulationAreaId = source.vehicle.circulationAreaId;
    }
  }

  static mapForUpdate(source: UnitEditViewModel): IUnit {
    return {
      bonusGroup: source.bonusGroup,
      observations: source.observations,
      vehicle: {
        carNumber: source.carNumber,
        plateNumber: source.plateNumber,
        registrationDate: source.registrationDate,
        vehicleTypeId: source.vehicleTypeId,
        vehicleUsageId: source.vehicleUsageId,
        movementArea: source.movementArea,
        brand: source.brand,
        model: source.model,
        version: source.version,
        maxAdmittedWeight: source.maxAdmittedWeight,
        value: source.value,
        valueExtras: source.valueExtras,
        busNumber: source.busNumber,
        bodyworkNumber: source.bodyworkNumber,
        bodywork: source.bodywork,
        busType: source.busType,
        entertaimentBus: source.entertaimentBus,
        seats: source.seats,
        seatNumber: source.seatNumber,
        rentingCompanyFiscalCode: source.rentingCompanyFiscalCode,
        rentingStartDate: source.rentingStartDate,
        rentingExpirationDate: source.rentingExpirationDate,
        power: source.power,
        division: source.division,
        zone: source.zone,
        contract: source.contract,
        owner: source.owner,
        lesseeId: source.lesseeId === '' ? undefined : source.lesseeId,
        userId: source.userId === '' ? undefined : source.userId,
        userZone: source.userZone,
        age: source.age,
        configuration: source.configuration,
        length: source.length,
        kilometers: source.kilometers,
        category: source.category,
        class: source.class,
        euroClass: source.euroClass,
        workshop: source.workshop,
        chassisNumber: source.chassisNumber,
        td: source.td,
        wifi: source.wifi,
        wifiName: source.wifiName,
        contractedMaintenance: source.contractedMaintenance,
        schoolBus: source.schoolBus,
        safeBelts: source.safeBelts,
        individualEntertaiment: source.individualEntertaiment,
        platform: source.platform,
        pmrsr: source.pmrsr,
        cameras: source.cameras,
        manufacturingMonth: source.manufacturingMonth,
        manufacturingYear: source.manufacturingYear,
        concession: source.concession,
        goodsTypeId: source.goodsTypeId === '' ? undefined : source.goodsTypeId,
        registrationClassification: source.registrationClassification ? parseInt(source.registrationClassification) : null,
        classificationCodeCL: source.classificationCodeCL,
        functionalTypeId: source.functionalTypeId === '' ? undefined : source.functionalTypeId,
        circulationAreaId: source.circulationAreaId === '' ? undefined : source.circulationAreaId
      }
    } as IUnit;
  }
}
