import { IFleetSetting } from 'src/app/core/models/api/fleets';

export class FleetEditSettingsViewModel {
  private _insuranceCompanySettings: Array<IFleetSetting> = [];
  private _industrySettings: Array<IFleetSetting> = [];
  private _vehicleTypeSettings: Array<IFleetSetting> = [];
  private _vehicleUsageSettings: Array<IFleetSetting> = [];

  get insuranceCompanySettings(): Array<IFleetSetting> {
    return this._insuranceCompanySettings;
  }

  set insuranceCompanySettings(value: Array<IFleetSetting>) {
    this._insuranceCompanySettings = value;
  }

  get industrySettings(): Array<IFleetSetting> {
    return this._industrySettings;
  }

  set industrySettings(value: Array<IFleetSetting>) {
    this._industrySettings = value;
  }

  get vehicleTypeSettings(): Array<IFleetSetting> {
    return this._vehicleTypeSettings;
  }

  set vehicleTypeSettings(value: Array<IFleetSetting>) {
    this._vehicleTypeSettings = value;
  }

  get vehicleUsageSettings(): Array<IFleetSetting> {
    return this._vehicleUsageSettings;
  }

  set vehicleUsageSettings(value: Array<IFleetSetting>) {
    this._vehicleUsageSettings = value;
  }
}
