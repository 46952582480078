import { ICirculationArea } from 'src/app/core/models/api/units';
import { UpsertCirculationAreaViewModel } from '../models';

export abstract class UpsertCirculationAreaTypeMapper {
  static mapForUpdate(source: UpsertCirculationAreaViewModel): ICirculationArea {
    return {
      description: source.description
    } as ICirculationArea;
  }
}
