<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">

    <div mat-dialog-title class="title-text" i18n="@@app.functional.types.upsert.title">Mantenimiento de tipos funcionales</div>

    <mat-dialog-content>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.functional.types.upsert.description.label">Descripción</mat-label>
          <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
          <mat-error #descriptionError></mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
