<div class="flex margin-top-10">
  <howden-table
    class="small-font"
    keyField="receiptId"
    paginable="local"
    sortable="local"
    filterable="global-local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.data"
  >
  </howden-table>
</div>
