<mat-grid-list cols="{{(cardLayout | async)?.columns }}" rowHeight="50px">

  <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
    <howden-label-value
      labelText="Flota"
      i18n-labelText="@@app.dashboard.header.name.label"
      value="{{fleetName}}"
    ></howden-label-value>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
    <howden-label-value
      labelText="Cliente"
      i18n-labelText="@@app.dashboard.header.client.label"
      class="ellipsis-text"
      value="{{clientName}}"
    ></howden-label-value>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
    <howden-label-value
      labelText="NIF/CIF"
      i18n-labelText="@@app.dashboard.header.fiscalCode.label"
      value="{{clientFiscalCode}}"
    ></howden-label-value>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
    <howden-label-value
      class="ellipsis-text"
      labelText="Actividad (CNAE)"
      i18n-labelText="@@app.dashboard.header.cnaeDescription.label"
      value="{{cnaeDescription}}"
    ></howden-label-value>
  </mat-grid-tile>

</mat-grid-list>
