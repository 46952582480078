import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IClaimPaymentDate } from 'src/app/core/models/api/lta';

export class ClaimsByCompanyReportViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IItemSelection> = [];
  private _claimPaymentDates: Array<IClaimPaymentDate> = [];

  readonly C_CLAIM_PAYMENT_DATE_ID = 'paymentDateId';
  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_CLAIM_PAYMENT_DATE_ID]: new FormControl(null, [Validators.required]),
      [this.C_START_DATE]: new FormControl(null, [Validators.required]),
      [this.C_END_DATE]: new FormControl(null, [Validators.required])
    });
  }

  get rootPolicies(): Array<IItemSelection> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IItemSelection>) {
    this._rootPolicies = value;
  }

  get claimPaymentDates(): Array<IClaimPaymentDate> {
    return this._claimPaymentDates;
  }

  set claimPaymentDates(value: Array<IClaimPaymentDate>) {
    this._claimPaymentDates = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get claimPaymentId(): string | null {
    return this.getControl(this.C_CLAIM_PAYMENT_DATE_ID).value;
  }

  set claimPaymentId(value: string | null) {
    this.getControl(this.C_CLAIM_PAYMENT_DATE_ID).setValue(value);
  }

  get startDate(): Date | null {
    return this.getControl(this.C_START_DATE).value;
  }

  set startDate(value: Date | null) {
    this.getControl(this.C_START_DATE).setValue(value);
  }

  get endDate(): Date | null {
    return this.getControl(this.C_END_DATE).value;
  }

  set endDate(value: Date | null) {
    this.getControl(this.C_END_DATE).setValue(value);
  }
}
