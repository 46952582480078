import { FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import {
  AutocompleteComponent,
  DialogResultTypes,
  IDialogResult
} from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IUnit, IUnitAllowedActions } from 'src/app/core/models/api/units';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { UnitFleetTransferDialogComponent } from '../../unit-fleet-transfer-dialog/pages/unit-fleet-transfer-dialog.component';
import { UnitPlateNumberChangeDialogComponent } from '../../unit-plate-number-change-dialog/pages/unit-plate-number-change-dialog.component';
import { UnitEditMapper } from '../mappers';
import { UnitEditViewModel } from '../models';

@Component({
  selector: 'howden-edit-unit',
  templateUrl: './unit-edit.component.html',
  styleUrls: ['./unit-edit.component.scss']
})
export class UnitEditComponent implements OnInit, OnDestroy {
  model = new UnitEditViewModel();

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('vehicleTypeSelectInput') vehicleTypeSelectInput!: AutocompleteComponent;

  private _subscriptions = new SubSink();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private unitSrv: IUnitService
  ) {
  }

  ngOnInit() {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        const fleetId = this.sessionSrv.activeFleet;
        const unitId = paramMap.get('id') as string;

        this.uiBlockerSrv.block();

        forkJoin([
          this.fleetSrv.getVehicleTypes(fleetId),
          this.fleetSrv.getVehicleUsages(fleetId),
          this.fleetSrv.getCompanies(fleetId),
          this.unitSrv.getMovementAreas(),
          this.unitSrv.getGoodsTypes(),
          this.unitSrv.getFunctionalTypes(),
          this.unitSrv.getCirculationAreas()
        ]).pipe(
          tap(([vt, vu, fc, ma, gt, ft, ca]) => {
            this.model.vehicleTypes = vt;
            this.model.vehicleUsages = vu;
            this.model.fleetCompanies = fc;
            this.model.movementAreas = ma;
            this.model.goodsTypes = gt;
            this.model.functionalTypes = ft;
            this.model.circulationAreas = ca;

            this.reload(unitId);

            this.uiBlockerSrv.unblock();

            this.vehicleTypeSelectInput.focus();
          })
        ).subscribe();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onTabChanged(event: MatTabChangeEvent): void {
    switch (event.index) {
      case 2: //Claims
        this.loadClaims();
        break;
    }
  }

  onSave(): void {
    const request = UnitEditMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.unitSrv.update(this.model.unitId, request).pipe(
      tap({
        next: () => {
          const title: string = $localize`:@@app.units.edit.save.dialog.title:Edición de unidades`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.reload(this.model.unitId));
        },
        complete: () => this.uiBlockerSrv.unblock()
      })
    ).subscribe();
  }

  onUnitFleetTransfer(): void {
    const inputData = { id: this.model.unitId };
    const dialogRef = this.dialog.open(UnitFleetTransferDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.units.edit.transfer.dialog.title:Cambio de flota`;
          const subtitle: string = $localize`:@@app.units.edit.transfer.dialog.subtitle:Finalizado correctamente`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reload(this.model.unitId));
        }
      })
    ).subscribe();
  }

  onUnitPlateNumberChange(): void {
    const inputData = {
      id: this.model.unitId,
      plateNumber: this.model.plateNumber
    };
    const dialogRef = this.dialog.open(UnitPlateNumberChangeDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.units.edit.plate.number.change.dialog.title:Cambio de matrícula`;
          const subtitle: string = $localize`:@@app.units.edit.plate.number.change.dialog.subtitle:Finalizado correctamente`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reload(this.model.unitId));
        }
      })
    ).subscribe();
  }

  onGuessRegistrationDate() {
    const plateNumber = this.model.plateNumber as string;

    this.uiBlockerSrv.block();

    this.unitSrv.guessRegistrationDate(plateNumber).subscribe({
      next: (result) => this.model.registrationDate = result.result,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  months(): ReadonlyArray<string> {
    return getLocaleMonthNames(this.locale, FormStyle.Standalone, TranslationWidth.Wide);
  }

  private loadClaims(): void {
    this.uiBlockerSrv.block();
    this.unitSrv.getClaims(this.model.unitId).subscribe({
      next: (data: Array<IDetailedClaim>) => this.model.claims = data,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  private reload(id: any): void {
    this.uiBlockerSrv.block();
    this.unitSrv.get(id).pipe(
      tap({
        next: (value: IUnit) => {
          UnitEditMapper.mapForEdit(id, value, this.model);
          this.unitSrv.getAllowedActions(this.model.unitId).subscribe((allowedActions: IUnitAllowedActions) => {
            this.model.allowFleetTransfer = allowedActions.fleetTransfer;
            this.model.allowPlateNumberChange = allowedActions.plateNumberChange;
          });
        },
        complete: () => this.uiBlockerSrv.unblock()
      })
    ).subscribe();
  }
}
