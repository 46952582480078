import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IPolicyParalization } from 'src/app/core/models/api/policies';

export class PolicyEditParalizationsViewModel {
  data: IPolicyParalization[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'startDate',
      fieldHeader: $localize`:@@app.policies.edit.paralizations.startDate.column:Fecha inicio`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'endDate',
      fieldHeader: $localize`:@@app.policies.edit.paralizations.endDate.column:Fecha fin`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.edit.paralizations.actions.delete.tooltip:Borrar`
  }, {
    name: 'edit',
    icon: 'edit',
    tooltip: $localize`:@@app.policies.edit.paralizations.actions.edit.tooltip:Editar`
  }];
}
