import { IEndorsementSummary } from 'src/app/core/models/api/endorsements';

export class EndorsementSummaryViewModel {
  constructor(private endorsement?: IEndorsementSummary) { }

  get receiptId(): string | null | undefined {
    return this.endorsement?.receiptId;
  }

  get alias(): string | null | undefined {
    return this.endorsement?.alias;
  }

  get endorsementType(): string | null | undefined {
    return this.endorsement?.endorsementType;
  }
  get description(): string | null | undefined {
    return this.endorsement?.description;
  }

  get endorsementStatus(): string | null | undefined {
    return this.endorsement?.endorsementStatus;
  }

  get requestDate(): Date | null | undefined {
    return this.endorsement?.requestDate;
  }

  get dateSentToInsuranceCompany(): Date | null | undefined {
    return this.endorsement?.dateSentToInsuranceCompany;
  }
  get receptionDate(): Date | null | undefined {
    return this.endorsement?.receptionDate;
  }

  get effectDate(): Date | null | undefined {
    return this.endorsement?.effectDate;
  }

  get expirationDate(): Date | null | undefined {
    return this.endorsement?.expirationDate;
  }

  get cancellationDate(): Date | null | undefined {
    return this.endorsement?.cancellationDate;
  }

  get insuranceCompanyReference(): string | null | undefined {
    return this.endorsement?.insuranceCompanyReference;
  }

  get netBonus(): number | null | undefined {
    return this.endorsement?.netBonus;
  }

  get receiptTotal(): number | null | undefined {
    return this.endorsement?.receiptTotal;
  }

  get currency(): string | null | undefined {
    return this.endorsement?.currency;
  }

  get office(): string | null | undefined {
    return this.endorsement?.office;
  }

  get client(): string | null | undefined {
    return this.endorsement?.client;
  }

  get createdBy(): string | null | undefined {
    return this.endorsement?.createdBy;
  }

  get createdAt(): string | null | undefined {
    return this.endorsement?.createdAt;
  }

  get modifiedBy(): string | null | undefined {
    return this.endorsement?.modifiedBy;
  }

  get modifiedAt(): string | null | undefined {
    return this.endorsement?.modifiedAt;
  }

  get insuranceCompanyReceiptNumber(): string | null | undefined {
    return this.endorsement?.insuranceCompanyReceiptNumber;
  }

  get receiptNumber(): string | null | undefined {
    return this.endorsement?.receiptNumber;
  }
  get receiptEffectDate(): Date | null | undefined {
    return this.endorsement?.receiptEffectDate;
  }

  get receiptExpirationDate(): Date | null | undefined {
    return this.endorsement?.expirationDate;
  }

  get receiptCancellationDate(): Date | null | undefined {
    return this.endorsement?.receiptCancellationDate;
  }

  get receiptConcept(): string | null | undefined {
    return this.endorsement?.receiptConcept;
  }
}
