import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MediationCodeStatus, PolicyClass } from 'src/app/core/enums';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IPolicy } from 'src/app/core/models/api/policies';
import { IMediationCode } from 'src/app/core/models/ida/providers';

export class PolicyEditAssignRisksViewModel {
  private _form: FormGroup;
  private _policy: IPolicy | null = null;
  private _bookingModes: Array<IBookingModeSearchResult> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _mediationCodes: Array<IMediationCode> = [];

  readonly C_FIRST_EFFECT_DATE = 'firstEffectDate';
  readonly C_EFFECT_DATE = 'effectDate';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_INSURED_ID = 'insuredId';
  readonly C_MEDIATION_CODE_ID = 'mediationCodeId';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';

  constructor() {
    this._form = new FormGroup({
      [this.C_FIRST_EFFECT_DATE]: new FormControl(null, []),
      [this.C_EFFECT_DATE]: new FormControl(null, []),
      [this.C_EXPIRATION_DATE]: new FormControl(null, []),
      [this.C_HOLDER_ID]: new FormControl(null, []),
      [this.C_INSURED_ID]: new FormControl(null, []),
      [this.C_MEDIATION_CODE_ID]: new FormControl(null, []),
      [this.C_BOOKING_MODE_ID]: new FormControl(null, [])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingModeSearchResult>) {
    this._bookingModes = value;
  }

  get mediationCodes(): Array<IMediationCode> {
    return this._mediationCodes;
  }

  set mediationCodes(value: Array<IMediationCode>) {
    this._mediationCodes = value.filter(x => x.status === MediationCodeStatus.Active);
  }

  get policy(): IPolicy | null {
    return this._policy;
  }

  set policy(value: IPolicy | null) {
    this._policy = value;
  }

  get isRoot(): boolean {
    return this._policy?.policyClass === PolicyClass.Other;
  }

  get isColective(): boolean {
    return this._policy?.policyClass === PolicyClass.Colective;
  }

  get form(): FormGroup {
    return this._form;
  }

  get firstEffectDate(): Date | null | undefined {
    return this.getControl(this.C_FIRST_EFFECT_DATE).value;
  }

  set firstEffectDate(value: Date | null | undefined) {
    this.getControl(this.C_FIRST_EFFECT_DATE).setValue(value);
  }

  get effectDate(): Date | null | undefined {
    return this.getControl(this.C_EFFECT_DATE).value;
  }

  set effectDate(value: Date | null | undefined) {
    this.getControl(this.C_EFFECT_DATE).setValue(value);
  }

  get expirationDate(): Date | null | undefined {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | null | undefined) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get holderId(): string | null | undefined {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string | null | undefined) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get insuredId(): string | null | undefined {
    return this.getControl(this.C_INSURED_ID).value;
  }

  set insuredId(value: string | null | undefined) {
    this.getControl(this.C_INSURED_ID).setValue(value);
  }

  get mediationCodeId(): string | null | undefined {
    return this.getControl(this.C_MEDIATION_CODE_ID).value;
  }

  set mediationCodeId(value: string | null | undefined) {
    this.getControl(this.C_MEDIATION_CODE_ID).setValue(value);
  }

  get bookingModeId(): string | null | undefined {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | null | undefined) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }
}
