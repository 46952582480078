import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IDetailedEndorsementView } from './detailed-endorsement-view';

export class PolicyEditEndorsementsViewModel {
  data: Array<IDetailedEndorsementView> = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'endorsementId',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.endorsementId.column:ID`,
      canSort: true
    },
    {
      fieldName: 'endorsementType',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.endorsementType.column:Tipo`,
      canSort: true
    },
    {
      fieldName: 'requestDate',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.requestDate.column:Fecha`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'alias',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.alias.column:Alias`,
      canSort: true
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.description.column:Descripción`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.effectDate.column:Fecha efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.expirationDate.column:Fecha venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'cancellationDate',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.cancellationDate.column:Fecha baja`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'office',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.office.column:Oficina`,
      canSort: true
    },
    {
      fieldName: 'endorsementStatus',
      fieldHeader: $localize`:@@app.policies.edit.endorsements.endorsementStatus.column:Estado`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.policies.edit.endorsements.actions.info.tooltip:Información`
  }];
}
