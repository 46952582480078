import { IUpdateFleet } from 'src/app/core/models/api/fleets';
import { IFleet } from 'src/app/core/models/api/fleets/fleet';
import { FleetEditMainViewModel } from '../models';

export abstract class FleetEditMainMapper {
  static mapForEdit(source: IFleet, target: FleetEditMainViewModel) {
    target.fleetId = source.fleetId;
    target.name = source.name;
    target.description = source.description;
  }

  static mapForUpdate(source: FleetEditMainViewModel): IUpdateFleet {
    return {
      name: source.name,
      description: source.description
    } as IFleet;
  }
}
