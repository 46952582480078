import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { FleetEditTaxMapper } from '../mappers';
import { FleetEditTaxViewModel } from '../models';

@Component({
  selector: 'howden-fleet-edit-tax-dialog',
  templateUrl: './fleet-edit-tax-dialog.component.html',
  styleUrls: ['./fleet-edit-tax-dialog.component.scss']
})
export class FleetEditTaxDialogComponent implements OnInit, OnDestroy {
  model = new FleetEditTaxViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<FleetEditTaxDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private masterSrv: IMasterService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const taxId = this.taxId;

    if (typeof taxId !== 'undefined' && taxId !== null) {
      this.uiBlockerSrv.block();
      forkJoin([
        this.masterSrv.getPaymentTypes(),
        this.fleetSrv.getTax(fleetId, taxId)
      ]).subscribe(([paymentTypes, tax]) => {
        this.model.paymentTypes = paymentTypes;
        FleetEditTaxMapper.mapForEdit(tax, this.model);
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.masterSrv.getPaymentTypes().subscribe((data: Array<IMasterValue>) => {
        this.model.paymentTypes = data;
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  onAccept(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FleetEditTaxMapper.mapForUpdate(this.taxId, this.model);

    this.uiBlockerSrv.block();
    this.fleetSrv.upsertTax(fleetId, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  private get taxId(): string | null {
    return this.inputData.id as string | null;
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
