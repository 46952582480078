import { IFleetTax } from 'src/app/core/models/api/fleets';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IFleetTaxViewModel } from '../models';

export abstract class FleetEditTaxesMapper {
  static mapForSearch(source: Array<IFleetTax>, paymentTypes: Array<IMasterValue>): Array<IFleetTaxViewModel> {
    return source.map(t => {
      const paymentType = paymentTypes.find(p => p.erpId === t.paymentTypeId);
      return {
        taxId: t.taxId,
        startDate: t.startDate,
        endDate: t.endDate,
        name: t.name,
        paymentType: paymentType?.description,
        value: t.value,
        rcso: t.rcso,
        rc: t.rc,
        sovi: t.sovi
      } as IFleetTaxViewModel;
    });
  }
}
