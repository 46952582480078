import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogResultTypes, HowdenAlertService, HowdenAskForDataService, IButtonActionData, IHowdenAskForData } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IInsuranceDaysService } from 'src/app/core/services/api/insurance-days';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { InsuranceDaysMapper } from '../mappers';
import { InsuranceDaysViewModel } from '../models';

@Component({
  selector: 'howden-insurance-days',
  templateUrl: './insurance-days.component.html',
  styleUrls: ['./insurance-days.component.scss']
})
export class InsuranceDaysComponent implements OnInit, OnDestroy {
  model = new InsuranceDaysViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private alertsSrv: HowdenAlertService,
    private insuranceCompaniesSrv: IInsuranceCompanyService,
    private insuranceDaysSrv: IInsuranceDaysService,
    private askForDataSrv: HowdenAskForDataService
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    const insuranceCompanyId = event.row.insuranceCompanyId;
    const insuranceDays = this.model.data.find(x => x.insuranceCompanyId === insuranceCompanyId);

    if (insuranceDays) {
      const options = {
        title: $localize`:@@app.route.fleets.insurance.days.update.title:Días de seguro`,
        subTitle: insuranceDays.insuranceCompany,
        placeHolder: $localize`:@@app.route.fleets.insurance.days.update.placeHolder:Indique el número de días de seguro`,
        label: $localize`:@@app.route.fleets.insurance.days.update.label:Días de seguro`,
        initialValue: insuranceDays.dayCount.toString(),
        inputType: 'number',
        allowCancel: true
      } as IHowdenAskForData;

      this.askForDataSrv.open(options).pipe(
        tap(result => {
          if (result.result === DialogResultTypes.OK && result.resultData) {
            const dayCount = result.resultData ? parseInt(result.resultData) : 0;
            if (typeof dayCount === 'undefined' || dayCount === null || dayCount < 0 || dayCount > 366) {
              const title: string = $localize`:@@app.fleets.insurance.days.alert.title:Días de seguro`;
              const message: string = $localize`:@@app.fleets.insurance.days.alert.message.1:El número de días de seguro debe estar entre 0 y 366.`;

              this.alertsSrv.error(title, message);
            } else {
              this
                .insuranceDaysSrv
                .update({ insuranceCompanyId: insuranceCompanyId, dayCount: parseInt(result.resultData) })
                .subscribe(() => this.refresh());
            }
          }
        })
      ).subscribe();
    }
  }

  private refresh() {
    this.uiBlockerSrv.block();

    forkJoin([
      this.insuranceCompaniesSrv.get(),
      this.insuranceDaysSrv.get()
    ]).subscribe(([insuranceCompanies, insuranceDays]) => {
      this.model.data = InsuranceDaysMapper.mapForSearch(insuranceCompanies, insuranceDays);
      this.uiBlockerSrv.unblock();
    });
  }
}
