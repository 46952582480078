import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogResultTypes, HowdenTableComponent, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IUnitService } from 'src/app/core/services/api/units';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { PolicySummaryDialogComponent } from 'src/app/features/policies/policy-summary-dialog/pages/policy-summary-dialog.component';
import { UnitCancelRisksDialogComponent } from 'src/app/features/units/unit-cancel-risks-dialog/pages/unit-cancel-risks-dialog.component';
import { SubSink } from 'subsink';
import { UnitEditPoliciesViewModel } from '../models';

@Component({
  selector: 'howden-unit-edit-policies',
  templateUrl: './unit-edit-policies.component.html',
  styleUrls: ['./unit-edit-policies.component.scss']
})
export class UnitEditPoliciesComponent implements OnChanges, OnDestroy {
  model = new UnitEditPoliciesViewModel();

  @Input() unitId = '';
  @ViewChild('unitPoliciesTable') unitPoliciesTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private router: Router,
    private dialog: MatDialog,
    private unitSrv: IUnitService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.unitId && this.unitId.length > 0) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onPolicyAction(event: IButtonActionData) {
    if (event.buttonName === 'edit') {
      this.navigateToPolicy(event);
    } else {
      this.showPolicyInfo(event);
    }
  }

  onPolicySelectionChanged(event: any[]) {
    this.model.allowRiskCancellation = event.length > 0;
  }

  onCancelRisks(): void {
    const inputData = {
      id: this.unitId,
      selection: this.unitPoliciesTable.selectedRows()
    };
    const dialogRef = this.dialog.open(UnitCancelRisksDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult?.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.units.edit.cancel.risks.dialog.title:Baja de riesgos`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.loadData());
        }
      })
    ).subscribe();
  }

  private loadData(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.unitSrv.getPolicySummary(this.unitId)
    ]).subscribe(([policies]) => {
      this.model.data = policies;
      this.uiBlockerSrv.unblock();
    });
  }

  private navigateToPolicy(event: IButtonActionData) {
    this.router.navigate(['policies/edit', event.row.policyId]);
  }

  private showPolicyInfo(event: IButtonActionData) {
    const inputData = { id: event.row.policyId };
    const dialogRef = this.dialog.open(PolicySummaryDialogComponent, {
      minWidth: '600px',
      width: '800px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }
}
