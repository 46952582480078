<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title>
      <span class="title-text" i18n="@@app.units.fleet.transfer.title">Cambio de flota</span>
      <span class="subtitle-text" i18n="@@app.units.fleet.transfer.subtitle">
        Por favor, indique los datos del cambio de flota.
      </span>
    </div>

    <mat-dialog-content>
      <div class="flex f-fd--column">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.units.fleet.transfer.targetFleetId.label">Flota de destino</mat-label>
          <input
            matInput
            [formControlName]="model.C_TARGET_FLEET_ID"
            [matAutocomplete]="targetFleetIdSelect"
            [howdenControlError]="targetFleetIdError"
          />
          <mat-autocomplete #targetFleetIdSelect="matAutocomplete" [displayWith]="getFleetName.bind(this)">
            @for (item of model.filteredFleets | async; track item.fleetId) {
              <mat-option [value]="item.fleetId">
                {{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #targetFleetIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-fd--column">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.units.fleet.transfer.targetRootPolicyId.label">Póliza madre de destino</mat-label>
          <input
            matInput
            [formControlName]="model.C_TARGET_ROOT_POLICY_ID"
            [matAutocomplete]="targetRootPolicySelect"
            [howdenControlError]="targetRootPolicyIdError"
          />
          <mat-autocomplete
            #targetRootPolicySelect="matAutocomplete"
            [displayWith]="getRootPolicyDescription.bind(this)"
            >
            @for (item of model.filteredRootPolicies | async; track item.policyId) {
              <mat-option [value]="item.policyId">
                {{ item.policyNumber }}-{{ item.riskDescription }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #targetRootPolicyIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-fd--column">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.units.fleet.transfer.holderId.label">Tomador</mat-label>
          <input matInput
            [formControlName]="model.C_HOLDER_ID"
            [matAutocomplete]="holderIdSelect"
            [howdenControlError]="holderIdError"
          />
          <mat-autocomplete #holderIdSelect="matAutocomplete" [displayWith]="getCompanyName.bind(this)">
            @for (item of model.filteredHolders | async; track item.companyId) {
              <mat-option [value]="item.companyId">
                {{ item.clientName }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #holderIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-fd--column">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.units.fleet.transfer.insuredId.label">Asegurado</mat-label>
          <input matInput [formControlName]="model.C_INSURED_ID" [matAutocomplete]="insuredIdSelect"
            [howdenControlError]="insuredIdError" />
            <mat-autocomplete #insuredIdSelect="matAutocomplete" [displayWith]="getCompanyName.bind(this)">
              @for (item of model.filteredInsureds | async; track item.companyId) {
                <mat-option [value]="item.companyId">
                  {{ item.clientName }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #insuredIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-fd--row f-gap--10px">
          <mat-form-field class="f-basis--6">
            <mat-label i18n="@@app.units.fleet.transfer.effectDate.label">F. Efecto</mat-label>
            <input
              matInput
              [matDatepicker]="effectDatePicker"
              [formControlName]="model.C_EFFECT_DATE"
              [howdenControlError]="effectDatePickerError"
              [min]="minDate"
            />
            <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectDatePicker></mat-datepicker>
            <mat-error #effectDatePickerError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--6">
            <mat-label i18n="@@app.units.fleet.transfer.expirationDate.label">F. Vencimiento</mat-label>
            <input
              matInput
              [matDatepicker]="expirationDatePicker"
              [formControlName]="model.C_EXPIRATION_DATE"
              [howdenControlError]="expirationDatePickerError"
              [min]="minDate"
            />
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
            <mat-error #expirationDatePickerError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-fd--column f-gap--10">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.units.fleet.transfer.bookingModeId.label">Modo de contratación</mat-label>
            <mat-select
              [formControlName]="model.C_BOOKING_MODE_ID"
              [howdenControlError]="bookingModeIdError"
              [required]="model.isBookingModeRequired"
            >
              <mat-option></mat-option>
              @for (item of model.bookingModes; track item.bookingModeId) {
                <mat-option [value]="item.bookingModeId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #bookingModeIdError></mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button type="button" mat-raised-button (click)="onCancel()">
          <span i18n="@@app.core.actions.cancel">Cancelar</span>
        </button>

        <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
          <span i18n="@@app.core.actions.accept">Aceptar</span>
        </button>
      </mat-dialog-actions>
    </div>
  </form>
