import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';

export class FleetEditMainViewModel {
  private _form: FormGroup;

  readonly C_ID = 'fleetId';
  readonly C_NAME = 'name';
  readonly C_DESCRIPTION = 'description';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl('', []),
      [this.C_NAME]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_DESCRIPTION]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart])
    });

    this._form.get(this.C_ID)?.disable();
  }

  get form(): FormGroup {
    return this._form;
  }

  get fleetId(): string {
    return this._form.get(this.C_ID)?.value;
  }

  set fleetId(value: string) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get name(): string {
    return this._form.get(this.C_NAME)?.value;
  }

  set name(value: string) {
    this._form.get(this.C_NAME)?.setValue(value);
  }

  get description(): string {
    return this._form.get(this.C_DESCRIPTION)?.value;
  }

  set description(value: string) {
    this._form.get(this.C_DESCRIPTION)?.setValue(value);
  }
}
