<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title>
      <span class="title-text" i18n="@@app.policies.edit.certificate.replacement.title">Reemplazo / Traspaso</span>
      <br />
      <span class="subtitle-text" i18n="@@app.policies.edit.certificate.replacement.subtitle">
        Por favor, indique los datos del reemplazo/traspaso de la póliza.
      </span>
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.policies.edit.certificate.replacement.targetRootPolicyId.label">
            Póliza madre de destino
          </mat-label>
          <input
            matInput
            [formControlName]="model.C_TARGET_ROOT_POLICY_ID"
            [matAutocomplete]="targetRootPolicySelect"
            [howdenControlError]="targetRootPolicyIdError"
          />
          <mat-autocomplete
            #targetRootPolicySelect="matAutocomplete"
            [displayWith]="getRootPolicyDescription.bind(this)"
            >
            @for (item of model.filteredRootPolicies | async; track item.policyId) {
              <mat-option [value]="item.policyId">
                {{ item.policyNumber }}-{{ item.riskDescription }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #targetRootPolicyIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <howden-autocomplete
          class="f-basis--12"
          i18n-label="@@app.policies.edit.certificate.replacement.holderId.label"
          label="Tomador"
          displayField="clientName"
          keyField="companyId"
          [selectableItems]="model.fleetCompanies"
          [formControlName]="model.C_HOLDER_ID"
      >
        </howden-autocomplete>
      </div>

      @if (hasSameHolder) {
        <div class="flex f-gap--10px margin-bottom-10" i18n="@@app.policies.edit.certificate.replacement.holder.warning">
          El tomador indicado es el mismo que el actualmente asignado a la póliza a reemplazar.
        </div>
      }

      <div class="flex f-gap--10px margin-bottom-10">
        <mat-slide-toggle
          class="f-basis--6 f-as--center"
          [formControlName]="model.C_KEEP_CURRENT_INSURED"
          i18n="@@app.policies.edit.certificate.replacement.keeyCurrentInsured.label"
        >
          Mantener asegurado actual
        </mat-slide-toggle>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.policies.edit.certificate.replacement.operationDate.label">F. Operación</mat-label>
          <input
            matInput
            [matDatepicker]="operationDatePicker"
            [formControlName]="model.C_OPERATION_DATE"
            [howdenControlError]="operationDateError"
            [min]="minDate"
          />
          <mat-error #operationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #operationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--6">
          <mat-label i18n="@@app.policies.edit.certificate.replacement.policyNumber.label">Nº. Póliza</mat-label>
          <input matInput [formControlName]="model.C_POLICY_NUMBER" maxlength="20" />
          <mat-error #operationDateError></mat-error>
          @if (model.getControl(model.C_POLICY_NUMBER).hasError('notAvailable')) {
            <mat-error
            i18n="@@app.policies.edit.number.update.not.available.error">El número de póliza indicado no está disponible.</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="f-basis--6">
          <mat-label i18n="@@app.policies.edit.certificate.replacement.expirationDate.label">F. Vencimiento</mat-label>
          <input
            matInput
            [matDatepicker]="expirationDatePicker"
            [formControlName]="model.C_EXPIRATION_DATE"
            [howdenControlError]="expirationDateError"
            [min]="minDate"
          />
          <mat-error #expirationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <howden-autocomplete
          class="f-basis--12"
          i18n-label="@@app.policies.edit.certificate.replacement.bookingModeId.label"
          label="Modo de contratación"
          displayField="description"
          keyField="bookingModeId"
          [selectableItems]="model.bookingModes"
          [formControlName]="model.C_BOOKING_MODE_ID"
        >
        </howden-autocomplete>
      </div>

      <div class="flex f-gap--10px">
        <howden-decimal
          class="f-basis--4"
          [formControlName]="model.C_NET_BONUS"
          i18n-label="@@app.policies.edit.certificate.replacement.netBonus.label"
          label="Prima neta"
        ></howden-decimal>

        <howden-decimal
          class="f-basis--4"
          [formControlName]="model.C_TOTAL_BONUS"
          i18n-label="@@app.policies.edit.certificate.replacement.totalBonus.label"
          label="Prima total"
        ></howden-decimal>

        <howden-decimal
          class="f-basis--4"
          [formControlName]="model.C_COMMISSION_RATE"
          i18n-label="@@app.policies.edit.certificate.replacement.commissionRate.label"
          label="Comisión (%)"
        ></howden-decimal>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
