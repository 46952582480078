import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { ICirculationArea, ICirculationAreaSearchRequest } from 'src/app/core/models/api/units';

export class CirculationAreaSearchViewModel {
  private _form: FormGroup;
  private _searchRequest: ICirculationAreaSearchRequest;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_DESCRIPTION = 'description';

  constructor() {
    this._form = new FormGroup({
      [this.C_DESCRIPTION]: new FormControl(null, [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this.sortedBy,
      sortDirection: this.sortDirection
    } as ICirculationAreaSearchRequest;

    this.updateServerSideFilters();
  }

  data: ICirculationArea[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'circulationAreaId',
      fieldHeader: $localize`:@@app.circulation.areas.search.functionalTypeId.column:ID`,
      width: '5%',
      canSort: false
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.circulation.areas.search.description.column:Descripción`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.circulation.areas.search.actions.edit.tooltip:Editar`
  }, {
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.circulation.areas.search.actions.delete.tooltip:Borrar`
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get searchRequest(): ICirculationAreaSearchRequest {
    return this._searchRequest;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  get description(): string | null | undefined {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string | null | undefined) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.description = this.description;
  }
}
