import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HowdenUserService } from '@howdeniberia/core-front';
import { EChartsOption } from 'echarts';
import { IReceiptExpirationByDate } from 'src/app/core/models/api/dashboards';

@Component({
  selector: 'howden-receipts-expirations-chart',
  templateUrl: './receipts-expirations-chart.component.html',
  styleUrls: ['./receipts-expirations-chart.component.scss']
})
export class ReceiptsExpirationsChartComponent implements OnChanges {
  @Input() data: Array<IReceiptExpirationByDate> = [];
  @Input() valueFormat = '1.0-2';

  options: EChartsOption = {};

  constructor(private _decimalPipe: DecimalPipe, private _userSrv: HowdenUserService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const textColor = this._userSrv.selectedTheme === 'dark' ? 'white' : 'black';

    if (changes['data'].currentValue && changes['data'].currentValue.length > 0) {
      this.options = {
        legend: {
          align: 'auto',
          backgroundColor: 'white',
          data: [
            $localize`:@@app.dashboards.receipts.expirations.by.date.chart.billed.series.label:Facturado`,
            $localize`:@@app.dashboards.receipts.expirations.by.date.chart.anulado.series.label:Anulado`,
            $localize`:@@app.dashboards.receipts.expirations.by.date.chart.pending.series.label:Pendiente`,
            $localize`:@@app.dashboards.receipts.expirations.by.date.chart.settled.series.label:Liquidado`
          ]
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          data: this.data.map(x => x.monthYear),
          axisTick: { alignWithLabel: true },
          axisLabel: {
            color: textColor,
            fontSize: 10,
            width: 100,
            overflow: 'truncate',
            rotate: 30
          },
          splitLine: { show: false }
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            color: textColor,
            fontSize: 10,
            formatter: (value: any): any => this._decimalPipe.transform(value, this.valueFormat)
          },
          splitLine: { show: false }
        }],
        series: [{
          type: 'bar',
          data: this.data.map(x => x.billedAmount),
          name: $localize`:@@app.dashboards.receipts.expirations.by.date.chart.billed.series.label:Facturado`,
          label: {
            show: false,
            formatter: function (_) {
              return $localize`:@@app.dashboards.receipts.expirations.by.date.chart.billed.series.label:Facturado`;
            }
          }
        }, {
          type: 'bar',
          data: this.data.map(x => x.cancelledAmount),
          name: $localize`:@@app.dashboards.receipts.expirations.by.date.chart.anulado.series.label:Anulado`,
          label: {
            show: false,
            formatter: function (_) {
              return $localize`:@@app.dashboards.receipts.expirations.by.date.chart.anulado.series.label:Anulado`;
            }
          }
        }, {
          type: 'bar',
          data: this.data.map(x => x.pendingAmount),
          name: $localize`:@@app.dashboards.receipts.expirations.by.date.chart.pending.series.label:Pendiente`,
          label: {
            show: false,
            formatter: function (_) {
              return $localize`:@@app.dashboards.receipts.expirations.by.date.chart.pending.series.label:Pendiente`;
            }
          }
        }, {
          type: 'bar',
          data: this.data.map(x => x.settledAmount),
          name: $localize`:@@app.dashboards.receipts.expirations.by.date.chart.settled.series.label:Liquidado`,
          label: {
            show: false,
            formatter: function (_) {
              return $localize`:@@app.dashboards.receipts.expirations.by.date.chart.settled.series.label:Liquidado`;
            }
          }
        }]
      };
    }
  }
}
