import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HowdenAuthService, NotificationsService } from '@howdeniberia/core-front';
import { catchError, forkJoin, of, switchMap, timer } from 'rxjs';
import { IFleetNotificationsService } from 'src/app/core/services/api/notifications';
import { IProcessService } from 'src/app/core/services/api/processes';
import { SubSink } from 'subsink';

const FLEET_NOTIFICATION_COUNT = 'fleet-notification-count';
const WAITING_PROCESS_COUNT = 'waiting-process-count';

@Component({
  selector: 'howden-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private authSrv: HowdenAuthService,
    private processSrv: IProcessService,
    private notificationSrv: NotificationsService,
    private fleetNotificationSrv: IFleetNotificationsService
  ) {
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      const interval = (60 * 1000) * 3;

      this._subscriptions.sink = timer(0, interval).pipe(
        switchMap(() => forkJoin([
          this.processSrv.getWaitingProcessCount(),
          this.fleetNotificationSrv.getNotificationCount()
        ]).pipe(catchError(() => of([{ result: 0 }, { result: 0 }]))
        ))
      ).subscribe(([value1, value2]) => {
        this.notificationSrv.updateNotification(WAITING_PROCESS_COUNT, value1?.result ?? 0);
        this.notificationSrv.updateNotification(FLEET_NOTIFICATION_COUNT, value2?.result ?? 0);
      });

      const $router = this.router;

      this._subscriptions.sink = this.notificationSrv.notificationsAction$.subscribe({
        next: (value: string) => {
          if (value === WAITING_PROCESS_COUNT) {
            $router.navigate(['processes/search']);
          } else if (value === FLEET_NOTIFICATION_COUNT) {
            $router.navigate(['notifications/policy-expirations']);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  get isLoggedIn(): boolean {
    return this.authSrv.isLogued();
  }
}
