import { IPolicyIncidence } from 'src/app/core/models/api/policies';
import { IPolicyIncidenceView } from '../models';

export abstract class PolicyIncidenceMapper {
  static map(source: Array<IPolicyIncidence>): Array<IPolicyIncidenceView> {
    return source.map(value => ({
        incidenceId: value.incidenceId,
        date: value.date,
        description: value.description,
        closingDate: value.closingDate,
        closeDescription: value.closeDescription
      } as IPolicyIncidenceView));
  }
}
