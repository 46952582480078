import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { ILTAService, LTAService } from 'src/app/core/services/api/lta';
import { IReportsService, ReportsService } from 'src/app/core/services/api/reports';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { BonusHistoryReportComponent } from './bonus-history-report/pages/bonus-history-report.component';
import { ClaimsByCompanyReportComponent } from './claims-by-company-report/pages/claims-by-company-report.component';
import { FleetControlReportComponent } from './fleet-control-report/pages/fleet-control-report.component';
import { FleetReportComponent } from './fleet-report/pages/fleet-report.component';
import { FleetStateReportComponent } from './fleet-state-report/pages/fleet-state-report.component';
import { LTAReportComponent } from './lta-report/pages/lta-report.component';
import { OperatingCompaniesReportComponent } from './operating-companies-report/pages/operating-companies-report.component';
import { PolicyHistoryReportComponent } from './policy-history-report/pages/policy-history-report.component';
import { PolicyReportComponent } from './policy-report/pages/policy-report.component';
import { RegularizationReportComponent } from './regularization-report/pages/regularization-report.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { RisksComparedReportComponent } from './risks-compared-report/pages/risks-compared-report.component';
import { RisksInsuredReportComponent } from './risks-insured-report/pages/risks-insured-report.component';
import { RootPolicyReportComponent } from './root-policy-report/pages/root-policy-report.component';
import { UnitReportComponent } from './unit-report/pages/unit-report.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatMomentDateModule,
    MatStepperModule,
    MatTooltipModule,
    HowdenAutocompleteModule,
    HowdenDecimalModule,
    HowdenFileUploadModule,
    HowdenSharedModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    ReportsRoutingModule
  ],
  declarations: [
    FleetReportComponent,
    FleetControlReportComponent,
    RootPolicyReportComponent,
    UnitReportComponent,
    PolicyReportComponent,
    PolicyHistoryReportComponent,
    BonusHistoryReportComponent,
    FleetStateReportComponent,
    RegularizationReportComponent,
    RisksComparedReportComponent,
    RisksInsuredReportComponent,
    ClaimsByCompanyReportComponent,
    OperatingCompaniesReportComponent,
    LTAReportComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: ILTAService, useClass: LTAService },
    { provide: IReportsService, useClass: ReportsService }
  ]
})
export class ReportsModule {
}
