import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { IPolicy } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { SubSink } from 'subsink';
import { PolicyEditHeaderComponent } from '../components/policy-edit-header/pages/policy-edit-header.component';
import { PolicyEditLayoutViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-layout',
  templateUrl: './policy-edit-layout.component.html',
  styleUrls: ['./policy-edit-layout.component.scss']
})
export class PolicyEditLayoutComponent implements OnInit, OnDestroy {
  model = new PolicyEditLayoutViewModel();
  policyId = '';

  @ViewChild('header') header!: PolicyEditHeaderComponent;
  @ViewChild('tabGroup') tabGroupRef!: MatTabGroup;

  private _subscriptions = new SubSink();

  constructor(private activatedRoute: ActivatedRoute, private policySrv: IPolicyService) {
  }

  ngOnInit(): void {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        if (this.tabGroupRef && this.tabGroupRef.selectedIndex !== 0) {
          this.tabGroupRef.selectedIndex = 0;
        }

        this.policyId = paramMap.get('id') as string;
        this.refresh();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onPolicyUpdated(): void {
    this.header.refresh();
    this.refresh();
  }

  private refresh(): void {
    this.policySrv.get(this.policyId).subscribe((policy: IPolicy) => this.model.policy = policy);
  }
}
