import { Component, Input } from '@angular/core';
import { IPolicyRegularizationGroup } from 'src/app/core/models/api/policies';

@Component({
  selector: 'howden-policy-edit-regularization-info',
  templateUrl: './policy-edit-regularization-info.component.html',
  styleUrls: ['./policy-edit-regularization-info.component.scss']
})
export class PolicyEditRegularizationInfoComponent {
  @Input() group: IPolicyRegularizationGroup | null = null;
}
