export class ClientViewModel {
  constructor(
    private _id: string,
    private _alias: string | null | undefined,
    private _name: string | null | undefined,
    private _surname1: string | null | undefined,
    private _surname2: string | null | undefined,
    private _fiscalCode: string | null | undefined) {
    if (typeof this._name === 'undefined') {
      this._name = '';
    }
    if (typeof this._surname1 === 'undefined') {
      this._surname1 = '';
    }
    if (typeof this._surname2 === 'undefined') {
      this._surname2 = '';
    }
  }

  get id(): string {
    return this._id;
  }

  get alias(): string | null | undefined {
    return this._alias;
  }

  get fullName(): string {
    if (!this._name) {
      return `${this._surname1} ${this._surname2}`.trim();
    } else if ((this._surname1 && this._surname1.length > 0) || (this._surname2 && this._surname2.length > 0)) {
      return `${this._surname1} ${this._surname2} ${this._name}`.trim();
    }

    return this._name ? this._name : '';
  }

  get fiscalCode(): string | null | undefined {
    return this._fiscalCode;
  }
}
