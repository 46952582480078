<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.document.title">Documentos</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label i18n="@@app.policies.edit.document.alias.label">Alias</mat-label>
          <input matInput [formControlName]="model.C_ALIAS" maxlength="255" [howdenControlError]="aliasError" />
          <mat-error #aliasError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label i18n="@@app.policies.edit.document.reference.label">Referencia</mat-label>
          <input matInput [formControlName]="model.C_REFERENCE" maxlength="255" [howdenControlError]="referenceError" />
          <mat-error #referenceError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-slide-toggle
          [formControlName]="model.C_CLIENT_VISIBLE"
          i18n="@@app.policies.edit.document.clientVisible.label">Visible cliente</mat-slide-toggle>
      </div>

      <div class="flex f-gap--10px">
        <howden-file-upload #file class="margin-10" (filesSelected)="onFileSelected()"></howden-file-upload>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label i18n="@@app.policies.edit.document.observations.label">Observaciones</mat-label>
          <textarea
            matInput
            [formControlName]="model.C_OBSERVATIONS"
            [howdenControlError]="observationsError"
            cdkTextareaAutosize
            cdkAutosizeMinRows="6"
            cdkAutosizeMaxRows="15"
          >
          </textarea>
          <mat-error #observationsError></mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
