import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogResultTypes, IButtonActionData, IDialogResult, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { catchError, debounceTime, distinctUntilChanged, forkJoin, of, switchMap, tap } from 'rxjs';
import { IProcessSearchResult } from 'src/app/core/models/api/processes';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IProcessService } from 'src/app/core/services/api/processes';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { ProcessSearchMapper } from '../mappers';
import { ProcessSearchViewModel } from '../models';

@Component({
  selector: 'howden-process-search',
  templateUrl: './process-search.component.html',
  styleUrls: ['./process-search.component.scss']
})
export class ProcessSearchComponent implements OnInit, OnDestroy {
  model = new ProcessSearchViewModel();

  @ViewChild('descriptionInput') descriptionInputRef!: ElementRef;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private mastersSrv: IMasterService,
    private processSrv: IProcessService
  ) {
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.uiBlockerSrv.block();

    forkJoin([
      this.mastersSrv.getProcessStatuses()
    ]).pipe(
      tap(([processStatuses]) => {
        this.model.processStatuses = processStatuses;

        this.descriptionInputRef.nativeElement.focus();

        this.refresh();
        this.onChanges();

        this.uiBlockerSrv.unblock();
      })
    ).subscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.onDelete(event.row.processId);
    } else if (event.buttonName === 'relaunch') {
      this.onRelaunch(event.row.processId);
    }
  }

  private onDelete(processId: string): void {
    const title: string = $localize`:@@app.processes.delete.title:Borrado de procesos`;
    const question: string = $localize`:@@app.processes.delete.subtitle:Se borrará el proceso, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.processes.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();

          this.processSrv.delete(processId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onRelaunch(processId: string) {
    const title: string = $localize`:@@app.processes.relaunch.title:Relanzar proceso`;
    const question: string = $localize`:@@app.processes.relaunch.subtitle:Se relanzará el proceso, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.processes.relaunch.confirm.subtitle:Finalizado correctamente.`;

          this.uiBlockerSrv.block();

          this.processSrv.relaunch(processId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).pipe(
                  tap(() => {
                    this.uiBlockerSrv.unblock();
                    this.refresh();
                  })
                ).subscribe();
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private refresh(): void {
    this.uiBlockerSrv.block();

    this.processSrv.search(this.model.searchRequest).subscribe((searchResult: IPageOf<IProcessSearchResult>) => {
      this.model.length = searchResult.totalCount;
      this.model.data = searchResult.data?.map((source) => ProcessSearchMapper.mapForSearch(source));

      this.uiBlockerSrv.unblock();
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.model.searchRequest.pageNumber = 0;
          this.model.updateServerSideFilters();
          this.uiBlockerSrv.block();
        }),
        switchMap(() => this.processSrv.search(this.model.searchRequest)
          .pipe(
            catchError(() => {
              this.uiBlockerSrv.unblock();
              return of([]);
            })
          )
        )
      ).subscribe({
        next: (response: any) => {
          const searchResult = response as IPageOf<IProcessSearchResult>;

          this.model.length = searchResult.totalCount;
          this.model.data = searchResult.data?.map((source) => ProcessSearchMapper.mapForSearch(source));
        },
        complete: () => this.uiBlockerSrv.unblock()
      });
  }
}
