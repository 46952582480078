<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  @if (!hasAssignationResults) {
    <div>
      <form [formGroup]="model.form">
        <div class="flex f-gap--10px margin-bottom-10">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@app.policies.assignation.rootPolicyId.label">Póliza madre</mat-label>
            <input
              #rootPolicyInput
              matInput
              [formControlName]="model.C_ROOT_POLICY_ID"
              [matAutocomplete]="rootPolicySelect"
              [howdenControlError]="rootPolicyIdError"
            />
            <mat-autocomplete
              #rootPolicySelect="matAutocomplete"
              [displayWith]="getRootPolicyDescription.bind(this)"
              >
              @for (item of model.filteredRootPolicies | async; track item.policyId) {
                <mat-option [value]="item.policyId">
                  {{ item.policyNumber }}-{{ item.riskDescription }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #rootPolicyIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px margin-bottom-20">
          <mat-slide-toggle
            class="f-as--center"
            [formControlName]="model.C_CONCAT_POLICY_NUMBER"
            i18n="@app.policies.assignation.concatPolicyNumbers.label">Concatenar el nº de póliza madre a los hijos</mat-slide-toggle>
        </div>

        <div class="flex f-gap--10px margin-bottom-10">
          <mat-form-field>
            <mat-label i18n="@app.policies.assignation.policyNumberSeparator.label">Separador Nº. Póliza</mat-label>
            <input
              matInput
              [formControlName]="model.C_POLICY_NUMBER_SEPARATOR"
              [howdenControlError]="policyNumberSeparatorError"
              maxlength="1"
            />
            <mat-error #policyNumberSeparatorError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px margin-bottom-10">
          <howden-file-upload #file class="margin-10" (filesSelected)="onFileSelected()"></howden-file-upload>
        </div>

        <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
            <span i18n="@@app.core.actions.accept">Aceptar</span>
          </button>
        </div>
      </form>
    </div>
  } @else {
    <div>
      <howden-table
        class="small-font"
        keyField="plateNumber"
        paginable="local"
        sortable="local"
        filterable="global-local"
        [pageSize]="10"
        [cols]="model.assignationResultsColumns"
        [data]="model.assignationResults"
        >
      </howden-table>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10">
      <button type="button" mat-raised-button (click)="onGoBack()">
        <span i18n="@@app.core.actions.back">Volver</span>
      </button>
    </div>
  }
</div>
