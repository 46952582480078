import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult, IPageOf } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IBookingMode, IBookingModeSearchRequest } from 'src/app/core/models/api/booking-modes';
import { IFleet } from 'src/app/core/models/api/fleets';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes/booking-mode.contract';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUnitService } from 'src/app/core/services/api/units';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { UnitFleetTransferMapper } from '../mappers';
import { UnitFleetTransferViewModel } from '../models';

@Component({
  selector: 'howden-unit-fleet-transfer-dialog',
  templateUrl: './unit-fleet-transfer-dialog.component.html',
  styleUrls: ['./unit-fleet-transfer-dialog.component.scss']
})
export class UnitFleetTransferDialogComponent implements OnInit {
  model = new UnitFleetTransferViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<UnitFleetTransferDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private fleetSrv: IFleetService,
    private bookingModeSrv: IBookingModeService,
    private unitSrv: IUnitService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const id = this.inputData.id as string;
    this.unitSrv.getFleetsForTransfer(id).subscribe((data: Array<IFleet>) => {
      this.model.fleets = data;
    });

    this.onChanges();
  }

  onAccept(): void {
    const id = this.inputData.id as string;
    const request = UnitFleetTransferMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.unitSrv.transfer(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getFleetName(fleetId: string): string {
    return this.model.fleets?.find(x => x.fleetId === fleetId)?.clientName ?? '';
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);
    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  getCompanyName(companyId: string): string {
    return this.model.fleetCompanies?.find(x => x.companyId === companyId)?.clientName ?? '';
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }

  private resetSelections(): void {
    this.model.targetRootPolicyId = null;
    this.model.holderId = null;
    this.model.insuredId = null;
    this.model.fleetCompanies = [];
    this.model.rootPolicies = [];
    this.model.isBookingModeRequired = false;

    this.resetBookingModeSelection();
  }

  private resetBookingModeSelection() {
    this.model.bookingModeId = null;
    this.model.bookingModes = [];
  }

  private onChanges(): void {
    this.model.form.get(this.model.C_TARGET_FLEET_ID)?.valueChanges.subscribe(() => this.onTargetFleetChanged());
    this.model.form.get(this.model.C_TARGET_ROOT_POLICY_ID)?.valueChanges.subscribe(() => this.onTargetRootPolicyChanged());
  }

  private onTargetFleetChanged(): void {
    const targetFleetId: string = this.model.targetFleetId;

    this.resetSelections();

    const targetFleet = this.model.fleets.find(x => x.fleetId === targetFleetId);

    if (targetFleet) {
      const policyFilter = {
        fleetId: targetFleetId,
        policyClasses: [PolicyClass.Colective.toString(), PolicyClass.Other.toString()],
        statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString(), PolicyStatus.Suspended.toString()],
        pageSize: Limits.maxPageSize
      } as IPolicySearchRequest;

      forkJoin([
        this.fleetSrv.getCompanies(targetFleetId),
        this.policySrv.search(policyFilter),
        this.fleetSrv.isBookingModeRequired(targetFleetId)
      ]).subscribe(([companies, rootPolicies, isBmRequired]) => {
        // Companies
        this.model.fleetCompanies = companies;

        if (companies.length === 1) {
          this.model.holderId = companies[0].companyId;
          this.model.insuredId = companies[0].companyId;
        }

        // Root Policies
        this.model.rootPolicies = rootPolicies.data;

        if (this.model.rootPolicies.length === 1) {
          this.model.targetRootPolicyId = this.model.rootPolicies[0].policyId as string;
        }

        // Booking Mode Required
        this.model.isBookingModeRequired = isBmRequired.result as boolean;
      });
    }
  }

  private onTargetRootPolicyChanged(): void {
    const targetRootPolicyId = this.model.targetRootPolicyId;
    const targetFleetId = this.model.targetFleetId;

    this.resetBookingModeSelection();

    const targetFleet = this.model.fleets.find(x => x.fleetId === targetFleetId);
    const targetRootPolicy = this.model.rootPolicies.find(x => x.policyId === targetRootPolicyId);

    if (targetFleet && targetRootPolicy) {
      const filter = <IBookingModeSearchRequest>{
        pageSize: 99999,
        fleetId: targetFleetId,
        insuranceCompanyId: targetRootPolicy.insuranceCompanyId,
        industryId: targetRootPolicy.industryId,
        productId: targetRootPolicy.productId
      };

      this.bookingModeSrv.search(filter).subscribe((data: IPageOf<IBookingMode>) => {
        this.model.bookingModes = data.data;

        if (data.data.length === 1) {
          this.model.bookingModeId = data.data[0].bookingModeId as string;
        }
      });
    }
  }
}
