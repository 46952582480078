import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { EndorsementLoaderComponent } from './endorsement-loader/pages/endorsement-loader.component';

export const EndorsementRoutes = [
  {
    canActivate: [AuthGuard, ActiveFleetGuard],
    path: 'endorsements',
    title: $localize`:@@app.route.endorsements:Suplementos`,
    data: {
      icon: 'file_open',
      linkText: $localize`:@@app.route.endorsements:Suplementos`
    },
    children: [
      {
        path: 'endorsement-loader',
        component: EndorsementLoaderComponent,
        title: $localize`:@@app.route.endorsements.loader:Alta masiva de suplementos`,
        data: { linkText: $localize`:@@app.route.endorsements.loader:Alta masiva de suplementos` }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(EndorsementRoutes)],
  exports: [RouterModule]
})
export class EndorsementsRoutingModule {
}
