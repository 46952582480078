import { FormStyle, TranslationWidth, getLocaleMonthNames } from '@angular/common';
import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { forkJoin, tap } from 'rxjs';
import { MediationCodeStatus, PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { PlateNumberValidator, PolicyNumberValidator } from 'src/app/shared/validators';
import { SubSink } from 'subsink';
import { UnitCreateNewMapper } from '../mappers';
import { UnitCreateNewViewModel } from '../models';

@Component({
  selector: 'howden-unit-create-new',
  templateUrl: './unit-create-new.component.html',
  styleUrls: ['./unit-create-new.component.scss']
})
export class UnitCreateNewComponent implements OnInit, OnDestroy {
  model: UnitCreateNewViewModel;

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('plateNumberInput') plateNumberInputRef!: ElementRef;
  @ViewChild('stepper') stepperRef!: MatStepper;

  private _subscriptions = new SubSink();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private insuranceCompanySrv: IInsuranceCompanyService,
    private policySrv: IPolicyService,
    private unitSrv: IUnitService,
    private plateNumberValidator: PlateNumberValidator
  ) {
    this.model = new UnitCreateNewViewModel(this.plateNumberValidator);
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    const policyFilter = {
      pageNumber: 0,
      fleetId: fleetId,
      policyClasses: [PolicyClass.Other.toString(), PolicyClass.Colective.toString()],
      pageSize: Limits.maxPageSize,
      statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString()]
    } as IPolicySearchRequest;

    forkJoin([
      this.policySrv.search(policyFilter),
      this.fleetSrv.isBookingModeRequired(fleetId),
      this.fleetSrv.getVehicleTypes(fleetId),
      this.fleetSrv.getVehicleUsages(fleetId),
      this.fleetSrv.getCompanies(fleetId),
      this.unitSrv.getFunctionalTypes(),
      this.unitSrv.getCirculationAreas(),
      this.unitSrv.getMovementAreas(),
      this.unitSrv.getGoodsTypes()
    ]).subscribe(([
      rootPolicies,
      isBmRequired,
      vehicleTypes,
      vehicleUsages,
      fleetCompanies,
      functionalTypes,
      circulationAreas,
      movementAreas,
      goodsTypes
    ]) => {
      this.model.rootPolicies = rootPolicies.data;
      this.model.vehicleTypes = vehicleTypes;
      this.model.vehicleUsages = vehicleUsages;
      this.model.fleetCompanies = fleetCompanies;
      this.model.movementAreas = movementAreas;
      this.model.functionalTypes = functionalTypes;
      this.model.circulationAreas = circulationAreas;
      this.model.goodsTypes = goodsTypes;
      this.model.isBookingModeRequired = isBmRequired.result as boolean;

      // Booking mode required
      if (this.model.isBookingModeRequired) {
        this.model.getControl(this.model.C_ASSIGNMENT_BOOKING_MODE_ID).addValidators([Validators.required]);
        this.model.getControl(this.model.C_ASSIGNMENT_BOOKING_MODE_ID).updateValueAndValidity();
      }

      this.initializeUnit();

      this.onChanges();

      this.model.adaptAssignmentControls();

      this.uiBlockerSrv.unblock();

      this.plateNumberInputRef.nativeElement.focus();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onGuessRegistrationDate() {
    const plateNumber = this.model.unitPlateNumber as string;

    this.uiBlockerSrv.block();

    this.unitSrv.guessRegistrationDate(plateNumber).subscribe({
      next: (result) => this.model.unitRegistrationDate = result.result,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  months(): ReadonlyArray<string> {
    return getLocaleMonthNames(this.locale, FormStyle.Standalone, TranslationWidth.Wide);
  }

  onSave(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = UnitCreateNewMapper.mapForUpdate(fleetId, this.model);
    const title: string = $localize`:@@app.units.create.new.save.dialog.title:Alta de unidades`;

    this.uiBlockerSrv.block();

    this.unitSrv.create(request).pipe(
      tap(() => {
        this.model.form.reset();

        this.stepperRef.selectedIndex = 0;

        this.uiBlockerSrv.unblock();

        this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe();
      })
    ).subscribe();
  }

  onCancel(): void {
    this.navigateToSearch();
  }

  onSearchDefaultBonus(): void {
    const policyId = this.model.assignmentTargetPolicyId as string;
    const request = UnitCreateNewMapper.mapForGuessBonus(this.model);

    this.uiBlockerSrv.block();

    this.policySrv.guessIndividualBonus(policyId, request).subscribe({
      next: (response) => {
        if (response && response.isMatch === true) {
          this.model.assignmentNetBonus = response.netBonus;
          this.model.assignmentTotalBonus = response.totalBonus;
          this.model.assignmentCommissionRate = response.commissionRate;
        }
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);
    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  getMediationCodeDescription(mediationCodeId: string): string {
    const mediationCode = this.model.mediationCodes?.find(x => x.mediationCodeId === mediationCodeId);
    return mediationCode ? `${mediationCode.code}-${mediationCode.description}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_ASSIGNMENT_FIRST_EFFECT_DATE).valueChanges.subscribe(() => {
      this.model.assignmentEffectDate = this.model.assignmentFirstEffectDate;
    });

    this._subscriptions.sink = this.model.getControl(this.model.C_ASSIGNMENT_TARGET_POLICY_ID).valueChanges.subscribe((value: string) => {
      this.onTargetRootPolicyChanged(value);
    });
  }

  private onTargetRootPolicyChanged(value: string): void {
    this.model.bookingModes = [];
    this.model.getControl(this.model.C_ASSIGNMENT_BOOKING_MODE_ID).reset();

    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);

    this.model.assignmentTargetPolicyClass = null;

    if (rootPolicy) {
      this.model.assignmentTargetPolicyClass = rootPolicy.policyClass;

      const insuranceCompanyId = rootPolicy.insuranceCompanyId as string;
      const policyNumberControl = this.model.getControl(this.model.C_ASSIGNMENT_POLICY_NUMBER);

      policyNumberControl.clearAsyncValidators();

      if (this.model.isAssignedToRootPolicy) {
        const policyNumberValidator = new PolicyNumberValidator(this.policySrv, insuranceCompanyId);
        policyNumberControl.setAsyncValidators([policyNumberValidator.validate.bind(policyNumberValidator)]);
      }

      policyNumberControl.updateValueAndValidity();

      this.uiBlockerSrv.block();

      forkJoin([
        this.policySrv.getBookingModes(value),
        this.insuranceCompanySrv.getMediationCodes(insuranceCompanyId)
      ]).subscribe(([
        bookingModes,
        mediationCodes
      ]) => {
        // Booking modes
        this.model.bookingModes = bookingModes;
        if (bookingModes.length === 1) {
          this.model.assignmentBookingModeId = bookingModes[0].bookingModeId as string;
        }

        // Mediation codes
        this.model.mediationCodes = mediationCodes.filter(x => x.status === MediationCodeStatus.Active);
        if (this.model.isAssignedToColectivePolicy) {
          this.model.assignmentMediationCodeId = null;
        } else if (rootPolicy?.mediationCodeId) {
          this.model.assignmentMediationCodeId = rootPolicy.mediationCodeId;
        } else {
          const defaultMediationCode = this.model.mediationCodes?.find(x => x.isDefault === true);
          if (defaultMediationCode) {
            this.model.assignmentMediationCodeId = defaultMediationCode.mediationCodeId;
          }
        }

        this.uiBlockerSrv.unblock();
      });
    }

    this.model.adaptAssignmentControls();
  }

  private initializeUnit(): void {
    this.model.bookingModes = [];
    this.model.mediationCodes = [];

    // Default owner
    if (this.model.fleetCompanies.length === 1) {
      this.model.unitOwner = this.model.fleetCompanies[0].clientName;
    }

    // Default movement area
    this.model.unitMovementArea = 'UE';

    // Holder / insured
    if (this.model.fleetCompanies.length === 1) {
      this.model.assignmentHolderId = this.model.fleetCompanies[0].companyId;
      this.model.assignmentInsuredId = this.model.fleetCompanies[0].companyId;
    }
  }

  private navigateToSearch(): void {
    this.router.navigate(['units/search']);
  }
}
