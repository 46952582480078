<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex margin-bottom-25">
      <button type="button" class="f-basis--4" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>
    </div>

    <div class="flex f-gap--10px margin-bottom-10">
      <mat-slide-toggle
        class="f-basis--4 f-as--center"
        [formControlName]="model.C_ACTIVE_RISKS"
        i18n="@@app.reports.policies.activeRisks.label">Riesgos vivos</mat-slide-toggle>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.policies.query.label">Filtro</mat-label>
        <input matInput [formControlName]="model.C_QUERY" />
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.policies.startDate.label">Fecha inicio</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          [formControlName]="model.C_START_DATE"
          [howdenControlError]="startDateError"
          [min]="minDate"
        />
        <mat-error #startDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.policies.endDate.label">Fecha fin</mat-label>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          [formControlName]="model.C_END_DATE"
          [howdenControlError]="endDateError"
          [min]="minDate"
        />
        <mat-error #endDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.policies.holderId.label"
        label="Tomador"
        displayField="clientName"
        keyField="companyId"
        [selectableItems]="model.fleetCompanies"
        [formControlName]="model.C_HOLDER_ID"
      >
      </howden-autocomplete>

      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.policies.insuredId.label"
        label="Asegurado"
        displayField="clientName"
        keyField="companyId"
        [selectableItems]="model.fleetCompanies"
        [formControlName]="model.C_INSURED_ID"
      >
      </howden-autocomplete>

      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.policies.onwer.label"
        label="Propietario"
        displayField="clientName"
        keyField="clientName"
        [selectableItems]="model.fleetCompanies"
        [formControlName]="model.C_OWNER"
      >
      </howden-autocomplete>
    </div>

    <div class="flex f-gap--10px">
      <div class="f-basis--3">
        <h4 i18n="@@app.reports.policies.rootPolicy.label">Póliza madre</h4>
        <mat-selection-list>
          @for (itemType of model.rootPolicies; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>

      <div class="f-basis--3">
        <h4 i18n="@@app.reports.policies.vehicleType.label">Tipo vehículo</h4>
        <mat-selection-list>
          @for (itemType of model.vehicleTypes; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>

      <div class="f-basis--3">
        <h4 i18n="@@app.reports.policies.vehicleUsage.label">Uso vehículo</h4>
        <mat-selection-list>
          @for (itemType of model.vehicleUsages; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>

      <div class="f-basis--3">
        <h4 i18n="@@app.reports.policies.status.label">Estado</h4>
        <mat-selection-list>
          @for (itemType of model.statuses; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>
    </div>
  </form>
</div>
