import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IPolicyRiskWarranty } from 'src/app/core/models/ida/production';
import { ICurrencyService } from 'src/app/core/services/api/currencies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyEditRiskWarrantyMapper } from '../mappers';
import { PolicyEditRiskWarrantiesViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-risk-warranties-dialog',
  templateUrl: './policy-edit-risk-warranties-dialog.component.html',
  styleUrls: ['./policy-edit-risk-warranties-dialog.component.scss']
})
export class PolicyEditRiskWarrantiesDialogComponent implements OnInit, OnDestroy {
  model = new PolicyEditRiskWarrantiesViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditRiskWarrantiesDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private currencySrv: ICurrencyService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const policyId = this.inputData.policyId;
    const policyRiskId = this.inputData.policyRiskId;

    this.uiBlockerSrv.block();

    forkJoin([
      this.currencySrv.getCurrencies(),
      this.policySrv.getPolicyRiskWarranties(policyId, policyRiskId)
    ]).subscribe(([
      currencies,
      warranties
    ]) => {
      this.model.currencies = currencies;
      this.model.warranties = warranties;

      this.onChanges();

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const policyId = this.inputData.policyId;
    const policyRiskId = this.inputData.policyRiskId;
    const request = PolicyEditRiskWarrantyMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.policySrv.updatePolicyRiskWarranty(policyId, policyRiskId, request).pipe(
      tap({
        complete: () => {
          this.policySrv.getPolicyRiskWarranties(policyId, policyRiskId).subscribe(data => this.model.warranties = data);
          this.model.abbrv = undefined;

          this.uiBlockerSrv.unblock();
        }
      })
    ).subscribe();
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_ABBRV).valueChanges.subscribe((val: string | undefined) => {
      this.onWarrantyChanged(val);
    });
  }

  private onWarrantyChanged(abbrv: string | undefined): void {
    if (!abbrv) {
      this.model.getControl(this.model.C_POLICY_RISK_ID).reset();
      this.model.getControl(this.model.C_WARRANTY_ID).reset();
      this.model.getControl(this.model.C_FRANCHISE).reset();
      this.model.getControl(this.model.C_CURRENCY_ID).reset();
      this.model.getControl(this.model.C_INCLUSION_DATE).reset();
      this.model.getControl(this.model.C_EXCLUSION_DATE).reset();
      this.model.getControl(this.model.C_ORDER).reset();
    } else {
      const warranty = this.model.warranties.find(x => x.abbrv === abbrv);
      if (warranty) {
        PolicyEditRiskWarrantyMapper.mapForEdit(warranty as IPolicyRiskWarranty, this.model);
      }
    }
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
