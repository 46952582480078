import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { IContractSummary } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicySummaryDialogComponent } from 'src/app/features/policies/policy-summary-dialog/pages/policy-summary-dialog.component';
import { PolicyEditContractMapper } from '../mappers';
import { PolicyEditContractViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-contract-dialog',
  templateUrl: './policy-edit-contract-dialog.component.html',
  styleUrls: ['./policy-edit-contract-dialog.component.scss']
})
export class PolicyEditContractDialogComponent implements OnInit {
  model = new PolicyEditContractViewModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private router: Router,
    private dialogRef: MatDialogRef<PolicySummaryDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const id = this.inputData.id as string;

    this.uiBlockerSrv.block();

    this.policySrv.getContract(id).subscribe({
      next: (data: IContractSummary) => PolicyEditContractMapper.mapForEdit(data, this.model),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  onClose(): void {
    this.closeDialog(DialogResultTypes.OK);
  }

  onAction(event: IButtonActionData) {
    this.closeDialog(DialogResultTypes.OK);
    this.router.navigate(['policies/edit', event.row.policyId]);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
