import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IDetailedReceipt } from 'src/app/core/models/ida/financial';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { DetailedReceiptMapper } from '../mappers';
import { PolicyEditReceiptsViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-receipts',
  templateUrl: './policy-edit-receipts.component.html',
  styleUrls: ['./policy-edit-receipts.component.scss']
})
export class PolicyEditReceiptsComponent implements OnChanges {
  model = new PolicyEditReceiptsViewModel();

  @Input() policyId = '';

  constructor(private uiBlockerSrv: IUIBlockerService, private policySrv: IPolicyService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.uiBlockerSrv.block();
    this.policySrv.getReceipts(this.policyId).subscribe({
      next: (data: Array<IDetailedReceipt>) => this.model.data = DetailedReceiptMapper.map(data),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
