import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, tap } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { FleetEditSettingsMapper } from '../mappers';
import { FleetEditSettingsViewModel } from '../models';

@Component({
  selector: 'howden-fleet-edit-settings',
  templateUrl: './fleet-edit-settings.component.html',
  styleUrls: ['./fleet-edit-settings.component.scss']
})
export class FleetEditSettingsComponent implements OnInit, OnDestroy {
  model = new FleetEditSettingsViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetsSrv: IFleetService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetsSrv.getInsuranceCompanySettings(fleetId),
      this.fleetsSrv.getIndustrySettings(fleetId),
      this.fleetsSrv.getVehicleTypeSettings(fleetId),
      this.fleetsSrv.getVehicleUsageSettings(fleetId)
    ]).subscribe(([
      insuranceCompanySettings,
      industrySettings,
      vehicleTypeSettings,
      vehicleUsageSettings
    ]) => {
      this.model.insuranceCompanySettings = insuranceCompanySettings;
      this.model.industrySettings = industrySettings;
      this.model.vehicleTypeSettings = vehicleTypeSettings;
      this.model.vehicleUsageSettings = vehicleUsageSettings;

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FleetEditSettingsMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.fleetsSrv.updateFleetSettings(fleetId, request).pipe(
      tap(() => {
        const title: string = $localize`:@@app.fleets.edit.save.dialog.title:Modificación de flotas`;

        this.uiBlockerSrv.unblock();

        this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe();
      })
    ).subscribe();
  }

  onClose(): void {
    this.navigateToSearch();
  }

  private navigateToSearch(): void {
    this.router.navigate(['fleets/search']);
  }
}
