<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title>
      <span class="title-text" i18n="@@app.units.cancel.risks.title">Baja de riesgos</span>
      <br />
      <span class="subtitle-text" i18n="@@app.units.cancel.risks.subtitle">
        Por favor, indique la fecha y el motivo de la baja para continuar.
      </span>
    </div>

    <mat-dialog-content>
      <mat-form-field>
        <mat-label i18n="@@app.units.cancel.risks.operationDate.label">Fecha operación</mat-label>
        <input
          matInput
          [matDatepicker]="operationDatePicker"
          [formControlName]="model.C_OPERATION_DATE"
          [howdenControlError]="operationDateError"
          [min]="minDate"
        />
        <mat-error #operationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #operationDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.units.cancel.risks.cancellationNotificationDate.label">Fecha notificación baja</mat-label>
        <input
          matInput
          [matDatepicker]="cancellationNotificationDatePicker"
          [formControlName]="model.C_CANCELLATION_NOTIFICATION_DATE"
          [howdenControlError]="cancellationNotificationDateError"
          [min]="minDate"
        />
        <mat-error #cancellationNotificationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="cancellationNotificationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #cancellationNotificationDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.units.cancel.risks.cancellationReason.label">Motivo anulación</mat-label>
        <mat-select [formControlName]="model.C_CANCELLATION_REASON_ID" [howdenControlError]="cancellationReasonError">
          @for (item of model.cancellationReasons; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #cancellationReasonError></mat-error>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions class="flex f-jc--center f-ai--center f-gap--10px">
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
