<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.risk.warranties.title">Modificación de garantías</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.risk.warranties.abbrv.label">Garantía</mat-label>
          <mat-select #policyTypeSelect [formControlName]="model.C_ABBRV" [howdenControlError]="warrantyAbbrvError">
            <mat-option></mat-option>
            @for (item of model.warranties | howdenArraySort: 'description'; track item.abbrv) {
              <mat-option [value]="item.abbrv">
                {{ item.abbrv }}
              </mat-option>
            }
          </mat-select>
          <mat-error #warrantyAbbrvError></mat-error>
        </mat-form-field>
      </div>

      @if (model.abbrv) {
        <mat-divider></mat-divider>

        <div class="flex f-gap--10px margin-top-10">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.risk.warranties.feanchise.label">Franquicia</mat-label>
            <input
              matInput
              [formControlName]="model.C_FRANCHISE"
              [howdenControlError]="franchiseError"
              i18n-placeholder="@@app.policies.edit.risk.warranties.feanchise.placeholder"
              placeholder="Franquicia"
              maxlength="20"
            />
            <mat-error #franchiseError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.risk.warranties.inclusionDate.label">Fecha inclusión</mat-label>
            <input
              matInput
              [matDatepicker]="inclusionDatePicker"
              [formControlName]="model.C_INCLUSION_DATE"
              [howdenControlError]="inclusionDateError"
              [min]="minDate"
            />
            <mat-error #inclusionDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="inclusionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #inclusionDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.risk.warranties.exclusionDate.label">Fecha exclusión</mat-label>
            <input
              matInput
              [matDatepicker]="exclusionDatePicker"
              [formControlName]="model.C_EXCLUSION_DATE"
              [howdenControlError]="exclusionDateError"
              [min]="minDate"
            />
            <mat-error #exclusionDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="exclusionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #exclusionDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--6"
            i18n-label="@@app.policies.edit.risk.warranties.currency.label"
            label="Divisa"
            displayField="description"
            keyField="currencyId"
            [selectableItems]="model.currencies"
            [formControlName]="model.C_CURRENCY_ID"
          >
          </howden-autocomplete>

          <howden-decimal
            class="f-basis--6"
            [formControlName]="model.C_ORDER"
            [precision]="0"
            i18n-label="@@app.policies.edit.risk.warranties.order.label"
            label="Orden"
          ></howden-decimal>
        </div>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
