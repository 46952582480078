import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';

export class PolicyEditRisksViewModel {
  data: IPolicyRiskSummary[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'riskAlias',
      fieldHeader: $localize`:@@app.policies.edit.risks.riskAlias.column:Matrícula`,
      canSort: true
    },
    {
      fieldName: 'carNumber',
      fieldHeader: $localize`:@@app.policies.edit.risks.carNumber.column:Nº. Coche`,
      canSort: true
    },
    {
      fieldName: 'insured',
      fieldHeader: $localize`:@@app.policies.edit.risks.insured.column:Asegurado`,
      canSort: true
    },
    {
      fieldName: 'activationDate',
      fieldHeader: $localize`:@@app.policies.edit.risks.activationDate.column:Fecha alta`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'deactivationDate',
      fieldHeader: $localize`:@@app.policies.edit.risks.deactivationDate.column:Fecha baja`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'deactivationNotificationDate',
      fieldHeader: $localize`:@@app.policies.edit.risks.deactivationNotificationDate.column:Fecha com. baja`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'bookingMode',
      fieldHeader: $localize`:@@app.policies.edit.risks.bookingMode.column:Modo de contratación`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    grouped: true,
    tooltip: $localize`:@@app.policies.edit.risks.actions.edit.tooltip:Editar`
  }, {
    name: 'delete',
    icon: 'delete',
    grouped: true,
    tooltip: $localize`:@@app.policies.edit.risks.actions.delete.tooltip:Borrar`
  }, {
    name: 'edit-booking-mode',
    icon: 'app_registration',
    grouped: true,
    tooltip: $localize`:@@app.policies.edit.risks.actions.bookingMode.tooltip:Cambio modo de contratación`,
    isDisabled: (row: any): boolean => {
      const disabled = !!row.cancellationDate || (typeof row.bookingModeId === 'undefined' || row.bookingModeId === null);

      return disabled;
    }
  }, {
    name: 'edit-warranties',
    icon: 'dataset',
    grouped: true,
    tooltip: $localize`:@@app.policies.edit.risks.actions.warranties.tooltip:Garantías`,
    isDisabled: (row: any): boolean => {
      const disabled = !!row.cancellationDate || (typeof row.bookingModeId === 'undefined' || row.bookingModeId === null);

      return disabled;
    }
  }, {
    name: 'info',
    icon: 'info',
    grouped: true,
    tooltip: $localize`:@@app.policies.edit.risks.actions.info.tooltip:Información`
  }];
}
