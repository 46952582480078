import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import {
  IAssignFleetCompany,
  IBookingModeChangerResponse,
  ICreateFleetResponse,
  IEndorsementLoaderResponse,
  IFleet,
  IFleetCompany,
  IFleetSearchRequest,
  IFleetSearchResult,
  IFleetSetting,
  IFleetTax,
  IFleetUpdaterResponse,
  IInitialStateLoaderResponse,
  IInsurerMergerItem,
  IInsurerMergerRequest,
  IParalizationDaysLoaderResponse,
  IPolicyBonusUpdaterResponse,
  IPolicyCancellerResponse,
  IPolicyHolderUpdaterResponse,
  IPolicyRenew,
  IPolicyRenumberingResponse,
  IPolicyReplacerResponse,
  IPolicyRiskUpdaterResponse,
  IPolicyUpdaterResponse,
  IRateChangerResponse,
  IRootPolicyUpdaterResponse,
  IUnitLoaderResponse,
  IUnitUpdaterResponse,
  IUpdateFleet,
  IUpdateFleetSettings
} from 'src/app/core/models/api/fleets';
import { IGlobalSearchRequest, IGlobalSearchResult } from 'src/app/core/models/api/global-search';
import { IPolicyLoaderError } from 'src/app/core/models/api/policies';
import { IClient } from 'src/app/core/models/ida/clients';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IIndustry } from 'src/app/core/models/ida/products';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';
import { IFleetService } from './fleet.contract';

@Injectable({
  providedIn: 'root'
})
export class FleetService implements IFleetService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  downloadHelpManual(): Observable<Blob> {
    return this.httpClient.getBlob(APP_CONSTANTS.API_NET_FLOTAS, '/fleets/download-help-manual', {
      responseType: 'blob'
    });
  }

  globalSearch(query: string | null): Observable<Array<IGlobalSearchResult>> {
    const request = { query: query } as IGlobalSearchRequest;

    return this.httpClient.post<Array<IGlobalSearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, '/fleets/global-search', request);
  }

  search(request: IFleetSearchRequest): Observable<IPageOf<IFleetSearchResult>> {
    return this.httpClient.post<IPageOf<IFleetSearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, '/fleets/search', request);
  }

  get(id: string): Observable<IFleet> {
    return this.httpClient.get<IFleet>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}`);
  }

  getDetails(id: string): Observable<IFleetSearchResult> {
    return this.httpClient.get<IFleetSearchResult>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/details`);
  }

  create(request: FormData): Observable<ICreateFleetResponse> {
    return this.httpClient.post<ICreateFleetResponse>(APP_CONSTANTS.API_NET_FLOTAS, '/fleets', request);
  }

  update(id: string, request: IUpdateFleet): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}`);
  }

  assignCompany(id: string, request: IAssignFleetCompany): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/companies`, request);
  }

  getCompanies(id: string): Observable<Array<IFleetCompany>> {
    return this.httpClient.get<Array<IFleetCompany>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/companies`);
  }

  getNotAssignedCompanies(id: string): Observable<Array<IClient>> {
    return this.httpClient.get<Array<IClient>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/not-assigned-companies`);
  }

  getInsuranceCompanies(id: string): Observable<Array<IInsuranceCompany>> {
    return this.httpClient.get<Array<IInsuranceCompany>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/insurance-companies`);
  }

  getIndustries(id: string): Observable<Array<IIndustry>> {
    return this.httpClient.get<Array<IIndustry>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/industries`);
  }

  getVehicleTypes(id: string): Observable<Array<IMasterValue>> {
    return this.httpClient.get<IMasterValue[]>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/vehicle-types`);
  }

  getVehicleUsages(id: string): Observable<Array<IMasterValue>> {
    return this.httpClient.get<IMasterValue[]>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/vehicle-usages`);
  }

  isBookingModeRequired(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.get<IResponse<boolean>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/is-booking-mode-required`);
  }

  hasNonRenewedBonuses(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.get<IResponse<boolean>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/has-non-renewed-bonuses`);
  }

  updateFleetSettings(id: string, settings: IUpdateFleetSettings): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/settings`, settings);
  }

  getInsuranceCompanySettings(id: string): Observable<Array<IFleetSetting>> {
    return this.httpClient.get<Array<IFleetSetting>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/settings/insurance-companies`);
  }

  getIndustrySettings(id: string): Observable<Array<IFleetSetting>> {
    return this.httpClient.get<Array<IFleetSetting>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/settings/industries`);
  }

  getVehicleTypeSettings(id: string): Observable<Array<IFleetSetting>> {
    return this.httpClient.get<Array<IFleetSetting>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/settings/vehicle-types`);
  }

  getVehicleUsageSettings(id: string): Observable<Array<IFleetSetting>> {
    return this.httpClient.get<Array<IFleetSetting>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/settings/vehicle-usages`);
  }

  bookingModeChanger(id: string, request: FormData): Observable<IBookingModeChangerResponse> {
    return this.httpClient.post<IBookingModeChangerResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/booking-mode-changer`, request);
  }

  loadParalizationDays(id: string, document: FormData): Observable<IParalizationDaysLoaderResponse> {
    return this.httpClient.post<IParalizationDaysLoaderResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/paralization-days/`, document);
  }

  policyBonusUpdater(id: string, request: FormData): Observable<IPolicyBonusUpdaterResponse> {
    return this.httpClient.post<IPolicyBonusUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-bonus-updater`, request);
  }

  policyCanceller(id: string, request: FormData): Observable<IPolicyCancellerResponse> {
    return this.httpClient.post<IPolicyCancellerResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-canceller`, request);
  }

  policyHolderUpdater(id: string, request: FormData): Observable<IPolicyHolderUpdaterResponse> {
    return this.httpClient.post<IPolicyHolderUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-holder-updater`, request);
  }

  policyRenewer(id: string, request: IPolicyRenew): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-renewer`, request);
  }

  policyRenumbering(id: string, request: FormData): Observable<IPolicyRenumberingResponse> {
    return this.httpClient.post<IPolicyRenumberingResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-renumbering`, request);
  }

  policyReplacer(id: string, request: FormData): Observable<IPolicyReplacerResponse> {
    return this.httpClient.post<IPolicyReplacerResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-replacer`, request);
  }

  policyRiskUpdater(id: string, request: FormData): Observable<IPolicyRiskUpdaterResponse> {
    return this.httpClient.post<IPolicyRiskUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-risk-updater`, request);
  }

  rateChanger(id: string, request: FormData): Observable<IRateChangerResponse> {
    return this.httpClient.post<IRateChangerResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/rate-changer`, request);
  }

  policyLoader(id: string, request: FormData): Observable<Array<IPolicyLoaderError>> {
    return this.httpClient.post<Array<IPolicyLoaderError>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-loader`, request);
  }

  policyLoaderValidation(id: string, request: FormData): Observable<Array<IPolicyLoaderError>> {
    return this.httpClient.post<Array<IPolicyLoaderError>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-loader-validator`, request);
  }

  policyUpdater(id: string, request: FormData): Observable<IPolicyUpdaterResponse> {
    return this.httpClient.post<IPolicyUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/policy-updater`, request);
  }

  rootPolicyUpdater(id: string, request: FormData): Observable<IRootPolicyUpdaterResponse> {
    return this.httpClient.post<IRootPolicyUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/root-policy-updater`, request);
  }

  unitLoader(id: string, request: FormData): Observable<IUnitLoaderResponse> {
    return this.httpClient.post<IUnitLoaderResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/unit-loader`, request);
  }

  unitUpdater(id: string, request: FormData): Observable<IUnitUpdaterResponse> {
    return this.httpClient.post<IUnitUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/unit-updater`, request);
  }

  endorsementLoader(id: string, request: FormData): Observable<IEndorsementLoaderResponse> {
    return this.httpClient.post<IEndorsementLoaderResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/endorsement-loader`, request);
  }

  fleetUpdater(id: string, request: FormData): Observable<IFleetUpdaterResponse> {
    return this.httpClient.post<IFleetUpdaterResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/fleet-updater`, request);
  }

  initialStateLoader(id: string, request: FormData): Observable<IInitialStateLoaderResponse> {
    return this.httpClient.post<IInitialStateLoaderResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/initial-state-loader`, request);
  }

  getTaxes(id: string): Observable<Array<IFleetTax>> {
    return this.httpClient.get<Array<IFleetTax>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/taxes`);
  }

  getTax(id: string, taxId: string): Observable<IFleetTax> {
    return this.httpClient.get<IFleetTax>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/taxes/${taxId}`);
  }

  upsertTax(id: string, request: IFleetTax): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/taxes`, request);
  }

  deleteTax(id: string, taxId: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/${id}/taxes/${taxId}`);
  }

  insurerMerger(request: IInsurerMergerRequest): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/insurers-merger`, request);
  }

  insurerMergerPreview(request: IInsurerMergerRequest): Observable<Array<IInsurerMergerItem>> {
    return this.httpClient.post<Array<IInsurerMergerItem>>(APP_CONSTANTS.API_NET_FLOTAS, `/fleets/insurers-merger-preview`, request);
  }
}
