import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';
import { SubtoolbarMenuService } from '@howdeniberia/core-front';
import { SubHeaderComponent } from './features/app-header/sub-header/pages/sub-header.component';

@Component({
  selector: 'howden-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private subtoolbarMenuSrv: SubtoolbarMenuService) {
  }

  ngOnInit(): void {
    this.subtoolbarMenuSrv.setMenu(new ComponentPortal(SubHeaderComponent));
  }
}
