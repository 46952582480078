import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IButtonActionData } from '@howdeniberia/core-front';
import { IPolicySummary } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicySummaryDialogComponent } from 'src/app/features/policies/policy-summary-dialog/pages/policy-summary-dialog.component';
import { SubSink } from 'subsink';
import { PolicySummaryViewMapper } from '../mappers';
import { PolicyEditCertificatesViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-certificates',
  templateUrl: './policy-edit-certificates.component.html',
  styleUrls: ['./policy-edit-certificates.component.scss']
})
export class PolicyEditCertificatesComponent implements OnChanges, OnDestroy {
  model = new PolicyEditCertificatesViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.uiBlockerSrv.block();
    this.policySrv.getCertificates(this.policyId).subscribe({
      next: (data: Array<IPolicySummary>) => this.model.data = PolicySummaryViewMapper.map(data),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.edit(event);
    } else {
      this.showInfo(event);
    }
  }

  private edit(event: IButtonActionData): void {
    this.router.navigate(['policies/edit', event.row.policyId]);
  }

  private showInfo(event: IButtonActionData): void {
    const inputData = { id: event.row.policyId };
    const dialogRef = this.dialog.open(PolicySummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }
}
