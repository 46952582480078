import { ICreateEndorsement } from 'src/app/core/models/api/policies';
import { CreateEndorsementViewModel } from '../models';

export abstract class CreateEndorsementMapper {
  static mapForUpdate(source: CreateEndorsementViewModel): ICreateEndorsement {
    return {
      endorsementTypeId: source.endorsementTypeId,
      endorsementStatusId: source.endorsementStatusId,
      endorsementAlias: source.endorsementAlias,
      endorsementInsuranceCompanyReference: source.endorsementInsuranceCompanyReference,
      endorsementDescription: source.endorsementDescription,
      endorsementObservations: source.endorsementObservations,
      endorsementDateSentToInsuranceCompany: source.endorsementDateSentToInsuranceCompany,
      endorsementRequestDate: source.endorsementRequestDate,
      endorsementReceptionDate: source.endorsementReceptionDate,
      endorsementEffectDate: source.endorsementEffectDate,
      endorsementExpirationDate: source.endorsementExpirationDate,
      endorsementCancellationDate: source.endorsementCancellationDate,
      createsReceipt: source.createsReceipt ?? false,
      endorsementOldInformation: source.endorsementOldInformation,
      endorsementNewInformation: source.endorsementNewInformation,
      receiptConcept: source.receiptConcept,
      receiptTypeId: source.receiptTypeId,
      receiptSituationId: source.receiptSituationId,
      receiptCurrencyId: source.receiptCurrencyId,
      receiptInsuranceCompanyReceiptNumber: source.receiptInsuranceCompanyReceiptNumber,
      receiptNumber: source.receiptNumber,
      receiptPaymentType: source.receiptPaymentType,
      receiptBrokerIssueDate: source.receiptBrokerIssueDate,
      receiptInsuranceCompanyIssueDate: source.receiptInsuranceCompanyIssueDate,
      receiptEffectDate: source.receiptEffectDate,
      receiptExpirationDate: source.receiptExpirationDate,
      receiptCollectionDate: source.receiptCollectionDate,
      receiptRebateDate: source.receiptRebateDate,
      receiptSettlementDate: source.receiptSettlementDate,
      receiptResolutionDate: source.receiptResolutionDate,
      receiptNetBonus: source.receiptNetBonus,
      receiptTotalBonus: source.receiptTotalBonus,
      receiptSurcharge: source.receiptSurcharge,
      receiptExternalSurcharge: source.receiptExternalSurcharge,
      receiptTaxes: source.receiptTaxes,
      receiptConsortiumTaxes: source.receiptConsortiumTaxes,
      receiptBonusPayAmount: source.receiptBonusPayAmount
    } as ICreateEndorsement;
  }
}
