<div class="flex f-fd--column">

  <div>
    <howden-page-title></howden-page-title>
  </div>

  <howden-policy-edit-header #header [policyId]="policyId"></howden-policy-edit-header>

  <mat-tab-group #tabGroup mat-align-tabs="start" dynamicHeight animationDuration="0ms">

    <mat-tab label="Póliza" i18n-label="@@app.policies.edit.tabs.policy.label">

      <howden-policy-edit (updated)="onPolicyUpdated()" [policyId]="policyId"></howden-policy-edit>

    </mat-tab>

    @if (model.showRisks) {
      <mat-tab label="Riesgos" i18n-label="@@app.policies.edit.tabs.risks.label">
        <ng-template matTabContent>
          <howden-policy-edit-risks [policyId]="policyId"></howden-policy-edit-risks>
        </ng-template>
      </mat-tab>
    }

    @if (model.showCertificates) {
      <mat-tab label="Certificados" i18n-label="@@app.policies.edit.tabs.certificates.label">
        <ng-template matTabContent>
          <howden-policy-edit-certificates [policyId]="policyId"></howden-policy-edit-certificates>
        </ng-template>
      </mat-tab>
    }

    @if (model.showBillingGroups) {
      <mat-tab label="Grupos.Fact." i18n-label="@@app.policies.edit.tabs.billingGroups.label">
        <ng-template matTabContent>
          <howden-policy-edit-billing-groups [policyId]="policyId"></howden-policy-edit-billing-groups>
        </ng-template>
      </mat-tab>
    }

    @if (model.showBonuses) {
      <mat-tab label="Primas" i18n-label="@@app.policies.edit.tabs.bonuses.label">
        <ng-template matTabContent>
          <howden-policy-edit-bonuses [policyId]="policyId"></howden-policy-edit-bonuses>
        </ng-template>
      </mat-tab>
    }

    @if (model.showInitialState) {
      <mat-tab label="Foto inicial" i18n-label="@@app.policies.edit.tabs.initialState.label">
        <ng-template matTabContent>
          <howden-policy-edit-initial-state [policyId]="policyId"></howden-policy-edit-initial-state>
        </ng-template>
      </mat-tab>
    }

    @if (model.showBilling) {
      <mat-tab label="Facturación" i18n-label="@@app.policies.edit.tabs.billing.label">
        <ng-template matTabContent>
          <howden-policy-edit-billing [policyId]="policyId"></howden-policy-edit-billing>
        </ng-template>
      </mat-tab>
    }

    @if (model.showRegularization) {
      <mat-tab label="Regularización" i18n-label="@@app.policies.edit.tabs.regularization.label">
        <ng-template matTabContent>
          <howden-policy-edit-regularization [policyId]="policyId"></howden-policy-edit-regularization>
        </ng-template>
      </mat-tab>
    }

    @if (model.showParalizations) {
      <mat-tab label="Días paralización" i18n-label="@@app.policies.edit.tabs.paralizationDays.label">
        <ng-template matTabContent>
          <howden-policy-edit-paralizations [policyId]="policyId"></howden-policy-edit-paralizations>
        </ng-template>
      </mat-tab>
    }

    @if (model.showClaims) {
      <mat-tab label="Siniestros" i18n-label="@@app.policies.edit.tabs.claims.label">
        <ng-template matTabContent>
          <howden-policy-edit-claims [policyId]="policyId"></howden-policy-edit-claims>
        </ng-template>
      </mat-tab>
    }

    <mat-tab label="Suplementos" i18n-label="@@app.policies.edit.tabs.endorsements.label">
      <ng-template matTabContent>
        <howden-policy-edit-endorsements [policyId]="policyId"></howden-policy-edit-endorsements>
      </ng-template>
    </mat-tab>

    <mat-tab label="Recibos" i18n-label="@@app.policies.edit.tabs.receipts.label">
      <ng-template matTabContent>
        <howden-policy-edit-receipts [policyId]="policyId"></howden-policy-edit-receipts>
      </ng-template>
    </mat-tab>

    <mat-tab label="Incidencias" i18n-label="@@app.policies.edit.tabs.incidences.label">
      <ng-template matTabContent>
        <howden-policy-edit-incidences [policyId]="policyId"></howden-policy-edit-incidences>
      </ng-template>
    </mat-tab>

    <mat-tab label="Documentos" i18n-label="@@app.policies.edit.tabs.documents.label">
      <ng-template matTabContent>
        <howden-policy-edit-documents [policyId]="policyId"></howden-policy-edit-documents>
      </ng-template>
    </mat-tab>

    @if (model.showAssignRisks) {
      <mat-tab label="Asignar riesgos" i18n-label="@@app.policies.edit.tabs.assignRisks.label">
        <ng-template matTabContent>
          <howden-policy-edit-assign-risks [policyId]="policyId"></howden-policy-edit-assign-risks>
        </ng-template>
      </mat-tab>
    }

    @if (model.showImport) {
      <mat-tab label="Importar" i18n-label="@@app.policies.edit.tabs.assign.importPolicies.label">
        <ng-template matTabContent>
          <howden-policy-edit-import [policyId]="policyId"></howden-policy-edit-import>
        </ng-template>
      </mat-tab>
    }

  </mat-tab-group>

</div>
