import { ColumnPipeTypes, IHowdenColumnConfig } from '@howdeniberia/core-front';
import { IPolicyIncidenceView } from './policy-incidence-view';

export class PolicyEditIncidencesViewModel {
  data: IPolicyIncidenceView[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'incidenceId',
      fieldHeader: $localize`:@@app.policies.edit.incidences.incidenceId.column:ID`,
      canSort: true
    },
    {
      fieldName: 'date',
      fieldHeader: $localize`:@@app.policies.edit.incidences.date.column:Fecha`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.policies.edit.incidences.description.column:Descripción`,
      canSort: true
    },
    {
      fieldName: 'closingDate',
      fieldHeader: $localize`:@@app.policies.edit.incidences.closingDate.column:Fecha cierre`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'closeDescription',
      fieldHeader: $localize`:@@app.policies.edit.incidences.closeDescription.column:Descripción cierre`,
      canSort: true
    }
  ];
}
