<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.processes.search.description.label">Descripción</mat-label>
        <input #descriptionInput matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.processes.search.creationDate.label">Fecha alta</mat-label>
        <input
          matInput
          [matDatepicker]="creationDatePicker"
          [formControlName]="model.C_CREATION_DATE"
          [howdenControlError]="creationDatePickerError"
        />
        <mat-error #creationDatePickerError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="creationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #creationDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.processes.search.statusId.label">Estado</mat-label>
        <mat-select [formControlName]="model.C_STATUS_ID">
          <mat-option></mat-option>
          @for (item of model.processStatuses | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div>
    <howden-table
      class="small-font"
      keyField="processId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (filtersChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
