import { Component, OnInit, ViewChild } from '@angular/core';
import { HowdenTableComponent } from '@howdeniberia/core-front';
import { IFleetNotificationsService } from 'src/app/core/services/api/notifications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { PolicyExpirationNotificationsViewModel } from '../models';

@Component({
  selector: 'howden-policy-expiration-notifications',
  templateUrl: './policy-expiration-notifications.component.html',
  styleUrls: ['./policy-expiration-notifications.component.scss']
})
export class PolicyExpirationNotificationsComponent implements OnInit {
  model = new PolicyExpirationNotificationsViewModel();

  @ViewChild('#notificationsTable') tableRef!: HowdenTableComponent;

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private fleetNotifications: IFleetNotificationsService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();
    this.fleetNotifications.getPolicyExpirationNotifications().subscribe({
      next: (data) => this.model.data = data,
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.tableRef?.focus();
      }
    });
  }

  onExport(): void {
    this.fleetNotifications.exportPolicyExpirationNotifications().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_NOTIFICACIONES_VENCIMIENTOS_POLIZAS', blob);
    });
  }
}
