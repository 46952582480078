import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IClaimsRisksPerMonth } from 'src/app/core/models/api/dashboards';

@Component({
  selector: 'howden-claim-evolution-chart',
  templateUrl: './claim-evolution-chart.component.html',
  styleUrls: ['./claim-evolution-chart.component.scss']
})
export class ClaimEvolutionChartComponent implements OnChanges {
  @Input() data: Array<IClaimsRisksPerMonth> = [];
  @Input() valueFormat = '1.0-2';

  options: EChartsOption = {};

  constructor(private _decimalPipe: DecimalPipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue && changes['data'].currentValue.length > 0) {
      this.options = {
        tooltip: { trigger: 'axis' },
        legend: {
          backgroundColor: 'white',
          data: [
            $localize`:@@app.dashboards.claims.evolution.chart.risks.series.name:Riesgos`,
            $localize`:@@app.dashboards.claims.evolution.chart.claimos.series.name:Siniestros`
          ]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: this.data.map(x => x.monthName),
          axisLabel: {
            fontSize: 10,
            width: 100,
            overflow: 'truncate',
            rotate: 30
          },
          splitLine: { show: false }
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            fontSize: 10,
            formatter: (params: any): any => this._decimalPipe.transform(params, this.valueFormat)
          },
          splitLine: { show: false }
        }],
        series: [{
          name: $localize`:@@app.dashboards.claims.evolution.chart.risks.series.name:Riesgos`,
          type: 'line',
          data: this.data.map(x => x.riskCount)
        }, {
          name: $localize`:@@app.dashboards.claims.evolution.chart.claimos.series.name:Siniestros`,
          type: 'line',
          data: this.data.map(x => x.claimCount)
        }]
      };
    }
  }
}
