export enum BillingGroupRuleType {
  VehicleType = 1,
  VehicleUsage = 2,
  BookingMode = 3,
  MovementArea = 4,
  UnitBonusGroup = 5,
  PolicyHolder = 6,
  PolicyInsured = 7,
  CirculationArea = 8,
  GoodsType = 9
}
