import { IBonusHistoryReportRequest } from 'src/app/core/models/api/reports';
import { BonusHistoryReportViewModel } from '../models';

export abstract class BonusHistoryReportMapper {
  static mapForGeneration(activeFleet: string, source: BonusHistoryReportViewModel): IBonusHistoryReportRequest {
    return {
      fleetId: activeFleet,
      query: source.query,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      insuranceCompanyId: source.insuranceCompanyId,
      holderId: source.holderId,
      insuredId: source.insuredId
    } as IBonusHistoryReportRequest;
  }
}
