<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.fleets.edit.taxes.dialog.title">Impuestos</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.fleets.edit.taxes.billing.groups.name.label">Nombre</mat-label>
          <input matInput [formControlName]="model.C_NAME" maxlength="50" />
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.fleets.edit.taxes.startDate.label">Inicio</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            [formControlName]="model.C_START_DATE"
            [howdenControlError]="startDateError"
            [min]="minDate"
          />
          <mat-error #startDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.fleets.edit.taxes.endDate.label">Fin</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            [formControlName]="model.C_END_DATE"
            [howdenControlError]="endDateError"
            [min]="minDate"
          />
          <mat-error #endDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.fleets.edit.taxes.paymentType.label">Forma de pago</mat-label>
          <mat-select [formControlName]="model.C_PAYMENT_TYPE_ID" [howdenControlError]="paymentTypeIdError">
            @for (item of model.paymentTypes | howdenArraySort: 'description'; track item.erpId) {
              <mat-option [value]="item.erpId">
                {{ item.description }}
              </mat-option>
            }
          </mat-select>
          <mat-error #paymentTypeIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.fleets.edit.taxes.valueType.label">Tipo de valor</mat-label>
          <mat-select [formControlName]="model.C_VALUE_TYPE" [howdenControlError]="valueTypeError">
            @for (item of model.valueTypes | howdenArraySort: 'description'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #valueTypeError></mat-error>
        </mat-form-field>

        <howden-decimal
          class="f-basis--4"
          [formControlName]="model.C_VALUE"
          i18n-label="@@app.fleets.edit.taxes.value.label"
          label="Valor"
        ></howden-decimal>
      </div>

      <div class="flex f-gap--10px margin-bottom-10">
        <mat-slide-toggle
          class="f-basis--4 f-as--center"
          [formControlName]="model.C_RCSO"
          i18n="@@app.fleets.edit.taxes.rcso.label">RCSO</mat-slide-toggle>

        <mat-slide-toggle
          class="f-basis--4 f-as--center"
          [formControlName]="model.C_RC"
          i18n="@@app.fleets.edit.taxes.rc.label">RC</mat-slide-toggle>

        <mat-slide-toggle
          class="f-basis--4 f-as--center"
          [formControlName]="model.C_SOVI"
          i18n="@@app.fleets.edit.taxes.sovi.label">SOVI</mat-slide-toggle>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
