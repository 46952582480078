<form [formGroup]="model.form" class="howden-dialog-content text-center">
  <span mat-dialog-title class="title-text" i18n="@@app.policies.edit.rollback.replacement.title">Anular reemplazo/traspaso</span>
  <span class="subtitle-text" i18n="@@app.policies.edit.rollback.replacement.subtitle">
    Se anulará el reemplazo/traspaso actual, por favor, indique la fecha y el motivo de la baja para continuar.
  </span>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field>
        <mat-label i18n="@@app.policies.edit.rollback.replacement.operationDate.label">Fecha operación</mat-label>
        <input
          matInput
          [matDatepicker]="operationDatePicker"
          [formControlName]="model.C_OPERATION_DATE"
          [howdenControlError]="operationDateError"
          [min]="minDate"
        />
        <mat-error #operationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #operationDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field>
        <mat-label i18n="@@app.policies.edit.rollback.replacement.cancellationReason.label">Motivo anulación</mat-label>
        <mat-select [formControlName]="model.C_CANCELLATION_REASON_ID" [howdenControlError]="cancellationReasonError">
          @for (item of model.cancellationReasons; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #cancellationReasonError></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>

    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
