<form class="margin-top-10" [formGroup]="model.form">
  <div class="flex f-gap--10px margin-bottom-10">
    <mat-form-field class="f-basis--4">
      <mat-label i18n="@@app.policies.edit.regularization.date.label">Fecha</mat-label>
      <input
        matInput
        [matDatepicker]="dateDatePicker"
        [formControlName]="model.C_DATE"
        [howdenControlError]="dateDatePickerError"
        [min]="minDate"
      />
      <mat-error #dateDatePickerError></mat-error>
      <mat-datepicker-toggle matSuffix [for]="dateDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dateDatePicker></mat-datepicker>
    </mat-form-field>

    <div class="mat-form-field inline">
      <div class="mat-form-field-wrapper">
        <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onFilter()">
          <span i18n="@@app.core.actions.accept">Aceptar</span>
        </button>
      </div>
    </div>
  </div>
</form>

@if (hasData) {
  <div class="flex">
    <h4 i18n="@@app.policies.edit.regularization.summary.section.label">Resúmenes</h4>
  </div>
}

@for (group of model.groups; track group) {
  <mat-expansion-panel class="margin-bottom-10">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ group.description }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <howden-policy-edit-regularization-info [group]="group"></howden-policy-edit-regularization-info>
  </mat-expansion-panel>
}

@if (hasData) {
  <div class="flex">
    <h4 i18n="@@app.policies.edit.regularization.details.section.label">Detalles</h4>
  </div>

  <div class="flex margin-top-10">
    <form>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@app.policies.edit.regularization.filters.label">Filtro</mat-label>
        <mat-select [(ngModel)]="selectedFilter" name="filter">
          <mat-option value=""  i18n="@@app.policies.edit.regularization.filters.0">Mostrar todos</mat-option>
          <mat-option value="1" i18n="@@app.policies.edit.regularization.filters.1">Solo diferencias</mat-option>
          <mat-option value="2" i18n="@@app.policies.edit.regularization.filters.2">Real S/Fact</mat-option>
          <mat-option value="3" i18n="@@app.policies.edit.regularization.filters.3">Fact S/Real</mat-option>
          <mat-option value="4" i18n="@@app.policies.edit.regularization.filters.4">Paralizaciones</mat-option>
          <mat-option value="5" i18n="@@app.policies.edit.regularization.filters.5">Total días</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div class="flex margin-top-10">
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th class="text-center text-bold white-background" colspan="4">&nbsp;</th>
            <th
              class="text-center text-bold yellow-background"
              colspan="6"
              i18n="@app.policies.edit.regularization.details.billed.column.group"
            >
              Facturado
            </th>
            <th class="text-center text-bold gray-background" colspan="8" i18n="@app.policies.edit.regularization.details.real.column.group">
              Real
            </th>
            <th
              class="text-center text-bold pink-background color-text-primary-contrast"
              colspan="2"
              i18n="@app.policies.edit.regularization.details.difference.column.group"
            >
              Diferencia
            </th>
          </tr>
          <tr>
            <th i18n="@app.policies.edit.regularization.details.policyNumber.column">Nº.Póliza</th>
            <th i18n="@app.policies.edit.regularization.details.industry.column">Ramo</th>
            <th i18n="@app.policies.edit.regularization.details.plateNumber.column">Matrícula</th>
            <th i18n="@app.policies.edit.regularization.details.carNumber.column">Nº.Coche</th>
            <th i18n="@app.policies.edit.regularization.details.startDate.column">Desde</th>
            <th i18n="@app.policies.edit.regularization.details.endDate.column">Hasta</th>
            <th i18n="@app.policies.edit.regularization.details.dayCount.column">Nº.Días</th>
            <th i18n="@app.policies.edit.regularization.details.amount.column">Importe</th>
            <th i18n="@app.policies.edit.regularization.details.compensation.column">Compensación</th>
            <th i18n="@app.policies.edit.regularization.details.total.column">Total</th>
            <th i18n="@app.policies.edit.regularization.details.startDate.column">Desde</th>
            <th i18n="@app.policies.edit.regularization.details.endDate.column">Hasta</th>
            <th i18n="@app.policies.edit.regularization.details.dayCount.column">Nº.Días</th>
            <th i18n="@app.policies.edit.regularization.details.paralizationStart.column">I.Paralización</th>
            <th i18n="@app.policies.edit.regularization.details.paralizationEnd.column">F.Paralización</th>
            <th i18n="@app.policies.edit.regularization.details.paralizationDayCount.column">D.Paralización</th>
            <th i18n="@app.policies.edit.regularization.details.totalDayCount.column">Total Días</th>
            <th i18n="@app.policies.edit.regularization.details.total.column">Total</th>
            <th i18n="@app.policies.edit.regularization.details.difference.column">Diferencia</th>
          </tr>
        </thead>
        <tbody>
          @for (history of filteredHistory; track history) {
            <tr>
              <td>{{ history.policyNumber}}</td>
              <td>{{ history.industry }}</td>
              <td>{{ history.riskPlateNumber }}</td>
              <td>{{ history.riskCarNumber }}</td>
              <td class="border-left-dashed">{{ history.billed?.startDate | date }}</td>
              <td>{{ history.billed?.endDate | date }}</td>
              <td class="text-right">{{ history.billed?.totalDayCount | number }}</td>
              <td class="text-right">{{ history.billed?.subtotalAmount| number : '1.2-2' }}</td>
              <td class="text-right">{{ history.billed?.compensationAmount| number : '1.2-2' }}</td>
              <td class="text-right border-right-dashed">{{ history.billed?.totalAmount| number : '1.2-2' }}</td>
              <td>{{ history.real?.startDate | date }}</td>
              <td>{{ history.real?.endDate | date }}</td>
              <td class="text-right">{{ history.real?.dayCount | number }}</td>
              <td>{{ history.real?.paralizationStart | date }}</td>
              <td>{{ history.real?.paralizationEnd | date }}</td>
              <td class="text-right">
                @if (history.real?.paralizationDayCount) {
                  <span>{{ history.real?.paralizationDayCount | number }}</span>
                }
              </td>
              <td class="text-right">{{ history.real?.totalDayCount | number }}</td>
              <td class="text-right border-right-dashed">{{ history.real?.totalAmount| number : '1.2-2' }}</td>
              <td class="text-right text-bold" [class]="getDiffClass(history)">{{ (history.real?.totalAmount ?? 0) - (history.billed?.totalAmount ?? 0) | number : '1.2-2' }}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
}
