import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { InsurerMergerMapper } from '../mappers';
import { InsurerMergerViewModel } from '../models';

@Component({
  selector: 'howden-insurer-merger',
  templateUrl: './insurer-merger.component.html',
  styleUrls: ['./insurer-merger.component.scss']
})
export class InsurerMergerComponent implements OnInit, OnDestroy {
  model = new InsurerMergerViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private fleetSrv: IFleetService) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getInsuranceCompanies('0'),
      this.fleetSrv.getIndustries('0')
    ]).subscribe(([
      insuranceCompanies
    ]) => {
      this.model.insuranceCompanies = insuranceCompanies;

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    const title: string = $localize`:@@app.insurer.merger.confirm.title:Fusión de aseguradoras`;
    const question: string = $localize`:@@app.insurer.merger.confirm.warning:Se procederá con el proceso de importación de las pólizas con los parámetros indicados, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const request = InsurerMergerMapper.mapForUpdate(this.model);

          this.uiBlockerSrv.block();

          this.fleetSrv
            .insurerMerger(request)
            .pipe(
              tap(() => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.model.form.reset();
                  this.model.data = [];
                });
              })
            ).subscribe();
        }
      })
    ).subscribe();
  }

  onPreviewMerge(): void {
    const request = InsurerMergerMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.fleetSrv.insurerMergerPreview(request).subscribe({
      next: (data) => this.model.data = data,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
