import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { BookingModeSearchComponent } from './booking-modes/pages/booking-mode-search.component';
import { FleetCreateComponent } from './fleet-create/pages/fleet-create.component';
import { FleetEditLayoutComponent } from './fleet-edit/pages/fleet-edit-layout.component';
import { FleetSearchComponent } from './fleet-search/pages/fleet-search.component';
import { FleetUpdaterComponent } from './fleet-updater/pages/fleet-updater.component';
import { InitialStateLoaderComponent } from './initial-state-loader/pages/initial-state-loader.component';
import { InsuranceDaysComponent } from './insurance-days/pages/insurance-days.component';
import { InsurerMergerComponent } from './insurer-merger/pages/insurer-merger.component';
import { ParalizationLoaderComponent } from './paralization-loader/pages/paralization-loader.component';

export const FleetRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'fleets',
    title: $localize`:@@app.route.fleets:Flotas`,
    data: {
      icon: 'local_shipping',
      linkText: $localize`:@@app.route.fleets:Flotas`
    },
    children: [
      {
        path: 'create',
        component: FleetCreateComponent,
        title: $localize`:@@app.route.fleets.create:Alta de flota`,
        data: { linkText: $localize`:@@app.route.fleet.create:Alta de flota` }
      },
      {
        path: 'search',
        component: FleetSearchComponent,
        title: $localize`:@@app.route.fleets.search:Consulta global`,
        data: { linkText: $localize`:@@app.route.fleet.search:Consulta global` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'edit/:id',
        component: FleetEditLayoutComponent,
        title: $localize`:@@app.route.fleets.edit:Modificar flota`
      },
      {
        path: 'insuranceDays',
        component: InsuranceDaysComponent,
        title: $localize`:@@app.route.fleets.insurance.days:Días de seguro`,
        data: { linkText: $localize`:@@app.route.fleets.insurance.days:Días de seguro` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'booking-modes',
        component: BookingModeSearchComponent,
        title: $localize`:@@app.route.fleets.booking.modes:Modos de contratación`,
        data: { linkText: $localize`:@@app.route.fleets.booking.modes:Modos de contratación` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'initial-state-loader',
        component: InitialStateLoaderComponent,
        title: $localize`:@@app.route.fleets.initial.state.loader:Carga foto inicial`,
        data: { linkText: $localize`:@@app.route.fleets.initial.state.loader:Carga foto inicial` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'paralization-loader',
        component: ParalizationLoaderComponent,
        title: $localize`:@@app.route.fleets.paralization.loader:Carga de paralizaciones`,
        data: { linkText: $localize`:@@app.route.fleets.paralization.loader:Carga de paralizaciones` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'fleet-updater',
        component: FleetUpdaterComponent,
        title: $localize`:@@app.route.fleets.fleet.updater:Actualización flota`,
        data: { linkText: $localize`:@@app.route.fleets.fleet.updater:Actualización flota` }
      },
      {
        path: 'insurer-merger',
        component: InsurerMergerComponent,
        title: $localize`:@@app.route.fleets.insurer.merger:Fusión aseguradoras`,
        data: { linkText: $localize`:@@app.route.fleets.insurer.merger:Fusión aseguradoras` }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(FleetRoutes)],
  exports: [RouterModule]
})
export class FleetRoutingModule {
}
