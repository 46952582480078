import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Limits } from 'src/app/shared/utils';
import { dateRangeValidator } from 'src/app/shared/validators';

export class PolicyEditBonusViewModel {
  private _form: FormGroup;

  readonly C_BONUS_ID = 'bonusId';
  readonly C_EFFECT_DATE = 'effectDate';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_NET_BONUS_RC = 'netBonusRC';
  readonly C_TOTAL_RC = 'totalRC';
  readonly C_COMMISSION_RATE = 'commissionRate';
  readonly C_EXTRA_COMMISSION_RATE = 'extraCommissionRate';

  constructor() {
    this._form = new FormGroup({
      [this.C_BONUS_ID]: new FormControl(null, []),
      [this.C_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_NET_BONUS_RC]: new FormControl(0, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_TOTAL_RC]: new FormControl(0, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_COMMISSION_RATE]: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      [this.C_EXTRA_COMMISSION_RATE]: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)])
    }, [dateRangeValidator('effectDate', 'expirationDate')]);
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get bonusId(): string | undefined {
    return this.getControl(this.C_BONUS_ID).value;
  }

  set bonusId(value: string | undefined) {
    this.getControl(this.C_BONUS_ID).setValue(value);
  }

  get effectDate(): Date | undefined {
    return this.getControl(this.C_EFFECT_DATE).value;
  }

  set effectDate(value: Date | undefined) {
    this.getControl(this.C_EFFECT_DATE).setValue(value);
  }

  get expirationDate(): Date | undefined {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | undefined) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get netBonusRC(): number {
    return this.getControl(this.C_NET_BONUS_RC).value;
  }

  set netBonusRC(value: number) {
    this.getControl(this.C_NET_BONUS_RC).setValue(value);
  }

  get totalRC(): number {
    return this.getControl(this.C_TOTAL_RC).value;
  }

  set totalRC(value: number) {
    this.getControl(this.C_TOTAL_RC).setValue(value);
  }

  get commissionRate(): number {
    return this.getControl(this.C_COMMISSION_RATE).value;
  }

  set commissionRate(value: number) {
    this.getControl(this.C_COMMISSION_RATE).setValue(value);
  }

  get extraCommissionRate(): number {
    return this.getControl(this.C_EXTRA_COMMISSION_RATE).value;
  }

  set extraCommissionRate(value: number) {
    this.getControl(this.C_EXTRA_COMMISSION_RATE).setValue(value);
  }
}
