<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex margin-bottom-25">
      <button type="button" class="f-basis--3" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.reports.operating.companies.claimPaymentDate.label">Carga siniestral</mat-label>
        <mat-select [formControlName]="model.C_CLAIM_PAYMENT_DATE_ID" [howdenControlError]="claimPaymentDateIdError">
          @for (item of model.claimPaymentDates | howdenArraySort: 'description'; track item.paymentDateId) {
            <mat-option [value]="item.paymentDateId">
              {{ item.date | date }}
            </mat-option>
          }
        </mat-select>
        <mat-error #claimPaymentDateIdError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--3"
        [formControlName]="model.C_YEAR"
        [allowNegative]="false"
        [precision]="0"
        i18n-label="@@app.reports.operating.companies.year.label"
        label="Año"
      ></howden-decimal>
    </div>

    <div class="flex f-gap--10px">
      <mat-slide-toggle
        class="f-basis--3 f-as--center"
        [formControlName]="model.C_INCLUDE_POLICIES_WITH_INDIVIDUAL_BONUSES"
        i18n="@@app.reports.operating.companies.includePoliciesWithIndividualBonuses.label">Incluir pólizas con primas individuales</mat-slide-toggle>
    </div>
  </form>
</div>
