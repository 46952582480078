import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { IFleet } from 'src/app/core/models/api/fleets';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { SubSink } from 'subsink';

@Component({
  selector: 'howden-current-fleet',
  templateUrl: './current-fleet.component.html',
  styleUrls: ['./current-fleet.component.scss']
})
export class CurrentFleetComponent implements OnInit, AfterContentInit, OnDestroy {
  activeFleet?: IFleet;

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService
  ) {
  }

  ngOnInit(): void {
    this._subscriptions.sink = this.sessionStorage.observe('active-fleet').subscribe({
      next: value => this.onUpdate(value)
    });
  }

  ngAfterContentInit(): void {
    if (!this.activeFleet && this.sessionSrv.hasActiveFleet) {
      this.onUpdate(this.sessionSrv.activeFleet);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onNavigate(): void {
    if (this.activeFleet) {
      this.router.navigate(['fleets/edit', this.activeFleet.fleetId]);
    }
  }

  get isVisible(): boolean { return typeof this.activeFleet !== 'undefined' && this.activeFleet !== null; }

  private onUpdate(value: string): void {
    if (value && typeof value !== 'undefined' && value !== null) {
      this.fleetSrv.get(value).subscribe((fleet: IFleet) => this.activeFleet = fleet);
    }
  }
}
