<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  @if (!hasUpdaterResults) {
    <div>
      <form [formGroup]="model.form">
        <div class="flex f-gap--10px">
          <howden-file-upload #file class="margin-10" (filesSelected)="onFileSelected()"></howden-file-upload>
        </div>
        <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
            <span i18n="@@app.core.actions.accept">Aceptar</span>
          </button>
        </div>
      </form>
    </div>
  } @else {
    <div>
      <howden-table
        class="small-font"
        paginable="local"
        sortable="local"
        filterable="global-local"
        [pageSize]="10"
        [cols]="model.updaterResultsColumns"
        [data]="model.updaterResults"
      >
      </howden-table>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10">
      <button type="button" mat-raised-button (click)="onGoBack()">
        <span i18n="@@app.core.actions.back">Volver</span>
      </button>
    </div>
  }
</div>
