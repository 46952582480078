import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IStatistic } from 'src/app/core/models/api/core';
import { IBonusBoard, IClaimDashboardPivotResponse, IClaimDashboardRequest, IClaimDashboardResponse, IInitialStateDashboardRequest, IInitialStateDashboardResponse, IReceiptDashboardRequest, IReceiptDashboardResponse } from 'src/app/core/models/api/dashboards';
import { IDashboardService } from './dashboard.contract';

@Injectable({
  providedIn: 'root'
})
export class DashboardService implements IDashboardService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  /* Bonus board */
  getBonusBoard(fleetId: string): Observable<Array<IBonusBoard>> {
    return this.httpClient.get<Array<IBonusBoard>>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/bonus-board`);
  }

  /* Receipts Dashboard */
  getReceiptsDashboard(fleetId: string, request: IReceiptDashboardRequest): Observable<IReceiptDashboardResponse> {
    return this.httpClient.post<IReceiptDashboardResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/receipts`, request);
  }

  exportReceiptsDashboard(fleetId: string, request: IReceiptDashboardRequest): Observable<Blob> {
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/export-receipts`, request, {
      responseType: 'blob'
    });
  }

  /* Claims Dashboard */
  getClaimsDashboard(fleetId: string, request: IClaimDashboardRequest): Observable<IClaimDashboardResponse> {
    return this.httpClient.post<IClaimDashboardResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/claims`, request);
  }
  getClaimsDashboardPivots(fleetId: string, request: IClaimDashboardRequest): Observable<IClaimDashboardPivotResponse> {
    return this.httpClient.post<IClaimDashboardPivotResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/claims-pivot`, request);
  }

  /* Initial State Dashboard */
  getInitialStateDashboard(fleetId: string, request: IInitialStateDashboardRequest): Observable<IInitialStateDashboardResponse> {
    return this.httpClient.post<IInitialStateDashboardResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/initial-state`, request);
  }

  exportInitialStateDashboard(fleetId: string, request: IInitialStateDashboardRequest): Observable<Blob> {
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/export-initial-state`, request, {
      responseType: 'blob'
    });
  }

  /* By Fleet */
  getUnitCount(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/unit-count`);
  }
  getActivePolicyCount(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/active-policy-count`);
  }
  getRootPolicyCount(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/root-policy-count`);
  }
  getChildPolicyCount(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/child-policy-count`);
  }
  getPolicyCountWithoutNumber(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/policy-count-without-number`);
  }
  getUnitCountWithoutBonus(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/unit-count-without-bonus`);
  }
  getUnitCountWithoutPolicy(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/unit-count-without-policy`);
  }
  getUnitCountWithAllPoliciesCancelled(fleetId: string): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/statistics/unit-count-with-all-policies-cancelled`);
  }
  getUnitsByVehicleType(fleetId: string): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/graphs/units-by-vehicle-type`);
  }
  getUnitsByPolicy(fleetId: string): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/graphs/units-by-policy`);
  }
  getAverageBonusByVehicleType(fleetId: string): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/graphs/average-bonus-by-vehicle-type`);
  }
  getPoliciesByPaymentType(fleetId: string): Observable<IStatistic[]> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, `/dashboards/${fleetId}/graphs/policies-by-payment-type`);
  }

  /* General */
  getGeneralUnitCount(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/unit-count');
  }
  getGeneralUnitCountWithoutBonus(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/unit-count-without-bonus');
  }
  getGeneralUnitCountWithoutPolicy(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/unit-count-without-policy');
  }
  getGeneralUnitCountWithAllPoliciesCancelled(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/unit-count-with-all-policies-cancelled');
  }
  getGeneralRootPolicyCount(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/root-policy-count');
  }
  getGeneralChildPolicyCount(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/child-policy-count');
  }
  getGeneralPolicyCountWithoutNumber(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/statistics/policy-count-without-number');
  }
  getGeneralUnitsByVehicleType(): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/graphs/units-by-vehicle-type');
  }
  getGeneralPoliciesByInsuranceCompany(): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/graphs/policies-by-insurance-company');
  }
  getGeneralPoliciesByOffice(): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/graphs/policies-by-office');
  }
  getGeneralPoliciesByPaymentType(): Observable<Array<IStatistic>> {
    return this.httpClient.get<Array<IStatistic>>(APP_CONSTANTS.API_NET_FLOTAS, '/dashboards/general/graphs/policies-by-payment-type');
  }
}
