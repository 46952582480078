  <form [formGroup]="model.form" class="margin-top-10">
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.fleets.edit.id.label">Id</mat-label>
        <input matInput [formControlName]="model.C_ID" />
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.fleets.edit.name.label">Nombre</mat-label>
        <input matInput [formControlName]="model.C_NAME" [howdenControlError]="nameError" maxlength="100" />
        <mat-error #nameError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.fleets.edit.description.label">Descripción</mat-label>
        <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
      <button type="button" mat-raised-button (click)="onClose()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
        <span i18n="@@app.core.actions.save">Guardar</span>
      </button>
    </div>
  </form>
