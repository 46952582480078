import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IItemSelection } from 'src/app/core/models/api/core';

export class RisksInsuredReportViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IItemSelection> = [];

  readonly C_DATE = 'startDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_DATE]: new FormControl(null, [])
    });
  }

  get rootPolicies(): Array<IItemSelection> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IItemSelection>) {
    this._rootPolicies = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get date(): Date | null {
    return this.getControl(this.C_DATE).value;
  }

  set date(value: Date | null) {
    this.getControl(this.C_DATE).setValue(value);
  }
}
