import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IItemSelection } from 'src/app/core/models/api/core';

export class UnitReportViewModel {
  private _form: FormGroup;
  private _vehicleTypes: Array<IItemSelection> = [];
  private _vehicleUsages: Array<IItemSelection> = [];
  private _movementAreas: Array<IItemSelection> = [];
  private _statuses: Array<IItemSelection> = [];

  readonly C_QUERY = 'query';

  constructor() {
    this._form = new FormGroup({
      [this.C_QUERY]: new FormControl(null, [])
    });
  }

  get statuses(): Array<IItemSelection> {
    return this._statuses;
  }

  set statuses(statuses: Array<IItemSelection>) {
    this._statuses = statuses;
  }

  get vehicleTypes(): Array<IItemSelection> {
    return this._vehicleTypes;
  }

  set vehicleTypes(value: Array<IItemSelection>) {
    this._vehicleTypes = value;
  }

  get vehicleUsages(): Array<IItemSelection> {
    return this._vehicleUsages;
  }

  set vehicleUsages(value: Array<IItemSelection>) {
    this._vehicleUsages = value;
  }

  get movementAreas(): Array<IItemSelection> {
    return this._movementAreas;
  }

  set movementAreas(value: Array<IItemSelection>) {
    this._movementAreas = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get query(): string | null {
    return this.getControl(this.C_QUERY).value;
  }

  set query(value: string | null) {
    this.getControl(this.C_QUERY).setValue(value);
  }
}
