import { IInsuranceDays } from 'src/app/core/models/api/insurance-days';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';
import { IInsuranceDayViewModel } from '../models';

export abstract class InsuranceDaysMapper {
  static mapForSearch(insuranceCompanies: Array<IInsuranceCompany>, source: Array<IInsuranceDays>): Array<IInsuranceDayViewModel> {
    return source.map(insuranceDay => {
      const insuranceCompany = insuranceCompanies.find(x => x.insuranceCompanyId === insuranceDay.insuranceCompanyId);
      return {
        insuranceCompanyId: insuranceDay.insuranceCompanyId,
        insuranceCompany: `${insuranceCompany?.alias}-${insuranceCompany?.name}`,
        dayCount: insuranceDay.dayCount
      } as IInsuranceDayViewModel;
    });
  }
}
