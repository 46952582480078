import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { IPolicy } from 'src/app/core/models/api/policies';
import { IOffice } from 'src/app/core/models/ida/company';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyEditOfficeUpdateViewModel {
  private _form: FormGroup;
  private _offices: Array<IOffice> = [];
  private _policy?: IPolicy;

  private _filteredOffices: Observable<Array<IOffice>>;

  readonly C_OFFICE_ID = 'officeId';
  readonly C_UPDATE_CANCELLED_POLICIES = 'updateCancelledChildPolicies';

  constructor() {
    this._form = new FormGroup({
      [this.C_OFFICE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.offices, 'officeId')]),
      [this.C_UPDATE_CANCELLED_POLICIES]: new FormControl(false, [])
    });

    this._filteredOffices = this.getControl(this.C_OFFICE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterOffices(value || ''))
    );
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get policy(): IPolicy | undefined {
    return this._policy;
  }

  set policy(value: IPolicy | undefined) {
    this._policy = value;
  }

  get offices(): Array<IOffice> {
    return this._offices;
  }

  set offices(value: Array<IOffice>) {
    this._offices = value;
  }

  get officeId(): string | undefined {
    return this.getControl(this.C_OFFICE_ID).value;
  }

  set officeId(value: string | undefined) {
    this.getControl(this.C_OFFICE_ID).setValue(value);
  }

  get updateCancelledChildPolicies(): boolean | undefined {
    return this.getControl(this.C_UPDATE_CANCELLED_POLICIES).value;
  }

  set updateCancelledChildPolicies(value: boolean | undefined) {
    this.getControl(this.C_UPDATE_CANCELLED_POLICIES).setValue(value);
  }

  get filteredOffices(): Observable<Array<IOffice>> {
    return this._filteredOffices;
  }

  private filterOffices(value: string): Array<IOffice> {
    return value !== ''
      ? this.offices.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this.offices;
  }
}
