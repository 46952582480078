import { Component } from '@angular/core';
import { IReportsService } from 'src/app/core/services/api/reports';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';

@Component({
  selector: 'howden-fleet-report',
  templateUrl: './fleet-report.component.html',
  styleUrls: ['./fleet-report.component.scss']
})
export class FleetReportComponent {
  constructor(
    private reportsSrv: IReportsService,
    private uiBlockerSrv: IUIBlockerService) {
  }

  onGenerateReport() {
    this.uiBlockerSrv.block();
    this.reportsSrv.fleets().subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_FLOTAS', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
