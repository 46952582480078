import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HowdenTableComponent } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IReceiptDashboardResponse } from 'src/app/core/models/api/dashboards';
import { IDashboardService } from 'src/app/core/services/api/dashboards';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IBreakPointObserverSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';
import { ReceiptsDashboardMapper } from '../mappers';
import { ReceiptsDashboardViewModel } from '../models';

@Component({
  selector: 'howden-receipts-dashboard',
  templateUrl: './receipts-dashboard.component.html',
  styleUrls: ['./receipts-dashboard.component.scss']
})
export class ReceiptsDashboardComponent implements OnInit, OnDestroy {
  model = new ReceiptsDashboardViewModel();

  get minDate(): Date { return Limits.minDate; }

  cardLayout = this.breakpointObserver.observe();

  @ViewChild('receiptsTable') receiptsTable?: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private breakpointObserver: IBreakPointObserverSrv,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private dashboardSrv: IDashboardService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getInsuranceCompanies(fleetId)
    ]).subscribe(([
      insuranceCompanies
    ]) => {
      this.model.insuranceCompanies = insuranceCompanies;

      this.onChanges();

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onFilter(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = ReceiptsDashboardMapper.map(this.model);

    this.uiBlockerSrv.block();

    this.onChanges();

    this.model.response = null;

    forkJoin([
      this.fleetSrv.getDetails(fleetId),
      this.dashboardSrv.getReceiptsDashboard(fleetId, request)
    ]).subscribe(([
      fleet,
      response
    ]) => {
      this.model.fleet = fleet;
      this.model.response = response;
      this.model.data = response.details;

      this.initializeMiniCards(response);

      this.uiBlockerSrv.unblock();
    });
  }

  onTableExport(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = ReceiptsDashboardMapper.map(this.model);

    this.uiBlockerSrv.block();

    this.dashboardSrv.exportReceiptsDashboard(fleetId, request).subscribe({
      next: blob => FileUtils.downloadFile('HOWDEN_NET_FLOTAS_DASHBOARD_RECIBOS', blob),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  get canExport(): boolean {
    const details = this.model.response?.details;
    return typeof details !== 'undefined' && details !== null && details.length > 0;
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.form.valueChanges.subscribe(() => {
      this.model.response = null;
    });
  }

  private initializeMiniCards(response: IReceiptDashboardResponse) {
    this.model.miniCardData = [{
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.issued.title:Emitidos`,
      value: response.issuedAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.issued.duration:recibos emitidos`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.pending.title:Pendientes`,
      value: response.pendingAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.pending.duration:recibos pendientes`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.billed.title:Facturados`,
      value: response.billedAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.billed.duration:recibos facturados`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.returned.title:Devueltos`,
      value: response.returnedAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.returned.duration:recibos devueltos`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.cancelled.title:Anulados`,
      value: response.cancelledAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.cancelled.duration:recibos anulados`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.insurance.company.cancelled.title:Anulados (CIA)`,
      value: response.insuranceCompanyCancelledAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.insurance.company.cancelled.duration:recibos anulados (CIA)`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.collected.title:Cobrados`,
      value: response.collectedAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.collected.duration:recibos cobrados`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.receipts.mini.cards.insurance.company.settled.title:Liquidados (CIA)`,
      value: response.insuranceCompanySettledAmount,
      color: 'primary',
      isCurrency: true,
      duration: $localize`:@@app.dashboards.receipts.mini.cards.insurance.company.settled.duration:recibos liquidados (CIA)`
    } as IMiniCardData];
  }
}
