import { IReceiptDashboardRequest } from 'src/app/core/models/api/dashboards';
import { ReceiptsDashboardViewModel } from '../models';

export abstract class ReceiptsDashboardMapper {
  static map(source: ReceiptsDashboardViewModel): IReceiptDashboardRequest {
    return {
      startDate: source.startDate,
      endDate: source.endDate,
      insuranceCompanyId: source.insuranceCompanyId,
      policyNumber: source.policyNumber
    } as IReceiptDashboardRequest;
  }
}
