import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyCancellerFileItem } from 'src/app/core/models/api/fleets';

export class PolicyCancellerViewModel {
  private _form: FormGroup;

  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });
  }

  updaterResults: IPolicyCancellerFileItem[] = [];
  updaterResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.updater.insuranceCompany.column:Compañía`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.updater.policyNumber.column:Nº. Póliza`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.updater.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get form(): FormGroup { return this._form; }
}
