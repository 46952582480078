import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogResultTypes,
  IButtonActionData,
  IDialogResult,
  IPageOf,
  ITableEventData
} from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { ICirculationArea } from 'src/app/core/models/api/units';
import { ICirculationAreaService } from 'src/app/core/services/api/circulation-area';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UpsertCirculationAreaDialogComponent } from '../dialogs/upsert-functional-type-dialog/pages/upsert-circulation-area-dialog.component';
import { CirculationAreaSearchViewModel } from '../models/circulation-area-search.viewmodel';

@Component({
  selector: 'howden-circulation-area-search',
  templateUrl: './circulation-area-search.component.html',
  styleUrls: ['./circulation-area-search.component.scss']
})
export class CirculationAreaSearchComponent implements OnInit, OnDestroy {
  model = new CirculationAreaSearchViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private circulationAreaSrv: ICirculationAreaService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    this.refresh();
    this.onChanges();

    this.uiBlockerSrv.unblock();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEditCirculationArea(event);
    } else {
      this.onDeleteCirculationArea(event);
    }
  }

  onNewCirculationArea(): void {
    const dialogRef = this.dialog.open(UpsertCirculationAreaDialogComponent, { minWidth: '800px', width: 'auto' });
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refresh());
  }

  onEditCirculationArea(event: IButtonActionData): void {
    const payload = { minWidth: '800px', width: 'auto', data: { circulationAreaId: event.row.circulationAreaId } };
    const dialogRef = this.dialog.open(UpsertCirculationAreaDialogComponent, payload);
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refresh());
  }

  onDeleteCirculationArea(event: IButtonActionData): void {
    const circulationAreaId = event.row.circulationAreaId;
    const title: string = $localize`:@@app.circulation.areas.delete.title:Borrado de recintos de circulación`;
    const question: string = $localize`:@@app.circulation.areas.delete.subtitle:Se borrará el recinto de circulación, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.circulation.areas.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();
          this.circulationAreaSrv.delete(circulationAreaId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.form.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.searchRequest.description = this.model.description;
      this.refresh();
    });
  }

  private refresh(): void {
    this.circulationAreaSrv.search(this.model.searchRequest).subscribe((searchResult: IPageOf<ICirculationArea>) => {
      this.model.length = searchResult.totalCount;
      this.model.data = searchResult.data;
    });
  }
}
