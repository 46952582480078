<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  @if (!hasUpdaterResults) {
    <div>
      <form [formGroup]="model.form">
        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--6"
            i18n-label="@@app.endorsements.loader.endorsementType.label"
            label="Tipo"
            displayField="description"
            keyField="erpId"
            [selectableItems]="model.endorsementTypes"
            [formControlName]="model.C_ENDORSEMENT_TYPE_ID"
          >
          </howden-autocomplete>
          <howden-autocomplete
            class="f-basis--6"
            i18n-label="@@app.endorsements.loader.endorsementStatus.label"
            label="Estado"
            displayField="description"
            keyField="erpId"
            [selectableItems]="model.endorsementStatuses"
            [formControlName]="model.C_ENDORSEMENT_STATUS_ID"
          >
          </howden-autocomplete>
        </div>

        <div class="flex">
          <h4 i18n="@@app.endorsements.loader.sections.endorsement.references">Referencias</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--6">
            <mat-label i18n="@@app.endorsements.loader.endorsementAlias.label">Propia</mat-label>
            <input
              matInput
              [formControlName]="model.C_ENDORSEMENT_ALIAS"
              [howdenControlError]="endorsementAliasError"
              maxlength="20"
            />
            <mat-error #endorsementAliasError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--6">
            <mat-label i18n="@@app.endorsements.loader.endorsementInsuranceCompanyReference.label">Ref. Aseguradora</mat-label>
            <input
              matInput
              [formControlName]="model.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE"
              [howdenControlError]="endorsementInsuranceCompanyReferenceError"
              maxlength="20"
            />
            <mat-error #endorsementInsuranceCompanyReferenceError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <h4 i18n="@@app.endorsements.loader.sections.endorsement.dates">Fechas</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.endorsements.loader.endorsementRequestDate.label">Fecha solicitud</mat-label>
            <input
              matInput
              [matDatepicker]="endorsementRequestDatePicker"
              [formControlName]="model.C_ENDORSEMENT_REQUEST_DATE"
              [howdenControlError]="endorsementRequestDateError"
              [min]="minDate"
            />
            <mat-error #endorsementRequestDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="endorsementRequestDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endorsementRequestDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.endorsements.loader.endorsementDateSentToInsuranceCompany.label">Fecha de envío a cia.</mat-label>
            <input
              matInput
              [matDatepicker]="dateSentToInsuranceCompanyPicker"
              [formControlName]="model.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY"
              [howdenControlError]="dateSentToInsuranceCompanyError"
              [min]="minDate"
            />
            <mat-error #dateSentToInsuranceCompanyError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="dateSentToInsuranceCompanyPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateSentToInsuranceCompanyPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.endorsements.loader.endorsementReceiptionDate.label">Fecha recepción</mat-label>
            <input
              matInput
              [matDatepicker]="endosementReceptionDatePicker"
              [formControlName]="model.C_ENDORSEMENT_RECEPTION_DATE"
              [howdenControlError]="endosementReceptionDateError"
              [min]="minDate"
            />
            <mat-error #endosementReceptionDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="endosementReceptionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endosementReceptionDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.endorsements.loader.endorsementEffectDate.label">Fecha efecto</mat-label>
            <input
              matInput
              [matDatepicker]="endorsementEffectDatePicker"
              [formControlName]="model.C_ENDORSEMENT_EFFECT_DATE"
              [howdenControlError]="endorsementEffectDateError"
              [min]="minDate"
            />
            <mat-error #endorsementEffectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="endorsementEffectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endorsementEffectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.endorsements.loader.endorsementExpirationDate.label">Fecha vencimiento</mat-label>
            <input
              matInput
              [matDatepicker]="endorsementExpirationDatePicker"
              [formControlName]="model.C_ENDORSEMENT_EXPIRATION_DATE"
              [howdenControlError]="endorsementExpirationDateError"
              [min]="minDate"
            />
            <mat-error #endorsementExpirationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="endorsementExpirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endorsementExpirationDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.endorsements.loader.endorsementCancellationDate.label">Fecha baja</mat-label>
            <input
              matInput
              [matDatepicker]="endosementExpirationDatePicker"
              [formControlName]="model.C_ENDORSEMENT_CANCELLATION_DATE"
              [howdenControlError]="endosementExpirationDateError"
              [min]="minDate"
            />
            <mat-error #endosementExpirationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="endosementExpirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endosementExpirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex">
          <h4 i18n="@@app.endorsements.loader.sections.endorsement.observations">Observaciones</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.endorsements.loader.endorsementDescription.label">Descripción</mat-label>
            <input
              matInput
              [formControlName]="model.C_ENDORSEMENT_DESCRIPTION"
              [howdenControlError]="endorsementDescriptionError"
              maxlength="255"
            />
            <mat-error #endorsementDescriptionError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.endorsements.loader.endorsementObservations.label">Observaciones</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_ENDORSEMENT_OBSERVATIONS"
              [howdenControlError]="endorsementObservationsError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="15"
            >
            </textarea>
            <mat-error #endorsementObservationsError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <h4 i18n="@@app.endorsements.loader.sections.endorsement.changes">Descripción cambios</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.endorsements.loader.endorsementOldInfo.label">Información anterior</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_ENDORSEMENT_OLD_INFO"
              [howdenControlError]="endorsementOldInfoError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="15"
              maxlength="255"
            >
            </textarea>
            <mat-error #endorsementOldInfoError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label i18n="@@app.endorsements.loader.endorsementNewInfo.label">Información nueva</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_ENDORSEMENT_NEW_INFO"
              [howdenControlError]="endorsementNewInfoError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="15"
              maxlength="255"
            >
            </textarea>
            <mat-error #endorsementNewInfoError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <h4 i18n="@@app.endorsements.loader.sections.policies">Indique el fichero con la selección de pólizas</h4>
        </div>
        <div class="flex f-gap--10px">
          <howden-file-upload #file class="margin-10" (filesSelected)="onFileSelected()"></howden-file-upload>
        </div>

        <div class="f-basis--12">
          <div class="flex f-jc--center f-gap--10px">
            <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
              <span i18n="@@app.core.actions.accept">Aceptar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  } @else {
    <div>
      <howden-table
        class="small-font"
        paginable="local"
        sortable="local"
        filterable="global-local"
        [pageSize]="10"
        [cols]="model.updaterResultsColumns"
        [data]="model.updaterResults"
      >
      </howden-table>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10">
      <button type="button" mat-raised-button (click)="onGoBack()">
        <mat-icon>arrow_back</mat-icon>
        <span i18n="@@app.core.actions.back">Volver</span>
      </button>
    </div>
  }
</div>
