import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IEndorsementSummary } from 'src/app/core/models/api/endorsements';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { EndorsementSummaryViewModel } from '../models';

@Component({
  selector: 'howden-endorsements-summary-dialog',
  templateUrl: './endorsement-summary-dialog.component.html',
  styleUrls: ['./endorsement-summary-dialog.component.scss']
})
export class EndorsementSummaryDialogComponent implements OnInit {
  model = new EndorsementSummaryViewModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EndorsementSummaryDialogComponent>,
    private policySrv: IPolicyService) {
  }

  ngOnInit(): void {
    const policyId = this.inputData.policyId as string;
    const endorsementId = this.inputData.endorsementId as string;

    this.policySrv.getEndorsementSummary(policyId, endorsementId).subscribe((data: IEndorsementSummary) => {
      this.model = new EndorsementSummaryViewModel(data);
    });
  }

  onClose(): void {
    this.dialogRef.close({ result: DialogResultTypes.OK } as IDialogResult<void>);
  }
}
