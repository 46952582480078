import { ColumnPipeTypes, IHowdenColumnConfig } from '@howdeniberia/core-front';
import { IPolicyImport } from 'src/app/core/models/api/policies';

export class PolicyImportViewModel {
  data: IPolicyImport[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyId',
      fieldHeader: $localize`:@@app.policies.edit.import.policyId.column:Id.`,
      canSort: true
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.edit.import.policyNumber.column:Nº. Póliza`,
      canSort: true
    },
    {
      fieldName: 'holder',
      fieldHeader: $localize`:@@app.policies.edit.import.holder.column:Tomador`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.policies.edit.import.effectDate.column:Fecha efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.policies.edit.import.expirationDate.column:Fecha venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.edit.import.insuranceCompany.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.policies.edit.import.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.policies.edit.import.product.column:Producto`,
      canSort: true
    },
    {
      fieldName: 'risk',
      fieldHeader: $localize`:@@app.policies.edit.import.risk.column:Riesgo`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.TRUNCATE
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.policies.edit.import.status.column:Estado`,
      canSort: true
    }
  ];
}
