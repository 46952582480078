import { IFunctionalType } from 'src/app/core/models/api/units';
import { UpsertFunctionalTypeViewModel } from '../models';

export abstract class UpsertFunctionalTypeMapper {
  static mapForUpdate(source: UpsertFunctionalTypeViewModel): IFunctionalType {
    return {
      description: source.description
    } as IFunctionalType;
  }
}
