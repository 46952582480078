import { HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { APP_CONSTANTS } from 'src/app/core/constants/app-constants';

export const ApiConfigurations: IHowdenApiInfo[] = [{
  name: APP_CONSTANTS.API_NET_FLOTAS,
  paths: {
    local: 'https://localhost:44365/api/',
    develop: 'https://how-apinetflotas-dev.azurewebsites.net/api/',
    staging: 'https://how-apinetflotas-pre.azurewebsites.net/api/',
    production: 'https://how-apinetflotas-pro.azurewebsites.net/api/',
    devHowdenGroup: 'https://how-ib-apinetfleets-dev.azurewebsites.net/api/',
    preHowdenGroup: 'https://how-ib-apinetfleets-pre.azurewebsites.net/api/',
    proHowdenGroup: 'https://how-ib-apinetfleets-pro.azurewebsites.net/api/',
    devHowdenArtai: 'https://how-ar-apinetflotas-dev.azurewebsites.net/api/',
    preHowdenArtai: 'https://how-ar-apinetflotas-pre.azurewebsites.net/api/',
    proHowdenArtai: 'https://how-ar-apinetflotas-pro.azurewebsites.net/api/'
  },
  useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
  needsToken: true
}] as IHowdenApiInfo[];
