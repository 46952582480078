import { IPolicyNumber } from 'src/app/core/models/api/policies';
import { PolicyEditNumberUpdateViewModel } from '../models';

export abstract class PolicyEditNumberUpdateMapper {
  static mapForUpdate(source: PolicyEditNumberUpdateViewModel): IPolicyNumber {
    return {
      insuranceCompanyId: source.insuranceCompanyId,
      policyNumber: source.policyNumber
    } as IPolicyNumber;
  }
}
