import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HowdenTableComponent, IButtonActionData } from '@howdeniberia/core-front';
import { IFleetNotificationsService } from 'src/app/core/services/api/notifications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicySummaryDialogComponent } from 'src/app/features/policies/policy-summary-dialog/pages/policy-summary-dialog.component';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { EmptyRootPolicyNotificationsViewModel } from '../models';

@Component({
  selector: 'howden-empty-root-policy-notifications',
  templateUrl: './empty-root-policy-notifications.component.html',
  styleUrls: ['./empty-root-policy-notifications.component.scss']
})
export class EmptyRootPolicyNotificationsComponent implements OnInit, OnDestroy {
  model = new EmptyRootPolicyNotificationsViewModel();

  @ViewChild('notificationsTable') tableRef!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private fleetNotifications: IFleetNotificationsService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();
    this.fleetNotifications.getEmptyRootPolicyNotifications().subscribe({
      next: (data) => this.model.data = data,
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.tableRef?.focus();
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    const inputData = { id: event.row.policyId };
    const dialogRef = this.dialog.open(PolicySummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  onExport(): void {
    this.fleetNotifications.exportEmptyRootPolicyNotifications().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_NOTIFICACIONES_POLIZAS_MADRE_VACIAS', blob);
    });
  }
}
