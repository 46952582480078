import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IParalizationDaysFileItem } from 'src/app/core/models/api/fleets';

export class ParalizationLoaderViewModel {
  private _form: FormGroup;

  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });
  }

  paralizationResults: IParalizationDaysFileItem[] = [];
  paralizationResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'plateNumber',
      fieldHeader: $localize`:@@app.paralizations.loader.results.plateNumber.column:Matrícula`,
      canSort: true,
      width: '110px'
    },
    {
      fieldName: 'startDate',
      fieldHeader: $localize`:@@app.paralizations.loader.results.startDate.column:Desde`,
      canSort: true,
      width: '75px',
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'endDate',
      fieldHeader: $localize`:@@app.paralizations.loader.results.endDate.column:Hasta`,
      canSort: true,
      width: '75px',
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'insuranceCompanyAlias',
      fieldHeader: $localize`:@@app.paralizations.loader.results.insuranceCompanyAlias.column:Aseguradora`,
      canSort: true,
      width: '110px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.paralizations.loader.results.policyNumber.column:Póliza`,
      canSort: true,
      width: '125px'
    },
    {
      fieldName: 'applicationNumber',
      fieldHeader: $localize`:@@app.paralizations.loader.results.applicationNumber.column:Aplicación`,
      canSort: true,
      width: '85px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.paralizations.loader.results.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get form(): FormGroup {
    return this._form;
  }

  get file(): any {
    return this._form.get(this.C_FILE)?.value;
  }

  set file(value: any) {
    this._form.get(this.C_FILE)?.setValue(value);
  }
}
