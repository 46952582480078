<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <mat-stepper #stepper linear>
      <mat-step>
        <ng-template matStepLabel i18n="@@app.fleets.create.step1.label">Datos flota</ng-template>

        <div class="flex f-fd--row f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.fleets.create.name.label">Nombre</mat-label>
            <input matInput [formControlName]="model.C_NAME" [howdenControlError]="nameError" maxlength="100" />
            <mat-error #nameError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-fd--row f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.fleets.create.description.label">Descripción</mat-label>
            <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
            <mat-error #descriptionError></mat-error>
          </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div class="flex">
          <h4 i18n="@@app.fleets.create.fileUpload.header">Fichero de carga de pólizas</h4>
        </div>

        <div class="flex margin-top-10">
          <mat-card appearance="outlined" i18n="@@app.fleets.create.fileUpload.warning">
            <mat-card-content>
              El fichero se validará de forma automática durante la creación de la flota, la flota se creará igualmente en caso de no superar la validación
              y la carga del fichero podrá realizarse desde la opción de carga de pólizas.
            </mat-card-content>
          </mat-card>
        </div>

        <div class="flex f-fd--row f-gap--10px">
          <div class="f-basis--12">
            <howden-file-upload #file class="margin-10" (filesSelected)="onFileSelected()"></howden-file-upload>
          </div>
        </div>

        <div class="flex f-fd--row f-gap--10px margin-top-20">
          <div class="f-basis--1"></div>

          <div class="f-basis--10">
            <div class="flex f-jc--center">
              <button type="button" mat-raised-button (click)="onCancel()">
                <span i18n="@@app.core.actions.cancel">Cancelar</span>
              </button>
            </div>
          </div>

          <div class="f-basis--1">
            <button
              type="button"
              mat-mini-fab matStepperNext
              matTooltip="Siguiente"
              i18n-matTooltip="@@app.core.stepper.actions.next"
            >
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel i18n="@@app.fleets.create.step2.label">Clientes</ng-template>

        <div class="flex f-fd--row f-gap--10px">
          <h4 i18n="@@app.fleets.create.sections.clients">Seleccione los clientes de la flota</h4>
        </div>

        <div class="flex f-fd--row f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.fleets.create.clientGroupId.label">Grupo de clientes</mat-label>
            <input
              matInput
              [formControlName]="model.C_CLIENT_GROUP_ID"
              [matAutocomplete]="clientGroupIdSelect"
              [howdenControlError]="clientGroupIdError"
              #clientGroupInput
            />
            <mat-autocomplete
              #clientGroupIdSelect="matAutocomplete"
              [displayWith]="getClientGroupName.bind(this)"
              >
              @for (item of model.filteredClientGroups | async; track item.groupId) {
                <mat-option [value]="item.groupId">
                  {{ item.name }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #clientGroupIdError></mat-error>
          </mat-form-field>
        </div>

        <howden-table
          #clientsTable
          keyField="id"
          paginable="local"
          sortable="local"
          filterable="global-local"
          [pageSize]="10"
          [multiselect]="true"
          [cols]="model.clientColumns"
          [data]="model.clients"
          (selectedRowsChange)="onClientSelectionChanged($event)"
        >
        </howden-table>

        <div class="flex f-fd--row f-gap--10px margin-top-20">
          <div class="f-basis--1">
            <button
              type="button"
              mat-mini-fab matStepperPrevious
              matTooltip="Anterior"
              i18n-matTooltip="@@app.core.stepper.actions.previous"
            >
              <mat-icon>navigate_before</mat-icon>
            </button>
          </div>

          <div class="f-basis--10">
            <div class="flex f-jc--center">
              <button type="button" mat-raised-button (click)="onCancel()">
                <span i18n="@@app.core.actions.cancel">Cancelar</span>
              </button>
            </div>
          </div>

          <div class="f-basis--1">
            <button
              type="button"
              mat-mini-fab matStepperNext
              matTooltip="Siguiente"
              i18n-matTooltip="@@app.core.stepper.actions.next"
              >
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel i18n="@@app.fleets.create.step3.label">Referenciales</ng-template>

        <div class="flex f-fd--row f-gap--10px margin-bottom-20" style="height: 500px; max-height: 500px; overflow-y: scroll;">
          <div class="f-basis--3">
            <h4 i18n="@@app.fleets.create.settings.insuranceCompanies.label">Aseguradora</h4>
            <mat-selection-list>
              @for (setting of model.insuranceCompanySettings; track setting.id) {
                <mat-list-option [(selected)]="setting.enabled">
                  {{ setting.description }}
                </mat-list-option>
              }
            </mat-selection-list>
          </div>

          <div class="f-basis--3">
            <h4 i18n="@@app.fleets.create.settings.industries.label">Ramo</h4>
            <mat-selection-list>
              @for (setting of model.industrySettings; track setting.id) {
                <mat-list-option [(selected)]="setting.enabled">
                  {{ setting.description }}
                </mat-list-option>
              }
            </mat-selection-list>
          </div>

          <div class="f-basis--3">
            <h4 i18n="@@app.fleets.create.settings.vehicleTypes.label">Tipo de vehículo</h4>
            <mat-selection-list>
              @for (setting of model.vehicleTypeSettings; track setting.id) {
                <mat-list-option [(selected)]="setting.enabled">
                  {{ setting.description }}
                </mat-list-option>
              }
            </mat-selection-list>
          </div>

          <div class="f-basis--3">
            <h4 i18n="@@app.fleets.create.settings.vehicleUsages.label">Uso vehículo</h4>
            <mat-selection-list>
              @for (setting of model.vehicleUsageSettings; track setting.id) {
                <mat-list-option [(selected)]="setting.enabled">
                  {{ setting.description }}
                </mat-list-option>
              }
            </mat-selection-list>
          </div>
        </div>

        <div class="flex f-fd--row f-gap--10px">
          <div class="f-basis--1">
            <button
              type="button"
              mat-mini-fab matStepperPrevious
              matTooltip="Anterior"
              i18n-matTooltip="@@app.core.stepper.actions.previous"
            >
              <mat-icon>navigate_before</mat-icon>
            </button>
          </div>

          <div class="f-basis--10">
            <div class="flex f-jc--center f-gap--10px">
              <button type="button" mat-raised-button (click)="onCancel()">
                <span i18n="@@app.core.actions.cancel">Cancelar</span>
              </button>

              <button
                type="button"
                mat-raised-button color="success"
                [disabled]="model.form.invalid || !model.clients || model.clients.length === 0"
                (click)="onSave()"
              >
                <span i18n="@@app.core.actions.accept">Aceptar</span>
              </button>
            </div>
          </div>

          <div class="f-basis--1"></div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
