import { IPolicyBillingGroupBonus } from 'src/app/core/models/api/policies';
import { PolicyEditBonusViewModel } from '../models';

export abstract class PolicyEditBonusMapper {
  static mapForEdit(source: IPolicyBillingGroupBonus, target: PolicyEditBonusViewModel) {
    target.bonusId = source.bonusId;
    target.effectDate = source.effectDate;
    target.expirationDate = source.expirationDate;
    target.netBonusRC = source.netBonusRC;
    target.totalRC = source.totalRC;
    target.commissionRate = source.commissionRate;
    target.extraCommissionRate = source.extraCommissionRate;
  }

  static mapForUpdate(source: PolicyEditBonusViewModel): IPolicyBillingGroupBonus {
    return {
      bonusId: source.bonusId,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      netBonusRC: source.netBonusRC,
      totalRC: source.totalRC,
      commissionRate: source.commissionRate,
      extraCommissionRate: source.extraCommissionRate
    } as IPolicyBillingGroupBonus;
  }
}
