<div class="flex margin-top-10 margin-bottom-10">
  <form [formGroup]="model.form" class="f-basis--12">

    @if (model.isColective) {
      <div class="flex f-gap--10px margin-bottom-10">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.assign.risks.effectDate.label">Fecha efecto</mat-label>
          <input
            matInput
            [matDatepicker]="effectDatePicker"
            [formControlName]="model.C_EFFECT_DATE"
            [howdenControlError]="effectDateError"
            [min]="minDate"
          />
          <mat-error #effectDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #effectDatePicker></mat-datepicker>
        </mat-form-field>

        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.policies.edit.assign.risks.insuredId.label"
          label="Asegurado"
          displayField="clientName"
          keyField="companyId"
          [selectableItems]="model.fleetCompanies"
          [formControlName]="model.C_INSURED_ID"
          >
        </howden-autocomplete>

        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.policies.edit.assign.risks.bookingModeId.label"
          label="Modo de contratación"
          displayField="description"
          keyField="bookingModeId"
          [selectableItems]="model.bookingModes"
          [formControlName]="model.C_BOOKING_MODE_ID"
          >
        </howden-autocomplete>
      </div>
    }

    @if (model.isRoot) {
      <div>
        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.assign.risks.firstEffectDate.label">Fecha primer efecto</mat-label>
            <input
              matInput
              [matDatepicker]="firstEffectDatePicker"
              [formControlName]="model.C_FIRST_EFFECT_DATE"
              [howdenControlError]="firstEffectDateError"
              [min]="minDate"
            />
            <mat-error #firstEffectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="firstEffectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #firstEffectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.assign.risks.effectDate.label">Fecha efecto</mat-label>
            <input
              matInput
              [matDatepicker]="effectDatePicker"
              [formControlName]="model.C_EFFECT_DATE"
              [howdenControlError]="effectDateError"
              [min]="minDate"
            />
            <mat-error #effectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.assign.risks.expirationDate.label">Fecha vencimiento</mat-label>
            <input
              matInput
              [matDatepicker]="expirationDatePicker"
              [formControlName]="model.C_EXPIRATION_DATE"
              [howdenControlError]="expirationDateError"
              [min]="minDate"
            />
            <mat-error #expirationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.policies.edit.assign.risks.holderId.label"
            label="Tomador"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_HOLDER_ID"
          >
          </howden-autocomplete>

          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.policies.edit.assign.risks.insuredId.label"
            label="Asegurado"
            displayField="clientName"
            keyField="companyId"
            [selectableItems]="model.fleetCompanies"
            [formControlName]="model.C_INSURED_ID"
          >
          </howden-autocomplete>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.edit.assign.risks.mediationCodeId.label">Código mediación</mat-label>
            <mat-select [formControlName]="model.C_MEDIATION_CODE_ID" [howdenControlError]="mediationCodeIdError">
              @for (item of model.mediationCodes | howdenArraySort: 'code'; track item.mediationCodeId) {
                <mat-option [value]="item.mediationCodeId">
                  {{ item.code }} - {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #mediationCodeIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--4"
            i18n-label="@@app.policies.edit.assign.risks.bookingModeId.label"
            label="Modo de contratación"
            displayField="description"
            keyField="bookingModeId"
            [selectableItems]="model.bookingModes"
            [formControlName]="model.C_BOOKING_MODE_ID"
          >
          </howden-autocomplete>
        </div>
      </div>
    }

    <div class="flex f-jc--center f-gap--10px margin-top-10">
      <button type="button" mat-raised-button color="success" [disabled]="!canAssign" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </div>
  </form>
</div>

<mat-divider></mat-divider>

<div class="flex f-gap--10px margin-top-10">
  <form [formGroup]="searchModel.filterForm" class="f-basis--12">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.edit.assign.risks.plateNumber.label">Matrícula</mat-label>
        <input #plateNumberInput  matInput [formControlName]="searchModel.C_PLATE_NUMBER" maxlength="15" />
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.edit.assign.risks.carNumber.label">Nº. Coche</mat-label>
        <input matInput [formControlName]="searchModel.C_CAR_NUMBER" maxlength="20" />
      </mat-form-field>
    </div>
  </form>
</div>

<div class="flex f-gap--10px margin-top-10">
  <howden-table
    #unitSearchTable
    class="small-font"
    keyField="unitId"
    paginable="server"
    sortable="server"
    filterable="server"
    [cols]="searchModel.columns"
    [data]="searchModel.data"
    [currentPage]="searchModel.request.pageNumber"
    [pageSize]="searchModel.request.pageSize"
    [totalRows]="searchModel.length"
    [buttons]="searchModel.buttons"
    (pageChanged)="onServerSideConfigChanged($event)"
    (sortChanged)="onServerSideConfigChanged($event)"
    (buttonClicked)="onAction($event)"
    [multiselect]="true"
  >
  </howden-table>
</div>
