import { IPolicyRenew } from 'src/app/core/models/api/fleets';
import { PolicyRenewerViewModel } from '../models';

export abstract class PolicyRenewerMapper {
  static mapForUpdate(source: PolicyRenewerViewModel): IPolicyRenew {
    return {
      rootPolicyId: source.rootPolicyId,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      paymentTypeId: source.paymentTypeId,
      duration: typeof source.duration !== 'undefined' && source.duration !== null ? parseInt(source.duration) : null,
      commissionRate: source.commissionRate,
      collectionManagerId: source.collectionManagerId
    } as IPolicyRenew;
  }
}
