import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IMasterValue } from 'src/app/core/models/ida/masters';

export class PolicyEditCancelReplacementViewModel {
  private _form: FormGroup;
  private _cancellationReasons: Array<IMasterValue> = [];

  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_CANCELLATION_REASON_ID = 'cancellationReasonId';

  constructor() {
    this._form = new FormGroup({
      [this.C_OPERATION_DATE]: new FormControl(new Date(), [Validators.required]),
      [this.C_CANCELLATION_REASON_ID]: new FormControl(null, [Validators.required])
    });
  }

  get cancellationReasons(): Array<IMasterValue> {
    return this._cancellationReasons;
  }

  set cancellationReasons(value: Array<IMasterValue>) {
    this._cancellationReasons = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get operationDate(): Date {
    return this._form.get(this.C_OPERATION_DATE)?.value;
  }

  set operationDate(value: Date) {
    this._form.get(this.C_OPERATION_DATE)?.setValue(value);
  }

  get cancellationReasonId(): string {
    return this._form.get(this.C_CANCELLATION_REASON_ID)?.value;
  }

  set cancellationReasonId(value: string) {
    this._form.get(this.C_CANCELLATION_REASON_ID)?.setValue(value);
  }
}
