import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IFleetCompany } from 'src/app/core/models/api/fleets';

export class PolicyReportViewModel {
  private _form: FormGroup;
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _rootPolicies: Array<IItemSelection> = [];
  private _policyClasses: Array<IItemSelection> = [];
  private _offices: Array<IItemSelection> = [];
  private _bookingModes: Array<IItemSelection> = [];
  private _statuses: Array<IItemSelection> = [];
  private _vehicleTypes: Array<IItemSelection> = [];
  private _vehicleUsages: Array<IItemSelection> = [];

  readonly C_QUERY = 'query';
  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_INSURED_ID = 'insuredId';
  readonly C_OWNER = 'owner';
  readonly C_ACTIVE_RISKS = 'activeRisks';

  constructor() {
    this._form = new FormGroup({
      [this.C_QUERY]: new FormControl(null, []),
      [this.C_START_DATE]: new FormControl(null, []),
      [this.C_END_DATE]: new FormControl(null, []),
      [this.C_HOLDER_ID]: new FormControl(null, []),
      [this.C_INSURED_ID]: new FormControl(null, []),
      [this.C_OWNER]: new FormControl(null, []),
      [this.C_ACTIVE_RISKS]: new FormControl(false, [])
    });
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(value: Array<IFleetCompany>) {
    this._fleetCompanies = value;
  }

  get rootPolicies(): Array<IItemSelection> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IItemSelection>) {
    this._rootPolicies = value;
  }

  get policyClasses(): Array<IItemSelection> {
    return this._policyClasses;
  }

  set policyClasses(value: Array<IItemSelection>) {
    this._policyClasses = value;
  }

  get statuses(): Array<IItemSelection> {
    return this._statuses;
  }

  set statuses(statuses: Array<IItemSelection>) {
    this._statuses = statuses;
  }

  get offices(): Array<IItemSelection> {
    return this._offices;
  }

  set offices(value: Array<IItemSelection>) {
    this._offices = value;
  }

  get bookingModes(): Array<IItemSelection> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IItemSelection>) {
    this._bookingModes = value;
  }

  get vehicleTypes(): Array<IItemSelection> {
    return this._vehicleTypes;
  }

  set vehicleTypes(value: Array<IItemSelection>) {
    this._vehicleTypes = value;
  }

  get vehicleUsages(): Array<IItemSelection> {
    return this._vehicleUsages;
  }

  set vehicleUsages(value: Array<IItemSelection>) {
    this._vehicleUsages = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get query(): string | null {
    return this.getControl(this.C_QUERY).value;
  }

  set query(value: string | null) {
    this.getControl(this.C_QUERY).setValue(value);
  }

  get startDate(): Date | null {
    return this.getControl(this.C_START_DATE).value;
  }

  set startDate(value: Date | null) {
    this.getControl(this.C_START_DATE).setValue(value);
  }

  get endDate(): Date | null {
    return this.getControl(this.C_END_DATE).value;
  }

  set endDate(value: Date | null) {
    this.getControl(this.C_END_DATE).setValue(value);
  }

  get holderId(): string | null {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string | null) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get insuredId(): string | null {
    return this.getControl(this.C_INSURED_ID).value;
  }

  set insuredId(value: string | null) {
    this.getControl(this.C_INSURED_ID).setValue(value);
  }

  get owner(): string | null {
    return this.getControl(this.C_OWNER).value;
  }

  set owner(value: string | null) {
    this.getControl(this.C_OWNER).setValue(value);
  }

  get activeRisks(): boolean {
    return this.getControl(this.C_ACTIVE_RISKS).value;
  }

  set activeRisks(value: boolean) {
    this.getControl(this.C_ACTIVE_RISKS).setValue(value);
  }
}
