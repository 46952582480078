<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <mat-stepper #stepper linear animationDuration="0">
    <mat-step [stepControl]="model.templateSelectionForm">
      <ng-template matStepLabel i18n="@@app.certificates.sections.certificate.selection">Certificados</ng-template>

      <form [formGroup]="model.templateSelectionForm">
        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.certificates.sections.templateId.label">Plantilla</mat-label>
            <mat-select [formControlName]="model.C_TEMPLATE_ID">
              <mat-option></mat-option>
              @for (item of model.templates | howdenArraySort: 'description'; track item.certificateId) {
                <mat-option [value]="item.certificateId">
                  {{ item.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <div class="flex f-fd--row f-gap--10px margin-top-20">
        <div class="f-basis--1"></div>

        <div class="f-basis--10">
          <div class="flex f-jc--center">
            <button type="button" mat-raised-button (click)="onCancel()">
              <span i18n="@@app.core.actions.cancel">Cancelar</span>
            </button>
          </div>
        </div>

        <div class="f-basis--1">
          <button
            type="button"
            mat-mini-fab matStepperNext
            matTooltip="Siguiente"
            i18n-matTooltip="@@app.core.stepper.actions.next"
            [disabled]="model.templateSelectionForm.invalid"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="model.policySearchForm">
      <ng-template matStepLabel i18n="@@app.certificates.sections.policy.selection">Pólizas</ng-template>

      <form [formGroup]="model.policySearchForm">
        <div class="flex f-gap--10px margin-bottom-10">
          <mat-form-field>
            <mat-label i18n="@@app.policies.search.policyNumber.label">Nº. Póliza</mat-label>
            <input #policyNumberInput matInput [formControlName]="model.C_POLICY_NUMBER" />
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n="@@app.policies.search.riskDescription.label">Denominación</mat-label>
            <input matInput [formControlName]="model.C_RISK_DESCRIPTION" />
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n="@@app.policies.search.holder.label">Tomador</mat-label>
            <input matInput [formControlName]="model.C_HOLDER" />
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n="@@app.policies.search.statusId.label">Estado</mat-label>
            <mat-select [formControlName]="model.C_STATUS_ID">
              <mat-option></mat-option>
              @for (item of model.policyStatuses | howdenArraySort: 'description'; track item.erpId) {
                <mat-option [value]="item.erpId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <howden-table
        #policySelectionTable
        class="small-font"
        keyField="policyId"
        paginable="local"
        sortable="local"
        filterable="server"
        [cols]="model.policyColumns"
        [data]="model.policies"
        [pageSize]="25"
        [buttons]="model.buttons"
        (selectedRowsChange)="onPolicySelectionChanged($event)"
        [multiselect]="true"
        tableFixedHeight="400px"
      >
      </howden-table>

      <div class="flex f-fd--row f-gap--10px margin-top-20">
        <div class="f-basis--1">
          <button
            type="button"
            mat-mini-fab matStepperPrevious
            matTooltip="Anterior"
            i18n-matTooltip="@@app.core.stepper.actions.previous"
          >
            <mat-icon>navigate_before</mat-icon>
          </button>
        </div>

        <div class="f-basis--10">
          <div class="flex f-jc--center">
            <button type="button" mat-raised-button (click)="onCancel()">
              <span i18n="@@app.core.actions.cancel">Cancelar</span>
            </button>
          </div>
        </div>

        <div class="f-basis--1">
          <button
            type="button"
            mat-mini-fab matStepperNext
            matTooltip="Siguiente"
            i18n-matTooltip="@@app.core.stepper.actions.next"
            [disabled]="model.hasNoPoliciesSelected"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel i18n="@@app.certificates.sections.certificate.formatting">Cabecera, cuerpo y pie</ng-template>

      <form [formGroup]="model.formattingForm">

        <div class="flex">
          <h4 i18n="@@app.certificates.sections.header">Cabecera</h4>
        </div>

        <div>
          <quill-editor
            #headerEditor
            [modules]="modules"
            [styles]="{height: '200px'}"
            (onEditorChanged)="onHeaderChanged($event)"
          >
          </quill-editor>
        </div>

        <div class="flex">
          <h4 i18n="@@app.certificates.sections.body">Cuerpo</h4>
        </div>

        <div>
          <quill-editor
            #bodyEditor
            [modules]="modules"
            [styles]="{height: '200px'}"
            (onEditorChanged)="onBodyChanged($event)"
          >
          </quill-editor>
        </div>

        <div class="flex">
          <h4 i18n="@@app.certificates.sections.footer">Pie</h4>
        </div>

        <div>
          <quill-editor
            #footerEditor
            [modules]="modules"
            [styles]="{height: '200px'}"
            (onEditorChanged)="onFooterChanged($event)"
          >
          </quill-editor>
        </div>

        <div class="flex">
          <h4 i18n="@@app.certificates.sections.misc">Otros datos</h4>
        </div>

        <div class="flex">
          <mat-form-field>
            <mat-label i18n="@@app.certificates.expeditionCity.label">Ciudad expedición</mat-label>
            <input
              matInput
              [formControlName]="model.C_EXPEDITION_CITY"
              [howdenControlError]="expeditionCityError"
              maxlength="50"
            />
            <mat-error #expeditionCityError></mat-error>
          </mat-form-field>
        </div>

      </form>

      <div class="flex f-fd--row f-gap--10px">
        <div class="f-basis--1">
          <button
            type="button"
            mat-mini-fab matStepperPrevious
            matTooltip="Anterior"
            i18n-matTooltip="@@app.core.stepper.actions.previous"
            >
            <mat-icon>navigate_before</mat-icon>
          </button>
        </div>

        <div class="f-basis--10">
          <div class="flex f-jc--center f-gap--10px">
            <button type="button" mat-raised-button (click)="onCancel()">
              <span i18n="@@app.core.actions.cancel">Cancelar</span>
            </button>

            <button type="button" mat-raised-button color="success" [disabled]="model.templateSelectionForm.invalid" (click)="onAccept()">
              <span i18n="@@app.core.actions.accept">Aceptar</span>
            </button>
          </div>
        </div>

        <div class="f-basis--1"></div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
