import { IRisksInsuredReportRequest } from 'src/app/core/models/api/reports';
import { RisksInsuredReportViewModel } from '../models';

export abstract class RisksInsuredReportMapper {
  static mapForGeneration(activeFleet: string, source: RisksInsuredReportViewModel): IRisksInsuredReportRequest {
    return {
      fleetId: activeFleet,
      date: source.date,
      rootPolicies: source.rootPolicies.filter(x => x.selected === true).map(x => x.id)
    } as IRisksInsuredReportRequest;
  }
}
