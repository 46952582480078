@if (group) {
  <div class="group-container">
    <howden-policy-edit-billing-group-segment [group]="group" [segment]="group.defaultSegment">
    </howden-policy-edit-billing-group-segment>
    @if (group.surchargesSegment) {
      <howden-policy-edit-billing-group-segment [group]="group" [segment]="group.surchargesSegment">
      </howden-policy-edit-billing-group-segment>
    }
  </div>
}
