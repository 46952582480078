import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IReportsService } from 'src/app/core/services/api/reports';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { BonusHistoryReportMapper } from '../mappers';
import { BonusHistoryReportViewModel } from '../models';

@Component({
  selector: 'howden-bonus-history-report',
  templateUrl: './bonus-history-report.component.html',
  styleUrls: ['./bonus-history-report.component.scss']
})
export class BonusHistoryReportComponent implements OnInit {
  model = new BonusHistoryReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.getInsuranceCompanies(fleetId)
    ]).subscribe(([fleetCompanies, insuranceCompanies]) => {
      this.model.fleetCompanies = fleetCompanies;
      this.model.insuranceCompanies = insuranceCompanies;
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = BonusHistoryReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.bonusHistory(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_HISTORICO_PRIMAS', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
