import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';
import { IBookingMode } from 'src/app/core/models/api/booking-modes';
import { IsPlateNumberInFleetValidator } from 'src/app/shared/validators';

export class PolicyEditChangeRiskViewModel {
  private _form: FormGroup;
  private _isBookingModeRequired = false;
  private _bookingModes: Array<IBookingMode> = [];

  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_RISK_ALIAS = 'riskAlias';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';

  constructor(private plateNumberValidator: IsPlateNumberInFleetValidator) {
    this._form = new FormGroup({
      [this.C_OPERATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_RISK_ALIAS]: new FormControl('', {
        validators: [StringValidators.noSpacesEndStart, Validators.required, Validators.pattern(/^[0-9A-Za-z]+$/)],
        asyncValidators: [this.plateNumberValidator.validate.bind(this.plateNumberValidator)],
        updateOn: 'blur'
      }),
      [this.C_BOOKING_MODE_ID]: new FormControl(null, [])
    });
  }

  get bookingModes(): Array<IBookingMode> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingMode>) {
    this._bookingModes = value;
  }

  get isBookingModeRequired(): boolean {
    return this._isBookingModeRequired;
  }

  set isBookingModeRequired(value: boolean) {
    this._isBookingModeRequired = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get operationDate(): Date {
    return this.getControl(this.C_OPERATION_DATE).value;
  }

  set operationDate(value: Date) {
    this.getControl(this.C_OPERATION_DATE).setValue(value);
  }

  get riskAlias(): string | undefined {
    return this.getControl(this.C_RISK_ALIAS).value;
  }

  set riskAlias(value: string | undefined) {
    this.getControl(this.C_RISK_ALIAS).setValue(value);
  }

  get bookingModeId(): string | undefined {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | undefined) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }
}
