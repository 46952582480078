import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IBreakPointObserverSrv } from './breakpoint-observer.contract';

@Injectable({
  providedIn: 'root'
})
export class BreakPointObserverSrv implements IBreakPointObserverSrv {
  constructor(private breakpointObserver: BreakpointObserver) {
  }

  observe(): Observable<any> {
    return this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(
      map(({ matches }) => {
        if (matches) {
          return {
            columns: 1,
            miniCard: { cols: 1, rows: 1 },
            chart: { cols: 1, rows: 2 },
            table: { cols: 1, rows: 4 }
          };
        }

        return {
          columns: 4,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 2, rows: 2 },
          table: { cols: 4, rows: 4 }
        };
      })
    );
  }
}
