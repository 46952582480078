import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PolicyClass } from 'src/app/core/enums';
import { IMasterValue } from 'src/app/core/models/ida/masters';

export class PolicyEditCancellationViewModel {
  private _form: FormGroup;
  private _cancellationReasons: Array<IMasterValue> = [];
  private _policyClass?: PolicyClass | null;

  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_CANCELLATION_NOTIFICATION_DATE = 'cancellationNotificationDate';
  readonly C_CANCELLATION_REASON_ID = 'cancellationReasonId';

  constructor() {
    this._form = new FormGroup({
      [this.C_OPERATION_DATE]: new FormControl(new Date(), [Validators.required]),
      [this.C_CANCELLATION_NOTIFICATION_DATE]: new FormControl(null, []),
      [this.C_CANCELLATION_REASON_ID]: new FormControl('', [Validators.required])
    });
  }

  get isIndividual(): boolean {
    return this.policyClass !== null
      && typeof this.policyClass !== 'undefined'
      && this.policyClass === PolicyClass.Individual;
  }

  get isColective(): boolean {
    return this.policyClass !== null
      && typeof this.policyClass !== 'undefined'
      && this.policyClass === PolicyClass.Colective;
  }

  get policyClass(): PolicyClass | null | undefined {
    return this._policyClass;
  }

  set policyClass(value: PolicyClass | null | undefined) {
    this._policyClass = value;
  }

  get cancellationReasons(): Array<IMasterValue> {
    return this._cancellationReasons;
  }

  set cancellationReasons(value: Array<IMasterValue>) {
    this._cancellationReasons = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get operationDate(): Date {
    return this._form.get(this.C_OPERATION_DATE)?.value;
  }

  set operationDate(value: Date) {
    this._form.get(this.C_OPERATION_DATE)?.setValue(value);
  }

  get cancellationNotificationDate(): Date {
    return this._form.get(this.C_CANCELLATION_NOTIFICATION_DATE)?.value;
  }

  set cancellationNotificationDate(value: Date) {
    this._form.get(this.C_CANCELLATION_NOTIFICATION_DATE)?.setValue(value);
  }

  get cancellationReasonId(): string {
    return this._form.get(this.C_CANCELLATION_REASON_ID)?.value;
  }

  set cancellationReasonId(value: string) {
    this._form.get(this.C_CANCELLATION_REASON_ID)?.setValue(value);
  }
}
