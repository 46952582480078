import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyLoaderError } from 'src/app/core/models/api/policies';

export class PolicyLoaderViewModel {
  private _form: FormGroup;

  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });
  }

  loaderResults: IPolicyLoaderError[] = [];
  loaderResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'rootPolicyIndex',
      fieldHeader: $localize`:@@app.policies.loader.insuranceCompany.column:#P.Madre`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'plateNumber',
      fieldHeader: $localize`:@@app.policies.loader.policyNumber.column:Matrícula`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.loader.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get form(): FormGroup { return this._form; }
}
