import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { filter, map, pairwise } from 'rxjs';
import { IPolicyRegularization, IPolicyRegularizationHistory } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyEditRegularizationViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-regularization',
  templateUrl: './policy-edit-regularization.component.html',
  styleUrls: ['./policy-edit-regularization.component.scss']
})
export class PolicyEditRegularizationComponent implements OnInit, OnDestroy {
  model = new PolicyEditRegularizationViewModel();

  get minDate(): Date { return Limits.minDate; }

  selectedFilter = '';

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onFilter(): void {
    this.uiBlockerSrv.block();
    this.policySrv.getRegularizationInfo(this.policyId, moment(this.model.date).format('YYYYMMDD')).subscribe({
      next: (data: IPolicyRegularization) => this.updateData(data),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  getDiffClass(history: IPolicyRegularizationHistory): string | null {
    const amountDifference = (history.real?.totalAmount ?? 0) - (history.billed?.totalAmount ?? 0);

    if (amountDifference === 0) {
      return null;
    }

    return 'color-text-accent';
  }

  get hasData(): boolean {
    return this.model.history.length > 0 || this.model.groups.length > 0;
  }

  get filteredHistory(): Array<IPolicyRegularizationHistory> {
    if (!this.model.history) {
      return [];
    }
    if (typeof this.selectedFilter === 'undefined' || this.selectedFilter === null || this.selectedFilter === '') {
      return this.model.history;
    }
    if (this.selectedFilter === '1') {
      return this.model.history.filter(x => x.differenceClass);
    }
    return this.model.history.filter(x => x.differenceClass.toString() === this.selectedFilter);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.form.valueChanges.pipe(
      pairwise(),
      map(([oldState, newState]) => {
        const changes: any = {};
        for (const key in newState) {
          if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
            changes[key] = newState[key];
          }
        }
        return changes;
      }),
      filter((changes: any) => Object.keys(changes).length !== 0)
    ).subscribe(() => this.clearData());
  }

  private updateData(data: IPolicyRegularization | null | undefined) {
    this.model.history = data?.history ?? [];
    this.model.groups = data?.groups ?? [];
  }

  private clearData() {
    this.model.history = [];
    this.model.groups = [];
  }
}
