import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IProduct } from 'src/app/core/models/ida/products';
import { IProductService } from './product.contract';

@Injectable({
  providedIn: 'root'
})
export class ProductService implements IProductService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getProducts(insuranceCompanyId: string, industryId: string): Observable<Array<IProduct>> {
    const uri = `/insurance-companies/${insuranceCompanyId}/industries/${industryId}/products`;
    return this.httpClient.get<Array<IProduct>>(APP_CONSTANTS.API_NET_FLOTAS, uri);
  }
}
