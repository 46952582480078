<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.number.update.title">Asignar número de póliza</div>

    <mat-dialog-content>
      <mat-form-field class="margin-bottom-10">
        <mat-label
        i18n="@@app.policies.edit.number.update.policyNumber.label">Por favor, indique el número de póliza</mat-label>
        <input
          matInput
          [formControlName]="model.C_POLICY_NUMBER"
          [howdenControlError]="policyNumberError"
          maxlength="20"
          i18n-placeholder="@@app.policies.edit.number.update.policyNumber.placeholder"
          placeholder="Número de póliza"
        />
        <mat-error #policyNumberError></mat-error>
        @if (model.policyNumberControl.hasError('notAvailable')) {
          <mat-error
          i18n="@@app.policies.edit.number.update.not.available.error">El número de póliza indicado no está disponible.</mat-error>
        }
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
