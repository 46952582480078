import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { BonusHistoryReportComponent } from './bonus-history-report/pages/bonus-history-report.component';
import { ClaimsByCompanyReportComponent } from './claims-by-company-report/pages/claims-by-company-report.component';
import { FleetControlReportComponent } from './fleet-control-report/pages/fleet-control-report.component';
import { FleetReportComponent } from './fleet-report/pages/fleet-report.component';
import { FleetStateReportComponent } from './fleet-state-report/pages/fleet-state-report.component';
import { OperatingCompaniesReportComponent } from './operating-companies-report/pages/operating-companies-report.component';
import { PolicyHistoryReportComponent } from './policy-history-report/pages/policy-history-report.component';
import { PolicyReportComponent } from './policy-report/pages/policy-report.component';
import { RegularizationReportComponent } from './regularization-report/pages/regularization-report.component';
import { RisksComparedReportComponent } from './risks-compared-report/pages/risks-compared-report.component';
import { RisksInsuredReportComponent } from './risks-insured-report/pages/risks-insured-report.component';
import { RootPolicyReportComponent } from './root-policy-report/pages/root-policy-report.component';
import { UnitReportComponent } from './unit-report/pages/unit-report.component';

export const ReportsRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'reports',
    title: $localize`:@@app.route.reports:Informes`,
    data: {
      icon: 'article',
      linkText: $localize`:@@app.route.reports:Informes`
    },
    children: [
      {
        path: 'fleets',
        component: FleetReportComponent,
        title: $localize`:@@app.route.reports.fleets:Listado de flotas`,
        data: {
          linkText: $localize`:@@app.route.reports.fleets:Listado de flotas`
        }
      },
      {
        path: 'fleets-control',
        component: FleetControlReportComponent,
        title: $localize`:@@app.route.reports.fleets.control:Listado de control de flotas`,
        data: {
          linkText: $localize`:@@app.route.reports.fleets.control:Listado de control de flotas`
        }
      },
      {
        path: 'root-policies',
        component: RootPolicyReportComponent,
        title: $localize`:@@app.route.reports.root.policies:Listado de pólizas madre/colectivas`,
        data: {
          linkText: $localize`:@@app.route.reports.root.policies:Listado de pólizas madre/colectivas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'units',
        component: UnitReportComponent,
        title: $localize`:@@app.route.reports.root.units:Listado de unidades`,
        data: {
          linkText: $localize`:@@app.route.reports.root.units:Listado de unidades`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policies',
        component: PolicyReportComponent,
        title: $localize`:@@app.route.reports.policies:Listado de pólizas`,
        data: {
          linkText: $localize`:@@app.route.reports.policies:Listado de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-history',
        component: PolicyHistoryReportComponent,
        title: $localize`:@@app.route.reports.policy.history:Histórico de modificaciones de pólizas`,
        data: {
          linkText: $localize`:@@app.route.reports.policy.history:Histórico de modificaciones de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'bonus-history',
        component: BonusHistoryReportComponent,
        title: $localize`:@@app.route.reports.bonus.history:Histórico de primas`,
        data: {
          linkText: $localize`:@@app.route.reports.bonus.history:Histórico de primas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'fleet-state',
        component: FleetStateReportComponent,
        title: $localize`:@@app.route.reports.fleet.state:Estado flota`,
        data: {
          linkText: $localize`:@@app.route.reports.fleet.state:Estado flota`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'regularization',
        component: RegularizationReportComponent,
        title: $localize`:@@app.route.reports.regularization:Regularización`,
        data: {
          linkText: $localize`:@@app.route.reports.regularization:Regularización`
        }
      },
      // {
      //   canActivate: [ActiveFleetGuard],
      //   path: 'lta',
      //   component: LTAReportComponent,
      //   title: $localize`:@@app.route.reports.lta:LTA`,
      //   data: {
      //     linkText: $localize`:@@app.route.reports.lta:LTA`
      //   }
      // },
      {
        canActivate: [ActiveFleetGuard],
        path: 'operating-companies',
        component: OperatingCompaniesReportComponent,
        title: $localize`:@@app.route.reports.operating.companies:Empresas explotadoras - primas vs costes`,
        data: {
          linkText: $localize`:@@app.route.reports.operating.companies:Empresas explotadoras - primas vs costes`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'claims-by-company',
        component: ClaimsByCompanyReportComponent,
        title: $localize`:@@app.route.reports.claims.by.company:Siniestralidad por empresa`,
        data: {
          linkText: $localize`:@@app.route.reports.claims.by.company:Siniestralidad por empresa`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'risks-insured',
        component: RisksInsuredReportComponent,
        title: $localize`:@@app.route.reports.risks.insured:Riesgos asegurados por póliza`,
        data: {
          linkText: $localize`:@@app.route.reports.risks.insured:Riesgos asegurados por póliza`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'risks-compared',
        component: RisksComparedReportComponent,
        title: $localize`:@@app.route.reports.risks.compared:Riesgos comparados`,
        data: {
          linkText: $localize`:@@app.route.reports.risks.compared:Riesgos comparados`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ReportsRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
