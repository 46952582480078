import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, forkJoin, map, pairwise } from 'rxjs';
import { IDashboardService } from 'src/app/core/services/api/dashboards';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IBreakPointObserverSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { InitialStateDashboardMapper } from '../mappers';
import { InitialStateDashboardViewModel } from '../models';

@Component({
  selector: 'howden-initial-state-dashboard',
  templateUrl: './initial-state-dashboard.component.html',
  styleUrls: ['./initial-state-dashboard.component.scss']
})
export class InitialStateDashboardComponent implements OnInit, OnDestroy {
  model = new InitialStateDashboardViewModel();

  cardLayout = this.breakpointObserver.observe();

  private _subscriptions = new SubSink();

  constructor(
    private breakpointObserver: IBreakPointObserverSrv,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private dashboardSrv: IDashboardService
  ) {
  }

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onFilter(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = InitialStateDashboardMapper.map(this.model);

    this.uiBlockerSrv.block();

    this.onChanges();

    this.clearData();

    forkJoin([
      this.fleetSrv.getDetails(fleetId),
      this.dashboardSrv.getInitialStateDashboard(fleetId, request)
    ]).subscribe(([
      fleet,
      dashboardData
    ]) => {
      this.model.fleet = fleet;
      this.model.dashboardData = dashboardData;

      this.uiBlockerSrv.unblock();
    });
  }

  onTableExport(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = InitialStateDashboardMapper.map(this.model);

    this.uiBlockerSrv.block();

    this.dashboardSrv.exportInitialStateDashboard(fleetId, request).subscribe({
      next: blob => FileUtils.downloadFile('HOWDEN_NET_FLOTAS_DASHBOARD_FOTO_INICIAL', blob),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  get canExport(): boolean {
    const details = this.model.dashboardData?.details;
    return typeof details !== 'undefined' && details !== null && details.length > 0;
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.form.valueChanges.pipe(
      pairwise(),
      map(([oldState, newState]) => {
        const changes: any = {};
        for (const key in newState) {
          if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
            changes[key] = newState[key];
          }
        }
        return changes;
      }),
      filter((changes: any) => Object.keys(changes).length !== 0)
    ).subscribe(() => this.clearData());
  }

  private clearData(): void {
    this.model.fleet = null;
    this.model.dashboardData = null;
  }
}
