<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.functional.types.search.description.label">Descripción</mat-label>
        <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>

      <button
        type="button"
        class="margin-right-10 margin-top 10"
        mat-mini-fab
        color="primary"
        i18n-matTooltip="@@app.functional.types.search.actions.create"
        matTooltip="Nuevo modo de contratación"
        (click)="onNewFunctionalType()"
      >
        <mat-icon>add</mat-icon>
      </button>

    </div>
  </form>

  <div>
    <howden-table
      class="small-font"
      keyField="functionalTypeId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (filtersChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
