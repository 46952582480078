<div class="howden-dialog-content">
  <div mat-dialog-title class="title-text">{{model.contractNumber}}</div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--6"
        labelText="Cliente"
        i18n-labelText="@@app.policy.edit.contract.client.label"
        value="{{model.client}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--6"
        labelText="Ejecutivo"
        i18n-labelText="@@app.policy.edit.contract.executive.label"
        value="{{model.executive}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--6"
        labelText="Nivel de cobro"
        i18n-labelText="@@app.policy.edit.contract.collectionLevel.label"
        value="{{model.collectionLevel}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--6"
        labelText="Gestor de cobro"
        i18n-labelText="@@app.policy.edit.contract.collectionManager.label"
        value="{{model.collectionManager}}"
      ></howden-label-value>
    </div>

    <div class="flex f-fd--column margin-bottom-10">
      <h4 i18n="@@app.policy.edit.contract.policies.label">Pólizas</h4>
      <howden-table
        class="small-font"
        keyField="policyId"
        paginable="none"
        sortable="local"
        [cols]="model.columns"
        [data]="model.policies"
        [buttons]="model.buttons"
        (buttonClicked)="onAction($event)"
      >
      </howden-table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button color="success" (click)="onClose()">
      <span i18n="@@app.core.actions.close">Cerrar</span>
    </button>
  </mat-dialog-actions>
</div>
