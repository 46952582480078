<div class="flex f-fd--column">

  <div>
    <howden-page-title></howden-page-title>
  </div>

  <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10">

    <button type="button" mat-raised-button color="success" (click)="onGenerateReport()">
      <span i18n="@@app.core.actions.generateReport">Generar informe</span>
    </button>

  </div>

</div>
