@if (model.hasNotAssignedCompanies) {
  <div class="flex f-gap--10px f-ai--center margin-top-10">
    <div class="f-basis--6">
      <form [formGroup]="model.form">
        <mat-form-field>
          <mat-label i18n="@@app.fleet.edit.clients.clientId.label">Cliente</mat-label>
          <mat-select [formControlName]="model.C_CLIENT_ID" [howdenControlError]="clientIdError">
            @for (item of model.notAssignedCompanies; track item.clientId) {
              <mat-option [value]="item.clientId">
                {{ item.surname1 }} {{ item.surname2 }} {{ item.name }}
              </mat-option>
            }
          </mat-select>
          <mat-error #clientIdError></mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
}

@if (model.hasNotAssignedCompanies) {
  <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10 margin-bottom-10">
    <button type="button" mat-raised-button (click)="onClose()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
      <span i18n="@@app.core.actions.save">Guardar</span>
    </button>
  </div>
}

<mat-divider></mat-divider>

<div class="margin-top-10">
  <howden-table
    class="small-font"
    keyField="companyId"
    filterable="global-local"
    paginable="local"
    sortable="local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.currentCompanies"
    >
  </howden-table>
</div>
