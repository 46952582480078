import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { BonusBoardComponent } from './bonus-board/pages/bonus-board.component';
import { ClaimsDashboardComponent } from './claims-dashboard/pages/claims-dashboard.component';
import { FleetDashboardComponent } from './fleet-dashboard/pages/fleet-dashboard.component';
import { GeneralDashboardComponent } from './general-dashboard/pages/general-dashboard.component';
import { InitialStateDashboardComponent } from './initial-state-dashboard/pages/initial-state-dashboard.component';
import { ReceiptsDashboardComponent } from './receipts-dashboard/pages/receipts-dashboard.component';

export const DashboardsRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'dashboards',
    title: $localize`:@@app.route.dashboards:Dashboards`,
    data: {
      icon: 'dashboard',
      linkText: $localize`:@@app.route.dashboards:Dashboards`
    },
    children: [
      {
        path: 'general',
        component: GeneralDashboardComponent,
        title: $localize`:@@app.route.dashboards.general:General`,
        data: { linkText: $localize`:@@app.route.dashboards.general:General` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'fleet',
        component: FleetDashboardComponent,
        title: $localize`:@@app.route.dashboards.fleet:Flota de trabajo`,
        data: { linkText: $localize`:@@app.route.dashboards.fleet:Flota de trabajo` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'initial-state',
        component: InitialStateDashboardComponent,
        title: $localize`:@@app.route.dashboards.initial.state:Foto inicial`,
        data: { linkText: $localize`:@@app.route.dashboards.initial.state:Foto inicial` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'bonus-board',
        component: BonusBoardComponent,
        title: $localize`:@@app.route.dashboards.bonus.board:Cuadro de primas`,
        data: { linkText: $localize`:@@app.route.dashboards.bonus.board:Cuadro de primas` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'claims',
        component: ClaimsDashboardComponent,
        title: $localize`:@@app.route.dashboards.claims:Siniestralidad`,
        data: { linkText: $localize`:@@app.route.dashboards.claims:Siniestralidad` }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'receipts',
        component: ReceiptsDashboardComponent,
        title: $localize`:@@app.route.dashboards.receipts:Recibos`,
        data: { linkText: $localize`:@@app.route.dashboards.receipts:Recibos` }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(DashboardsRoutes)],
  exports: [RouterModule]
})
export class DashboardsRoutingModule {
}
