import { ICancelReplacement } from 'src/app/core/models/ida/production';
import { PolicyEditCancelReplacementViewModel } from '../models';

export abstract class PolicyEditCancelReplacementMapper {
  static mapForUpdate(source: PolicyEditCancelReplacementViewModel): ICancelReplacement {
    return {
      operationDate: source.operationDate,
      cancellationReasonId: source.cancellationReasonId
    } as ICancelReplacement;
  }
}
