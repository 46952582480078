import { IDetailedReceipt } from 'src/app/core/models/ida/financial';
import { IDetailedReceiptView } from '../models';

export abstract class DetailedReceiptMapper {
  static map(source: Array<IDetailedReceipt>): Array<IDetailedReceiptView> {
    return source.map(value => ({
        receiptId: value.receiptId,
        client: value.client,
        receiptNumber: value.receiptNumber,
        insuranceCompanyReceiptNumber: value.insuranceCompanyReceiptNumber,
        effectDate: value.effectDate,
        expirationDate: value.expirationDate,
        collectionDate: value.collectionDate,
        currency: value.currency,
        netBonus: value.netBonus,
        receiptTotal: value.receiptTotal,
        brokerCommisionEuros: value.brokerCommisionEuros,
        situation: value.situation,
        receiptType: value.receiptType
      } as IDetailedReceiptView));
  }
}
