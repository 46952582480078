import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IFunctionalTypeService } from 'src/app/core/services/api/functional-type';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UpsertFunctionalTypeMapper } from '../mappers';
import { UpsertFunctionalTypeViewModel } from '../models';

@Component({
  selector: 'howden-upsert-functional-type-dialog',
  templateUrl: './upsert-functional-type-dialog.component.html',
  styleUrls: ['./upsert-functional-type-dialog.component.scss']
})
export class UpsertFunctionalTypeDialogComponent implements OnInit, OnDestroy {
  model = new UpsertFunctionalTypeViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<UpsertFunctionalTypeDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private functionalTypeSrv: IFunctionalTypeService
  ) {
  }

  ngOnInit(): void {
    if (this.inputData?.functionalTypeId) {
      this.uiBlockerSrv.block();
      this.functionalTypeSrv.get(this.inputData.functionalTypeId).subscribe((data) => {
        this.model.description = data?.description;
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const request = UpsertFunctionalTypeMapper.mapForUpdate(this.model);

    if (this.inputData?.functionalTypeId) {
      request.functionalTypeId = this.inputData.functionalTypeId;
    }

    this.uiBlockerSrv.block();

    this.functionalTypeSrv.upsert(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
