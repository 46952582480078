import { IChangePolicyHolder } from 'src/app/core/models/api/policies';
import { PolicyEditHolderUpdateViewModel } from '../models';

export abstract class PolicyEditHolderUpdateMapper {
  static mapForUpdate(source: PolicyEditHolderUpdateViewModel): IChangePolicyHolder {
    return {
      operationDate: source.operationDate,
      holderId: source.holderId,
      bookingModeId: source.bookingModeId,
      keepCurrentInsured: source.keepCurrentInsured,
      updateRiskOwner: source.updateRiskOwner,
      netBonus: source.netBonus,
      totalBonus: source.totalBonus,
      commissionRate: source.commissionRate
    } as IChangePolicyHolder;
  }
}
