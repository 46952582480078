<div class="howden-dialog-content">
  <span mat-dialog-title class="title-text">{{claim.claimType}}</span>
  <span class="subtitle-text">{{claim.claimDate | date}} ({{claim.substate}})</span>

  <mat-dialog-content class="flex f-fd--column f-gap--10px f-ac--start margin-top-5">

    <div class="f-basis--12">
      <div class="flex f-fd--row f-gap--5px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Aseguradora"
          i18n-labelText="@@app.claim.summary.insuranceCompany.label"
          value="{{claim.insuranceCompany}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Nº. Póliza"
          i18n-labelText="@@app.claim.summary.policyNumber.label"
          value="{{claim.policyNumber}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Cliente"
          i18n-labelText="@@app.claim.summary.clientName.label"
          value="{{claim.client}}"
        ></howden-label-value>
      </div>
    </div>

    <div class="f-basis--12">
      <div class="flex f-fd--row f-gap--5px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Alias"
          i18n-labelText="@@app.claim.summary.alias.label"
          value="{{claim.alias}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Referencia cliente"
          i18n-labelText="@@app.claim.summary.clientReference.label"
          value="{{claim.clientReference}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Referencia aseguradora"
          i18n-labelText="@@app.claim.summary.insuranceCompanyReference.label"
          value="{{claim.insuranceCompanyReference}}"
        ></howden-label-value>
      </div>
    </div>

    <div class="f-basis--12">
      <div class="flex f-fd--row f-gap--5px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Causa"
          i18n-labelText="@@app.claim.summary.cause.label"
          value="{{claim.accidentCause}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Daños"
          i18n-labelText="@@app.claim.summary.damages.label"
          value="{{claim.damages}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Fecha resolución"
          i18n-labelText="@@app.claim.summary.resolutionDate.label"
          value="{{claim.resolutionDate | date}}"
        ></howden-label-value>
      </div>
    </div>

    <div class="f-basis--12">
      <div class="flex f-fd--row f-gap--5px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Valoración (~)"
          i18n-labelText="@@app.claim.summary.assessment.label"
          value="{{claim.valuation | number : '1.2-2'}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Pagos"
          i18n-labelText="@@app.claim.summary.payments.label"
          value="{{claim.payments | number : '1.2-2'}}"
        ></howden-label-value>

        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Coste"
          i18n-labelText="@@app.claim.summary.claimCost.label"
          value="{{claim.cost | number : '1.2-2'}}"
        ></howden-label-value>
      </div>
    </div>

    <howden-label-value
      class="f-basis--12 f-as-start"
      labelText="Descripción"
      i18n-labelText="@@app.claim.summary.description.label"
      value="{{claim.description}}"
    ></howden-label-value>

    <div class="flex margin-top-10">
      <h4 i18n="@@app.claim.summary.sections.audit">Auditoría</h4>
    </div>

    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--6 f-as-start"
        labelText="Creado por"
        i18n-labelText="@@app.claim.summary.createdBy.label"
        value="{{claim.createdBy}} ({{claim.createdAt}})"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--6 f-as-start"
        labelText="Modificado por"
        i18n-labelText="@@app.claim.summary.modifiedBy.label"
        value="{{claim.modifiedBy}} ({{claim.modifiedAt}})"
      ></howden-label-value>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="flex f-jc--center f-ai--center f-gap--10px">

    <button type="button" mat-raised-button color="success" (click)="onClose()">
      <span i18n="@@app.core.actions.close">Cerrar</span>
    </button>

  </mat-dialog-actions>

</div>
