import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const ClaimRoutes = [] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ClaimRoutes)],
  exports: [RouterModule]
})
export class ClaimsRoutingModule {
}
