import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';
import { PolicyNumberValidator } from 'src/app/shared/validators';

export class PolicyEditNumberUpdateViewModel {
  private _form: FormGroup;
  private _insuranceCompanyId = '';
  private _policyNumberControl: FormControl;

  readonly C_POLICY_NUMBER = 'policyNumber';

  constructor(private policyNumberValidator: PolicyNumberValidator) {
    this._policyNumberControl = new FormControl('', {
      validators: [StringValidators.noSpacesEndStart, Validators.required],
      asyncValidators: [this.policyNumberValidator.validate.bind(this.policyNumberValidator)],
      updateOn: 'blur'
    });
    this._form = new FormGroup({ [this.C_POLICY_NUMBER]: this._policyNumberControl });
  }

  get form(): FormGroup {
    return this._form;
  }

  get policyNumberControl(): FormControl {
    return this._policyNumberControl;
  }

  get insuranceCompanyId(): string {
    return this._insuranceCompanyId;
  }

  set insuranceCompanyId(value: string) {
    this._insuranceCompanyId = value;
  }

  get policyNumber(): string | undefined {
    return this._form.get(this.C_POLICY_NUMBER)?.value;
  }

  set policyNumber(value: string | undefined) {
    this._form.get(this.C_POLICY_NUMBER)?.setValue(value);
  }
}
