import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  HowdenDecimalModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { ClaimService, IClaimService } from 'src/app/core/services/api/claims';
import { ISessionService, SessionService } from 'src/app/core/services/storage/session';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { ClaimListComponent } from './claim-list/pages/claim-list.component';
import { ClaimSummaryDialogComponent } from './claim-summary-dialog/pages/claim-summary-dialog.component';
import { ClaimsRoutingModule } from './claims-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMomentDateModule,
    MatCardModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenLabelValueModule,
    HowdenTableModule,
    ClaimsRoutingModule
  ],
  declarations: [
    ClaimSummaryDialogComponent,
    ClaimListComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: ISessionService, useClass: SessionService },
    { provide: IClaimService, useClass: ClaimService }
  ],
  exports: [
    ClaimListComponent
  ]
})
export class ClaimsModule {
}
