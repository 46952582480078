import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';

export class PolicySummaryRisksViewModel {
  constructor(public data: Array<IPolicyRiskSummary>) {
  }

  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'riskAlias',
      fieldHeader: $localize`:@@app.policies.summary.risks.riskAlias.column:Matrícula`
    },
    {
      fieldName: 'insured',
      fieldHeader: $localize`:@@app.policies.summary.risks.insured.column:Asegurado`
    },
    {
      fieldName: 'activationDate',
      fieldHeader: $localize`:@@app.policies.summary.risks.activationDate.column:Fecha alta`,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'deactivationDate',
      fieldHeader: $localize`:@@app.policies.summary.risks.deactivationDate.column:Fecha baja`,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'deactivationNotificationDate',
      fieldHeader: $localize`:@@app.policies.summary.risks.deactivationNotificationDate.column:Fecha com. baja`,
      pipeToApply: ColumnPipeTypes.DATE
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.summary.risks.actions.goto.unit:Ir a unidad`
  }];

  get isNotEmpty(): boolean {
    return this.data && this.data.length > 0;
  }
}
