import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { filter, forkJoin, map, pairwise } from 'rxjs';
import { ILTAService } from 'src/app/core/services/api/lta';
import { IReportsService } from 'src/app/core/services/api/reports';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { LTAReportMapper } from '../mappers';
import { LTAReportViewModel } from '../models';

@Component({
  selector: 'howden-lta-report',
  templateUrl: './lta-report.component.html',
  styleUrls: ['./lta-report.component.scss']
})
export class LTAReportComponent implements OnInit, OnDestroy {
  model = new LTAReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private ltaSrv: ILTAService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.ltaSrv.getBaseFrequencies(fleetId),
      this.ltaSrv.getBaseReportParams(fleetId)
    ]).subscribe(([
      baseFrequencies,
      baseReportParams
    ]) => {
      this.model.baseFrequencies = baseFrequencies;
      this.model.claimPaymentDates = baseReportParams.claimPaymentDates;
      this.model.baseFrequencyHistoryId = baseReportParams.baseFrequencyHistoryId;
      this.model.startDate = baseReportParams.startDate;
      this.model.endDate = baseReportParams.endDate;
      this.onChanges();
      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onGenerateReport() {
    const request = LTAReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.lta(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LTA', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_CLAIM_PAYMENT_DATE_ID).valueChanges.pipe(
      pairwise(),
      map(([oldState, newState]) => {
        const changes: any = {};
        for (const key in newState) {
          if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
            changes[key] = newState[key];
          }
        }
        return changes;
      }),
      filter((changes: any) => Object.keys(changes).length !== 0)
    ).subscribe(() => {
      const claimPayment = this.model.claimPaymentDates.find(x => x.paymentDateId === this.model.claimPaymentId);

      if (claimPayment) {
        const date = claimPayment.date;
        const from = moment(this.model.startDate ?? new Date());
        const to = moment(date).add(-1, 'months').endOf('month').add(1, 'days');

        this.model.endDate = to > from ? to.toDate() : from.toDate();
      }
    });
  }
}
