import { IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IContractPolicy } from 'src/app/core/models/api/policies';

export class PolicyEditContractViewModel {
  private _contractNumber?: string | null = '';
  private _client?: string | null = '';
  private _executive?: string | null = '';
  private _collectionLevel?: string | null = '';
  private _collectionManager?: string | null = '';

  get contractNumber(): string | null | undefined {
    return this._contractNumber;
  }

  set contractNumber(value: string | null | undefined) {
    this._contractNumber = value;
  }

  get client(): string | null | undefined {
    return this._client;
  }

  set client(value: string | null | undefined) {
    this._client = value;
  }

  get executive(): string | null | undefined {
    return this._executive;
  }

  set executive(value: string | null | undefined) {
    this._executive = value;
  }

  get collectionLevel(): string | null | undefined {
    return this._collectionLevel;
  }

  set collectionLevel(value: string | null | undefined) {
    this._collectionLevel = value;
  }

  get collectionManager(): string | null | undefined {
    return this._collectionManager;
  }

  set collectionManager(value: string | null | undefined) {
    this._collectionManager = value;
  }

  policies: Array<IContractPolicy> = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policy.edit.contract.policyNumber.column:Nº. Póliza`
    },
    {
      fieldName: 'riskDescription',
      fieldHeader: $localize`:@@app.policy.edit.contract.riskDescription.column:Denominación`
    },
    {
      fieldName: 'holder',
      fieldHeader: $localize`:@@app.policy.edit.contract.holder.column:Tomador`
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.policy.edit.contract.status.column:Estado`
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policy.edit.contract.actions.goto.policy:Ir a póliza`
  }];
}
