import { IUnitFleetTransferRequest } from 'src/app/core/models/api/units';
import { UnitFleetTransferViewModel } from '../models';

export abstract class UnitFleetTransferMapper {
  static mapForUpdate(source: UnitFleetTransferViewModel): IUnitFleetTransferRequest {
    return {
      fleetId: source.targetFleetId,
      rootPolicyId: source.targetRootPolicyId,
      holderId: source.holderId,
      insuredId: source.insuredId,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      bookingModeId: source.bookingModeId,
      coverageOrderEmailTo: source.coverageOrderEmailTo
    } as IUnitFleetTransferRequest;
  }
}
