import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HowdenUserService } from '@howdeniberia/core-front';
import { EChartsOption } from 'echarts';
import { IStatistic } from 'src/app/core/models/api/core';

@Component({
  selector: 'howden-dashboard-pie-chart',
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrls: ['./dashboard-pie-chart.component.scss']
})
export class DashboardPieChartComponent implements OnChanges {
  @Input() data: Array<IStatistic> = [];
  @Input() valueFormat = '1.0-2';

  options: EChartsOption = {};

  constructor(private _decimalPipe: DecimalPipe, private _userSrv: HowdenUserService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const textColor = this._userSrv.selectedTheme === 'dark' ? 'white' : 'black';

    if (changes['data'].currentValue && changes['data'].currentValue.length > 0) {
      this.options = {
        legend: {
          align: 'auto',
          bottom: 0,
          backgroundColor: 'white',
          data: this.data.map(x => x.description)
        },
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            const value = this._decimalPipe.transform(params.data.value, this.valueFormat);

            return `<strong>${params.name}</strong> - ${value} (${params.percent}%)`;
          }
        },
        series: [
          {
            name: 'area',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              color: textColor,
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: this.data.map(x => ({ value: x.value, name: x.description }))
          }
        ],
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (_) {
          return Math.random() * 200;
        }
      };
    }
  }
}
