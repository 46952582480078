import { IUnitSearchResult } from 'src/app/core/models/api/units';
import { UnitSearchResultViewModel } from '../models';

export abstract class UnitSearchMapper {
  static mapForSearch(source: IUnitSearchResult): UnitSearchResultViewModel {
    return {
      unitId: source.unitId,
      plateNumber: source.plateNumber,
      carNumber: source.carNumber,
      owner: source.owner,
      vehicleType: source.vehicleType,
      vehicleUsage: source.vehicleUsage,
      movementArea: source.movementArea,
      description: source.description
    } as UnitSearchResultViewModel;
  }
}
