import { IAssignRisks } from 'src/app/core/models/api/policies';
import { PolicyEditAssignRisksViewModel } from '../models';

export abstract class PolicyEditAssignRisksMapper {
  static mapForUpdate(source: PolicyEditAssignRisksViewModel): IAssignRisks {
    return {
      firstEffectDate: source.firstEffectDate,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      holderId: source.holderId,
      insuredId: source.insuredId,
      mediationCodeId: source.mediationCodeId,
      bookingModeId: source.bookingModeId
    } as IAssignRisks;
  }
}
