import { IStatistic } from 'src/app/core/models/api/core';
import { IFleetSearchResult } from 'src/app/core/models/api/fleets';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';

export class FleetDashboardViewModel {
  private _fleet: IFleetSearchResult | null = null;
  private _unitsByVehicleType: Array<IStatistic> = [];
  private _unitsByPolicy: Array<IStatistic> = [];
  private _averageBonusByVehicleType: Array<IStatistic> = [];
  private _policiesByPaymentType: Array<IStatistic> = [];

  miniCardData: Array<IMiniCardData> = [];

  get fleet(): IFleetSearchResult | null {
    return this._fleet;
  }
  set fleet(value: IFleetSearchResult | null) {
    this._fleet = value;
  }

  get unitsByVehicleType(): Array<IStatistic> {
    return this._unitsByVehicleType;
  }
  set unitsByVehicleType(value: Array<IStatistic>) {
    this._unitsByVehicleType = value;
  }

  get unitsByPolicy(): Array<IStatistic> {
    return this._unitsByPolicy;
  }
  set unitsByPolicy(value: Array<IStatistic>) {
    this._unitsByPolicy = value;
  }

  get averageBonusByVehicleType(): Array<IStatistic> {
    return this._averageBonusByVehicleType;
  }
  set averageBonusByVehicleType(value: Array<IStatistic>) {
    this._averageBonusByVehicleType = value;
  }

  get policiesByPaymentType(): Array<IStatistic> {
    return this._policiesByPaymentType;
  }
  set policiesByPaymentType(value: Array<IStatistic>) {
    this._policiesByPaymentType = value;
  }
}
