import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FileUploadComponent, IPageOf } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { ProductStatus } from 'src/app/core/enums';
import { IBookingModeSearchRequest, IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IProduct } from 'src/app/core/models/ida/products';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { ICurrencyService } from 'src/app/core/services/api/currencies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IProductService } from 'src/app/core/services/api/products';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FormGroupUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { BookingModeChangerViewModel } from '../models';

@Component({
  selector: 'howden-booking-mode-changer',
  templateUrl: './booking-mode-changer.component.html',
  styleUrls: ['./booking-mode-changer.component.scss']
})
export class BookingModeChangerComponent implements OnInit, OnDestroy {
  model = new BookingModeChangerViewModel();

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('stepper') stepperRef!: MatStepper;
  @ViewChild('file') fileRef?: FileUploadComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private currencySrv: ICurrencyService,
    private masterSrv: IMasterService,
    private productSrv: IProductService,
    private bookingModeSrv: IBookingModeService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getInsuranceCompanies(fleetId),
      this.fleetSrv.getIndustries(fleetId),
      this.masterSrv.getEndorsementStatuses(),
      this.masterSrv.getEndorsementTypes(),
      this.masterSrv.getReceiptSituations(),
      this.masterSrv.getReceiptTypes(),
      this.currencySrv.getCurrencies()
    ]).subscribe(([
      insuranceCompanies,
      industries,
      endorsementStatuses,
      endorsementTypes,
      receiptSituations,
      receiptTypes,
      currencies
    ]) => {
      this.model.insuranceCompanies = insuranceCompanies;
      this.model.industries = industries;
      this.model.endorsementStatuses = endorsementStatuses;
      this.model.endorsementTypes = endorsementTypes;
      this.model.receiptSituations = receiptSituations;
      this.model.receiptTypes = receiptTypes;
      this.model.currencies = currencies;

      this.onChanges();
      this.onDisableReceipt(true);
      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.fleetSrv.bookingModeChanger(fleetId, request).pipe(
      tap((response) => {
        this.uiBlockerSrv.unblock();

        if (response && response.updates && response.updates.length > 0) {
          this.model.updaterResults = response.updates;
        } else {
          const title = $localize`:@@app.policies.booking.mode.changer.confirm.dialog.title:Cambio de modo de contratación`;
          const subtitle = $localize`:@@app.policies.booking.mode.changer.confirm.dialog.subtitle:Finalizado correctamente, recuerde que no todos los cambios son efectivos de inmediato.`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reset());
        }
      })
    ).subscribe();
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef?.file
    });
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries?.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getProductName(productId: string): string {
    const product = this.model.products?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  getBookingModeDescription(bookingModeId: string): string {
    return this.model.bookingModes?.find(x => x.bookingModeId === bookingModeId)?.description ?? '';
  }

  get hasUpdaterResults(): boolean {
    return this.model.updaterResults && this.model.updaterResults.length > 0;
  }

  onGoBack(): void {
    this.reset();
  }

  private reset(): void {
    this.model.form.reset();
    this.model.updaterResults = [];
    this.fileRef?.clearFiles();

    if (typeof this.fileRef !== 'undefined' && this.fileRef !== null) {
      this.stepperRef.selectedIndex = 0;
    }
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_ENDORSEMENT_CREATES_RECEIPT).valueChanges.subscribe((val: boolean) => {
      this.onDisableReceipt(!val);
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
      this.onLoadBookingModes();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_INDUSTRY_ID).valueChanges.subscribe(() => {
      this.onLoadProducts();
      this.onLoadBookingModes();
    });
    this._subscriptions.sink = this.model.getControl(this.model.C_PRODUCT_ID).valueChanges.subscribe(() => {
      this.onLoadBookingModes();
    });
  }

  private onLoadProducts(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;
    const industryId: string = this.model.industryId;

    this.model.productId = '';
    this.model.products = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        this.model.products = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private onLoadBookingModes(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;
    const industryId: string = this.model.industryId;
    const productId: string = this.model.productId;

    this.model.bookingModeId = '';
    this.model.bookingModes = new Array<IBookingModeSearchResult>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);
    const product = this.model.products.find(x => x.productId === productId);

    if (insuranceCompany && industry && product) {
      const fleetId = this.sessionSrv.activeFleet;
      const filter = {
        pageNumber: 0,
        pageSize: Limits.maxPageSize,
        fleetId: fleetId,
        insuranceCompanyId: insuranceCompanyId,
        industryId: industryId,
        productId: productId
      } as IBookingModeSearchRequest;

      this.bookingModeSrv.search(filter).subscribe((searchResult: IPageOf<IBookingModeSearchResult>) => {
        this.model.bookingModes = searchResult.data;
      });
    }
  }

  private onDisableReceipt(val: boolean) {
    if (val === true) {
      this.model.disableReceiptStep();
    } else {
      this.model.enableReceiptStep();
    }
  }
}
