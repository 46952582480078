<mat-form-field>
  <mat-label i18n="@@app.header.global.search.label">Búsqueda global (mín. 3 caract.)</mat-label>
  <input
    matInput
    type="text"
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    maxlength="20"
  >
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelected($event)"
      [displayWith]="displayWith"
      [panelWidth]="600"
    >
      @if (isLoading) {
        <mat-option style="font-size: 90%;" i18n="@@app.header.global.search.input.searching.label">
          Buscando...
        </mat-option>
      } @else {
        @for (result of filteredResults; track result) {
          <mat-option [value]="result" style="font-size: 90%;">
            <div class="flex f-gap--5px full-width">
              <span class="global-search-result-cell"><strong>{{result.value}}</strong></span>
              <span class="global-search-result-cell ellipsis-text">{{result.extra}}</span>
              <span class="global-search-result-cell ellipsis-text">{{result.fleetName}}</span>
              <span class="global-search-result-cell-state"><strong>{{result.itemState}}</strong></span>
            </div>
          </mat-option>
        }
      }
  </mat-autocomplete>
</mat-form-field>
