import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, tap } from 'rxjs';
import { CollectionManagerStatus, PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyRenewerMapper } from '../mappers';
import { PolicyRenewerViewModel } from '../models';

@Component({
  selector: 'howden-policy-renewer',
  templateUrl: './policy-renewer.component.html',
  styleUrls: ['./policy-renewer.component.scss']
})
export class PolicyRenewerComponent implements OnInit, OnDestroy {
  model = new PolicyRenewerViewModel();

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('rootPolicyInput') rootPolicyInputRef!: ElementRef;

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private masterSrv: IMasterService,
    private fleetSrv: IFleetService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    const policyFilter = {
      pageNumber: 0,
      fleetId: fleetId,
      policyClasses: [PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize,
      statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString()]
    } as IPolicySearchRequest;

    forkJoin([
      this.policySrv.search(policyFilter),
      this.masterSrv.getCollectionManagers(),
      this.masterSrv.getPaymentTypes(),
      this.masterSrv.getPolicyDurations()
    ]).subscribe(([rootPolicies, collectionManagers, paymentTypes, policyDurations]) => {
      this.model.rootPolicies = rootPolicies.data;
      this.model.collectionManagers = collectionManagers.filter(x => x.status === CollectionManagerStatus.Active);
      this.model.paymentTypes = paymentTypes;
      this.model.policyDurations = policyDurations;

      this.onChanges();

      this.uiBlockerSrv.unblock();

      this.rootPolicyInputRef.nativeElement.focus();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = PolicyRenewerMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.fleetSrv.policyRenewer(fleetId, request).pipe(
      tap({
        next: () => {
          const title = $localize`:@@app.policies.renewer.save.confirm.title:Renovación de pólizas`;
          const subtitle = $localize`:@@app.policies.renewer.confirm.dialog.subtitle:Finalizada correctamente, recuerde que no todos los cambios son efectivos de inmediato.`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
            this.model.form.reset();
            this.rootPolicyInputRef.nativeElement.focus();
          });
        },
        complete: () => this.uiBlockerSrv.unblock()
      })
    ).subscribe();
  }

  onClose(): void {
    this.navigateToSearch();
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);
    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  getCollectionManagerName(collectionManagerId: string): string {
    const collectionManager = this.model.collectionManagers?.find(x => x.collectionManagerId === collectionManagerId);
    return collectionManager ? `${collectionManager.alias}-${collectionManager.name}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_ROOT_POLICY_ID).valueChanges.subscribe(() => {
      this.model.getControl(this.model.C_COLLECTION_MANAGER_ID).reset();
      this.model.getControl(this.model.C_EFFECT_DATE).reset();
      this.model.getControl(this.model.C_EXPIRATION_DATE).reset();
      this.model.getControl(this.model.C_PAYMENT_TYPE_ID).reset();
      this.model.getControl(this.model.C_DURATION).reset();
      this.model.getControl(this.model.C_COMMISSION_RATE).reset();
    });
  }

  private navigateToSearch(): void {
    this.router.navigate(['policies/search']);
  }
}
