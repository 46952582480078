import { Component, Input } from '@angular/core';
import { IPolicyBillingGroupResult } from 'src/app/core/models/api/policies';

@Component({
  selector: 'howden-policy-edit-billing-info',
  templateUrl: './policy-edit-billing-info.component.html',
  styleUrls: ['./policy-edit-billing-info.component.scss']
})
export class PolicyEditBillingInfoComponent {
  @Input() group: IPolicyBillingGroupResult | null = null;
}
