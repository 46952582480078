import { IHowdenColumnConfig } from '@howdeniberia/core-front';
import { IFigureDetails } from 'src/app/core/models/api/core';

export class PolicySummaryFiguresViewModel {
  constructor(public data: Array<IFigureDetails>) { }

  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'figureType',
      fieldHeader: $localize`:@@app.policies.summary.figures.type.column:Figura`
    },
    {
      fieldName: 'userName',
      fieldHeader: $localize`:@@app.policies.summary.figures.userName.column:Usuario`
    }
  ];

  get isNotEmpty(): boolean {
    return this.data && this.data.length > 0;
  }
}
