import { IUnitReportRequest } from 'src/app/core/models/api/reports';
import { UnitReportViewModel } from '../models';

export abstract class UnitReportMapper {
  static mapForGeneration(activeFleet: string, source: UnitReportViewModel): IUnitReportRequest {
    return {
      fleetId: activeFleet,
      query: source.query,
      vehicleTypes: source.vehicleTypes.filter(x => x.selected === true).map(x => x.id),
      vehicleUsages: source.vehicleUsages.filter(x => x.selected === true).map(x => x.id),
      movementAreas: source.movementAreas.filter(x => x.selected === true).map(x => x.id),
      statuses: source.statuses.filter(x => x.selected === true).map(x => x.id)
    } as IUnitReportRequest;
  }
}
