import { FormControl, FormGroup } from '@angular/forms';
import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { ProcessStatus } from 'src/app/core/enums';
import { IProcessSearchRequest } from 'src/app/core/models/api/processes';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IProcessSearchResultViewModel } from './process-search-result.viewmodel';

export class ProcessSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IProcessSearchRequest;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;
  private _processStatuses: Array<IMasterValue> = [];

  readonly C_CREATION_DATE = 'creationDate';
  readonly C_DESCRIPTION = 'description';
  readonly C_STATUS_ID = 'statusId';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_CREATION_DATE]: new FormControl(null, []),
      [this.C_DESCRIPTION]: new FormControl(null, []),
      [this.C_STATUS_ID]: new FormControl('1', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this.sortedBy,
      sortDirection: this.sortDirection
    } as IProcessSearchRequest;

    this.updateServerSideFilters();
  }

  data: IProcessSearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'processId',
      fieldHeader: $localize`:@@app.processes.search.processId.column:ID`,
      canSort: true,
      width: '100px'
    },
    {
      fieldName: 'action',
      fieldHeader: $localize`:@@app.processes.search.action.column:Acción`,
      canSort: false,
      width: '200px'
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.processes.search.description.column:Descripción`,
      canSort: true,
      width: '250px'
    },
    {
      fieldName: 'creationDate',
      fieldHeader: $localize`:@@app.processes.search.creationDate.column:F.Alta`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      width: '100px'
    },
    {
      fieldName: 'retryCount',
      fieldHeader: $localize`:@@app.processes.search.retryCount.column:Reintentos`,
      aligment: ColumnAligment.CENTER,
      canSort: false,
      width: '100px'
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.processes.search.status.column:Estado`,
      canSort: false,
      width: '100px'
    },
    {
      fieldName: 'message',
      fieldHeader: $localize`:@@app.processes.search.message.column:Error`,
      canSort: false
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.processes.search.actions.delete.tooltip:Borrar`,
    isHidden: (row: any) => row.statusId !== ProcessStatus.Faulted
  }, {
    name: 'relaunch',
    icon: 'switch_access_shortcut_add',
    tooltip: $localize`:@@app.processes.search.actions.relaunc.tooltip:Relanzar`,
    isHidden: (row: any) => row.statusId !== ProcessStatus.Faulted
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get processStatuses(): Array<IMasterValue> {
    return this._processStatuses;
  }

  set processStatuses(processStatuses: Array<IMasterValue>) {
    this._processStatuses = processStatuses;
  }

  get searchRequest(): IProcessSearchRequest {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get creationDate(): Date | null | undefined {
    return this._filterForm.get(this.C_CREATION_DATE)?.value;
  }

  set creationDate(value: Date | null | undefined) {
    this._filterForm.get(this.C_CREATION_DATE)?.setValue(value);
  }

  get description(): string | null | undefined {
    return this._filterForm.get(this.C_DESCRIPTION)?.value;
  }

  set description(value: string | null | undefined) {
    this._filterForm.get(this.C_DESCRIPTION)?.setValue(value);
  }

  get statusId(): string | null | undefined {
    return this._filterForm.get(this.C_STATUS_ID)?.value;
  }

  set statusId(value: string | null | undefined) {
    this._filterForm.get(this.C_STATUS_ID)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.description = this.description;
    this.searchRequest.creationDate = this.creationDate;
    this.searchRequest.statusId = this.statusId;
  }
}
