import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyRenumberingFileItem } from 'src/app/core/models/api/fleets';

export class PolicyRenumberingViewModel {
  private _form: FormGroup;

  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });
  }

  renumberingResults: IPolicyRenumberingFileItem[] = [];
  renumberingResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.renumbering.insuranceCompany.column:Compañía`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'currentPolicyNumber',
      fieldHeader: $localize`:@@app.policies.renumbering.currentPolicyNumber.column:Nº de póliza (actual)`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'newPolicyNumber',
      fieldHeader: $localize`:@@app.policies.renumbering.newPolicyNumber.column:Nº de póliza (nuevo)`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.renumbering.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get form(): FormGroup { return this._form; }
}
