import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function dateRangeValidator(startFieldName: string, endFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate = control.get(startFieldName)?.value as Date;
    const endDate = control.get(endFieldName)?.value as Date;

    return (endDate < startDate) ? { dateRange: true } : null;
  };
}
