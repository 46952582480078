import { IInsurerMergerRequest } from 'src/app/core/models/api/fleets';
import { InsurerMergerViewModel } from '../models';

export abstract class InsurerMergerMapper {
  static mapForUpdate(source: InsurerMergerViewModel): IInsurerMergerRequest {
    return {
      sourceInsuranceCompanyId: source.sourceInsuranceCompanyId,
      targetInsuranceCompanyId: source.targetInsuranceCompanyId,
      operationDate: source.operationDate
    } as IInsurerMergerRequest;
  }
}
