import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IReceiptDashboardResponse } from 'src/app/core/models/api/dashboards';
import { IFleetSearchResult } from 'src/app/core/models/api/fleets';
import { ILocalReceipt } from 'src/app/core/models/api/receipts';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';

export class ReceiptsDashboardViewModel {
  private _form: FormGroup;
  private _fleet: IFleetSearchResult | null = null;
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _response: IReceiptDashboardResponse | null = null;
  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;

  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';
  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_POLICY_NUMBER = 'policyNumber';

  constructor() {
    this._form = new FormGroup({
      [this.C_START_DATE]: new FormControl(null, []),
      [this.C_END_DATE]: new FormControl(null, []),
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, []),
      [this.C_POLICY_NUMBER]: new FormControl(null, [])
    });

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );
  }

  miniCardData: Array<IMiniCardData> = [];

  data: ILocalReceipt[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.dashboard.receipts.policyNumber.column:Nº.Póliza`,
      canSort: true
    },
    {
      fieldName: 'receiptNumber',
      fieldHeader: $localize`:@@app.dashboard.receipts.receiptNumber.column:Nº.Recibo`,
      canSort: true
    },
    {
      fieldName: 'insuranceCompanyReceiptNumber',
      fieldHeader: $localize`:@@app.dashboard.receipts.insuranceCompanyReceiptNumber.column:Nº.Recibo aseguradora`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.dashboard.receipts.effectDate.column:F.Efecto`,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.dashboard.receipts.expirationDate.column:F.Venc.`,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'collectionDate',
      fieldHeader: $localize`:@@app.dashboard.receipts.collectionDate.column:F.Cobro`,
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'payer',
      fieldHeader: $localize`:@@app.dashboard.receipts.payer.column:Pagador`,
      canSort: true
    },
    {
      fieldName: 'currency',
      fieldHeader: $localize`:@@app.dashboard.receipts.currency.column:Divisa`,
      aligment: ColumnAligment.CENTER,
      canSort: true
    },
    {
      fieldName: 'netBonus',
      fieldHeader: $localize`:@@app.dashboard.receipts.netBonus.column:P. Neta anual`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    },
    {
      fieldName: 'totalAmountEuros',
      fieldHeader: $localize`:@@app.dashboard.receipts.totalAmountEuros.column:Total Recibo`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    },
    {
      fieldName: 'brokerCommissionEuros',
      fieldHeader: $localize`:@@app.dashboard.receipts.brokerCommissionEuros.column:Comisión correduría`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    },
    {
      fieldName: 'situationDescription',
      fieldHeader: $localize`:@@app.dashboard.receipts.situationDescription.column:Situación`,
      aligment: ColumnAligment.CENTER,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get response(): IReceiptDashboardResponse | null {
    return this._response;
  }
  set response(value: IReceiptDashboardResponse | null) {
    this._response = value;
  }

  get fleet(): IFleetSearchResult | null {
    return this._fleet;
  }
  set fleet(value: IFleetSearchResult | null) {
    this._fleet = value;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get startDate(): Date | null {
    return this.getControl(this.C_START_DATE).value;
  }

  set startDate(value: Date | null) {
    this.getControl(this.C_START_DATE).setValue(value);
  }

  get endDate(): Date | null {
    return this.getControl(this.C_END_DATE).value;
  }

  set endDate(value: Date | null) {
    this.getControl(this.C_END_DATE).setValue(value);
  }

  get insuranceCompanyId(): string | null {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string | null) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get policyNumber(): string | null {
    return this.getControl(this.C_POLICY_NUMBER).value;
  }

  set policyNumber(value: string | null) {
    this.getControl(this.C_POLICY_NUMBER).setValue(value);
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this.insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.insuranceCompanies;
  }
}
