import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IFleet } from 'src/app/core/models/api/fleets';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';
import {
  ICirculationArea,
  ICreateUnit,
  IFunctionalType,
  IGoodsType,
  IIsPlateNumberAvailable,
  IMovementArea,
  IUnitAllowedActions,
  IUnitCancelPolicyRisksRequest,
  IUnitFleetTransferRequest,
  IUnitSearchRequest,
  IUnitSearchResult,
  IUnitSummary
} from 'src/app/core/models/api/units';
import { IUnit } from 'src/app/core/models/api/units/unit';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';

export abstract class IUnitService {
  abstract getFunctionalTypes(): Observable<Array<IFunctionalType>>;
  abstract getCirculationAreas(): Observable<Array<ICirculationArea>>;
  abstract getMovementAreas(): Observable<Array<IMovementArea>>;
  abstract getGoodsTypes(): Observable<Array<IGoodsType>>;
  abstract get(id: string): Observable<IUnit>;
  abstract getAllowedActions(id: string): Observable<IUnitAllowedActions>;
  abstract getSummary(id: string): Observable<IUnitSummary>;
  abstract getPolicySummary(id: string): Observable<Array<IPolicyRiskSummary>>;
  abstract getClaims(id: string): Observable<Array<IDetailedClaim>>;
  abstract getFleetsForTransfer(id: string): Observable<Array<IFleet>>;
  abstract create(request: ICreateUnit): Observable<void>;
  abstract update(id: string, unit: IUnit): Observable<void>;
  abstract changePlateNumber(id: string, newPlateNumber: string): Observable<void>;
  abstract transfer(id: string, request: IUnitFleetTransferRequest): Observable<void>;
  abstract cancelRisks(id: string, request: IUnitCancelPolicyRisksRequest): Observable<void>;
  abstract search(request: IUnitSearchRequest): Observable<IPageOf<IUnitSearchResult>>;
  abstract isPlateNumberAvailable(request: IIsPlateNumberAvailable): Observable<IResponse<boolean>>;
  abstract guessRegistrationDate(plateNumber: string): Observable<IResponse<Date>>;
}
