<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <div>
    <form [formGroup]="model.form">
      <div class="flex f-gap--10px">
        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.fleets.insurer.merger.source.insurance.company.label"
          label="Aseguradora de origen"
          displayField="name"
          keyField="insuranceCompanyId"
          [selectableItems]="model.insuranceCompanies"
          [formControlName]="model.C_SOURCE_INSURANCE_COMPANY_ID"
        >
        </howden-autocomplete>

        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.fleets.insurer.merger.target.insurance.company.label"
          label="Aseguradora de destino"
          displayField="name"
          keyField="insuranceCompanyId"
          [selectableItems]="model.insuranceCompanies"
          [formControlName]="model.C_TARGET_INSURANCE_COMPANY_ID"
        >
        </howden-autocomplete>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.fleets.insurer.merger.operation.date.label">Fecha operación</mat-label>
          <input matInput [matDatepicker]="operationDatePicker" [formControlName]="model.C_OPERATION_DATE"
          [howdenControlError]="operationDateError" [min]="minDate" />
          <mat-error #operationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #operationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
        <button type="button" mat-raised-button color="primary" [disabled]="model.form.invalid" (click)="onPreviewMerge()">
          <span i18n="@@app.core.actions.preview">Previsualizar</span>
        </button>

        <mat-divider [vertical]="true"></mat-divider>

        <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
          <span i18n="@@app.core.actions.accept">Aceptar</span>
        </button>
      </div>
    </form>
  </div>

  <div class="margin-top-25">
    <howden-table
      class="small-font"
      paginable="local"
      sortable="local"
      filterable="global-local"
      [pageSize]="10"
      [cols]="model.columns"
      [data]="model.data"
    >
    </howden-table>
  </div>
</div>
