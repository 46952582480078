import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { ICollectionManager } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IMasterService } from './master.contract';

@Injectable({
  providedIn: 'root'
})
export class MasterService implements IMasterService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getCollectionManagers(): Observable<Array<ICollectionManager>> {
    return this.httpClient.get<Array<ICollectionManager>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/collection-managers`);
  }

  getPaymentTypes(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/payment-types`);
  }

  getPolicyCancellationReasons(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/policy-cancellation-reasons`);
  }

  getPolicyDurations(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/policy-durations`);
  }

  getPolicyStatuses(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/policy-statuses`);
  }

  getEndorsementStatuses(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/endorsement-statuses`);
  }

  getEndorsementTypes(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/endorsement-types`);
  }

  getReceiptSituations(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/receipt-situations`);
  }

  getReceiptTypes(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/receipt-types`);
  }

  getProcessStatuses(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/process-statuses`);
  }

  getFleetControlReportItemTypes(): Observable<Array<IKeyValuePair<number, string>>> {
    return this.httpClient.get<Array<IKeyValuePair<number, string>>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/fleet-control-report-item-types`);
  }

  getUnitStatuses(): Observable<Array<IKeyValuePair<number, string>>> {
    return this.httpClient.get<Array<IKeyValuePair<number, string>>>(APP_CONSTANTS.API_NET_FLOTAS, `/masters/unit-report-statuses`);
  }
}
