import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ProcessSearchComponent } from './process-search/pages/process-search.component';

export const ProcessRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'processes',
    title: $localize`:@@app.route.processes:Procesos`,
    data: {
      icon: 'memory',
      linkText: $localize`:@@app.route.processes:Procesos`
    },
    children: [
      {
        path: 'search',
        component: ProcessSearchComponent,
        title: $localize`:@@app.route.processes.search:Consulta global`,
        data: {
          linkText: $localize`:@@app.route.processes.search:Consulta global`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ProcessRoutes)],
  exports: [RouterModule]
})
export class ProcessesRoutingModule {
}
