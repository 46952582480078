import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { ICertificateGenerationRequest, ICertificateTemplate } from 'src/app/core/models/api/certificates';
import { ICertificateService } from './certificates.contract';

@Injectable({
  providedIn: 'root'
})
export class CertificateService implements ICertificateService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getTemplates(typeId: string): Observable<Array<ICertificateTemplate>> {
    return this.httpClient.get<Array<ICertificateTemplate>>(APP_CONSTANTS.API_NET_FLOTAS, `/certificates/${typeId}/templates`);
  }

  generate(request: ICertificateGenerationRequest): Observable<Blob> {
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, `/certificates`, request, { responseType: 'blob' });
  }
}
