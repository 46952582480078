import { ICertificateReplacement } from 'src/app/core/models/api/policies';
import { PolicyEditCertificateReplacementViewModel } from '../models';

export abstract class PolicyEditCertificateReplacementMapper {
  static mapForUpdate(source: PolicyEditCertificateReplacementViewModel): ICertificateReplacement {
    return {
      rootPolicyId: source.targetRootPolicyId,
      holderId: source.holderId,
      policyNumber: source.policyNumber,
      bookingModeId: source.bookingModeId,
      keepCurrentInsured: source.keepCurrentInsured,
      operationDate: source.operationDate,
      expirationDate: source.expirationDate,
      netBonus: source.netBonus,
      totalBonus: source.totalBonus,
      commissionRate: source.commissionRate
    } as ICertificateReplacement;
  }
}
