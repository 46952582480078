import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IButtonActionData, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicySummaryDialogComponent } from '../../policy-summary-dialog/pages/policy-summary-dialog.component';
import { PolicySearchMapper } from '../mappers';
import { PolicySearchViewModel } from '../models';

@Component({
  selector: 'howden-unit-search',
  templateUrl: './policy-search.component.html',
  styleUrls: ['./policy-search.component.scss']
})
export class PolicySearchComponent implements OnInit, OnDestroy {
  model = new PolicySearchViewModel();

  @ViewChild('policyNumberInput') policyNumberInputRef!: ElementRef;

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private masterSrv: IMasterService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit() {
    this.model.searchRequest.fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.getPolicyClasses(),
      this.masterSrv.getPolicyStatuses()
    ]).pipe(
      tap(([policyClasses, policyStatuses]) => {
        this.model.policyClasses = policyClasses;
        this.model.policyStatuses = policyStatuses;

        this.refresh();
        this.onChanges();

        this.uiBlockerSrv.unblock();

        this.policyNumberInputRef.nativeElement.focus();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.edit(event);
    } else {
      this.showInfo(event);
    }
  }

  private edit(event: IButtonActionData): void {
    this.router.navigate(['policies/edit', event.row.policyId]);
  }

  private showInfo(event: IButtonActionData): void {
    const inputData = { id: event.row.policyId };
    const dialogRef = this.dialog.open(PolicySummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  private refresh(): void {
    this.uiBlockerSrv.block();

    this.policySrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IPolicySearchResult>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => PolicySearchMapper.mapForSearch(source));
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }
}
