import { Component } from '@angular/core';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { FileUtils } from 'src/app/shared/utils';

@Component({
  selector: 'howden-download-help',
  templateUrl: './download-help.component.html',
  styleUrls: ['./download-help.component.scss']
})
export class DownloadHelpComponent {
  constructor(private fleetsSrv: IFleetService) {
  }

  onDownloadHelp(): void {
    this.fleetsSrv.downloadHelpManual().subscribe(blob => {
      FileUtils.downloadFile('MANUAL-HOWDEN-NET-FLOTAS', blob, 'PDF');
    });
  }
}
