import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICurrency } from 'src/app/core/models/ida/financial';
import { IPolicyRiskWarranty } from 'src/app/core/models/ida/production';

export class PolicyEditRiskWarrantiesViewModel {
  private _form: FormGroup;
  private _currencies: Array<ICurrency> = [];
  private _warranties: Array<IPolicyRiskWarranty> = [];

  readonly C_POLICY_RISK_ID = 'policyRiskId';
  readonly C_WARRANTY_ID = 'warrantyId';
  readonly C_ABBRV = 'abbrv';
  readonly C_FRANCHISE = 'franchise';
  readonly C_CURRENCY_ID = 'currencyId';
  readonly C_INCLUSION_DATE = 'inclusionDate';
  readonly C_EXCLUSION_DATE = 'exclusionDate';
  readonly C_ORDER = 'order';

  constructor() {
    this._form = new FormGroup({
      [this.C_POLICY_RISK_ID]: new FormControl(null, [Validators.required]),
      [this.C_WARRANTY_ID]: new FormControl(null, [Validators.required]),
      [this.C_ABBRV]: new FormControl(null, [Validators.required]),
      [this.C_FRANCHISE]: new FormControl(null, []),
      [this.C_CURRENCY_ID]: new FormControl(null, [Validators.required]),
      [this.C_INCLUSION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EXCLUSION_DATE]: new FormControl(null, []),
      [this.C_ORDER]: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(32767)])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get currencies(): Array<ICurrency> {
    return this._currencies;
  }

  set currencies(value: Array<ICurrency>) {
    this._currencies = value;
  }

  get warranties(): Array<IPolicyRiskWarranty> {
    return this._warranties;
  }

  set warranties(value: Array<IPolicyRiskWarranty>) {
    this._warranties = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get policyRiskId(): string | null | undefined {
    return this.getControl(this.C_POLICY_RISK_ID).value;
  }

  set policyRiskId(value: string | null | undefined) {
    this.getControl(this.C_POLICY_RISK_ID).setValue(value);
  }

  get warrantyId(): string | null | undefined {
    return this.getControl(this.C_WARRANTY_ID).value;
  }

  set warrantyId(value: string | null | undefined) {
    this.getControl(this.C_WARRANTY_ID).setValue(value);
  }

  get abbrv(): string | null | undefined {
    return this.getControl(this.C_ABBRV).value;
  }

  set abbrv(value: string | null | undefined) {
    this.getControl(this.C_ABBRV).setValue(value);
  }

  get franchise(): string | null | undefined {
    return this.getControl(this.C_FRANCHISE).value;
  }

  set franchise(value: string | null | undefined) {
    this.getControl(this.C_FRANCHISE).setValue(value);
  }

  get currencyId(): string | null | undefined {
    return this.getControl(this.C_CURRENCY_ID).value;
  }

  set currencyId(value: string | null | undefined) {
    this.getControl(this.C_CURRENCY_ID).setValue(value);
  }

  get inclusionDate(): Date | null | undefined {
    return this.getControl(this.C_INCLUSION_DATE).value;
  }

  set inclusionDate(value: Date | null | undefined) {
    this.getControl(this.C_INCLUSION_DATE).setValue(value);
  }

  get exclusionDate(): Date | null | undefined {
    return this.getControl(this.C_EXCLUSION_DATE).value;
  }

  set exclusionDate(value: Date | null | undefined) {
    this.getControl(this.C_EXCLUSION_DATE).setValue(value);
  }

  get order(): number | null | undefined {
    return this.getControl(this.C_ORDER).value;
  }

  set order(value: number | null | undefined) {
    this.getControl(this.C_ORDER).setValue(value);
  }
}
