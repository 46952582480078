<div class="flex f-gap--10px f-ai--center margin-top-10">
  <div class="f-basis--6">
    <form [formGroup]="model.form">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.edit.billing.groups.billingGroupId.label">Grupo de facturación</mat-label>
        <input
          matInput
          [formControlName]="model.C_BILLING_GROUP_ID"
          [matAutocomplete]="billingGroupSelect"
          [howdenControlError]="billingGroupError"
        />
        <mat-autocomplete #billingGroupSelect="matAutocomplete" [displayWith]="getBillingGroupDescription.bind(this)">
          @for (item of model.filteredBillingGroups | async; track item.billingGroupId) {
            <mat-option [value]="item.billingGroupId">
              {{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #billingGroupError></mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="f-basis--6">
    <div class="flex f-ai--top f-gap--10px margin-bottom-10">
      <button
        type="button"
        mat-mini-fab
        color="primary"
        matTooltip="Nueva prima"
        i18n-matTooltip="@@app.policies.edit.bonuses.actions.create"
        (click)="onNewBonus()"
        [disabled]="!hasValidSelection"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="flex">
  <howden-table
    class="small-font"
    keyField="bonusId"
    paginable="local"
    sortable="local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.bonuses"
    [buttons]="model.buttons"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
