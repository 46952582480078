
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { PolicyClass } from 'src/app/core/enums';
import { ICertificateTemplate } from 'src/app/core/models/api/certificates';
import { IPolicySearchRequest, IPolicySearchResult } from 'src/app/core/models/api/policies';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { Limits } from 'src/app/shared/utils';

export class CertificatesViewModel {
  private _hasNoPoliciesSelected = true;
  private _templateSelectionForm: FormGroup;
  private _policySearchForm: FormGroup;
  private _formattingForm: FormGroup;
  private _searchRequest: IPolicySearchRequest;
  private _templates: Array<ICertificateTemplate> = [];
  private _policyStatuses: Array<IMasterValue> = [];

  readonly C_TEMPLATE_ID = 'templateId';
  readonly C_RISK_DESCRIPTION = 'riskDescription';
  readonly C_POLICY_NUMBER = 'policyNumber';
  readonly C_HOLDER = 'holder';
  readonly C_STATUS_ID = 'statusId';
  readonly C_HEADER = 'header';
  readonly C_BODY = 'body';
  readonly C_FOOTER = 'footer';
  readonly C_EXPEDITION_CITY = 'expeditionCity';

  constructor() {
    this._templateSelectionForm = new FormGroup({
      [this.C_TEMPLATE_ID]: new FormControl('', [Validators.required])
    });

    this._policySearchForm = new FormGroup({
      [this.C_RISK_DESCRIPTION]: new FormControl('', []),
      [this.C_POLICY_NUMBER]: new FormControl('', []),
      [this.C_HOLDER]: new FormControl('', []),
      [this.C_STATUS_ID]: new FormControl('', [])
    });

    this._formattingForm = new FormGroup({
      [this.C_HEADER]: new FormControl('', []),
      [this.C_BODY]: new FormControl('', []),
      [this.C_FOOTER]: new FormControl('', []),
      [this.C_EXPEDITION_CITY]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: Limits.maxPageSize
    } as IPolicySearchRequest;

    this.updateServerSideFilters();
  }

  /* Step 1 */

  public get templates(): Array<ICertificateTemplate> {
    return this._templates;
  }
  public set templates(value: Array<ICertificateTemplate>) {
    this._templates = value;
  }

  public get certificateTemplateId(): string | null {
    return this.templateSelectionForm.get(this.C_TEMPLATE_ID)?.value;
  }
  public set certificateTemplateId(value: string | null) {
    this.templateSelectionForm.get(this.C_TEMPLATE_ID)?.setValue(value);
  }

  public get templateSelectionForm(): FormGroup {
    return this._templateSelectionForm;
  }
  public set templateSelectionForm(value: FormGroup) {
    this._templateSelectionForm = value;
  }

  /* Step 2 */

  public get hasNoPoliciesSelected(): boolean {
    return this._hasNoPoliciesSelected;
  }
  public set hasNoPoliciesSelected(value: boolean) {
    this._hasNoPoliciesSelected = value;
  }

  policies: Array<IPolicySearchResult> = [];
  policyColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.certificates.policies.policyNumber.column:Nº.Póliza`,
      canSort: true
    },
    {
      fieldName: 'riskDescription',
      fieldHeader: $localize`:@@app.certificates.policies.riskDescription.column:Denom.`,
      canSort: true
    },
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.certificates.policies.insuranceCompany.column:Aseg.`,
      canSort: true
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.certificates.policies.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.certificates.policies.product.column:Producto`,
      canSort: true
    },
    {
      fieldName: 'holder',
      fieldHeader: $localize`:@@app.certificates.policies.holder.column:Tomador`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.certificates.policies.effectDate.column:F.Efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.certificates.policies.expirationDate.column:F.Venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.certificates.policies.status.column:Estado`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get policyStatuses(): Array<IMasterValue> {
    return this._policyStatuses;
  }

  set policyStatuses(policyStatuses: Array<IMasterValue>) {
    this._policyStatuses = policyStatuses;
  }

  get searchRequest(): IPolicySearchRequest {
    return this._searchRequest;
  }

  get policySearchForm(): FormGroup {
    return this._policySearchForm;
  }

  set policySearchForm(value: FormGroup) {
    this._policySearchForm = value;
  }

  get riskDescription(): string | undefined {
    return this._policySearchForm.get(this.C_RISK_DESCRIPTION)?.value;
  }

  set riskDescription(value: string | undefined) {
    this._policySearchForm.get(this.C_RISK_DESCRIPTION)?.setValue(value);
  }

  get policyNumber() {
    return this._policySearchForm.get(this.C_POLICY_NUMBER)?.value;
  }

  set policyNumber(value: string | undefined) {
    this._policySearchForm.get(this.C_POLICY_NUMBER)?.setValue(value);
  }

  get holder() {
    return this._policySearchForm.get(this.C_HOLDER)?.value;
  }

  set holder(value: string | undefined) {
    this._policySearchForm.get(this.C_HOLDER)?.setValue(value);
  }

  get statusId(): string | undefined {
    return this._policySearchForm.get(this.C_STATUS_ID)?.value;
  }

  set statusId(value: string | undefined) {
    this._policySearchForm.get(this.C_STATUS_ID)?.setValue(value);
  }

  updateServerSideFilters(): void {
    this.searchRequest.riskDescription = this.riskDescription;
    this.searchRequest.policyNumber = this.policyNumber;
    this.searchRequest.holder = this.holder;
    this.searchRequest.policyClasses = [PolicyClass.Individual.toString()];
    this.searchRequest.statuses = this.statusId ? [this.statusId] : [];
  }

  /* Step 3 */

  public get formattingForm(): FormGroup {
    return this._formattingForm;
  }
  public set formattingForm(value: FormGroup) {
    this._formattingForm = value;
  }

  public get header(): string | null | undefined {
    return this.formattingForm.get(this.C_HEADER)?.value;
  }
  public set header(value: string | null | undefined) {
    this.formattingForm.get(this.C_HEADER)?.setValue(value);
  }

  public get body(): string | null | undefined {
    return this.formattingForm.get(this.C_BODY)?.value;
  }
  public set body(value: string | null | undefined) {
    this.formattingForm.get(this.C_BODY)?.setValue(value);
  }

  public get footer(): string | null | undefined {
    return this.formattingForm.get(this.C_FOOTER)?.value;
  }
  public set footer(value: string | null | undefined) {
    this.formattingForm.get(this.C_FOOTER)?.setValue(value);
  }

  public get expeditionCity(): string | null | undefined {
    return this.formattingForm.get(this.C_EXPEDITION_CITY)?.value;
  }
  public set expeditionCity(value: string | null | undefined) {
    this.formattingForm.get(this.C_EXPEDITION_CITY)?.setValue(value);
  }
}
