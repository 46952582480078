import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogResultTypes,
  IButtonActionData,
  IDialogResult
} from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IPolicyBillingGroup, IPolicyBillingGroupBonus } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicyEditBonusDialogComponent } from '../../../dialogs/policy-edit-bonus-dialog/pages/policy-edit-bonus-dialog.component';
import { PolicyEditBonusesViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-bonuses',
  templateUrl: './policy-edit-bonuses.component.html',
  styleUrls: ['./policy-edit-bonuses.component.scss']
})
export class PolicyEditBonusesComponent implements OnInit, OnChanges, OnDestroy {
  model = new PolicyEditBonusesViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.model.bonuses = [];
    this.uiBlockerSrv.block();
    this.policySrv.getBillingGroups(this.policyId).subscribe({
      next: (data: Array<IPolicyBillingGroup>) => {
        this.model.billingGroups = data;
        if (data && data.length === 1) {
          this.model.billingGroupId = data[0].billingGroupId;
        }
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.openDialog(event.row.bonusId);
    } else {
      this.onDelete(event.row.bonusId);
    }
  }

  onNewBonus(): void {
    this.openDialog();
  }

  getBillingGroupDescription(billingGroupId: string): string {
    return this.model.billingGroups?.find(x => x.billingGroupId === billingGroupId)?.name ?? '';
  }

  get hasValidSelection(): boolean {
    const billingGroupId = this.model.billingGroupId;

    if (billingGroupId) {
      const billingGroup = this.model.billingGroups.find(x => x.billingGroupId === billingGroupId);

      if (billingGroup) {
        return true;
      }
    }

    return false;
  }

  private openDialog(bonusId?: string): void {
    const inputData = {
      policyId: this.policyId,
      billingGroupId: this.model.billingGroupId,
      bonusId: bonusId
    };
    const dialogRef = this.dialog.open(PolicyEditBonusDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.refreshBonuses());
  }

  private onDelete(bonusId: string): void {
    const title: string = $localize`:@@app.policies.edit.bonuses.delete.title:Borrado de primas`;
    const question: string = $localize`:@@app.policies.edit.bonuses.delete.subtitle:Se borrarán las primas, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.policies.edit.bonuses.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();

          this.policySrv.deleteBillingGroupBonus(this.policyId, this.model.billingGroupId, bonusId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refreshBonuses();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private refreshBonuses() {
    this.uiBlockerSrv.block();

    this.policySrv.getBillingGroupBonuses(this.policyId, this.model.billingGroupId).subscribe({
      next: (data: Array<IPolicyBillingGroupBonus>) => this.model.bonuses = data,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_BILLING_GROUP_ID).valueChanges.subscribe(() => {
      const billingGroupId = this.model.billingGroupId;

      this.model.bonuses = [];

      if (billingGroupId) {
        const billingGroup = this.model.billingGroups.find(x => x.billingGroupId === billingGroupId);

        if (billingGroup) {
          this.refreshBonuses();
        }
      }
    });
  }
}
