import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BillingGroupUtils } from 'src/app/shared/utils/billing-group-utils';

export class PolicyEditBonusTypeUpdateViewModel {
  private _form: FormGroup;

  readonly C_BILLING_GROUP = 'billingGroup';

  constructor() {
    this._form = new FormGroup({
      [this.C_BILLING_GROUP]: new FormControl(null, [Validators.required, Validators.pattern(BillingGroupUtils.billingGroupPattern)])
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get billingGroup(): string | undefined {
    return this._form.get(this.C_BILLING_GROUP)?.value;
  }

  set billingGroup(value: string | undefined) {
    this._form.get(this.C_BILLING_GROUP)?.setValue(value);
  }
}
