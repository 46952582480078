<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.bonus.type.update.title">Cambio de tipo de prima</div>
    <div class="subtitle-text" i18n="@@app.policies.edit.bonus.type.update.subtitle">
      Se cambiará el tipo de prima de la póliza, regenerandose las primas en función del cambio a realizar.
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="margin-bottom-10">
          <mat-label
            i18n="@@app.policies.edit.bonus.type.update.billingGroup.label">Grupo facturación</mat-label>
          <input
            matInput
            [formControlName]="model.C_BILLING_GROUP"
            [howdenControlError]="billingGroupError"
            maxlength="15"
          />
          <mat-error #billingGroupError></mat-error>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div class="flex f-gap--10px text-left">
        <table>
          <thead>
            <tr>
              <th i18n="@@app.policies.edit.bonus.type.update.code.label">Código</th>
              <th i18n="@@app.policies.edit.bonus.type.update.description.label">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.insured.abbrv.label">+AP</td>
              <td i18n="@@app.policies.edit.bonus.type.update.insured.label">Asegurado</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.holder.abbrv.label">+TP</td>
              <td i18n="@@app.policies.edit.bonus.type.update.holder.label">Tomador</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.vehicleType.abbrv.label">+TV</td>
              <td i18n="@@app.policies.edit.bonus.type.update.vehicleType.label">Tipo de vehículo</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.vehicleUsage.abbrv.label">+UV</td>
              <td i18n="@@app.policies.edit.bonus.type.update.vehicleUsage.label">Uso vehículo</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.bookingMode.abbrv.label">+MC</td>
              <td i18n="@@app.policies.edit.bonus.type.update.bookingMode.label">Modo de contratación</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.movementArea.abbrv.label">+AC</td>
              <td i18n="@@app.policies.edit.bonus.type.update.movementArea.label">Ambito de circulación</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.circulationArea.abbrv.label">+RC</td>
              <td i18n="@@app.policies.edit.bonus.type.update.circulationArea.label">Recinto de circulación</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.bonusGroup.abbrv.label">+GP</td>
              <td i18n="@@app.policies.edit.bonus.type.update.bonusGroup.label">Grupo Primas</td>
            </tr>
            <tr>
              <td i18n="@@app.policies.edit.bonus.type.update.goodsType.abbrv.label">+TM</td>
              <td i18n="@@app.policies.edit.bonus.type.update.goodsType.label">Tipo de mercancía</td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
