import { Component, Input } from '@angular/core';
import { PolicyBillingGroupSegmentType } from 'src/app/core/enums';
import { IPolicyBillingGroupSegment, IPolicyRegularizationGroup } from 'src/app/core/models/api/policies';

@Component({
  selector: 'howden-policy-edit-regularization-group-segment',
  templateUrl: './policy-edit-regularization-group-segment.component.html',
  styleUrls: ['./policy-edit-regularization-group-segment.component.scss']
})
export class PolicyEditRegularizationGroupSegmentComponent {
  @Input() group: IPolicyRegularizationGroup | null = null;
  @Input() segment: IPolicyBillingGroupSegment | null = null;

  get isDefaultSegment(): boolean {
    return typeof this.segment !== 'undefined'
      && this.segment !== null
      && this.segment.segmentType === PolicyBillingGroupSegmentType.Default;
  }

  get description(): string {
    return this.isDefaultSegment ? 'NORMAL' : 'RECARGO';
  }
}
