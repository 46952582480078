import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IDetailedClaimView } from './detailed-claim-view';

export class ClaimListViewModel {
  data: Array<IDetailedClaimView> = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'claimId',
      fieldHeader: $localize`:@@app.claims.id.column:ID`,
      canSort: true
    },
    {
      fieldName: 'claimDate',
      fieldHeader: $localize`:@@app.claims.date.colum:Fecha`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'clientName',
      fieldHeader: $localize`:@@app.claims.clientName.Column:Cliente`,
      canSort: true
    },
    {
      fieldName: 'insuranceCompanyName',
      fieldHeader: $localize`:@@app.claims.insuranceCompanyName.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.claims.policyNumber.column:Nº. Póliza`,
      canSort: true
    },
    {
      fieldName: 'claimTypeDescription',
      fieldHeader: $localize`:@@app.claimsType.column:Tipo`,
      canSort: true
    },
    {
      fieldName: 'cause',
      fieldHeader: $localize`:@@app.claims.cause.column:Causa`,
      canSort: true
    },
    {
      fieldName: 'statusDescription',
      fieldHeader: $localize`:@@app.claims.status.column:Estado`,
      canSort: true
    },
    {
      fieldName: 'subStatus',
      fieldHeader: $localize`:@@app.claims.substatus.column:Situación`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.units.edit.claims.actions.info:Información`
  }];
}
