import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { IEndorsementSummary } from 'src/app/core/models/api/endorsements';
import {
  IBillingModel,
  IBonusType,
  IBonusTypeUpdate,
  IBookingModeChange,
  ICertificateReplacement,
  IChangePolicyHolder,
  IChangePolicyRisk,
  IColectivePolicyReplacement,
  IContractSummary,
  ICreateEndorsement,
  ICreateRootPolicy,
  IGuessIndividualBonus,
  IGuessIndividualBonusResponse,
  IPolicy,
  IPolicyAssignationResponse,
  IPolicyBilling,
  IPolicyBillingGroup,
  IPolicyBillingGroupBonus,
  IPolicyBillingGroupRule,
  IPolicyCancellation,
  IPolicyClass,
  IPolicyImport,
  IPolicyIncidence,
  IPolicyNumber,
  IPolicyParalization,
  IPolicyRegularization,
  IPolicyRisk,
  IPolicyRiskSummary,
  IPolicySearchRequest,
  IPolicySearchResult,
  IPolicySummary,
  IPolicyUpdate,
  IReceiptTarget
} from 'src/app/core/models/api/policies';
import { IAssignRisks } from 'src/app/core/models/api/policies/assign-risks';
import { IPolicyImportRequest } from 'src/app/core/models/api/policies/policy-import-request';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IDocument } from 'src/app/core/models/ida/document-manager/document';
import { IDetailedReceipt } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import {
  ICancelReplacement,
  IDetailedEndorsement,
  IPolicyRiskWarranty,
  IUpdatePolicyOffice,
  IUpsertRiskWarranty
} from 'src/app/core/models/ida/production';
import { IPolicyService } from './policy.contract';

@Injectable({
  providedIn: 'root'
})
export class PolicyService implements IPolicyService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getCancellationReasons(): Observable<Array<IMasterValue>> {
    return this.httpClient.get<Array<IMasterValue>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/cancellation-reasons`);
  }

  getBonusTypes(): Observable<Array<IBonusType>> {
    return this.httpClient.get<Array<IBonusType>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/bonus-types`);
  }

  getPolicyClasses(): Observable<Array<IPolicyClass>> {
    return this.httpClient.get<Array<IPolicyClass>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/policy-classes`);
  }

  getRootPolicyClasses(): Observable<Array<IPolicyClass>> {
    return this.httpClient.get<Array<IPolicyClass>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/root-policy-classes`);
  }

  getBillingModels(): Observable<Array<IBillingModel>> {
    return this.httpClient.get<Array<IBillingModel>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/billing-models`);
  }

  getReceiptTargets(): Observable<Array<IReceiptTarget>> {
    return this.httpClient.get<Array<IReceiptTarget>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/receipt-targets`);
  }

  getTransferModes(): Observable<Array<IKeyValuePair<string, string>>> {
    return this.httpClient.get<Array<IKeyValuePair<string, string>>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/transfer-modes`);
  }

  getHolderReferences(): Observable<Array<IKeyValuePair<string, string>>> {
    return this.httpClient.get<Array<IKeyValuePair<string, string>>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/holder-references`);
  }

  getInsuredReferences(): Observable<Array<IKeyValuePair<string, string>>> {
    return this.httpClient.get<Array<IKeyValuePair<string, string>>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/insured-references`);
  }

  get(id: string): Observable<IPolicy> {
    return this.httpClient.get<IPolicy>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}`);
  }

  getSummary(id: string): Observable<IPolicySummary> {
    return this.httpClient.get<IPolicySummary>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/summary`);
  }

  getSummaryHeader(id: string): Observable<IPolicySummary> {
    return this.httpClient.get<IPolicySummary>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/summary-header`);
  }

  getCertificates(id: string): Observable<Array<IPolicySummary>> {
    return this.httpClient.get<Array<IPolicySummary>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/certificates`);
  }

  getClaims(id: string): Observable<Array<IDetailedClaim>> {
    return this.httpClient.get<Array<IDetailedClaim>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/claims`);
  }

  getIncidences(id: string): Observable<Array<IPolicyIncidence>> {
    return this.httpClient.get<Array<IPolicyIncidence>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/incidences`);
  }

  getEndorsements(id: string): Observable<Array<IDetailedEndorsement>> {
    return this.httpClient.get<Array<IDetailedEndorsement>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/endorsements`);
  }

  getEndorsementSummary(policyId: string, endorsementId: string): Observable<IEndorsementSummary> {
    return this.httpClient.get<IEndorsementSummary>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${policyId}/endorsements/${endorsementId}/summary`);
  }

  getReceipts(id: string): Observable<Array<IDetailedReceipt>> {
    return this.httpClient.get<Array<IDetailedReceipt>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/receipts`);
  }

  getPolicyRisks(id: string): Observable<Array<IPolicyRiskSummary>> {
    return this.httpClient.get<Array<IPolicyRiskSummary>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks`);
  }

  getPolicyRisk(id: string, policyRiskId: string): Observable<IPolicyRisk> {
    return this.httpClient.get<IPolicyRisk>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks/${policyRiskId}`);
  }

  getPolicyRiskWarranties(id: string, policyRiskId: string): Observable<Array<IPolicyRiskWarranty>> {
    return this.httpClient.get<Array<IPolicyRiskWarranty>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks/${policyRiskId}/warranties`);
  }

  getBookingModes(id: string): Observable<Array<IBookingModeSearchResult>> {
    return this.httpClient.get<Array<IBookingModeSearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/booking-modes`);
  }

  hasWaitingProcesses(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.get<IResponse<boolean>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/has-waiting-processes`);
  }

  update(id: string, request: IPolicyUpdate): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}`, request);
  }

  updatePolicyNumber(id: string, request: IPolicyNumber): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-number`, request);
  }

  updatePolicyOffice(id: string, request: IUpdatePolicyOffice): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-office`, request);
  }

  updatePolicyHolder(id: string, request: IChangePolicyHolder): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-holder`, request);
  }

  updateBonusType(id: string, request: IBonusTypeUpdate): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/bonus-type`, request);
  }

  updatePolicyRiskWarranty(id: string, policyRiskId: string, warranty: IUpsertRiskWarranty): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks/${policyRiskId}/warranties`, warranty);
  }

  changePolicyRisk(id: string, request: IChangePolicyRisk): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risk`, request);
  }

  cancel(id: string, request: IPolicyCancellation): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/cancel`, request);
  }

  reactivate(id: string): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/reactivate`, null);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}`);
  }

  updatePolicyRisk(id: string, policyRiskId: string, policyRisk: IPolicyRisk): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks/${policyRiskId}`, policyRisk);
  }

  deletePolicyRisk(id: string, policyRiskId: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks/${policyRiskId}`);
  }

  bookingModeChange(id: string, policyRiskId: string, request: IBookingModeChange): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-risks/${policyRiskId}/booking-mode`, request);
  }

  createRootPolicy(request: ICreateRootPolicy): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/create-root-policy`, request);
  }

  search(request: IPolicySearchRequest): Observable<IPageOf<IPolicySearchResult>> {
    return this.httpClient.post<IPageOf<IPolicySearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, '/policies/search', request);
  }

  isPolicyNumberAvailable(insuranceCompanyId: string, policyNumber: string): Observable<IResponse<boolean>> {
    const request: IPolicyNumber = {
      insuranceCompanyId: insuranceCompanyId,
      policyNumber: policyNumber
    } as IPolicyNumber;

    return this.httpClient.post<IResponse<boolean>>(APP_CONSTANTS.API_NET_FLOTAS, '/policies/numbers/is-available', request);
  }

  getParalizations(id: string): Observable<Array<IPolicyParalization>> {
    return this.httpClient.get<Array<IPolicyParalization>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/paralization-days`);
  }

  getParalization(id: string, paralizationId: string): Observable<IPolicyParalization> {
    return this.httpClient.get<IPolicyParalization>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/paralization-days/${paralizationId}`);
  }

  saveParalization(id: string, paralization: IPolicyParalization): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/paralization-days`, paralization);
  }

  deleteParalization(id: string, paralizationId: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/paralization-days/${paralizationId}`);
  }

  getBillingGroups(id: string): Observable<Array<IPolicyBillingGroup>> {
    return this.httpClient.get<Array<IPolicyBillingGroup>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups`);
  }

  getBillingGroup(id: string, billingGroupId: string): Observable<IPolicyBillingGroup> {
    return this.httpClient.get<IPolicyBillingGroup>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}`);
  }

  saveBillingGroup(id: string, billingGroup: IPolicyBillingGroup): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups`, billingGroup);
  }

  deleteBillingGroup(id: string, billingGroupId: string): Observable<void> {
    return this.httpClient.delete(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}`);
  }

  getBillingGroupRules(id: string, billingGroupId: string): Observable<Array<IPolicyBillingGroupRule>> {
    return this.httpClient.get<Array<IPolicyBillingGroupRule>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/rules`);
  }

  getBillingGroupRule(id: string, billingGroupId: string, ruleId: string): Observable<IPolicyBillingGroupRule> {
    return this.httpClient.get<IPolicyBillingGroupRule>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/rules/${ruleId}`);
  }

  saveBillingGroupRule(id: string, billingGroupId: string, rule: IPolicyBillingGroupRule): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/rules`, rule);
  }

  deleteBillingGroupRule(id: string, billingGroupId: string, ruleId: string): Observable<void> {
    return this.httpClient.delete(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/rules/${ruleId}`);
  }

  getBillingGroupBonuses(id: string, billingGroupId: string): Observable<Array<IPolicyBillingGroupBonus>> {
    return this.httpClient.get<Array<IPolicyBillingGroupBonus>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/bonuses`);
  }

  getBillingGroupBonus(id: string, billingGroupId: string, bonusId: string): Observable<IPolicyBillingGroupBonus> {
    return this.httpClient.get<IPolicyBillingGroupBonus>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/bonuses/${bonusId}`);
  }

  saveBillingGroupBonus(id: string, billingGroupId: string, bonus: IPolicyBillingGroupBonus): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/bonuses`, bonus);
  }

  deleteBillingGroupBonus(id: string, billingGroupId: string, bonusId: string): Observable<void> {
    return this.httpClient.delete(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-groups/${billingGroupId}/bonuses/${bonusId}`);
  }

  getDocuments(id: string): Observable<Array<IDocument>> {
    return this.httpClient.get<Array<IDocument>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/documents`);
  }

  saveDocument(id: string, document: FormData): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/documents/`, document);
  }

  downloadDocument(id: string, documentId: string): Observable<Blob> {
    return this.httpClient.getBlob(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/documents/${documentId}/download`, {
      reportProgress: true,
      responseType: 'blob'
    });
  }

  deleteDocument(id: string, documentId: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/documents/${documentId}`);
  }

  getPoliciesToImport(rootPolicyId: string): Observable<Array<IPolicyImport>> {
    return this.httpClient.get<Array<IPolicyImport>>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${rootPolicyId}/import`);
  }

  importPolicies(rootPolicyId: string, request: IPolicyImportRequest): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${rootPolicyId}/import`, request);
  }

  certificateReplacement(id: string, request: ICertificateReplacement): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/certificate-replacement`, request);
  }

  colectiveReplacement(id: string, request: IColectivePolicyReplacement): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/colective-replacement`, request);
  }

  cancelReplacement(id: string, request: ICancelReplacement): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/cancel-replacement`, request);
  }

  createEndorsement(id: string, request: ICreateEndorsement): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/endorsements`, request);
  }

  getContract(id: string): Observable<IContractSummary> {
    return this.httpClient.get<IContractSummary>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/contract`);
  }

  assignRisks(id: string, request: IAssignRisks): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/assign-risks`, request);
  }

  guessIndividualBonus(id: string, request: IGuessIndividualBonus): Observable<IGuessIndividualBonusResponse> {
    return this.httpClient.post<IGuessIndividualBonusResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/guess-individual-bonus`, request);
  }

  policyAssignation(id: string, request: FormData): Observable<IPolicyAssignationResponse> {
    return this.httpClient.post<IPolicyAssignationResponse>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/policy-assignation`, request);
  }

  getInitialState(id: string, year: number): Observable<IPolicyBilling> {
    return this.httpClient.get<IPolicyBilling>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/initial-state/${year}`);
  }

  getBillingInfo(id: string, date: string): Observable<IPolicyBilling> {
    return this.httpClient.get<IPolicyBilling>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/billing-info/${date}`);
  }

  getRegularizationInfo(id: string, date: string): Observable<IPolicyRegularization> {
    return this.httpClient.get<IPolicyRegularization>(APP_CONSTANTS.API_NET_FLOTAS, `/policies/${id}/regularization-info/${date}`);
  }
}
