<div class="howden-dialog-content">
  <div mat-dialog-title class="title-text">{{ model.plateNumber }}</div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <div class="f-basis--12">
        <div class="flex f-fd--row f-gap--5px">
          <howden-label-value
            class="f-basis--4 f-as-start"
            labelText="Tipo de vehículo"
            i18n-labelText="@@app.units.summary.vehicleType.label"
            value="{{model.vehicleType}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4 f-as-start"
            labelText="Uso vehículo"
            i18n-labelText="@@app.units.summary.vehicleUsage.label"
            value="{{model.vehicleUsage}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4 f-as-start"
            labelText="Nº Coche"
            i18n-labelText="@@app.units.summary.carNumber.label"
            value="{{model.carNumber}}"
          ></howden-label-value>
        </div>
      </div>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <div class="f-basis--12">
        <div class="flex f-fd--row f-gap--5px">
          <howden-label-value
            class="f-basis--4"
            labelText="Propietario"
            i18n-labelText="@@app.units.summary.owner.label"
            value="{{model.owner}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Usuario"
            i18n-labelText="@@app.units.summary.user.label"
            value="{{model.user}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Arrendatario"
            i18n-labelText="@@app.units.summary.lessee.label"
            value="{{model.lessee}}"
          ></howden-label-value>
        </div>
      </div>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <div class="f-basis--12">
        <div class="flex f-fd--row f-gap--5px">
          <howden-label-value
            class="f-basis--4"
            labelText="Marca"
            i18n-labelText="@@app.units.summary.brand.label"
            value="{{model.brand}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Modelo"
            i18n-labelText="@@app.units.summary.model.label"
            value="{{model.model}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Versión"
            i18n-labelText="@@app.units.summary.version.label"
            value="{{model.version}}"
          ></howden-label-value>
        </div>
      </div>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <div class="f-basis--12">
        <div class="flex f-fd--row f-gap--5px">
          <howden-label-value
            class="f-basis--4"
            labelText="Valor"
            i18n-labelText="@@app.units.summary.value.label"
            value="{{model.value | number : '1.2-2'}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Plazas"
            i18n-labelText="@@app.units.summary.seatNumber1.label"
            value="{{model.seatNumber1}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Total plazas"
            i18n-labelText="@@app.units.summary.seatNumber2.label"
            value="{{model.seatNumber2}}"
          ></howden-label-value>
        </div>
      </div>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <div class="f-basis--12">
        <div class="flex f-fd--row f-gap--5px">
          <howden-label-value
            class="f-basis--4"
            labelText="Ambito de circulación"
            i18n-labelText="@@app.units.summary.movementArea.label"
            value="{{model.movementArea}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Recinto de circulacion"
            i18n-labelText="@@app.units.summary.circulationArea.label"
            value="{{model.circulationArea}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Tipo funcional"
            i18n-labelText="@@app.units.summary.functionalType.label"
            value="{{model.functionalType}}"
          ></howden-label-value>
        </div>
      </div>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <div class="f-basis--12">
        <div class="flex f-fd--row f-gap--5px">
          <howden-label-value
            class="f-basis--4"
            labelText="Tipificación matrícula"
            i18n-labelText="@@app.units.summary.registrationClassification.label"
            value="{{model.registrationClassification}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="F. matriculación"
            i18n-labelText="@@app.units.summary.registrationDate.label"
            value="{{model.registrationDate | date}}"
          ></howden-label-value>

          <howden-label-value
            class="f-basis--4"
            labelText="Concesión"
            i18n-labelText="@@app.units.summary.concession.label"
            value="{{model.concession}}"
          ></howden-label-value>
        </div>
      </div>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <howden-label-value
        class="f-basis--12"
        labelText="Código clasificación CL"
        i18n-labelText="@@app.units.summary.classificationCodeCL.label"
        value="{{model.classificationCodeCL}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      @if (model.hasObservations) {
        <howden-label-value
          class="f-basis--12 observations"
          labelText="Observaciones"
          i18n-labelText="@@app.units.summary.observations.label"
          value="{{model.observations}}"
        ></howden-label-value>
      }
    </div>

    @if (policies) {
      <div class="flex f-gap--10px margin-top-10">
        <howden-table
          class="small-font"
          keyField="policyRiskId"
          paginable="none"
          sortable="local"
          filterable="global-local"
          [cols]="policies.columns"
          [data]="policies.data"
          [buttons]="policies.buttons"
          (buttonClicked)="onPolicyAction($event)"
          >
        </howden-table>
      </div>
    }

    <div class="flex f-gap--10px margin-top-10"></div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button color="success" (click)="onClose()">
      <span i18n="@@app.core.actions.close">Cerrar</span>
    </button>
  </mat-dialog-actions>
</div>
