import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { PolicyEditRiskMapper } from '../mappers';
import { PolicyEditRiskViewModel } from '../models';

@Component({
  selector: 'howden-policy-edi-riskt-dialog',
  templateUrl: './policy-edit-risk-dialog.component.html',
  styleUrls: ['./policy-edit-risk-dialog.component.scss']
})
export class PolicyEditRiskDialogComponent implements OnInit {
  model = new PolicyEditRiskViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditRiskDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.isBookingModeRequired(fleetId),
      this.policySrv.getBookingModes(this.inputData.policyId),
      this.policySrv.getPolicyRisk(this.inputData.policyId, this.inputData.policyRiskId)
    ]).subscribe(([
      fleetCompanies,
      isBookingModeRequired,
      bookingModes,
      policyRisk
    ]) => {
      this.model.policyId = this.inputData.policyId;
      this.model.policyRiskId = this.inputData.policyRiskId;
      this.model.fleetCompanies = fleetCompanies;
      this.model.bookingModes = bookingModes;

      if (isBookingModeRequired.result === true) {
        this.model.getControl(this.model.C_BOOKING_MODE_ID).addValidators([Validators.required]);
      } else {
        this.model.getControl(this.model.C_BOOKING_MODE_ID).removeValidators([Validators.required]);
      }
      this.model.getControl(this.model.C_BOOKING_MODE_ID).updateValueAndValidity();

      PolicyEditRiskMapper.mapForEdit(policyRisk, this.model);

      this.uiBlockerSrv.unblock();
    });
  }

  onAccept() {
    const policyId = this.inputData.policyId;
    const policyRiskId = this.inputData.policyRiskId;
    const policyRisk = PolicyEditRiskMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.updatePolicyRisk(policyId, policyRiskId, policyRisk).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getBookingModeDescription(bookingModeId: string): string {
    return this.model.bookingModes?.find(x => x.bookingModeId === bookingModeId)?.description ?? '';
  }

  getFleetCompanyName(fleetCompanyId: string): string {
    return this.model.fleetCompanies?.find(x => x.companyId === fleetCompanyId)?.clientName ?? '';
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
