import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IClient, IGroupOfClient, IGroupOfClientOffice } from 'src/app/core/models/ida/clients';
import { IClientGroupsService } from './client-groups.contract';

@Injectable({
  providedIn: 'root'
})
export class ClientGroupsService implements IClientGroupsService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getClientGroups(): Observable<Array<IGroupOfClient>> {
    return this.httpClient.get<Array<IGroupOfClient>>(APP_CONSTANTS.API_NET_FLOTAS, '/groups-of-clients');
  }

  getClients(id: string): Observable<Array<IClient>> {
    return this.httpClient.get<Array<IClient>>(APP_CONSTANTS.API_NET_FLOTAS, `/groups-of-clients/${id}/clients`);
  }

  getOffices(id: string): Observable<Array<IGroupOfClientOffice>> {
    return this.httpClient.get<Array<IGroupOfClientOffice>>(APP_CONSTANTS.API_NET_FLOTAS, `/groups-of-clients/${id}/offices`);
  }
}
