<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  @if (!hasUpdaterResults) {
    <div>
      <form [formGroup]="model.form">
        <div class="flex">
          <h4 i18n="@@app.policies.replacer.sections.transferInfo">Datos traspaso</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@app.policies.replacer.targetRootPolicyId.label">Póliza madre de destino</mat-label>
            <input
              #targetRootPolicyInput
              matInput
              [formControlName]="model.C_TARGET_ROOT_POLICY_ID"
              [matAutocomplete]="rootPolicySelect"
              [howdenControlError]="rootPolicyIdError"
            />
            <mat-autocomplete
              #rootPolicySelect="matAutocomplete"
              [displayWith]="getRootPolicyDescription.bind(this)"
              >
              @for (item of model.filteredRootPolicies | async; track item.policyId) {
                <mat-option [value]="item.policyId">
                  {{ item.policyNumber }}-{{ item.riskDescription }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #rootPolicyIdError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.transferModeID.label">Modo Traspaso/Reemplazo</mat-label>
            <mat-select [formControlName]="model.C_TRANSFER_MODE_ID" [howdenControlError]="transferModeIdError">
              @for (item of model.transferModes; track item.key) {
                <mat-option [value]="item.key">
                  {{ item.value }}
                </mat-option>
              }
            </mat-select>
            <mat-error #transferModeIdError></mat-error>
          </mat-form-field>

          <mat-slide-toggle
            class="f-basis--4 f-as--center"
            [formControlName]="model.C_CANCEL_WITHOUT_EFFECT"
            i18n="@@app.policies.replacer.cancelWithoutEffect.label">Baja sin effecto</mat-slide-toggle>
        </div>

        <div class="flex">
          <h4 i18n="@@app.policies.replacer.sections.newPolicyInfo">Datos nuevas pólizas</h4>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.insuranceCompanyId.label">Aseguradora</mat-label>
            <input
              matInput
              [formControlName]="model.C_INSURANCE_COMPANY_ID"
              [matAutocomplete]="insuranceCompanySelect"
              [howdenControlError]="insuranceCompanyIdError"
            />
            <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
              @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
                <mat-option [value]="item.insuranceCompanyId">
                  {{ item.alias }}-{{ item.name }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #insuranceCompanyIdError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.industryId.label">Ramo</mat-label>
            <input
              matInput
              [formControlName]="model.C_INDUSTRY_ID"
              [matAutocomplete]="industrySelect"
              [howdenControlError]="industryIdError"
            />
            <mat-autocomplete #industrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
              @for (item of model.filteredIndustries | async; track item.industryId) {
                <mat-option [value]="item.industryId">
                  {{ item.alias }}-{{ item.name }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #industryIdError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.productId.label">Producto</mat-label>
            <input
              matInput
              [formControlName]="model.C_PRODUCT_ID"
              [matAutocomplete]="productSelect"
              [howdenControlError]="productIdError"
            />
            <mat-autocomplete #productSelect="matAutocomplete" [displayWith]="getProductName.bind(this)">
              @for (item of model.filteredProducts | async; track item.productId) {
                <mat-option [value]="item.productId">
                  {{ item.alias }}-{{ item.name }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #productIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.firstEffectDate.label">Fecha primer efecto</mat-label>
            <input
              matInput
              [matDatepicker]="firstEffectDatePicker"
              [formControlName]="model.C_FIRST_EFFECT_DATE"
              [howdenControlError]="firstEffectDateError"
              [min]="minDate"
            />
            <mat-error #firstEffectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="firstEffectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #firstEffectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.effectDate.label">Fecha efecto</mat-label>
            <input
              matInput
              [matDatepicker]="effectDatePicker"
              [formControlName]="model.C_EFFECT_DATE"
              [howdenControlError]="effectDateError"
              [min]="minDate"
            />
            <mat-error #effectDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.expirationDate.label">Fecha vencimiento</mat-label>
            <input
              matInput
              [matDatepicker]="expirationDatePicker"
              [formControlName]="model.C_EXPIRATION_DATE"
              [howdenControlError]="expirationDateError"
              [min]="minDate"
            />
            <mat-error #expirationDateError></mat-error>
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.holderReferenceId.label">Tomador</mat-label>
            <mat-select [formControlName]="model.C_HOLDER_REFERENCE_ID" [howdenControlError]="holderReferenceIdError">
              @for (item of model.holderReferences | howdenArraySort: 'value'; track item.key) {
                <mat-option [value]="item.key">
                  {{ item.value }}
                </mat-option>
              }
            </mat-select>
            <mat-error #holderReferenceIdError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.insuredReferenceId.label">Asegurado</mat-label>
            <mat-select [formControlName]="model.C_INSURED_REFERENCE_ID" [howdenControlError]="insuredReferenceIdError">
              <mat-option></mat-option>
              @for (item of model.insuredReferences | howdenArraySort: 'value'; track item.key) {
                <mat-option [value]="item.key">
                  {{ item.value }}
                </mat-option>
              }
            </mat-select>
            <mat-error #insuredReferenceIdError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.mediationCodeId.label">Código mediación</mat-label>
            <input
              matInput
              [formControlName]="model.C_MEDIATION_CODE_ID"
              [matAutocomplete]="mediationCodeSelect"
              [howdenControlError]="mediationCodeIdError"
            />
            <mat-autocomplete #mediationCodeSelect="matAutocomplete" [displayWith]="getMediationCodeDescription.bind(this)">
              @for (item of model.filteredMediationCodes | async; track item.mediationCodeId) {
                <mat-option [value]="item.mediationCodeId">
                  {{ item.code }}-{{ item.description }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #mediationCodeIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.paymentTypeId.label">Forma de pago</mat-label>
            <mat-select [formControlName]="model.C_PAYMENT_TYPE_ID" [howdenControlError]="paymentTypeIdError">
              <mat-option></mat-option>
              @for (item of model.paymentTypes | howdenArraySort: 'description'; track item.erpId) {
                <mat-option [value]="item.erpId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #paymentTypeIdError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.duration.label">Duración</mat-label>
            <mat-select [formControlName]="model.C_DURATION_ID" [howdenControlError]="durationError">
              @for (item of model.policyDurations | howdenArraySort: 'description'; track item.erpId) {
                <mat-option [value]="item.erpId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #durationError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--4">
            <mat-label i18n="@@app.policies.replacer.collectionManagerId.label">Gestor de cobro</mat-label>
            <input
              matInput
              [formControlName]="model.C_COLLECTION_MANAGER_ID"
              [matAutocomplete]="collectionManagerSelect"
              [howdenControlError]="collectionManagerIdError"
            />
            <mat-autocomplete
              #collectionManagerSelect="matAutocomplete"
              [displayWith]="getCollectionManagerName.bind(this)"
              >
              @for (item of model.filteredCollectionManagers | async; track item.collectionManagerId) {
                <mat-option [value]="item.collectionManagerId">
                  {{ item.alias }}-{{ item.name }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #collectionManagerIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis-12">
            <mat-label i18n="@@app.policies.replacer.bookingModeId.label">Modo de contratación</mat-label>
            <input
              matInput
              [formControlName]="model.C_BOOKING_MODE_ID"
              [matAutocomplete]="bookingModeSelect"
              [howdenControlError]="bookingModeIdError"
            />
            <mat-autocomplete
              #bookingModeSelect="matAutocomplete"
              [displayWith]="getBookingModeDescription.bind(this)"
              >
              @for (item of model.filteredBookingModes | async; track item.bookingModeId) {
                <mat-option [value]="item.bookingModeId">
                  {{ item.description }}
                </mat-option>
              }
            </mat-autocomplete>
            <mat-error #bookingModeIdError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <h4 i18n="@@app.policies.replacer.sections.policies">Pólizas a traspasar</h4>
        </div>

        <div class="flex f-gap--10px">
          <howden-file-upload #file class="margin-10" (filesSelected)="onFileSelected()"></howden-file-upload>
        </div>

        <div class="f-basis--12">
          <div class="flex f-jc--center f-gap--10px">
            <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
              <span i18n="@@app.core.actions.accept">Aceptar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  } @else {
    <div>
      <howden-table
        class="small-font"
        paginable="local"
        sortable="local"
        filterable="global-local"
        [pageSize]="10"
        [cols]="model.updaterResultsColumns"
        [data]="model.updaterResults"
        >
      </howden-table>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10">
      <button type="button" mat-raised-button (click)="onGoBack()">
        <span i18n="@@app.core.actions.back">Volver</span>
      </button>
    </div>
  }
</div>
