import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IPolicyParalization } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { PolicyEditParalizationMapper } from '../mappers';
import { PolicyEditParalizationViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-paralization-dialog',
  templateUrl: './policy-edit-paralization-dialog.component.html',
  styleUrls: ['./policy-edit-paralization-dialog.component.scss']
})
export class PolicyEditParalizationDialogComponent implements OnInit {
  model = new PolicyEditParalizationViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditParalizationDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const policyId = this.inputData.policyId as string;
    const paralizationId = this.inputData.paralizationId;

    if (typeof paralizationId !== 'undefined' && paralizationId !== null) {
      this.policySrv.getParalization(policyId, paralizationId).subscribe((data: IPolicyParalization) => {
        PolicyEditParalizationMapper.mapForEdit(data, this.model);
      });
    }
  }

  onAccept() {
    const id = this.inputData.policyId as string;
    const request = PolicyEditParalizationMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.saveParalization(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
