import { Component, Input } from '@angular/core';
import { IBreakPointObserverSrv } from 'src/app/core/services/ui';

@Component({
  selector: 'howden-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent {
  @Input() fleetName: string | null | undefined = '';
  @Input() clientName: string | null | undefined = '';
  @Input() clientFiscalCode: string | null | undefined = '';
  @Input() cnaeDescription: string | null | undefined = '';

  cardLayout = this.breakpointObserver.observe();

  constructor(private breakpointObserver: IBreakPointObserverSrv) {
  }
}
