<button
  type="button"
  mat-mini-fab
  matTooltip="Descarga manual"
  i18n-matTooltip="@@app.header.download.manual.tooltip.label"
  aria-label="Descarga manual"
  i18n-aria-label="@@app.header.download.manual.arial.label"
  (click)="onDownloadHelp()"
>
  <mat-icon>help</mat-icon>
</button>
