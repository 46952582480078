import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IButtonActionData } from '@howdeniberia/core-front';
import { IDetailedEndorsement } from 'src/app/core/models/ida/production';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { EndorsementSummaryDialogComponent } from 'src/app/features/endorsements/endorsement-summary-dialog/pages/endorsement-summary-dialog.component';
import { SubSink } from 'subsink';
import { DetailedEndorsementMapper } from '../mappers';
import { PolicyEditEndorsementsViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-endorsements',
  templateUrl: './policy-edit-endorsements.component.html',
  styleUrls: ['./policy-edit-endorsements.component.scss']
})
export class PolicyEditEndorsementsComponent implements OnChanges, OnDestroy {
  model = new PolicyEditEndorsementsViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.uiBlockerSrv.block();

    this.policySrv.getEndorsements(this.policyId).subscribe({
      next: (data: Array<IDetailedEndorsement>) => this.model.data = DetailedEndorsementMapper.map(data),
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    const inputData = {
      policyId: this.policyId,
      endorsementId: event.row.endorsementId
    };
    const dialogRef = this.dialog.open(EndorsementSummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }
}
