import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PolicyClass } from 'src/app/core/enums';
import { IBookingModeSearchRequest } from 'src/app/core/models/api/booking-modes';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IOfficeService } from 'src/app/core/services/api/offices';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IReportsService } from 'src/app/core/services/api/reports';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { PolicyReportMapper } from '../mappers';
import { PolicyReportViewModel } from '../models';

@Component({
  selector: 'howden-policy-report',
  templateUrl: './policy-report.component.html',
  styleUrls: ['./policy-report.component.scss']
})
export class PolicyReportComponent implements OnInit {
  model = new PolicyReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private mastersSrv: IMasterService,
    private officesSrv: IOfficeService,
    private bookingModeSrv: IBookingModeService,
    private policySrv: IPolicyService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const policyFilter = {
      fleetId: fleetId,
      policyClasses: [PolicyClass.Colective.toString(), PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize
    } as IPolicySearchRequest;
    const bookingModeFilter = <IBookingModeSearchRequest>{ pageSize: 99999, fleetId: fleetId };

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getCompanies(fleetId),
      this.policySrv.search(policyFilter),
      this.policySrv.getPolicyClasses(),
      this.mastersSrv.getPolicyStatuses(),
      this.officesSrv.getOffices(),
      this.bookingModeSrv.search(bookingModeFilter),
      this.fleetSrv.getVehicleTypes(fleetId),
      this.fleetSrv.getVehicleUsages(fleetId)
    ]).subscribe(([
      fleetCompanies,
      rootPolicies,
      policyClasses,
      policyStatuses,
      offices,
      bookingModes,
      vehicleTypes,
      vehicleUsages
    ]) => {
      this.model.fleetCompanies = fleetCompanies;
      this.model.rootPolicies = rootPolicies.data.map(x => <IItemSelection>{ id: x.policyId, description: `${x.policyNumber}-${x.riskDescription}` });
      this.model.policyClasses = policyClasses.map(x => <IItemSelection>{ id: x.policyClassId, description: x.description });
      this.model.statuses = policyStatuses.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.model.offices = offices.map(x => <IItemSelection>{ id: x.officeId, description: x.alias });
      this.model.bookingModes = bookingModes.data.map(x => <IItemSelection>{ id: x.bookingModeId, description: x.description });
      this.model.vehicleTypes = vehicleTypes.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.model.vehicleUsages = vehicleUsages.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = PolicyReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.policies(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_POLIZAS', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
