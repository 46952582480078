import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { PolicyNumberValidator } from 'src/app/shared/validators';
import { SubSink } from 'subsink';
import { PolicyEditCertificateReplacementMapper } from '../mappers';
import { PolicyEditCertificateReplacementViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-certificate-replacement-dialog',
  templateUrl: './policy-edit-certificate-replacement-dialog.component.html',
  styleUrls: ['./policy-edit-certificate-replacement-dialog.component.scss']
})
export class PolicyEditCertificateReplacementDialogComponent implements OnInit, OnDestroy {
  model: PolicyEditCertificateReplacementViewModel;
  hasSameHolder = false;

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditCertificateReplacementDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private policySrv: IPolicyService
  ) {
    const validator = new PolicyNumberValidator(this.policySrv, this.inputData.insuranceCompanyId);

    this.model = new PolicyEditCertificateReplacementViewModel(validator);
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    const policyFilter = {
      pageNumber: 0,
      fleetId: fleetId,
      policyClasses: [PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize,
      statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString()]
    } as IPolicySearchRequest;

    forkJoin([
      this.policySrv.get(this.inputData.id),
      this.policySrv.search(policyFilter),
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.isBookingModeRequired(fleetId)
    ]).subscribe(([policy, rootPolicies, companies, isBmRequired]) => {
      this.model.originalHolderId = policy.holderId;
      this.model.rootPolicies = rootPolicies.data;
      this.model.fleetCompanies = companies;

      this.onChanges();

      // Default holder
      if (companies.length === 1) {
        this.model.holderId = companies[0].companyId;
      }

      // Booking mode required
      const isBookingModeRequired = isBmRequired.result as boolean;

      if (isBookingModeRequired) {
        this.model.getControl(this.model.C_BOOKING_MODE_ID).addValidators([Validators.required]);
        this.model.getControl(this.model.C_BOOKING_MODE_ID).updateValueAndValidity();
      }

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const id = this.inputData.id as string;
    const request = PolicyEditCertificateReplacementMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.certificateReplacement(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);

    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_HOLDER_ID).valueChanges.subscribe((value: string) => {
      this.hasSameHolder = false;
      const holder = this.model.fleetCompanies.find(x => x.companyId === value);

      if (holder) {
        this.hasSameHolder = holder.companyId === this.model.originalHolderId;
      }
    });

    this._subscriptions.sink = this.model.getControl(this.model.C_TARGET_ROOT_POLICY_ID).valueChanges.pipe(
      tap((value: string) => {
        const targetRootPolicyId: string = this.model.targetRootPolicyId;

        this.model.getControl(this.model.C_BOOKING_MODE_ID).reset();
        this.model.bookingModes = [];

        const rootPolicy = this.model.rootPolicies.find(x => x.policyId === targetRootPolicyId);

        if (rootPolicy) {
          this.policySrv.getBookingModes(value).subscribe((bms: Array<IBookingModeSearchResult>) => {
            this.model.bookingModes = bms;

            if (bms.length === 1) {
              this.model.bookingModeId = bms[0].bookingModeId as string;
            }
          });
        }
      })
    ).subscribe();
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
