import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, forkJoin, map, pairwise } from 'rxjs';
import { IClaimDashboardResponse } from 'src/app/core/models/api/dashboards';
import { IDashboardService } from 'src/app/core/services/api/dashboards';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IBreakPointObserverSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';
import { ClaimsDashboardMapper } from '../mappers';
import { ClaimsDashboardViewModel } from '../models';

@Component({
  selector: 'howden-claims-dashboard',
  templateUrl: './claims-dashboard.component.html',
  styleUrls: ['./claims-dashboard.component.scss']
})
export class ClaimsDashboardComponent implements OnInit, OnDestroy {
  model = new ClaimsDashboardViewModel();

  cardLayout = this.breakpointObserver.observe();

  private _subscriptions = new SubSink();

  constructor(
    private breakpointObserver: IBreakPointObserverSrv,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private dashboardSrv: IDashboardService
  ) {
  }

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onFilter(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = ClaimsDashboardMapper.map(this.model);

    this.uiBlockerSrv.block();

    this.onChanges();

    this.clearData();

    forkJoin([
      this.fleetSrv.getDetails(fleetId),
      this.dashboardSrv.getClaimsDashboard(fleetId, request),
      this.dashboardSrv.getClaimsDashboardPivots(fleetId, request)
    ]).subscribe(([
      fleet,
      dashboardData,
      pivotData
    ]) => {
      this.model.fleet = fleet;
      this.model.dashboardData = dashboardData;
      this.model.pivotData = pivotData;

      this.initializeMiniCards(dashboardData);

      this.uiBlockerSrv.unblock();
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.form.valueChanges.pipe(
      pairwise(),
      map(([oldState, newState]) => {
        const changes: any = {};
        for (const key in newState) {
          if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
            changes[key] = newState[key];
          }
        }
        return changes;
      }),
      filter((changes: any) => Object.keys(changes).length !== 0)
    ).subscribe(() => this.clearData());
  }

  private initializeMiniCards(response: IClaimDashboardResponse) {
    this.model.miniCardData = [{
      title: $localize`:@@app.dashboards.claims.mini.cards.claims.title:Siniestros`,
      value: response.claimsOnCurrentYear,
      color: 'primary',
      duration: $localize`:@@app.dashboards.claims.mini.cards.claims.duration:número de siniestros registrados`
    } as IMiniCardData, {
      title: $localize`:@@app.dashboards.claims.mini.cards.risks.title:Riesgos activos`,
      value: response.activeRiskCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.claims.mini.cards.risks.duration:número de riesgos activos actualmente`
    } as IMiniCardData, {
      title: $localize`:@@app.dashboards.claims.mini.cards.policy.summary.title:Resumen pólizas`,
      value: response.policySummary,
      color: 'primary',
      duration: $localize`:@@app.dashboards.claims.mini.cards.policy.summary.duration:número de altas y bajas en el mes actual`
    } as IMiniCardData, {
      title: $localize`:@@app.dashboards.claims.mini.cards.risk.summary.title:Resumen riesgos`,
      value: response.riskSummary,
      color: 'primary',
      duration: $localize`:@@app.dashboards.claims.mini.cards.risk.summary.duration:número de altas y bajas en el mes actual`
    } as IMiniCardData];
  }

  private clearData(): void {
    this.model.fleet = null;
    this.model.dashboardData = null;
    this.model.pivotData = null;
    this.model.miniCardData = [];
  }
}
