import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IKeyValue } from '@howdeniberia/core-front';
import { BillingGroupRuleType } from 'src/app/core/enums';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { ICirculationArea, IGoodsType, IMovementArea } from 'src/app/core/models/api/units';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { RuleType } from 'src/app/core/models/ui';
import { BillingGroupUtils } from 'src/app/shared/utils';

export class PolicyEditBillingGroupRuleViewModel {
  private _form: FormGroup;
  private _bookingModes: Array<IBookingModeSearchResult> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _vehicleTypes: Array<IMasterValue> = [];
  private _vehicleUsages: Array<IMasterValue> = [];
  private _movementAreas: Array<IMovementArea> = [];
  private _circulationAreas: Array<ICirculationArea> = [];
  private _goodsTypes: Array<IGoodsType> = [];
  private _ruleTypes: Array<RuleType> = BillingGroupUtils.getRuleTypes();
  private _referenceValues: Array<IKeyValue> = [];

  readonly C_ID = 'ruleId';
  readonly C_TYPE = 'ruleType';
  readonly C_VALUE_ID = 'valueId';
  readonly C_DIRECT_VALUE = 'directValue';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, []),
      [this.C_TYPE]: new FormControl(null, [Validators.required]),
      [this.C_VALUE_ID]: new FormControl(null, []),
      [this.C_DIRECT_VALUE]: new FormControl(null, [])
    });
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingModeSearchResult>) {
    this._bookingModes = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(value: Array<IFleetCompany>) {
    this._fleetCompanies = value;
  }

  get vehicleTypes(): Array<IMasterValue> {
    return this._vehicleTypes;
  }

  set vehicleTypes(value: Array<IMasterValue>) {
    this._vehicleTypes = value;
  }

  get vehicleUsages(): Array<IMasterValue> {
    return this._vehicleUsages;
  }

  set vehicleUsages(value: Array<IMasterValue>) {
    this._vehicleUsages = value;
  }

  get movementAreas(): Array<IMovementArea> {
    return this._movementAreas;
  }

  set movementAreas(value: Array<IMovementArea>) {
    this._movementAreas = value;
  }

  get circulationAreas(): Array<ICirculationArea> {
    return this._circulationAreas;
  }

  set circulationAreas(value: Array<ICirculationArea>) {
    this._circulationAreas = value;
  }

  get goodsTypes(): Array<IGoodsType> {
    return this._goodsTypes;
  }

  set goodsTypes(value: Array<IGoodsType>) {
    this._goodsTypes = value;
  }

  get ruleTypes(): Array<RuleType> {
    return this._ruleTypes;
  }

  set ruleTypes(value: Array<RuleType>) {
    this._ruleTypes = value;
  }

  get referenceValues(): Array<IKeyValue> {
    return this._referenceValues;
  }

  set referenceValues(value: Array<IKeyValue>) {
    this._referenceValues = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get ruleId(): string {
    return this.getControl(this.C_ID).value;
  }

  set ruleId(value: string) {
    this.getControl(this.C_ID).setValue(value);
  }

  get type(): BillingGroupRuleType {
    return this.getControl(this.C_TYPE).value;
  }

  set type(value: BillingGroupRuleType) {
    this.getControl(this.C_TYPE).setValue(value);
  }

  get valueId(): string {
    return this.getControl(this.C_VALUE_ID).value;
  }

  set valueId(value: string) {
    this.getControl(this.C_VALUE_ID).setValue(value);
  }

  get directValue(): string {
    return this.getControl(this.C_DIRECT_VALUE).value;
  }

  set directValue(value: string) {
    this.getControl(this.C_DIRECT_VALUE).setValue(value);
  }

  get showDirectValue(): boolean {
    return this.type === BillingGroupRuleType.UnitBonusGroup;
  }
}
