import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';
import { PlateNumberValidator } from 'src/app/shared/validators';

export class UnitPlateNumberChangeViewModel {
  private _form: FormGroup;
  private _originalPlateNumber?: string;
  private _plateNumberControl: FormControl;

  readonly C_PLATE_NUMBER = 'plateNumber';

  constructor(private plateNumberValidator: PlateNumberValidator) {
    this._plateNumberControl = new FormControl('', {
      validators: [StringValidators.noSpacesEndStart, Validators.required, Validators.pattern(/^[0-9A-Za-z]+$/)],
      asyncValidators: [this.plateNumberValidator.validate.bind(this.plateNumberValidator)],
      updateOn: 'blur'
    });
    this._form = new FormGroup({ [this.C_PLATE_NUMBER]: this._plateNumberControl });
  }

  get form(): FormGroup {
    return this._form;
  }

  get plateNumberControl(): FormControl {
    return this._plateNumberControl;
  }

  get originalPlateNumber(): string | undefined {
    return this._originalPlateNumber;
  }

  set originalPlateNumber(value: string | undefined) {
    this._originalPlateNumber = value;
  }

  get plateNumber(): string | undefined {
    return this._form.get(this.C_PLATE_NUMBER)?.value;
  }

  set plateNumber(value: string | undefined) {
    this._form.get(this.C_PLATE_NUMBER)?.setValue(value);
  }
}
