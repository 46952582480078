import { IPolicyHistoryReportRequest } from 'src/app/core/models/api/reports';
import { PolicyHistoryReportViewModel } from '../models';

export abstract class PolicyHistoryReportMapper {
  static mapForGeneration(activeFleet: string, source: PolicyHistoryReportViewModel): IPolicyHistoryReportRequest {
    return {
      fleetId: activeFleet,
      query: source.query,
      startDate: source.startDate,
      endDate: source.endDate,
      holderId: source.holderId,
      insuredId: source.insuredId,
      owner: source.owner,
      rootPolicies: source.rootPolicies.filter(x => x.selected === true).map(x => x.id),
      statuses: source.statuses.filter(x => x.selected === true).map(x => x.id),
      policyClasses: source.policyClasses.filter(x => x.selected === true).map(x => x.id),
      bookingModes: source.bookingModes.filter(x => x.selected === true).map(x => x.id),
      offices: source.offices.filter(x => x.selected === true).map(x => x.id),
      vehicleTypes: source.vehicleTypes.filter(x => x.selected === true).map(x => x.id),
      vehicleUsages: source.vehicleUsages.filter(x => x.selected === true).map(x => x.id)
    } as IPolicyHistoryReportRequest;
  }
}
