import { IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IEmptyRootPolicyNotification, IPolicyExpirationNotification } from 'src/app/core/models/api/notifications';

export abstract class IFleetNotificationsService {
  abstract getNotificationCount(): Observable<IResponse<number>>;
  abstract getPolicyExpirationNotifications(): Observable<Array<IPolicyExpirationNotification>>;
  abstract exportPolicyExpirationNotifications(): Observable<Blob>;
  abstract getEmptyRootPolicyNotifications(): Observable<Array<IEmptyRootPolicyNotification>>;
  abstract exportEmptyRootPolicyNotifications(): Observable<Blob>;
}
