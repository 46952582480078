import { IChangePolicyRisk } from 'src/app/core/models/api/policies';
import { PolicyEditChangeRiskViewModel } from '../models';

export abstract class PolicyEditChangeRiskMapper {
  static mapForUpdate(source: PolicyEditChangeRiskViewModel): IChangePolicyRisk {
    return {
      operationDate: source.operationDate,
      riskAlias: source.riskAlias,
      bookingModeId: source.bookingModeId
    } as IChangePolicyRisk;
  }
}
