import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { IEndorsementSummary } from 'src/app/core/models/api/endorsements';
import {
  IBillingModel,
  IBonusType,
  IBonusTypeUpdate,
  IBookingModeChange,
  ICertificateReplacement,
  IChangePolicyHolder,
  IChangePolicyRisk,
  IColectivePolicyReplacement,
  IContractSummary,
  ICreateEndorsement,
  ICreateRootPolicy,
  IGuessIndividualBonus,
  IGuessIndividualBonusResponse,
  IPolicy,
  IPolicyAssignationResponse,
  IPolicyBilling,
  IPolicyBillingGroup,
  IPolicyBillingGroupBonus,
  IPolicyBillingGroupRule,
  IPolicyCancellation,
  IPolicyClass,
  IPolicyImport,
  IPolicyIncidence,
  IPolicyNumber,
  IPolicyParalization,
  IPolicyRegularization,
  IPolicyRisk,
  IPolicyRiskSummary,
  IPolicySearchRequest,
  IPolicySearchResult,
  IPolicySummary,
  IPolicyUpdate,
  IReceiptTarget
} from 'src/app/core/models/api/policies';
import { IAssignRisks } from 'src/app/core/models/api/policies/assign-risks';
import { IPolicyImportRequest } from 'src/app/core/models/api/policies/policy-import-request';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IDocument } from 'src/app/core/models/ida/document-manager';
import { IDetailedReceipt } from 'src/app/core/models/ida/financial';
import {
  ICancelReplacement,
  IDetailedEndorsement,
  IPolicyRiskWarranty,
  IUpdatePolicyOffice,
  IUpsertRiskWarranty
} from 'src/app/core/models/ida/production';

export abstract class IPolicyService {
  abstract getBonusTypes(): Observable<Array<IBonusType>>;
  abstract getPolicyClasses(): Observable<Array<IPolicyClass>>;
  abstract getRootPolicyClasses(): Observable<Array<IPolicyClass>>;
  abstract getBillingModels(): Observable<Array<IBillingModel>>;
  abstract getReceiptTargets(): Observable<Array<IReceiptTarget>>;
  abstract getTransferModes(): Observable<Array<IKeyValuePair<string, string>>>;
  abstract getHolderReferences(): Observable<Array<IKeyValuePair<string, string>>>;
  abstract getInsuredReferences(): Observable<Array<IKeyValuePair<string, string>>>;
  abstract get(id: string): Observable<IPolicy>;
  abstract getSummary(id: string): Observable<IPolicySummary>;
  abstract getSummaryHeader(id: string): Observable<IPolicySummary>;
  abstract getCertificates(id: string): Observable<Array<IPolicySummary>>;
  abstract getClaims(id: string): Observable<Array<IDetailedClaim>>;
  abstract getIncidences(id: string): Observable<Array<IPolicyIncidence>>;
  abstract getEndorsements(id: string): Observable<Array<IDetailedEndorsement>>;
  abstract getEndorsementSummary(policyId: string, endorsementId: string): Observable<IEndorsementSummary>;
  abstract getReceipts(id: string): Observable<Array<IDetailedReceipt>>;
  abstract getPolicyRisks(id: string): Observable<Array<IPolicyRiskSummary>>;
  abstract getPolicyRisk(id: string, policyRiskId: string): Observable<IPolicyRisk>;
  abstract getPolicyRiskWarranties(id: string, policyRiskId: string): Observable<Array<IPolicyRiskWarranty>>;
  abstract getBookingModes(id: string): Observable<Array<IBookingModeSearchResult>>;
  abstract hasWaitingProcesses(id: string): Observable<IResponse<boolean>>;
  abstract update(id: string, request: IPolicyUpdate): Observable<void>;
  abstract updatePolicyNumber(id: string, request: IPolicyNumber): Observable<void>;
  abstract updatePolicyOffice(id: string, request: IUpdatePolicyOffice): Observable<void>;
  abstract updatePolicyHolder(id: string, request: IChangePolicyHolder): Observable<void>;
  abstract updateBonusType(id: string, request: IBonusTypeUpdate): Observable<void>;
  abstract updatePolicyRiskWarranty(id: string, policyRiskId: string, warranty: IUpsertRiskWarranty): Observable<void>;
  abstract changePolicyRisk(id: string, request: IChangePolicyRisk): Observable<void>;
  abstract cancel(id: string, request: IPolicyCancellation): Observable<void>;
  abstract reactivate(id: string): Observable<void>;
  abstract delete(id: string): Observable<void>;
  abstract updatePolicyRisk(id: string, policyRiskId: string, policyRisk: IPolicyRisk): Observable<void>;
  abstract deletePolicyRisk(id: string, policyRiskId: string): Observable<void>;
  abstract bookingModeChange(id: string, policyRiskId: string, request: IBookingModeChange): Observable<void>;
  abstract createRootPolicy(request: ICreateRootPolicy): Observable<void>;
  abstract search(request: IPolicySearchRequest): Observable<IPageOf<IPolicySearchResult>>;
  abstract isPolicyNumberAvailable(insuranceCompanyId: string, policyNumber: string): Observable<IResponse<boolean>>;
  abstract getParalizations(id: string): Observable<Array<IPolicyParalization>>;
  abstract getParalization(id: string, paralizationId: string): Observable<IPolicyParalization>;
  abstract saveParalization(id: string, paralization: IPolicyParalization): Observable<void>;
  abstract deleteParalization(id: string, paralizationId: string): Observable<void>;
  abstract getBillingGroups(id: string): Observable<Array<IPolicyBillingGroup>>;
  abstract getBillingGroup(id: string, billingGroupId: string): Observable<IPolicyBillingGroup>;
  abstract saveBillingGroup(id: string, billingGroup: IPolicyBillingGroup): Observable<void>;
  abstract deleteBillingGroup(id: string, billingGroupId: string): Observable<void>;
  abstract getBillingGroupRules(id: string, billingGroupId: string): Observable<Array<IPolicyBillingGroupRule>>;
  abstract getBillingGroupRule(id: string, billingGroupId: string, ruleId: string): Observable<IPolicyBillingGroupRule>;
  abstract saveBillingGroupRule(id: string, billingGroupId: string, rule: IPolicyBillingGroupRule): Observable<void>;
  abstract deleteBillingGroupRule(id: string, billingGroupId: string, rileId: string): Observable<void>;
  abstract getBillingGroupBonuses(id: string, billingGroupId: string): Observable<Array<IPolicyBillingGroupBonus>>;
  abstract getBillingGroupBonus(id: string, billingGroupId: string, bonusId: string): Observable<IPolicyBillingGroupBonus>;
  abstract saveBillingGroupBonus(id: string, billingGroupId: string, bonus: IPolicyBillingGroupBonus): Observable<void>;
  abstract deleteBillingGroupBonus(id: string, billingGroupId: string, bonusId: string): Observable<void>;
  abstract getDocuments(id: string): Observable<Array<IDocument>>;
  abstract saveDocument(id: string, document: FormData): Observable<void>;
  abstract downloadDocument(id: string, documentId: string): Observable<Blob>;
  abstract deleteDocument(id: string, documentId: string): Observable<void>;
  abstract getPoliciesToImport(rootPolicyId: string): Observable<Array<IPolicyImport>>;
  abstract importPolicies(rootPolicyId: string, request: IPolicyImportRequest): Observable<void>;
  abstract certificateReplacement(id: string, request: ICertificateReplacement): Observable<void>;
  abstract colectiveReplacement(id: string, request: IColectivePolicyReplacement): Observable<void>;
  abstract cancelReplacement(id: string, request: ICancelReplacement): Observable<void>;
  abstract createEndorsement(id: string, request: ICreateEndorsement): Observable<void>;
  abstract getContract(id: string): Observable<IContractSummary>;
  abstract assignRisks(id: string, request: IAssignRisks): Observable<void>;
  abstract guessIndividualBonus(id: string, request: IGuessIndividualBonus): Observable<IGuessIndividualBonusResponse>;
  abstract policyAssignation(id: string, request: FormData): Observable<IPolicyAssignationResponse>;
  abstract getInitialState(id: string, year: number): Observable<IPolicyBilling>
  abstract getBillingInfo(id: string, date: string): Observable<IPolicyBilling>
  abstract getRegularizationInfo(id: string, date: string): Observable<IPolicyRegularization>
}
