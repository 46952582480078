import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IClaimSummary } from 'src/app/core/models/api/claims';
import { IClaimService } from 'src/app/core/services/api/claims';

@Component({
  selector: 'howden-claim-summary-dialog',
  templateUrl: './claim-summary-dialog.component.html',
  styleUrls: ['./claim-summary-dialog.component.scss']
})
export class ClaimSummaryDialogComponent implements OnInit {
  claim: IClaimSummary = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<ClaimSummaryDialogComponent>,
    private claimsSrv: IClaimService
  ) {
  }

  ngOnInit(): void {
    const id = this.inputData.id as string;

    this.claimsSrv.getSummary(id).subscribe((data: IClaimSummary) => {
      this.claim = data ?? {};
    });
  }

  onClose(): void {
    this.closeDialog(DialogResultTypes.OK);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
