@if (pivotTable) {
  <div class="pvtTable-container">
    <table class="pvtTable">
      <thead>
        <tr>
          <th class="pvtRowDimensionsHeader" colspan="2"></th>
          @for (columnAggregate of pivotTable.columnAggregates; track columnAggregate) {
            <th class="pvtColumn">{{ columnAggregate.value }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of pivotTable.rows; track row; let i = $index) {
          <tr>
            @if (shouldPlotColumn(pivotTable.rows, i)) {
              <th class="pvtRow primary-row-group" [attr.rowspan]="calculateRowSpan(pivotTable.rows, row.value.item1)">
                {{ row.value.item1 }}
              </th>
            }
            <th class="pvtRow secondary-row-group">{{ row.value.item2 }}</th>
            @for (columnAggregate of pivotTable.columnAggregates; track columnAggregate) {
              <td>
                {{ formatAggregate(row.value.item1, row.value.item2, row.columnAggregates, columnAggregate) }}
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
}
