import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';

export class UnitSummaryPoliciesViewModel {
  constructor(public data: Array<IPolicyRiskSummary>) { }

  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.risks.insuranceCcompany.column:Aseguradora`
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.risks.policyNumber.column:Nº. Póliza`
    },
    {
      fieldName: 'insured',
      fieldHeader: $localize`:@@app.policies.risks.insured.column:Asegurado`
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.policies.risks.product.column:Producto`
    },
    {
      fieldName: 'policyState',
      fieldHeader: $localize`:@@app.policies.risks.policyState.column:Estado póliza`
    },
    {
      fieldName: 'activationDate',
      fieldHeader: $localize`:@@app.policies.risks.policyRiskActivationDate.column:F. Alta`,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'deactivationDate',
      fieldHeader: $localize`:@@app.policies.risks.policyRiskDeactivationDate.column:F. Baja`,
      pipeToApply: ColumnPipeTypes.DATE
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.units.summary.actions.goto.policy:Ir a póliza`
  }];

  isNotEmpty() { return this.data && this.data.length > 0; }
}
