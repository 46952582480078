<form class="margin-top-10" [formGroup]="model.form">
  <div class="flex f-gap--10px margin-bottom-10">
    <howden-decimal
      class="f-basis--2"
      [formControlName]="model.C_YEAR"
      [allowNegative]="false"
      [precision]="0"
      i18n-label="@@app.policies.edit.initial.state.year.label"
      label="Año"
    ></howden-decimal>

    <div class="mat-form-field inline">
      <div class="mat-form-field-wrapper">
        <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onFilter()">
          <span i18n="@@app.core.actions.accept">Aceptar</span>
        </button>
      </div>
    </div>
  </div>
</form>

@if (hasData) {
  <div class="flex">
    <h4 i18n="@@app.policies.edit.initial.state.summary.section.label">Resúmenes</h4>
  </div>
}

@for (group of model.groups; track group) {
  <div class="flex">
    <div class="f-basis--12">
      <howden-policy-edit-billing-info [group]="group"></howden-policy-edit-billing-info>
    </div>
  </div>
}

@if (hasData) {
  <div class="flex">
    <h4 i18n="@@app.policies.edit.initial.state.details.section.label">Detalles</h4>
  </div>

  <div class="flex margin-top-10">
    <howden-table
      class="small-font"
      keyField="policyId"
      paginable="local"
      sortable="local"
      filterable="global-local"
      [pageSize]="10"
      [cols]="model.columns"
      [data]="model.history"
      [buttons]="model.buttons"
      >
    </howden-table>
  </div>
}
