import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IPolicyAssignationFileItem, IPolicySearchResult } from 'src/app/core/models/api/policies';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyAssignationViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;

  readonly C_ROOT_POLICY_ID = 'rootPolicyId';
  readonly C_CONCAT_POLICY_NUMBER = 'concatPolicyNumber';
  readonly C_POLICY_NUMBER_SEPARATOR = 'policyNumberSeparator';
  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_ROOT_POLICY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.rootPolicies, 'policyId')]),
      [this.C_CONCAT_POLICY_NUMBER]: new FormControl(false, []),
      [this.C_POLICY_NUMBER_SEPARATOR]: new FormControl('/', []),
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });

    this.getControl(this.C_POLICY_NUMBER_SEPARATOR).disable();

    this._filteredRootPolicies = this.getControl(this.C_ROOT_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );
  }

  assignationResults: IPolicyAssignationFileItem[] = [];
  assignationResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'riskAlias',
      fieldHeader: $localize`:@@app.policies.assignation.risk.column:Riesgo`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.assignation.policyNumber.column:Nº de póliza`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.assignation.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IPolicySearchResult>) {
    this._rootPolicies = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup { return this._form; }

  get rootPolicyId(): string | null | undefined {
    return this.getControl(this.C_ROOT_POLICY_ID).value;
  }

  set rootPolicyId(value: string | null | undefined) {
    this.getControl(this.C_ROOT_POLICY_ID).setValue(value);
  }

  get concatPolicyNumber(): boolean {
    return this.getControl(this.C_CONCAT_POLICY_NUMBER).value;
  }

  set concatPolicyNumber(value: boolean) {
    this.getControl(this.C_CONCAT_POLICY_NUMBER).setValue(value);
  }

  get policyNumberSeparator(): string | null | undefined {
    return this.getControl(this.C_POLICY_NUMBER_SEPARATOR).value;
  }

  set policyNumberSeparator(value: string | null | undefined) {
    this.getControl(this.C_POLICY_NUMBER_SEPARATOR).setValue(value);
  }

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }
}
