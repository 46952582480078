import { ICreateRootPolicy } from 'src/app/core/models/api/policies';
import { CreateRootPolicyViewModel } from '../models';

export abstract class CreateRootPolicyMapper {
  static mapForUpdate(source: CreateRootPolicyViewModel): ICreateRootPolicy {
    return {
      fleetId: source.fleetId,
      policyClass: parseInt(source.policyClass),
      policyBonusType: parseInt(source.policyBonusType),
      collectionManagerId: source.collectionManagerId,
      description: source.description,
      officeCode: source.officeCode,
      currencyId: source.currencyId,
      insuranceCompanyId: source.insuranceCompanyId,
      industryId: source.industryId,
      productId: source.productId,
      firstEffectDate: source.firstEffectDate,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      mediationCodeId: source.mediationCodeId,
      duration: parseInt(source.duration),
      holderId: source.holderId,
      paymentType: parseInt(source.paymentType),
      billingModel: parseInt(source.billingModel),
      regularizationPeriodId: source.regularizationPeriodId,
      doubleBilling: source.doubleBilling,
      commissionRate: source.commissionRate,
      receiptsOn: parseInt(source.receiptsOn)
    } as ICreateRootPolicy;
  }
}
