import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyExpirationNotification } from 'src/app/core/models/api/notifications';

export class PolicyExpirationNotificationsViewModel {
  data: IPolicyExpirationNotification[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'fleetName',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.fleetName.column:Flota`,
      canSort: true,
      width: '300px'
    },
    {
      fieldName: 'rootPolicyInsuranceCompany',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.rootPolicyInsuranceCompany.column:Aseguradora`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'rootPolicyNumber',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.rootPolicyNumber.column:Póliza madre`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'rootPolicyName',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.rootPolicyName.column:Descripción`,
      canSort: true,
      width: '300px'
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.expirationDate.column:F.Vencimiento`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      width: '100px'
    },
    {
      fieldName: 'office',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.office.column:Oficina`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'executiveName',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.executiveName.column:Ejecutivo`,
      canSort: true,
      width: '250px'
    },
    {
      fieldName: 'managerName',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.managerName.column:Gestor de cuenta`,
      canSort: true,
      width: '250px'
    },
    {
      fieldName: 'firstTechnicianName',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.firstTechnicianName.column:Técnico`,
      canSort: true,
      width: '250px'
    },
    {
      fieldName: 'secondTechnicianame',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.secondTechnicianame.column:Técnico auxiliar`,
      canSort: true,
      width: '250px'
    },
    {
      fieldName: 'policyExpirationCount',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.policyExpirationCount.column:Pólizas a vencimiento`,
      pipeToApply: ColumnPipeTypes.NUMBER,
      aligment: ColumnAligment.CENTER,
      width: '100px'
    },
    {
      fieldName: 'expirationDaysCount',
      fieldHeader: $localize`:@@app.notifications.policy.expirations.expirationDaysCount.column:Días a vencimiento`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.NUMBER,
      aligment: ColumnAligment.CENTER,
      width: '100px'
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
