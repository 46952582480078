import { IRootPolicyReportRequest } from 'src/app/core/models/api/reports';
import { RootPolicyReportViewModel } from '../models';

export abstract class RootPolicyReportMapper {
  static mapForGeneration(source: RootPolicyReportViewModel): IRootPolicyReportRequest {
    return {
      fleetId: source.fleetId,
      policyStatuses: source.statuses.filter(x => x.selected === true).map(x => x.id),
      offices: source.offices.filter(x => x.selected === true).map(x => x.id),
      startDate: source.startDate,
      endDate: source.endDate
    } as IRootPolicyReportRequest;
  }
}
