import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IUser } from 'src/app/core/models/ida/company';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getUsers(): Observable<Array<IUser>> {
    return this.httpClient.get<Array<IUser>>(APP_CONSTANTS.API_NET_FLOTAS, '/users');
  }
}
