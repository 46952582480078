import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { Limits } from 'src/app/shared/utils';
import { PolicyNumberValidator, forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyEditCertificateReplacementViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _bookingModes: Array<IBookingModeSearchResult> = [];
  private _originalHolderId = '';
  private _isBookingModeRequired = false;

  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;

  readonly C_TARGET_ROOT_POLICY_ID = 'targetRootPolicyId';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_POLICY_NUMBER = 'policyNumber';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';
  readonly C_KEEP_CURRENT_INSURED = 'keepCurrentInsured';
  readonly C_NET_BONUS = 'netBonus';
  readonly C_TOTAL_BONUS = 'totalBonus';
  readonly C_COMMISSION_RATE = 'commissionRate';

  constructor(private policyNumberValidator: PolicyNumberValidator) {
    const policyNumberControl = new FormControl('', {
      validators: [StringValidators.noSpacesEndStart],
      asyncValidators: [this.policyNumberValidator.validate.bind(this.policyNumberValidator)],
      updateOn: 'blur'
    });

    this._form = new FormGroup({
      [this.C_TARGET_ROOT_POLICY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.rootPolicies, 'policyId')]),
      [this.C_HOLDER_ID]: new FormControl(null, [Validators.required]),
      [this.C_KEEP_CURRENT_INSURED]: new FormControl(false, [Validators.required]),
      [this.C_OPERATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_POLICY_NUMBER]: policyNumberControl,
      [this.C_EXPIRATION_DATE]: new FormControl(null, []),
      [this.C_BOOKING_MODE_ID]: new FormControl(null, []),
      [this.C_NET_BONUS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_TOTAL_BONUS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_COMMISSION_RATE]: new FormControl(null, [Validators.min(0), Validators.max(100)])
    });

    this._filteredRootPolicies = this.getControl(this.C_TARGET_ROOT_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );
  }

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IPolicySearchResult>) {
    this._rootPolicies = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(value: Array<IFleetCompany>) {
    this._fleetCompanies = value;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingModeSearchResult>) {
    this._bookingModes = value;
  }

  get originalHolderId(): string {
    return this._originalHolderId;
  }

  set originalHolderId(value: string) {
    this._originalHolderId = value;
  }

  get isBookingModeRequired(): boolean {
    return this._isBookingModeRequired;
  }

  set isBookingModeRequired(value: boolean) {
    this._isBookingModeRequired = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get targetRootPolicyId(): string {
    return this.getControl(this.C_TARGET_ROOT_POLICY_ID).value;
  }

  set targetRootPolicyId(value: string) {
    this.getControl(this.C_TARGET_ROOT_POLICY_ID).setValue(value);
  }

  get holderId(): string {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get keepCurrentInsured(): boolean {
    return this.getControl(this.C_KEEP_CURRENT_INSURED).value;
  }

  set keepCurrentInsured(value: boolean) {
    this.getControl(this.C_KEEP_CURRENT_INSURED).setValue(value);
  }

  get operationDate(): Date {
    return this.getControl(this.C_OPERATION_DATE).value;
  }

  set operationDate(value: Date) {
    this.getControl(this.C_OPERATION_DATE).setValue(value);
  }

  get policyNumber(): string | undefined {
    return this.getControl(this.C_POLICY_NUMBER).value;
  }

  set policyNumber(value: string | undefined) {
    this.getControl(this.C_POLICY_NUMBER).setValue(value);
  }

  get expirationDate(): Date | undefined {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | undefined) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get bookingModeId(): string | undefined {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | undefined) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }

  get netBonus(): number | undefined {
    return this.getControl(this.C_NET_BONUS).value;
  }

  set netBonus(value: number | undefined) {
    this.getControl(this.C_NET_BONUS).setValue(value);
  }

  get totalBonus(): number | undefined {
    return this.getControl(this.C_TOTAL_BONUS).value;
  }

  set totalBonus(value: number | undefined) {
    this.getControl(this.C_TOTAL_BONUS).setValue(value);
  }

  get commissionRate(): number | undefined {
    return this.getControl(this.C_COMMISSION_RATE).value;
  }

  set commissionRate(value: number | undefined) {
    this.getControl(this.C_COMMISSION_RATE).setValue(value);
  }

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }
}
