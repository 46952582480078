<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex margin-bottom-10">
      <button type="button" class="f-basis--4" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>
    </div>

    <div class="flex">
      <h4 i18n="@@app.reports.fleets.control.fleet.section.label">Seleccione una flota</h4>
    </div>

    <div class="flex">
      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.fleets.control.fleetId.label"
        label="Flota"
        displayField="name"
        keyField="fleetId"
        [selectableItems]="model.fleets"
        [formControlName]="model.C_FLEET_ID"
      >
      </howden-autocomplete>
    </div>

    <div class="flex">
      <div class="f-basis--4">
        <h4 i18n="@@app.reports.fleets.control.itemType.label">Tipo</h4>
        <mat-selection-list>
          @for (itemType of model.itemTypes; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>
    </div>
  </form>
</div>
