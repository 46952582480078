import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';

export class BonusHistoryReportViewModel {
  private _form: FormGroup;
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _insuranceCompanies: Array<IInsuranceCompany> = [];

  readonly C_QUERY = 'query';
  readonly C_EFFECT_DATE = 'effect';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_INSURED_ID = 'insuredId';

  constructor() {
    this._form = new FormGroup({
      [this.C_QUERY]: new FormControl(null, []),
      [this.C_EFFECT_DATE]: new FormControl(null, []),
      [this.C_EXPIRATION_DATE]: new FormControl(null, []),
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, []),
      [this.C_HOLDER_ID]: new FormControl(null, []),
      [this.C_INSURED_ID]: new FormControl(null, [])
    });
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(value: Array<IFleetCompany>) {
    this._fleetCompanies = value;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get query(): string | null {
    return this.getControl(this.C_QUERY).value;
  }

  set query(value: string | null) {
    this.getControl(this.C_QUERY).setValue(value);
  }

  get effectDate(): Date | null {
    return this.getControl(this.C_EFFECT_DATE).value;
  }

  set effectDate(value: Date | null) {
    this.getControl(this.C_EFFECT_DATE).setValue(value);
  }

  get expirationDate(): Date | null {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | null) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get insuranceCompanyId(): string | null {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string | null) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get holderId(): string | null {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string | null) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get insuredId(): string | null {
    return this.getControl(this.C_INSURED_ID).value;
  }

  set insuredId(value: string | null) {
    this.getControl(this.C_INSURED_ID).setValue(value);
  }
}
