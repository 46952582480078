<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.billing.groups.title">Grupos de facturación</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--10">
          <mat-label i18n="@@app.policies.edit.billing.groups.name.label">Nombre</mat-label>
          <input matInput [formControlName]="model.C_NAME" maxlength="100" />
        </mat-form-field>

        <howden-decimal
          class="f-basis--2"
          [allowNegative]="false"
          [allowZero]="true"
          [precision]="0"
          [formControlName]="model.C_PRIORITY"
          i18n-label="@@app.policies.edit.billing.groups.priority.label"
          label="Prioridad"
        ></howden-decimal>

      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
