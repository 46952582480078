import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { ICurrency } from 'src/app/core/models/ida/financial';
import { ICurrencyService } from './currency.contract';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService implements ICurrencyService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getCurrencies(): Observable<Array<ICurrency>> {
    return this.httpClient.get<Array<ICurrency>>(APP_CONSTANTS.API_NET_FLOTAS, `/currencies`);
  }
}
