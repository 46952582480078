import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenDecimalModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { EmptyRootPolicyNotificationsComponent } from './empty-root-policy-notifications/pages/empty-root-policy-notifications.component';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { PolicyExpirationNotificationsComponent } from './policy-expiration-notifications/pages/policy-expiration-notifications.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    NotificationsRoutingModule
  ],
  declarations: [
    PolicyExpirationNotificationsComponent,
    EmptyRootPolicyNotificationsComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService }
  ]
})
export class NotificationsModule {
}
