import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import {
  IBonusHistoryReportRequest,
  IClaimsByCompanyReportRequest,
  IFleetControlReportRequest,
  IFleetStateReportRequest,
  IOperatingCompaniesReportRequest,
  IPolicyHistoryReportRequest,
  IPolicyReportRequest,
  IRegularizationReportRequest,
  IRisksComparedReportRequest,
  IRisksInsuredReportRequest,
  IRootPolicyReportRequest,
  IUnitReportRequest
} from 'src/app/core/models/api/reports';
import { ILTAReportRequest } from 'src/app/core/models/api/reports/lta-report-request';
import { IReportsService } from './reports.contract';

@Injectable({
  providedIn: 'root'
})
export class ReportsService implements IReportsService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  fleets(): Observable<Blob> {
    const uri = `/reports/fleets`;
    return this.httpClient.getBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, { responseType: 'blob' });
  }

  fleetsControl(request: IFleetControlReportRequest): Observable<Blob> {
    return this.postReport(`/reports/fleets-control`, request);
  }

  rootPolicies(request: IRootPolicyReportRequest): Observable<Blob> {
    return this.postReport(`/reports/root-policies`, request);
  }

  units(request: IUnitReportRequest): Observable<Blob> {
    return this.postReport(`/reports/units`, request);
  }

  policies(request: IPolicyReportRequest): Observable<Blob> {
    return this.postReport(`/reports/policies`, request);
  }

  policyHistory(request: IPolicyHistoryReportRequest): Observable<Blob> {
    return this.postReport(`/reports/policy-history`, request);
  }

  bonusHistory(request: IBonusHistoryReportRequest): Observable<Blob> {
    return this.postReport(`/reports/bonus-history`, request);
  }

  fleetState(request: IFleetStateReportRequest): Observable<Blob> {
    return this.postReport(`/reports/fleet-state`, request);
  }

  regularization(request: IRegularizationReportRequest): Observable<Blob> {
    return this.postReport(`/reports/regularization`, request);
  }

  risksCompared(request: IRisksComparedReportRequest): Observable<Blob> {
    return this.postReport(`/reports/risks-compared`, request);
  }

  risksInsured(request: IRisksInsuredReportRequest): Observable<Blob> {
    return this.postReport(`/reports/risks-insured`, request);
  }

  claimsByCompany(request: IClaimsByCompanyReportRequest): Observable<Blob> {
    return this.postReport(`/reports/claims-by-company`, request);
  }

  operatingCompanies(request: IOperatingCompaniesReportRequest): Observable<Blob> {
    return this.postReport(`/reports/operating-companies`, request);
  }

  lta(request: ILTAReportRequest): Observable<Blob> {
    return this.postReport(`/reports/lta`, request);
  }

  private postReport(uri: string, request: any): Observable<Blob> {
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, { responseType: 'blob' });
  }
}
