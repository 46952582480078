import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IFleetSearchResult } from 'src/app/core/models/api/fleets';

export class RootPolicyReportViewModel {
  private _form: FormGroup;
  private _fleets: Array<IFleetSearchResult> = [];
  private _offices: Array<IItemSelection> = [];
  private _statuses: Array<IItemSelection> = [];

  readonly C_FLEET_ID = 'fleetId';
  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_FLEET_ID]: new FormControl(null, []),
      [this.C_START_DATE]: new FormControl(null, []),
      [this.C_END_DATE]: new FormControl(null, [])
    });
  }

  get fleets(): Array<IFleetSearchResult> {
    return this._fleets;
  }

  set fleets(fleets: Array<IFleetSearchResult>) {
    this._fleets = fleets;
  }

  get offices(): Array<IItemSelection> {
    return this._offices;
  }

  set offices(offices: Array<IItemSelection>) {
    this._offices = offices;
  }

  get statuses(): Array<IItemSelection> {
    return this._statuses;
  }

  set statuses(statuses: Array<IItemSelection>) {
    this._statuses = statuses;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get fleetId(): string {
    return this._form.get(this.C_FLEET_ID)?.value;
  }

  set fleetId(value: string) {
    this._form.get(this.C_FLEET_ID)?.setValue(value);
  }

  get startDate(): Date | null {
    return this._form.get(this.C_START_DATE)?.value;
  }

  set startDate(value: Date | null) {
    this._form.get(this.C_START_DATE)?.setValue(value);
  }

  get endDate(): Date | null {
    return this._form.get(this.C_END_DATE)?.value;
  }

  set endDate(value: Date | null) {
    this._form.get(this.C_END_DATE)?.setValue(value);
  }
}
