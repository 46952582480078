import { IUnitSummary } from 'src/app/core/models/api/units';

export class UnitSummaryViewModel {
  constructor(private summary?: IUnitSummary) { }

  get unitId(): string | null | undefined {
    return this.summary?.unitId;
  }

  get plateNumber(): string | null | undefined {
    return this.summary?.plateNumber;
  }

  get carNumber(): string | null | undefined {
    return this.summary?.carNumber;
  }

  get vehicleUsage(): string | null | undefined {
    return this.summary?.vehicleUsage;
  }

  get vehicleType(): string | null | undefined {
    return this.summary?.vehicleType;
  }

  get movementArea(): string | null | undefined {
    return this.summary?.movementArea;
  }

  get registrationDate(): Date | null | undefined {
    return this.summary?.registrationDate;
  }

  get brand(): string | null | undefined {
    return this.summary?.brand;
  }

  get model(): string | null | undefined {
    return this.summary?.model;
  }

  get version(): string | null | undefined {
    return this.summary?.version;
  }

  get value(): number | null | undefined {
    return this.summary?.value;
  }

  get seatNumber1(): number | null | undefined {
    return this.summary?.seatNumber1;
  }

  get seatNumber2(): string | null | undefined {
    return this.summary?.seatNumber2;
  }

  get owner(): string | null | undefined {
    return this.summary?.owner;
  }

  get lessee(): string | null | undefined {
    return this.summary?.lessee;
  }

  get user(): string | null | undefined {
    return this.summary?.user;
  }

  get concession(): string | null | undefined {
    return this.summary?.concession;
  }

  get observations(): string | null | undefined {
    return this.summary?.observations;
  }

  get registrationClassification(): string | null | undefined {
    return this.summary?.registrationClassification;
  }

  get classificationCodeCL(): string | null | undefined {
    return this.summary?.classificationCodeCL;
  }

  get functionalType(): string | null | undefined {
    return this.summary?.functionalType;
  }

  get circulationArea(): string | null | undefined {
    return this.summary?.circulationArea;
  }

  get hasObservations(): boolean {
    return typeof this.observations !== 'undefined' && this.observations !== null && this.observations.length > 0;
  }
}
