import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { ICollectionManager } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyRenewerViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _collectionManagers: Array<ICollectionManager> = [];
  private _paymentTypes: Array<IMasterValue> = [];
  private _policyDurations: Array<IMasterValue> = [];

  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;
  private _filteredCollectionManagers: Observable<Array<ICollectionManager>>;

  readonly C_ROOT_POLICY_ID = 'rootPolicyId';
  readonly C_COLLECTION_MANAGER_ID = 'collectionManagerId';
  readonly C_EFFECT_DATE = 'effectDate';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_PAYMENT_TYPE_ID = 'paymentTypeId';
  readonly C_DURATION = 'duration';
  readonly C_COMMISSION_RATE = 'commissionRate';

  constructor() {
    this._form = new FormGroup({
      [this.C_ROOT_POLICY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.rootPolicies, 'policyId')]),
      [this.C_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_COLLECTION_MANAGER_ID]: new FormControl('', [forbiddenNamesValidator(() => this.collectionManagers, 'collectionManagerId')]),
      [this.C_PAYMENT_TYPE_ID]: new FormControl('', []),
      [this.C_DURATION]: new FormControl('', []),
      [this.C_COMMISSION_RATE]: new FormControl(null, [Validators.min(0), Validators.max(100)])
    });

    this._filteredRootPolicies = this.getControl(this.C_ROOT_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );

    this._filteredCollectionManagers = this.getControl(this.C_COLLECTION_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCollectionManagers(value || ''))
    );
  }

  get currentEffectDate(): Date | null | undefined {
    return this.currentRootPolicy?.effectDate;
  }

  get currentExpirationDate(): Date | null | undefined {
    return this.currentRootPolicy?.expirationDate;
  }

  get currentRootPolicy(): IPolicySearchResult | null | undefined {
    if (this.rootPolicyId) {
      return this.rootPolicies.find(x => x.policyId === this.rootPolicyId);
    }

    return null;
  }

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IPolicySearchResult>) {
    this._rootPolicies = value;
  }

  get collectionManagers(): Array<ICollectionManager> {
    return this._collectionManagers;
  }

  set collectionManagers(collectionManagers: Array<ICollectionManager>) {
    this._collectionManagers = collectionManagers;
  }

  get paymentTypes(): Array<IMasterValue> {
    return this._paymentTypes;
  }

  set paymentTypes(paymentTypes: Array<IMasterValue>) {
    this._paymentTypes = paymentTypes;
  }

  get policyDurations(): Array<IMasterValue> {
    return this._policyDurations;
  }

  set policyDurations(policyDurations: Array<IMasterValue>) {
    this._policyDurations = policyDurations;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get rootPolicyId(): string {
    return this.getControl(this.C_ROOT_POLICY_ID).value;
  }

  set rootPolicyId(value: string) {
    this.getControl(this.C_ROOT_POLICY_ID).setValue(value);
  }

  get effectDate(): Date | null | undefined {
    return this.getControl(this.C_EFFECT_DATE).value;
  }

  set effectDate(value: Date | null | undefined) {
    this.getControl(this.C_EFFECT_DATE).setValue(value);
  }

  get expirationDate(): Date | null | undefined {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | null | undefined) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get collectionManagerId(): string {
    return this.getControl(this.C_COLLECTION_MANAGER_ID).value;
  }

  set collectionManagerId(value: string) {
    this.getControl(this.C_COLLECTION_MANAGER_ID).setValue(value);
  }

  get paymentTypeId(): string {
    return this.getControl(this.C_PAYMENT_TYPE_ID).value;
  }

  set paymentTypeId(value: string) {
    this.getControl(this.C_PAYMENT_TYPE_ID).setValue(value);
  }

  get duration(): string {
    return this.getControl(this.C_DURATION).value;
  }

  set duration(value: string) {
    this.getControl(this.C_DURATION).setValue(value);
  }

  get commissionRate(): number {
    return this.getControl(this.C_COMMISSION_RATE).value;
  }

  set commissionRate(value: number) {
    this.getControl(this.C_COMMISSION_RATE).setValue(value);
  }

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  get filteredCollectionManagers(): Observable<Array<ICollectionManager>> {
    return this._filteredCollectionManagers;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }

  private filterCollectionManagers(value: string): Array<ICollectionManager> {
    return value !== ''
      ? this._collectionManagers.filter(c => c.name.toLowerCase().includes(value.toLowerCase()))
      : this._collectionManagers;
  }
}
