import { IProcessSearchResult } from 'src/app/core/models/api/processes';
import { IProcessSearchResultViewModel } from '../models';

export abstract class ProcessSearchMapper {
  static mapForSearch(source: IProcessSearchResult): IProcessSearchResultViewModel {
    return {
      processId: source.processId,
      target: source.target,
      action: source.action,
      description: source.description,
      creationDate: source.creationDate,
      closedDate: source.closedDate,
      message: source.message,
      retryCount: source.retryCount,
      statusId: source.statusId,
      status: source.status
    } as IProcessSearchResultViewModel;
  }
}
