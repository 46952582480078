<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Flota" i18n-label="@@app.fleets.edit.tabs.main.label">
      <ng-template matTabContent>
        <howden-fleet-edit-main></howden-fleet-edit-main>
      </ng-template>
    </mat-tab>

    <mat-tab label="Clientes" i18n-label="@@app.fleets.edit.tabs.clients.label">
      <ng-template matTabContent>
        <howden-fleet-edit-clients></howden-fleet-edit-clients>
      </ng-template>
    </mat-tab>

    <mat-tab label="Referenciales" i18n-label="@@app.fleets.edit.tabs.settings.label">
      <ng-template matTabContent>
        <howden-fleet-edit-settings></howden-fleet-edit-settings>
      </ng-template>
    </mat-tab>

    <mat-tab label="Impuestos" i18n-label="@@app.fleets.edit.tabs.taxes.label">
      <ng-template matTabContent>
        <howden-fleet-edit-taxes></howden-fleet-edit-taxes>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
