import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { HowdenTableComponent, IPageOf } from '@howdeniberia/core-front';
import { EditorChangeContent, EditorChangeSelection, QuillEditorComponent } from 'ngx-quill';
import { forkJoin, tap } from 'rxjs';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { ICertificateService } from 'src/app/core/services/api/certificates';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { CertificatesMapper } from '../mappers';
import { CertificatesViewModel } from '../models';

@Component({
  selector: 'howden-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit, OnDestroy {
  model = new CertificatesViewModel();
  modules: any = {
    'toolbar': [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ]
  };

  @ViewChild('stepper') stepperRef!: MatStepper;
  @ViewChild('policySelectionTable') policySelectionTable!: HowdenTableComponent;
  @ViewChild('headerEditor') headerEditorRef!: QuillEditorComponent;
  @ViewChild('bodyEditor') bodyEditorRef!: QuillEditorComponent;
  @ViewChild('footerEditor') footerEditorRef!: QuillEditorComponent;

  private _subscriptions = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private masterSrv: IMasterService,
    private policySrv: IPolicyService,
    private certificateSrv: ICertificateService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.url.pipe(
      tap(() => {
        const data: any = this.activatedRoute.snapshot?.data;

        this.model.searchRequest.fleetId = this.sessionSrv.activeFleet;

        this.uiBlockerSrv.block();

        forkJoin([
          this.masterSrv.getPolicyStatuses(),
          this.certificateSrv.getTemplates(data.certificateType.toString())
        ]).subscribe(([policyStatuses, templates]) => {
          this.model.policyStatuses = policyStatuses;
          this.model.templates = templates;

          this.onChanges();

          this.uiBlockerSrv.unblock();
        });
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    const request = CertificatesMapper.map(this.model);

    request.policies = this.policySelectionTable.selectedRows();

    this.uiBlockerSrv.block();
    this.certificateSrv.generate(request).subscribe({
      next: (blob) => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_CERTIFICADOS_', blob, 'zip');

        this.model.templateSelectionForm.reset();
        this.model.policySearchForm.reset();
        this.model.formattingForm.reset();
        this.model.policies = [];
        this.model.searchRequest.insuranceCompanyId = null;
        this.stepperRef.selectedIndex = 0;
        this.headerEditorRef.writeValue('');
        this.bodyEditorRef.writeValue('');
        this.footerEditorRef.writeValue('');
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }

  onCancel(): void {
    this.goToSearch();
  }

  onHeaderChanged(event: EditorChangeContent | EditorChangeSelection) {
    this.model.header = JSON.stringify(event.editor.root.innerHTML);
  }

  onBodyChanged(event: EditorChangeContent | EditorChangeSelection) {
    this.model.body = JSON.stringify(event.editor.root.innerHTML);
  }

  onFooterChanged(event: EditorChangeContent | EditorChangeSelection) {
    this.model.footer = JSON.stringify(event.editor.root.innerHTML);
  }

  onPolicySelectionChanged(event: any[]) {
    this.model.hasNoPoliciesSelected = event.length === 0;
  }

  private refreshPolicies(): void {
    const template = this.model.templates.find(x => x.certificateId === this.model.certificateTemplateId);

    this.model.searchRequest.insuranceCompanyId = template?.insuranceCompanyId;

    this.uiBlockerSrv.block();

    this.policySrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IPolicySearchResult>) => this.model.policies = searchResult.data,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.templateSelectionForm.valueChanges.subscribe(() => {
      const template = this.model.templates.find(x => x.certificateId === this.model.certificateTemplateId);
      if (template) {
        this.refreshPolicies();
      } else {
        this.model.policies = [];
      }
    });

    this._subscriptions.sink = this.model.policySearchForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refreshPolicies();
    });
  }

  private goToSearch(): void {
    this.router.navigate(['fleets/search']);
  }
}
