import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { ISessionService } from './session.contract';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements ISessionService {
  constructor(private storage: SessionStorageService) {
  }

  activateFleet(fleetId: string): void {
    if (fleetId !== this.activeFleet) {
      this.storage.store('active-fleet', fleetId);
    }
  }

  get activeFleet(): string {
    return this.storage.retrieve('active-fleet') as string;
  }

  get hasActiveFleet(): boolean {
    const activeFleet = this.storage.retrieve('active-fleet') as string | undefined;

    return !!activeFleet;
  }
}
