<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex margin-bottom-25">
      <button type="button" class="f-basis--4" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.claims.by.company.claimPaymentDate.label">Carga siniestral</mat-label>
        <mat-select [formControlName]="model.C_CLAIM_PAYMENT_DATE_ID" [howdenControlError]="claimPaymentDateIdError">
          @for (item of model.claimPaymentDates | howdenArraySort: 'description'; track item.paymentDateId) {
            <mat-option [value]="item.paymentDateId">
              {{ item.date | date }}
            </mat-option>
          }
        </mat-select>
        <mat-error #claimPaymentDateIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.claims.by.company.startDate.label">Fecha inicio</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          [formControlName]="model.C_START_DATE"
          [howdenControlError]="startDateError"
          [min]="minDate"
        />
        <mat-error #startDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.claims.by.company.endDate.label">Fecha fin</mat-label>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          [formControlName]="model.C_END_DATE"
          [howdenControlError]="endDateError"
          [min]="minDate"
        />
        <mat-error #endDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <div class="f-basis--3">
        <h4 i18n="@@app.reports.policies.rootPolicy.label">Póliza madre</h4>
        <mat-selection-list>
          @for (itemType of model.rootPolicies; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>
    </div>
  </form>
</div>
