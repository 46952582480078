import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyEditHolderUpdateMapper } from '../mappers';
import { PolicyEditHolderUpdateViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-holder-update-dialog',
  templateUrl: './policy-edit-holder-update-dialog.component.html',
  styleUrls: ['./policy-edit-holder-update-dialog.component.scss']
})
export class PolicyEditHolderUpdateDialogComponent implements OnInit, OnDestroy {
  model = new PolicyEditHolderUpdateViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditHolderUpdateDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const policyId = this.inputData.id;
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.get(policyId),
      this.policySrv.getBookingModes(policyId),
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.isBookingModeRequired(fleetId)
    ]).subscribe(([
      policy,
      bookingModes,
      fleetCompanies,
      isBmRequired
    ]) => {
      this.model.policy = policy;
      this.model.originalHolderId = this.inputData.holderId;
      this.model.fleetCompanies = fleetCompanies.filter(x => x.companyId !== this.model.originalHolderId);
      this.model.bookingModes = bookingModes;

      if (this.model.showBookingMode) {
        // Default booking mode
        if (bookingModes.length === 1) {
          this.model.bookingModeId = bookingModes[0].bookingModeId as string;
        }

        // Booking mode required
        const isBookingModeRequired = isBmRequired.result as boolean;

        if (isBookingModeRequired) {
          this.model.getControl(this.model.C_BOOKING_MODE_ID).addValidators([Validators.required]);
          this.model.getControl(this.model.C_BOOKING_MODE_ID).updateValueAndValidity();
        }
      }

      if (!this.model.showBonus) {
        this.model.getControl(this.model.C_NET_BONUS).removeValidators([Validators.required]);
        this.model.getControl(this.model.C_TOTAL_BONUS).removeValidators([Validators.required]);
        this.model.getControl(this.model.C_NET_BONUS).updateValueAndValidity();
        this.model.getControl(this.model.C_TOTAL_BONUS).updateValueAndValidity();
      }

      this.onChanges();

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const id = this.inputData.id;
    const request = PolicyEditHolderUpdateMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.policySrv.updatePolicyHolder(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_KEEP_CURRENT_INSURED).valueChanges.subscribe((value: boolean) => {
      const controls = [
        this.model.getControl(this.model.C_UPDATE_RISK_OWNER),
        this.model.getControl(this.model.C_BOOKING_MODE_ID),
        this.model.getControl(this.model.C_NET_BONUS),
        this.model.getControl(this.model.C_TOTAL_BONUS),
        this.model.getControl(this.model.C_COMMISSION_RATE)
      ];

      if (value === true) {
        controls.forEach((control) => {
          control.reset();
          control.disable();
        });
        this.model.updateRiskOwner = false;
      } else {
        controls.forEach((control) => control.enable());
      }
    });
  }
}
