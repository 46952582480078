import { IPolicySummary } from 'src/app/core/models/api/policies';
import { IPolicySummaryView } from '../models';

export abstract class PolicySummaryViewMapper {
  static map(source: Array<IPolicySummary>): Array<IPolicySummaryView> {
    return source.map(value => ({
        policyId: value.policyId,
        description: value.description,
        policyNumber: value.policyNumber,
        holder: value.holder,
        insuranceCompany: value.insuranceCompany,
        industry: value.industry,
        product: value.product,
        effectDate: value.effectDate,
        expirationDate: value.expirationDate,
        policyStatus: value.policyStatus,
        replacesPolicy: value.replacesPolicy
      } as IPolicySummaryView));
  }
}
