import { IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import {
  IBookingModeSearchRequest,
  IBookingModeSearchResult,
  ICreateBookingMode
} from 'src/app/core/models/api/booking-modes';

export abstract class IBookingModeService {
  abstract search(request: IBookingModeSearchRequest): Observable<IPageOf<IBookingModeSearchResult>>;
  abstract create(request: ICreateBookingMode): Observable<void>;
  abstract delete(id: string): Observable<void>;
}
