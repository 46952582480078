import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'howden-pivot-table-component',
  templateUrl: './pivot-table-component.component.html',
  styleUrls: ['./pivot-table-component.component.scss']
})
export class PivotTableComponentComponent {
  @Input() pivotTable: any = null;
  @Input() currencyFormat = '1.2-2';
  @Input() decimalFormat = '1.0-2';
  @Input() percentageFormat = '1.0-2';

  private readonly rowGroupKeyBonuses: string = $localize`:@@app.dashboard.pivots.charts.rowGroupKeyBonuses:Primas`;
  private readonly rowGroupKeyClaims: string = $localize`:@@app.dashboard.pivots.charts.rowGroupKeyClaims:Siniestros`;
  private readonly rowGroupKeyRisks: string = $localize`:@@app.dashboard.pivots.charts.rowGroupKeyRisks:Riesgos`;
  private readonly rowGroupKeyRatios: string = $localize`:@@app.dashboard.pivots.charts.rowGroupKeyRatios:Ratios`;

  private readonly rowKeyNetBonus: string = $localize`:@@app.dashboard.pivots.charts.rowKeyNetBonus:Prima Neta`;
  private readonly rowKeyClaimCount: string = $localize`:@@app.dashboard.pivots.charts.rowKeyClaimCount:Nº Siniestros`;
  private readonly rowKeyClaimPayments: string = $localize`:@@app.dashboard.pivots.charts.rowKeyClaimPayments:Pagos`;
  private readonly rowKeyClaimReservations: string = $localize`:@@app.dashboard.pivots.charts.rowKeyClaimReservations:Reservas`;
  private readonly rowKeyClaimCost: string = $localize`:@@app.dashboard.pivots.charts.rowKeyClaimCost:Coste`;
  private readonly rowKeyAvgClaimCost: string = $localize`:@@app.dashboard.pivots.charts.rowKeyAvgClaimCost:Coste Medio`;
  private readonly rowKeyOpenClaims: string = $localize`:@@app.dashboard.pivots.charts.rowKeyOpenClaims:Abiertos`;
  private readonly rowKeyClosedClaims: string = $localize`:@@app.dashboard.pivots.charts.rowKeyClosedClaims:Cerrados`;
  private readonly rowKeyOpenClaimsCost: string = $localize`:@@app.dashboard.pivots.charts.rowKeyOpenClaimsCost:Coste Abiertos`;
  private readonly rowKeyClosedClaimsCost: string = $localize`:@@app.dashboard.pivots.charts.rowKeyClosedClaimsCost:Coste Cerrados`;
  private readonly rowKeyAvgOpenClaimCost: string = $localize`:@@app.dashboard.pivots.charts.rowKeyAvgOpenClaimCost:CM Abiertos`;
  private readonly rowKeyAvgClosedClaimCost: string = $localize`:@@app.dashboard.pivots.charts.rowKeyAvgClosedClaimCost:CM Cerrados`;
  private readonly rowKeyExposedRiskCount: string = $localize`:@@app.dashboard.pivots.charts.rowKeyExposedRiskCount:Expuestos`;
  private readonly rowKeyFrequencyRatio: string = $localize`:@@app.dashboard.pivots.charts.rowKeyFrequencyRatio:Frecuencia`;
  private readonly rowKeyCostBonusRatio: string = $localize`:@@app.dashboard.pivots.charts.rowKeyCostBonusRatio:Coste/Primas`;
  private readonly rowKeyCostClaimRatio: string = $localize`:@@app.dashboard.pivots.charts.rowKeyCostClaimRatio:Coste x Siniestro`;
  private readonly rowKeyCostRiskRatio: string = $localize`:@@app.dashboard.pivots.charts.rowKeyCostRiskRatio:Coste x Riesgo`;
  private readonly rowTotalsColumnKey: string = $localize`:@@app.dashboard.pivots.charts.rowTotalsColumnKey: TOTAL`;

  constructor(
    private _decimalPipe: DecimalPipe,
    private _currencyPipe: CurrencyPipe,
    private _percentPipe: PercentPipe) {
  }

  shouldPlotColumn(rows: any[], index: number): boolean {
    if (index === 0) {
      return true;
    }

    const currentRow = rows[index];
    const previousRow = rows[index - 1];

    return currentRow.value.item1 !== previousRow.value.item1;
  }

  calculateRowSpan(rows: any[], value: any): number {
    return rows.filter((r: any) => r.value.item1 === value).length;
  }

  formatAggregate(dim1: string, dim2: string, columnAggregates: any[], columnAggregate: any): string | null {
    const aggregate = columnAggregates.find((a: any) => a.value === columnAggregate.value);
    return this.formatValue(dim1, dim2, aggregate ? aggregate.aggregates : 0);
  }

  private formatValue(dim1: string, dim2: string, value: number): string | null {
    if (dim1 === this.rowGroupKeyClaims) {
      if (dim2 === this.rowKeyClaimPayments) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyClaimReservations) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyClaimCost) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyAvgClaimCost) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyOpenClaimsCost) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyClosedClaimsCost) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyAvgOpenClaimCost) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyAvgClosedClaimCost) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      }
    } else if (dim1 === this.rowGroupKeyRatios) {
      if (dim2 === this.rowKeyFrequencyRatio) {
        return this._percentPipe.transform(value / 100, this.percentageFormat);
      } else if (dim2 === this.rowKeyCostBonusRatio) {
        return this._percentPipe.transform(value / 100, this.percentageFormat);
      } else if (dim2 === this.rowKeyCostClaimRatio) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      } else if (dim2 === this.rowKeyCostRiskRatio) {
        return this._currencyPipe.transform(value, 'EUR', 'symbol', this.currencyFormat);
      }
    }

    return this._decimalPipe.transform(value, this.decimalFormat);
  }
}
