import { RootPolicyUpdateMode } from 'src/app/core/enums';
import { IPolicy, IPolicyUpdate } from 'src/app/core/models/api/policies';
import { PolicyEditViewModel } from '../models';

export abstract class PolicyEditMapper {
  static mapForEdit(source: IPolicy, target: PolicyEditViewModel) {
    target.rootPolicyId = source.rootPolicyId;
    target.policyClass = source.policyClass;
    target.policyBonusType = source.policyBonusType;
    target.billingModelId = source.billingModel.toString();
    target.regularizationPeriodId = source.regularizationPeriodId;
    target.doubleBilling = source.doubleBilling;
    target.commissionRate = source.commissionRate;
    target.officeCode = source.officeCode;
    target.executiveId = source.executiveId;
    target.holderId = source.holderId;
    target.addressId = source.addressId;
    target.directDebitId = source.directDebitId;
    target.insuranceCompanyId = source.insuranceCompanyId;
    target.industryId = source.industryId;
    target.productId = source.productId;
    target.policyNumber = source.policyNumber;
    target.collectionManagerId = source.collectionManagerId;
    target.mediationCodeId = source.mediationCodeId;
    target.currencyId = source.currencyId;
    target.paymentTypeId = source.paymentType?.toString();
    target.duration = source.duration?.toString();
    target.firstEffectDate = source.firstEffectDate;
    target.effectDate = source.effectDate;
    target.expirationDate = source.expirationDate;
    target.cancellationDate = source.cancellationDate;
    target.cancellationReasonId = source.cancellationReasonId;
    target.cancellationReason = source.cancellationReason;
    target.description = source.riskDescription;
    target.receiptsOn = source.receiptsOn?.toString();
    target.replacesPolicy = source.replacesPolicy;
    target.replacedBy = source.replacedBy;
    target.individualBonusGrouping = source.individualBonusGrouping;
    target.rootPolicyUpdateMode = RootPolicyUpdateMode.RootPolicy.toString();
    target.status = source.status;
  }

  static mapForUpdate(source: PolicyEditViewModel): IPolicyUpdate {
    return {
      billingModel: source.billingModelId ? parseInt(source.billingModelId) : null,
      regularizationPeriodId: source.regularizationPeriodId,
      doubleBilling: source.doubleBilling,
      commissionRate: source.commissionRate,
      officeCode: source.officeCode,
      executiveId: source.executiveId,
      holderId: source.holderId,
      addressId: source.addressId,
      directDebitId: source.directDebitId,
      insuranceCompanyId: source.insuranceCompanyId,
      industryId: source.industryId,
      productId: source.productId,
      policyNumber: source.policyNumber,
      collectionManagerId: source.collectionManagerId,
      mediationCodeId: source.mediationCodeId,
      currencyId: source.currencyId,
      paymentType: source.paymentTypeId ? parseInt(source.paymentTypeId) : null,
      duration: source.duration ? parseInt(source.duration) : null,
      firstEffectDate: source.firstEffectDate,
      effectDate: source.effectDate,
      expirationDate: source.expirationDate,
      cancellationDate: source.cancellationDate,
      cancellationReasonId: source.cancellationReasonId,
      cancellationReason: source.cancellationReason,
      description: source.description,
      receiptsOn: source.receiptsOn ? parseInt(source.receiptsOn) : null,
      individualBonusGrouping: source.individualBonusGrouping,
      rootPolicyUpdateMode: source.rootPolicyUpdateMode ? parseInt(source.rootPolicyUpdateMode) : null,
      status: source.status
    } as IPolicyUpdate;
  }
}
