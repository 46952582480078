export class Limits {
  static readonly zero = 0;
  static readonly maxDecimal = 9223372036854.0;
  static readonly maxInt16 = 32767;
  static readonly maxInt32 = 2147483647;
  static readonly maxPageSize = 2147483647;
  static get minDate(): Date {
    return new Date(1900, 0, 1);
  }
}
