<form [formGroup]="model.form">
  <div class="howden-dialog-content">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.billing.group.rules.title">
      Reglas de grupos de facturación
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--6">
          <mat-label i18n="@@app.policies.edit.billing.group.rules.type.label">Tipo</mat-label>
          <mat-select #ruleTypeSelect [formControlName]="model.C_TYPE" [howdenControlError]="ruleTypeError">
            @for (item of model.ruleTypes | howdenArraySort: 'description'; track item.typeId) {
              <mat-option [value]="item.typeId">
                {{ item.description }}
              </mat-option>
            }
          </mat-select>
          <mat-error #ruleTypeError></mat-error>
        </mat-form-field>

        @if (model.type) {
          @if (!model.showDirectValue) {
            <mat-form-field class="f-basis--6">
              <mat-label i18n="@@app.policies.edit.billing.group.rules.value.label">Valor</mat-label>
              <mat-select #ruleTypeSelect [formControlName]="model.C_VALUE_ID" [howdenControlError]="ruleTypeError">
                @for (item of model.referenceValues | howdenArraySort: 'description'; track item.key) {
                  <mat-option [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                }
              </mat-select>
              <mat-error #ruleTypeError></mat-error>
            </mat-form-field>
          } @else {
            <mat-form-field class="f-basis--6">
              <mat-label i18n="@@app.policies.edit.billing.group.rules.directValue.label">Valor</mat-label>
              <input matInput [formControlName]="model.C_DIRECT_VALUE" maxlength="10" />
            </mat-form-field>
          }
        }
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
