@if (this.segment && this.group) {
  <div>
    @if (!isDefaultSegment) {
      <div class="flex">
        <div class="f-basis--12">
          <h4>{{ description }}</h4>
        </div>
      </div>
    }
    <div class="flex">
      <div class="f-basis--12">
        <table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td class="text-center text-bold text-uppercase">Facturado</td>
              <td class="text-center text-bold text-uppercase">Real</td>
              <td class="text-center text-bold text-uppercase">A regularizar</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td class="text-center text-bold yellow-background">{{ group.yearlyVehicleCount1 | number }} (V/A)</td>
              <td class="text-center text-bold yellow-background">{{ group.yearlyVehicleCount2 | number }} (V/A)</td>
              <td class="text-center text-bold yellow-background">{{ group.yearlyVehicleCountDifference | number }} (V/A)</td>
            </tr>
            @for (subtotal of segment.subtotals; track subtotal) {
              <tr>
                <td>{{ subtotal.description }}</td>
                <td class="text-right text-italic">
                  @if (subtotal.percentage) {
                    <span>{{ subtotal.percentage | percent : '1.2-2' }}</span>
                  }
                </td>
                <td class="text-right">{{ subtotal.totalAmount | number : '1.2-2' }}</td>
                <td class="text-right">{{ subtotal.totalAmount2 | number : '1.2-2' }}</td>
                <td class="text-right text-bold" [class]="subtotal.totalDifference !== 0 ? 'color-text-accent' : ''">
                  {{ subtotal.totalDifference | number : '1.2-2' }}
                </td>
              </tr>
            }
            <tr>
              <td><strong>{{ segment.totals.description }}</strong></td>
              <td></td>
              <td class="text-right text-bold">{{ segment.totals.totalAmount | number : '1.2-2' }}</td>
              <td class="text-right text-bold">{{ segment.totals.totalAmount2 | number : '1.2-2' }}</td>
              <td class="text-right text-bold" [class]="segment.totals.totalDifference !== 0 ? 'color-text-accent' : ''">
                {{ segment.totals.totalDifference | number : '1.2-2' }}
              </td>
            </tr>
            @for (commission of segment.commissions; track commission) {
              <tr>
                <td></td>
                <td class="text-right text-bold">{{ commission.description }} {{ commission.percentage | percent : '1.2-2' }}</td>
                <td class="text-right">{{ commission.totalAmount | number : '1.2-2' }}</td>
                <td class="text-right">{{ commission.totalAmount2 | number : '1.2-2' }}</td>
                <td class="text-right text-bold" [class]="commission.totalDifference !== 0 ? 'color-text-accent': ''">
                  {{ commission.totalDifference | number : '1.2-2' }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
}
