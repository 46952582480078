import { Observable } from 'rxjs';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { ICollectionManager } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';

export abstract class IMasterService {
  abstract getCollectionManagers(): Observable<Array<ICollectionManager>>;
  abstract getPaymentTypes(): Observable<Array<IMasterValue>>;
  abstract getPolicyCancellationReasons(): Observable<Array<IMasterValue>>;
  abstract getPolicyDurations(): Observable<Array<IMasterValue>>;
  abstract getPolicyStatuses(): Observable<Array<IMasterValue>>;
  abstract getEndorsementStatuses(): Observable<Array<IMasterValue>>;
  abstract getEndorsementTypes(): Observable<Array<IMasterValue>>;
  abstract getReceiptSituations(): Observable<Array<IMasterValue>>;
  abstract getReceiptTypes(): Observable<Array<IMasterValue>>;
  abstract getProcessStatuses(): Observable<Array<IMasterValue>>;
  abstract getFleetControlReportItemTypes(): Observable<Array<IKeyValuePair<number, string>>>;
  abstract getUnitStatuses(): Observable<Array<IKeyValuePair<number, string>>>;
}
