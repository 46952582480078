import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IInitialStateDashboardResponse } from 'src/app/core/models/api/dashboards';
import { IFleetSearchResult } from 'src/app/core/models/api/fleets';

export class InitialStateDashboardViewModel {
  private _form: FormGroup;
  private _fleet: IFleetSearchResult | null = null;
  private _dashboardData: IInitialStateDashboardResponse | null = null;

  readonly C_YEAR = 'year';

  constructor() {
    this._form = new FormGroup({
      [this.C_YEAR]: new FormControl(null, [])
    });
  }

  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.dashboard.initial.state.insuranceCompany.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'rootPolicyNumber',
      fieldHeader: $localize`:@@app.dashboard.initial.state.rootPolicyNumber.column:Póliza madre`,
      canSort: true
    },
    {
      fieldName: 'certificateNumber',
      fieldHeader: $localize`:@@app.dashboard.initial.state.certificateNumber.column:Nº.Póliza`,
      canSort: true
    },
    {
      fieldName: 'plateNumber',
      fieldHeader: $localize`:@@app.dashboard.initial.state.plateNumber.column:Matrícula`,
      canSort: true
    },
    {
      fieldName: 'carNumber',
      fieldHeader: $localize`:@@app.dashboard.initial.state.carNumber.column:Nº.Coche`,
      canSort: true
    },
    {
      fieldName: 'riskDescription',
      fieldHeader: $localize`:@@app.dashboard.initial.state.riskDescription.column:Riesgo`,
      canSort: true
    },
    {
      fieldName: 'totalAmount',
      fieldHeader: $localize`:@@app.dashboard.initial.state.totalAmount.column:Importe`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get dashboardData(): IInitialStateDashboardResponse | null {
    return this._dashboardData;
  }
  set dashboardData(value: IInitialStateDashboardResponse | null) {
    this._dashboardData = value;
  }

  get fleet(): IFleetSearchResult | null {
    return this._fleet;
  }
  set fleet(value: IFleetSearchResult | null) {
    this._fleet = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get year(): number | null {
    return this.getControl(this.C_YEAR).value;
  }

  set year(value: number | null) {
    this.getControl(this.C_YEAR).setValue(value);
  }
}
