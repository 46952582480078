import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig } from '@howdeniberia/core-front';
import { IDetailedReceiptView } from './detailed-receipt-view';

export class PolicyEditReceiptsViewModel {
  data: IDetailedReceiptView[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'receiptId',
      fieldHeader: $localize`:@@app.policies.edit.receipts.receiptId.column:ID`,
      canSort: true
    },
    {
      fieldName: 'client',
      fieldHeader: $localize`:@@app.policies.edit.receipts.client.column:Cliente`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.TRUNCATE
    },
    {
      fieldName: 'receiptNumber',
      fieldHeader: $localize`:@@app.policies.edit.receipts.receiptNumber.column:Nº. Recibo`,
      canSort: true
    },
    {
      fieldName: 'insuranceCompanyReceiptNumber',
      fieldHeader: $localize`:@@app.policies.edit.receipts.insuranceCompanyReceiptNumber.column:Nº. Rec. Ase.`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.policies.edit.receipts.effectDate.column:F. Efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.policies.edit.receipts.expirationDate.column:F. Venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'collectionDate',
      fieldHeader: $localize`:@@app.policies.edit.receipts.collectionDate.column:F. Cobro`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'currency',
      fieldHeader: $localize`:@@app.policies.edit.receipts.currency.column:Divisa`,
      canSort: true
    },
    {
      fieldName: 'netBonus',
      fieldHeader: $localize`:@@app.policies.edit.receipts.netBonus.column:P. Neta anual`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    },
    {
      fieldName: 'receiptTotal',
      fieldHeader: $localize`:@@app.policies.edit.receipts.receiptTotal.column:Total recibo`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    },
    {
      fieldName: 'brokerCommisionEuros',
      fieldHeader: $localize`:@@app.policies.edit.receipts.brokerCommisionEuros.column:Com. corr. (€)`,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      canSort: true
    },
    {
      fieldName: 'situation',
      fieldHeader: $localize`:@@app.policies.edit.receipts.situation.column:Situación`,
      canSort: true
    },
    {
      fieldName: 'receiptType',
      fieldHeader: $localize`:@@app.policies.edit.receipts.receiptType.column:Tipo`,
      canSort: true
    }
  ];
}
