import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IButtonActionData, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { IUnitSearchResult } from 'src/app/core/models/api/units';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UnitSummaryDialogComponent } from '../../unit-summary-dialog/pages/unit-summary-dialog.component';
import { UnitSearchMapper } from '../mappers';
import { UnitSearchViewModel } from '../models';

@Component({
  selector: 'howden-unit-search',
  templateUrl: './unit-search.component.html',
  styleUrls: ['./unit-search.component.scss']
})
export class UnitSearchComponent implements OnInit, OnDestroy {
  model = new UnitSearchViewModel();

  private _subscriptions = new SubSink();

  @ViewChild('plateNumberInput') plateNumberInputRef!: ElementRef;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private unitsSrv: IUnitService
  ) {
  }

  ngOnInit(): void {
    this.model.searchRequest.fleetId = this.sessionSrv.activeFleet;
    this.refresh(true);
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.edit(event);
    } else {
      this.showInfo(event);
    }
  }

  private edit(event: IButtonActionData): void {
    this.router.navigate(['units/edit', event.row.unitId]);
  }

  private showInfo(event: IButtonActionData): void {
    const inputData = { id: event.row.unitId };
    const dialogRef = this.dialog.open(UnitSummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  private refresh(initial?: boolean): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.unitsSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IUnitSearchResult>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => UnitSearchMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();

        if (initial === true) {
          this.plateNumberInputRef.nativeElement.focus();
        }
      }
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }
}
