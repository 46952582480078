<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="margin-bottom-0">
      <span class="title-text" i18n="@@app.policies.edit.holder.update.title">Cambio de tomador</span>
      <br />
      <span class="subtitle-text" i18n="@@app.policies.edit.holder.update.subtitle">
        Por favor, indique los datos de la operación.
      </span>
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label i18n="@@app.policies.edit.holder.update.operationDate.label">Fecha operación</mat-label>
          <input
            matInput
            [matDatepicker]="operationDatePicker"
            [formControlName]="model.C_OPERATION_DATE"
            [howdenControlError]="operationDateError"
            [min]="minDate"
          />
          <mat-error #operationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #operationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <howden-autocomplete
          class="f-basis--12"
          i18n-label="@@app.policies.edit.holder.update.holderId.label"
          label="Tomador"
          displayField="clientName"
          keyField="companyId"
          [selectableItems]="model.fleetCompanies"
          [formControlName]="model.C_HOLDER_ID"
        >
        </howden-autocomplete>
      </div>

      @if (this.model.showOptional) {
        <div class="flex f-gap--10px margin-bottom-10">
          <mat-slide-toggle
            class="f-basis--6 f-as--center"
            [formControlName]="model.C_KEEP_CURRENT_INSURED"
            i18n="@@app.policies.edit.holder.update.keepCurrentInsured.label">Mantener asegurado actual</mat-slide-toggle>
          <mat-slide-toggle
            class="f-basis--6 f-as--center"
            [formControlName]="model.C_UPDATE_RISK_OWNER"
            i18n="@@app.policies.edit.holder.update.updateRiskOwner.label">Actualizar el propietario del vehículo</mat-slide-toggle>
        </div>
      }

      @if (this.model.showBookingMode) {
        <div class="flex f-gap--10px">
          <howden-autocomplete
            class="f-basis--12"
            i18n-label="@@app.policies.edit.holder.update.bookingModeId.label"
            label="Modo de contratación"
            displayField="description"
            keyField="bookingModeId"
            [selectableItems]="model.bookingModes"
            [formControlName]="model.C_BOOKING_MODE_ID"
          >
          </howden-autocomplete>
        </div>
      }

      @if (this.model.showBonus) {
        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--6"
            [formControlName]="model.C_NET_BONUS"
            i18n-label="@@app.policies.edit.holder.update.netBonus.label"
            label="Prima neta anual"
          ></howden-decimal>

          <howden-decimal
            class="f-basis--6"
            [formControlName]="model.C_TOTAL_BONUS"
            i18n-label="@@app.policies.edit.holder.update.totalBonus.label"
            label="Prima total anual"
          ></howden-decimal>
        </div>

        <div class="flex f-gap--10px">
          <howden-decimal
            class="f-basis--12"
            [formControlName]="model.C_COMMISSION_RATE"
            i18n-label="@@app.policies.edit.holder.update.commissionRate.label"
            label="Comisión (%)"
          ></howden-decimal>
        </div>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
