import { IPolicyParalization } from 'src/app/core/models/api/policies';
import { PolicyEditParalizationViewModel } from '../models';

export abstract class PolicyEditParalizationMapper {
  static mapForEdit(source: IPolicyParalization, target: PolicyEditParalizationViewModel) {
    target.paralizationId = source.paralizationId;
    target.startDate = source.startDate;
    target.endDate = source.endDate;
  }

  static mapForUpdate(source: PolicyEditParalizationViewModel): IPolicyParalization {
    return {
      paralizationId: source.paralizationId,
      startDate: source.startDate,
      endDate: source.endDate
    } as IPolicyParalization;
  }
}
