import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { IPolicyReplacerFileItem } from 'src/app/core/models/api/fleets';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { ICollectionManager } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany, IMediationCode } from 'src/app/core/models/ida/providers';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyReplacerViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _products: Array<IProduct> = [];
  private _mediationCodes: Array<IMediationCode> = [];
  private _collectionManagers: Array<ICollectionManager> = [];
  private _paymentTypes: Array<IMasterValue> = [];
  private _bookingModes: Array<IBookingModeSearchResult> = [];
  private _policyDurations: Array<IMasterValue> = [];
  private _transferModes: Array<IKeyValuePair<string, string>> = [];
  private _holderReferences: Array<IKeyValuePair<string, string>> = [];
  private _insuredReferences: Array<IKeyValuePair<string, string>> = [];

  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;
  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _filteredIndustries: Observable<Array<IIndustry>>;
  private _filteredProducts: Observable<Array<IProduct>>;
  private _filteredMediationCodes: Observable<Array<IMediationCode>>;
  private _filteredCollectionManagers: Observable<Array<ICollectionManager>>;
  private _filteredBookingModes: Observable<Array<IBookingModeSearchResult>>;

  readonly C_TARGET_ROOT_POLICY_ID = 'targetRootPolicyId';
  readonly C_TRANSFER_MODE_ID = 'transferModeId';
  readonly C_CANCEL_WITHOUT_EFFECT = 'cancelWithoutEffect';
  readonly C_HOLDER_REFERENCE_ID = 'holderReferenceId';
  readonly C_INSURED_REFERENCE_ID = 'insuredReferenceId';
  readonly C_FIRST_EFFECT_DATE = 'firstEffectDate';
  readonly C_EFFECT_DATE = 'effectDate';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_INDUSTRY_ID = 'industryId';
  readonly C_PRODUCT_ID = 'productId';
  readonly C_MEDIATION_CODE_ID = 'mediationCodeId';
  readonly C_COLLECTION_MANAGER_ID = 'collectionManagerId';
  readonly C_PAYMENT_TYPE_ID = 'paymentTypeId';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';
  readonly C_DURATION_ID = 'durationId';
  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_TARGET_ROOT_POLICY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.rootPolicies, 'policyId')]),
      [this.C_TRANSFER_MODE_ID]: new FormControl(null, [Validators.required]),
      [this.C_CANCEL_WITHOUT_EFFECT]: new FormControl(false, []),
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_INDUSTRY_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_PRODUCT_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.products, 'productId')]),
      [this.C_FIRST_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_HOLDER_REFERENCE_ID]: new FormControl(null, [Validators.required]),
      [this.C_INSURED_REFERENCE_ID]: new FormControl(null, []),
      [this.C_MEDIATION_CODE_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.mediationCodes, 'mediationCodeId')]),
      [this.C_PAYMENT_TYPE_ID]: new FormControl(null, []),
      [this.C_DURATION_ID]: new FormControl(null, [Validators.required]),
      [this.C_COLLECTION_MANAGER_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.collectionManagers, 'collectionManagerId')]),
      [this.C_BOOKING_MODE_ID]: new FormControl(null, []),
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });

    this._filteredRootPolicies = this.getControl(this.C_TARGET_ROOT_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._filteredIndustries = this.getControl(this.C_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._filteredProducts = this.getControl(this.C_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterProducts(value || ''))
    );

    this._filteredMediationCodes = this.getControl(this.C_MEDIATION_CODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterMediationCodes(value || ''))
    );

    this._filteredCollectionManagers = this.getControl(this.C_COLLECTION_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCollectionManagers(value || ''))
    );

    this._filteredBookingModes = this.getControl(this.C_BOOKING_MODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterBookingModes(value || ''))
    );
  }

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IPolicySearchResult>) {
    this._rootPolicies = value;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get products(): Array<IProduct> {
    return this._products;
  }

  set products(value: Array<IProduct>) {
    this._products = value;
  }

  get mediationCodes(): Array<IMediationCode> {
    return this._mediationCodes;
  }

  set mediationCodes(value: Array<IMediationCode>) {
    this._mediationCodes = value;
  }

  get collectionManagers(): Array<ICollectionManager> {
    return this._collectionManagers;
  }

  set collectionManagers(collectionManagers: Array<ICollectionManager>) {
    this._collectionManagers = collectionManagers;
  }

  get paymentTypes(): Array<IMasterValue> {
    return this._paymentTypes;
  }

  set paymentTypes(paymentTypes: Array<IMasterValue>) {
    this._paymentTypes = paymentTypes;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(values: Array<IBookingModeSearchResult>) {
    this._bookingModes = values;
  }

  get policyDurations(): Array<IMasterValue> {
    return this._policyDurations;
  }

  set policyDurations(value: Array<IMasterValue>) {
    this._policyDurations = value;
  }

  get transferModes(): Array<IKeyValuePair<string, string>> {
    return this._transferModes;
  }

  set transferModes(value: Array<IKeyValuePair<string, string>>) {
    this._transferModes = value;
  }

  get holderReferences(): Array<IKeyValuePair<string, string>> {
    return this._holderReferences;
  }

  set holderReferences(value: Array<IKeyValuePair<string, string>>) {
    this._holderReferences = value;
  }

  get insuredReferences(): Array<IKeyValuePair<string, string>> {
    return this._insuredReferences;
  }

  set insuredReferences(value: Array<IKeyValuePair<string, string>>) {
    this._insuredReferences = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  /** OPERATION */

  get targetRootPolicyId(): string | null {
    return this.getControl(this.C_TARGET_ROOT_POLICY_ID).value;
  }

  set targetRootPolicyId(value: string | null) {
    this.getControl(this.C_TARGET_ROOT_POLICY_ID).setValue(value);
  }

  get transferModeId(): string | null {
    return this.getControl(this.C_TRANSFER_MODE_ID).value;
  }

  set transferModeId(value: string | null) {
    this.getControl(this.C_TRANSFER_MODE_ID).setValue(value);
  }

  get cancelWithoutEffect(): boolean {
    return this.getControl(this.C_CANCEL_WITHOUT_EFFECT).value;
  }

  set cancelWithoutEffect(value: boolean) {
    this.getControl(this.C_CANCEL_WITHOUT_EFFECT).setValue(value);
  }

  get insuranceCompanyId(): string | null {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string | null) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get industryId(): string | null {
    return this.getControl(this.C_INDUSTRY_ID).value;
  }

  set industryId(value: string | null) {
    this.getControl(this.C_INDUSTRY_ID).setValue(value);
  }

  get productId(): string | null {
    return this.getControl(this.C_PRODUCT_ID).value;
  }

  set productId(value: string | null) {
    this.getControl(this.C_PRODUCT_ID).setValue(value);
  }

  get firstEffectDate(): Date | null {
    return this.getControl(this.C_FIRST_EFFECT_DATE).value;
  }

  set firstEffectDate(value: Date | null) {
    this.getControl(this.C_FIRST_EFFECT_DATE).setValue(value);
  }

  get effectDate(): Date | null {
    return this.getControl(this.C_EFFECT_DATE).value;
  }

  set effectDate(value: Date | null) {
    this.getControl(this.C_EFFECT_DATE).setValue(value);
  }

  get expirationDate(): Date | null {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | null) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get holderReferenceId(): string | null {
    return this.getControl(this.C_HOLDER_REFERENCE_ID).value;
  }

  set holderReferenceId(value: string | null) {
    this.getControl(this.C_HOLDER_REFERENCE_ID).setValue(value);
  }

  get insuredReferenceId(): string | null {
    return this.getControl(this.C_INSURED_REFERENCE_ID).value;
  }

  set insuredReferenceId(value: string | null) {
    this.getControl(this.C_INSURED_REFERENCE_ID).setValue(value);
  }

  get mediationCodeId(): string | null {
    return this.getControl(this.C_MEDIATION_CODE_ID).value;
  }

  set mediationCodeId(value: string | null) {
    this.getControl(this.C_MEDIATION_CODE_ID).setValue(value);
  }

  get paymentTypeId(): string | null {
    return this.getControl(this.C_PAYMENT_TYPE_ID).value;
  }

  set paymentTypeId(value: string | null) {
    this.getControl(this.C_PAYMENT_TYPE_ID).setValue(value);
  }

  get durationId(): string | null {
    return this.getControl(this.C_DURATION_ID).value;
  }

  set durationId(value: string | null) {
    this.getControl(this.C_DURATION_ID).setValue(value);
  }

  get collectionManagerId(): string | null {
    return this.getControl(this.C_COLLECTION_MANAGER_ID).value;
  }

  set collectionManagerId(value: string | null) {
    this.getControl(this.C_COLLECTION_MANAGER_ID).setValue(value);
  }

  get bookingModeId(): string | null {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | null) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }

  /* AutoComplete */

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  get filteredIndustries(): Observable<Array<IIndustry>> {
    return this._filteredIndustries;
  }

  get filteredProducts(): Observable<Array<IProduct>> {
    return this._filteredProducts;
  }

  get filteredMediationCodes(): Observable<Array<IMediationCode>> {
    return this._filteredMediationCodes;
  }

  get filteredCollectionManagers(): Observable<Array<ICollectionManager>> {
    return this._filteredCollectionManagers;
  }

  get filteredBookingModes(): Observable<Array<IBookingModeSearchResult>> {
    return this._filteredBookingModes;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this._insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this._insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this._industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this._industries;
  }

  private filterProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this._products.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this._products;
  }

  private filterCollectionManagers(value: string): Array<ICollectionManager> {
    return value !== ''
      ? this._collectionManagers.filter(c => c.name.toLowerCase().includes(value.toLowerCase()))
      : this._collectionManagers;
  }

  private filterMediationCodes(value: string): Array<IMediationCode> {
    return value !== ''
      ? this._mediationCodes.filter(x => x.description?.toLowerCase().includes(value.toLowerCase()))
      : this._mediationCodes;
  }

  private filterBookingModes(value: string): Array<IBookingModeSearchResult> {
    return value !== ''
      ? this.bookingModes.filter(c => c.description?.toLowerCase().includes(value.toLowerCase()))
      : this.bookingModes;
  }

  updaterResults: IPolicyReplacerFileItem[] = [];
  updaterResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.replacer.insuranceCompany.column:Compañía`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.replacer.policyNumber.column:Nº. Póliza`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.replacer.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
