import { IUpdateFleetSettings } from 'src/app/core/models/api/fleets';
import { FleetEditSettingsViewModel } from '../models';

export abstract class FleetEditSettingsMapper {
  static mapForUpdate(source: FleetEditSettingsViewModel): IUpdateFleetSettings {
    return {
      insuranceCompanies: source.insuranceCompanySettings,
      industries: source.industrySettings,
      vehicleTypes: source.vehicleTypeSettings,
      vehicleUsages: source.vehicleUsageSettings
    } as IUpdateFleetSettings;
  }
}
