import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IEmptyRootPolicyNotification } from 'src/app/core/models/api/notifications';

export class EmptyRootPolicyNotificationsViewModel {
  data: IEmptyRootPolicyNotification[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'fleetName',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.fleetName.column:Flota`,
      canSort: true,
      width: '300px'
    },
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.insuranceCompany.column:Aseguradora`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.policyNumber.column:Póliza madre`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyName',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.policyName.column:Descripción`,
      canSort: true,
      width: '300px'
    },
    {
      fieldName: 'firstEffectDate',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.firstEffectDate.column:F. Pri. Efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      width: '100px'
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.effectDate.column:F. Efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      width: '100px'
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.expirationDate.column:F. Venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      width: '100px'
    },
    {
      fieldName: 'holderName',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.holderName.column:Tomador`,
      canSort: true,
      width: '300px'
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.industry.column:Ramo`,
      canSort: true,
      width: '300px'
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.notifications.empty.root.policy.product.column:Producto`,
      canSort: true,
      width: '300px'
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.policies.search.actions.info.tooltip:Información`
  }];
}
