import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IDashboardService } from 'src/app/core/services/api/dashboards';
import { IBreakPointObserverSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';
import { GeneralDashboardViewModel } from '../models';

@Component({
  selector: 'howden-general-dashboard',
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.scss']
})
export class GeneralDashboardComponent implements OnInit {
  model = new GeneralDashboardViewModel();

  cardLayout = this.breakpointObserver.observe();

  constructor(
    private breakpointObserver: IBreakPointObserverSrv,
    private uiBlockerSrv: IUIBlockerService,
    private dashboardSrv: IDashboardService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.dashboardSrv.getGeneralUnitCount(),
      this.dashboardSrv.getGeneralUnitCountWithoutBonus(),
      this.dashboardSrv.getGeneralUnitCountWithoutPolicy(),
      this.dashboardSrv.getGeneralUnitCountWithAllPoliciesCancelled(),
      this.dashboardSrv.getGeneralRootPolicyCount(),
      this.dashboardSrv.getGeneralChildPolicyCount(),
      this.dashboardSrv.getGeneralPolicyCountWithoutNumber(),
      this.dashboardSrv.getGeneralUnitsByVehicleType(),
      this.dashboardSrv.getGeneralPoliciesByInsuranceCompany(),
      this.dashboardSrv.getGeneralPoliciesByOffice(),
      this.dashboardSrv.getGeneralPoliciesByPaymentType()
    ]).subscribe(([
      unitCount,
      unitCountWithoutBonus,
      unitCountWithoutPolicy,
      unitCountWithAllPoliciesCancelled,
      rootPolicyCount,
      childPolicyCount,
      policyCountWithoutNumber,
      unitsByVehicleType,
      policiesByInsuranceCompany,
      policiesByOffice,
      policiesByPaymentType
    ]) => {
      this.initializeMiniCards(
        unitCount,
        unitCountWithoutBonus,
        unitCountWithoutPolicy,
        unitCountWithAllPoliciesCancelled,
        rootPolicyCount,
        childPolicyCount,
        policyCountWithoutNumber);

      this.model.unitsByVehicleType = unitsByVehicleType;
      this.model.policiesByInsuranceCompany = policiesByInsuranceCompany;
      this.model.policiesByOffice = policiesByOffice;
      this.model.policiesByPaymentType = policiesByPaymentType;

      this.uiBlockerSrv.unblock();
    });
  }

  private initializeMiniCards(
    unitCount: number,
    unitCountWithoutBonus: number,
    unitCountWithoutPolicy: number,
    unitCountWithAllPoliciesCancelled: number,
    rootPolicyCount: number,
    childPolicyCount: number,
    policyCountWithoutNumber: number) {
    this.model.miniCardData = [{
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.units.title:Unidades`,
      value: unitCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.units.duration:Nº. de unidades con pólizas en vigor`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.policies.without.number.title:Pólizas S/Nº`,
      value: policyCountWithoutNumber,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.policies.without.number.duration:Nº. de pólizas sin número`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.units.without.bonus.title:Und. S/Prima`,
      value: unitCountWithoutBonus,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.units.without.bonus.duration:Nº. de unidades sin prima`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.units.without.policy.title:Und. S/Póliza`,
      value: unitCountWithoutPolicy,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.units.without.policy.duration:Unidades sin póliza`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.units.with.all.policies.cancelled.title:Und. S/Póliza`,
      value: unitCountWithAllPoliciesCancelled,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.units.with.all.policies.cancelled.duration:Unidades con todas sus pólizas anuladas`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.root.policy.count.title:Nº. de pólizas madre/colectivas`,
      value: rootPolicyCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.root.policy.count.duration:Número de pólizas madre + colectivas en vigor`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.general.mini.cards.child.policy.count.title:Nº. de certificados`,
      value: childPolicyCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.general.mini.cards.child.policy.count.duration:Número de certificados en vigor`
    } as IMiniCardData];
  }
}
