import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HowdenAutocompleteModule, HowdenSharedModule } from '@howdeniberia/core-front';
import { ClientGroupsService, IClientGroupsService } from 'src/app/core/services/api/clients';
import { FleetNotificationsService, IFleetNotificationsService } from 'src/app/core/services/api/notifications';
import { FileTemplatesService, IFileTemplatesService } from 'src/app/core/services/api/templates';
import { IUsersService, UsersService } from 'src/app/core/services/api/users';
import { ISessionService, SessionService } from 'src/app/core/services/storage/session';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { ArtaiCommunityComponent } from './sub-header/components/artai-community/pages/artai-community.component';
import { CurrentFleetComponent } from './sub-header/components/current-fleet/pages/current-fleet.component';
import { DownloadHelpComponent } from './sub-header/components/download-help/pages/download-help.component';
import { GlobalSearchComponent } from './sub-header/components/global-search/pages/global-search.component';
import { GeneratePolicyLoadTemplateDialogComponent } from './sub-header/components/templates/components/generate-policy-load-template-dialog/pages/generate-policy-load-template-dialog.component';
import { TemplatesComponent } from './sub-header/components/templates/pages/templates.component';
import { SubHeaderComponent } from './sub-header/pages/sub-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatStepperModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    HowdenSharedModule,
    HowdenAutocompleteModule
  ],
  declarations: [
    SubHeaderComponent,
    GlobalSearchComponent,
    TemplatesComponent,
    ArtaiCommunityComponent,
    DownloadHelpComponent,
    CurrentFleetComponent,
    GeneratePolicyLoadTemplateDialogComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: ISessionService, useClass: SessionService },
    { provide: IFleetNotificationsService, useClass: FleetNotificationsService },
    { provide: IFileTemplatesService, useClass: FileTemplatesService },
    { provide: IClientGroupsService, useClass: ClientGroupsService },
    { provide: IUsersService, useClass: UsersService }
  ]
})
export class AppHeaderModule {
}
