import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { IsPlateNumberInFleetValidator } from 'src/app/shared/validators';
import { PolicyEditCertificateReplacementDialogComponent } from '../../policy-edit-certificate-replacement-dialog/pages/policy-edit-certificate-replacement-dialog.component';
import { PolicyEditChangeRiskMapper } from '../mappers';
import { PolicyEditChangeRiskViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-change-risk-dialog',
  templateUrl: './policy-edit-change-risk-dialog.component.html',
  styleUrls: ['./policy-edit-change-risk-dialog.component.scss']
})
export class PolicyEditChangeRiskDialogComponent implements OnInit {
  model: PolicyEditChangeRiskViewModel;

  get minDate(): Date { return Limits.minDate; }

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditCertificateReplacementDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private policySrv: IPolicyService,
    private plateNumberValidator: IsPlateNumberInFleetValidator
  ) {
    this.model = new PolicyEditChangeRiskViewModel(this.plateNumberValidator);
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.isBookingModeRequired(fleetId),
      this.policySrv.getBookingModes(this.inputData.id)
    ]).subscribe(([isBmRequired, bookingModes]) => {
      this.model.bookingModes = bookingModes;

      // Booking mode required
      const isBookingModeRequired = isBmRequired.result as boolean;

      if (isBookingModeRequired) {
        this.model.getControl(this.model.C_BOOKING_MODE_ID).addValidators([Validators.required]);
        this.model.getControl(this.model.C_BOOKING_MODE_ID).updateValueAndValidity();
      }

      this.uiBlockerSrv.unblock();
    });
  }

  onAccept() {
    const id = this.inputData.id as string;
    const request = PolicyEditChangeRiskMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.changePolicyRisk(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
