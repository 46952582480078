import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { BookingModeSearchResultViewModel } from '../models';

export abstract class BookingModeSearchMapper {
  static mapForSearch(source: IBookingModeSearchResult): BookingModeSearchResultViewModel {
    return {
      bookingModeId: source.bookingModeId,
      insuranceCompany: source.insuranceCompany,
      industry: source.industry,
      product: source.product,
      description: source.description
    } as BookingModeSearchResultViewModel;
  }
}
