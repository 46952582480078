import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploadComponent, IHowdenYesNoData, YesNoService } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { ICreateFleetResponse, IFleetSetting } from 'src/app/core/models/api/fleets';
import { IClient } from 'src/app/core/models/ida/clients';
import { IClientGroupsService } from 'src/app/core/services/api/clients';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { ClientViewModel, FleetCreateViewModel } from '../models';

@Component({
  selector: 'howden-fleet-create',
  templateUrl: './fleet-create.component.html',
  styleUrls: ['./fleet-create.component.scss']
})
export class FleetCreateComponent implements OnInit, OnDestroy {
  model = new FleetCreateViewModel();

  @ViewChild('file') fileRef!: FileUploadComponent;

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private confirmDialogSrv: YesNoService,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetsSrv: IFleetService,
    private clientGroupSrv: IClientGroupsService) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    const fleetId = '0';

    forkJoin([
      this.clientGroupSrv.getClientGroups(),
      this.fleetsSrv.getInsuranceCompanies(fleetId),
      this.fleetsSrv.getIndustries(fleetId),
      this.fleetsSrv.getVehicleTypes(fleetId),
      this.fleetsSrv.getVehicleUsages(fleetId)
    ]).subscribe(([
      clientGroups,
      insuranceCompanies,
      industries,
      vehicleTypes,
      vehicleUsages
    ]) => {
      this.model.clientGroups = clientGroups;
      this.model.insuranceCompanySettings = insuranceCompanies.map(x => <IFleetSetting>{
        id: x.insuranceCompanyId,
        description: x.name,
        enabled: true
      });
      this.model.industrySettings = industries.map(x => <IFleetSetting>{
        id: x.industryId,
        description: x.name,
        enabled: true
      });
      this.model.vehicleTypeSettings = vehicleTypes.map(x => <IFleetSetting>{
        id: x.erpId,
        description: x.description,
        enabled: true
      });
      this.model.vehicleUsageSettings = vehicleUsages.map(x => <IFleetSetting>{
        id: x.erpId,
        description: x.description,
        enabled: true
      });

      this.uiBlockerSrv.unblock();

      this.onChanges();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave() {
    const request = this.buildCreateRequestForm();

    this.uiBlockerSrv.block();

    this.fleetsSrv.create(request).pipe(
      tap((response: ICreateFleetResponse) => {
        const subtitle = response.policyFileProcessed === false
          ? $localize`:@@app.fleets.create.save.ko.dialog.subtitle:Se ha dado de alta la flota pero el fichero de carga de pólizas indicado no es válido, puede realizar la carga del fichero desde la opción de carga de pólizas`
          : $localize`:@@app.fleets.create.save.ok.dialog.subtitle:Finalizada correctamente`;

        const icon = response.policyFileProcessed === false
          ? 'warning'
          : 'task_alt';

        const options = {
          title: $localize`:@@app.fleets.create.save.dialog.title:Alta de flotas`,
          subTitle: subtitle,
          okActionText: $localize`:@@app.core.actions.ok:Aceptar`,
          icon: icon,
          allowCancel: false,
          question: '',
          moreText: '',
          disableClose: true
        } as IHowdenYesNoData;

        this.uiBlockerSrv.unblock();

        this._subscriptions.sink = this.confirmDialogSrv.open(options).subscribe(() => {
          if (response.fleetId) {
            this.sessionSrv.activateFleet(response.fleetId);
          }
          this.goToSearch();
        });
      })
    ).subscribe();
  }

  onCancel() {
    this.goToSearch();
  }

  onClientSelectionChanged(event: any[]) {
    this.model.fleetCompanies = event && event.length > 0 ? event.join(',') : null;
  }

  getClientGroupName(clientGroupId: string): string {
    return this.model.clientGroups?.find(x => x.groupId === clientGroupId)?.name ?? '';
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef.file
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_CLIENT_GROUP_ID)
      .valueChanges
      .subscribe(() => this.onClientGroupChanged());
  }

  private onClientGroupChanged(): void {
    const clientGroupId = this.model.clientGroupId;

    this.model.clients = new Array<ClientViewModel>();

    if (clientGroupId) {
      const clientGroup = this.model.clientGroups.find(x => x.groupId === clientGroupId);

      if (clientGroup) {
        this.clientGroupSrv.getClients(clientGroupId).subscribe((data: Array<IClient>) => {
          this.model.clients = data
            .filter(x => x.fiscalCode)
            .map(x => new ClientViewModel(x.clientId, x.alias, x.name, x.surname1, x.surname2, x.fiscalCode));
        });
      }
    }
  }

  private buildCreateRequestForm(): FormData {
    const formData = new FormData();
    const group = this.model.form;

    /* common data */

    for (const key of Object.keys(group.value)) {
      const value: any = group.value[key];

      if (typeof value !== 'undefined' && value !== null) {
        formData.append(key, value);
      }
    }

    /* insurance companies */

    const insuranceCompanies = this.model
      .insuranceCompanySettings
      .filter(x => x.enabled === true)
      .map(x => x.id);

    formData.append('insuranceCompanies', insuranceCompanies.join(','));

    /* industries */

    const industries = this.model
      .industrySettings
      .filter(x => x.enabled === true)
      .map(x => x.id);

    formData.append('industries', industries.join(','));

    /* vehicle types */

    const vehicleTypes = this.model
      .vehicleTypeSettings
      .filter(x => x.enabled === true)
      .map(x => x.id);

    formData.append('vehicleTypes', vehicleTypes.join(','));

    /* vehicle usages */

    const vehicleUsages = this.model
      .vehicleUsageSettings
      .filter(x => x.enabled === true)
      .map(x => x.id);

    formData.append('vehicleUsages', vehicleUsages.join(','));

    return formData;
  }

  private goToSearch(): void {
    this.router.navigate(['fleets/search']);
  }
}
