<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <div class="flex margin-top-10">
    <howden-table
      #notificationsTable
      class="small-font"
      paginable="local"
      sortable="local"
      filterable="global-local"
      [pageSize]="10"
      [cols]="model.columns"
      [data]="model.data"
      [buttons]="model.buttons"
      tableFixedWidth="2200px"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
  <div class="flex f-jc--center margin-top-10">
    <button  type="button" mat-button color="success" i18n="@@app.core.export" (click)="onExport()">Exportar</button>
  </div>
</div>
