import { IFleetControlReportRequest } from 'src/app/core/models/api/reports';
import { FleetControlReportViewModel } from '../models';

export abstract class FleetControlReportMapper {
  static mapForGeneration(source: FleetControlReportViewModel): IFleetControlReportRequest {
    return {
      fleetId: source.fleetId,
      itemTypes: source.itemTypes.filter(x => x.selected === true).map(x => x.id)
    } as IFleetControlReportRequest;
  }
}
