<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <!--Filters-->
  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--2"
        [formControlName]="model.C_YEAR"
        [allowNegative]="false"
        [precision]="0"
        i18n-label="@@app.dashboards.claims.year.label"
        label="Año"
      ></howden-decimal>

      <div class="mat-form-field inline">
        <div class="mat-form-field-wrapper">
          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onFilter()">
            <span i18n="@@app.core.actions.accept">Aceptar</span>
          </button>
        </div>
      </div>
    </div>
  </form>

  <mat-divider class="margin-bottom-10"></mat-divider>

  <!--Header-->
  @if (model.fleet) {
    <howden-dashboard-header
      [fleetName]="model.fleet.name"
      [clientName]="model.fleet.clientName"
      [clientFiscalCode]="model.fleet.clientFiscalCode"
      [cnaeDescription]="model.fleet.cnaeDescription"
    >
    </howden-dashboard-header>
  }

  @if (model.dashboardData || model.pivotData) {
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Cuadro de mando"  i18n-label="@app.dashboards.claims.sections.dashboards">
        <!--Dashboard-->

        @if (model.dashboardData) {
          <mat-grid-list cols="{{(cardLayout | async)?.columns }}" rowHeight="200px">
            <!--Mini Cards-->

            @for (mc of model.miniCardData; track mc) {
              <mat-grid-tile
                [colspan]="( cardLayout | async )?.miniCard?.cols"
                [rowspan]="( cardLayout | async )?.miniCard?.rows"
              >
                <howden-dashboard-mini-card
                  [isCurrency]="mc.isCurrency"
                  [isIncrease]="mc.isIncrease"
                  [duration]="mc.duration"
                  [icon]="mc.icon"
                  [title]="mc.title"
                  [value]="mc.value"
                  [color]="mc.color"Graph2
                  [percentValue]="mc.percentValue"
                >
                </howden-dashboard-mini-card>
              </mat-grid-tile>
            }

            <!--Charts-->

            <!-- Evolution -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Evolución siniestralidad'"
                i18n-title="@@app.dashboards.claims.claims.evolution.by.month.chart.title"
                [subtitle]="'Evolución siniestros vs riesgos por mes'"
                i18n-subtitle="@@app.dashboards.claims.evolution.by.month.chart.subtitle"
              >
                @if (model.dashboardData.claimsRisksByMonth) {
                  <howden-claim-evolution-chart [data]="model.dashboardData.claimsRisksByMonth">
                  </howden-claim-evolution-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Claims by type -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Siniestros por tipo'"
                i18n-title="@@app.dashboards.claims.claims.by.type.chart.title"
                [subtitle]="'Total de siniestros clasificados por tipo de siniestro'"
                i18n-subtitle="@@app.dashboards.claims.claims.by.type.chart.subtitle"
              >
                @if (model.dashboardData.claimsByType) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.claimsByType"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Claims by situation -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Siniestros por situación'"
                i18n-title="@@app.dashboards.claims.claims.by.type.chart.title"
                [subtitle]="'Total de siniestros clasificados por situación'"
                i18n-subtitle="@@app.dashboards.claims.claims.by.type.chart.subtitle"
              >
                @if (model.dashboardData.claimsBySubState) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.claimsBySubState"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Claims by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Siniestros por mes'"
                i18n-title="@@app.dashboards.claims.claims.by.month.chart.title"
                [subtitle]="'Total de siniestros clasificados por mes'"
                i18n-subtitle="@@app.dashboards.claims.claims.by.month.chart.subtitle"
              >
                @if (model.dashboardData.claimsByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.claimsByMonth"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Claim cost by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Coste siniestral por mes'"
                i18n-title="@@app.dashboards.claims.claim.cost.by.month.chart.title"
                [subtitle]="'Coste siniestral total clasificado por mes'"
                i18n-subtitle="@@app.dashboards.claims.claim.cost.by.month.chart.subtitle"
              >
                @if (model.dashboardData.claimCostByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.claimCostByMonth">
                  </howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Non intermediated claims by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Siniestros no intermediados por mes'"
                i18n-title="@@app.dashboards.claims.non.intermediated.claims.by.month.chart.title"
                [subtitle]="'Total de siniestros no intermediados clasificados por mes'"
                i18n-subtitle="@@app.dashboards.claims.non.intermediated.claims.by.month.chart.subtitle"
              >
                @if (model.dashboardData.notItermediatedClaimsByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.notItermediatedClaimsByMonth">
                  </howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Non intermediated claim cost by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Coste siniestral no intermediados por mes'"
                i18n-title="@@app.dashboards.claims.non.intermediated.claim.cost.by.month.chart.title"
                [subtitle]="'Coste siniestral total no intermediados clasificado por mes'"
                i18n-subtitle="@@app.dashboards.claims.non.intermediated.claim.cost.by.month.chart.subtitle"
              >
                @if (model.dashboardData.notItermediatedClaimCostByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.notItermediatedClaimCostByMonth">
                  </howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Payments by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Pagos por mes'"
                i18n-title="@@app.dashboards.claims.payments.by.month.chart.title"
                [subtitle]="'Total de pagos clasificados por mes'"
                i18n-subtitle="@@app.dashboards.claims.payments.by.month.chart.subtitle"
              >
                @if (model.dashboardData.paymentByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.paymentByMonth"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Recovery (recobro) by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Recobros por mes'"
                i18n-title="@@app.dashboards.claims.recoveries.by.month.chart.title"
                [subtitle]="'Total de recobros clasificados por mes'"
                i18n-subtitle="@@app.dashboards.claims.recoveries.by.month.chart.subtitle"
              >
                @if (model.dashboardData.recoveryByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.recoveryByMonth"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Claims by reason -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Siniestros por causa'"
                i18n-title="@@app.dashboards.claims.claims.by.reason.chart.title"
                [subtitle]="'Total Siniestros clasificados por causa de siniestralidad'"
                i18n-subtitle="@@app.dashboards.claims.claims.by.reason.chart.subtitle"
              >
                @if (model.dashboardData.claimsByReason) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.claimsByReason"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Reservations by month -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Reservas por mes'"
                i18n-title="@@app.dashboards.claims.reservations.by.month.chart.title"
                [subtitle]="'Total de reservas clasificadas por mes'"
                i18n-subtitle="@@app.dashboards.claims.reservations.by.month.chart.subtitle"
              >
                @if (model.dashboardData.reservationsByMonth) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.reservationsByMonth"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- Reservations by reason -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
              <howden-dashboard-card
                [title]="'Reservas por causa'"
                i18n-title="@@app.dashboards.claims.reservations.by.reason.chart.title"
                [subtitle]="'Total de reservas clasificadas por causa de siniestralidad'"
                i18n-subtitle="@@app.dashboards.claims.reservations.by.reason.chart.subtitle"
              >
                @if (model.dashboardData.reservationsByReason) {
                  <howden-dashboard-bar-chart [data]="model.dashboardData.reservationsByReason"></howden-dashboard-bar-chart>
                }
              </howden-dashboard-card>
            </mat-grid-tile>
          </mat-grid-list>
        }
      </mat-tab>

      <mat-tab label="Siniestralidad global" i18n-label="@app.dashboards.claims.sections.global.claims">
        @if (model.pivotData) {
          <mat-grid-list cols="{{(cardLayout | async)?.columns }}" rowHeight="200px">
            <!-- By root policy -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
              <howden-dashboard-card [title]="'Por póliza madre'" i18n-title="@@app.dashboards.claims.pivot.by.root.policy.title">
                <howden-pivot-table-component [pivotTable]="model.pivotData.byRootPolicy"></howden-pivot-table-component>
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- By client -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
              <howden-dashboard-card [title]="'Por cliente'" i18n-title="@@app.dashboards.claims.pivot.by.client.title">
                <howden-pivot-table-component [pivotTable]="model.pivotData.byClient"></howden-pivot-table-component>
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- By warranties -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
              <howden-dashboard-card [title]="'Por garantías contratadas'" i18n-title="@@app.dashboards.claims.pivot.by.warranties.title">
                <howden-pivot-table-component [pivotTable]="model.pivotData.byBookingMode"></howden-pivot-table-component>
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- By insurance company -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
              <howden-dashboard-card [title]="'Por compañía aseguradora'" i18n-title="@@app.dashboards.claims.pivot.by.insurance.company.title">
                <howden-pivot-table-component [pivotTable]="model.pivotData.byClient"></howden-pivot-table-component>
              </howden-dashboard-card>
            </mat-grid-tile>

            <!-- By vehicle type -->

            <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
              <howden-dashboard-card [title]="'Por tipo de vehículo'" i18n-title="@@app.dashboards.claims.pivot.by.vehicle.type.title">
                <howden-pivot-table-component [pivotTable]="model.pivotData.byVehicleType"></howden-pivot-table-component>
              </howden-dashboard-card>
            </mat-grid-tile>
          </mat-grid-list>
        }
      </mat-tab>
    </mat-tab-group>
  }
</div>
