import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IOfficeService } from 'src/app/core/services/api/offices';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicyEditOfficeUpdateMapper } from '../mappers';
import { PolicyEditOfficeUpdateViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-office-update-dialog',
  templateUrl: './policy-edit-office-update-dialog.component.html',
  styleUrls: ['./policy-edit-office-update-dialog.component.scss']
})
export class PolicyEditOfficeUpdateDialogComponent implements OnInit {
  model = new PolicyEditOfficeUpdateViewModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditOfficeUpdateDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private officeSrv: IOfficeService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.get(this.inputData.id),
      this.officeSrv.getOffices()
    ]).subscribe(([
      policy,
      offices
    ]) => {
      this.model.policy = policy;
      this.model.offices = offices.filter(x => x.code !== policy.officeCode);

      this.uiBlockerSrv.unblock();
    });
  }

  onAccept() {
    const id = this.inputData.id;
    const request = PolicyEditOfficeUpdateMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.updatePolicyOffice(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  getOfficeName(officeId: string): string {
    return this.model.offices?.find(x => x.officeId === officeId)?.name ?? '';
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
