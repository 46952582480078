import { IPolicyCancellation } from 'src/app/core/models/api/policies';
import { PolicyEditCancellationViewModel } from '../models';

export abstract class PolicyEditCancellationMapper {
  static mapForUpdate(source: PolicyEditCancellationViewModel): IPolicyCancellation {
    return {
      operationDate: source.operationDate,
      cancellationNotificationDate: source.cancellationNotificationDate,
      cancellationReasonId: source.cancellationReasonId
    } as IPolicyCancellation;
  }
}
