<div class="flex f-jc--end f-gap--10px f-ai--center margin-top-10">
  <button
    type="button"
    mat-mini-fab
    color="primary"
    i18n-matTooltip="@@app.units.edit.paralizations.actions.create"
    matTooltip="Nueva paralización"
    (click)="onNewParalization()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>

<div class="flex f-gap--10px margin-top-10">
  <howden-table
    class="small-font"
    keyField="paralizationId"
    paginable="local"
    sortable="local"
    filterable="global-local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
