<div class="flex margin-top-10">
  <howden-table
    class="small-font"
    keyField="claimId"
    paginable="local"
    sortable="local"
    filterable="global-local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
