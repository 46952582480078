import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicyEditBonusTypeUpdateMapper } from '../mappers';
import { PolicyEditBonusTypeUpdateViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-bonus-type-update-dialog',
  templateUrl: './policy-edit-bonus-type-update-dialog.component.html',
  styleUrls: ['./policy-edit-bonus-type-update-dialog.component.scss']
})
export class PolicyEditBonusTypeUpdateDialogComponent {
  model = new PolicyEditBonusTypeUpdateViewModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditBonusTypeUpdateDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  onAccept() {
    const id = this.inputData.id;
    const request = PolicyEditBonusTypeUpdateMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.updateBonusType(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
