<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  @for (board of model.data; track board) {
    <div class="flex f-gap--10px margin-bottom-25 pvtTable-container">
      <div class="f-basis--12">
        <table class="table_general pvtTable">
          <thead>
            <tr>
              <th class="text-center"><h4>{{ board.description }}</h4></th>
              <th class="pvtColumn text-right" i18n="@@app.bonus.board.header.units.label">Unidades</th>
              <th class="pvtColumn text-center" i18n="@@app.bonus.board.header.bookingMode.label">Modo de contratación</th>
              <th class="pvtColumn text-right" i18n="@@app.bonus.board.header.netBonus.label">Prima neta (Unitaria)</th>
              <th class="pvtColumn text-right" i18n="@@app.bonus.board.header.totalBonus.label">Prima total (Unitaria)</th>
            </tr>
          </thead>
          @for (client of board.clients; track client) {
            <tbody>
              <tr class="text-bold">
                <td colspan="5" class="pvtRow primary-row-group">{{ client.name }}</td>
              </tr>
              @for (detail of client.details; track detail) {
                <tr>
                  <td class="pvtRow secondary-row-group text-right">{{ detail.vehicleType }}-{{ detail.movementArea }}</td>
                  <td class="pvtRow secondary-row-group text-right text-bold">{{ detail.unitCount | number }}</td>
                  <td class="text-center">{{ detail.bookingMode }}</td>
                  <td class="text-right">{{ detail.netBonus | number : '1.2-4' }}</td>
                  <td class="text-right">{{ detail.totalBonus | number : '1.2-4' }}</td>
                </tr>
              }
            </tbody>
          }
          <tfoot>
            <tr>
              <td class="text-bold">{{ board.unitCountTotals.description }}</td>
              <td class="text-bold text-right">{{ board.unitCountTotals.unitCount | number }}</td>
            </tr>
            @for (total of board.totalsByClient; track total) {
              <tr>
                <td colspan="2"></td>
                <td class="pvtRow text-bold secondary-row-group">{{ total.description }}</td>
                <td class="pvtRow text-bold text-right">{{ total.netAmount | number : '1.2-2' }}</td>
                <td class="pvtRow text-bold text-right">{{ total.totalAmount | number : '1.2-2' }}</td>
              </tr>
            }
            <tr>
              <td colspan="2"></td>
              <td class="pvtRow text-bold text-right">{{ board.clientTotals.description }}</td>
              <td class="pvtRow text-bold text-right">{{ board.clientTotals.netAmount | number : '1.2-2' }}</td>
              <td class="pvtRow text-bold text-right">{{ board.clientTotals.totalAmount | number : '1.2-2' }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }
</div>
