import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenMainLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent
} from '@howdeniberia/core-front';
import { APP_CONSTANTS } from './core/constants';
import { CertificatesModule } from './features/certificates/certtificates.module';
import { ClaimsModule } from './features/claims/claims.module';
import { DashboardsModule } from './features/dashboards/dashboards.module';
import { EndorsementsModule } from './features/endorsements/endorsements.module';
import { FleetsModule } from './features/fleets/fleets.module';
import { NotificationsModule } from './features/notifications/notifications.module';
import { PoliciesModule } from './features/policies/policies.module';
import { ProcessesModule } from './features/processes/processes.module';
import { ReportsModule } from './features/reports/reports.module';
import { UnitsModule } from './features/units/units.module';

export const AppRoutes = [
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    path: '',
    component: HowdenMainLayoutComponent,
    data: {
      isLayout: true,
      roles: [...APP_CONSTANTS.ROLES]
    },
    children: [
      { path: '', loadChildren: () => FleetsModule },
      { path: '', loadChildren: () => UnitsModule },
      { path: '', loadChildren: () => PoliciesModule },
      { path: '', loadChildren: () => EndorsementsModule },
      { path: '', loadChildren: () => ReportsModule },
      { path: '', loadChildren: () => NotificationsModule },
      { path: '', loadChildren: () => CertificatesModule },
      { path: '', loadChildren: () => ProcessesModule },
      { path: '', loadChildren: () => DashboardsModule },
      { path: '', loadChildren: () => ClaimsModule }
    ]
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.route.notauthorized: No autorizado`,
    component: NotAuthorizedComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' }
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
