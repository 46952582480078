import { FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { IUnitSearchRequest } from 'src/app/core/models/api/units';
import { UnitSearchResultViewModel } from './unit-search-result.viewmodel';

export class UnitSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IUnitSearchRequest;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_PLATE_NUMBER = 'plateNumber';
  readonly C_CAR_NUMBER = 'carNumber';
  readonly C_WITHOUT_POLICIES = 'withoutPolicies';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_CAR_NUMBER]: new FormControl('', []),
      [this.C_PLATE_NUMBER]: new FormControl('', []),
      [this.C_WITHOUT_POLICIES]: new FormControl(false, [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IUnitSearchRequest;
  }

  data: UnitSearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'plateNumber',
      fieldHeader: $localize`:@@app.units.search.plateNumber.column:Matrícula`,
      canSort: true
    },
    {
      fieldName: 'owner',
      fieldHeader: $localize`:@@app.units.search.owner.column:Propietario`,
      canSort: true
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.units.search.description.column:Marca/Modelo/Version`,
      canSort: false
    },
    {
      fieldName: 'vehicleType',
      fieldHeader: $localize`:@@app.units.search.vehicleType.column:Tipo de vehículo`,
      canSort: true
    },
    {
      fieldName: 'vehicleUsage',
      fieldHeader: $localize`:@@app.units.search.vehicleUsage.column:Uso vehículo`,
      canSort: true
    },
    {
      fieldName: 'movementArea',
      fieldHeader: $localize`:@@app.units.search.movementArea.column:Ámbito circulación`,
      canSort: true
    },
    {
      fieldName: 'carNumber',
      fieldHeader: $localize`:@@app.units.search.carNumber.column:Nº. Coche`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.units.search.actions.edit.tooltip:Editar`
  }, {
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.units.search.actions.info.tooltip:Información`
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get searchRequest(): IUnitSearchRequest {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get plateNumber(): string | undefined {
    return this._filterForm.get(this.C_PLATE_NUMBER)?.value;
  }

  set plateNumber(value: string | undefined) {
    this._filterForm.get(this.C_PLATE_NUMBER)?.setValue(value);
  }

  get carNumber(): string | undefined {
    return this._filterForm.get(this.C_CAR_NUMBER)?.value;
  }

  set carNumber(value: string | undefined) {
    this._filterForm.get(this.C_CAR_NUMBER)?.setValue(value);
  }

  get withoutPolicy(): boolean | undefined {
    return this._filterForm.get(this.C_WITHOUT_POLICIES)?.value;
  }

  set withoutPolicy(value: boolean | undefined) {
    this._filterForm.get(this.C_WITHOUT_POLICIES)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.plateNumber = this.plateNumber;
    this.searchRequest.carNumber = this.carNumber;
    this.searchRequest.withoutPolicies = this.withoutPolicy;
  }
}
