<div class="flex f-jc--end f-gap--10px f-ai--center margin-top-10 margin-bottom-10">

  <button type="button" mat-raised-button color="cancel" (click)="onCancelRisks()" [disabled]="!model.allowRiskCancellation">
    <span i18n="@@app.units.edit.policy.risks.actions.delete">Dar de baja</span>
  </button>

</div>

<div>
  <howden-table
    #unitPoliciesTable
    class="small-font"
    keyField="policyRiskId"
    paginable="none"
    sortable="local"
    filterable="global-local"
    [multiselect]="true"
    [hideRowCheckbox]="model.hideSelection"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    (buttonClicked)="onPolicyAction($event)"
    (selectedRowsChange)="onPolicySelectionChanged($event)"
  >
  </howden-table>
</div>
