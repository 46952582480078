import { IDetailedEndorsement } from 'src/app/core/models/ida/production';
import { IDetailedEndorsementView } from '../models';

export abstract class DetailedEndorsementMapper {
  static map(source: Array<IDetailedEndorsement>): Array<IDetailedEndorsementView> {
    return source.map(value => ({
        endorsementId: value.endorsementId,
        endorsementType: value.endorsementType,
        requestDate: value.requestDate,
        alias: value.alias,
        description: value.description,
        effectDate: value.effectDate,
        expirationDate: value.expirationDate,
        cancellationDate: value.cancellationDate,
        office: value.office,
        endorsementStatus: value.endorsementStatus
      } as IDetailedEndorsementView));
  }
}
