import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HowdenTableComponent, IButtonActionData, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IUnitSearchResult } from 'src/app/core/models/api/units';
import { IFleetService } from 'src/app/core/services/api/fleets/fleet.contract';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { UnitSummaryDialogComponent } from 'src/app/features/units/unit-summary-dialog/pages/unit-summary-dialog.component';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyEditAssignRisksMapper, UnitSearchMapper } from '../mappers';
import { PolicyEditAssignRisksViewModel, PolicyEditSearchRisksViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-assign-risks',
  templateUrl: './policy-edit-assign-risks.component.html',
  styleUrls: ['./policy-edit-assign-risks.component.scss']
})
export class PolicyEditAssignRisksComponent implements OnChanges, OnDestroy {
  model = new PolicyEditAssignRisksViewModel();
  searchModel = new PolicyEditSearchRisksViewModel();

  get minDate(): Date { return Limits.minDate; }

  @Input() policyId = '';

  @ViewChild('unitSearchTable') unitSearchTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private insuranceCompanySrv: IInsuranceCompanyService,
    private policySrv: IPolicyService,
    private unitSrv: IUnitService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.isBookingModeRequired(fleetId),
      this.policySrv.getBookingModes(this.policyId),
      this.policySrv.get(this.policyId)
    ]).pipe(
      tap(([fleetCompanies, isBmRequired, bookingModes, policy]) => {
        this.model.policy = policy;
        this.model.fleetCompanies = fleetCompanies;
        this.model.bookingModes = bookingModes;
        this.searchModel.request.fleetId = fleetId;

        // Booking mode required
        const isBookingModeRequired = isBmRequired.result as boolean;
        if (isBookingModeRequired) {
          this.model.getControl(this.model.C_BOOKING_MODE_ID).addValidators([Validators.required]);
        }

        // Mediation codes
        this.insuranceCompanySrv.getMediationCodes(policy.insuranceCompanyId).subscribe(data => {
          this.model.mediationCodes = data;
          if (policy.mediationCodeId) {
            this.model.mediationCodeId = policy.mediationCodeId;
          } else {
            const defaultMediationCode = this.model.mediationCodes?.find(x => x.isDefault === true);
            if (defaultMediationCode) {
              this.model.mediationCodeId = defaultMediationCode.mediationCodeId;
            }
          }
        });

        this.onChanges();

        this.refreshSearch();

        this.uiBlockerSrv.unblock();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.searchModel.updateServerSideConfig(event);
    this.refreshSearch();
  }

  onAccept() {
    const request = PolicyEditAssignRisksMapper.mapForUpdate(this.model);

    request.riskIds = this.unitSearchTable.selectedRows();

    this.uiBlockerSrv.block();
    this.policySrv.assignRisks(this.policyId, request).pipe(
      tap({
        next: () => {
          const title: string = $localize`:@@app.policies.edit.assign.risks.confirm.title:Asignación de riesgos`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => {
            this.model.form.reset();
            this.refreshSearch();
          });
        },
        complete: () => this.uiBlockerSrv.unblock()
      })
    ).subscribe();
  }

  onAction(event: IButtonActionData): void {
    const inputData = { id: event.row.unitId };
    const dialogRef = this.dialog.open(UnitSummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  get canAssign(): boolean {
    return typeof this.unitSearchTable !== 'undefined'
      && this.unitSearchTable !== null
      && typeof this.unitSearchTable.selectedRows !== 'undefined'
      && this.unitSearchTable.selectedRows() !== null
      && this.unitSearchTable.selectedRows().length > 0
      && this.model.form.valid;
  }

  private refreshSearch(): void {
    this.searchModel.updateServerSideFilters();

    this.unitSrv.search(this.searchModel.request).subscribe((searchResult: IPageOf<IUnitSearchResult>) => {
      this.searchModel.length = searchResult.totalCount;
      this.searchModel.data = searchResult.data?.map((source) => UnitSearchMapper.mapForSearch(source));
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.searchModel.filterForm.valueChanges.subscribe(() => {
      this.searchModel.request.pageNumber = 0;
      this.searchModel.updateServerSideFilters();
      this.refreshSearch();
    });
  }
}
