<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title>
      <span class="title-text" i18n="@@app.policies.edit.colective.replacement.title">Reemplazo / Traspaso</span>
      <br />
      <span class="subtitle-text" i18n="@@app.policies.edit.colective.replacement.subtitle">
        Por favor, indique los datos del reemplazo/traspaso de la póliza.
      </span>
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.operationDate.label">Fecha operación</mat-label>
          <input
            matInput
            [matDatepicker]="operationDatePicker"
            [formControlName]="model.C_OPERATION_DATE"
            [howdenControlError]="operationDateError"
            [min]="minDate"
          />
          <mat-error #operationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #operationDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.description.label">Denominación</mat-label>
          <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
          <mat-error #descriptionError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.holder.label">Tomador</mat-label>
          <input
            matInput
            [formControlName]="model.C_HOLDER_ID"
            [matAutocomplete]="holderSelect"
            [howdenControlError]="holderIdError"
          />
          <mat-autocomplete
            #holderSelect="matAutocomplete"
            [displayWith]="getFleetCompanyName.bind(this)"
            >
            @for (item of model.filteredFleetCompanies | async; track item.companyId) {
              <mat-option [value]="item.companyId">
                {{ item.clientName }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #holderIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.insuranceCompanyId.label">Aseguradora</mat-label>
          <input
            matInput
            [formControlName]="model.C_INSURANCE_COMPANY_ID"
            [matAutocomplete]="insuranceCompanySelect"
            [howdenControlError]="insuranceCompanyIdError"
          />
          <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
            @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
              <mat-option [value]="item.insuranceCompanyId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #insuranceCompanyIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.industryId.label">Ramo</mat-label>
          <input
            matInput
            [formControlName]="model.C_INDUSTRY_ID"
            [matAutocomplete]="industrySelect"
            [howdenControlError]="industryIdError"
          />
          <mat-autocomplete #industrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
            @for (item of model.filteredIndustries | async; track item.industryId) {
              <mat-option [value]="item.industryId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #industryIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.productId.label">Producto</mat-label>
          <input
            matInput
            [formControlName]="model.C_PRODUCT_ID"
            [matAutocomplete]="productSelect"
            [howdenControlError]="productIdError"
          />
          <mat-autocomplete #productSelect="matAutocomplete" [displayWith]="getProductName.bind(this)">
            @for (item of model.filteredProducts | async; track item.productId) {
              <mat-option [value]="item.productId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #productIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.policyNumber.label">Nº. Póliza</mat-label>
          <input matInput [formControlName]="model.C_POLICY_NUMBER" [howdenControlError]="policyNumberError" maxlength="20" />
          <mat-error #policyNumberError></mat-error>
          @if (model.policyNumberControl.hasError('notAvailable')) {
            <mat-error
            i18n="@@app.policies.edit.number.update.not.available.error">El número de póliza indicado no está disponible.</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.officeId.label">Oficina</mat-label>
          <input
            matInput
            [formControlName]="model.C_OFFICE_CODE"
            [matAutocomplete]="officeSelect"
            [howdenControlError]="officeCodeError"
          />
          <mat-autocomplete #officeSelect="matAutocomplete" [displayWith]="getOfficeDescription.bind(this)">
            @for (item of model.filteredOffices | async; track item.officeId) {
              <mat-option [value]="item.code">
                {{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #officeCodeError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.mediationCodeId.label">Código mediación</mat-label>
          <input
            matInput
            [formControlName]="model.C_MEDIATION_CODE_ID"
            [matAutocomplete]="mediationCodeSelect"
            [howdenControlError]="mediationCodeIdError"
          />
          <mat-autocomplete #mediationCodeSelect="matAutocomplete" [displayWith]="getMediationCodeDescription.bind(this)">
            @for (item of model.filteredMediationCodes | async; track item.mediationCodeId) {
              <mat-option [value]="item.mediationCodeId">
                {{ item.code }}-{{ item.description }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #mediationCodeIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.collectionManagerId.label">Gestor de cobro</mat-label>
          <input
            matInput
            [formControlName]="model.C_COLLECTION_MANAGER_ID"
            [matAutocomplete]="collectionManagerSelect"
            [howdenControlError]="collectionManagerIdError"
          />
          <mat-autocomplete #collectionManagerSelect="matAutocomplete" [displayWith]="getCollectionManagerName.bind(this)">
            @for (item of model.filteredCollectionManagers | async; track item.collectionManagerId) {
              <mat-option [value]="item.collectionManagerId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #collectionManagerIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.currencyId.label">Divisa</mat-label>
          <input
            matInput
            [formControlName]="model.C_CURRENCY_ID"
            [matAutocomplete]="currencySelect"
            [howdenControlError]="currencyIdError"
          />
          <mat-autocomplete #currencySelect="matAutocomplete" [displayWith]="getCurrencyDescription.bind(this)">
            @for (item of model.filteredCurrencies | async; track item.currencyId) {
              <mat-option [value]="item.currencyId">
                {{ item.description }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #currencyIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.paymentTypeId.label">Forma de pago</mat-label>
          <mat-select [formControlName]="model.C_PAYMENT_TYPE_ID" [howdenControlError]="paymentTypeIdError">
            @for (item of model.paymentTypes | howdenArraySort: 'description'; track item.erpId) {
              <mat-option [value]="item.erpId">
                {{ item.description }}
              </mat-option>
            }
          </mat-select>
          <mat-error #paymentTypeIdError></mat-error>
        </mat-form-field>

      </div>

      <div class="flex f-gap--10px">

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.billingModelId.label">Modelo facturación</mat-label>
          <mat-select [formControlName]="model.C_BILLING_MODEL_ID" [howdenControlError]="billingModelIdError">
            @for (item of model.billingModels | howdenArraySort: 'description'; track item.billingModelId) {
              <mat-option [value]="item.billingModelId">
                {{ item.description }}
              </mat-option>
            }
          </mat-select>
          <mat-error #billingModelIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.duration.label">Duración</mat-label>
          <mat-select [formControlName]="model.C_DURATION" [howdenControlError]="durationError">
            @for (item of model.policyDurations | howdenArraySort: 'description'; track item.erpId) {
              <mat-option [value]="item.erpId">
                {{ item.description }}
              </mat-option>
            }
          </mat-select>
          <mat-error #durationError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.colective.replacement.expirationDate.label">Fecha Vencimiento</mat-label>
          <input
            matInput
            [matDatepicker]="expirationDatePicker"
            [formControlName]="model.C_EXPIRATION_DATE"
            [howdenControlError]="expirationDateError"
            [min]="minDate"
          />
          <mat-error #expirationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDatePicker></mat-datepicker>
        </mat-form-field>

      </div>

      <div class="flex f-gap--10px margin-bottom-10">
        <mat-slide-toggle
          class="f-basis--6 f-as--center"
          [formControlName]="model.C_KEEP_CURRENT_INSURED"
        i18n="@@app.policies.edit.colective.replacement.keeyCurrentInsured.label">Mantener asegurado actual</mat-slide-toggle>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
