import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { ILTABaseReportParams, ILTAHistory } from 'src/app/core/models/api/lta';
import { ILTAService } from './lta.contract';

@Injectable({
  providedIn: 'root'
})
export class LTAService implements ILTAService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getBaseFrequencies(fleetId: string): Observable<Array<ILTAHistory>> {
    return this.httpClient.get<Array<ILTAHistory>>(APP_CONSTANTS.API_NET_FLOTAS, `/lta/${fleetId}/base-frequencies`);
  }

  getBaseReportParams(fleetId: string): Observable<ILTABaseReportParams> {
    return this.httpClient.get<ILTABaseReportParams>(APP_CONSTANTS.API_NET_FLOTAS, `/lta/${fleetId}/base-params`);
  }
}
