import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { FleetEditTaxDialogComponent } from '../../../dialogs/fleet-edit-tax-dialog/pages/fleet-edit-tax-dialog.component';
import { FleetEditTaxesMapper } from '../mappers';
import { FleetEditTaxesViewModel } from '../models';

@Component({
  selector: 'howden-fleet-edit-taxes',
  templateUrl: './fleet-edit-taxes.component.html',
  styleUrls: ['./fleet-edit-taxes.component.scss']
})
export class FleetEditTaxesComponent implements OnInit, OnDestroy {
  model = new FleetEditTaxesViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private masterSrv: IMasterService
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.edit(event);
    } else {
      this.delete(event);
    }
  }

  onNewTax(): void {
    const inputData = { id: null };
    const dialogRef = this.dialog.open(FleetEditTaxDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(_ => this.refresh());
  }

  private edit(event: IButtonActionData): void {
    const inputData = { id: event.row.taxId };
    const dialogRef = this.dialog.open(FleetEditTaxDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(_ => this.refresh());
  }

  private delete(event: IButtonActionData): void {
    const fleetId = this.sessionSrv.activeFleet;
    const taxId = event.row.taxId;
    const title: string = $localize`:@@app.fleets.edit.taxes.delete.title:Borrado de impuestos`;
    const question: string = $localize`:@@app.fleets.edit.taxes.delete.subtitle:Se borrará el impuesto, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.fleets.edit.taxes.delete.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();
          this.fleetSrv.deleteTax(fleetId, taxId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private refresh(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getTaxes(fleetId),
      this.masterSrv.getPaymentTypes()
    ]).subscribe(([taxes, paymentTypes]) => {
      this.model.data = FleetEditTaxesMapper.mapForSearch(taxes, paymentTypes);

      this.uiBlockerSrv.unblock();
    });
  }
}
