import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { IResponse } from '@howdeniberia/core-front';
import { Observable, catchError, map, of } from 'rxjs';
import { IIsPlateNumberAvailable } from 'src/app/core/models/api/units';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';

@Injectable({ providedIn: 'root' })
export class IsPlateNumberInFleetValidator implements AsyncValidator {
  constructor(private unitsSrv: IUnitService, private sessionSrv: ISessionService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of({ notAvailable: true });
    }
    const request = { fleetId: this.sessionSrv.activeFleet, plateNumber: control.value } as IIsPlateNumberAvailable;
    return this.unitsSrv.isPlateNumberAvailable(request).pipe(
      map((response: IResponse<boolean>) => response.result ? { notAvailable: true } : null),
      catchError(() => of({ notAvailable: true }))
    );
  }
}
