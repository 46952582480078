import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IUnitService } from 'src/app/core/services/api/units';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PlateNumberValidator } from 'src/app/shared/validators';
import { UnitPlateNumberChangeViewModel } from '../models';

@Component({
  selector: 'howden-unit-plate-number-change-dialog',
  templateUrl: './unit-plate-number-change-dialog.component.html',
  styleUrls: ['./unit-plate-number-change-dialog.component.scss']
})
export class UnitPlateNumberChangeDialogComponent implements OnInit {
  model: UnitPlateNumberChangeViewModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<UnitPlateNumberChangeDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private plateNumberValidator: PlateNumberValidator,
    private unitsSrv: IUnitService
  ) {
    this.model = new UnitPlateNumberChangeViewModel(this.plateNumberValidator);
  }

  ngOnInit(): void {
    this.model.originalPlateNumber = this.inputData.plateNumber;
    this.model.plateNumber = this.inputData.plateNumber;
  }

  onAccept() {
    const id = this.inputData.id;
    const pn = this.model.plateNumber as string;

    this.uiBlockerSrv.block();
    this.unitsSrv.changePlateNumber(id, pn).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
