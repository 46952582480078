<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex margin-bottom-25">
      <button type="button" class="f-basis--4" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>
    </div>

    <div class="flex margin-top-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.units.query.label">Filtro</mat-label>
        <input matInput [formControlName]="model.C_QUERY" />
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <div class="f-basis--3">
        <h4 i18n="@@app.reports.units.vehicleType.label">Tipo vehículo</h4>
        <mat-selection-list>
          @for (itemType of model.vehicleTypes; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>

      <div class="f-basis--3">
        <h4 i18n="@@app.reports.units.vehicleUsage.label">Uso vehículo</h4>
        <mat-selection-list>
          @for (itemType of model.vehicleUsages; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>

      <div class="f-basis--3">
        <h4 i18n="@@app.reports.units.movementArea.label">Ámbito de circulación</h4>
        <mat-selection-list>
          @for (itemType of model.movementAreas; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>

      <div class="f-basis--3">
        <h4 i18n="@@app.reports.units.status.label">Estado</h4>
        <mat-selection-list>
          @for (itemType of model.statuses; track itemType) {
            <mat-list-option [(selected)]="itemType.selected">
              {{ itemType.description }}
            </mat-list-option>
          }
        </mat-selection-list>
      </div>
    </div>
  </form>
</div>
