import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, tap } from 'rxjs';
import { IAssignFleetCompany } from 'src/app/core/models/api/fleets';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { FleetEditClientsViewModel } from '../models';

@Component({
  selector: 'howden-fleet-edit-clients',
  templateUrl: './fleet-edit-clients.component.html',
  styleUrls: ['./fleet-edit-clients.component.scss']
})
export class FleetEditClientsComponent implements OnInit, OnDestroy {
  model = new FleetEditClientsViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetsSrv: IFleetService
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave(): void {
    const fleetId = this.sessionSrv.activeFleet;
    const request = { companyId: this.model.clientId } as IAssignFleetCompany;

    this.uiBlockerSrv.block();

    this.fleetsSrv.assignCompany(fleetId, request).pipe(
      tap(() => {
        const title: string = $localize`:@@app.fleets.edit.clients.save.dialog.title:Asignación de clientes`;

        this.uiBlockerSrv.unblock();

        this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.refresh());
      })
    ).subscribe();
  }

  onClose(): void {
    this.navigateToSearch();
  }

  private navigateToSearch(): void {
    this.router.navigate(['fleets/search']);
  }

  private refresh(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetsSrv.getCompanies(fleetId),
      this.fleetsSrv.getNotAssignedCompanies(fleetId)
    ]).subscribe(([
      currentCompanies,
      notAssignedCompanies
    ]) => {
      this.model.currentCompanies = currentCompanies;
      this.model.notAssignedCompanies = notAssignedCompanies;

      this.uiBlockerSrv.unblock();
    });
  }
}
