import { IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { ICirculationArea, ICirculationAreaSearchRequest } from 'src/app/core/models/api/units';

export abstract class ICirculationAreaService {
  abstract get(id: string): Observable<ICirculationArea>;
  abstract search(request: ICirculationAreaSearchRequest): Observable<IPageOf<ICirculationArea>>;
  abstract upsert(request: ICirculationArea): Observable<void>;
  abstract delete(id: string): Observable<void>;
}
