import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IPolicyBillingGroup, IPolicyBillingGroupBonus } from 'src/app/core/models/api/policies';

export class PolicyEditBonusesViewModel {
  private _form: FormGroup;
  private _billingGoups: Array<IPolicyBillingGroup> = [];

  private _filteredBillingGroups: Observable<Array<IPolicyBillingGroup>>;

  readonly C_BILLING_GROUP_ID = 'billingGroupId';

  constructor() {
    this._form = new FormGroup({
      [this.C_BILLING_GROUP_ID]: new FormControl(null, [])
    });

    this._filteredBillingGroups = this.getControl(this.C_BILLING_GROUP_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterBillingGroups(value || ''))
    );
  }

  bonuses: IPolicyBillingGroupBonus[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.policies.edit.bonuses.startDate.column:Fecha inicio`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.policies.edit.bonuses.endDate.column:Fecha fin`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'netBonusRC',
      fieldHeader: $localize`:@@app.policies.edit.bonuses.netBonusRC.column:Prima neta`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT
    },
    {
      fieldName: 'totalRC',
      fieldHeader: $localize`:@@app.policies.edit.bonuses.totalRC.column:Prima total`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT
    },
    {
      fieldName: 'commissionRate',
      fieldHeader: $localize`:@@app.policies.edit.bonuses.commissionRate.column:% Comisión`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT
    },
    {
      fieldName: 'extraCommissionRate',
      fieldHeader: $localize`:@@app.policies.edit.bonuses.extraCommissionRate.column:% Comisión adicional`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.edit.bonuses.actions.delete.tooltip:Borrar`
  }, {
    name: 'edit',
    icon: 'edit',
    tooltip: $localize`:@@app.policies.edit.bonuses.actions.edit.tooltip:Editar`
  }];

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get billingGroups(): Array<IPolicyBillingGroup> {
    return this._billingGoups;
  }

  set billingGroups(value: Array<IPolicyBillingGroup>) {
    this._billingGoups = value;
  }

  get billingGroupId(): string {
    return this.getControl(this.C_BILLING_GROUP_ID).value;
  }

  set billingGroupId(value: string) {
    this.getControl(this.C_BILLING_GROUP_ID).setValue(value);
  }

  get filteredBillingGroups(): Observable<Array<IPolicyBillingGroup>> {
    return this._filteredBillingGroups;
  }

  private filterBillingGroups(value: string): Array<IPolicyBillingGroup> {
    return value !== ''
      ? this._billingGoups.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this._billingGoups;
  }
}
