import { Injectable } from '@angular/core';
import { HowdenLoaderService } from '@howdeniberia/core-front';
import { IUIBlockerService } from './ui-blocker.contract';

@Injectable({
  providedIn: 'root'
})
export class UIBlockerService implements IUIBlockerService {
  private _count = 0;

  constructor(private loaderSrv: HowdenLoaderService) {
  }

  block(): void {
    if (this._count === 0) {
      this.loaderSrv.isSpinner.next({ active: true });
    }
    this._count++;
  }

  unblock(): void {
    this._count = this._count > 0 ? this._count - 1 : 0;
    if (this._count === 0) {
      this.loaderSrv.isSpinner.next({ active: false });
    }
  }
}
