import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IFleetSearchRequest } from 'src/app/core/models/api/fleets';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IOfficeService } from 'src/app/core/services/api/offices';
import { IReportsService } from 'src/app/core/services/api/reports';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { RootPolicyReportMapper } from '../mappers';
import { RootPolicyReportViewModel } from '../models';

@Component({
  selector: 'howden-root-policy-report',
  templateUrl: './root-policy-report.component.html',
  styleUrls: ['./root-policy-report.component.scss']
})
export class RootPolicyReportComponent implements OnInit {
  model = new RootPolicyReportViewModel();

  get minDate(): Date { return Limits.minDate; }

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private fleetSrv: IFleetService,
    private officesSrv: IOfficeService,
    private mastersSrv: IMasterService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    const filter = {
      pageNumber: 0,
      pageSize: 99999
    } as IFleetSearchRequest;

    forkJoin([
      this.fleetSrv.search(filter),
      this.officesSrv.getOffices(),
      this.mastersSrv.getPolicyStatuses()
    ]).subscribe(([fleets, offices, statuses]) => {
      this.model.fleets = fleets.data;
      this.model.offices = offices.map(x => <IItemSelection>{ id: x.officeId, description: x.name });
      this.model.statuses = statuses.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = RootPolicyReportMapper.mapForGeneration(this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.rootPolicies(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_POLIZAS_MADRE', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
