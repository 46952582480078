import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IBillingModel } from 'src/app/core/models/api/policies';
import { IOffice } from 'src/app/core/models/ida/company';
import { ICollectionManager, ICurrency } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany, IMediationCode } from 'src/app/core/models/ida/providers';
import { PolicyNumberValidator, forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyEditColectiveReplacementViewModel {
  private _form: FormGroup;
  private _policyNumberControl: FormControl;
  private _collectionManagers: Array<ICollectionManager> = [];
  private _paymentTypes: Array<IMasterValue> = [];
  private _regularizationPeriods: Array<IMasterValue> = [];
  private _policyDurations: Array<IMasterValue> = [];
  private _billingModels: Array<IBillingModel> = [];
  private _currencies: Array<ICurrency> = [];
  private _offices: Array<IOffice> = [];
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _products: Array<IProduct> = [];
  private _mediationCodes: Array<IMediationCode> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];

  private _filteredFleetCompanies: Observable<Array<IFleetCompany>>;
  private _filteredCollectionManagers: Observable<Array<ICollectionManager>>;
  private _filteredOffices: Observable<Array<IOffice>>;
  private _filteredCurrencies: Observable<Array<ICurrency>>;
  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _filteredIndustries: Observable<Array<IIndustry>>;
  private _filteredProducts: Observable<Array<IProduct>>;
  private _filteredMediationCodes: Observable<Array<IMediationCode>>;

  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_DESCRIPTION = 'description';
  readonly C_POLICY_NUMBER = 'policyNumber';
  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_INDUSTRY_ID = 'industryId';
  readonly C_PRODUCT_ID = 'productId';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_MEDIATION_CODE_ID = 'mediationCodeId';
  readonly C_OFFICE_CODE = 'officeCode';
  readonly C_COLLECTION_MANAGER_ID = 'collectionManagerId';
  readonly C_CURRENCY_ID = 'currencyId';
  readonly C_PAYMENT_TYPE_ID = 'paymentTypeId';
  readonly C_BILLING_MODEL_ID = 'billingModelId';
  readonly C_DURATION = 'duration';
  readonly C_KEEP_CURRENT_INSURED = 'keepCurrentInsured';
  readonly C_EXPIRATION_DATE = 'expirationDate';

  constructor(private policyNumberValidator: PolicyNumberValidator) {
    this._policyNumberControl = new FormControl('', {
      validators: [StringValidators.noSpacesEndStart],
      asyncValidators: [this.policyNumberValidator.validate.bind(this.policyNumberValidator)],
      updateOn: 'blur'
    });

    this._form = new FormGroup({
      [this.C_OPERATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_DESCRIPTION]: new FormControl(null, [Validators.required]),
      [this.C_POLICY_NUMBER]: this._policyNumberControl,
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_INDUSTRY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_PRODUCT_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.products, 'productId')]),
      [this.C_HOLDER_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.fleetCompanies, 'companyId')]),
      [this.C_MEDIATION_CODE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.mediationCodes, 'mediationCodeId')]),
      [this.C_OFFICE_CODE]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.offices, 'code')]),
      [this.C_COLLECTION_MANAGER_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.collectionManagers, 'collectionManagerId')]),
      [this.C_CURRENCY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.currencies, 'currencyId')]),
      [this.C_PAYMENT_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_BILLING_MODEL_ID]: new FormControl(null, [Validators.required]),
      [this.C_DURATION]: new FormControl(null, [Validators.required]),
      [this.C_KEEP_CURRENT_INSURED]: new FormControl(false, [Validators.required]),
      [this.C_EXPIRATION_DATE]: new FormControl(null, [Validators.required])
    });

    this._filteredFleetCompanies = this.getControl(this.C_HOLDER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterFleetCompanies(value || ''))
    );

    this._filteredCollectionManagers = this.getControl(this.C_COLLECTION_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCollectionManagers(value || ''))
    );

    this._filteredOffices = this.getControl(this.C_OFFICE_CODE).valueChanges.pipe(
      startWith(''),
      map(value => this.filterOffices(value || ''))
    );

    this._filteredCurrencies = this.getControl(this.C_CURRENCY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCurrencies(value || ''))
    );

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._filteredIndustries = this.getControl(this.C_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._filteredProducts = this.getControl(this.C_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterProducts(value || ''))
    );

    this._filteredMediationCodes = this.getControl(this.C_MEDIATION_CODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterMediationCodes(value || ''))
    );
  }

  get policyNumberControl(): FormControl {
    return this._policyNumberControl;
  }

  get collectionManagers(): Array<ICollectionManager> {
    return this._collectionManagers;
  }

  set collectionManagers(collectionManagers: Array<ICollectionManager>) {
    this._collectionManagers = collectionManagers;
  }

  get paymentTypes(): Array<IMasterValue> {
    return this._paymentTypes;
  }

  set paymentTypes(paymentTypes: Array<IMasterValue>) {
    this._paymentTypes = paymentTypes;
  }

  get regularizationPeriods(): Array<IMasterValue> {
    return this._regularizationPeriods;
  }

  set regularizationPeriods(regularizationPeriods: Array<IMasterValue>) {
    this._regularizationPeriods = regularizationPeriods;
  }

  get policyDurations(): Array<IMasterValue> {
    return this._policyDurations;
  }

  set policyDurations(policyDurations: Array<IMasterValue>) {
    this._policyDurations = policyDurations;
  }

  get billingModels(): Array<IBillingModel> {
    return this._billingModels;
  }

  set billingModels(value: Array<IBillingModel>) {
    this._billingModels = value;
  }

  get currencies(): Array<ICurrency> {
    return this._currencies;
  }

  set currencies(value: Array<ICurrency>) {
    this._currencies = value;
  }

  get offices(): Array<IOffice> {
    return this._offices;
  }

  set offices(value: Array<IOffice>) {
    this._offices = value;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get products(): Array<IProduct> {
    return this._products;
  }

  set products(value: Array<IProduct>) {
    this._products = value;
  }

  get mediationCodes(): Array<IMediationCode> {
    return this._mediationCodes;
  }

  set mediationCodes(value: Array<IMediationCode>) {
    this._mediationCodes = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get operationDate(): Date {
    return this.getControl(this.C_OPERATION_DATE).value;
  }

  set operationDate(value: Date) {
    this.getControl(this.C_OPERATION_DATE).setValue(value);
  }

  get description(): string {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }

  get policyNumber(): string | undefined {
    return this.getControl(this.C_POLICY_NUMBER).value;
  }

  set policyNumber(value: string | undefined) {
    this.getControl(this.C_POLICY_NUMBER).setValue(value);
  }

  get insuranceCompanyId(): string {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get industryId(): string {
    return this.getControl(this.C_INDUSTRY_ID).value;
  }

  set industryId(value: string) {
    this.getControl(this.C_INDUSTRY_ID).setValue(value);
  }

  get productId(): string {
    return this.getControl(this.C_PRODUCT_ID).value;
  }

  set productId(value: string) {
    this.getControl(this.C_PRODUCT_ID).setValue(value);
  }

  get holderId(): string {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get mediationCodeId(): string {
    return this.getControl(this.C_MEDIATION_CODE_ID).value;
  }

  set mediationCodeId(value: string) {
    this.getControl(this.C_MEDIATION_CODE_ID).setValue(value);
  }

  get officeCode(): string {
    return this.getControl(this.C_OFFICE_CODE).value;
  }

  set officeCode(value: string) {
    this.getControl(this.C_OFFICE_CODE).setValue(value);
  }

  get collectionManagerId(): string {
    return this.getControl(this.C_COLLECTION_MANAGER_ID).value;
  }

  set collectionManagerId(value: string) {
    this.getControl(this.C_COLLECTION_MANAGER_ID).setValue(value);
  }

  get currencyId(): string {
    return this.getControl(this.C_CURRENCY_ID).value;
  }

  set currencyId(value: string) {
    this.getControl(this.C_CURRENCY_ID).setValue(value);
  }

  get paymentTypeId(): string {
    return this.getControl(this.C_PAYMENT_TYPE_ID).value;
  }

  set paymentTypeId(value: string) {
    this.getControl(this.C_PAYMENT_TYPE_ID).setValue(value);
  }

  get billingModelId(): string {
    return this.getControl(this.C_BILLING_MODEL_ID).value;
  }

  set billingModelId(value: string) {
    this.getControl(this.C_BILLING_MODEL_ID).setValue(value);
  }

  get duration(): string {
    return this.getControl(this.C_DURATION).value;
  }

  set duration(value: string) {
    this.getControl(this.C_DURATION).setValue(value);
  }

  get keepCurrentInsured(): boolean {
    return this.getControl(this.C_KEEP_CURRENT_INSURED).value;
  }

  set keepCurrentInsured(value: boolean) {
    this.getControl(this.C_KEEP_CURRENT_INSURED).setValue(value);
  }

  get expirationDate(): Date {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get filteredFleetCompanies(): Observable<Array<IFleetCompany>> {
    return this._filteredFleetCompanies;
  }

  get filteredCollectionManagers(): Observable<Array<ICollectionManager>> {
    return this._filteredCollectionManagers;
  }

  get filteredOffices(): Observable<Array<IOffice>> {
    return this._filteredOffices;
  }

  get filteredCurrencies(): Observable<Array<ICurrency>> {
    return this._filteredCurrencies;
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  get filteredIndustries(): Observable<Array<IIndustry>> {
    return this._filteredIndustries;
  }

  get filteredProducts(): Observable<Array<IProduct>> {
    return this._filteredProducts;
  }

  get filteredMediationCodes(): Observable<Array<IMediationCode>> {
    return this._filteredMediationCodes;
  }

  private filterFleetCompanies(value: string): Array<IFleetCompany> {
    return value !== ''
      ? this.fleetCompanies.filter(c => c.clientName?.toLowerCase().includes(value.toLowerCase()))
      : this.fleetCompanies;
  }

  private filterCollectionManagers(value: string): Array<ICollectionManager> {
    return value !== ''
      ? this.collectionManagers.filter(c => c.name.toLowerCase().includes(value.toLowerCase()))
      : this.collectionManagers;
  }

  private filterOffices(value: string): Array<IOffice> {
    return value !== ''
      ? this.offices.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this.offices;
  }

  private filterCurrencies(value: string): Array<ICurrency> {
    return value !== ''
      ? this.currencies.filter(c => c.description?.toLowerCase().includes(value.toLowerCase()))
      : this.currencies;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this.insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this.industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.industries;
  }

  private filterProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this.products.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.products;
  }

  private filterMediationCodes(value: string): Array<IMediationCode> {
    return value !== ''
      ? this.mediationCodes.filter(x => x.description?.toLowerCase().includes(value.toLowerCase()))
      : this.mediationCodes;
  }
}
