import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { catchError, debounceTime, filter, finalize, of, switchMap, tap } from 'rxjs';
import { GlobalSearchResultType } from 'src/app/core/enums';
import { IGlobalSearchResult } from 'src/app/core/models/api/global-search';
import { IFleetService } from 'src/app/core/services/api/fleets/fleet.contract';
import { ISessionService } from 'src/app/core/services/storage/session';
import { SubSink } from 'subsink';

@Component({
  selector: 'howden-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  searchControl = new FormControl('');
  minLengthTerm = 3;
  isLoading = false;
  filteredResults: Array<IGlobalSearchResult> = [];
  selectedResult?: IGlobalSearchResult;

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private fleetSrv: IFleetService,
    private sessionSrv: ISessionService
  ) {
  }

  ngOnInit() {
    this._subscriptions.sink = this.searchControl.valueChanges
      .pipe(
        filter((res: string | null) => typeof res !== 'undefined' && res !== null && res.length >= this.minLengthTerm),
        debounceTime(1000),
        // distinctUntilChanged(),
        tap(() => {
          this.filteredResults = [];
          this.isLoading = true;
        }),
        switchMap(value => this.fleetSrv.globalSearch(value)
          .pipe(
            catchError(() => of([])),
            finalize(() => this.isLoading = false)
          )
        )
      ).subscribe((data: Array<IGlobalSearchResult>) => this.filteredResults = data);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  displayWith(value: IGlobalSearchResult): string {
    return value?.value ?? '';
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    const result = event.option.value as IGlobalSearchResult;

    if (result) {
      this.reset();
      this.sessionSrv.activateFleet(result.fleetId);
      this.navigate(result);
    }
  }

  private reset() {
    this.filteredResults = [];
    this.searchControl.reset();
  }

  private navigate(result: IGlobalSearchResult): void {
    const targetUri = (result.resultType === GlobalSearchResultType.Policy) ? 'policies/edit' : 'units/edit';

    this.router.navigate([targetUri, result.itemId]);
  }
}
