import { BillingGroupRuleType } from 'src/app/core/enums';
import { RuleType } from 'src/app/core/models/ui';

export class BillingGroupUtils {
  static readonly billingGroupPattern = /^(\+{1}(AP|TP|TV|UV|MC|AC|RC|GP|TM)+)*$/;

  static getRuleTypes(): Array<RuleType> {
    return [
      BillingGroupUtils.createRuleType(BillingGroupRuleType.BookingMode),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.MovementArea),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.PolicyHolder),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.PolicyInsured),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.UnitBonusGroup),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.VehicleType),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.VehicleUsage),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.CirculationArea),
      BillingGroupUtils.createRuleType(BillingGroupRuleType.GoodsType)
    ];
  }

  static getRuleTypeDescription(type: BillingGroupRuleType): string {
    switch (type) {
      case BillingGroupRuleType.BookingMode:
        return $localize`:@@app.policies.edit.billing.group.rules.types.bookingMode:Modo de contratación`;
      case BillingGroupRuleType.PolicyHolder:
        return $localize`:@@app.policies.edit.billing.group.rules.types.holder:Tomador`;
      case BillingGroupRuleType.MovementArea:
        return $localize`:@@app.policies.edit.billing.group.rules.types.movementArea:Ámbito de circulación`;
      case BillingGroupRuleType.PolicyInsured:
        return $localize`:@@app.policies.edit.billing.group.rules.types.insured:Asegurado`;
      case BillingGroupRuleType.UnitBonusGroup:
        return $localize`:@@app.policies.edit.billing.group.rules.types.bonusGroup:Grupo de primas`;
      case BillingGroupRuleType.VehicleType:
        return $localize`:@@app.policies.edit.billing.group.rules.types.vehicleType:Tipo de vehículo`;
      case BillingGroupRuleType.VehicleUsage:
        return $localize`:@@app.policies.edit.billing.group.rules.types.vehicleUsage:Uso del vehículo`;
      case BillingGroupRuleType.CirculationArea:
        return $localize`:@@app.policies.edit.billing.group.rules.types.circulationArea:Recinto de circulación`;
      case BillingGroupRuleType.GoodsType:
        return $localize`:@@app.policies.edit.billing.group.rules.types.goodsType:Tipo de mercancía`;
      default:
        return '';
    }
  }

  private static createRuleType(type: BillingGroupRuleType) {
    return new RuleType(type, BillingGroupUtils.getRuleTypeDescription(type));
  }
}
