import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAlertService,
  HowdenAskForDataModule,
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { SessionStorageService } from 'ngx-webstorage';
import { forkJoin } from 'rxjs';
import { FleetService, IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceDaysService, InsuranceDaysService } from 'src/app/core/services/api/insurance-days';
import { ISessionService, SessionService } from 'src/app/core/services/storage/session';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { CreateBookingModeDialogComponent } from './booking-modes/dialogs/create-booking-mode-dialog/pages/create-booking-mode-dialog.component';
import { BookingModeSearchComponent } from './booking-modes/pages/booking-mode-search.component';
import { FleetCreateComponent } from './fleet-create/pages/fleet-create.component';
import { FleetEditClientsComponent } from './fleet-edit/components/fleet-edit-clients/pages/fleet-edit-clients.component';
import { FleetEditMainComponent } from './fleet-edit/components/fleet-edit-main/pages/fleet-edit-main.component';
import { FleetEditSettingsComponent } from './fleet-edit/components/fleet-edit-settings/pages/fleet-edit-settings.component';
import { FleetEditTaxesComponent } from './fleet-edit/components/fleet-edit-taxes/pages/fleet-edit-taxes.component';
import { FleetEditTaxDialogComponent } from './fleet-edit/dialogs/fleet-edit-tax-dialog/pages/fleet-edit-tax-dialog.component';
import { FleetEditLayoutComponent } from './fleet-edit/pages/fleet-edit-layout.component';
import { FleetSearchComponent } from './fleet-search/pages/fleet-search.component';
import { FleetUpdaterComponent } from './fleet-updater/pages/fleet-updater.component';
import { FleetRoutingModule } from './fleets-routing.module';
import { InitialStateLoaderComponent } from './initial-state-loader/pages/initial-state-loader.component';
import { InsuranceDaysComponent } from './insurance-days/pages/insurance-days.component';
import { InsurerMergerComponent } from './insurer-merger/pages/insurer-merger.component';
import { ParalizationLoaderComponent } from './paralization-loader/pages/paralization-loader.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    MatTooltipModule,
    HowdenFileUploadModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    HowdenAutocompleteModule,
    HowdenAskForDataModule,
    FleetRoutingModule
  ],
  declarations: [
    FleetSearchComponent,
    FleetEditLayoutComponent,
    FleetEditMainComponent,
    FleetEditClientsComponent,
    FleetEditSettingsComponent,
    BookingModeSearchComponent,
    CreateBookingModeDialogComponent,
    ParalizationLoaderComponent,
    FleetCreateComponent,
    InsuranceDaysComponent,
    FleetEditTaxesComponent,
    FleetEditTaxDialogComponent,
    FleetUpdaterComponent,
    InsurerMergerComponent,
    InitialStateLoaderComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: ISessionService, useClass: SessionService },
    { provide: IFleetService, useClass: FleetService },
    { provide: IInsuranceDaysService, useClass: InsuranceDaysService }
  ]
})
export class FleetsModule {
  constructor(
    private sessionStorage: SessionStorageService,
    private alertsSrv: HowdenAlertService,
    private fleetsSrv: IFleetService
  ) {
    this.sessionStorage.observe('active-fleet').subscribe({
      next: value => this.onUpdate(value)
    });
  }

  private onUpdate(value: string): void {
    if (typeof value !== 'undefined' && value !== null) {
      forkJoin([
        this.fleetsSrv.get(value),
        this.fleetsSrv.hasNonRenewedBonuses(value)
      ]).subscribe(([
        fleet,
        hasNonRenewedBonuses
      ]) => {
        const title: string = $localize`:@@app.alerts.fleet.activation.title:Activación flota`;
        const message: string = (hasNonRenewedBonuses.result === true)
          ? $localize`:@@app.alerts.fleet.activation.message.1:La flota '${fleet.description}' tiene pendientes de actualizar las primas de renovación.`
          : $localize`:@@app.alerts.fleet.activation.message.2:Flota activa cambiada a '${fleet.description}'`;

        this.alertsSrv.info(title, message);
      });
    }
  }
}
