<form [formGroup]="model.form">
  <div class="howden-dialog-content">
    <div mat-dialog-title class="title-text" i18n="@@app.header.templates.policy.load.title">Plantilla de carga de pólizas</div>

    <div class="flex f-fd--row f-gap--10px">
      <mat-form-field class="f-basis--4 margin-left-25">
        <mat-label i18n="@@app.header.templates.policy.load.clientGroupId.label">Grupo de clientes</mat-label>
        <input
          matInput
          [formControlName]="model.C_CLIENT_GROUP_ID"
          [matAutocomplete]="clientGroupIdSelect"
          [howdenControlError]="clientGroupIdError"
          #clientGroupInput
        />
        <mat-autocomplete
          #clientGroupIdSelect="matAutocomplete"
          [displayWith]="getClientGroupName.bind(this)"
          >
          @for (item of model.filteredClientGroups | async; track item.groupId) {
            <mat-option [value]="item.groupId">
              {{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #clientGroupIdError></mat-error>
      </mat-form-field>
    </div>

    <mat-dialog-content>

      <mat-stepper #stepper linear>

        <mat-step>

          <ng-template matStepLabel i18n="@@app.header.templates.policy.load.sections.policy.load">Carga/Importación pólizas</ng-template>

          <div class="flex f-fd--row f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.insuranceCompanyId.label">Aseguradora</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_INSURANCE_COMPANY_ID"
                [matAutocomplete]="mainInsuranceCompanySelect"
                [howdenControlError]="mainInsuranceCompanyIdError"
              />
              <mat-autocomplete #mainInsuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
                @for (item of model.mainFilteredInsuranceCompanies | async; track item.insuranceCompanyId) {
                  <mat-option [value]="item.insuranceCompanyId">
                    {{ item.alias }}-{{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainInsuranceCompanyIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.industryId.label">Ramo</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_INDUSTRY_ID"
                [matAutocomplete]="mainIndustrySelect"
                [howdenControlError]="mainIndustryIdError"
              />
              <mat-autocomplete #mainIndustrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
                @for (item of model.mainFilteredIndustries | async; track item.industryId) {
                  <mat-option [value]="item.industryId">
                    {{ item.alias }}-{{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainIndustryIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.productId.label">Producto</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_PRODUCT_ID"
                [matAutocomplete]="mainProductSelect"
                [howdenControlError]="mainProductIdError"
              />
              <mat-autocomplete #mainProductSelect="matAutocomplete" [displayWith]="getMainProductName.bind(this)">
                @for (item of model.mainFilteredProducts | async; track item.productId) {
                  <mat-option [value]="item.productId">
                    {{ item.alias }}-{{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainProductIdError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.mediationCodeId.label">Código mediación</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_MEDIATION_CODE_ID"
                [matAutocomplete]="mainMediationCodeSelect"
                [howdenControlError]="mainMediationCodeIdError"
              />
              <mat-autocomplete #mainMediationCodeSelect="matAutocomplete" [displayWith]="getMainMediationCodeDescription.bind(this)">
                @for (item of model.mainFilteredMediationCodes | async; track item.mediationCodeId) {
                  <mat-option [value]="item.mediationCodeId">
                    {{ item.code }}-{{ item.description }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainMediationCodeIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.officeId.label">Oficina</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_OFFICE_ID"
                [matAutocomplete]="mainOfficeSelect"
                [howdenControlError]="mainOfficeIdError"
              />
              <mat-autocomplete #mainOfficeSelect="matAutocomplete" [displayWith]="getOfficeDescription.bind(this)">
                @for (item of model.mainFilteredOffices | async; track item.officeId) {
                  <mat-option [value]="item.officeId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainOfficeIdError></mat-error>
            </mat-form-field>

            <div class="f-basis--4"></div>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.accountManagerId.label">Gestor de cuenta</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_ACCOUNT_MANAGER_ID"
                [matAutocomplete]="mainAccountManagerSelect"
                [howdenControlError]="mainAccountManagerIdError"
              />
              <mat-autocomplete #mainAccountManagerSelect="matAutocomplete" [displayWith]="getUserName.bind(this)">
                @for (item of model.mainFilteredAccountManagers | async; track item.userId) {
                  <mat-option [value]="item.userId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainAccountManagerIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.technicianId.label">Técnico</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_TECHNICIAN_ID"
                [matAutocomplete]="mainTechnicianSelect"
                [howdenControlError]="mainTechnicianIdError"
              />
              <mat-autocomplete #mainTechnicianSelect="matAutocomplete" [displayWith]="getUserName.bind(this)">
                @for (item of model.mainFilteredTechnicians | async; track item.userId) {
                  <mat-option [value]="item.userId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainTechnicianIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.auxiliarTechnicianId.label">Técnico auxiliar</mat-label>
              <input
                matInput
                [formControlName]="model.C_MAIN_AUX_TECHNICIAN_ID"
                [matAutocomplete]="mainAuxiliarTechnicianSelect"
                [howdenControlError]="mainAuxiliarTechnicianIdError"
              />
              <mat-autocomplete #mainAuxiliarTechnicianSelect="matAutocomplete" [displayWith]="getUserName.bind(this)">
                @for (item of model.mainFilteredAuxiliaryTechnicians | async; track item.userId) {
                  <mat-option [value]="item.userId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #mainAuxiliarTechnicianIdError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <mat-slide-toggle
              class="f-basis--4 f-as--center"
              [formControlName]="model.C_MAIN_IMPORT"
              i18n="@@app.header.templates.policy.load.import.label">Carga pólizas VisualSeg</mat-slide-toggle>

            <mat-slide-toggle
              class="f-basis--4 f-as--center"
              [formControlName]="model.C_MAIN_IMPORT_ALL"
              i18n="@@app.header.templates.policy.load.importAll.label">Cargar todos los productos</mat-slide-toggle>

            <mat-slide-toggle
              class="f-basis--4 f-as--center"
              [formControlName]="model.C_MAIN_REPLACE"
              i18n="@@app.header.templates.policy.load.replace.label">Reemplazar pólizas</mat-slide-toggle>
          </div>

          <div class="flex f-fd--row f-gap--10px margin-top-20">
            <div class="f-basis--1"></div>

            <div class="f-basis--10">
              <div class="flex f-jc--center">
                <button type="button" mat-raised-button (click)="onCancel()">
                  <span i18n="@@app.core.actions.cancel">Cancelar</span>
                </button>
              </div>
            </div>

            <div class="f-basis--1">
              <button
                type="button"
                mat-mini-fab matStepperNext
                matTooltip="Siguiente"
                i18n-matTooltip="@@app.core.stepper.actions.next"
              >
                <mat-icon>navigate_next</mat-icon>
              </button>
            </div>
          </div>

        </mat-step>

        <mat-step>

          <ng-template matStepLabel i18n="@@app.header.templates.policy.load.sections.replacement.load">Reemplazos</ng-template>

          <div class="flex f-fd--row f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.insuranceCompanyId.label">Aseguradora</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_INSURANCE_COMPANY_ID"
                [matAutocomplete]="secondaryInsuranceCompanySelect"
                [howdenControlError]="secondaryInsuranceCompanyIdError"
              />
              <mat-autocomplete #secondaryInsuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
                @for (item of model.secondaryFilteredInsuranceCompanies | async; track item.insuranceCompanyId) {
                  <mat-option [value]="item.insuranceCompanyId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryInsuranceCompanyIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.industryId.label">Ramo</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_INDUSTRY_ID"
                [matAutocomplete]="secondaryIndustrySelect"
                [howdenControlError]="secondaryIndustryIdError"
              />
              <mat-autocomplete #secondaryIndustrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
                @for (item of model.secondaryFilteredIndustries | async; track item.industryId) {
                  <mat-option [value]="item.industryId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryIndustryIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.productId.label">Producto</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_PRODUCT_ID"
                [matAutocomplete]="secondaryProductSelect"
                [howdenControlError]="secondaryProductIdError"
              />
              <mat-autocomplete #secondaryProductSelect="matAutocomplete" [displayWith]="getSecondaryProductName.bind(this)">
                @for (item of model.secondaryFilteredProducts | async; track item.productId) {
                  <mat-option [value]="item.productId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryProductIdError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.mediationCodeId.label">Código mediación</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_MEDIATION_CODE_ID"
                [matAutocomplete]="secondaryMediationCodeSelect"
                [howdenControlError]="secondaryMediationCodeIdError"
              />
              <mat-autocomplete #secondaryMediationCodeSelect="matAutocomplete" [displayWith]="getSecondaryMediationCodeDescription.bind(this)">
                @for (item of model.secondaryFilteredMediationCodes | async; track item.mediationCodeId) {
                  <mat-option [value]="item.mediationCodeId">
                    {{ item.code }} - {{ item.description }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryMediationCodeIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.officeId.label">Oficina</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_OFFICE_ID"
                [matAutocomplete]="secondaryOfficeSelect"
                [howdenControlError]="secondaryOfficeIdError"
              />
              <mat-autocomplete #secondaryOfficeSelect="matAutocomplete" [displayWith]="getOfficeDescription.bind(this)">
                @for (item of model.secondaryFilteredOffices | async; track item.officeId) {
                  <mat-option [value]="item.officeId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryOfficeIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.operationDate.label">F. Operación</mat-label>
              <input
                matInput
                [matDatepicker]="secondaryOperationDatePicker"
                [formControlName]="model.C_SECONDARY_OPERATION_DATE"
                [howdenControlError]="secondaryOperationDateError"
                [min]="minDate"
              />
              <mat-error #secondaryOperationDateError></mat-error>
              <mat-datepicker-toggle matSuffix [for]="secondaryOperationDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #secondaryOperationDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.accountManagerId.label">Gestor de cuenta</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_ACCOUNT_MANAGER_ID"
                [matAutocomplete]="secondaryAccountManagerSelect"
                [howdenControlError]="secondaryAccountManagerIdError"
              />
              <mat-autocomplete #secondaryAccountManagerSelect="matAutocomplete" [displayWith]="getUserName.bind(this)">
                @for (item of model.secondaryFilteredAccountManagers | async; track item.userId) {
                  <mat-option [value]="item.userId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryAccountManagerIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.technicianId.label">Técnico</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_TECHNICIAN_ID"
                [matAutocomplete]="secondaryTechnicianSelect"
                [howdenControlError]="secondaryTechnicianIdError"
              />
              <mat-autocomplete #secondaryTechnicianSelect="matAutocomplete" [displayWith]="getUserName.bind(this)">
                @for (item of model.secondaryFilteredTechnicians | async; track item.userId) {
                  <mat-option [value]="item.userId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryTechnicianIdError></mat-error>
            </mat-form-field>

            <mat-form-field class="f-basis--4">
              <mat-label i18n="@@app.header.templates.policy.load.auxiliarTechnicianId.label">Técnico auxiliar</mat-label>
              <input
                matInput
                [formControlName]="model.C_SECONDARY_AUX_TECHNICIAN_ID"
                [matAutocomplete]="secondaryAuxiliarTechnicianSelect"
                [howdenControlError]="secondaryAuxiliarTechnicianIdError"
              />
              <mat-autocomplete #secondaryAuxiliarTechnicianSelect="matAutocomplete" [displayWith]="getUserName.bind(this)">
                @for (item of model.secondaryFilteredAuxiliaryTechnicians | async; track item.userId) {
                  <mat-option [value]="item.userId">
                    {{ item.name }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-error #secondaryAuxiliarTechnicianIdError></mat-error>
            </mat-form-field>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <div class="f-basis--1">
              <button
                type="button"
                mat-mini-fab matStepperPrevious
                matTooltip="Anterior"
                i18n-matTooltip="@@app.core.stepper.actions.previous"
                >
                <mat-icon>navigate_before</mat-icon>
              </button>
            </div>

            <div class="f-basis--10">
              <div class="flex f-jc--center f-gap--10px">
                <button type="button" mat-raised-button (click)="onCancel()">
                  <span i18n="@@app.core.actions.cancel">Cancelar</span>
                </button>

                <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
                  <span i18n="@@app.core.actions.accept">Aceptar</span>
                </button>
              </div>
            </div>

            <div class="f-basis--1"></div>

          </div>

        </mat-step>

      </mat-stepper>
    </mat-dialog-content>
  </div>
</form>
