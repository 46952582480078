import { IPolicySummary } from 'src/app/core/models/api/policies';

export class PolicySummaryViewModel {
  constructor(private summary?: IPolicySummary) { }

  get policyNumber(): string | null | undefined {
    return this.summary?.policyNumber;
  }

  get description(): string | null | undefined {
    return this.summary?.description;
  }

  get holder(): string | null | undefined {
    return this.summary?.holder;
  }

  get replacesPolicy(): string | null | undefined {
    return this.summary?.replacesPolicy;
  }

  get replacedByPolicy(): string | null | undefined {
    return this.summary?.replacedByPolicy;
  }

  get collectionManager(): string | null | undefined {
    return this.summary?.collectionManager;
  }

  get mediationCode(): string | null | undefined {
    return this.summary?.mediationCode;
  }

  get duration(): string | null | undefined {
    return this.summary?.duration;
  }

  get office(): string | null | undefined {
    return this.summary?.office;
  }

  get firstEffectDate(): Date | null | undefined {
    return this.summary?.firstEffectDate;
  }

  get effectDate(): Date | null | undefined {
    return this.summary?.effectDate;
  }

  get expirationDate(): Date | null | undefined {
    return this.summary?.expirationDate;
  }

  get cancellationDate(): Date | null | undefined {
    return this.summary?.cancellationDate;
  }

  get currency(): string | null | undefined {
    return this.summary?.currency;
  }

  get insuranceCompany(): string | null | undefined {
    return this.summary?.insuranceCompany;
  }

  get industry(): string | null | undefined {
    return this.summary?.industry;
  }

  get product(): string | null | undefined {
    return this.summary?.product;
  }

  get policyClass(): string | null | undefined {
    return this.summary?.policyClass;
  }

  get policyBonusType(): string | null | undefined {
    return this.summary?.policyBonusType;
  }

  get policyStatus(): string | null | undefined {
    return this.summary?.policyStatus;
  }

  get cancellationReason(): string | null | undefined {
    return this.summary?.cancellationReason;
  }

  get paymentType(): string | null | undefined {
    return this.summary?.paymentType;
  }

  get billingModel(): string | null | undefined {
    return this.summary?.billingModel;
  }

  get commissionRate(): number | null | undefined {
    return this.summary?.commissionRate;
  }

  get dossier(): string | null | undefined {
    return this.summary?.dossier;
  }

  get receiptTarget(): string | null | undefined {
    return this.summary?.receiptTarget;
  }

  get rootPolicyNumber(): string | null | undefined {
    return this.summary?.rootPolicyNumber;
  }

  get rootPolicyName(): string | null | undefined {
    return this.summary?.rootPolicyName;
  }

  get rootPolicyStatus(): string | null | undefined {
    return this.summary?.rootPolicyStatus;
  }

  get createdBy(): string | null | undefined {
    return this.summary?.createdBy;
  }

  get createdAt(): string | null | undefined {
    return this.summary?.createdAt;
  }

  get modifiedBy(): string | null | undefined {
    return this.summary?.modifiedBy;
  }

  get modifiedAt(): string | null | undefined {
    return this.summary?.modifiedAt;
  }

  get hasRootPolicy(): boolean {
    return typeof this.rootPolicyNumber !== 'undefined' && this.rootPolicyNumber !== null;
  }
}
