import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import {
  BillingModelType,
  PolicyBonusType,
  PolicyClass,
  PolicyStatus
} from 'src/app/core/enums';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IBillingModel, IBonusType, IPolicyClass, IReceiptTarget } from 'src/app/core/models/api/policies';
import { ICollectionManager, ICurrency } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany, IMediationCode } from 'src/app/core/models/ida/providers';
import { BillingGroupUtils } from 'src/app/shared/utils';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyEditViewModel {
  private _form: FormGroup;
  private _collectionManagers: Array<ICollectionManager> = [];
  private _paymentTypes: Array<IMasterValue> = [];
  private _regularizationPeriods: Array<IMasterValue> = [];
  private _policyDurations: Array<IMasterValue> = [];
  private _policyClasses: Array<IPolicyClass> = [];
  private _receiptTargets: Array<IReceiptTarget> = [];
  private _billingModels: Array<IBillingModel> = [];
  private _bonusTypes: Array<IBonusType> = [];
  private _currencies: Array<ICurrency> = [];
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _products: Array<IProduct> = [];
  private _mediationCodes: Array<IMediationCode> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];

  private _filteredCollectionManagers: Observable<Array<ICollectionManager>>;
  private _filteredCurrencies: Observable<Array<ICurrency>>;
  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _filteredIndustries: Observable<Array<IIndustry>>;
  private _filteredProducts: Observable<Array<IProduct>>;
  private _filteredMediationCodes: Observable<Array<IMediationCode>>;

  readonly C_ID = 'policyId';
  readonly C_ROOT_POLICY_ID = 'rootPolicyId';
  readonly C_POLICY_CLASS = 'policyClass';
  readonly C_POLICY_BONUS_TYPE_ID = 'policyBonusTypeId';
  readonly C_BILLING_MODEL_ID = 'billingModelId';
  readonly C_REGULARIZATION_PERIOD_ID = 'regularizationPeriodId';
  readonly C_DOUBLE_BILLING = 'doubleBilling';
  readonly C_COMMISSION_RATE = 'commissionRate';
  readonly C_OFFICE_CODE = 'officeCode';
  readonly C_EXECUTIVE_ID = 'executiveId';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_ADDRESS_ID = 'addressId';
  readonly C_DIRECT_DEBIT_ID = 'directDebitId';
  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_INDUSTRY_ID = 'industryId';
  readonly C_PRODUCT_ID = 'productId';
  readonly C_POLICY_NUMBER = 'policyNumber';
  readonly C_COLLECTION_MANAGER_ID = 'collectionManagerId';
  readonly C_MEDIATION_CODE_ID = 'mediationCodeId';
  readonly C_CURRENCY_ID = 'currencyId';
  readonly C_PAYMENT_TYPE_ID = 'paymentTypeId';
  readonly C_DURATION = 'duration';
  readonly C_FIRST_EFFECT_DATE = 'firstEffectDate';
  readonly C_EFFECT_DATE = 'effectDate';
  readonly C_EXPIRATION_DATE = 'expirationDate';
  readonly C_CANCELLATION_DATE = 'cancellationDate';
  readonly C_CANCELLATION_REASON_ID = 'cancellationReasonId';
  readonly C_CANCELLATION_REASON = 'cancellationReason';
  readonly C_DESCRIPTION = 'riskDescription';
  readonly C_RECEIPTS_ON = 'receiptsOn';
  readonly C_REPLACES_POLICY = 'replacesPolicy';
  readonly C_REPLACED_BY = 'replacedBy';
  readonly C_INDIVIDUAL_BONUS_GROUPING = 'individualBonusGrouping';
  readonly C_ROOT_POLICY_UPDATE_MODE = 'rootPolicyUpdateMode';
  readonly C_STATUS = 'status';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, [Validators.required]),
      [this.C_ROOT_POLICY_ID]: new FormControl(null, []),
      [this.C_POLICY_CLASS]: new FormControl(null, [Validators.required]),
      [this.C_POLICY_BONUS_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_BILLING_MODEL_ID]: new FormControl(null, [Validators.required]),
      [this.C_REGULARIZATION_PERIOD_ID]: new FormControl(null, []),
      [this.C_DOUBLE_BILLING]: new FormControl(null, []),
      [this.C_COMMISSION_RATE]: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      [this.C_OFFICE_CODE]: new FormControl(null, [Validators.required]),
      [this.C_EXECUTIVE_ID]: new FormControl(null, []),
      [this.C_HOLDER_ID]: new FormControl(null, [Validators.required]),
      [this.C_ADDRESS_ID]: new FormControl(null, []),
      [this.C_DIRECT_DEBIT_ID]: new FormControl(null, []),
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_INDUSTRY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_PRODUCT_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.products, 'productId')]),
      [this.C_POLICY_NUMBER]: new FormControl(null, [Validators.required]),
      [this.C_COLLECTION_MANAGER_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.collectionManagers, 'collectionManagerId')]),
      [this.C_MEDIATION_CODE_ID]: new FormControl('', [Validators.required, forbiddenNamesValidator(() => this.mediationCodes, 'mediationCodeId')]),
      [this.C_CURRENCY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.currencies, 'currencyId')]),
      [this.C_PAYMENT_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_DURATION]: new FormControl(null, [Validators.required]),
      [this.C_FIRST_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_CANCELLATION_DATE]: new FormControl(null, []),
      [this.C_CANCELLATION_REASON_ID]: new FormControl(null, []),
      [this.C_CANCELLATION_REASON]: new FormControl(null, []),
      [this.C_DESCRIPTION]: new FormControl(null, [Validators.required]),
      [this.C_RECEIPTS_ON]: new FormControl(null, []),
      [this.C_REPLACES_POLICY]: new FormControl(null, []),
      [this.C_REPLACED_BY]: new FormControl(null, []),
      [this.C_INDIVIDUAL_BONUS_GROUPING]: new FormControl(null, [Validators.pattern(BillingGroupUtils.billingGroupPattern)]),
      [this.C_ROOT_POLICY_UPDATE_MODE]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(null, [Validators.required])
    });

    this.getControl(this.C_DESCRIPTION).disable();

    this._filteredCollectionManagers = this.getControl(this.C_COLLECTION_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCollectionManagers(value || ''))
    );

    this._filteredCurrencies = this.getControl(this.C_CURRENCY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCurrencies(value || ''))
    );

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._filteredIndustries = this.getControl(this.C_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._filteredProducts = this.getControl(this.C_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterProducts(value || ''))
    );

    this._filteredMediationCodes = this.getControl(this.C_MEDIATION_CODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterMediationCodes(value || ''))
    );
  }

  get collectionManagers(): Array<ICollectionManager> {
    return this._collectionManagers;
  }

  set collectionManagers(collectionManagers: Array<ICollectionManager>) {
    this._collectionManagers = collectionManagers;
  }

  get paymentTypes(): Array<IMasterValue> {
    return this._paymentTypes;
  }

  set paymentTypes(paymentTypes: Array<IMasterValue>) {
    this._paymentTypes = paymentTypes;
  }

  get regularizationPeriods(): Array<IMasterValue> {
    return this._regularizationPeriods;
  }

  set regularizationPeriods(regularizationPeriods: Array<IMasterValue>) {
    this._regularizationPeriods = regularizationPeriods;
  }

  get policyDurations(): Array<IMasterValue> {
    return this._policyDurations;
  }

  set policyDurations(policyDurations: Array<IMasterValue>) {
    this._policyDurations = policyDurations;
  }

  get policyClasses(): Array<IPolicyClass> {
    return this._policyClasses;
  }

  set policyClasses(value: Array<IPolicyClass>) {
    this._policyClasses = value;
  }

  get receiptTargets(): Array<IReceiptTarget> {
    return this._receiptTargets;
  }

  set receiptTargets(value: Array<IReceiptTarget>) {
    this._receiptTargets = value;
  }

  get billingModels(): Array<IBillingModel> {
    return this._billingModels;
  }

  set billingModels(value: Array<IBillingModel>) {
    this._billingModels = value;
  }

  get bonusTypes(): Array<IBonusType> {
    return this._bonusTypes;
  }

  set bonusTypes(value: Array<IBonusType>) {
    this._bonusTypes = value;
  }

  get currencies(): Array<ICurrency> {
    return this._currencies;
  }

  set currencies(value: Array<ICurrency>) {
    this._currencies = value;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get products(): Array<IProduct> {
    return this._products;
  }

  set products(value: Array<IProduct>) {
    this._products = value;
  }

  get mediationCodes(): Array<IMediationCode> {
    return this._mediationCodes;
  }

  set mediationCodes(value: Array<IMediationCode>) {
    this._mediationCodes = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get policyId(): string {
    return this.getControl(this.C_ID).value;
  }

  set policyId(value: string) {
    this.getControl(this.C_ID)?.setValue(value);
  }

  get rootPolicyId(): string {
    return this.getControl(this.C_ROOT_POLICY_ID).value;
  }

  set rootPolicyId(value: string) {
    this.getControl(this.C_ROOT_POLICY_ID)?.setValue(value);
  }

  get policyClass(): PolicyClass {
    return this.getControl(this.C_POLICY_CLASS).value;
  }

  set policyClass(value: PolicyClass) {
    this.getControl(this.C_POLICY_CLASS).setValue(value);
  }

  get policyBonusType(): PolicyBonusType {
    return this.getControl(this.C_POLICY_BONUS_TYPE_ID).value;
  }

  set policyBonusType(value: PolicyBonusType) {
    this.getControl(this.C_POLICY_BONUS_TYPE_ID).setValue(value);
  }

  get billingModelId(): string | undefined {
    return this.getControl(this.C_BILLING_MODEL_ID).value;
  }

  set billingModelId(value: string | undefined) {
    this.getControl(this.C_BILLING_MODEL_ID).setValue(value);
  }

  get regularizationPeriodId(): string | undefined {
    return this.getControl(this.C_REGULARIZATION_PERIOD_ID).value;
  }

  set regularizationPeriodId(value: string | undefined) {
    this.getControl(this.C_REGULARIZATION_PERIOD_ID).setValue(value);
  }

  get doubleBilling(): boolean {
    return this.getControl(this.C_DOUBLE_BILLING).value;
  }

  set doubleBilling(value: boolean) {
    this.getControl(this.C_DOUBLE_BILLING).setValue(value);
  }

  get commissionRate(): number {
    return this.getControl(this.C_COMMISSION_RATE).value;
  }

  set commissionRate(value: number) {
    this.getControl(this.C_COMMISSION_RATE).setValue(value);
  }

  get officeCode(): string {
    return this.getControl(this.C_OFFICE_CODE).value;
  }

  set officeCode(value: string) {
    this.getControl(this.C_OFFICE_CODE).setValue(value);
  }

  get executiveId(): string {
    return this.getControl(this.C_EXECUTIVE_ID).value;
  }

  set executiveId(value: string) {
    this.getControl(this.C_EXECUTIVE_ID).setValue(value);
  }

  get holderId(): string {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get addressId(): string {
    return this.getControl(this.C_ADDRESS_ID).value;
  }

  set addressId(value: string) {
    this.getControl(this.C_ADDRESS_ID).setValue(value);
  }

  get directDebitId(): string {
    return this.getControl(this.C_DIRECT_DEBIT_ID).value;
  }

  set directDebitId(value: string) {
    this.getControl(this.C_DIRECT_DEBIT_ID).setValue(value);
  }

  get insuranceCompanyId(): string {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get industryId(): string {
    return this.getControl(this.C_INDUSTRY_ID).value;
  }

  set industryId(value: string) {
    this.getControl(this.C_INDUSTRY_ID).setValue(value);
  }

  get productId(): string {
    return this.getControl(this.C_PRODUCT_ID).value;
  }

  set productId(value: string) {
    this.getControl(this.C_PRODUCT_ID).setValue(value);
  }

  get policyNumber(): string {
    return this.getControl(this.C_POLICY_NUMBER).value;
  }

  set policyNumber(value: string) {
    this.getControl(this.C_POLICY_NUMBER).setValue(value);
  }

  get collectionManagerId(): string {
    return this.getControl(this.C_COLLECTION_MANAGER_ID).value;
  }

  set collectionManagerId(value: string) {
    this.getControl(this.C_COLLECTION_MANAGER_ID).setValue(value);
  }

  get mediationCodeId(): string {
    return this.getControl(this.C_MEDIATION_CODE_ID).value;
  }

  set mediationCodeId(value: string) {
    this.getControl(this.C_MEDIATION_CODE_ID).setValue(value);
  }

  get currencyId(): string {
    return this.getControl(this.C_CURRENCY_ID).value;
  }

  set currencyId(value: string) {
    this.getControl(this.C_CURRENCY_ID).setValue(value);
  }

  get paymentTypeId(): string | null | undefined {
    return this.getControl(this.C_PAYMENT_TYPE_ID).value;
  }

  set paymentTypeId(value: string | null | undefined) {
    this.getControl(this.C_PAYMENT_TYPE_ID).setValue(value);
  }

  get duration(): string | null | undefined {
    return this.getControl(this.C_DURATION).value;
  }

  set duration(value: string | null | undefined) {
    this.getControl(this.C_DURATION).setValue(value);
  }

  get firstEffectDate(): Date | null | undefined {
    return this.getControl(this.C_FIRST_EFFECT_DATE).value;
  }

  set firstEffectDate(value: Date | null | undefined) {
    this.getControl(this.C_FIRST_EFFECT_DATE).setValue(value);
  }

  get effectDate(): Date | null | undefined {
    return this.getControl(this.C_EFFECT_DATE).value;
  }

  set effectDate(value: Date | null | undefined) {
    this.getControl(this.C_EFFECT_DATE).setValue(value);
  }

  get expirationDate(): Date | null | undefined {
    return this.getControl(this.C_EXPIRATION_DATE).value;
  }

  set expirationDate(value: Date | null | undefined) {
    this.getControl(this.C_EXPIRATION_DATE).setValue(value);
  }

  get cancellationDate(): Date | null | undefined {
    return this.getControl(this.C_CANCELLATION_DATE).value;
  }

  set cancellationDate(value: Date | null | undefined) {
    this.getControl(this.C_CANCELLATION_DATE).setValue(value);
  }

  get cancellationReasonId(): string | null | undefined {
    return this.getControl(this.C_CANCELLATION_REASON_ID).value;
  }

  set cancellationReasonId(value: string | null | undefined) {
    this.getControl(this.C_CANCELLATION_REASON_ID).setValue(value);
  }

  get cancellationReason(): string | null | undefined {
    return this.getControl(this.C_CANCELLATION_REASON).value;
  }

  set cancellationReason(value: string | null | undefined) {
    this.getControl(this.C_CANCELLATION_REASON).setValue(value);
  }

  get description(): string | null | undefined {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string | null | undefined) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }

  get receiptsOn(): string | null | undefined {
    return this.getControl(this.C_RECEIPTS_ON).value;
  }

  set receiptsOn(value: string | null | undefined) {
    this.getControl(this.C_RECEIPTS_ON).setValue(value);
  }

  get replacesPolicy(): string | null | undefined {
    return this.getControl(this.C_REPLACES_POLICY).value;
  }

  set replacesPolicy(value: string | null | undefined) {
    this.getControl(this.C_REPLACES_POLICY).setValue(value);
  }

  get replacedBy(): string | null | undefined {
    return this.getControl(this.C_REPLACED_BY).value;
  }

  set replacedBy(value: string | null | undefined) {
    this.getControl(this.C_REPLACED_BY).setValue(value);
  }

  get individualBonusGrouping(): string | null | undefined {
    return this.getControl(this.C_INDIVIDUAL_BONUS_GROUPING).value;
  }

  set individualBonusGrouping(value: string | null | undefined) {
    this.getControl(this.C_INDIVIDUAL_BONUS_GROUPING).setValue(value);
  }

  get rootPolicyUpdateMode(): string | null | undefined {
    return this.getControl(this.C_ROOT_POLICY_UPDATE_MODE).value;
  }

  set rootPolicyUpdateMode(value: string | null | undefined) {
    this.getControl(this.C_ROOT_POLICY_UPDATE_MODE).setValue(value);
  }

  get status(): PolicyStatus | null | undefined {
    return this.getControl(this.C_STATUS).value;
  }

  set status(value: PolicyStatus | null | undefined) {
    this.getControl(this.C_STATUS).setValue(value);
  }

  get showIndividualBonusGrouping(): boolean {
    return this.policyClass === PolicyClass.Other && this.policyBonusType === PolicyBonusType.Individual;
  }

  get canAssignNumber(): boolean {
    return this.hasExpirationDate;
  }

  get canAddNewEndorsement(): boolean {
    return this.hasExpirationDate;
  }

  get canUpdateHolder(): boolean {
    return this.policyClass !== PolicyClass.Colective
      && this.status !== PolicyStatus.Cancelled
      && (this._fleetCompanies && this._fleetCompanies.length >= 2)
      && !this.hasPendingTasks;
  }

  get canUpdateOffice(): boolean {
    return true;
  }

  get canReplace(): boolean {
    return this.policyClass !== PolicyClass.Other
      && this.status !== PolicyStatus.Cancelled
      && !this.hasPendingTasks;
  }

  get canCancelReplacement(): boolean {
    return this.policyClass !== PolicyClass.Other
      && typeof this.replacesPolicy !== 'undefined'
      && this.replacesPolicy !== null
      && this.hasExpirationDate;
  }

  get canChangeRisk(): boolean {
    return this.policyClass === PolicyClass.Individual;
  }

  get canDelete(): boolean {
    return (typeof this.replacedBy === 'undefined' || this.replacedBy === null)
      && (typeof this.replacesPolicy === 'undefined' || this.replacesPolicy === null);
  }

  get canUpdateBonusType(): boolean {
    return this.policyClass !== PolicyClass.Individual
      && this.policyBonusType !== PolicyBonusType.Fixed;
  }

  get canReactivate(): boolean {
    return this.status === PolicyStatus.Cancelled
      && (typeof this.replacedBy === 'undefined' || this.replacedBy === null);
  }

  get canCancel(): boolean {
    return this.status !== PolicyStatus.Cancelled;
  }

  get canEditCommissionRate(): boolean {
    return this.policyClass === PolicyClass.Other
      || this.policyClass === PolicyClass.Colective;
  }

  get canShowRootPolicyInfo(): boolean {
    return this.policyClass === PolicyClass.Individual;
  }

  get hasPendingTasks(): boolean {
    return false;
  }

  get hasExpirationDate(): boolean {
    const expirationDate = this.getControl(this.C_EXPIRATION_DATE).value;

    return typeof expirationDate !== 'undefined' && expirationDate !== null;
  }

  get isRoot(): boolean {
    return this.policyClass === PolicyClass.Other;
  }

  get isColective(): boolean {
    return this.policyClass === PolicyClass.Colective;
  }

  get isRegularized(): boolean {
    const value = this.getControl(this.C_BILLING_MODEL_ID).value;
    if (!value) {
      return false;
    }
    return parseInt(value) === BillingModelType.Regularized;
  }

  get filteredCollectionManagers(): Observable<Array<ICollectionManager>> {
    return this._filteredCollectionManagers;
  }

  get filteredCurrencies(): Observable<Array<ICurrency>> {
    return this._filteredCurrencies;
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  get filteredIndustries(): Observable<Array<IIndustry>> {
    return this._filteredIndustries;
  }

  get filteredProducts(): Observable<Array<IProduct>> {
    return this._filteredProducts;
  }

  get filteredMediationCodes(): Observable<Array<IMediationCode>> {
    return this._filteredMediationCodes;
  }

  reset(): void {
    this.insuranceCompanies = [];
    this.industries = [];
    this.fleetCompanies = [];
    this.products = [];
    this.mediationCodes = [];

    this.form.reset();
  }

  private filterCollectionManagers(value: string): Array<ICollectionManager> {
    return value !== ''
      ? this.collectionManagers.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()))
      : this.collectionManagers;
  }

  private filterCurrencies(value: string): Array<ICurrency> {
    return value !== ''
      ? this.currencies.filter(x => x.description?.toLowerCase().includes(value.toLowerCase()))
      : this.currencies;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this.insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this.industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.industries;
  }

  private filterProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this.products.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.products;
  }

  private filterMediationCodes(value: string): Array<IMediationCode> {
    return value !== ''
      ? this._mediationCodes.filter(x => x.description?.toLowerCase().includes(value.toLowerCase()))
      : this._mediationCodes;
  }
}
