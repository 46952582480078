import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, StringValidators } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IFleetSetting } from 'src/app/core/models/api/fleets';
import { IGroupOfClient } from 'src/app/core/models/ida/clients';
import { ClientViewModel } from './client.viewmodel';

export class FleetCreateViewModel {
  private _form: FormGroup;
  private _clientGroups: Array<IGroupOfClient> = [];
  private _insuranceCompanySettings: Array<IFleetSetting> = [];
  private _industrySettings: Array<IFleetSetting> = [];
  private _vehicleTypeSettings: Array<IFleetSetting> = [];
  private _vehicleUsageSettings: Array<IFleetSetting> = [];

  private _filteredClientGroups: Observable<Array<IGroupOfClient>>;

  readonly C_NAME = 'name';
  readonly C_DESCRIPTION = 'description';
  readonly C_CLIENT_GROUP_ID = 'clientGroupId';
  readonly C_FILE = 'file';
  readonly C_FLEET_COMPANIES = 'fleetCompanies';

  constructor() {
    this._form = new FormGroup({
      [this.C_NAME]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_DESCRIPTION]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_CLIENT_GROUP_ID]: new FormControl('', [Validators.required]),
      [this.C_FILE]: new FormControl(null, []),
      [this.C_FLEET_COMPANIES]: new FormControl(null, [])
    });

    // client groups
    this._filteredClientGroups = this.getControl(this.C_CLIENT_GROUP_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterClientGroups(value || ''))
    );
  }

  /* Form */

  get form(): FormGroup {
    return this._form;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this.form.get(controlName)!;
  }

  /* Common fleet information */

  get name(): string {
    return this.getControl(this.C_NAME).value;
  }

  set name(value: string) {
    this.getControl(this.C_NAME).setValue(value);
  }

  get description(): string {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }

  /* Fleet clients */

  get clientGroups(): Array<IGroupOfClient> {
    return this._clientGroups;
  }

  set clientGroups(value: Array<IGroupOfClient>) {
    this._clientGroups = value;
  }

  get filteredClientGroups(): Observable<Array<IGroupOfClient>> {
    return this._filteredClientGroups;
  }

  set clientGroupId(value: string | null) {
    this.getControl(this.C_CLIENT_GROUP_ID)?.setValue(value);
  }

  get clientGroupId(): string | null {
    return this.getControl(this.C_CLIENT_GROUP_ID)?.value;
  }

  get fleetCompanies(): string | null {
    return this.getControl(this.C_FLEET_COMPANIES).value;
  }

  set fleetCompanies(value: string | null) {
    this.getControl(this.C_FLEET_COMPANIES).setValue(value);
  }

  clients: Array<ClientViewModel> = [];

  clientColumns: Array<IHowdenColumnConfig> = [{
    fieldName: 'alias',
    fieldHeader: $localize`:@@app.unit.create.clients.alias.column:Alias`,
    width: '10rem',
    canSort: true
  }, {
    fieldName: 'fullName',
    fieldHeader: $localize`:@@app.unit.create.clients.fullName.column:Cliente`,
    canSort: true
  }, {
    fieldName: 'fiscalCode',
    fieldHeader: $localize`:@@app.unit.create.clients.fiscalCode.column:NIF/CIF`,
    canSort: true
  }];

  /* Fleet Settings */

  get insuranceCompanySettings(): Array<IFleetSetting> {
    return this._insuranceCompanySettings;
  }

  set insuranceCompanySettings(value: Array<IFleetSetting>) {
    this._insuranceCompanySettings = value;
  }

  get industrySettings(): Array<IFleetSetting> {
    return this._industrySettings;
  }

  set industrySettings(value: Array<IFleetSetting>) {
    this._industrySettings = value;
  }

  get vehicleTypeSettings(): Array<IFleetSetting> {
    return this._vehicleTypeSettings;
  }

  set vehicleTypeSettings(value: Array<IFleetSetting>) {
    this._vehicleTypeSettings = value;
  }

  get vehicleUsageSettings(): Array<IFleetSetting> {
    return this._vehicleUsageSettings;
  }

  set vehicleUsageSettings(value: Array<IFleetSetting>) {
    this._vehicleUsageSettings = value;
  }

  /* Misc */

  private filterClientGroups(value: string): Array<IGroupOfClient> {
    return value !== ''
      ? this.clientGroups.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this.clientGroups;
  }
}
