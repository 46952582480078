<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.booking.mode.search.insuranceCompanyId.label">Aseguradora</mat-label>
        <input
          #insuranceCompany
          matInput
          [formControlName]="model.C_INSURANCE_COMPANY_ID"
          [matAutocomplete]="insuranceCompanySelect"
          [howdenControlError]="insuranceCompanyIdError"
        />
        <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
          @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
            <mat-option [value]="item.insuranceCompanyId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #insuranceCompanyIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.booking.mode.search.industryId.label">Ramo</mat-label>
        <input
          matInput
          [formControlName]="model.C_INDUSTRY_ID"
          [matAutocomplete]="industrySelect"
          [howdenControlError]="industryIdError"
        />
        <mat-autocomplete #industrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
          @for (item of model.filteredIndustries | async; track item.industryId) {
            <mat-option [value]="item.industryId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #industryIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.booking.mode.search.productId.label">Producto</mat-label>
        <input
          matInput
          [formControlName]="model.C_PRODUCT_ID"
          [matAutocomplete]="productSelect"
          [howdenControlError]="productIdError"
        />
        <mat-autocomplete #productSelect="matAutocomplete" [displayWith]="getProductName.bind(this)">
          @for (item of model.filteredProducts | async; track item.productId) {
            <mat-option [value]="item.productId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #productIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.booking.mode.search.description.label">Descripción</mat-label>
        <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>

      <button
        type="button"
        class="margin-right-10 margin-top 10"
        mat-mini-fab
        color="primary"
        i18n-matTooltip="@@app.booking.mode.search.actions.create"
        matTooltip="Nuevo modo de contratación"
        (click)="onNewBookingMode()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </form>

  <div>
    <howden-table
      class="small-font"
      keyField="bookingModeId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (filtersChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
