<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <div>
    <howden-table
      class="small-font"
      keyField="policyId"
      paginable="local"
      sortable="local"
      filterable="global-local"
      [pageSize]="10"
      [cols]="model.columns"
      [data]="model.data"
      [buttons]="model.buttons"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
