@if (group) {
  <div class="group-container">
    <howden-policy-edit-regularization-group-segment [group]="group" [segment]="group.defaultSegment">
    </howden-policy-edit-regularization-group-segment>
    @if (group.surchargeSegment) {
      <howden-policy-edit-regularization-group-segment [group]="group" [segment]="group.surchargeSegment">
      </howden-policy-edit-regularization-group-segment>
    }
  </div>
}
