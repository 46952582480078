import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PolicyBonusType, PolicyClass } from 'src/app/core/enums';
import { IBookingMode } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IPolicy } from 'src/app/core/models/api/policies';
import { Limits } from 'src/app/shared/utils';

export class PolicyEditHolderUpdateViewModel {
  private _form: FormGroup;
  private _policy?: IPolicy;
  private _originalHolderId = '';
  private _bookingModes: Array<IBookingMode> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];

  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_HOLDER_ID = 'holderId';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';
  readonly C_KEEP_CURRENT_INSURED = 'keepCurrentInsured';
  readonly C_UPDATE_RISK_OWNER = 'updateRiskOwner';
  readonly C_NET_BONUS = 'netBonus';
  readonly C_TOTAL_BONUS = 'totalBonus';
  readonly C_COMMISSION_RATE = 'commissionRate';

  constructor() {
    this._form = new FormGroup({
      [this.C_OPERATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_HOLDER_ID]: new FormControl(null, [Validators.required]),
      [this.C_BOOKING_MODE_ID]: new FormControl(null, []),
      [this.C_KEEP_CURRENT_INSURED]: new FormControl(false, []),
      [this.C_UPDATE_RISK_OWNER]: new FormControl(false, []),
      [this.C_NET_BONUS]: new FormControl(null, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_TOTAL_BONUS]: new FormControl(null, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_COMMISSION_RATE]: new FormControl(null, [Validators.min(0), Validators.max(100)])
    });
  }

  get policy(): IPolicy | undefined {
    return this._policy;
  }

  set policy(value: IPolicy | undefined) {
    this._policy = value;
  }

  get originalHolderId(): string {
    return this._originalHolderId;
  }

  set originalHolderId(value: string) {
    this._originalHolderId = value;
  }

  get bookingModes(): Array<IBookingMode> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingMode>) {
    this._bookingModes = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(value: Array<IFleetCompany>) {
    this._fleetCompanies = value;
  }

  get showOptional(): boolean {
    return this._policy?.policyClass !== PolicyClass.Other;
  }

  get showBonus(): boolean {
    return this._policy?.policyClass === PolicyClass.Individual
      && this._policy?.policyBonusType === PolicyBonusType.Individual;
  }

  get showBookingMode(): boolean {
    return this._policy?.policyClass === PolicyClass.Individual;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get operationDate(): Date {
    return this.getControl(this.C_OPERATION_DATE).value;
  }

  set operationDate(value: Date) {
    this.getControl(this.C_OPERATION_DATE).setValue(value);
  }

  get holderId(): string {
    return this.getControl(this.C_HOLDER_ID).value;
  }

  set holderId(value: string) {
    this.getControl(this.C_HOLDER_ID).setValue(value);
  }

  get bookingModeId(): string | undefined {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | undefined) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }

  get keepCurrentInsured(): boolean {
    return this.getControl(this.C_KEEP_CURRENT_INSURED).value;
  }

  set keepCurrentInsured(value: boolean) {
    this.getControl(this.C_KEEP_CURRENT_INSURED).setValue(value);
  }

  get updateRiskOwner(): boolean {
    return this.getControl(this.C_UPDATE_RISK_OWNER).value;
  }

  set updateRiskOwner(value: boolean) {
    this.getControl(this.C_UPDATE_RISK_OWNER).setValue(value);
  }

  get netBonus(): number | undefined {
    return this.getControl(this.C_NET_BONUS).value;
  }

  set netBonus(value: number | undefined) {
    this.getControl(this.C_NET_BONUS).setValue(value);
  }

  get totalBonus(): number | undefined {
    return this.getControl(this.C_TOTAL_BONUS).value;
  }

  set totalBonus(value: number | undefined) {
    this.getControl(this.C_TOTAL_BONUS).setValue(value);
  }

  get commissionRate(): number | undefined {
    return this.getControl(this.C_COMMISSION_RATE).value;
  }

  set commissionRate(value: number | undefined) {
    this.getControl(this.C_COMMISSION_RATE).setValue(value);
  }
}
