import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IPolicySummaryView } from './policy-summary-view';

export class PolicyEditCertificatesViewModel {
  data: IPolicySummaryView[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyId',
      fieldHeader: $localize`:@@app.policies.edit.certificates.policyId.column:ID`,
      width: '5rem',
      canSort: true
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.edit.certificates.policyNumber.column:Nº.Póliza`,
      canSort: true
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.policies.edit.certificates.description.column:Denominación`,
      canSort: true
    },
    {
      fieldName: 'holder',
      fieldHeader: $localize`:@@app.policies.edit.certificates.holder.column:Tomador`,
      canSort: true
    },
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.edit.certificates.insuranceCompany.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.policies.edit.certificates.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.policies.edit.certificates.product.column:Producto`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.policies.edit.certificates.effectDate.column:F.Efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.policies.edit.certificates.expirationDate.column:F.Venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'policyStatus',
      fieldHeader: $localize`:@@app.policies.edit.certificates.status.policyStatus.column:Estado`,
      canSort: true
    },
    {
      fieldName: 'replacesPolicy',
      fieldHeader: $localize`:@@app.policies.edit.certificates.replacesPolicy.column:Reemplaza A`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.edit.certificates.actions.edit.tooltip:Editar`
  }, {
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.policies.edit.certificates.actions.info.tooltip:Información`
  }];
}
