import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { CurrencyService, ICurrencyService } from 'src/app/core/services/api/currencies';
import { FleetService, IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceCompanyService, InsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IMasterService, MasterService } from 'src/app/core/services/api/masters';
import { IOfficeService, OfficeService } from 'src/app/core/services/api/offices';
import { IPolicyService, PolicyService } from 'src/app/core/services/api/policies';
import { IProductService, ProductService } from 'src/app/core/services/api/products';
import { ISessionService, SessionService } from 'src/app/core/services/storage/session';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { ClaimsModule } from '../claims/claims.module';
import { BookingModeChangerComponent } from './booking-mode-changer/pages/booking-mode-changer.component';
import { PoliciesRoutingModule } from './policies-routing.module';
import { PolicyAssignationComponent } from './policy-assignation/pages/policy-assignation.component';
import { PolicyBonusUpdaterComponent } from './policy-bonus-updater/pages/policy-bonus-updater.component';
import { PolicyCancellerComponent } from './policy-canceller/pages/policy-canceller.component';
import { PolicyCreateRootComponent } from './policy-create-root/pages/policy-create-root.component';
import { PolicyEditAssignRisksComponent } from './policy-edit/components/policy-edit-assign-risks/pages/policy-edit-assign-risks.component';
import { PolicyEditBillingGroupSegmentComponent } from './policy-edit/components/policy-edit-billing-group-segment/pages/policy-edit-billing-group-segment.component';
import { PolicyEditBillingGroupsComponent } from './policy-edit/components/policy-edit-billing-groups/pages/policy-edit-billing-groups.component';
import { PolicyEditBillingInfoComponent } from './policy-edit/components/policy-edit-billing-info/pages/policy-edit-billing-info.component';
import { PolicyEditBillingComponent } from './policy-edit/components/policy-edit-billing/pages/policy-edit-billing.component';
import { PolicyEditBonusesComponent } from './policy-edit/components/policy-edit-bonuses/pages/policy-edit-bonuses.component';
import { PolicyEditCertificatesComponent } from './policy-edit/components/policy-edit-certificates/pages/policy-edit-certificates.component';
import { PolicyEditClaimsComponent } from './policy-edit/components/policy-edit-claims/pages/policy-edit-claims.component';
import { PolicyEditDocumentsComponent } from './policy-edit/components/policy-edit-documents/pages/policy-edit-documents.component';
import { PolicyEditEndorsementsComponent } from './policy-edit/components/policy-edit-endorsements/pages/policy-edit-endorsements.component';
import { PolicyEditHeaderComponent } from './policy-edit/components/policy-edit-header/pages/policy-edit-header.component';
import { PolicyEditImportComponent } from './policy-edit/components/policy-edit-import/pages/policy-edit-import.component';
import { PolicyEditIncidencesComponent } from './policy-edit/components/policy-edit-incidences/pages/policy-edit-incidences.component';
import { PolicyEditInitialStateComponent } from './policy-edit/components/policy-edit-initial-state/pages/policy-edit-initial-state.component';
import { PolicyEditParalizationsComponent } from './policy-edit/components/policy-edit-paralizations/pages/policy-edit-paralizations.component';
import { PolicyEditReceiptsComponent } from './policy-edit/components/policy-edit-receipts/pages/policy-edit-receipts.component';
import { PolicyEditRegularizationGroupSegmentComponent } from './policy-edit/components/policy-edit-regularization-group-segment/pages/policy-edit-regularization-group-segment.component';
import { PolicyEditRegularizationInfoComponent } from './policy-edit/components/policy-edit-regularization-info/pages/policy-edit-regularization-info.component';
import { PolicyEditRegularizationComponent } from './policy-edit/components/policy-edit-regularization/pages/policy-edit-regularization.component';
import { PolicyEditRisksComponent } from './policy-edit/components/policy-edit-risks/pages/policy-edit-risks.component';
import { PolicyEditComponent } from './policy-edit/components/policy-edit/pages/policy-edit.component';
import { PolicyEditBillingGroupDialogComponent } from './policy-edit/dialogs/policy-edit-billing-group-dialog/pages/policy-edit-billing-group-dialog.component';
import { PolicyEditBillingGroupRuleDialogComponent } from './policy-edit/dialogs/policy-edit-billing-group-rule-dialog/pages/policy-edit-billing-group-rule-dialog.component';
import { PolicyEditBonusDialogComponent } from './policy-edit/dialogs/policy-edit-bonus-dialog/pages/policy-edit-bonus-dialog.component';
import { PolicyEditBonusTypeUpdateDialogComponent } from './policy-edit/dialogs/policy-edit-bonus-type-update-dialog/pages/policy-edit-bonus-type-update-dialog.component';
import { PolicyEditCancelReplacementDialogComponent } from './policy-edit/dialogs/policy-edit-cancel-replacement-dialog/pages/policy-edit-cancel-replacement-dialog.component';
import { PolicyEditCancellationDialogComponent } from './policy-edit/dialogs/policy-edit-cancellation-dialog/pages/policy-edit-cancellation-dialog.component';
import { PolicyEditCertificateReplacementDialogComponent } from './policy-edit/dialogs/policy-edit-certificate-replacement-dialog/pages/policy-edit-certificate-replacement-dialog.component';
import { PolicyEditChangeRiskDialogComponent } from './policy-edit/dialogs/policy-edit-change-risk-dialog/pages/policy-edit-change-risk-dialog.component';
import { PolicyEditColectiveReplacementDialogComponent } from './policy-edit/dialogs/policy-edit-colective-replacement-dialog/pages/policy-edit-colective-replacement-dialog.component';
import { PolicyEditContractDialogComponent } from './policy-edit/dialogs/policy-edit-contract-dialog/pages/policy-edit-contract-dialog.component';
import { PolicyEditDocumentDialogComponent } from './policy-edit/dialogs/policy-edit-document-dialog/pages/policy-edit-document-dialog.component';
import { PolicyEditHolderUpdateDialogComponent } from './policy-edit/dialogs/policy-edit-holder-update-dialog/pages/policy-edit-holder-update-dialog.component';
import { PolicyEditNumberUpdateDialogComponent } from './policy-edit/dialogs/policy-edit-number-update-dialog/pages/policy-edit-number-update-dialog.component';
import { PolicyEditOfficeUpdateDialogComponent } from './policy-edit/dialogs/policy-edit-office-update-dialog/pages/policy-edit-office-update-dialog.component';
import { PolicyEditParalizationDialogComponent } from './policy-edit/dialogs/policy-edit-paralization-dialog/pages/policy-edit-paralization-dialog.component';
import { PolicyEditRiskBookingModeDialogComponent } from './policy-edit/dialogs/policy-edit-risk-booking-mode-dialog/pages/policy-edit-risk-booking-mode-dialog.component';
import { PolicyEditRiskDialogComponent } from './policy-edit/dialogs/policy-edit-risk-dialog/pages/policy-edit-risk-dialog.component';
import { PolicyEditRiskWarrantiesDialogComponent } from './policy-edit/dialogs/policy-edit-risk-warranties-dialog/pages/policy-edit-risk-warranties-dialog.component';
import { PolicyEditLayoutComponent } from './policy-edit/pages/policy-edit-layout.component';
import { PolicyHolderUpdaterComponent } from './policy-holder-updater/pages/policy-holder-updater.component';
import { PolicyLoaderComponent } from './policy-loader/pages/policy-loader.component';
import { PolicyRenewerComponent } from './policy-renewer/pages/policy-renewer.component';
import { PolicyRenumberingComponent } from './policy-renumbering/pages/policy-renumbering.component';
import { PolicyReplacerComponent } from './policy-replacer/pages/policy-replacer.component';
import { PolicyRiskUpdaterComponent } from './policy-risk-updater/pages/policy-risk-updater.component';
import { PolicySearchComponent } from './policy-search/pages/policy-search.component';
import { PolicySummaryDialogComponent } from './policy-summary-dialog/pages/policy-summary-dialog.component';
import { PolicyUpdaterComponent } from './policy-updater/pages/policy-updater.component';
import { RateChangerComponent } from './rate-changer/pages/rate-changer.component';
import { RootPolicyUpdaterComponent } from './root-policy-updater/pages/root-policy-updater.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMomentDateModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    MatTooltipModule,
    HowdenFileUploadModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    HowdenAutocompleteModule,
    ClaimsModule,
    PoliciesRoutingModule
  ],
  declarations: [
    PolicyCreateRootComponent,
    PolicyEditLayoutComponent,
    PolicyEditComponent,
    PolicyEditAssignRisksComponent,
    PolicyEditBillingComponent,
    PolicyEditBillingGroupDialogComponent,
    PolicyEditBillingGroupsComponent,
    PolicyEditBonusesComponent,
    PolicyEditBonusDialogComponent,
    PolicyEditCancellationDialogComponent,
    PolicyEditCancelReplacementDialogComponent,
    PolicyEditCertificatesComponent,
    PolicyEditCertificateReplacementDialogComponent,
    PolicyEditColectiveReplacementDialogComponent,
    PolicyEditClaimsComponent,
    PolicyEditDocumentsComponent,
    PolicyEditDocumentDialogComponent,
    PolicyEditEndorsementsComponent,
    PolicyEditHeaderComponent,
    PolicyEditHolderUpdateDialogComponent,
    PolicyEditImportComponent,
    PolicyEditIncidencesComponent,
    PolicyEditInitialStateComponent,
    PolicyEditNumberUpdateDialogComponent,
    PolicyEditOfficeUpdateDialogComponent,
    PolicyEditParalizationDialogComponent,
    PolicyEditParalizationsComponent,
    PolicyEditParalizationsComponent,
    PolicyEditReceiptsComponent,
    PolicyEditRegularizationComponent,
    PolicyEditRisksComponent,
    PolicyEditRiskDialogComponent,
    PolicyEditRiskBookingModeDialogComponent,
    PolicySearchComponent,
    PolicySummaryDialogComponent,
    PolicyEditBillingGroupRuleDialogComponent,
    PolicyEditContractDialogComponent,
    PolicyEditBonusTypeUpdateDialogComponent,
    PolicyEditRiskWarrantiesDialogComponent,
    PolicyEditChangeRiskDialogComponent,
    PolicyAssignationComponent,
    PolicyRenumberingComponent,
    PolicyUpdaterComponent,
    PolicyRiskUpdaterComponent,
    PolicyBonusUpdaterComponent,
    PolicyHolderUpdaterComponent,
    PolicyCancellerComponent,
    RootPolicyUpdaterComponent,
    PolicyRenewerComponent,
    BookingModeChangerComponent,
    PolicyReplacerComponent,
    PolicyLoaderComponent,
    PolicyEditBillingInfoComponent,
    PolicyEditBillingGroupSegmentComponent,
    PolicyEditRegularizationInfoComponent,
    PolicyEditRegularizationGroupSegmentComponent,
    RateChangerComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: ICurrencyService, useClass: CurrencyService },
    { provide: IFleetService, useClass: FleetService },
    { provide: IInsuranceCompanyService, useClass: InsuranceCompanyService },
    { provide: IMasterService, useClass: MasterService },
    { provide: IOfficeService, useClass: OfficeService },
    { provide: IPolicyService, useClass: PolicyService },
    { provide: IProductService, useClass: ProductService },
    { provide: ISessionService, useClass: SessionService }
  ]
})
export class PoliciesModule {
}
