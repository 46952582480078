import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { IFleet } from 'src/app/core/models/api/fleets';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { FleetEditMainMapper } from '../mappers';
import { FleetEditMainViewModel } from '../models';

@Component({
  selector: 'howden-fleet-edit-main',
  templateUrl: './fleet-edit-main.component.html',
  styleUrls: ['./fleet-edit-main.component.scss']
})
export class FleetEditMainComponent implements OnInit, OnDestroy {
  model = new FleetEditMainViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private fleetsSrv: IFleetService
  ) {
  }

  ngOnInit(): void {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        const fleetId = paramMap.get('id') as string;

        this.fleetsSrv.get(fleetId).subscribe((fleet: IFleet) => {
          FleetEditMainMapper.mapForEdit(fleet, this.model);
        });
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave(): void {
    const fleetId = this.model.fleetId;
    const request = FleetEditMainMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.fleetsSrv.update(fleetId, request).pipe(
      tap(() => {
        const title: string = $localize`:@@app.fleets.edit.save.dialog.title:Modificación de flotas`;

        this.uiBlockerSrv.unblock();

        this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe();
      })
    ).subscribe();
  }

  onClose(): void {
    this.navigateToSearch();
  }

  private navigateToSearch(): void {
    this.router.navigate(['fleets/search']);
  }
}
