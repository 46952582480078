import { IColectivePolicyReplacement } from 'src/app/core/models/api/policies';
import { PolicyEditColectiveReplacementViewModel } from '../models';

export abstract class PolicyEditColectiveReplacementMapper {
  static mapForUpdate(source: PolicyEditColectiveReplacementViewModel): IColectivePolicyReplacement {
    return {
      operationDate: source.operationDate,
      description: source.description,
      policyNumber: source.policyNumber,
      insuranceCompanyId: source.insuranceCompanyId,
      industryId: source.industryId,
      productId: source.productId,
      holderId: source.holderId,
      mediationCodeId: source.mediationCodeId,
      officeCode: source.officeCode,
      collectionManagerId: source.collectionManagerId,
      currencyId: source.currencyId,
      paymentTypeId: source.paymentTypeId,
      billingModelId: source.billingModelId,
      keepCurrentInsured: source.keepCurrentInsured,
      duration: parseInt(source.duration),
      expirationDate: source.expirationDate
    } as IColectivePolicyReplacement;
  }
}
