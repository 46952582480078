import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IFunctionalType, IFunctionalTypeSearchRequest } from 'src/app/core/models/api/units';
import { IFunctionalTypeService } from './functional-type.contract';

@Injectable({
  providedIn: 'root'
})
export class FunctionalTypeService implements IFunctionalTypeService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(id: string): Observable<IFunctionalType> {
    return this.httpClient.get<IFunctionalType>(APP_CONSTANTS.API_NET_FLOTAS, `/functional-types/${id}`);
  }

  search(request: IFunctionalTypeSearchRequest): Observable<IPageOf<IFunctionalType>> {
    return this.httpClient.post<IPageOf<IFunctionalType>>(APP_CONSTANTS.API_NET_FLOTAS, '/functional-types/search', request);
  }

  upsert(request: IFunctionalType): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/functional-types`, request);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/functional-types/${id}`);
  }
}
