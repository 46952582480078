<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.policyType.label">Tipo de póliza</mat-label>
        <mat-select #policyTypeSelect [formControlName]="model.C_POLICY_CLASS" [howdenControlError]="policyTypeError">
          @for (item of model.policyClasses | howdenArraySort: 'description'; track item.policyClassId) {
            <mat-option [value]="item.policyClassId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #policyTypeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.bonusType.label">Tipo de prima</mat-label>
        <mat-select [formControlName]="model.C_POLICY_BONUS_TYPE" [howdenControlError]="bonusTypeError">
          @for (item of model.bonusTypes | howdenArraySort: 'description'; track item.bonusTypeId) {
            <mat-option [value]="item.bonusTypeId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #bonusTypeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.receiptsOn.label">Recibos en</mat-label>
        <mat-select [formControlName]="model.C_RECEIPTS_ON" [howdenControlError]="receiptTargetError">
          @for (item of model.receiptTargets | howdenArraySort: 'description'; track item.receiptTargetId) {
            <mat-option [value]="item.receiptTargetId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #receiptTargetError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.description.label">Denominación</mat-label>
        <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.officeCode.label">Oficina</mat-label>
        <input
          matInput
          [formControlName]="model.C_OFFICE_CODE"
          [matAutocomplete]="officeSelect"
          [howdenControlError]="officeCodeError"
        />
        <mat-autocomplete #officeSelect="matAutocomplete" [displayWith]="getOfficeDescription.bind(this)">
          @for (item of model.filteredOffices | async; track item.officeId) {
            <mat-option [value]="item.code">
              {{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #officeCodeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.currencyId.label">Divisa</mat-label>
        <input
          matInput
          [formControlName]="model.C_CURRENCY_ID"
          [matAutocomplete]="currencySelect"
          [howdenControlError]="currencyIdError"
        />
        <mat-autocomplete #currencySelect="matAutocomplete" [displayWith]="getCurrencyDescription.bind(this)">
          @for (item of model.filteredCurrencies | async; track item.currencyId) {
            <mat-option [value]="item.currencyId">
              {{ item.description }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #currencyIdError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.insuranceCompanyId.label">Aseguradora</mat-label>
        <input
          matInput
          [formControlName]="model.C_INSURANCE_COMPANY_ID"
          [matAutocomplete]="insuranceCompanySelect"
          [howdenControlError]="insuranceCompanyIdError"
        />
        <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
          @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
            <mat-option [value]="item.insuranceCompanyId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #insuranceCompanyIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.industryId.label">Ramo</mat-label>
        <input
          matInput
          [formControlName]="model.C_INDUSTRY_ID"
          [matAutocomplete]="industrySelect"
          [howdenControlError]="industryIdError"
        />
        <mat-autocomplete #industrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
          @for (item of model.filteredIndustries | async; track item.industryId) {
            <mat-option [value]="item.industryId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #industryIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.productId.label">Producto</mat-label>
        <input
          matInput
          [formControlName]="model.C_PRODUCT_ID"
          [matAutocomplete]="productSelect"
          [howdenControlError]="productIdError"
        />
        <mat-autocomplete #productSelect="matAutocomplete" [displayWith]="getProductName.bind(this)">
          @for (item of model.filteredProducts | async; track item.productId) {
            <mat-option [value]="item.productId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #productIdError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.firstEffectDate.label">Fecha primer efecto</mat-label>
        <input
          matInput
          [matDatepicker]="firstEffectDatePicker"
          [formControlName]="model.C_FIRST_EFFECT_DATE"
          [howdenControlError]="firstEffectDateError"
          [min]="minDate"
        />
        <mat-error #firstEffectDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="firstEffectDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #firstEffectDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.effectDate.label">Fecha efecto</mat-label>
        <input
          matInput
          [matDatepicker]="effectDatePicker"
          [formControlName]="model.C_EFFECT_DATE"
          [howdenControlError]="effectDateError"
          [min]="minDate"
        />
        <mat-error #effectDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #effectDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.expirationDate.label">Fecha vencimiento</mat-label>
        <input
          matInput
          [matDatepicker]="expirationDatePicker"
          [formControlName]="model.C_EXPIRATION_DATE"
          [howdenControlError]="expirationDateError"
          [min]="minDate"
        />
        <mat-error #expirationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expirationDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.mediationCodeId.label">Código mediación</mat-label>
        <input
          matInput
          [formControlName]="model.C_MEDIATION_CODE_ID"
          [matAutocomplete]="mediationCodeSelect"
          [howdenControlError]="mediationCodeIdError"
        />
        <mat-autocomplete #mediationCodeSelect="matAutocomplete" [displayWith]="getMediationCodeDescription.bind(this)">
          @for (item of model.filteredMediationCodes | async; track item.mediationCodeId) {
            <mat-option [value]="item.mediationCodeId">
              {{ item.code }}-{{ item.description }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #mediationCodeIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.duration.label">Duración</mat-label>
        <mat-select [formControlName]="model.C_DURATION" [howdenControlError]="durationError">
          @for (item of model.policyDurations | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #durationError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.holderId.label">Tomador</mat-label>
        <input
          matInput
          [formControlName]="model.C_HOLDER_ID"
          [matAutocomplete]="holderSelect"
          [howdenControlError]="holderIdError"
        />
        <mat-autocomplete
          #holderSelect="matAutocomplete"
          [displayWith]="getFleetCompanyName.bind(this)"
          >
          @for (item of model.filteredFleetCompanies | async; track item.companyId) {
            <mat-option [value]="item.companyId">
              {{ item.clientName }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #holderIdError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.collectionManagerId.label">Gestor de cobro</mat-label>
        <input
          matInput
          [formControlName]="model.C_COLLECTION_MANAGER_ID"
          [matAutocomplete]="collectionManagerSelect"
          [howdenControlError]="collectionManagerIdError"
        />
        <mat-autocomplete
          #collectionManagerSelect="matAutocomplete"
          [displayWith]="getCollectionManagerName.bind(this)"
          >
          @for (item of model.filteredCollectionManagers | async; track item.collectionManagerId) {
            <mat-option [value]="item.collectionManagerId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #collectionManagerIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.paymentType.label">Forma de pago</mat-label>
        <mat-select [formControlName]="model.C_PAYMENT_TYPE" [howdenControlError]="paymentTypeIdError">
          @for (item of model.paymentTypes | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #paymentTypeIdError></mat-error>
      </mat-form-field>

      <howden-decimal
        class="f-basis--4"
        [formControlName]="model.C_COMMISSION_RATE"
        i18n-label="@@app.policies.create.root.commissionRate.label"
        label="Comisión"
      ></howden-decimal>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.billingModel.label">Modelo facturación</mat-label>
        <mat-select [formControlName]="model.C_BILLING_MODEL" [howdenControlError]="billingModelIdError">
          @for (item of model.billingModels | howdenArraySort: 'description'; track item.billingModelId) {
            <mat-option [value]="item.billingModelId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #billingModelIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.create.root.regularizationPeriodId.label">Periodo reg.</mat-label>
        <mat-select [formControlName]="model.C_REGULARIZATION_PERIOD_ID" [howdenControlError]="regularizationPeriodId">
          @for (item of model.regularizationPeriods | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #regularizationPeriodId></mat-error>
      </mat-form-field>

      <div class="f-basis--4 f-as--center"></div>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
      <button mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
        <span i18n="@@app.core.actions.save">Guardar</span>
      </button>
    </div>
  </form>
</div>
