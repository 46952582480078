import { IBonusTypeUpdate } from 'src/app/core/models/api/policies';
import { PolicyEditBonusTypeUpdateViewModel } from '../models';

export abstract class PolicyEditBonusTypeUpdateMapper {
  static mapForUpdate(source: PolicyEditBonusTypeUpdateViewModel): IBonusTypeUpdate {
    return {
      billingGroup: source.billingGroup
    } as IBonusTypeUpdate;
  }
}
