<div class="flex f-gap--10px margin-top-10" style="height: 500px; max-height: 500px; overflow-y: scroll;">
  <div class="f-basis--3">
    <h4 i18n="@@app.fleets.edit.settings.insuranceCompanies.label">Aseguradora</h4>
    <mat-selection-list>
      @for (setting of model.insuranceCompanySettings; track setting) {
        <mat-list-option [(selected)]="setting.enabled">
          {{ setting.description }}
        </mat-list-option>
      }
    </mat-selection-list>
  </div>

  <div class="f-basis--3">
    <h4 i18n="@@app.fleets.edit.settings.industries.label">Ramo</h4>
    <mat-selection-list>
      @for (setting of model.industrySettings; track setting.id) {
        <mat-list-option [(selected)]="setting.enabled">
          {{ setting.description }}
        </mat-list-option>
      }
    </mat-selection-list>
  </div>

  <div class="f-basis--3">
    <h4 i18n="@@app.fleets.edit.settings.vehicleTypes.label">Tipo de vehículo</h4>
    <mat-selection-list>
      @for (setting of model.vehicleTypeSettings; track setting.id) {
        <mat-list-option [(selected)]="setting.enabled">
          {{ setting.description }}
        </mat-list-option>
      }
    </mat-selection-list>
  </div>

  <div class="f-basis--3">
    <h4 i18n="@@app.fleets.edit.settings.vehicleUsages.label">Uso vehículo</h4>
    <mat-selection-list>
      @for (setting of model.vehicleUsageSettings; track setting.id) {
        <mat-list-option [(selected)]="setting.enabled">
          {{ setting.description }}
        </mat-list-option>
      }
    </mat-selection-list>
  </div>
</div>

<mat-divider></mat-divider>

<div class="flex f-jc--center f-gap--10px f-ai--center margin-top-10 margin-bottom-10">
  <button type="button" mat-raised-button (click)="onClose()">
    <span i18n="@@app.core.actions.cancel">Cancelar</span>
  </button>

  <button type="button" mat-raised-button color="success" (click)="onSave()">
    <span i18n="@@app.core.actions.save">Guardar</span>
  </button>
</div>
