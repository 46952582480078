import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { IResponse } from '@howdeniberia/core-front';
import { Observable, catchError, map, of } from 'rxjs';
import { IPolicyService } from 'src/app/core/services/api/policies';

export class PolicyNumberValidator implements AsyncValidator {
  constructor(private policySrv: IPolicyService, private insuranceCompanyId: string | (() => string | null)) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value && control.value.length > 0) {
      const icId = typeof this.insuranceCompanyId === 'string' ? this.insuranceCompanyId : this.insuranceCompanyId();
      if (!icId) {
        return of({ notAvailable: true });
      }
      return this.policySrv.isPolicyNumberAvailable(icId, control.value).pipe(
        map((response: IResponse<boolean>) => response.result ? null : { notAvailable: true }),
        catchError(() => of({ notAvailable: true }))
      );
    }
    return of(null);
  }
}
