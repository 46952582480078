import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { ICirculationAreaService } from 'src/app/core/services/api/circulation-area';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UpsertCirculationAreaTypeMapper } from '../mappers';
import { UpsertCirculationAreaViewModel } from '../models';

@Component({
  selector: 'howden-upsert-functional-type-dialog',
  templateUrl: './upsert-circulation-area-dialog.component.html',
  styleUrls: ['./upsert-circulation-area-dialog.component.scss']
})
export class UpsertCirculationAreaDialogComponent implements OnInit, OnDestroy {
  model = new UpsertCirculationAreaViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<UpsertCirculationAreaDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private circulationAreaSrv: ICirculationAreaService
  ) {
  }

  ngOnInit(): void {
    if (this.inputData?.circulationAreaId) {
      this.uiBlockerSrv.block();
      this.circulationAreaSrv.get(this.inputData.circulationAreaId).subscribe((data) => {
        this.model.description = data?.description;
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const request = UpsertCirculationAreaTypeMapper.mapForUpdate(this.model);

    if (this.inputData?.circulationAreaId) {
      request.circulationAreaId = this.inputData.circulationAreaId;
    }

    this.uiBlockerSrv.block();

    this.circulationAreaSrv.upsert(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
