import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IDashboardService } from 'src/app/core/services/api/dashboards';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IBreakPointObserverSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { IMiniCardData } from '../../components/dashboard-mini-card/mini-card-data';
import { FleetDashboardViewModel } from '../models';

@Component({
  selector: 'howden-fleet-dashboard',
  templateUrl: './fleet-dashboard.component.html',
  styleUrls: ['./fleet-dashboard.component.scss']
})
export class FleetDashboardComponent implements OnInit {
  model = new FleetDashboardViewModel();

  cardLayout = this.breakpointObserver.observe();

  constructor(
    private breakpointObserver: IBreakPointObserverSrv,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private dashboardSrv: IDashboardService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getDetails(fleetId),
      this.dashboardSrv.getUnitCount(fleetId),
      this.dashboardSrv.getActivePolicyCount(fleetId),
      this.dashboardSrv.getRootPolicyCount(fleetId),
      this.dashboardSrv.getChildPolicyCount(fleetId),
      this.dashboardSrv.getPolicyCountWithoutNumber(fleetId),
      this.dashboardSrv.getUnitCountWithoutBonus(fleetId),
      this.dashboardSrv.getUnitCountWithoutPolicy(fleetId),
      this.dashboardSrv.getUnitCountWithAllPoliciesCancelled(fleetId),
      this.dashboardSrv.getUnitsByVehicleType(fleetId),
      this.dashboardSrv.getUnitsByPolicy(fleetId),
      this.dashboardSrv.getAverageBonusByVehicleType(fleetId),
      this.dashboardSrv.getPoliciesByPaymentType(fleetId)
    ]).subscribe(([
      fleet,
      unitCount,
      activePolicyCount,
      rootPolicyCount,
      childPolicyCount,
      policyCountWithoutNumber,
      unitCountWithoutBonus,
      unitCountWithoutPolicy,
      unitCountWithAllPoliciesCancelled,
      unitsByVehicleType,
      unitsByPolicy,
      averageBonusByVehicleType,
      policiesByPaymentType
    ]) => {
      this.model.fleet = fleet;
      this.initializeMiniCards(
        unitCount,
        activePolicyCount,
        policyCountWithoutNumber,
        unitCountWithoutBonus,
        unitCountWithoutPolicy,
        unitCountWithAllPoliciesCancelled,
        rootPolicyCount,
        childPolicyCount);

      this.model.unitsByVehicleType = unitsByVehicleType;
      this.model.unitsByPolicy = unitsByPolicy;
      this.model.averageBonusByVehicleType = averageBonusByVehicleType;
      this.model.policiesByPaymentType = policiesByPaymentType;

      this.uiBlockerSrv.unblock();
    });
  }

  private initializeMiniCards(
    unitCount: number,
    activePolicyCount: number,
    policyCountWithoutNumber: number,
    unitCountWithoutBonus: number,
    unitCountWithoutPolicy: number,
    unitCountWithAllPoliciesCancelled: number,
    rootPolicyCount: number,
    childPolicyCount: number) {
    this.model.miniCardData = [{
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.units.title:Unidades`,
      value: unitCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.units.duration:Nº. de unidades con pólizas en vigor`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.policies.title:Pólizas`,
      value: activePolicyCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.policies.duration:Nº. de pólizas en vigor`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.policies.without.number.title:Pólizas S/Nº`,
      value: policyCountWithoutNumber,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.policies.without.number.duration:Nº. de pólizas sin número`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.units.without.bonus.title:Und. S/Prima`,
      value: unitCountWithoutBonus,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.units.without.bonus.duration:Nº. de unidades sin prima`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.units.without.policy.title:Und. S/Póliza`,
      value: unitCountWithoutPolicy,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.units.without.policy.duration:Unidades sin póliza`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.units.with.all.policies.cancelled.title:Und. S/Póliza`,
      value: unitCountWithAllPoliciesCancelled,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.units.with.all.policies.cancelled.duration:Unidades con todas sus pólizas anuladas`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.root.policy.count.title:Nº. de pólizas madre/colectivas`,
      value: rootPolicyCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.root.policy.count.duration:Número de pólizas madre + colectivas en vigor`
    } as IMiniCardData, {
      icon: null,
      title: $localize`:@@app.dashboards.fleet.mini.cards.child.policy.count.title:Nº. de certificados`,
      value: childPolicyCount,
      color: 'primary',
      duration: $localize`:@@app.dashboards.fleet.mini.cards.child.policy.count.duration:Número de certificados en vigor`
    } as IMiniCardData];
  }
}
