import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IProcessSearchRequest, IProcessSearchResult } from 'src/app/core/models/api/processes';

export abstract class IProcessService {
  abstract getWaitingProcessCount(): Observable<IResponse<number>>;
  abstract search(filter: IProcessSearchRequest): Observable<IPageOf<IProcessSearchResult>>;
  abstract delete(processId: string): Observable<void>;
  abstract relaunch(processId: string): Observable<void>;
}
