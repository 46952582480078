import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IFleet } from 'src/app/core/models/api/fleets';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';
import {
  ICirculationArea,
  ICreateUnit,
  IFunctionalType,
  IGoodsType,
  IIsPlateNumberAvailable,
  IMovementArea,
  IUnitAllowedActions,
  IUnitCancelPolicyRisksRequest,
  IUnitFleetTransferRequest,
  IUnitSearchRequest,
  IUnitSearchResult,
  IUnitSummary
} from 'src/app/core/models/api/units';
import { IUnit } from 'src/app/core/models/api/units/unit';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IUnitService } from './unit.contract';

@Injectable({
  providedIn: 'root'
})
export class UnitService implements IUnitService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getFunctionalTypes(): Observable<Array<IFunctionalType>> {
    return this.httpClient.get<Array<IFunctionalType>>(APP_CONSTANTS.API_NET_FLOTAS, '/units/functional-types');
  }

  getCirculationAreas(): Observable<Array<ICirculationArea>> {
    return this.httpClient.get<Array<ICirculationArea>>(APP_CONSTANTS.API_NET_FLOTAS, '/units/circulation-areas');
  }

  getMovementAreas(): Observable<Array<IMovementArea>> {
    return this.httpClient.get<Array<IMovementArea>>(APP_CONSTANTS.API_NET_FLOTAS, '/units/movement-areas');
  }

  getGoodsTypes(): Observable<Array<IGoodsType>> {
    return this.httpClient.get<Array<IGoodsType>>(APP_CONSTANTS.API_NET_FLOTAS, '/units/goods-types');
  }

  get(id: string): Observable<IUnit> {
    return this.httpClient.get<IUnit>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}`);
  }

  getAllowedActions(id: string): Observable<IUnitAllowedActions> {
    return this.httpClient.get<IUnitAllowedActions>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/allowed-actions`);
  }

  getSummary(id: string): Observable<IUnitSummary> {
    return this.httpClient.get<IUnitSummary>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/summary`);
  }

  getPolicySummary(id: string): Observable<Array<IPolicyRiskSummary>> {
    return this.httpClient.get<Array<IPolicyRiskSummary>>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/policy-summary`);
  }

  getClaims(id: string): Observable<Array<IDetailedClaim>> {
    return this.httpClient.get<Array<IDetailedClaim>>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/claims`);
  }

  getFleetsForTransfer(id: string): Observable<Array<IFleet>> {
    return this.httpClient.get<Array<IFleet>>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/transfer/fleets`);
  }

  create(request: ICreateUnit): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/units`, request);
  }

  update(id: string, unit: IUnit): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}`, unit);
  }

  changePlateNumber(id: string, newPlateNumber: string): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/change-plate-number`, { value: newPlateNumber });
  }

  transfer(id: string, request: IUnitFleetTransferRequest): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/transfer`, request);
  }

  cancelRisks(id: string, request: IUnitCancelPolicyRisksRequest): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, `/units/${id}/cancel`, request);
  }

  search(request: IUnitSearchRequest): Observable<IPageOf<IUnitSearchResult>> {
    return this.httpClient.post<IPageOf<IUnitSearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, '/units/search', request);
  }

  isPlateNumberAvailable(request: IIsPlateNumberAvailable): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(APP_CONSTANTS.API_NET_FLOTAS, `/units/plates/is-available`, request);
  }

  guessRegistrationDate(plateNumber: string): Observable<IResponse<Date>> {
    return this.httpClient.get<IResponse<Date>>(APP_CONSTANTS.API_NET_FLOTAS, `/units/plates/${plateNumber}/guess-registration-date`);
  }
}
