import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { IGroupOfClient } from 'src/app/core/models/ida/clients';
import { IOffice, IUser } from 'src/app/core/models/ida/company';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany, IMediationCode } from 'src/app/core/models/ida/providers';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyLoadViewModel {
  private _form: FormGroup;

  private _clientGroups: Array<IGroupOfClient> = [];
  private _offices: Array<IOffice> = [];
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _mainProducts: Array<IProduct> = [];
  private _mainMediationCodes: Array<IMediationCode> = [];
  private _secondaryProducts: Array<IProduct> = [];
  private _secondaryMediationCodes: Array<IMediationCode> = [];
  private _users: Array<IUser> = [];

  private _filteredClientGroups: Observable<Array<IGroupOfClient>>;
  private _mainFilteredOffices: Observable<Array<IOffice>>;
  private _mainFilteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _mainFilteredIndustries: Observable<Array<IIndustry>>;
  private _mainFilteredProducts: Observable<Array<IProduct>>;
  private _mainFilteredMediationCodes: Observable<Array<IMediationCode>>;
  private _mainFilteredAccountManagers: Observable<Array<IUser>>;
  private _mainFilteredTechnicians: Observable<Array<IUser>>;
  private _mainFilteredAuxiliaryTechnicians: Observable<Array<IUser>>;

  private _secondaryFilteredOffices: Observable<Array<IOffice>>;
  private _secondaryFilteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _secondaryFilteredIndustries: Observable<Array<IIndustry>>;
  private _secondaryFilteredProducts: Observable<Array<IProduct>>;
  private _secondaryFilteredMediationCodes: Observable<Array<IMediationCode>>;
  private _secondaryFilteredAccountManagers: Observable<Array<IUser>>;
  private _secondaryFilteredTechnicians: Observable<Array<IUser>>;
  private _secondaryFilteredAuxiliaryTechnicians: Observable<Array<IUser>>;

  readonly C_CLIENT_GROUP_ID = 'clientGroupId';

  readonly C_MAIN_OFFICE_ID = 'officeId';
  readonly C_MAIN_INSURANCE_COMPANY_ID = 'mainInsuranceCompanyId';
  readonly C_MAIN_INDUSTRY_ID = 'mainIndustryId';
  readonly C_MAIN_PRODUCT_ID = 'mainProductId';
  readonly C_MAIN_MEDIATION_CODE_ID = 'mainMediationCodeId';
  readonly C_MAIN_ACCOUNT_MANAGER_ID = 'mainAccountManagerId';
  readonly C_MAIN_TECHNICIAN_ID = 'mainTechnicianId';
  readonly C_MAIN_AUX_TECHNICIAN_ID = 'mainAuxiliarTechnicianId';
  readonly C_MAIN_REPLACE = 'mainReplace';
  readonly C_MAIN_IMPORT = 'mainImport';
  readonly C_MAIN_IMPORT_ALL = 'mainImportAll';

  readonly C_SECONDARY_OFFICE_ID = 'secondaryOfficeId';
  readonly C_SECONDARY_INSURANCE_COMPANY_ID = 'secondaryInsuranceCompanyId';
  readonly C_SECONDARY_INDUSTRY_ID = 'secondaryIndustryId';
  readonly C_SECONDARY_PRODUCT_ID = 'secondaryProductId';
  readonly C_SECONDARY_MEDIATION_CODE_ID = 'secondaryMediationCodeId';
  readonly C_SECONDARY_ACCOUNT_MANAGER_ID = 'secondaryAccountManagerId';
  readonly C_SECONDARY_TECHNICIAN_ID = 'secondaryTechnicianId';
  readonly C_SECONDARY_AUX_TECHNICIAN_ID = 'secondaryAuxiliarTechnicianId';
  readonly C_SECONDARY_OPERATION_DATE = 'secondaryOperationDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_CLIENT_GROUP_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.clientGroups, 'groupId')]),
      [this.C_MAIN_OFFICE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.offices, 'officeId')]),
      [this.C_MAIN_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_MAIN_INDUSTRY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_MAIN_PRODUCT_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.mainProducts, 'productId')]),
      [this.C_MAIN_MEDIATION_CODE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.mainMediationCodes, 'mediationCodeId')]),
      [this.C_MAIN_ACCOUNT_MANAGER_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.users, 'userId')]),
      [this.C_MAIN_TECHNICIAN_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.users, 'userId')]),
      [this.C_MAIN_AUX_TECHNICIAN_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.users, 'userId')]),
      [this.C_MAIN_IMPORT]: new FormControl(false, [Validators.required]),
      [this.C_MAIN_IMPORT_ALL]: new FormControl(false, [Validators.required]),
      [this.C_MAIN_REPLACE]: new FormControl(false, [Validators.required]),
      [this.C_SECONDARY_OFFICE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.offices, 'officeId')]),
      [this.C_SECONDARY_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_SECONDARY_INDUSTRY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_SECONDARY_PRODUCT_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.mainProducts, 'productId')]),
      [this.C_SECONDARY_MEDIATION_CODE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.mainMediationCodes, 'mediationCodeId')]),
      [this.C_SECONDARY_ACCOUNT_MANAGER_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.users, 'userId')]),
      [this.C_SECONDARY_TECHNICIAN_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.users, 'userId')]),
      [this.C_SECONDARY_AUX_TECHNICIAN_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.users, 'userId')]),
      [this.C_SECONDARY_OPERATION_DATE]: new FormControl(null, [Validators.required])
    });

    this.disableSecondarySection();

    // client groups
    this._filteredClientGroups = this.getControl(this.C_CLIENT_GROUP_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterClientGroups(value || ''))
    );

    // offices
    this._mainFilteredOffices = this.getControl(this.C_MAIN_OFFICE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterOffices(value || ''))
    );

    this._secondaryFilteredOffices = this.getControl(this.C_SECONDARY_OFFICE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterOffices(value || ''))
    );

    // insurance company filters
    this._mainFilteredInsuranceCompanies = this.getControl(this.C_MAIN_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._secondaryFilteredInsuranceCompanies = this.getControl(this.C_SECONDARY_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    // industry filters
    this._mainFilteredIndustries = this.getControl(this.C_MAIN_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._secondaryFilteredIndustries = this.getControl(this.C_SECONDARY_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    // product filters
    this._mainFilteredProducts = this.getControl(this.C_MAIN_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterMainProducts(value || ''))
    );

    this._secondaryFilteredProducts = this.getControl(this.C_SECONDARY_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterSecondaryProducts(value || ''))
    );

    // mediation code filters
    this._mainFilteredMediationCodes = this.getControl(this.C_MAIN_MEDIATION_CODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterMainMediationCodes(value || ''))
    );

    this._secondaryFilteredMediationCodes = this.getControl(this.C_SECONDARY_MEDIATION_CODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterSecondaryMediationCodes(value || ''))
    );

    // account manager filters
    this._mainFilteredAccountManagers = this.getControl(this.C_MAIN_ACCOUNT_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value || ''))
    );

    this._secondaryFilteredAccountManagers = this.getControl(this.C_SECONDARY_ACCOUNT_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value || ''))
    );

    // technician filters
    this._mainFilteredTechnicians = this.getControl(this.C_MAIN_TECHNICIAN_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value || ''))
    );

    this._secondaryFilteredTechnicians = this.getControl(this.C_SECONDARY_TECHNICIAN_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value || ''))
    );

    // auxiliary technician filters
    this._mainFilteredAuxiliaryTechnicians = this.getControl(this.C_MAIN_AUX_TECHNICIAN_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value || ''))
    );

    this._secondaryFilteredAuxiliaryTechnicians = this.getControl(this.C_SECONDARY_AUX_TECHNICIAN_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value || ''))
    );
  }

  disableSecondarySection(): void {
    this.getControl(this.C_SECONDARY_OFFICE_ID).disable();
    this.getControl(this.C_SECONDARY_INSURANCE_COMPANY_ID).disable();
    this.getControl(this.C_SECONDARY_INDUSTRY_ID).disable();
    this.getControl(this.C_SECONDARY_PRODUCT_ID).disable();
    this.getControl(this.C_SECONDARY_MEDIATION_CODE_ID).disable();
    this.getControl(this.C_SECONDARY_ACCOUNT_MANAGER_ID).disable();
    this.getControl(this.C_SECONDARY_TECHNICIAN_ID).disable();
    this.getControl(this.C_SECONDARY_AUX_TECHNICIAN_ID).disable();
    this.getControl(this.C_SECONDARY_OPERATION_DATE).disable();
  }

  enableSecondarySection(): void {
    this.getControl(this.C_SECONDARY_OFFICE_ID).enable();
    this.getControl(this.C_SECONDARY_INSURANCE_COMPANY_ID).enable();
    this.getControl(this.C_SECONDARY_INDUSTRY_ID).enable();
    this.getControl(this.C_SECONDARY_PRODUCT_ID).enable();
    this.getControl(this.C_SECONDARY_MEDIATION_CODE_ID).enable();
    this.getControl(this.C_SECONDARY_ACCOUNT_MANAGER_ID).enable();
    this.getControl(this.C_SECONDARY_TECHNICIAN_ID).enable();
    this.getControl(this.C_SECONDARY_AUX_TECHNICIAN_ID).enable();
    this.getControl(this.C_SECONDARY_OPERATION_DATE).enable();
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup { return this._form; }

  get clientGroups(): Array<IGroupOfClient> {
    return this._clientGroups;
  }

  set clientGroups(value: Array<IGroupOfClient>) {
    this._clientGroups = value;
  }

  get offices(): Array<IOffice> {
    return this._offices;
  }

  set offices(value: Array<IOffice>) {
    this._offices = value;
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get mainProducts(): Array<IProduct> {
    return this._mainProducts;
  }

  set mainProducts(value: Array<IProduct>) {
    this._mainProducts = value;
  }

  get mainMediationCodes(): Array<IMediationCode> {
    return this._mainMediationCodes;
  }

  set mainMediationCodes(value: Array<IMediationCode>) {
    this._mainMediationCodes = value;
  }

  get secondaryProducts(): Array<IProduct> {
    return this._secondaryProducts;
  }

  set secondaryProducts(value: Array<IProduct>) {
    this._secondaryProducts = value;
  }

  get secondaryMediationCodes(): Array<IMediationCode> {
    return this._secondaryMediationCodes;
  }

  set secondaryMediationCodes(value: Array<IMediationCode>) {
    this._secondaryMediationCodes = value;
  }

  get users(): Array<IUser> {
    return this._users;
  }

  set users(value: Array<IUser>) {
    this._users = value;
  }

  // ----------------------------------------------------------------------------------------------------------------

  get clientGroupId(): string {
    return this.getControl(this.C_CLIENT_GROUP_ID).value;
  }

  set clientGroupId(value: string) {
    this.getControl(this.C_CLIENT_GROUP_ID).setValue(value);
  }

  get mainOfficeId(): string {
    return this.getControl(this.C_MAIN_OFFICE_ID).value;
  }

  set mainOfficeId(value: string) {
    this.getControl(this.C_MAIN_OFFICE_ID).setValue(value);
  }

  get mainInsuranceCompanyId(): string {
    return this.getControl(this.C_MAIN_INSURANCE_COMPANY_ID).value;
  }

  set mainInsuranceCompanyId(value: string) {
    this.getControl(this.C_MAIN_INSURANCE_COMPANY_ID).setValue(value);
  }

  get mainIndustryId(): string {
    return this.getControl(this.C_MAIN_INDUSTRY_ID).value;
  }

  set mainIndustryId(value: string) {
    this.getControl(this.C_MAIN_INDUSTRY_ID).setValue(value);
  }

  get mainProductId(): string {
    return this.getControl(this.C_MAIN_PRODUCT_ID).value;
  }

  set mainProductId(value: string) {
    this.getControl(this.C_MAIN_PRODUCT_ID).setValue(value);
  }

  get mainMediationCodeId(): string {
    return this.getControl(this.C_MAIN_MEDIATION_CODE_ID).value;
  }

  set mainMediationCodeId(value: string) {
    this.getControl(this.C_MAIN_MEDIATION_CODE_ID).setValue(value);
  }

  get mainAccountManagerId(): string {
    return this.getControl(this.C_MAIN_ACCOUNT_MANAGER_ID).value;
  }

  set mainAccountManagerId(value: string) {
    this.getControl(this.C_MAIN_ACCOUNT_MANAGER_ID).setValue(value);
  }

  get mainTechnicianId(): string {
    return this.getControl(this.C_MAIN_TECHNICIAN_ID).value;
  }

  set mainTechnicianId(value: string) {
    this.getControl(this.C_MAIN_TECHNICIAN_ID).setValue(value);
  }

  get mainAuxiliarTechnicianId(): string {
    return this.getControl(this.C_MAIN_AUX_TECHNICIAN_ID).value;
  }

  set mainAuxiliarTechnicianId(value: string) {
    this.getControl(this.C_MAIN_AUX_TECHNICIAN_ID).setValue(value);
  }

  get mainReplace(): boolean {
    return this.getControl(this.C_MAIN_REPLACE).value;
  }

  set mainReplace(value: boolean) {
    this.getControl(this.C_MAIN_REPLACE).setValue(value);
  }

  get mainImport(): boolean {
    return this.getControl(this.C_MAIN_IMPORT).value;
  }

  set mainImport(value: boolean) {
    this.getControl(this.C_MAIN_IMPORT).setValue(value);
  }

  get mainImportAll(): boolean {
    return this.getControl(this.C_MAIN_IMPORT_ALL).value;
  }

  set mainImportAll(value: boolean) {
    this.getControl(this.C_MAIN_IMPORT_ALL).setValue(value);
  }

  // ----------------------------------------------------------------------------------------------------------------

  get secondaryOfficeId(): string {
    return this.getControl(this.C_SECONDARY_OFFICE_ID).value;
  }

  set secondaryOfficeId(value: string) {
    this.getControl(this.C_SECONDARY_OFFICE_ID).setValue(value);
  }

  get secondaryInsuranceCompanyId(): string {
    return this.getControl(this.C_SECONDARY_INSURANCE_COMPANY_ID).value;
  }

  set secondaryInsuranceCompanyId(value: string) {
    this.getControl(this.C_SECONDARY_INSURANCE_COMPANY_ID).setValue(value);
  }

  get secondaryIndustryId(): string {
    return this.getControl(this.C_SECONDARY_INDUSTRY_ID).value;
  }

  set secondaryIndustryId(value: string) {
    this.getControl(this.C_SECONDARY_INDUSTRY_ID).setValue(value);
  }

  get secondaryProductId(): string {
    return this.getControl(this.C_SECONDARY_PRODUCT_ID).value;
  }

  set secondaryProductId(value: string) {
    this.getControl(this.C_SECONDARY_PRODUCT_ID).setValue(value);
  }

  get secondaryMediationCodeId(): string {
    return this.getControl(this.C_SECONDARY_MEDIATION_CODE_ID).value;
  }

  set secondaryMediationCodeId(value: string) {
    this.getControl(this.C_SECONDARY_MEDIATION_CODE_ID).setValue(value);
  }

  get secondaryAccountManagerId(): string {
    return this.getControl(this.C_SECONDARY_ACCOUNT_MANAGER_ID).value;
  }

  set secondaryAccountManagerId(value: string) {
    this.getControl(this.C_SECONDARY_ACCOUNT_MANAGER_ID).setValue(value);
  }

  get secondaryTechnicianId(): string {
    return this.getControl(this.C_SECONDARY_TECHNICIAN_ID).value;
  }

  set secondaryTechnicianId(value: string) {
    this.getControl(this.C_SECONDARY_TECHNICIAN_ID).setValue(value);
  }

  get secondaryAuxiliarTechnicianId(): string {
    return this.getControl(this.C_SECONDARY_AUX_TECHNICIAN_ID).value;
  }

  set secondaryAuxiliarTechnicianId(value: string) {
    this.getControl(this.C_SECONDARY_AUX_TECHNICIAN_ID).setValue(value);
  }

  get secondaryOperationDate(): Date {
    return this.getControl(this.C_SECONDARY_OPERATION_DATE).value;
  }

  set secondaryOperationDate(value: Date) {
    this.getControl(this.C_SECONDARY_OPERATION_DATE).setValue(value);
  }

  // ----------------------------------------------------------------------------------------------------------------

  get filteredClientGroups(): Observable<Array<IGroupOfClient>> {
    return this._filteredClientGroups;
  }

  get mainFilteredOffices(): Observable<Array<IOffice>> {
    return this._mainFilteredOffices;
  }

  get mainFilteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._mainFilteredInsuranceCompanies;
  }

  get mainFilteredIndustries(): Observable<Array<IIndustry>> {
    return this._mainFilteredIndustries;
  }

  get mainFilteredProducts(): Observable<Array<IProduct>> {
    return this._mainFilteredProducts;
  }

  get mainFilteredMediationCodes(): Observable<Array<IMediationCode>> {
    return this._mainFilteredMediationCodes;
  }

  get mainFilteredAccountManagers(): Observable<Array<IUser>> {
    return this._mainFilteredAccountManagers;
  }

  get mainFilteredTechnicians(): Observable<Array<IUser>> {
    return this._mainFilteredTechnicians;
  }

  get mainFilteredAuxiliaryTechnicians(): Observable<Array<IUser>> {
    return this._mainFilteredAuxiliaryTechnicians;
  }

  get secondaryFilteredOffices(): Observable<Array<IOffice>> {
    return this._secondaryFilteredOffices;
  }

  get secondaryFilteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._secondaryFilteredInsuranceCompanies;
  }

  get secondaryFilteredIndustries(): Observable<Array<IIndustry>> {
    return this._secondaryFilteredIndustries;
  }

  get secondaryFilteredProducts(): Observable<Array<IProduct>> {
    return this._secondaryFilteredProducts;
  }

  get secondaryFilteredMediationCodes(): Observable<Array<IMediationCode>> {
    return this._secondaryFilteredMediationCodes;
  }

  get secondaryFilteredAccountManagers(): Observable<Array<IUser>> {
    return this._secondaryFilteredAccountManagers;
  }

  get secondaryFilteredTechnicians(): Observable<Array<IUser>> {
    return this._secondaryFilteredTechnicians;
  }

  get secondaryFilteredAuxiliaryTechnicians(): Observable<Array<IUser>> {
    return this._secondaryFilteredAuxiliaryTechnicians;
  }

  // ----------------------------------------------------------------------------------------------------------------

  private filterClientGroups(value: string): Array<IGroupOfClient> {
    return value !== ''
      ? this.clientGroups.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this.clientGroups;
  }

  private filterOffices(value: string): Array<IOffice> {
    return value !== ''
      ? this.offices.filter(c => c.name?.toLowerCase().includes(value.toLowerCase()))
      : this.offices;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this.insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this.industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.industries;
  }

  private filterMainProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this.mainProducts.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.mainProducts;
  }

  private filterSecondaryProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this.secondaryProducts.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.secondaryProducts;
  }

  private filterMainMediationCodes(value: string): Array<IMediationCode> {
    return value !== ''
      ? this.mainMediationCodes.filter(x => x.code?.toLowerCase().startsWith(value.toLowerCase())
        || x.description?.toLowerCase().includes(value.toLowerCase()))
      : this.mainMediationCodes;
  }

  private filterSecondaryMediationCodes(value: string): Array<IMediationCode> {
    return value !== ''
      ? this.secondaryMediationCodes.filter(x => x.code?.toLowerCase().startsWith(value.toLowerCase())
        || x.description?.toLowerCase().includes(value.toLowerCase()))
      : this.secondaryMediationCodes;
  }

  private filterUsers(value: string): Array<IUser> {
    return value !== ''
      ? this.users.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()))
      : this.users;
  }
}
