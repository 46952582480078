import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class UpsertCirculationAreaViewModel {
  private _form: FormGroup;

  readonly C_DESCRIPTION = 'description';

  constructor() {
    this._form = new FormGroup({
      [this.C_DESCRIPTION]: new FormControl(null, [Validators.required])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get description(): string {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }
}
