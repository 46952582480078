<form [formGroup]="model.form" class="howden-dialog-content text-center">
  <span mat-dialog-title class="title-text" i18n="@@app.policies.edit.office.update.title">Cambio de oficina</span>
  <span class="subtitle-text" i18n="@@app.policies.edit.office.update.subtitle">
    Por favor, indique la nueva oficina.
  </span>

  <mat-dialog-content>
    <div class="flex f-gap--10px margin-bottom-20 margin-top-20">
      <mat-slide-toggle
        [formControlName]="model.C_UPDATE_CANCELLED_POLICIES"
        i18n="@@app.policies.edit.office.update.updateCancelledPolicies.label">Actualizar pólizas anuladas</mat-slide-toggle>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field>
        <mat-label i18n="@@app.policies.edit.office.update.officeId.label">Oficina</mat-label>
        <input
          matInput
          [formControlName]="model.C_OFFICE_ID"
          [matAutocomplete]="officeSelect"
          [howdenControlError]="officeIdError"
        />
        <mat-autocomplete
          #officeSelect="matAutocomplete"
          [displayWith]="getOfficeName.bind(this)"
        >
          @for (item of model.filteredOffices | async; track item.officeId) {
            <mat-option [value]="item.officeId">
              {{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #officeIdError></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>

    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
