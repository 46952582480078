import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IButtonActionData } from '@howdeniberia/core-front';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { SubSink } from 'subsink';
import { ClaimSummaryDialogComponent } from '../../claim-summary-dialog/pages/claim-summary-dialog.component';
import { DetailedClaimMapper } from '../mappers';
import { ClaimListViewModel } from '../models';

@Component({
  selector: 'howden-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss']
})
export class ClaimListComponent implements OnChanges, OnDestroy {
  model = new ClaimListViewModel();

  @Input() claims: Array<IDetailedClaim> = [];

  private _subscriptions = new SubSink();

  constructor(private dialog: MatDialog) {
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.model.data = DetailedClaimMapper.map(this.claims);
  }

  onAction(event: IButtonActionData): void {
    this.showInfo(event);
  }

  private showInfo(event: IButtonActionData): void {
    const inputData = { id: event.row.claimId };
    const dialogRef = this.dialog.open(ClaimSummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }
}
