<div class="flex f-jc--end f-ai--center margin-top-10 margin-bottom-20">
  <button
    type="button"
    mat-mini-fab
    color="primary"
    i18n-matTooltip="@@app.policies.edit.documents.actions.create"
    matTooltip="Nuevo documento"
    (click)="onNewDocument()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>

<div class="flex">
  <howden-table
    class="small-font"
    keyField="documentId"
    paginable="local"
    sortable="local"
    [pageSize]="10"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
