import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { ICurrencyService } from 'src/app/core/services/api/currencies';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreateEndorsementMapper } from '../mappers';
import { CreateEndorsementViewModel } from '../models';
import { Limits } from 'src/app/shared/utils';

@Component({
  selector: 'howden-create-endorsement-dialog',
  templateUrl: './create-endorsement-dialog.component.html',
  styleUrls: ['./create-endorsement-dialog.component.scss']
})
export class CreateEndorsementDialogComponent implements OnInit, OnDestroy {
  model = new CreateEndorsementViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<CreateEndorsementDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService,
    private currencySrv: ICurrencyService,
    private masterSrv: IMasterService
  ) {
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.masterSrv.getEndorsementStatuses(),
      this.masterSrv.getEndorsementTypes(),
      this.masterSrv.getReceiptSituations(),
      this.masterSrv.getReceiptTypes(),
      this.currencySrv.getCurrencies()
    ]).subscribe(([
      endorsementStatuses,
      endorsementTypes,
      receiptSituations,
      receiptTypes,
      currencies
    ]) => {
      this.model.endorsementStatuses = endorsementStatuses;
      this.model.endorsementTypes = endorsementTypes;
      this.model.receiptSituations = receiptSituations;
      this.model.receiptTypes = receiptTypes;
      this.model.currencies = currencies;

      this.onChanges();
      this.onDisableReceipt(true);
      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    const policyId = this.inputData.id;
    const request = CreateEndorsementMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.policySrv.createEndorsement(policyId, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_ENDORSEMENT_CREATES_RECEIPT).valueChanges.subscribe((val: boolean) => {
      this.onDisableReceipt(!val);
    });
  }

  private onDisableReceipt(val: boolean): void {
    if (val === true) {
      this.model.disableReceiptStep();
    } else {
      this.model.enableReceiptStep();
    }
  }
}
