import { BillingGroupRuleType } from 'src/app/core/enums';
import { BillingGroupUtils } from 'src/app/shared/utils';

export class PolicyEditBillingGroupRuleViewModel {
  constructor(private _ruleId: string, private _typeId: BillingGroupRuleType, private _value: string) { }

  get ruleId(): string {
    return this._ruleId;
  }

  get type(): string {
    return BillingGroupUtils.getRuleTypeDescription(this._typeId);
  }

  get value(): string {
    return this._value;
  }
}
