import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FileUploadComponent } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FormGroupUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyLoaderViewModel } from '../models';

@Component({
  selector: 'howden-policy-loader',
  templateUrl: './policy-loader.component.html',
  styleUrls: ['./policy-loader.component.scss']
})
export class PolicyLoaderComponent implements OnDestroy {
  model = new PolicyLoaderViewModel();

  @ViewChild('file') fileRef!: FileUploadComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService
  ) {
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onValidate() {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.fleetSrv.policyLoaderValidation(fleetId, request).pipe(
      tap((response) => {
        this.uiBlockerSrv.unblock();

        if (response && response.length > 0) {
          this.model.loaderResults = response;
        } else {
          const title = $localize`:@@app.policies.loader.validation.confirm.dialog.title:Validación de carga de pólizas`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe();
        }
      })
    ).subscribe();
  }

  onAccept() {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.fleetSrv.policyLoader(fleetId, request).pipe(
      tap((response) => {
        this.uiBlockerSrv.unblock();

        if (response && response.length > 0) {
          this.model.loaderResults = response;
        } else {
          const title = $localize`:@@app.policies.loader.confirm.dialog.title:Carga de pólizas`;
          const subtitle = $localize`:@@app.policies.loader.confirm.dialog.subtitle:Finalizada correctamente, recuerde que no todos los cambios son efectivos de inmediato.`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reset());
        }
      })
    ).subscribe();
  }

  onGoBack(): void {
    this.reset();
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef.file
    });
  }

  get hasUpdaterResults(): boolean {
    return this.model.loaderResults && this.model.loaderResults.length > 0;
  }

  private reset(): void {
    this.model.form.reset();
    this.model.loaderResults = [];
    this.fileRef?.clearFiles();
  }
}
