import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { IFigureDetails } from 'src/app/core/models/api/core';
import { IPolicySummary } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicySummaryFiguresViewModel, PolicySummaryRisksViewModel, PolicySummaryViewModel } from '../models';

@Component({
  selector: 'howden-policy-summary-dialog',
  templateUrl: './policy-summary-dialog.component.html',
  styleUrls: ['./policy-summary-dialog.component.scss']
})
export class PolicySummaryDialogComponent implements OnInit {
  policy: PolicySummaryViewModel = new PolicySummaryViewModel();
  risks!: PolicySummaryRisksViewModel;
  figures!: PolicySummaryFiguresViewModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private router: Router,
    private dialogRef: MatDialogRef<PolicySummaryDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const id = this.inputData.id as string;

    this.uiBlockerSrv.block();

    this.policySrv.getSummary(id).subscribe({
      next: (data: IPolicySummary) => {
        const figureRange = data
          .figures
          .filter(a => typeof a.figureType !== 'undefined' && a.figureType !== null
            && typeof a.userName !== 'undefined' && a.userName !== null)
          .sort((a: IFigureDetails, b: IFigureDetails) => a.figureType?.localeCompare(b.figureType ?? '') ?? -1);

        this.policy = new PolicySummaryViewModel(data);
        this.risks = new PolicySummaryRisksViewModel(data.risks);
        this.figures = new PolicySummaryFiguresViewModel(figureRange);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  onClose(): void {
    this.closeDialog(DialogResultTypes.OK);
  }

  onRiskAction(event: IButtonActionData) {
    this.closeDialog(DialogResultTypes.OK);
    this.router.navigate(['units/edit', event.row.unitId]);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
