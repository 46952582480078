import { IUpdatePolicyOffice } from 'src/app/core/models/ida/production';
import { PolicyEditOfficeUpdateViewModel } from '../models';

export abstract class PolicyEditOfficeUpdateMapper {
  static mapForUpdate(source: PolicyEditOfficeUpdateViewModel): IUpdatePolicyOffice {
    return {
      officeId: source.officeId,
      updateCancelledChildPolicies: source.updateCancelledChildPolicies
    } as IUpdatePolicyOffice;
  }
}
