import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { UnitSummaryDialogComponent } from 'src/app/features/units/unit-summary-dialog/pages/unit-summary-dialog.component';
import { SubSink } from 'subsink';
import { PolicyEditRiskBookingModeDialogComponent } from '../../../dialogs/policy-edit-risk-booking-mode-dialog/pages/policy-edit-risk-booking-mode-dialog.component';
import { PolicyEditRiskDialogComponent } from '../../../dialogs/policy-edit-risk-dialog/pages/policy-edit-risk-dialog.component';
import { PolicyEditRiskWarrantiesDialogComponent } from '../../../dialogs/policy-edit-risk-warranties-dialog/pages/policy-edit-risk-warranties-dialog.component';
import { PolicyEditRisksViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-risks',
  templateUrl: './policy-edit-risks.component.html',
  styleUrls: ['./policy-edit-risks.component.scss']
})
export class PolicyEditRisksComponent implements OnChanges, OnDestroy {
  model = new PolicyEditRisksViewModel();

  @Input() policyId = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private policySrv: IPolicyService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.reload();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEdit(event);
    } else if (event.buttonName === 'edit-booking-mode') {
      this.onEditBookingMode(event);
    } else if (event.buttonName === 'edit-warranties') {
      this.onEditWarranties(event);
    } else if (event.buttonName === 'delete') {
      this.onDelete(event);
    } else if (event.buttonName === 'info') {
      this.onShowInfo(event);
    }
  }

  private onEdit(event: IButtonActionData): void {
    const inputData = {
      policyId: this.policyId,
      policyRiskId: event.row.policyRiskId
    };
    const dialogRef = this.dialog.open(PolicyEditRiskDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.reload());
  }

  private onEditBookingMode(event: IButtonActionData) {
    const inputData = {
      policyId: this.policyId,
      policyRiskId: event.row.policyRiskId,
      bookingModeId: event.row.bookingModeId
    };
    const dialogRef = this.dialog.open(PolicyEditRiskBookingModeDialogComponent, {
      minWidth: '600px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.reload());
  }

  private onEditWarranties(event: IButtonActionData) {
    const inputData = {
      policyId: this.policyId,
      policyRiskId: event.row.policyRiskId
    };
    const dialogRef = this.dialog.open(PolicyEditRiskWarrantiesDialogComponent, {
      minWidth: '600px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.reload());
  }

  private onDelete(event: IButtonActionData) {
    const policyId = this.policyId;
    const policyRiskId = event.row.policyRiskId;
    const title: string = $localize`:@@app.policies.edit.risks.delete.title:Borrado de riesgo-póliza`;
    const question: string = $localize`:@@app.policies.edit.risks.subtitle:Se borrará el riesgo-póliza, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`:@@app.policies.edit.risks.confirm.subtitle:Finalizado correctamente`;

          this.uiBlockerSrv.block();
          this.policySrv.deletePolicyRisk(policyId, policyRiskId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.reload();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onShowInfo(event: IButtonActionData): void {
    const inputData = { id: event.row.unitId };
    const dialogRef = this.dialog.open(UnitSummaryDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  private reload(): void {
    this.policySrv.getPolicyRisks(this.policyId).subscribe((data: Array<IPolicyRiskSummary>) => this.model.data = data);
  }
}
