import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IInsurerMergerItem } from 'src/app/core/models/api/fleets';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';

export class InsurerMergerViewModel {
  private _form: FormGroup;
  private _insuranceCompanies: Array<IInsuranceCompany> = [];

  readonly C_SOURCE_INSURANCE_COMPANY_ID = 'sourceInsuranceCompanyId';
  readonly C_TARGET_INSURANCE_COMPANY_ID = 'targetInsuranceCompanyId';
  readonly C_OPERATION_DATE = 'operationDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_SOURCE_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required]),
      [this.C_TARGET_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required]),
      [this.C_OPERATION_DATE]: new FormControl(null, [Validators.required])
    });
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this.form.get(controlName)!;
  }

  get form(): FormGroup { return this._form; }

  get sourceInsuranceCompanyId(): string | null | undefined {
    return this.getControl(this.C_SOURCE_INSURANCE_COMPANY_ID).value;
  }

  set sourceInsuranceCompanyId(value: string | null | undefined) {
    this.getControl(this.C_SOURCE_INSURANCE_COMPANY_ID).setValue(value);
  }

  get targetInsuranceCompanyId(): string | null | undefined {
    return this.getControl(this.C_TARGET_INSURANCE_COMPANY_ID).value;
  }

  set targetInsuranceCompanyId(value: string | null | undefined) {
    this.getControl(this.C_TARGET_INSURANCE_COMPANY_ID).setValue(value);
  }

  get operationDate(): string | null | undefined {
    return this.getControl(this.C_OPERATION_DATE).value;
  }

  set operationDate(value: string | null | undefined) {
    this.getControl(this.C_OPERATION_DATE).setValue(value);
  }

  data: IInsurerMergerItem[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'fleetName',
      fieldHeader: $localize`:@@app.insurer.merger.preview.fleetName.column:Flota`,
      canSort: true
    },
    {
      fieldName: 'sourcePolicyNumber',
      fieldHeader: $localize`:@@app.insurer.merger.preview.sourcePolicyNumber.column:Número de póliza (origen)`,
      canSort: true
    },
    {
      fieldName: 'targetPolicyNumber',
      fieldHeader: $localize`:@@app.insurer.merger.preview.targetPolicyNumber.column:Número de póliza (destino)`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
