import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IUnitService } from 'src/app/core/services/api/units';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { UnitCancelRisksMapper } from '../mapper';
import { UnitCancelRisksViewModel } from '../models';

@Component({
  selector: 'howden-unit-cancel-risks-dialog',
  templateUrl: './unit-cancel-risks-dialog.component.html',
  styleUrls: ['./unit-cancel-risks-dialog.component.scss']
})
export class UnitCancelRisksDialogComponent implements OnInit {
  model: UnitCancelRisksViewModel;

  get minDate(): Date { return Limits.minDate; }

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<UnitCancelRisksDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private unitsSrv: IUnitService,
    private masterSrv: IMasterService
  ) {
    this.model = new UnitCancelRisksViewModel(inputData.selection);
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    this.masterSrv.getPolicyCancellationReasons().subscribe({
      next: (data: Array<IMasterValue>) => {
        this.model.cancellationReasons = data;
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }

  onAccept() {
    const id = this.inputData.id;
    const request = UnitCancelRisksMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.unitsSrv.cancelRisks(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
