import { IClaimsByCompanyReportRequest } from 'src/app/core/models/api/reports';
import { ClaimsByCompanyReportViewModel } from '../models';

export abstract class ClaimsByCompanyReportMapper {
  static mapForGeneration(activeFleet: string, source: ClaimsByCompanyReportViewModel): IClaimsByCompanyReportRequest {
    return {
      fleetId: activeFleet,
      claimPaymentId: source.claimPaymentId,
      startDate: source.startDate,
      endDate: source.endDate,
      rootPolicies: source.rootPolicies.filter(x => x.selected === true).map(x => x.id)
    } as IClaimsByCompanyReportRequest;
  }
}
