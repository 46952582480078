import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class PolicyEditDocumentViewModel {
  private _form: FormGroup;

  readonly C_DOCUMENT_ID = 'documentId';
  readonly C_ALIAS = 'alias';
  readonly C_REFERENCE = 'reference';
  readonly C_CLIENT_VISIBLE = 'isClientVisible';
  readonly C_FILE = 'file';
  readonly C_OBSERVATIONS = 'observations';

  constructor() {
    this._form = new FormGroup({
      [this.C_DOCUMENT_ID]: new FormControl(null, []),
      [this.C_ALIAS]: new FormControl(null, [Validators.required]),
      [this.C_REFERENCE]: new FormControl(null, []),
      [this.C_CLIENT_VISIBLE]: new FormControl(true, [Validators.required]),
      [this.C_FILE]: new FormControl(null, [Validators.required]),
      [this.C_OBSERVATIONS]: new FormControl(null, [])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get documentId(): string | undefined {
    return this.getControl(this.C_DOCUMENT_ID).value;
  }

  set documentId(value: string | undefined) {
    this.getControl(this.C_DOCUMENT_ID).setValue(value);
  }

  get alias(): string | undefined {
    return this.getControl(this.C_ALIAS).value;
  }

  set alias(value: string | undefined) {
    this.getControl(this.C_ALIAS).setValue(value);
  }

  get reference(): string | undefined {
    return this.getControl(this.C_REFERENCE).value;
  }

  set reference(value: string | undefined) {
    this.getControl(this.C_REFERENCE).setValue(value);
  }

  get isClientVisible(): string | undefined {
    return this.getControl(this.C_CLIENT_VISIBLE).value;
  }

  set isClientVisible(value: string | undefined) {
    this.getControl(this.C_CLIENT_VISIBLE).setValue(value);
  }

  get file(): any {
    return this.getControl(this.C_FILE).value;
  }

  set file(value: any) {
    this.getControl(this.C_FILE).setValue(value);
  }

  get observations(): any {
    return this.getControl(this.C_OBSERVATIONS).value;
  }

  set observations(value: any) {
    this.getControl(this.C_OBSERVATIONS).setValue(value);
  }
}
