import { FormControl, FormGroup } from '@angular/forms';
import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { IPolicyClass, IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { PolicySearchResultViewModel } from './policy-search-result.viewmodel';

export class PolicySearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IPolicySearchRequest;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;
  private _policyStatuses: Array<IMasterValue> = [];
  private _policyClasses: Array<IPolicyClass> = [];

  readonly C_RISK_DESCRIPTION = 'riskDescription';
  readonly C_POLICY_NUMBER = 'policyNumber';
  readonly C_HOLDER = 'holder';
  readonly C_CLASS_ID = 'policyClassId';
  readonly C_STATUS_ID = 'statusId';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_RISK_DESCRIPTION]: new FormControl('', []),
      [this.C_POLICY_NUMBER]: new FormControl('', []),
      [this.C_HOLDER]: new FormControl('', []),
      // [this.C_CLASS_ID]: new FormControl(PolicyClass.Other.toString(), []),
      [this.C_CLASS_ID]: new FormControl('', []),
      [this.C_STATUS_ID]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this.sortedBy,
      sortDirection: this.sortDirection
    } as IPolicySearchRequest;

    this.updateServerSideFilters();
  }

  data: PolicySearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.search.policyNumber.column:Nº.Póliza`,
      canSort: true
    },
    {
      fieldName: 'riskDescription',
      fieldHeader: $localize`:@@app.policies.search.riskDescription.column:Denom.`,
      canSort: true
    },
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.search.insuranceCompany.column:Aseg.`,
      canSort: false
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.policies.search.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.policies.search.product.column:Producto`,
      canSort: true
    },
    {
      fieldName: 'holder',
      fieldHeader: $localize`:@@app.policies.search.holder.column:Tomador`,
      canSort: true
    },
    {
      fieldName: 'effectDate',
      fieldHeader: $localize`:@@app.policies.search.effectDate.column:F.Efecto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'expirationDate',
      fieldHeader: $localize`:@@app.policies.search.expirationDate.column:F.Venc.`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.policies.search.status.column:Estado`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.policies.search.actions.edit.tooltip:Editar`
  }, {
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.policies.search.actions.info.tooltip:Información`
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get policyStatuses(): Array<IMasterValue> {
    return this._policyStatuses;
  }

  set policyStatuses(policyStatuses: Array<IMasterValue>) {
    this._policyStatuses = policyStatuses;
  }

  get policyClasses(): Array<IPolicyClass> {
    return this._policyClasses;
  }

  set policyClasses(policyClasses: Array<IPolicyClass>) {
    this._policyClasses = policyClasses;
  }

  get searchRequest(): IPolicySearchRequest {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get riskDescription(): string | undefined {
    return this._filterForm.get(this.C_RISK_DESCRIPTION)?.value;
  }

  set riskDescription(value: string | undefined) {
    this._filterForm.get(this.C_RISK_DESCRIPTION)?.setValue(value);
  }

  get policyNumber() {
    return this._filterForm.get(this.C_POLICY_NUMBER)?.value;
  }

  set policyNumber(value: string | undefined) {
    this._filterForm.get(this.C_POLICY_NUMBER)?.setValue(value);
  }

  get holder() {
    return this._filterForm.get(this.C_HOLDER)?.value;
  }

  set holder(value: string | undefined) {
    this._filterForm.get(this.C_HOLDER)?.setValue(value);
  }

  get policyClassId(): string | undefined {
    return this._filterForm.get(this.C_CLASS_ID)?.value;
  }

  set policyClassId(value: string | undefined) {
    this._filterForm.get(this.C_CLASS_ID)?.setValue(value);
  }

  get statusId(): string | undefined {
    return this._filterForm.get(this.C_STATUS_ID)?.value;
  }

  set statusId(value: string | undefined) {
    this._filterForm.get(this.C_STATUS_ID)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.riskDescription = this.riskDescription;
    this.searchRequest.policyNumber = this.policyNumber;
    this.searchRequest.holder = this.holder;
    this.searchRequest.policyClasses = this.policyClassId ? [this.policyClassId] : [];
    this.searchRequest.statuses = this.statusId ? [this.statusId] : [];
  }
}
