import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { HowdenTableComponent, ITableEventData } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IPolicyImport } from 'src/app/core/models/api/policies';
import { IPolicyImportRequest } from 'src/app/core/models/api/policies/policy-import-request';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicyImportViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-import',
  templateUrl: './policy-edit-import.component.html',
  styleUrls: ['./policy-edit-import.component.scss']
})
export class PolicyEditImportComponent implements OnChanges, OnDestroy {
  model = new PolicyImportViewModel();
  disableImport = true;
  hideToggleSelectionCheckbox = false;

  @Input() policyId = '';
  @ViewChild('policyImportTable') policyImportTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private policySrv: IPolicyService
  ) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onImport(): void {
    const request = {
      remotePolicyIds: this.policyImportTable.selectedRows()
    } as IPolicyImportRequest;

    this.uiBlockerSrv.block();
    this.policySrv.importPolicies(this.policyId, request).pipe(
      tap({
        complete: () => {
          this.uiBlockerSrv.unblock();

          const title: string = $localize`:@@app.policies.edit.import.confirm.title:Importación de pólizas`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.refresh());
        }
      })
    ).subscribe();
  }

  onFiltersChanged($event: ITableEventData) {
    this.hideToggleSelectionCheckbox = typeof $event.genericfilterValue !== 'undefined'
      && $event.genericfilterValue !== null
      && $event.genericfilterValue.length > 0;
  }

  onSelectionChanged(event: any[]) {
    this.disableImport = event.length === 0;
  }

  private refresh(): void {
    this.uiBlockerSrv.block();
    this.policySrv.getPoliciesToImport(this.policyId).subscribe({
      next: (data: Array<IPolicyImport>) => this.model.data = data,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
