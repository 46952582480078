<form [formGroup]="model.form" class="howden-dialog-content text-center">
  <span mat-dialog-title class="title-text" i18n="@@app.policies.edit.change.risk.title">Cambio de riesgo</span>
  <span class="subtitle-text">
    <div><b i18n="@@app.policies.edit.change.risk.subtitle.header">ATENCIÓN</b></div>
    <div i18n="@@app.policies.edit.change.risk.subtitle.body">Se sustituirá el riesgo actualmente asociado a la póliza</div>
  </span>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field class="margin-bottom-10">
        <mat-label i18n="@@app.policies.edit.change.risk.riskAlias.label">Indique la matrícula del riesgo a asociar a la póliza</mat-label>
        <input
          matInput
          [formControlName]="model.C_RISK_ALIAS"
          [howdenControlError]="riskAliasError"
          maxlength="15"
          placeholder="Matrícula"
          i18n-placeholder="@@app.policies.edit.change.risk.riskAlias.placeholder"
        />
        <mat-error #riskAliasError></mat-error>
        @if (model.getControl(model.C_RISK_ALIAS).hasError('notAvailable')) {
          <mat-error
          i18n="@@app.policies.edit.change.risk.plate.number.not.available.error">La matrícula indicada no está disponible.</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.policies.edit.change.risk.operationDate.label">F. Operación</mat-label>
        <input
          matInput
          [matDatepicker]="operationDatePicker"
          [formControlName]="model.C_OPERATION_DATE"
          [howdenControlError]="operationDateError"
          [min]="minDate"
        />
        <mat-error #operationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="operationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #operationDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-autocomplete
        class="f-basis--12"
        i18n-label="@@app.policies.edit.change.risk.bookingModeId.label"
        label="Modo de contratación"
        displayField="description"
        keyField="bookingModeId"
        [selectableItems]="model.bookingModes"
        [formControlName]="model.C_BOOKING_MODE_ID"
        >
      </howden-autocomplete>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>

    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
