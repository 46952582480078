import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { dateRangeValidator } from 'src/app/shared/validators';

export class PolicyEditParalizationViewModel {
  private _form: FormGroup;

  readonly C_PARALIZATION_ID = 'paralizationId';
  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';

  constructor() {
    this._form = new FormGroup({
      [this.C_PARALIZATION_ID]: new FormControl(null, []),
      [this.C_START_DATE]: new FormControl(null, [Validators.required]),
      [this.C_END_DATE]: new FormControl(null, [Validators.required])
    }, [dateRangeValidator('startDate', 'endDate')]);
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get paralizationId(): string | null | undefined {
    return this.getControl(this.C_PARALIZATION_ID).value;
  }

  set paralizationId(value: string | null | undefined) {
    this.getControl(this.C_PARALIZATION_ID).setValue(value);
  }

  get startDate(): Date | null | undefined {
    return this.getControl(this.C_START_DATE).value;
  }

  set startDate(value: Date | null | undefined) {
    this.getControl(this.C_START_DATE).setValue(value);
  }

  get endDate(): Date | null | undefined {
    return this.getControl(this.C_END_DATE).value;
  }

  set endDate(value: Date | null | undefined) {
    this.getControl(this.C_END_DATE).setValue(value);
  }
}
