import { Observable } from 'rxjs';
import { IStatistic } from 'src/app/core/models/api/core';
import { IBonusBoard, IClaimDashboardPivotResponse, IClaimDashboardRequest, IClaimDashboardResponse, IInitialStateDashboardRequest, IInitialStateDashboardResponse, IReceiptDashboardRequest, IReceiptDashboardResponse } from 'src/app/core/models/api/dashboards';

export abstract class IDashboardService {
  /* Bonus board */
  abstract getBonusBoard(fleetId: string): Observable<Array<IBonusBoard>>;

  /* Receipts Dashboard */
  abstract getReceiptsDashboard(fleetId: string, request: IReceiptDashboardRequest): Observable<IReceiptDashboardResponse>;
  abstract exportReceiptsDashboard(fleetId: string, request: IReceiptDashboardRequest): Observable<Blob>;

  /* Claims Dashboard */
  abstract getClaimsDashboard(fleetId: string, request: IClaimDashboardRequest): Observable<IClaimDashboardResponse>;
  abstract getClaimsDashboardPivots(fleetId: string, request: IClaimDashboardRequest): Observable<IClaimDashboardPivotResponse>;

  /* Initial State Dashboard */
  abstract getInitialStateDashboard(fleetId: string, request: IInitialStateDashboardRequest): Observable<IInitialStateDashboardResponse>;
  abstract exportInitialStateDashboard(fleetId: string, request: IInitialStateDashboardRequest): Observable<Blob>;

  /* By Fleet */
  abstract getUnitCount(fleetId: string): Observable<number>;
  abstract getActivePolicyCount(fleetId: string): Observable<number>;
  abstract getRootPolicyCount(fleetId: string): Observable<number>;
  abstract getChildPolicyCount(fleetId: string): Observable<number>;
  abstract getPolicyCountWithoutNumber(fleetId: string): Observable<number>;
  abstract getUnitCountWithoutBonus(fleetId: string): Observable<number>;
  abstract getUnitCountWithoutPolicy(fleetId: string): Observable<number>;
  abstract getUnitCountWithAllPoliciesCancelled(fleetId: string): Observable<number>;
  abstract getUnitsByVehicleType(fleetId: string): Observable<Array<IStatistic>>;
  abstract getUnitsByPolicy(fleetId: string): Observable<Array<IStatistic>>;
  abstract getAverageBonusByVehicleType(fleetId: string): Observable<Array<IStatistic>>;
  abstract getPoliciesByPaymentType(fleetId: string): Observable<Array<IStatistic>>;

  /* General */
  abstract getGeneralUnitCount(): Observable<number>;
  abstract getGeneralUnitCountWithoutBonus(): Observable<number>;
  abstract getGeneralUnitCountWithoutPolicy(): Observable<number>;
  abstract getGeneralUnitCountWithAllPoliciesCancelled(): Observable<number>;
  abstract getGeneralRootPolicyCount(): Observable<number>;
  abstract getGeneralChildPolicyCount(): Observable<number>;
  abstract getGeneralPolicyCountWithoutNumber(): Observable<number>;
  abstract getGeneralUnitsByVehicleType(): Observable<Array<IStatistic>>;
  abstract getGeneralPoliciesByInsuranceCompany(): Observable<Array<IStatistic>>;
  abstract getGeneralPoliciesByOffice(): Observable<Array<IStatistic>>;
  abstract getGeneralPoliciesByPaymentType(): Observable<Array<IStatistic>>;
}
