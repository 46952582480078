import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IEndorsementLoaderFileItem } from 'src/app/core/models/api/fleets';
import { IMasterValue } from 'src/app/core/models/ida/masters';

export class EndorsementLoaderViewModel {
  private _form: FormGroup;
  private _endorsementStatuses: Array<IMasterValue> = [];
  private _endorsementTypes: Array<IMasterValue> = [];

  readonly C_ENDORSEMENT_TYPE_ID = 'endorsementTypeId';
  readonly C_ENDORSEMENT_STATUS_ID = 'endorsementStatusId';
  readonly C_ENDORSEMENT_ALIAS = 'endorsementAlias';
  readonly C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE = 'endorsementInsuranceCompanyReference';
  readonly C_ENDORSEMENT_DESCRIPTION = 'endorsementDescription';
  readonly C_ENDORSEMENT_OBSERVATIONS = 'endorsementObservations';
  readonly C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY = 'endorsementDateSentToInsuranceCompany';
  readonly C_ENDORSEMENT_REQUEST_DATE = 'endorsementRequestDate';
  readonly C_ENDORSEMENT_RECEPTION_DATE = 'endorsementReceptionDate';
  readonly C_ENDORSEMENT_EFFECT_DATE = 'endorsementEffectDate';
  readonly C_ENDORSEMENT_EXPIRATION_DATE = 'endorsementExpirationDate';
  readonly C_ENDORSEMENT_CANCELLATION_DATE = 'endorsementCancellationDate';
  readonly C_ENDORSEMENT_CREATES_RECEIPT = 'createsReceipt';
  readonly C_ENDORSEMENT_OLD_INFO = 'endorsementOldInformation';
  readonly C_ENDORSEMENT_NEW_INFO = 'endorsementNewInformation';
  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_ENDORSEMENT_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_STATUS_ID]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_ALIAS]: new FormControl(null, []),
      [this.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE]: new FormControl(null, []),
      [this.C_ENDORSEMENT_DESCRIPTION]: new FormControl(null, []),
      [this.C_ENDORSEMENT_OBSERVATIONS]: new FormControl(null, []),
      [this.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY]: new FormControl(null, []),
      [this.C_ENDORSEMENT_REQUEST_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_RECEPTION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_CANCELLATION_DATE]: new FormControl(null, []),
      [this.C_ENDORSEMENT_OLD_INFO]: new FormControl(null, []),
      [this.C_ENDORSEMENT_NEW_INFO]: new FormControl(null, []),
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }
  set form(value: FormGroup) {
    this._form = value;
  }

  get endorsementStatuses(): Array<IMasterValue> {
    return this._endorsementStatuses;
  }

  set endorsementStatuses(value: Array<IMasterValue>) {
    this._endorsementStatuses = value;
  }

  get endorsementTypes(): Array<IMasterValue> {
    return this._endorsementTypes;
  }

  set endorsementTypes(values: Array<IMasterValue>) {
    this._endorsementTypes = values;
  }

  /** ENDORSEMENT */

  get endorsementTypeId(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_TYPE_ID).value;
  }

  set endorsementTypeId(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_TYPE_ID).setValue(value);
  }

  get endorsementStatusId(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_STATUS_ID).value;
  }

  set endorsementStatusId(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_STATUS_ID).setValue(value);
  }

  get endorsementAlias(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_ALIAS).value;
  }

  set endorsementAlias(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_ALIAS).setValue(value);
  }

  get endorsementInsuranceCompanyReference(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE).value;
  }

  set endorsementInsuranceCompanyReference(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE).setValue(value);
  }

  get endorsementDescription(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_DESCRIPTION).value;
  }

  set endorsementDescription(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_DESCRIPTION).setValue(value);
  }

  get endorsementObservations(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_OBSERVATIONS).value;
  }

  set endorsementObservations(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_OBSERVATIONS).setValue(value);
  }

  get endorsementDateSentToInsuranceCompany(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY).value;
  }

  set endorsementDateSentToInsuranceCompany(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY).setValue(value);
  }

  get endorsementRequestDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_REQUEST_DATE).value;
  }

  set endorsementRequestDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_REQUEST_DATE).setValue(value);
  }

  get endorsementReceptionDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_RECEPTION_DATE).value;
  }

  set endorsementReceptionDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_RECEPTION_DATE).setValue(value);
  }

  get endorsementEffectDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_EFFECT_DATE).value;
  }

  set endorsementEffectDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_EFFECT_DATE).setValue(value);
  }

  get endorsementExpirationDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_EXPIRATION_DATE).value;
  }

  set endorsementExpirationDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_EXPIRATION_DATE).setValue(value);
  }

  get endorsementCancellationDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_CANCELLATION_DATE).value;
  }

  set endorsementCancellationDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_CANCELLATION_DATE).setValue(value);
  }

  get endorsementOldInformation(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_OLD_INFO).value;
  }

  set endorsementOldInformation(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_OLD_INFO).setValue(value);
  }

  get endorsementNewInformation(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_NEW_INFO).value;
  }

  set endorsementNewInformation(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_NEW_INFO).setValue(value);
  }

  updaterResults: IEndorsementLoaderFileItem[] = [];
  updaterResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.endorsements.loader.insuranceCompany.column:Compañía`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.endorsements.loader.policyNumber.column:Nº. Póliza`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.endorsements.loader.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
