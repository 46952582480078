import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { IUnitSummary } from 'src/app/core/models/api/units';
import { IUnitService } from 'src/app/core/services/api/units';
import { UnitSummaryPoliciesViewModel, UnitSummaryViewModel } from '../models';

@Component({
  selector: 'howden-unit-summary-dialog',
  templateUrl: './unit-summary-dialog.component.html',
  styleUrls: ['./unit-summary-dialog.component.scss']
})
export class UnitSummaryDialogComponent implements OnInit {
  model = new UnitSummaryViewModel();
  policies?: UnitSummaryPoliciesViewModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private router: Router,
    private dialogRef: MatDialogRef<UnitSummaryDialogComponent>,
    private unitsSrv: IUnitService
  ) {
  }

  ngOnInit(): void {
    const id = this.inputData.id as string;

    this.unitsSrv.getSummary(id).subscribe((data: IUnitSummary) => {
      this.model = new UnitSummaryViewModel(data);
      this.policies = data.policyRisks
        ? new UnitSummaryPoliciesViewModel(data.policyRisks)
        : undefined;
    });
  }

  onPolicyAction(event: IButtonActionData) {
    this.closeDialog(DialogResultTypes.OK);
    this.router.navigate(['policies/edit', event.row.policyId]);
  }

  onClose(): void {
    this.closeDialog(DialogResultTypes.OK);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
