import moment from 'moment';

export class FileUtils {
  static downloadFile(filename: string, data: Blob, ext?: string) {
    const fn = FileUtils.formatFilename(filename, ext);
    FileUtils.downloadRawFile(fn, data);
  }

  static downloadRawFile(filename: string, data: Blob) {
    const a = document.createElement('a');
    a.setAttribute('tabindex', '-1');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = filename;
    a.href = URL.createObjectURL(data);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  private static formatFilename(prefix: string, ext?: string | null | undefined): string {
    const datetime = moment(new Date()).format('YYYYMMDDHHmmss');
    const extension = ext ?? 'xlsx';
    const filename = `${prefix}_${datetime}.${extension}`;

    return filename;
  }
}
