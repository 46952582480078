<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">

    <div mat-dialog-title class="title-text" i18n="@@app.policies.edit.risk.title">Modificación de riesgos póliza</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <h4 i18n="@@app.policies.edit.risk.sections.risk">Datos riesgo</h4>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.risk.activationDate.label">Fecha de alta</mat-label>
          <input
            matInput
            [matDatepicker]="activationDatePicker"
            [formControlName]="model.C_ACTIVATION_DATE"
            [howdenControlError]="activationDateError"
            [min]="minDate"
          />
          <mat-error #activationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="activationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #activationDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.risk.deactivationDate.label">Fecha de baja</mat-label>
          <input
            matInput
            [matDatepicker]="deactivationDatePicker"
            [formControlName]="model.C_DEACTIVATION_DATE"
            [howdenControlError]="deactivationDateError"
            [min]="minDate"
          />
          <mat-error #deactivationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="deactivationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #deactivationDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.risk.deactivationNotificationDate.label">Fecha comunicación baja</mat-label>
          <input
            matInput
            [matDatepicker]="deactivationNotificationDatePicker"
            [formControlName]="model.C_DEACTIVATION_NOTIFICATION_DATE"
            [howdenControlError]="deactivationNotificationDateError"
            [min]="minDate"
          />
          <mat-error #deactivationNotificationDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="deactivationNotificationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #deactivationNotificationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.policies.edit.risk.bookingModeId.label">Modo de contratación</mat-label>
          <input
            matInput
            [formControlName]="model.C_BOOKING_MODE_ID"
            [matAutocomplete]="bookingModeSelect"
            [howdenControlError]="bookingModeIdError"
          />
          <mat-autocomplete
            #bookingModeSelect="matAutocomplete"
            [displayWith]="getBookingModeDescription.bind(this)"
          >
            @for (item of model.filteredBookingModes | async; track item.bookingModeId) {
              <mat-option [value]="item.bookingModeId">
                {{ item.description }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #bookingModeIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <h4 i18n="@@app.policies.edit.risk.sections.insured">Datos asegurado</h4>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.risk.insuredId.label">Asegurado</mat-label>
          <input
            matInput
            [formControlName]="model.C_INSURED_ID"
            [matAutocomplete]="insuredSelect"
            [howdenControlError]="bookingModeIdError"
          />
          <mat-autocomplete
            #insuredSelect="matAutocomplete"
            [displayWith]="getFleetCompanyName.bind(this)"
            >
            @for (item of model.filteredFleetCompanies | async; track item.companyId) {
              <mat-option [value]="item.companyId">
                {{ item.clientName }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #bookingModeIdError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.policies.edit.risk.insuredInclusionDate.label">Fecha inclusión</mat-label>
          <input
            matInput
            [matDatepicker]="insuredInclusionDatePicker"
            [formControlName]="model.C_INSURED_INCLUSION_DATE"
            [howdenControlError]="insuredInclusionDateError"
            [min]="minDate"
          />
          <mat-error #insuredInclusionDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="insuredInclusionDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #insuredInclusionDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="f-basis--5">
          <mat-label i18n="@@app.policies.edit.risk.insuredExclusionDate.label">Fecha exclusión</mat-label>
          <input
            matInput
            [matDatepicker]="insuredExclusionDatePicker"
            [formControlName]="model.C_INSURED_EXCLUSION_DATE"
            [howdenControlError]="insuredExclusionDateError"
            [min]="minDate"
          />
          <mat-error #insuredExclusionDateError></mat-error>
          <mat-datepicker-toggle matSuffix [for]="insuredExclusionDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #insuredExclusionDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <h4 i18n="@@app.policies.edit.risk.sections.creditor">Datos beneficiario</h4>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.policies.edit.risk.creditorName.label">Nombre</mat-label>
          <input matInput [formControlName]="model.C_CREDITOR_NAME" [howdenControlError]="creditorNameError" maxlength="100" />
          <mat-error #creditorNameError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.policies.edit.risk.creditorFiscalCode.label">NIF/CIF</mat-label>
          <input matInput [formControlName]="model.C_CREDITOR_FISCAL_CODE" [howdenControlError]="creditorFiscalCode" maxlength="10" />
          <mat-error #creditorFiscalCode></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--5">
          <mat-label i18n="@@app.policies.edit.risk.creditorEmail.label">Dirección de correo electrónico</mat-label>
          <input matInput [formControlName]="model.C_CREDITOR_EMAIL" [howdenControlError]="creditorEmail" maxlength="60" />
          <mat-error #creditorEmail></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label i18n="@@app.policies.edit.risk.creditorComments.label">Observaciones</mat-label>
          <textarea
            matInput
            [formControlName]="model.C_CREDITOR_COMMENTS"
            [howdenControlError]="commentsError"
            cdkTextareaAutosize
            cdkAutosizeMinRows="6"
            cdkAutosizeMaxRows="15"
          >
          </textarea>
          <mat-error #commentsError></mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
