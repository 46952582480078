import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IBookingModeSearchRequest, IBookingModeSearchResult, ICreateBookingMode } from 'src/app/core/models/api/booking-modes';
import { IBookingModeService } from './booking-mode.contract';

@Injectable({
  providedIn: 'root'
})
export class BookingModeService implements IBookingModeService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  search(request: IBookingModeSearchRequest): Observable<IPageOf<IBookingModeSearchResult>> {
    return this.httpClient.post<IPageOf<IBookingModeSearchResult>>(APP_CONSTANTS.API_NET_FLOTAS, '/booking-modes/search', request);
  }

  create(request: ICreateBookingMode): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/booking-modes`, request);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/booking-modes/${id}`);
  }
}
