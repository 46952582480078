import { IGuessIndividualBonus } from 'src/app/core/models/api/policies';
import { ICreateUnit, ICreateUnitPolicyAssignment } from 'src/app/core/models/api/units';
import { IUnit } from 'src/app/core/models/api/units/unit';
import { UnitCreateNewViewModel } from '../models';

export abstract class UnitCreateNewMapper {
  static mapForGuessBonus(source: UnitCreateNewViewModel): IGuessIndividualBonus {
    return {
      vehicleTypeId: source.unitVehicleTypeId,
      vehicleUsageId: source.unitVehicleUsageId,
      movementAreaId: source.unitMovementArea,
      circulationAreaId: source.unitCirculationAreaId,
      holderId: source.assignmentHolderId,
      insuredId: source.assignmentInsuredId,
      bookingModeId: source.assignmentBookingModeId,
      effectDate: source.assignmentEffectDate,
      expirationDate: source.assignmentExpirationDate
    } as IGuessIndividualBonus;
  }

  static mapForUpdate(fleetId: string, source: UnitCreateNewViewModel): ICreateUnit {
    const createUnit = {
      fleetId: fleetId,

      unit: {
        bonusGroup: source.unitBonusGroup,
        observations: source.unitObservations,
        vehicle: {
          carNumber: source.unitCarNumber,
          plateNumber: source.unitPlateNumber,
          registrationDate: source.unitRegistrationDate,
          vehicleTypeId: source.unitVehicleTypeId,
          vehicleUsageId: source.unitVehicleUsageId,
          movementArea: source.unitMovementArea,
          brand: source.unitBrand,
          model: source.unitModel,
          version: source.unitVersion,
          maxAdmittedWeight: source.unitMaxAdmittedWeight,
          value: source.unitValue,
          valueExtras: source.unitValueExtras,
          busNumber: source.unitBusNumber,
          bodyworkNumber: source.unitBodyworkNumber,
          bodywork: source.unitBodywork,
          busType: source.unitBusType,
          entertaimentBus: source.unitEntertaimentBus,
          seats: source.unitSeats,
          seatNumber: source.unitSeatNumber,
          rentingCompanyFiscalCode: source.unitRentingCompanyFiscalCode,
          rentingStartDate: source.unitRentingStartDate,
          rentingExpirationDate: source.unitRentingExpirationDate,
          power: source.unitPower,
          division: source.unitDivision,
          zone: source.unitZone,
          contract: source.unitContract,
          owner: source.unitOwner,
          lesseeId: source.unitLesseeId === '' ? undefined : source.unitLesseeId,
          userId: source.unitUserId === '' ? undefined : source.unitUserId,
          userZone: source.unitUserZone,
          age: source.unitAge,
          configuration: source.unitConfiguration,
          length: source.unitLength,
          kilometers: source.unitKilometers,
          category: source.unitCategory,
          class: source.unitClass,
          euroClass: source.unitEuroClass,
          workshop: source.unitWorkshop,
          chassisNumber: source.unitChassisNumber,
          td: source.unitTd,
          wifi: source.unitWifi,
          wifiName: source.unitWifiName,
          contractedMaintenance: source.unitContractedMaintenance,
          schoolBus: source.unitSchoolBus,
          safeBelts: source.unitSafeBelts,
          individualEntertaiment: source.unitIndividualEntertaiment,
          platform: source.unitPlatform,
          pmrsr: source.unitPmrsr,
          cameras: source.unitCameras,
          manufacturingMonth: source.unitManufacturingMonth,
          manufacturingYear: source.unitManufacturingYear,
          concession: source.unitConcession,
          goodsTypeId: source.unitGoodsTypeId === '' ? undefined : source.unitGoodsTypeId,
          registrationClassification: source.unitRegistrationClassification ? parseInt(source.unitRegistrationClassification) : null,
          classificationCodeCL: source.unitClassificationCodeCL,
          functionalTypeId: source.unitFunctionalTypeId === '' ? undefined : source.unitFunctionalTypeId,
          circulationAreaId: source.unitCirculationAreaId === '' ? undefined : source.unitCirculationAreaId
        }
      } as IUnit
    } as ICreateUnit;

    if (typeof source.assignmentTargetPolicyId !== 'undefined' && source.assignmentTargetPolicyId !== null) {
      createUnit.assignment = {
        targetPolicyClass: source.assignmentTargetPolicyClass,
        targetPolicyId: source.assignmentTargetPolicyId,
        policyNumber: source.assignmentPolicyNumber,
        holderId: source.assignmentHolderId,
        insuredId: source.assignmentInsuredId,
        firstEffectDate: source.assignmentFirstEffectDate,
        effectDate: source.assignmentEffectDate,
        expirationDate: source.assignmentExpirationDate,
        mediationCodeId: source.assignmentMediationCodeId,
        bookingModeId: source.assignmentBookingModeId,
        franchiseDP: source.assignmentFranchiseDP,
        franchiseTR: source.assignmentFranchiseTR,
        franchiseI: source.assignmentFranchiseI,
        franchiseR: source.assignmentFranchiseR,
        billingGroupPattern: source.assignmentBillingGroupPattern,
        bonusEffectDate: source.assignmentBonusEffectDate,
        bonusExpirationDate: source.assignmentBonusExpirationDate,
        netBonus: source.assignmentNetBonus,
        totalBonus: source.assignmentTotalBonus,
        commissionRate: source.assignmentCommissionRate,
        creditorName: source.assignmentCreditorName,
        creditorFiscalCode: source.assignmentCreditorFiscalCode,
        creditorEmail: source.assignmentCreditorEmail,
        creditorObservations: source.assignmentCreditorObservations
      } as ICreateUnitPolicyAssignment;
    }

    return createUnit;
  }
}
