import { Component, Input } from '@angular/core';
import { PolicyBillingGroupSegmentType } from 'src/app/core/enums';
import { IBonusAmount, IPolicyBillingGroupResult, IPolicyBillingGroupSegment } from 'src/app/core/models/api/policies';

@Component({
  selector: 'howden-policy-edit-billing-group-segment',
  templateUrl: './policy-edit-billing-group-segment.component.html',
  styleUrls: ['./policy-edit-billing-group-segment.component.scss']
})
export class PolicyEditBillingGroupSegmentComponent {
  @Input() group: IPolicyBillingGroupResult | null = null;
  @Input() segment: IPolicyBillingGroupSegment | null = null;

  get isDefaultSegment(): boolean {
    return typeof this.segment !== 'undefined'
      && this.segment !== null
      && this.segment.segmentType === PolicyBillingGroupSegmentType.Default;
  }

  get receipts(): Array<IBonusAmount> {
    if (typeof this.segment !== 'undefined'
      && this.segment !== null
      && this.segment.subtotals
      && this.segment.subtotals.length > 0
      && this.segment.subtotals[0].receipts) {
      return this.segment.subtotals[0].receipts;
    }

    return [];
  }

  get receiptCount(): number {
    if (typeof this.segment !== 'undefined'
      && this.segment !== null
      && this.segment.subtotals
      && this.segment.subtotals.length > 0
      && this.segment.subtotals[0].receipts) {
      return this.segment.subtotals[0].receipts.length;
    }

    return 0;
  }
}
