import { IContractSummary } from 'src/app/core/models/api/policies';
import { PolicyEditContractViewModel } from '../models';

export abstract class PolicyEditContractMapper {
  static mapForEdit(source: IContractSummary, target: PolicyEditContractViewModel) {
    target.contractNumber = source.contractNumber;
    target.client = source.client;
    target.executive = source.executive;
    target.collectionLevel = source.collectionLevel;
    target.collectionManager = source.collectionManager;
    target.policies = source.policies?.filter(p => p !== null) ?? [];
  }
}
