<div class="f-basis--6">
  <div class="flex f-jc--end f-ai--center margin-top-10 margin-bottom-20">
    <button
      type="button"
      mat-mini-fab
      color="primary"
      matTooltip="Nuevo impuesto"
      i18n-matTooltip="@@app.fleets.edit.taxes.actions.create"
      (click)="onNewTax()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
<div>
  <howden-table
    class="small-font"
    keyField="taxId"
    sortable="local"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
