<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@app.policies.renewer.rootPolicyId.label">Póliza madre</mat-label>
        <input
          #rootPolicyInput
          matInput
          [formControlName]="model.C_ROOT_POLICY_ID"
          [matAutocomplete]="rootPolicySelect"
          [howdenControlError]="rootPolicyIdError"
        />
        <mat-autocomplete
          #rootPolicySelect="matAutocomplete"
          [displayWith]="getRootPolicyDescription.bind(this)"
          >
          @for (item of model.filteredRootPolicies | async; track item.policyId) {
            <mat-option [value]="item.policyId">
              {{ item.policyNumber }}-{{ item.riskDescription }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #rootPolicyIdError></mat-error>
      </mat-form-field>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha afecto actual"
        i18n-labelText="@@app.policies.renewer.currentEffectDate.label"
        value="{{model.currentEffectDate | date}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha vencimiento actual"
        i18n-labelText="@@app.policies.renewer.currentExpirationDate.label"
        value="{{model.currentExpirationDate | date}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.renewer.effectDate.label">Fecha efecto</mat-label>
        <input
          matInput
          [matDatepicker]="effectDatePicker"
          [formControlName]="model.C_EFFECT_DATE"
          [howdenControlError]="effectDateError"
          [min]="minDate"
        />
        <mat-error #effectDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #effectDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.renewer.expirationDate.label">Fecha vencimiento</mat-label>
        <input
          matInput
          [matDatepicker]="expirationDatePicker"
          [formControlName]="model.C_EXPIRATION_DATE"
          [howdenControlError]="expirationDateError"
          [min]="minDate"
        />
        <mat-error #expirationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expirationDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.renewer.duration.label">Duración</mat-label>
        <mat-select [formControlName]="model.C_DURATION" [howdenControlError]="durationError">
          @for (item of model.policyDurations | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #durationError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.renewer.paymentTypeId.label">Forma de pago</mat-label>
        <mat-select [formControlName]="model.C_PAYMENT_TYPE_ID" [howdenControlError]="paymentTypeIdError">
          @for (item of model.paymentTypes | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #paymentTypeIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.policies.renewer.collectionManagerId.label">Gestor de cobro</mat-label>
        <input
          matInput
          [formControlName]="model.C_COLLECTION_MANAGER_ID"
          [matAutocomplete]="collectionManagerSelect"
          [howdenControlError]="collectionManagerIdError"
        />
        <mat-autocomplete
          #collectionManagerSelect="matAutocomplete"
          [displayWith]="getCollectionManagerName.bind(this)"
        >
          @for (item of model.filteredCollectionManagers | async; track item.collectionManagerId) {
            <mat-option [value]="item.collectionManagerId">
              {{ item.alias }}-{{ item.name }}
            </mat-option>
          }
        </mat-autocomplete>
        <mat-error #collectionManagerIdError></mat-error>
      </mat-form-field>

      <howden-decimal
        class="f-basis--4"
        [formControlName]="model.C_COMMISSION_RATE"
        i18n-label="@@app.policies.renewer.commissionRate.label"
        label="Comisión"
      ></howden-decimal>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </div>
  </form>
</div>
