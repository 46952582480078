import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IInsuranceDays } from 'src/app/core/models/api/insurance-days';
import { IInsuranceDaysService } from './insurance-days.contract';

@Injectable({
  providedIn: 'root'
})
export class InsuranceDaysService implements IInsuranceDaysService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(): Observable<IInsuranceDays[]> {
    return this.httpClient.get<Array<IInsuranceDays>>(APP_CONSTANTS.API_NET_FLOTAS, 'insurance-days');
  }

  update(insuranceDays: IInsuranceDays): Observable<void> {
    return this.httpClient.put<void>(APP_CONSTANTS.API_NET_FLOTAS, 'insurance-days', insuranceDays);
  }
}
