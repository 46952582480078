<div class="howden-dialog-content">
  <span mat-dialog-title class="title-text">{{policy.insuranceCompany}} - {{policy.policyNumber}} - {{policy.description | slice:0:50}}</span>
  <span class="subtitle-text">{{policy.policyStatus}} {{policy.cancellationDate | date}} {{policy.cancellationReason}}</span>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Tomador"
        i18n-labelText="@@app.policies.summary.holder.label"
        value="{{policy.holder}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Oficina"
        i18n-labelText="@@app.policies.summary.office.label"
        value="{{policy.office}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Gestor de cobro"
        i18n-labelText="@@app.policies.summary.collectionManager.label"
        value="{{policy.collectionManager}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Aseguradora"
        i18n-labelText="@@app.policies.summary.insuranceCompany.label"
        value="{{policy.insuranceCompany}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Ramo"
        i18n-labelText="@@app.policies.summary.industry.label"
        value="{{policy.industry}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Producto"
        i18n-labelText="@@app.policies.summary.product.label"
        value="{{policy.product}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha primer efecto"
        i18n-labelText="@@app.policies.summary.firstEffectDate.label"
        value="{{policy.firstEffectDate | date}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha efecto"
        i18n-labelText="@@app.policies.summary.effectDate.label"
        value="{{policy.effectDate | date}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Fecha vencimiento"
        i18n-labelText="@@app.policies.summary.expirationDate.label"
        value="{{policy.expirationDate | date}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Forma de pago"
        i18n-labelText="@@app.policies.summary.paymentType.label"
        value="{{policy.paymentType}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Código de mediación"
        i18n-labelText="@@app.policies.summary.mediationCode.label"
        value="{{policy.mediationCode}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Duración"
        i18n-labelText="@@app.policies.summary.duration.label"
        value="{{policy.duration}}"
      ></howden-label-value>
    </div>

    <div class="flex f-gap--10px margin-top-10">
      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Nº. Expediente"
        i18n-labelText="@@app.policies.summary.dossier.label"
        value="{{policy.dossier}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Modelo facturación"
        i18n-labelText="@@app.policies.summary.billingModel.label"
        value="{{policy.billingModel}}"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--4 f-as-start"
        labelText="Recibos en"
        i18n-labelText="@@app.policies.summary.receiptTarget.label"
        value="{{policy.receiptTarget}}"
      ></howden-label-value>
    </div>

    @if (policy.replacesPolicy || policy.replacedByPolicy) {
      <div class="flex f-gap--10px margin-top-10">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Reemplaza a"
          i18n-labelText="@@app.policies.summary.replacesPolicy.label"
          value="{{policy.replacesPolicy}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Reemplazada por"
          i18n-labelText="@@app.policies.summary.replacedByPolicy.label"
          value="{{policy.replacedByPolicy}}"
        ></howden-label-value>
        <div class="f-basis--4"></div>
      </div>
    }

    @if (policy.hasRootPolicy) {
      <div class="flex f-gap--10px margin-top-10">
        <h4 i18n="@@app.policies.summary.sections.root.policy">Póliza madre</h4>
      </div>

      <div class="flex f-gap--10px">
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Nº. póliza madre"
          i18n-labelText="@@app.policies.summary.rootPolicyNumber.label"
          value="{{policy.rootPolicyNumber}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Denominación"
          i18n-labelText="@@app.policies.summary.rootPolicyName.label"
          value="{{policy.rootPolicyName}}"
        ></howden-label-value>
        <howden-label-value
          class="f-basis--4 f-as-start"
          labelText="Estado"
          i18n-labelText="@@app.policies.summary.rootPolicyStatus.label"
          value="{{policy.rootPolicyStatus}}"
        ></howden-label-value>
      </div>
    }

    @if (figures && figures.isNotEmpty) {
      <div class="flex f-fd--column">
        <h4 i18n="@@app.policies.summary.sections.figures">Figuras</h4>
        <howden-table
          class="small-font"
          keyField="figureId"
          paginable="none"
          sortable="local"
          filterable="global-local"
          [cols]="figures.columns"
          [data]="figures.data"
        >
        </howden-table>
      </div>
    }

    @if (risks && risks.isNotEmpty) {
      <div class="flex f-fd--column">
        <h4 i18n="@@app.policies.summary.sections.risks">Riesgos</h4>
        <howden-table
          class="small-font"
          keyField="policyRiskId"
          paginable="local"
          sortable="local"
          filterable="global-local"
          [pageSize]="10"
          [cols]="risks.columns"
          [data]="risks.data"
          [buttons]="risks.buttons"
          (buttonClicked)="onRiskAction($event)"
        >
        </howden-table>
      </div>
    }

    <div class="flex margin-top-10">
      <h4 i18n="@@app.policies.summary.sections.audit">Auditoría</h4>
    </div>

    <div class="flex f-gap--10px">
      <howden-label-value
        class="f-basis--6 f-as-start"
        labelText="Creado por"
        i18n-labelText="@@app.policies.summary.createdBy.label"
        value="{{policy.createdBy}} ({{policy.createdAt}})"
      ></howden-label-value>

      <howden-label-value
        class="f-basis--6 f-as-start"
        labelText="Modificado por"
        i18n-labelText="@@app.policies.summary.modifiedBy.label"
        value="{{policy.modifiedBy}} ({{policy.modifiedAt}})"
      ></howden-label-value>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button color="success" (click)="onClose()">
      <span i18n="@@app.core.actions.close">Cerrar</span>
    </button>
  </mat-dialog-actions>
</div>
