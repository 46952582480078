import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';

@Component({
  selector: 'howden-policy-edit-claims',
  templateUrl: './policy-edit-claims.component.html',
  styleUrls: ['./policy-edit-claims.component.scss']
})
export class PolicyEditClaimsComponent implements OnChanges {
  claims: Array<IDetailedClaim> = [];

  @Input() policyId = '';

  constructor(private uiBlockerSrv: IUIBlockerService, private policySrv: IPolicyService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.uiBlockerSrv.block();
    this.policySrv.getClaims(this.policyId).subscribe({
      next: (data: Array<IDetailedClaim>) => this.claims = data,
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
