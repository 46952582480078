import { ICertificateGenerationRequest } from 'src/app/core/models/api/certificates';
import { CertificatesViewModel } from '../models';

export abstract class CertificatesMapper {
  static map(source: CertificatesViewModel): ICertificateGenerationRequest {
    return {
      certificateTemplateId: source.certificateTemplateId,
      headerText: source.header,
      bodyText: source.body,
      footerText: source.footer,
      expeditionCity: source.expeditionCity
    } as ICertificateGenerationRequest;
  }
}
