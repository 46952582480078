import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function forbiddenNamesValidator(values: () => any[], property: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentValue = control.value;
    const index = typeof currentValue === 'string' && currentValue !== null && currentValue !== ''
      ? values().findIndex(value => value[property] === currentValue)
      : 0;
    return index < 0 ? { forbiddenNames: { value: currentValue } } : null;
  };
}
