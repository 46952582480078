import { IPolicyBillingGroupRule } from 'src/app/core/models/api/policies';
import { PolicyEditBillingGroupRuleViewModel } from '../models';

export abstract class PolicyEditBillinGroupRuleMapper {
  static mapForEdit(source: IPolicyBillingGroupRule, target: PolicyEditBillingGroupRuleViewModel) {
    target.ruleId = source.ruleId;
    target.type = source.type;
    target.valueId = source.valueId;
    target.directValue = source.directValue;
  }

  static mapForUpdate(source: PolicyEditBillingGroupRuleViewModel): IPolicyBillingGroupRule {
    return {
      ruleId: source.ruleId,
      type: source.type,
      valueId: source.valueId,
      directValue: source.directValue
    } as IPolicyBillingGroupRule;
  }
}
