import { Component, Input } from '@angular/core';

@Component({
  selector: 'howden-dashboard-mini-card',
  templateUrl: './dashboard-mini-card.component.html',
  styleUrls: ['./dashboard-mini-card.component.scss']
})
export class DashboardMiniCardComponent {
  @Input() icon: string | null = '';
  @Input() title: string | null = '';
  @Input() value: number | string | null = null;
  @Input() color: string | null = '';
  @Input() isIncrease = false;
  @Input() isCurrency = false;
  @Input() duration: string | null = '';
  @Input() percentValue: number | null = 0;
}
