import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { ICirculationArea, ICirculationAreaSearchRequest } from 'src/app/core/models/api/units';
import { ICirculationAreaService } from './circulation-area.contract';

@Injectable({
  providedIn: 'root'
})
export class CirculationAreaService implements ICirculationAreaService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(id: string): Observable<ICirculationArea> {
    return this.httpClient.get<ICirculationArea>(APP_CONSTANTS.API_NET_FLOTAS, `/circulation-areas/${id}`);
  }

  search(request: ICirculationAreaSearchRequest): Observable<IPageOf<ICirculationArea>> {
    return this.httpClient.post<IPageOf<ICirculationArea>>(APP_CONSTANTS.API_NET_FLOTAS, '/circulation-areas/search', request);
  }

  upsert(request: ICirculationArea): Observable<void> {
    return this.httpClient.post<void>(APP_CONSTANTS.API_NET_FLOTAS, `/circulation-areas`, request);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(APP_CONSTANTS.API_NET_FLOTAS, `/circulation-areas/${id}`);
  }
}
