import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IPolicyRiskSummary } from 'src/app/core/models/api/policies';

export class UnitEditPoliciesViewModel {
  private _allowRiskCancellation = false;

  get allowRiskCancellation(): boolean {
    return this._allowRiskCancellation;
  }

  set allowRiskCancellation(value: boolean) {
    this._allowRiskCancellation = value;
  }

  data: Array<IPolicyRiskSummary> = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.insuranceCompany.column:Aseguradora`,
      canSort: true
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.policyNumber.column:Nº. Póliza`,
      canSort: true
    },
    {
      fieldName: 'insured',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.insuredName.column:Asegurado`,
      canSort: true
    },
    {
      fieldName: 'industry',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.industry.column:Ramo`,
      canSort: true
    },
    {
      fieldName: 'product',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.product.column:Producto`,
      canSort: true
    },
    {
      fieldName: 'policyState',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.policyState.column:Estado póliza`,
      canSort: true
    },
    {
      fieldName: 'activationDate',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.creationDate.column:F. Alta riesgo`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'deactivationDate',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.cancellationDate.column:F. Baja riesgo`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'deactivationNotificationDate',
      fieldHeader: $localize`:@@app.unit.edit.policies.risks.cancellationNotificationDate.column:F. Com. baja riesgo`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    tooltip: $localize`:@@app.units.edit.policies.actions.goto.policy:Ir a póliza`
  }, {
    name: 'info',
    icon: 'info',
    tooltip: $localize`:@@app.units.edit.policies.actions.info:Información`
  }];

  hideSelection(row: any): boolean {
    return !!row.deactivationDate;
  }
}
