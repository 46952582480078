import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { PolicyNumberValidator } from 'src/app/shared/validators';
import { PolicyEditNumberUpdateMapper } from '../mappers';
import { PolicyEditNumberUpdateViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-number-update-dialog',
  templateUrl: './policy-edit-number-update-dialog.component.html',
  styleUrls: ['./policy-edit-number-update-dialog.component.scss']
})
export class PolicyEditNumberUpdateDialogComponent implements OnInit {
  model: PolicyEditNumberUpdateViewModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditNumberUpdateDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
    const validator = new PolicyNumberValidator(this.policySrv, this.inputData.insuranceCompanyId);

    this.model = new PolicyEditNumberUpdateViewModel(validator);
  }

  ngOnInit(): void {
    this.model.insuranceCompanyId = this.inputData.insuranceCompanyId;
    this.model.policyNumber = this.inputData.policyNumber;
  }

  onAccept() {
    const id = this.inputData.id;
    const request = PolicyEditNumberUpdateMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.updatePolicyNumber(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
