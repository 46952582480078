import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, FileUploadComponent, IDialogResult } from '@howdeniberia/core-front';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FormGroupUtils } from 'src/app/shared/utils';
import { PolicyEditDocumentViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-document-dialog',
  templateUrl: './policy-edit-document-dialog.component.html',
  styleUrls: ['./policy-edit-document-dialog.component.scss']
})
export class PolicyEditDocumentDialogComponent {
  model = new PolicyEditDocumentViewModel();

  @ViewChild('file') fileRef!: FileUploadComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditDocumentDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private policySrv: IPolicyService
  ) {
  }

  onAccept() {
    const id = this.inputData.policyId as string;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.policySrv.saveDocument(id, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef.file
    });
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
