<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field>
        <mat-label i18n="@@app.policies.search.policyNumber.label">Nº. Póliza</mat-label>
        <input #policyNumberInput matInput [formControlName]="model.C_POLICY_NUMBER" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.policies.search.riskDescription.label">Denominación</mat-label>
        <input matInput [formControlName]="model.C_RISK_DESCRIPTION" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.policies.search.holder.label">Tomador</mat-label>
        <input matInput [formControlName]="model.C_HOLDER" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.policies.search.classId.label">Tipo</mat-label>
        <mat-select [formControlName]="model.C_CLASS_ID">
          <mat-option></mat-option>
          @for (item of model.policyClasses | howdenArraySort: 'description'; track item.policyClassId) {
            <mat-option [value]="item.policyClassId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.policies.search.statusId.label">Estado</mat-label>
        <mat-select [formControlName]="model.C_STATUS_ID">
          <mat-option></mat-option>
          @for (item of model.policyStatuses | howdenArraySort: 'description'; track item.erpId) {
            <mat-option [value]="item.erpId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div>
    <howden-table
      class="small-font"
      keyField="policyId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (filtersChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
      >
    </howden-table>
  </div>
</div>
