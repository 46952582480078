import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { PolicyClass } from 'src/app/core/enums';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { ICirculationArea, IFunctionalType, IGoodsType, IMovementArea } from 'src/app/core/models/api/units';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IMediationCode } from 'src/app/core/models/ida/providers';
import { Limits } from 'src/app/shared/utils';
import { BillingGroupUtils } from 'src/app/shared/utils/billing-group-utils';
import { PlateNumberValidator, forbiddenNamesValidator } from 'src/app/shared/validators';

export class UnitCreateNewViewModel {
  private _form: FormGroup;
  private _isBookingModeRequired = false;
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _vehicleTypes: Array<IMasterValue> = [];
  private _vehicleUsages: Array<IMasterValue> = [];
  private _movementAreas: Array<IMovementArea> = [];
  private _functionalTypes: Array<IFunctionalType> = [];
  private _circulationAreas: Array<ICirculationArea> = [];
  private _goodsTypes: Array<IGoodsType> = [];
  private _mediationCodes: Array<IMediationCode> = [];
  private _bookingModes: Array<IBookingModeSearchResult> = [];

  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;
  private _filteredMediationCodes: Observable<Array<IMediationCode>>;

  readonly C_UNIT_BONUS_GROUP = 'bonusGroup';
  readonly C_UNIT_OBSERVATIONS = 'comments';
  readonly C_UNIT_CAR_NUMBER = 'carNumber';
  readonly C_UNIT_PLATE_NUMBER = 'plateNumber';
  readonly C_UNIT_REGISTRATION_DATE = 'registrationDate';
  readonly C_UNIT_VEHICLE_TYPE_ID = 'vehicleTypeId';
  readonly C_UNIT_VEHICLE_USAGE_ID = 'vehicleUsageId';
  readonly C_UNIT_MOVEMENT_AREA = 'movementArea';
  readonly C_UNIT_BRAND = 'brand';
  readonly C_UNIT_MODEL = 'model';
  readonly C_UNIT_VERSION = 'version';
  readonly C_UNIT_MAX_ADDMITTED_WEIGHT = 'maxAdmittedWeight';
  readonly C_UNIT_VALUE = 'value';
  readonly C_UNIT_VALUE_EXTRAS = 'valueExtras';
  readonly C_UNIT_BUS_NUMBER = 'busNumber';
  readonly C_UNIT_BODY_WORK_NUMBER = 'bodyworkNumber';
  readonly C_UNIT_BODY_WORK = 'bodywork';
  readonly C_UNIT_BUS_TYPE = 'busType';
  readonly C_UNIT_ENTERTAIMENT_BUS = 'entertaimentBus';
  readonly C_UNIT_SEATS = 'seats';
  readonly C_UNIT_SEAT_NUMBER = 'seatNumber';
  readonly C_UNIT_RENTING_COMPANY_FISCAL_CODE = 'rentingCompanyFiscalCode';
  readonly C_UNIT_RENTING_START_DATE = 'rentingStartDate';
  readonly C_UNIT_RENTING_EXPIRATION_DATE = 'rentingExpirationDate';
  readonly C_UNIT_POWER = 'power';
  readonly C_UNIT_DIVISION = 'division';
  readonly C_UNIT_ZONE = 'zone';
  readonly C_UNIT_CONTRACT = 'contract';
  readonly C_UNIT_OWNER = 'owner';
  readonly C_UNIT_LESSEE_ID = 'lesseeId';
  readonly C_UNIT_USER_ID = 'userId';
  readonly C_UNIT_USER_ZONE = 'userZone';
  readonly C_UNIT_AGE = 'age';
  readonly C_UNIT_CONFIGURATION = 'configuration';
  readonly C_UNIT_LENGTH = 'length';
  readonly C_UNIT_KILOMETERS = 'kilometers';
  readonly C_UNIT_CATEGORY = 'category';
  readonly C_UNIT_CLASS = 'class';
  readonly C_UNIT_EUROCLASS = 'euroClass';
  readonly C_UNIT_WORKSHOP = 'workshop';
  readonly C_UNIT_CHASIS_NUMBER = 'chasisNumber';
  readonly C_UNIT_TD = 'td';
  readonly C_UNIT_WIFI = 'wifi';
  readonly C_UNIT_WIFI_NAME = 'wifiName';
  readonly C_UNIT_CONTRACTED_MAINTENANCE = 'contractedMaintenance';
  readonly C_UNIT_SCHOOL_BUS = 'schoolBus';
  readonly C_UNIT_SAFE_BELTS = 'SafeBelts';
  readonly C_UNIT_INDIVIDUAL_ENTERTAIMENT = 'individualEntertaiment';
  readonly C_UNIT_PLATFORM = 'platform';
  readonly C_UNIT_PMRSR = 'pmrsr';
  readonly C_UNIT_CAMERAS = 'cameras';
  readonly C_UNIT_MANUFACTURING_MONTH = 'manufacturingMonth';
  readonly C_UNIT_MANUFACTURING_YEAR = 'manufacturingYear';
  readonly C_UNIT_CONCESSION = 'concession';
  readonly C_UNIT_GOODS_TYPE_ID = 'goodsTypeId';
  readonly C_UNIT_REGISTRATION_CLASSIFICATION = 'registrationClassification';
  readonly C_UNIT_CLASSIFICATION_CODE_CL = 'classificationCodeCL';
  readonly C_UNIT_FUNCTIONAL_TYPE_ID = 'functionalTypeId';
  readonly C_UNIT_CIRCULATION_AREA_ID = 'circulationAreaId';

  readonly C_ASSIGNMENT_TARGET_POLICY_CLASS = 'assignmentTargetPolicyClass';
  readonly C_ASSIGNMENT_TARGET_POLICY_ID = 'assignmentTargetPolicyId';

  readonly C_ASSIGNMENT_POLICY_NUMBER = 'assignmentPolicyNumber';
  readonly C_ASSIGNMENT_HOLDER_ID = 'assignmentHolderId';
  readonly C_ASSIGNMENT_INSURED_ID = 'assignmentInsuredId';

  readonly C_ASSIGNMENT_FIRST_EFFECT_DATE = 'assignmentFirstEffectDate';
  readonly C_ASSIGNMENT_EFFECT_DATE = 'assignmentEffectDate';
  readonly C_ASSIGNMENT_EXPIRATION_DATE = 'assignmentExpirationDate';

  readonly C_ASSIGNMENT_MEDIATION_CODE_ID = 'assignmentMediationCodeId';
  readonly C_ASSIGNMENT_BOOKING_MODE_ID = 'assignmentBookingModeId';
  readonly C_ASSIGNMENT_FRANCHISE_DP = 'assignmentFranchiseDP';
  readonly C_ASSIGNMENT_FRANCHISE_I = 'assignmentFranchiseI';
  readonly C_ASSIGNMENT_FRANCHISE_R = 'assignmentFranchiseR';
  readonly C_ASSIGNMENT_FRANCHISE_TR = 'assignmentFranchiseTR';

  readonly C_ASSIGNMENT_BILLING_GROUP_PATTERN = 'assignmentBillingGroupPattern';
  readonly C_ASSIGNMENT_BONUS_EFFECT_DATE = 'assignmentBonusEffectDate';
  readonly C_ASSIGNMENT_BONUS_EXPIRATION_DATE = 'assignmentBonusExpirationDate';
  readonly C_ASSIGNMENT_NET_BONUS = 'assignmentNetBonus';
  readonly C_ASSIGNMENT_TOTAL_BONUS = 'assignmentTotalBonus';
  readonly C_ASSIGNMENT_COMMISSION_RATE = 'assignmentCommissionRate';

  readonly C_ASSIGNMENT_CREDITOR_NAME = 'creditorName';
  readonly C_ASSIGNMENT_CREDITOR_FISCAL_CODE = 'creditorFiscalCode';
  readonly C_ASSIGNMENT_CREDITOR_EMAIL = 'creditorEmail';
  readonly C_ASSIGNMENT_CREDITOR_OBSERVATIONS = 'creditorObservations';

  constructor(private plateNumberValidator: PlateNumberValidator) {
    const plateNumberControl = new FormControl('', {
      validators: [Validators.required, Validators.pattern(/^[0-9A-Za-z]+$/)],
      asyncValidators: [this.plateNumberValidator.validate.bind(this.plateNumberValidator)],
      updateOn: 'blur'
    });

    const policyNumberControl = new FormControl('', {
      asyncValidators: [],
      updateOn: 'blur'
    });

    this._form = new FormGroup({
      [this.C_UNIT_BONUS_GROUP]: new FormControl(null, []),
      [this.C_UNIT_OBSERVATIONS]: new FormControl(null, []),
      [this.C_UNIT_CAR_NUMBER]: new FormControl(null, []),
      [this.C_UNIT_PLATE_NUMBER]: plateNumberControl,
      [this.C_UNIT_REGISTRATION_DATE]: new FormControl(null, []),
      [this.C_UNIT_VEHICLE_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_UNIT_VEHICLE_USAGE_ID]: new FormControl(null, [Validators.required]),
      [this.C_UNIT_MOVEMENT_AREA]: new FormControl(null, [Validators.required]),
      [this.C_UNIT_BRAND]: new FormControl(null, []),
      [this.C_UNIT_MODEL]: new FormControl(null, []),
      [this.C_UNIT_VERSION]: new FormControl(null, []),
      [this.C_UNIT_MAX_ADDMITTED_WEIGHT]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_UNIT_VALUE]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxDecimal)]),
      [this.C_UNIT_VALUE_EXTRAS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxDecimal)]),
      [this.C_UNIT_BUS_NUMBER]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_UNIT_BODY_WORK_NUMBER]: new FormControl(null, []),
      [this.C_UNIT_BODY_WORK]: new FormControl(null, []),
      [this.C_UNIT_BUS_TYPE]: new FormControl(null, []),
      [this.C_UNIT_ENTERTAIMENT_BUS]: new FormControl(false, []),
      [this.C_UNIT_SEATS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt16)]),
      [this.C_UNIT_SEAT_NUMBER]: new FormControl(null, []),
      [this.C_UNIT_RENTING_COMPANY_FISCAL_CODE]: new FormControl(null, []),
      [this.C_UNIT_RENTING_START_DATE]: new FormControl(null, []),
      [this.C_UNIT_RENTING_EXPIRATION_DATE]: new FormControl(null, []),
      [this.C_UNIT_POWER]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt16)]),
      [this.C_UNIT_DIVISION]: new FormControl(null, []),
      [this.C_UNIT_ZONE]: new FormControl(null, []),
      [this.C_UNIT_CONTRACT]: new FormControl(null, []),
      [this.C_UNIT_OWNER]: new FormControl(null, [Validators.required]),
      [this.C_UNIT_LESSEE_ID]: new FormControl(null, []),
      [this.C_UNIT_USER_ID]: new FormControl(null, []),
      [this.C_UNIT_USER_ZONE]: new FormControl(null, []),
      [this.C_UNIT_AGE]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_UNIT_CONFIGURATION]: new FormControl(null, []),
      [this.C_UNIT_LENGTH]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_UNIT_KILOMETERS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_UNIT_CATEGORY]: new FormControl(null, []),
      [this.C_UNIT_CLASS]: new FormControl(null, []),
      [this.C_UNIT_EUROCLASS]: new FormControl(null, []),
      [this.C_UNIT_WORKSHOP]: new FormControl(null, []),
      [this.C_UNIT_CHASIS_NUMBER]: new FormControl(null, []),
      [this.C_UNIT_TD]: new FormControl(false, []),
      [this.C_UNIT_WIFI]: new FormControl(false, []),
      [this.C_UNIT_WIFI_NAME]: new FormControl(null, []),
      [this.C_UNIT_CONTRACTED_MAINTENANCE]: new FormControl(false, []),
      [this.C_UNIT_SCHOOL_BUS]: new FormControl(false, []),
      [this.C_UNIT_SAFE_BELTS]: new FormControl(null, []),
      [this.C_UNIT_INDIVIDUAL_ENTERTAIMENT]: new FormControl(false, []),
      [this.C_UNIT_PLATFORM]: new FormControl(null, []),
      [this.C_UNIT_PMRSR]: new FormControl(null, []),
      [this.C_UNIT_CAMERAS]: new FormControl(null, []),
      [this.C_UNIT_MANUFACTURING_MONTH]: new FormControl(null, []),
      [this.C_UNIT_MANUFACTURING_YEAR]: new FormControl(null, []),
      [this.C_UNIT_CONCESSION]: new FormControl(null, []),
      [this.C_UNIT_GOODS_TYPE_ID]: new FormControl(null, []),
      [this.C_UNIT_REGISTRATION_CLASSIFICATION]: new FormControl(null, []),
      [this.C_UNIT_CLASSIFICATION_CODE_CL]: new FormControl(null, []),
      [this.C_UNIT_FUNCTIONAL_TYPE_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.functionalTypes, 'functionalTypeId')]),
      [this.C_UNIT_CIRCULATION_AREA_ID]: new FormControl(null, []),

      [this.C_ASSIGNMENT_TARGET_POLICY_CLASS]: new FormControl(null, []),
      [this.C_ASSIGNMENT_TARGET_POLICY_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.rootPolicies, 'policyId')]),

      [this.C_ASSIGNMENT_POLICY_NUMBER]: policyNumberControl,
      [this.C_ASSIGNMENT_HOLDER_ID]: new FormControl(null, [Validators.required]),
      [this.C_ASSIGNMENT_INSURED_ID]: new FormControl(null, []),
      [this.C_ASSIGNMENT_FIRST_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ASSIGNMENT_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ASSIGNMENT_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),

      [this.C_ASSIGNMENT_MEDIATION_CODE_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.mediationCodes, 'mediationCodeId')]),

      [this.C_ASSIGNMENT_BOOKING_MODE_ID]: new FormControl(null, []),
      [this.C_ASSIGNMENT_FRANCHISE_DP]: new FormControl(null, []),
      [this.C_ASSIGNMENT_FRANCHISE_I]: new FormControl(null, []),
      [this.C_ASSIGNMENT_FRANCHISE_R]: new FormControl(null, []),
      [this.C_ASSIGNMENT_FRANCHISE_TR]: new FormControl(null, []),

      [this.C_ASSIGNMENT_BILLING_GROUP_PATTERN]: new FormControl(null, [Validators.pattern(BillingGroupUtils.billingGroupPattern)]),
      [this.C_ASSIGNMENT_BONUS_EFFECT_DATE]: new FormControl(null, []),
      [this.C_ASSIGNMENT_BONUS_EXPIRATION_DATE]: new FormControl(null, []),
      [this.C_ASSIGNMENT_NET_BONUS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_ASSIGNMENT_TOTAL_BONUS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_ASSIGNMENT_COMMISSION_RATE]: new FormControl(null, [Validators.min(0), Validators.max(100)]),

      [this.C_ASSIGNMENT_CREDITOR_NAME]: new FormControl(null, []),
      [this.C_ASSIGNMENT_CREDITOR_FISCAL_CODE]: new FormControl(null, []),
      [this.C_ASSIGNMENT_CREDITOR_EMAIL]: new FormControl(null, []),
      [this.C_ASSIGNMENT_CREDITOR_OBSERVATIONS]: new FormControl(null, [])
    });

    this._filteredRootPolicies = this.getControl(this.C_ASSIGNMENT_TARGET_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );

    this._filteredMediationCodes = this.getControl(this.C_ASSIGNMENT_MEDIATION_CODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterMediationCodes(value || ''))
    );
  }

  adaptAssignmentControls(): void {
    const assignmentControls = [
      this.getControl(this.C_ASSIGNMENT_POLICY_NUMBER),
      this.getControl(this.C_ASSIGNMENT_HOLDER_ID),
      this.getControl(this.C_ASSIGNMENT_INSURED_ID),
      this.getControl(this.C_ASSIGNMENT_FIRST_EFFECT_DATE),
      this.getControl(this.C_ASSIGNMENT_EFFECT_DATE),
      this.getControl(this.C_ASSIGNMENT_EXPIRATION_DATE),
      this.getControl(this.C_ASSIGNMENT_MEDIATION_CODE_ID),
      this.getControl(this.C_ASSIGNMENT_BOOKING_MODE_ID),
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_DP),
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_I),
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_R),
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_TR),
      this.getControl(this.C_ASSIGNMENT_BILLING_GROUP_PATTERN),
      this.getControl(this.C_ASSIGNMENT_BONUS_EFFECT_DATE),
      this.getControl(this.C_ASSIGNMENT_BONUS_EXPIRATION_DATE),
      this.getControl(this.C_ASSIGNMENT_NET_BONUS),
      this.getControl(this.C_ASSIGNMENT_TOTAL_BONUS),
      this.getControl(this.C_ASSIGNMENT_COMMISSION_RATE),
      this.getControl(this.C_ASSIGNMENT_CREDITOR_NAME),
      this.getControl(this.C_ASSIGNMENT_CREDITOR_FISCAL_CODE),
      this.getControl(this.C_ASSIGNMENT_CREDITOR_EMAIL),
      this.getControl(this.C_ASSIGNMENT_CREDITOR_OBSERVATIONS)
    ];

    assignmentControls.forEach(ctl => {
      ctl.reset();
      ctl.disable();
    });

    if (this.isAssignedToRootPolicy) {
      this.getControl(this.C_ASSIGNMENT_POLICY_NUMBER).enable();
      this.getControl(this.C_ASSIGNMENT_HOLDER_ID).enable();
      this.getControl(this.C_ASSIGNMENT_INSURED_ID).enable();
      this.getControl(this.C_ASSIGNMENT_FIRST_EFFECT_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_EFFECT_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_EXPIRATION_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_MEDIATION_CODE_ID).enable();
      this.getControl(this.C_ASSIGNMENT_BOOKING_MODE_ID).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_DP).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_I).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_R).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_TR).enable();

      this.getControl(this.C_ASSIGNMENT_BONUS_EFFECT_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_BONUS_EXPIRATION_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_NET_BONUS).enable();
      this.getControl(this.C_ASSIGNMENT_TOTAL_BONUS).enable();
      this.getControl(this.C_ASSIGNMENT_COMMISSION_RATE).enable();

      this.getControl(this.C_ASSIGNMENT_CREDITOR_NAME).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_FISCAL_CODE).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_EMAIL).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_OBSERVATIONS).enable();
    } else if (this.isAssignedToColectivePolicy) {
      this.getControl(this.C_ASSIGNMENT_INSURED_ID).enable();
      this.getControl(this.C_ASSIGNMENT_INSURED_ID).setValidators([Validators.required]);
      this.getControl(this.C_ASSIGNMENT_INSURED_ID).updateValueAndValidity();
      this.getControl(this.C_ASSIGNMENT_EFFECT_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_BOOKING_MODE_ID).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_DP).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_I).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_R).enable();
      this.getControl(this.C_ASSIGNMENT_FRANCHISE_TR).enable();
      this.getControl(this.C_ASSIGNMENT_BILLING_GROUP_PATTERN).enable();
      this.getControl(this.C_ASSIGNMENT_BONUS_EFFECT_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_BONUS_EXPIRATION_DATE).enable();
      this.getControl(this.C_ASSIGNMENT_NET_BONUS).enable();
      this.getControl(this.C_ASSIGNMENT_TOTAL_BONUS).enable();
      this.getControl(this.C_ASSIGNMENT_COMMISSION_RATE).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_NAME).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_FISCAL_CODE).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_EMAIL).enable();
      this.getControl(this.C_ASSIGNMENT_CREDITOR_OBSERVATIONS).enable();
    }
  }

  get isBookingModeRequired(): boolean {
    return this._isBookingModeRequired;
  }

  set isBookingModeRequired(value: boolean) {
    this._isBookingModeRequired = value;
  }

  get isAssignedToRootPolicy() {
    return this.assignmentTargetPolicyClass === PolicyClass.Other;
  }

  get isAssignedToColectivePolicy() {
    return this.assignmentTargetPolicyClass === PolicyClass.Colective;
  }

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IPolicySearchResult>) {
    this._rootPolicies = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  get vehicleTypes(): Array<IMasterValue> {
    return this._vehicleTypes;
  }

  set vehicleTypes(vehicleTypes: Array<IMasterValue>) {
    this._vehicleTypes = vehicleTypes;
  }

  get vehicleUsages(): Array<IMasterValue> {
    return this._vehicleUsages;
  }

  set vehicleUsages(vehicleUsages: Array<IMasterValue>) {
    this._vehicleUsages = vehicleUsages;
  }

  get functionalTypes(): Array<IFunctionalType> {
    return this._functionalTypes;
  }

  set functionalTypes(functionalTypes: Array<IFunctionalType>) {
    this._functionalTypes = functionalTypes;
  }

  get circulationAreas(): Array<ICirculationArea> {
    return this._circulationAreas;
  }

  set circulationAreas(circulationAreas: Array<ICirculationArea>) {
    this._circulationAreas = circulationAreas;
  }

  get movementAreas(): Array<IMovementArea> {
    return this._movementAreas;
  }

  set movementAreas(movementAreas: Array<IMovementArea>) {
    this._movementAreas = movementAreas;
  }

  get goodsTypes(): Array<IGoodsType> {
    return this._goodsTypes;
  }

  set goodsTypes(goodsTypes: Array<IGoodsType>) {
    this._goodsTypes = goodsTypes;
  }

  get mediationCodes(): Array<IMediationCode> {
    return this._mediationCodes;
  }

  set mediationCodes(value: Array<IMediationCode>) {
    this._mediationCodes = value;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(value: Array<IBookingModeSearchResult>) {
    this._bookingModes = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this.form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get unitBonusGroup(): string | null | undefined {
    return this.getControl(this.C_UNIT_BONUS_GROUP).value;
  }

  set unitBonusGroup(value: string | null | undefined) {
    this.getControl(this.C_UNIT_BONUS_GROUP).setValue(value);
  }

  get unitObservations(): string | null | undefined {
    return this.getControl(this.C_UNIT_OBSERVATIONS).value;
  }

  set unitObservations(value: string | null | undefined) {
    this.getControl(this.C_UNIT_OBSERVATIONS).setValue(value);
  }

  get unitCarNumber(): string | null | undefined {
    return this.getControl(this.C_UNIT_CAR_NUMBER).value;
  }

  set unitCarNumber(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CAR_NUMBER).setValue(value);
  }

  get unitPlateNumber(): string | null | undefined {
    return this.getControl(this.C_UNIT_PLATE_NUMBER).value;
  }

  set unitPlateNumber(value: string | null | undefined) {
    this.getControl(this.C_UNIT_PLATE_NUMBER).setValue(value);
  }

  get unitRegistrationDate(): Date | null | undefined {
    return this.getControl(this.C_UNIT_REGISTRATION_DATE).value;
  }

  set unitRegistrationDate(value: Date | null | undefined) {
    this.getControl(this.C_UNIT_REGISTRATION_DATE).setValue(value);
  }

  get unitVehicleTypeId(): string | null | undefined {
    return this.getControl(this.C_UNIT_VEHICLE_TYPE_ID).value;
  }

  set unitVehicleTypeId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_VEHICLE_TYPE_ID).setValue(value);
  }

  get unitVehicleUsageId(): string | null | undefined {
    return this.getControl(this.C_UNIT_VEHICLE_USAGE_ID).value;
  }

  set unitVehicleUsageId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_VEHICLE_USAGE_ID).setValue(value);
  }

  get unitMovementArea(): string | null | undefined {
    return this.getControl(this.C_UNIT_MOVEMENT_AREA).value;
  }

  set unitMovementArea(value: string | null | undefined) {
    this.getControl(this.C_UNIT_MOVEMENT_AREA).setValue(value);
  }

  get unitBrand(): string | null | undefined {
    return this.getControl(this.C_UNIT_BRAND).value;
  }

  set unitBrand(value: string | null | undefined) {
    this.getControl(this.C_UNIT_BRAND).setValue(value);
  }

  get unitModel(): string | null | undefined {
    return this.getControl(this.C_UNIT_MODEL).value;
  }

  set unitModel(value: string | null | undefined) {
    this.getControl(this.C_UNIT_MODEL).setValue(value);
  }

  get unitVersion(): string | null | undefined {
    return this.getControl(this.C_UNIT_VERSION).value;
  }

  set unitVersion(value: string | null | undefined) {
    this.getControl(this.C_UNIT_VERSION).setValue(value);
  }

  get unitMaxAdmittedWeight(): number | null | undefined {
    return this.getControl(this.C_UNIT_MAX_ADDMITTED_WEIGHT).value;
  }

  set unitMaxAdmittedWeight(value: number | null | undefined) {
    this.getControl(this.C_UNIT_MAX_ADDMITTED_WEIGHT).setValue(value);
  }

  get unitValue(): number | null | undefined {
    return this.getControl(this.C_UNIT_VALUE).value;
  }

  set unitValue(value: number | null | undefined) {
    this.getControl(this.C_UNIT_VALUE).setValue(value);
  }

  get unitValueExtras(): number | null | undefined {
    return this.getControl(this.C_UNIT_VALUE_EXTRAS).value;
  }

  set unitValueExtras(value: number | null | undefined) {
    this.getControl(this.C_UNIT_VALUE_EXTRAS).setValue(value);
  }

  get unitBusNumber(): number | null | undefined {
    return this.getControl(this.C_UNIT_BUS_NUMBER).value;
  }

  set unitBusNumber(value: number | null | undefined) {
    this.getControl(this.C_UNIT_BUS_NUMBER).setValue(value);
  }

  get unitBodyworkNumber(): string | null | undefined {
    return this.getControl(this.C_UNIT_BODY_WORK_NUMBER).value;
  }

  set unitBodyworkNumber(value: string | null | undefined) {
    this.getControl(this.C_UNIT_BODY_WORK_NUMBER).setValue(value);
  }

  get unitBodywork(): string | null | undefined {
    return this.getControl(this.C_UNIT_BODY_WORK).value;
  }

  set unitBodywork(value: string | null | undefined) {
    this.getControl(this.C_UNIT_BODY_WORK).setValue(value);
  }

  get unitBusType(): string | null | undefined {
    return this.getControl(this.C_UNIT_BUS_TYPE).value;
  }

  set unitBusType(value: string | null | undefined) {
    this.getControl(this.C_UNIT_BUS_TYPE).setValue(value);
  }

  get unitEntertaimentBus(): boolean | null | undefined {
    return this.getControl(this.C_UNIT_ENTERTAIMENT_BUS).value;
  }

  set unitEntertaimentBus(value: boolean | null | undefined) {
    this.getControl(this.C_UNIT_ENTERTAIMENT_BUS).setValue(value);
  }

  get unitSeats(): number | null | undefined {
    return this.getControl(this.C_UNIT_SEATS).value;
  }

  set unitSeats(value: number | null | undefined) {
    this.getControl(this.C_UNIT_SEATS).setValue(value);
  }

  get unitSeatNumber(): string | null | undefined {
    return this.getControl(this.C_UNIT_SEAT_NUMBER).value;
  }

  set unitSeatNumber(value: string | null | undefined) {
    this.getControl(this.C_UNIT_SEAT_NUMBER).setValue(value);
  }

  get unitRentingCompanyFiscalCode(): string | null | undefined {
    return this.getControl(this.C_UNIT_RENTING_COMPANY_FISCAL_CODE).value;
  }

  set unitRentingCompanyFiscalCode(value: string | null | undefined) {
    this.getControl(this.C_UNIT_RENTING_COMPANY_FISCAL_CODE).setValue(value);
  }

  get unitRentingStartDate(): Date | null | undefined {
    return this.getControl(this.C_UNIT_RENTING_START_DATE).value;
  }

  set unitRentingStartDate(value: Date | null | undefined) {
    this.getControl(this.C_UNIT_RENTING_START_DATE).setValue(value);
  }

  get unitRentingExpirationDate(): Date | null | undefined {
    return this.getControl(this.C_UNIT_RENTING_EXPIRATION_DATE).value;
  }

  set unitRentingExpirationDate(value: Date | null | undefined) {
    this.getControl(this.C_UNIT_RENTING_EXPIRATION_DATE).setValue(value);
  }

  get unitPower(): number | null | undefined {
    return this.getControl(this.C_UNIT_POWER).value;
  }

  set unitPower(value: number | null | undefined) {
    this.getControl(this.C_UNIT_POWER).setValue(value);
  }

  get unitDivision(): string | null | undefined {
    return this.getControl(this.C_UNIT_DIVISION).value;
  }

  set unitDivision(value: string | null | undefined) {
    this.getControl(this.C_UNIT_DIVISION).setValue(value);
  }

  get unitZone(): string | null | undefined {
    return this.getControl(this.C_UNIT_ZONE).value;
  }

  set unitZone(value: string | null | undefined) {
    this.getControl(this.C_UNIT_ZONE).setValue(value);
  }

  get unitContract(): string | null | undefined {
    return this.getControl(this.C_UNIT_CONTRACT).value;
  }

  set unitContract(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CONTRACT).setValue(value);
  }

  get unitOwner(): string | null | undefined {
    return this.getControl(this.C_UNIT_OWNER).value;
  }

  set unitOwner(value: string | null | undefined) {
    this.getControl(this.C_UNIT_OWNER).setValue(value);
  }

  get unitLesseeId(): string | null | undefined {
    return this.getControl(this.C_UNIT_LESSEE_ID).value;
  }

  set unitLesseeId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_LESSEE_ID).setValue(value);
  }

  get unitUserId(): string | null | undefined {
    return this.getControl(this.C_UNIT_USER_ID).value;
  }

  set unitUserId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_USER_ID).setValue(value);
  }

  get unitUserZone(): string | null | undefined {
    return this.getControl(this.C_UNIT_USER_ZONE).value;
  }

  set unitUserZone(value: string | null | undefined) {
    this.getControl(this.C_UNIT_USER_ZONE).setValue(value);
  }

  get unitAge(): number | null | undefined {
    return this.getControl(this.C_UNIT_AGE).value;
  }

  set unitAge(value: number | null | undefined) {
    this.getControl(this.C_UNIT_AGE).setValue(value);
  }

  get unitConfiguration(): string | null | undefined {
    return this.getControl(this.C_UNIT_CONFIGURATION).value;
  }

  set unitConfiguration(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CONFIGURATION).setValue(value);
  }

  get unitLength(): number | null | undefined {
    return this.getControl(this.C_UNIT_LENGTH).value;
  }

  set unitLength(value: number | null | undefined) {
    this.getControl(this.C_UNIT_LENGTH).setValue(value);
  }

  get unitKilometers(): number | null | undefined {
    return this.getControl(this.C_UNIT_KILOMETERS).value;
  }

  set unitKilometers(value: number | null | undefined) {
    this.getControl(this.C_UNIT_KILOMETERS).setValue(value);
  }

  get unitCategory(): string | null | undefined {
    return this.getControl(this.C_UNIT_CATEGORY).value;
  }

  set unitCategory(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CATEGORY).setValue(value);
  }

  get unitClass(): string | null | undefined {
    return this.getControl(this.C_UNIT_CLASS).value;
  }

  set unitClass(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CLASS).setValue(value);
  }

  get unitEuroClass(): string | null | undefined {
    return this.getControl(this.C_UNIT_EUROCLASS).value;
  }

  set unitEuroClass(value: string | null | undefined) {
    this.getControl(this.C_UNIT_EUROCLASS).setValue(value);
  }

  get unitWorkshop(): string | null | undefined {
    return this.getControl(this.C_UNIT_WORKSHOP).value;
  }

  set unitWorkshop(value: string | null | undefined) {
    this.getControl(this.C_UNIT_WORKSHOP).setValue(value);
  }

  get unitChassisNumber(): string | null | undefined {
    return this.getControl(this.C_UNIT_CHASIS_NUMBER).value;
  }

  set unitChassisNumber(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CHASIS_NUMBER).setValue(value);
  }

  get unitTd(): boolean | null | undefined {
    return this.getControl(this.C_UNIT_TD).value;
  }

  set unitTd(value: boolean | null | undefined) {
    this.getControl(this.C_UNIT_TD).setValue(value);
  }

  get unitWifi(): boolean | null | undefined {
    return this.getControl(this.C_UNIT_WIFI).value;
  }

  set unitWifi(value: boolean | null | undefined) {
    this.getControl(this.C_UNIT_WIFI).setValue(value);
  }

  get unitWifiName(): string | null | undefined {
    return this.getControl(this.C_UNIT_WIFI_NAME).value;
  }

  set unitWifiName(value: string | null | undefined) {
    this.getControl(this.C_UNIT_WIFI_NAME).setValue(value);
  }

  get unitContractedMaintenance(): boolean | null | undefined {
    return this.getControl(this.C_UNIT_CONTRACTED_MAINTENANCE).value;
  }

  set unitContractedMaintenance(value: boolean | null | undefined) {
    this.getControl(this.C_UNIT_CONTRACTED_MAINTENANCE).setValue(value);
  }

  get unitSchoolBus(): boolean | null | undefined {
    return this.getControl(this.C_UNIT_SCHOOL_BUS).value;
  }

  set unitSchoolBus(value: boolean | null | undefined) {
    this.getControl(this.C_UNIT_SCHOOL_BUS).setValue(value);
  }

  get unitSafeBelts(): string | null | undefined {
    return this.getControl(this.C_UNIT_SAFE_BELTS).value;
  }

  set unitSafeBelts(value: string | null | undefined) {
    this.getControl(this.C_UNIT_SAFE_BELTS).setValue(value);
  }

  get unitIndividualEntertaiment(): boolean | null | undefined {
    return this.getControl(this.C_UNIT_INDIVIDUAL_ENTERTAIMENT).value;
  }

  set unitIndividualEntertaiment(value: boolean | null | undefined) {
    this.getControl(this.C_UNIT_INDIVIDUAL_ENTERTAIMENT).setValue(value);
  }

  get unitPlatform(): string | null | undefined {
    return this.getControl(this.C_UNIT_PLATFORM).value;
  }

  set unitPlatform(value: string | null | undefined) {
    this.getControl(this.C_UNIT_PLATFORM).setValue(value);
  }

  get unitPmrsr(): string | null | undefined {
    return this.getControl(this.C_UNIT_PMRSR).value;
  }

  set unitPmrsr(value: string | null | undefined) {
    this.getControl(this.C_UNIT_PMRSR).setValue(value);
  }

  get unitCameras(): string | null | undefined {
    return this.getControl(this.C_UNIT_CAMERAS).value;
  }

  set unitCameras(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CAMERAS).setValue(value);
  }

  get unitManufacturingMonth(): number | null | undefined {
    return this.getControl(this.C_UNIT_MANUFACTURING_MONTH).value;
  }

  set unitManufacturingMonth(value: number | null | undefined) {
    this.getControl(this.C_UNIT_MANUFACTURING_MONTH).setValue(value);
  }

  get unitManufacturingYear(): number | null | undefined {
    return this.getControl(this.C_UNIT_MANUFACTURING_YEAR).value;
  }

  set unitManufacturingYear(value: number | null | undefined) {
    this.getControl(this.C_UNIT_MANUFACTURING_YEAR).setValue(value);
  }

  get unitConcession(): string | null | undefined {
    return this.getControl(this.C_UNIT_CONCESSION).value;
  }

  set unitConcession(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CONCESSION).setValue(value);
  }

  get unitGoodsTypeId(): string | null | undefined {
    return this.getControl(this.C_UNIT_GOODS_TYPE_ID).value;
  }

  set unitGoodsTypeId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_GOODS_TYPE_ID).setValue(value);
  }

  get unitRegistrationClassification(): string | null | undefined {
    return this.getControl(this.C_UNIT_REGISTRATION_CLASSIFICATION).value;
  }

  set unitRegistrationClassification(value: string | null | undefined) {
    this.getControl(this.C_UNIT_REGISTRATION_CLASSIFICATION).setValue(value);
  }

  get unitClassificationCodeCL(): string | null | undefined {
    return this.getControl(this.C_UNIT_CLASSIFICATION_CODE_CL).value;
  }

  set unitClassificationCodeCL(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CLASSIFICATION_CODE_CL).setValue(value);
  }

  get unitFunctionalTypeId(): string | null | undefined {
    return this.getControl(this.C_UNIT_FUNCTIONAL_TYPE_ID).value;
  }

  set unitFunctionalTypeId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_FUNCTIONAL_TYPE_ID).setValue(value);
  }

  get unitCirculationAreaId(): string | null | undefined {
    return this.getControl(this.C_UNIT_CIRCULATION_AREA_ID).value;
  }

  set unitCirculationAreaId(value: string | null | undefined) {
    this.getControl(this.C_UNIT_CIRCULATION_AREA_ID).setValue(value);
  }

  get assignmentTargetPolicyClass(): PolicyClass | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_TARGET_POLICY_CLASS).value;
  }

  set assignmentTargetPolicyClass(value: PolicyClass | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_TARGET_POLICY_CLASS).setValue(value);
  }

  get assignmentTargetPolicyId(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_TARGET_POLICY_ID).value;
  }

  set assignmentTargetPolicyId(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_TARGET_POLICY_ID).setValue(value);
  }

  get assignmentPolicyNumber(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_POLICY_NUMBER).value;
  }

  set assignmentPolicyNumber(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_POLICY_NUMBER).setValue(value);
  }

  get assignmentHolderId(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_HOLDER_ID).value;
  }

  set assignmentHolderId(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_HOLDER_ID).setValue(value);
  }

  get assignmentInsuredId(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_INSURED_ID).value;
  }

  set assignmentInsuredId(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_INSURED_ID).setValue(value);
  }

  get assignmentFirstEffectDate(): Date | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_FIRST_EFFECT_DATE).value;
  }

  set assignmentFirstEffectDate(value: Date | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_FIRST_EFFECT_DATE).setValue(value);
  }

  get assignmentEffectDate(): Date | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_EFFECT_DATE).value;
  }

  set assignmentEffectDate(value: Date | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_EFFECT_DATE).setValue(value);
  }

  get assignmentExpirationDate(): Date | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_EXPIRATION_DATE).value;
  }

  set assignmentExpirationDate(value: Date | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_EXPIRATION_DATE).setValue(value);
  }

  get assignmentMediationCodeId(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_MEDIATION_CODE_ID).value;
  }

  set assignmentMediationCodeId(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_MEDIATION_CODE_ID).setValue(value);
  }

  get assignmentBookingModeId(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_BOOKING_MODE_ID).value;
  }

  set assignmentBookingModeId(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_BOOKING_MODE_ID).setValue(value);
  }

  get assignmentFranchiseDP(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_FRANCHISE_DP).value;
  }

  set assignmentFranchiseDP(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_FRANCHISE_DP).setValue(value);
  }

  get assignmentFranchiseI(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_FRANCHISE_I).value;
  }

  set assignmentFranchiseI(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_FRANCHISE_I).setValue(value);
  }

  get assignmentFranchiseR(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_FRANCHISE_R).value;
  }

  set assignmentFranchiseR(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_FRANCHISE_R).setValue(value);
  }

  get assignmentFranchiseTR(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_FRANCHISE_TR).value;
  }

  set assignmentFranchiseTR(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_FRANCHISE_TR).setValue(value);
  }

  get assignmentBillingGroupPattern(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_BILLING_GROUP_PATTERN).value;
  }

  set assignmentBillingGroupPattern(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_BILLING_GROUP_PATTERN).setValue(value);
  }

  get assignmentBonusEffectDate(): Date | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_BONUS_EFFECT_DATE).value;
  }

  set assignmentBonusEffectDate(value: Date | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_BONUS_EFFECT_DATE).setValue(value);
  }

  get assignmentBonusExpirationDate(): Date | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_BONUS_EXPIRATION_DATE).value;
  }

  set assignmentBonusExpirationDate(value: Date | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_BONUS_EXPIRATION_DATE).setValue(value);
  }

  get assignmentNetBonus(): number | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_NET_BONUS).value;
  }

  set assignmentNetBonus(value: number | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_NET_BONUS).setValue(value);
  }

  get assignmentTotalBonus(): number | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_TOTAL_BONUS).value;
  }

  set assignmentTotalBonus(value: number | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_TOTAL_BONUS).setValue(value);
  }

  get assignmentCommissionRate(): number | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_COMMISSION_RATE).value;
  }

  set assignmentCommissionRate(value: number | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_COMMISSION_RATE).setValue(value);
  }

  get assignmentCreditorName(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_CREDITOR_NAME).value;
  }

  set assignmentCreditorName(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_CREDITOR_NAME).setValue(value);
  }

  get assignmentCreditorFiscalCode(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_CREDITOR_FISCAL_CODE).value;
  }

  set assignmentCreditorFiscalCode(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_CREDITOR_FISCAL_CODE).setValue(value);
  }

  get assignmentCreditorEmail(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_CREDITOR_EMAIL).value;
  }

  set assignmentCreditorEmail(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_CREDITOR_EMAIL).setValue(value);
  }

  get assignmentCreditorObservations(): string | null | undefined {
    return this.getControl(this.C_ASSIGNMENT_CREDITOR_OBSERVATIONS).value;
  }

  set assignmentCreditorObservations(value: string | null | undefined) {
    this.getControl(this.C_ASSIGNMENT_CREDITOR_OBSERVATIONS).setValue(value);
  }

  /*************************************************************************************** */

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  get filteredMediationCodes(): Observable<Array<IMediationCode>> {
    return this._filteredMediationCodes;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }

  private filterMediationCodes(value: string): Array<IMediationCode> {
    return value !== ''
      ? this.mediationCodes.filter(x => x.description?.toLowerCase().includes(value.toLowerCase()))
      : this.mediationCodes;
  }
}
