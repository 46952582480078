import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IClaimSummary } from 'src/app/core/models/api/claims';
import { IClaimService } from './claim.contract';

@Injectable({
  providedIn: 'root'
})
export class ClaimService implements IClaimService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getSummary(id: string): Observable<IClaimSummary> {
    return this.httpClient.get<IClaimSummary>(APP_CONSTANTS.API_NET_FLOTAS, `/claims/${id}/summary`);
  }
}
