import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult, IKeyValue } from '@howdeniberia/core-front';
import { forkJoin, of } from 'rxjs';
import { BillingGroupRuleType } from 'src/app/core/enums';
import { IFleetService } from 'src/app/core/services/api/fleets/fleet.contract';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { PolicyEditBillinGroupRuleMapper } from '../mappers';
import { PolicyEditBillingGroupRuleViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit-billing-group-rule-dialog',
  templateUrl: './policy-edit-billing-group-rule-dialog.component.html',
  styleUrls: ['./policy-edit-billing-group-rule-dialog.component.scss']
})
export class PolicyEditBillingGroupRuleDialogComponent implements OnInit, OnDestroy {
  model = new PolicyEditBillingGroupRuleViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<PolicyEditBillingGroupRuleDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private unitSrv: IUnitService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const policyId = this.inputData.policyId as string;
    const billingGroupId = this.inputData.billingGroupId;
    const ruleId = this.inputData.ruleId;
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.getBookingModes(policyId),
      this.fleetSrv.getCompanies(fleetId),
      this.fleetSrv.getVehicleTypes(fleetId),
      this.fleetSrv.getVehicleUsages(fleetId),
      this.unitSrv.getMovementAreas(),
      this.unitSrv.getCirculationAreas(),
      this.unitSrv.getGoodsTypes(),
      (typeof ruleId !== 'undefined' && ruleId !== null)
        ? this.policySrv.getBillingGroupRule(policyId, billingGroupId, ruleId)
        : of(null)
    ]).subscribe(([
      bookingModes,
      fleetCompanies,
      vehicleTypes,
      vehicleUsages,
      movementAreas,
      circulationAreas,
      goodsTypes,
      rule
    ]) => {
      this.model.bookingModes = bookingModes;
      this.model.fleetCompanies = fleetCompanies;
      this.model.vehicleTypes = vehicleTypes;
      this.model.vehicleUsages = vehicleUsages;
      this.model.movementAreas = movementAreas;
      this.model.circulationAreas = circulationAreas;
      this.model.goodsTypes = goodsTypes;

      this.onChanges();

      if (typeof rule !== 'undefined' && rule !== null) {
        PolicyEditBillinGroupRuleMapper.mapForEdit(rule, this.model);
      }

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const policyId = this.inputData.policyId as string;
    const billingGroupId = this.inputData.billingGroupId;
    const request = PolicyEditBillinGroupRuleMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.policySrv.saveBillingGroupRule(policyId, billingGroupId, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.getControl(this.model.C_TYPE).valueChanges.subscribe(() => {
      this.model.referenceValues = this.getReferenceValues();

      switch (this.model.type) {
        case BillingGroupRuleType.UnitBonusGroup:
          this.model.getControl(this.model.C_VALUE_ID).clearValidators();
          this.model.getControl(this.model.C_DIRECT_VALUE).addValidators([Validators.required]);
          this.model.getControl(this.model.C_DIRECT_VALUE).updateValueAndValidity();
          break;
        default:
          this.model.getControl(this.model.C_DIRECT_VALUE).clearValidators();
          this.model.getControl(this.model.C_VALUE_ID).addValidators([Validators.required]);
          this.model.getControl(this.model.C_VALUE_ID).updateValueAndValidity();
          break;
      }

      this.model.getControl(this.model.C_VALUE_ID).reset();
      this.model.getControl(this.model.C_DIRECT_VALUE).reset();
    });
  }

  private getReferenceValues(): Array<IKeyValue> {
    switch (this.model.type) {
      case BillingGroupRuleType.BookingMode:
        return this.model.bookingModes.map((value) => ({ key: value.bookingModeId, value: value.description } as IKeyValue));

      case BillingGroupRuleType.PolicyHolder:
      case BillingGroupRuleType.PolicyInsured:
        return this.model.fleetCompanies.map((value) => ({ key: value.companyId, value: value.clientName } as IKeyValue));

      case BillingGroupRuleType.MovementArea:
        return this.model.movementAreas.map((value) => ({ key: value.movementAreaId, value: value.description } as IKeyValue));

      case BillingGroupRuleType.VehicleType:
        return this.model.vehicleTypes.map((value) => ({ key: value.erpId, value: value.description } as IKeyValue));

      case BillingGroupRuleType.VehicleUsage:
        return this.model.vehicleUsages.map((value) => ({ key: value.erpId, value: value.description } as IKeyValue));

      case BillingGroupRuleType.CirculationArea:
        return this.model.circulationAreas.map((value) => ({ key: value.circulationAreaId, value: value.description } as IKeyValue));

      case BillingGroupRuleType.GoodsType:
        return this.model.goodsTypes.map((value) => ({ key: value.goodTypeId, value: value.description } as IKeyValue));

      default:
        return [];
    }
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
