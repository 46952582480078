import { IFilterValue, IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { FleetSearchResultViewModel } from './fleet-search-result.viewmodel';

export class FleetSearchViewModel {
  private _pageIndex = 0;
  private _pageSize = 10;
  private _length = 0;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _filters?: IFilterValue[];

  data: FleetSearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'name',
      fieldHeader: $localize`:@@app.fleets.search.name.column:Denominación flota`,
      canSort: true,
      canFilter: true
    },
    {
      fieldName: 'clientName',
      fieldHeader: $localize`:@@app.fleets.search.clientName.column:Grupo cliente/cliente`,
      canSort: true,
      canFilter: true
    },
    {
      fieldName: 'clientFiscalCode',
      fieldHeader: $localize`:@@app.fleets.search.clientFiscalCode.column:NIF/CIF`,
      canSort: true,
      canFilter: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.fleets.search.actions.delete.tooltip:Borrar`,
    isDisabled: (row: any): boolean => {
      const activeFleet: string = sessionStorage.getItem('ngx-webstorage:active-fleet') as string;
      const rowFleet: string = `"${row.fleetId}"`;
      return activeFleet === rowFleet;
    }
  }, {
    name: 'activate',
    icon: 'toggle_on',
    tooltip: $localize`:@@app.fleets.search.actions.activate.tooltip:Activar`,
    isDisabled: (row: any): boolean => {
      const activeFleet: string = sessionStorage.getItem('ngx-webstorage:active-fleet') as string;
      const rowFleet: string = `"${row.fleetId}"`;
      return activeFleet === rowFleet;
    }
  }];

  get pageIndex(): number { return this._pageIndex; }

  set pageIndex(value: number) { this._pageIndex = value; }

  get pageSize(): number { return this._pageSize; }

  set pageSize(value: number) { this._pageSize = value; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  set sortedBy(value: string | undefined) { this._sortedBy = value; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  set sortDirection(value: string | undefined) { this._sortDirection = value; }

  get filters(): Array<IFilterValue> | undefined { return this._filters; }

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  updateServerSideConfig(event: ITableEventData): void {
    this._pageIndex = event.pageIndex;
    this._pageSize = event.pageSize;
    this._sortedBy = event.fieldSorted;
    this._sortDirection = event.order;
    this._filters = event.filters;
  }

  getFilterValue<T>(index: number): T | null {
    return this.filters && this.filters.length > 0 ? this.filters[index].value as T : null;
  }
}
