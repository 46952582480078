import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { IFleetTaxViewModel } from './fleet-tax.viewmodel';

export class FleetEditTaxesViewModel {
  data: IFleetTaxViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'name',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.name.column:Impuesto`,
      canSort: true
    },
    {
      fieldName: 'startDate',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.startDate.column:Desde`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'endDate',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.endDate.column:Hasta`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true
    },
    {
      fieldName: 'paymentType',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.paymentType.column:Forma de pago`,
      canSort: true
    },
    {
      fieldName: 'value',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.value.column:Valor`,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT,
      canSort: true
    },
    {
      fieldName: 'rcso',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.rcso.column:RCSO`,
      pipeToApply: ColumnPipeTypes.CHECK,
      aligment: ColumnAligment.CENTER,
      canSort: true
    },
    {
      fieldName: 'rc',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.rc.column:RC`,
      pipeToApply: ColumnPipeTypes.CHECK,
      aligment: ColumnAligment.CENTER,
      canSort: true
    },
    {
      fieldName: 'sovi',
      fieldHeader: $localize`:@@app.fleets.edit.taxes.sovi.column:SOVI`,
      pipeToApply: ColumnPipeTypes.CHECK,
      aligment: ColumnAligment.CENTER,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    tooltip: $localize`:@@app.fleets.edit.taxes.actions.edit.tooltip:Editar`
  }, {
    name: 'delete',
    icon: 'delete',
    tooltip: $localize`:@@app.fleets.edit.taxes.actions.delete.tooltip:Borrar`
  }];
}
