{
  "name": "howden-netflotas",
  "version": "1.18.0-rc17",
  "description": "Howden - NET Flotas",
  "title": "Howden - NET Flotas",
  "languages": [
    "es"
  ],
  "copyright": "Copyright 2015-2024 | Howden Iberia | Todos los derechos reservados",
  "scripts": {
    "start": "ng serve",
    "start-pre": "ng serve -c staging",
    "start-pro": "ng serve -c production",
    "build": "ng build",
    "build-Development": "ng build -c development",
    "build-Staging": "ng build -c staging",
    "build-Production": "ng build -c production",
    "build-DevelopmentHowdenArtai": "ng build --configuration devHowdenArtai",
    "build-StagingHowdenArtai": "ng build --configuration preHowdenArtai",
    "build-ProductionHowdenArtai": "ng build --configuration proHowdenArtai",
    "build-DevelopmentHowdenGroup": "ng build --configuration devHowdenGroup",
    "build-StagingHowdenGroup": "ng build --configuration preHowdenGroup",
    "build-ProductionHowdenGroup": "ng build --configuration proHowdenGroup",
    "watch": "ng build --watch -c development",
    "test": "ng test",
    "lint": "eslint ",
    "lint-and-fix": "eslint --fix",
    "i18n-extract": "ng extract-i18n --output-path=src/locale --progress=true"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.4",
    "@angular/cdk": "^18.2.3",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/forms": "^18.2.4",
    "@angular/material": "^18.2.3",
    "@angular/material-moment-adapter": "^18.2.3",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/router": "^18.2.4",
    "@howdeniberia/core-front": "^1.18.4",
    "echarts": "^5.5.1",
    "moment": "^2.30.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-currency": "^18.0.0",
    "ngx-echarts": "^18.0.0",
    "ngx-quill": "26.0.2",
    "ngx-toastr": "~18.0.0",
    "ngx-webstorage": "^18.0.0",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "subsink": "^1.0.2",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular-devkit/core": "^18.2.4",
    "@angular-devkit/schematics": "^18.2.4",
    "@angular/cli": "^18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@angular/localize": "^18.2.4",
    "@eslint/js": "^9.10.0",
    "@schematics/angular": "18.2.4",
    "@types/echarts": "^4.9.18",
    "@types/eslint__js": "^8.42.3",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^20.14.9",
    "@types/quill": "^2.0.14",
    "angular-eslint": "18.2.0",
    "eslint": "^9.9.0",
    "jasmine-core": "^5.1.2",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-firefox-launcher": "~2.1.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "~5.4.5",
    "typescript-eslint": "^8.1.0"
  }
}
