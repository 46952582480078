import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IItemSelection } from 'src/app/core/models/api/core';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IReportsService } from 'src/app/core/services/api/reports';
import { IUnitService } from 'src/app/core/services/api/units';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { UnitReportMapper } from '../mappers';
import { UnitReportViewModel } from '../models';

@Component({
  selector: 'howden-unit-report',
  templateUrl: './unit-report.component.html',
  styleUrls: ['./unit-report.component.scss']
})
export class UnitReportComponent implements OnInit {
  model = new UnitReportViewModel();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private mastersSrv: IMasterService,
    private unitSrv: IUnitService,
    private reportsSrv: IReportsService) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    forkJoin([
      this.fleetSrv.getVehicleTypes(fleetId),
      this.fleetSrv.getVehicleUsages(fleetId),
      this.unitSrv.getMovementAreas(),
      this.mastersSrv.getUnitStatuses()
    ]).subscribe(([vehicleTypes, vehicleUsages, movementAreas, unitStatuses]) => {
      this.model.vehicleTypes = vehicleTypes.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.model.vehicleUsages = vehicleUsages.map(x => <IItemSelection>{ id: x.erpId, description: x.description });
      this.model.movementAreas = movementAreas.map(x => <IItemSelection>{ id: x.movementAreaId, description: x.description });
      this.model.statuses = unitStatuses.map(x => <IItemSelection>{ id: x.key, description: x.value });
      this.uiBlockerSrv.unblock();
    });
  }

  onGenerateReport() {
    const request = UnitReportMapper.mapForGeneration(this.sessionSrv.activeFleet, this.model);

    this.uiBlockerSrv.block();
    this.reportsSrv.units(request).subscribe({
      next: blob => {
        FileUtils.downloadFile('HOWDEN_NET_FLOTAS_LISTADO_UNIDADES', blob);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
