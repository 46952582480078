import { IUnitCancelPolicyRisksRequest } from 'src/app/core/models/api/units';
import { UnitCancelRisksViewModel } from '../models';

export abstract class UnitCancelRisksMapper {
  static mapForUpdate(source: UnitCancelRisksViewModel): IUnitCancelPolicyRisksRequest {
    return {
      policyRisks: source.policyRiskIds,
      operationDate: source.operationDate,
      cancellationNotificationDate: source.cancellationNotificationDate,
      cancellationReasonId: source.cancellationReasonId
    } as IUnitCancelPolicyRisksRequest;
  }
}
