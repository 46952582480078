import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IFleetCompany } from 'src/app/core/models/api/fleets';
import { ICirculationArea, IFunctionalType, IGoodsType, IMovementArea } from 'src/app/core/models/api/units';
import { IDetailedClaim } from 'src/app/core/models/ida/claims';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { Limits } from 'src/app/shared/utils';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class UnitEditViewModel {
  private _form: FormGroup;
  private _claims: Array<IDetailedClaim> = [];
  private _fleetCompanies: Array<IFleetCompany> = [];
  private _vehicleTypes: Array<IMasterValue> = [];
  private _vehicleUsages: Array<IMasterValue> = [];
  private _movementAreas: Array<IMovementArea> = [];
  private _goodsTypes: Array<IGoodsType> = [];
  private _functionalTypes: Array<IFunctionalType> = [];
  private _circulationAreas: Array<ICirculationArea> = [];
  private _allowFleetTransfer!: boolean;
  private _allowPlateNumberChange!: boolean;

  readonly C_ID = 'unitId';
  readonly C_BONUS_GROUP = 'bonusGroup';
  readonly C_OBSERVATIONS = 'comments';
  readonly C_CAR_NUMBER = 'carNumber';
  readonly C_PLATE_NUMBER = 'plateNumber';
  readonly C_REGISTRATION_DATE = 'registrationDate';
  readonly C_VEHICLE_TYPE_ID = 'vehicleTypeId';
  readonly C_VEHICLE_USAGE_ID = 'vehicleUsageId';
  readonly C_MOVEMENT_AREA = 'movementArea';
  readonly C_BRAND = 'brand';
  readonly C_MODEL = 'model';
  readonly C_VERSION = 'version';
  readonly C_MAX_ADDMITTED_WEIGHT = 'maxAdmittedWeight';
  readonly C_VALUE = 'value';
  readonly C_VALUE_EXTRAS = 'valueExtras';
  readonly C_BUS_NUMBER = 'busNumber';
  readonly C_BODY_WORK_NUMBER = 'bodyworkNumber';
  readonly C_BODY_WORK = 'bodywork';
  readonly C_BUS_TYPE = 'busType';
  readonly C_ENTERTAIMENT_BUS = 'entertaimentBus';
  readonly C_SEATS = 'seats';
  readonly C_SEAT_NUMBER = 'seatNumber';
  readonly C_RENTING_COMPANY_FISCAL_CODE = 'rentingCompanyFiscalCode';
  readonly C_RENTING_START_DATE = 'rentingStartDate';
  readonly C_RENTING_EXPIRATION_DATE = 'rentingExpirationDate';
  readonly C_POWER = 'power';
  readonly C_DIVISION = 'division';
  readonly C_ZONE = 'zone';
  readonly C_CONTRACT = 'contract';
  readonly C_OWNER = 'owner';
  readonly C_LESSEE_ID = 'lesseeId';
  readonly C_USER_ID = 'userId';
  readonly C_USER_ZONE = 'userZone';
  readonly C_AGE = 'age';
  readonly C_CONFIGURATION = 'configuration';
  readonly C_LENGTH = 'length';
  readonly C_KILOMETERS = 'kilometers';
  readonly C_CATEGORY = 'category';
  readonly C_CLASS = 'class';
  readonly C_EUROCLASS = 'euroClass';
  readonly C_WORKSHOP = 'workshop';
  readonly C_CHASIS_NUMBER = 'chasisNumber';
  readonly C_TD = 'td';
  readonly C_WIFI = 'wifi';
  readonly C_WIFI_NAME = 'wifiName';
  readonly C_CONTRACTED_MAINTENANCE = 'contractedMaintenance';
  readonly C_SCHOOL_BUS = 'schoolBus';
  readonly C_SAFE_BELTS = 'SafeBelts';
  readonly C_INDIVIDUAL_ENTERTAIMENT = 'individualEntertaiment';
  readonly C_PLATFORM = 'platform';
  readonly C_PMRSR = 'pmrsr';
  readonly C_CAMERAS = 'cameras';
  readonly C_MANUFACTURING_MONTH = 'manufacturingMonth';
  readonly C_MANUFACTURING_YEAR = 'manufacturingYear';
  readonly C_CONCESSION = 'concession';
  readonly C_GOODS_TYPE_ID = 'goodsTypeId';
  readonly C_REGISTRATION_CLASSIFICATION = 'registrationClassification';
  readonly C_CLASSIFICATION_CODE_CL = 'classificationCodeCL';
  readonly C_FUNCTIONAL_TYPE_ID = 'functionalTypeId';
  readonly C_CIRCULATION_AREA_ID = 'circulationAreaId';
  readonly C_DATASOURCE_TYPE = 'dataSourceType';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, []),
      [this.C_BONUS_GROUP]: new FormControl(null, []),
      [this.C_OBSERVATIONS]: new FormControl(null, []),
      [this.C_CAR_NUMBER]: new FormControl(null, []),
      [this.C_PLATE_NUMBER]: new FormControl(null, []),
      [this.C_REGISTRATION_DATE]: new FormControl(null, []),
      [this.C_VEHICLE_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_VEHICLE_USAGE_ID]: new FormControl(null, [Validators.required]),
      [this.C_MOVEMENT_AREA]: new FormControl(null, [Validators.required]),
      [this.C_BRAND]: new FormControl(null, []),
      [this.C_MODEL]: new FormControl(null, []),
      [this.C_VERSION]: new FormControl(null, []),
      [this.C_MAX_ADDMITTED_WEIGHT]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_VALUE]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxDecimal)]),
      [this.C_VALUE_EXTRAS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxDecimal)]),
      [this.C_BUS_NUMBER]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_BODY_WORK_NUMBER]: new FormControl(null, []),
      [this.C_BODY_WORK]: new FormControl(null, []),
      [this.C_BUS_TYPE]: new FormControl(null, []),
      [this.C_ENTERTAIMENT_BUS]: new FormControl(false, []),
      [this.C_SEATS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt16)]),
      [this.C_SEAT_NUMBER]: new FormControl(null, []),
      [this.C_RENTING_COMPANY_FISCAL_CODE]: new FormControl(null, []),
      [this.C_RENTING_START_DATE]: new FormControl(null, []),
      [this.C_RENTING_EXPIRATION_DATE]: new FormControl(null, []),
      [this.C_POWER]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt16)]),
      [this.C_DIVISION]: new FormControl(null, []),
      [this.C_ZONE]: new FormControl(null, []),
      [this.C_CONTRACT]: new FormControl(null, []),
      [this.C_OWNER]: new FormControl(null, [Validators.required]),
      [this.C_LESSEE_ID]: new FormControl(null, []),
      [this.C_USER_ID]: new FormControl(null, []),
      [this.C_USER_ZONE]: new FormControl(null, []),
      [this.C_AGE]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_CONFIGURATION]: new FormControl(null, []),
      [this.C_LENGTH]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_KILOMETERS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_CATEGORY]: new FormControl(null, []),
      [this.C_CLASS]: new FormControl(null, []),
      [this.C_EUROCLASS]: new FormControl(null, []),
      [this.C_WORKSHOP]: new FormControl(null, []),
      [this.C_CHASIS_NUMBER]: new FormControl(null, []),
      [this.C_TD]: new FormControl(false, []),
      [this.C_WIFI]: new FormControl(false, []),
      [this.C_WIFI_NAME]: new FormControl(null, []),
      [this.C_CONTRACTED_MAINTENANCE]: new FormControl(false, []),
      [this.C_SCHOOL_BUS]: new FormControl(false, []),
      [this.C_SAFE_BELTS]: new FormControl(null, []),
      [this.C_INDIVIDUAL_ENTERTAIMENT]: new FormControl(false, []),
      [this.C_PLATFORM]: new FormControl(null, []),
      [this.C_PMRSR]: new FormControl(null, []),
      [this.C_CAMERAS]: new FormControl(null, []),
      [this.C_MANUFACTURING_MONTH]: new FormControl(null, []),
      [this.C_MANUFACTURING_YEAR]: new FormControl(null, []),
      [this.C_CONCESSION]: new FormControl(null, []),
      [this.C_GOODS_TYPE_ID]: new FormControl(null, []),
      [this.C_REGISTRATION_CLASSIFICATION]: new FormControl(null, []),
      [this.C_CLASSIFICATION_CODE_CL]: new FormControl(null, []),
      [this.C_FUNCTIONAL_TYPE_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.functionalTypes, 'functionalTypeId')]),
      [this.C_CIRCULATION_AREA_ID]: new FormControl(null, []),
      [this.C_DATASOURCE_TYPE]: new FormControl(null, [])
    });

    this.getControl(this.C_ID).disable();
    this.getControl(this.C_PLATE_NUMBER).disable();
  }

  get claims(): Array<IDetailedClaim> {
    return this._claims;
  }

  set claims(value: Array<IDetailedClaim>) {
    this._claims = value;
  }

  get fleetCompanies(): Array<IFleetCompany> {
    return this._fleetCompanies;
  }

  set fleetCompanies(fleetCompanies: Array<IFleetCompany>) {
    this._fleetCompanies = fleetCompanies;
  }

  get vehicleTypes(): Array<IMasterValue> {
    return this._vehicleTypes;
  }

  set vehicleTypes(vehicleTypes: Array<IMasterValue>) {
    this._vehicleTypes = vehicleTypes;
  }

  get vehicleUsages(): Array<IMasterValue> {
    return this._vehicleUsages;
  }

  set vehicleUsages(vehicleUsages: Array<IMasterValue>) {
    this._vehicleUsages = vehicleUsages;
  }

  get functionalTypes(): Array<IFunctionalType> {
    return this._functionalTypes;
  }

  set functionalTypes(functionalTypes: Array<IFunctionalType>) {
    this._functionalTypes = functionalTypes;
  }

  get circulationAreas(): Array<ICirculationArea> {
    return this._circulationAreas;
  }

  set circulationAreas(circulationAreas: Array<ICirculationArea>) {
    this._circulationAreas = circulationAreas;
  }

  get movementAreas(): Array<IMovementArea> {
    return this._movementAreas;
  }

  set movementAreas(movementAreas: Array<IMovementArea>) {
    this._movementAreas = movementAreas;
  }

  get goodsTypes(): Array<IGoodsType> {
    return this._goodsTypes;
  }

  set goodsTypes(goodsTypes: Array<IGoodsType>) {
    this._goodsTypes = goodsTypes;
  }

  get allowFleetTransfer(): boolean {
    return this._allowFleetTransfer;
  }

  set allowFleetTransfer(value: boolean) {
    this._allowFleetTransfer = value;
  }

  get allowPlateNumberChange(): boolean {
    return this._allowPlateNumberChange;
  }

  set allowPlateNumberChange(value: boolean) {
    this._allowPlateNumberChange = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this.form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get unitId(): string {
    return this.getControl(this.C_ID).value;
  }

  set unitId(value: string) {
    this.getControl(this.C_ID).setValue(value);
  }

  get bonusGroup(): string | null | undefined {
    return this.getControl(this.C_BONUS_GROUP).value;
  }

  set bonusGroup(value: string | null | undefined) {
    this.getControl(this.C_BONUS_GROUP).setValue(value);
  }

  get observations(): string | null | undefined {
    return this.getControl(this.C_OBSERVATIONS).value;
  }

  set observations(value: string | null | undefined) {
    this.getControl(this.C_OBSERVATIONS).setValue(value);
  }

  get carNumber(): string | null | undefined {
    return this.getControl(this.C_CAR_NUMBER).value;
  }

  set carNumber(value: string | null | undefined) {
    this.getControl(this.C_CAR_NUMBER).setValue(value);
  }

  get plateNumber(): string | null | undefined {
    return this.getControl(this.C_PLATE_NUMBER).value;
  }

  set plateNumber(value: string | null | undefined) {
    this.getControl(this.C_PLATE_NUMBER).setValue(value);
  }

  get registrationDate(): Date | null | undefined {
    return this.getControl(this.C_REGISTRATION_DATE).value;
  }

  set registrationDate(value: Date | null | undefined) {
    this.getControl(this.C_REGISTRATION_DATE).setValue(value);
  }

  get vehicleTypeId(): string | null | undefined {
    return this.getControl(this.C_VEHICLE_TYPE_ID).value;
  }

  set vehicleTypeId(value: string | null | undefined) {
    this.getControl(this.C_VEHICLE_TYPE_ID).setValue(value);
  }

  get vehicleUsageId(): string | null | undefined {
    return this.getControl(this.C_VEHICLE_USAGE_ID).value;
  }

  set vehicleUsageId(value: string | null | undefined) {
    this.getControl(this.C_VEHICLE_USAGE_ID).setValue(value);
  }

  get movementArea(): string | null | undefined {
    return this.getControl(this.C_MOVEMENT_AREA).value;
  }

  set movementArea(value: string | null | undefined) {
    this.getControl(this.C_MOVEMENT_AREA).setValue(value);
  }

  get brand(): string | null | undefined {
    return this.getControl(this.C_BRAND).value;
  }

  set brand(value: string | null | undefined) {
    this.getControl(this.C_BRAND).setValue(value);
  }

  get model(): string | null | undefined {
    return this.getControl(this.C_MODEL).value;
  }

  set model(value: string | null | undefined) {
    this.getControl(this.C_MODEL).setValue(value);
  }

  get version(): string | null | undefined {
    return this.getControl(this.C_VERSION).value;
  }

  set version(value: string | null | undefined) {
    this.getControl(this.C_VERSION).setValue(value);
  }

  get maxAdmittedWeight(): number | null | undefined {
    return this.getControl(this.C_MAX_ADDMITTED_WEIGHT).value;
  }

  set maxAdmittedWeight(value: number | null | undefined) {
    this.getControl(this.C_MAX_ADDMITTED_WEIGHT).setValue(value);
  }

  get value(): number | null | undefined {
    return this.getControl(this.C_VALUE).value;
  }

  set value(value: number | null | undefined) {
    this.getControl(this.C_VALUE).setValue(value);
  }

  get valueExtras(): number | null | undefined {
    return this.getControl(this.C_VALUE_EXTRAS).value;
  }

  set valueExtras(value: number | null | undefined) {
    this.getControl(this.C_VALUE_EXTRAS).setValue(value);
  }

  get busNumber(): number | null | undefined {
    return this.getControl(this.C_BUS_NUMBER).value;
  }

  set busNumber(value: number | null | undefined) {
    this.getControl(this.C_BUS_NUMBER).setValue(value);
  }

  get bodyworkNumber(): string | null | undefined {
    return this.getControl(this.C_BODY_WORK_NUMBER).value;
  }

  set bodyworkNumber(value: string | null | undefined) {
    this.getControl(this.C_BODY_WORK_NUMBER).setValue(value);
  }

  get bodywork(): string | null | undefined {
    return this.getControl(this.C_BODY_WORK).value;
  }

  set bodywork(value: string | null | undefined) {
    this.getControl(this.C_BODY_WORK).setValue(value);
  }

  get busType(): string | null | undefined {
    return this.getControl(this.C_BUS_TYPE).value;
  }

  set busType(value: string | null | undefined) {
    this.getControl(this.C_BUS_TYPE).setValue(value);
  }

  get entertaimentBus(): boolean | null | undefined {
    return this.getControl(this.C_ENTERTAIMENT_BUS).value;
  }

  set entertaimentBus(value: boolean | null | undefined) {
    this.getControl(this.C_ENTERTAIMENT_BUS).setValue(value);
  }

  get seats(): number | null | undefined {
    return this.getControl(this.C_SEATS).value;
  }

  set seats(value: number | null | undefined) {
    this.getControl(this.C_SEATS).setValue(value);
  }

  get seatNumber(): string | null | undefined {
    return this.getControl(this.C_SEAT_NUMBER).value;
  }

  set seatNumber(value: string | null | undefined) {
    this.getControl(this.C_SEAT_NUMBER).setValue(value);
  }

  get rentingCompanyFiscalCode(): string | null | undefined {
    return this.getControl(this.C_RENTING_COMPANY_FISCAL_CODE).value;
  }

  set rentingCompanyFiscalCode(value: string | null | undefined) {
    this.getControl(this.C_RENTING_COMPANY_FISCAL_CODE).setValue(value);
  }

  get rentingStartDate(): Date | null | undefined {
    return this.getControl(this.C_RENTING_START_DATE).value;
  }

  set rentingStartDate(value: Date | null | undefined) {
    this.getControl(this.C_RENTING_START_DATE).setValue(value);
  }

  get rentingExpirationDate(): Date | null | undefined {
    return this.getControl(this.C_RENTING_EXPIRATION_DATE).value;
  }

  set rentingExpirationDate(value: Date | null | undefined) {
    this.getControl(this.C_RENTING_EXPIRATION_DATE).setValue(value);
  }

  get power(): number | null | undefined {
    return this.getControl(this.C_POWER).value;
  }

  set power(value: number | null | undefined) {
    this.getControl(this.C_POWER).setValue(value);
  }

  get division(): string | null | undefined {
    return this.getControl(this.C_DIVISION).value;
  }

  set division(value: string | null | undefined) {
    this.getControl(this.C_DIVISION).setValue(value);
  }

  get zone(): string | null | undefined {
    return this.getControl(this.C_ZONE).value;
  }

  set zone(value: string | null | undefined) {
    this.getControl(this.C_ZONE).setValue(value);
  }

  get contract(): string | null | undefined {
    return this.getControl(this.C_CONTRACT).value;
  }

  set contract(value: string | null | undefined) {
    this.getControl(this.C_CONTRACT).setValue(value);
  }

  get owner(): string | null | undefined {
    return this.getControl(this.C_OWNER).value;
  }

  set owner(value: string | null | undefined) {
    this.getControl(this.C_OWNER).setValue(value);
  }

  get lesseeId(): string | null | undefined {
    return this.getControl(this.C_LESSEE_ID).value;
  }

  set lesseeId(value: string | null | undefined) {
    this.getControl(this.C_LESSEE_ID).setValue(value);
  }

  get userId(): string | null | undefined {
    return this.getControl(this.C_USER_ID).value;
  }

  set userId(value: string | null | undefined) {
    this.getControl(this.C_USER_ID).setValue(value);
  }

  get userZone(): string | null | undefined {
    return this.getControl(this.C_USER_ZONE).value;
  }

  set userZone(value: string | null | undefined) {
    this.getControl(this.C_USER_ZONE).setValue(value);
  }

  get age(): number | null | undefined {
    return this.getControl(this.C_AGE).value;
  }

  set age(value: number | null | undefined) {
    this.getControl(this.C_AGE).setValue(value);
  }

  get configuration(): string | null | undefined {
    return this.getControl(this.C_CONFIGURATION).value;
  }

  set configuration(value: string | null | undefined) {
    this.getControl(this.C_CONFIGURATION).setValue(value);
  }

  get length(): number | null | undefined {
    return this.getControl(this.C_LENGTH).value;
  }

  set length(value: number | null | undefined) {
    this.getControl(this.C_LENGTH).setValue(value);
  }

  get kilometers(): number | null | undefined {
    return this.getControl(this.C_KILOMETERS).value;
  }

  set kilometers(value: number | null | undefined) {
    this.getControl(this.C_KILOMETERS).setValue(value);
  }

  get category(): string | null | undefined {
    return this.getControl(this.C_CATEGORY).value;
  }

  set category(value: string | null | undefined) {
    this.getControl(this.C_CATEGORY).setValue(value);
  }

  get class(): string | null | undefined {
    return this.getControl(this.C_CLASS).value;
  }

  set class(value: string | null | undefined) {
    this.getControl(this.C_CLASS).setValue(value);
  }

  get euroClass(): string | null | undefined {
    return this.getControl(this.C_EUROCLASS).value;
  }

  set euroClass(value: string | null | undefined) {
    this.getControl(this.C_EUROCLASS).setValue(value);
  }

  get workshop(): string | null | undefined {
    return this.getControl(this.C_WORKSHOP).value;
  }

  set workshop(value: string | null | undefined) {
    this.getControl(this.C_WORKSHOP).setValue(value);
  }

  get chassisNumber(): string | null | undefined {
    return this.getControl(this.C_CHASIS_NUMBER).value;
  }

  set chassisNumber(value: string | null | undefined) {
    this.getControl(this.C_CHASIS_NUMBER).setValue(value);
  }

  get td(): boolean | null | undefined {
    return this.getControl(this.C_TD).value;
  }

  set td(value: boolean | null | undefined) {
    this.getControl(this.C_TD).setValue(value);
  }

  get wifi(): boolean | null | undefined {
    return this.getControl(this.C_WIFI).value;
  }

  set wifi(value: boolean | null | undefined) {
    this.getControl(this.C_WIFI).setValue(value);
  }

  get wifiName(): string | null | undefined {
    return this.getControl(this.C_WIFI_NAME).value;
  }

  set wifiName(value: string | null | undefined) {
    this.getControl(this.C_WIFI_NAME).setValue(value);
  }

  get contractedMaintenance(): boolean | null | undefined {
    return this.getControl(this.C_CONTRACTED_MAINTENANCE).value;
  }

  set contractedMaintenance(value: boolean | null | undefined) {
    this.getControl(this.C_CONTRACTED_MAINTENANCE).setValue(value);
  }

  get schoolBus(): boolean | null | undefined {
    return this.getControl(this.C_SCHOOL_BUS).value;
  }

  set schoolBus(value: boolean | null | undefined) {
    this.getControl(this.C_SCHOOL_BUS).setValue(value);
  }

  get safeBelts(): string | null | undefined {
    return this.getControl(this.C_SAFE_BELTS).value;
  }

  set safeBelts(value: string | null | undefined) {
    this.getControl(this.C_SAFE_BELTS).setValue(value);
  }

  get individualEntertaiment(): boolean | null | undefined {
    return this.getControl(this.C_INDIVIDUAL_ENTERTAIMENT).value;
  }

  set individualEntertaiment(value: boolean | null | undefined) {
    this.getControl(this.C_INDIVIDUAL_ENTERTAIMENT).setValue(value);
  }

  get platform(): string | null | undefined {
    return this.getControl(this.C_PLATFORM).value;
  }

  set platform(value: string | null | undefined) {
    this.getControl(this.C_PLATFORM).setValue(value);
  }

  get pmrsr(): string | null | undefined {
    return this.getControl(this.C_PMRSR).value;
  }

  set pmrsr(value: string | null | undefined) {
    this.getControl(this.C_PMRSR).setValue(value);
  }

  get cameras(): string | null | undefined {
    return this.getControl(this.C_CAMERAS).value;
  }

  set cameras(value: string | null | undefined) {
    this.getControl(this.C_CAMERAS).setValue(value);
  }

  get manufacturingMonth(): number | null | undefined {
    return this.getControl(this.C_MANUFACTURING_MONTH).value;
  }

  set manufacturingMonth(value: number | null | undefined) {
    this.getControl(this.C_MANUFACTURING_MONTH).setValue(value);
  }

  get manufacturingYear(): number | null | undefined {
    return this.getControl(this.C_MANUFACTURING_YEAR).value;
  }

  set manufacturingYear(value: number | null | undefined) {
    this.getControl(this.C_MANUFACTURING_YEAR).setValue(value);
  }

  get concession(): string | null | undefined {
    return this.getControl(this.C_CONCESSION).value;
  }

  set concession(value: string | null | undefined) {
    this.getControl(this.C_CONCESSION).setValue(value);
  }

  get goodsTypeId(): string | null | undefined {
    return this.getControl(this.C_GOODS_TYPE_ID).value;
  }

  set goodsTypeId(value: string | null | undefined) {
    this.getControl(this.C_GOODS_TYPE_ID).setValue(value);
  }

  get registrationClassification(): string | null | undefined {
    return this.getControl(this.C_REGISTRATION_CLASSIFICATION).value;
  }

  set registrationClassification(value: string | null | undefined) {
    this.getControl(this.C_REGISTRATION_CLASSIFICATION).setValue(value);
  }

  get classificationCodeCL(): string | null | undefined {
    return this.getControl(this.C_CLASSIFICATION_CODE_CL).value;
  }

  set classificationCodeCL(value: string | null | undefined) {
    this.getControl(this.C_CLASSIFICATION_CODE_CL).setValue(value);
  }

  get functionalTypeId(): string | null | undefined {
    return this.getControl(this.C_FUNCTIONAL_TYPE_ID).value;
  }

  set functionalTypeId(value: string | null | undefined) {
    this.getControl(this.C_FUNCTIONAL_TYPE_ID).setValue(value);
  }

  get circulationAreaId(): string | null | undefined {
    return this.getControl(this.C_CIRCULATION_AREA_ID).value;
  }

  set circulationAreaId(value: string | null | undefined) {
    this.getControl(this.C_CIRCULATION_AREA_ID).setValue(value);
  }
}
