import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DialogResultTypes,
  IDialogResult
} from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { MediationCodeStatus, ProductStatus } from 'src/app/core/enums';
import { IPolicyUpdate } from 'src/app/core/models/api/policies';
import { IProduct } from 'src/app/core/models/ida/products';
import { IMediationCode } from 'src/app/core/models/ida/providers';
import { ICurrencyService } from 'src/app/core/services/api/currencies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IInsuranceCompanyService } from 'src/app/core/services/api/insurance-companies';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { IProductService } from 'src/app/core/services/api/products';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { CreateEndorsementDialogComponent } from 'src/app/features/endorsements/create-endorsement-dialog/pages/create-endorsement-dialog.component';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyEditBonusTypeUpdateDialogComponent } from '../../../dialogs/policy-edit-bonus-type-update-dialog/pages/policy-edit-bonus-type-update-dialog.component';
import { PolicyEditCancelReplacementDialogComponent } from '../../../dialogs/policy-edit-cancel-replacement-dialog/pages/policy-edit-cancel-replacement-dialog.component';
import { PolicyEditCancellationDialogComponent } from '../../../dialogs/policy-edit-cancellation-dialog/pages/policy-edit-cancellation-dialog.component';
import { PolicyEditCertificateReplacementDialogComponent } from '../../../dialogs/policy-edit-certificate-replacement-dialog/pages/policy-edit-certificate-replacement-dialog.component';
import { PolicyEditChangeRiskDialogComponent } from '../../../dialogs/policy-edit-change-risk-dialog/pages/policy-edit-change-risk-dialog.component';
import { PolicyEditColectiveReplacementDialogComponent } from '../../../dialogs/policy-edit-colective-replacement-dialog/pages/policy-edit-colective-replacement-dialog.component';
import { PolicyEditContractDialogComponent } from '../../../dialogs/policy-edit-contract-dialog/pages/policy-edit-contract-dialog.component';
import { PolicyEditHolderUpdateDialogComponent } from '../../../dialogs/policy-edit-holder-update-dialog/pages/policy-edit-holder-update-dialog.component';
import { PolicyEditNumberUpdateDialogComponent } from '../../../dialogs/policy-edit-number-update-dialog/pages/policy-edit-number-update-dialog.component';
import { PolicyEditOfficeUpdateDialogComponent } from '../../../dialogs/policy-edit-office-update-dialog/pages/policy-edit-office-update-dialog.component';
import { PolicyEditMapper } from '../mappers';
import { PolicyEditViewModel } from '../models';

@Component({
  selector: 'howden-policy-edit',
  templateUrl: './policy-edit.component.html',
  styleUrls: ['./policy-edit.component.scss']
})
export class PolicyEditComponent implements OnInit, OnChanges, OnDestroy {
  model = new PolicyEditViewModel();

  get minDate(): Date { return Limits.minDate; }

  @Input() policyId = '';
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  private _hasBeenInitialized = false;
  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private uiBlockerSrv: IUIBlockerService,
    private currencySrv: ICurrencyService,
    private fleetSrv: IFleetService,
    private masterSrv: IMasterService,
    private policySrv: IPolicyService,
    private productSrv: IProductService,
    private insuranceCompanySrv: IInsuranceCompanyService
  ) {
  }

  ngOnInit(): void {
    this._hasBeenInitialized = true;
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.policyId) {
      if (!this._hasBeenInitialized) {
        this.performFullInitialization();
      } else {
        this.performPartialInitialization();
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave(): void {
    const request: IPolicyUpdate = PolicyEditMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.policySrv.update(this.model.policyId, request).pipe(
      tap({
        next: () => {
          const title: string = $localize`:@@app.policies.edit.save.confirm.title:Modificación de pólizas`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.emitUpdatedEvent());
        },
        complete: () => this.uiBlockerSrv.unblock()
      })
    ).subscribe();
  }

  onUpdatePolicyNumber(): void {
    const inputData = {
      id: this.model.policyId,
      insuranceCompanyId: this.model.insuranceCompanyId,
      policyNumber: this.model.policyNumber
    };
    const dialogRef = this.dialog.open(PolicyEditNumberUpdateDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.update.policy.number.confirm.title:Asignación de número de póliza`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onCreateEndorsement(): void {
    const inputData = { id: this.model.policyId };
    const dialogRef = this.dialog.open(CreateEndorsementDialogComponent, {
      width: '900px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.create.endorsement.confirm.title:Alta de suplemento`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onUpdateHolder(): void {
    const inputData = { id: this.model.policyId, holderId: this.model.holderId };
    const dialogRef = this.dialog.open(PolicyEditHolderUpdateDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.update.holder.confirm.title:Cambio de tomador`;
          const subtitle: string = $localize`:@@app.policies.edit.update.holder.confirm.subtitle:Finalizado correctamente`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onUpdateOffice(): void {
    const inputData = {
      id: this.model.policyId,
      insuranceCompanyId: this.model.insuranceCompanyId,
      policyNumber: this.model.policyNumber
    };
    const dialogRef = this.dialog.open(PolicyEditOfficeUpdateDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.update.office.confirm.title:Cambio de oficina`;
          const subtitle: string = $localize`:@@app.policies.edit.update.office.confirm.subtitle:Finalizado correctamente`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onReplacement(): void {
    const title: string = $localize`:@@app.policies.edit.replacement.confirm.title:Reemplazo/Traspaso`;
    const subtitle: string = $localize`:@@app.policies.edit.replacement.confirm.subtitle:Finalizado correctamente`;
    const dialogWidth: string = this.model.isColective ? '850px' : '700px';
    const dialogOptions = {
      width: dialogWidth,
      data: {
        id: this.model.policyId,
        insuranceCompanyId: this.model.insuranceCompanyId
      }
    };
    const dialogRef = this.model.isColective
      ? this.dialog.open(PolicyEditColectiveReplacementDialogComponent, dialogOptions)
      : this.dialog.open(PolicyEditCertificateReplacementDialogComponent, dialogOptions);

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onCancelReplacement(): void {
    const inputData = { id: this.model.policyId };
    const dialogRef = this.dialog.open(PolicyEditCancelReplacementDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.cancel.replacement.confirm.title:Anulación de reemplazo/traspaso`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onChangeRisk(): void {
    const inputData = { id: this.model.policyId };
    const dialogRef = this.dialog.open(PolicyEditChangeRiskDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.change.risk.confirm.title:Cambio de riesgo`;
          const subtitle: string = $localize`:@@app.policies.edit.change.risk.confirm.subtitle:Finalizado correctamente`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onCancel(): void {
    const inputData = { id: this.model.policyId, policyClass: this.model.policyClass };
    const dialogRef = this.dialog.open(PolicyEditCancellationDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.cancel.policy.confirm.title:Baja de póliza`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onReactivate(): void {
    const title: string = $localize`:@@app.policies.edit.policy.reactivate.title:Reactivación de pólizas`;
    const question: string = $localize`:@@app.policies.edit.policy.reactivate.subtitle:Se reactivará la póliza, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openHelp(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.policySrv.reactivate(this.model.policyId).pipe(
            tap({
              next: () => this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.emitUpdatedEvent()),
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onDelete(): void {
    const title: string = $localize`:@@app.policies.edit.policy.delete.title:Borrado de póliza`;
    const question: string = $localize`:@@app.policies.edit.policy.delete.subtitle:Se borrará la póliza, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.policySrv.delete(this.model.policyId).pipe(
            tap({
              next: () => this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.navigateToSearch()),
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onUpdateBonusType(): void {
    const inputData = { id: this.model.policyId };
    const dialogRef = this.dialog.open(PolicyEditBonusTypeUpdateDialogComponent, {
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          const title: string = $localize`:@@app.policies.edit.update.bonus.type.confirm.title:Cambio de tipo de prima`;
          const subtitle: string = $localize`:@@app.policies.edit.update.bonus.type.confirm.subtitle:Finalizado correctamente`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.emitUpdatedEvent());
        }
      })
    ).subscribe();
  }

  onNavigateToRootPolicy(): void {
    this.router.navigate(['policies/edit', this.model.rootPolicyId]);
  }

  onShowContract(): void {
    const dialogWidth = '650px';
    const dialogOptions = {
      width: dialogWidth,
      data: { id: this.model.policyId }
    };
    const dialogRef = this.dialog.open(PolicyEditContractDialogComponent, dialogOptions);

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }

  getCollectionManagerName(collectionManagerId: string): string {
    const collectionManager = this.model.collectionManagers?.find(x => x.collectionManagerId === collectionManagerId);
    return collectionManager ? `${collectionManager.alias}-${collectionManager.name}` : '';
  }

  getCurrencyDescription(currencyId: string): string {
    return this.model.currencies?.find(x => x.currencyId === currencyId)?.description ?? '';
  }

  getInsuranceCompanyName(insuranceCompanyId: string): string {
    const insuranceCompany = this.model.insuranceCompanies?.find(x => x.insuranceCompanyId === insuranceCompanyId);
    return insuranceCompany ? `${insuranceCompany.alias}-${insuranceCompany.name}` : '';
  }

  getIndustryName(industryId: string): string {
    const industry = this.model.industries?.find(x => x.industryId === industryId);
    return industry ? `${industry.alias}-${industry.name}` : '';
  }

  getProductName(productId: string): string {
    const product = this.model.products?.find(x => x.productId === productId);
    return product ? `${product.alias}-${product.name}` : '';
  }

  getMediationCodeDescription(mediationCodeId: string): string {
    const mediationCode = this.model.mediationCodes?.find(x => x.mediationCodeId === mediationCodeId);
    return mediationCode ? `${mediationCode.code}-${mediationCode.description}` : '';
  }

  private onChanges(): void {
    const firstEffectDateControl = this.model.getControl(this.model.C_FIRST_EFFECT_DATE);
    const insuranceCompanyControl = this.model.getControl(this.model.C_INSURANCE_COMPANY_ID);
    const industryControl = this.model.getControl(this.model.C_INDUSTRY_ID);
    const billingModelControl = this.model.getControl(this.model.C_BILLING_MODEL_ID);

    this._subscriptions.sink = firstEffectDateControl.valueChanges.subscribe(val => this.model.effectDate = val);
    this._subscriptions.sink = insuranceCompanyControl.valueChanges.subscribe(() => this.onInsuranceCompanyChanged());
    this._subscriptions.sink = industryControl.valueChanges.subscribe(() => this.onIndustryChanged());
    this._subscriptions.sink = billingModelControl.valueChanges.subscribe(() => this.onBillingModelChanged());

    this.model.getControl(this.model.C_RECEIPTS_ON).removeValidators([Validators.required]);
    if (this.model.isRoot) {
      this.model.getControl(this.model.C_RECEIPTS_ON).setValidators([Validators.required]);
    }
    this.model.getControl(this.model.C_RECEIPTS_ON).updateValueAndValidity();
  }

  private onInsuranceCompanyChanged(): void {
    this.onLoadProducts();
    this.onLoadMediationCodes();
  }

  private onIndustryChanged(): void {
    this.onLoadProducts();
  }

  private onBillingModelChanged(): void {
    if (this.model.isRegularized) {
      this.model.getControl(this.model.C_REGULARIZATION_PERIOD_ID).enable();
      this.model.getControl(this.model.C_DOUBLE_BILLING).enable();
    } else {
      this.model.regularizationPeriodId = undefined;
      this.model.doubleBilling = false;
      this.model.getControl(this.model.C_REGULARIZATION_PERIOD_ID).disable();
      this.model.getControl(this.model.C_DOUBLE_BILLING).disable();
    }
  }

  private onLoadProducts(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;
    const industryId: string = this.model.industryId;

    this.model.productId = '';
    this.model.products = new Array<IProduct>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);
    const industry = this.model.industries.find(x => x.industryId === industryId);

    if (insuranceCompany && industry) {
      this.productSrv.getProducts(insuranceCompanyId, industryId).subscribe((data: Array<IProduct>) => {
        this.model.products = data.filter(x => x.status === ProductStatus.Active);
      });
    }
  }

  private onLoadMediationCodes(): void {
    const insuranceCompanyId: string = this.model.insuranceCompanyId;

    this.model.mediationCodeId = '';
    this.model.mediationCodes = new Array<IMediationCode>();

    const insuranceCompany = this.model.insuranceCompanies.find(x => x.insuranceCompanyId === insuranceCompanyId);

    if (insuranceCompany) {
      this.insuranceCompanySrv.getMediationCodes(insuranceCompanyId).subscribe((data: Array<IMediationCode>) => {
        this.model.mediationCodes = data.filter(x => x.status === MediationCodeStatus.Active);
        const defaultMediationCode = this.model.mediationCodes?.find(x => x.isDefault === true);
        if (defaultMediationCode) {
          this.model.mediationCodeId = defaultMediationCode.mediationCodeId;
        }
      });
    }
  }

  private navigateToSearch(): void {
    this.router.navigate(['policies/search']);
  }

  private emitUpdatedEvent(): void {
    this.updated.emit(null);
    this.performPartialInitialization();
  }

  private performFullInitialization(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.policySrv.get(this.policyId),
      this.masterSrv.getCollectionManagers(),
      this.masterSrv.getPaymentTypes(),
      this.masterSrv.getPolicyDurations(),
      this.policySrv.getBonusTypes(),
      this.policySrv.getRootPolicyClasses(),
      this.policySrv.getBillingModels(),
      this.policySrv.getReceiptTargets(),
      this.currencySrv.getCurrencies()
    ]).pipe(
      tap(([
        policy,
        collectionManagers,
        paymentTypes,
        policyDurations,
        bonusTypes,
        policyClasses,
        billingModels,
        receiptTargets,
        currencies
      ]) => {
        forkJoin([
          this.fleetSrv.getInsuranceCompanies(policy.fleetId),
          this.fleetSrv.getIndustries(policy.fleetId),
          this.fleetSrv.getCompanies(policy.fleetId),
          this.insuranceCompanySrv.getMediationCodes(policy.insuranceCompanyId),
          this.productSrv.getProducts(policy.insuranceCompanyId, policy.industryId)
        ]).subscribe(([
          insuranceCompanies,
          industries,
          fleetCompanies,
          mediationCodes,
          products
        ]) => {
          this.model.policyId = this.policyId;
          this.model.insuranceCompanies = insuranceCompanies;
          this.model.industries = industries;
          this.model.fleetCompanies = fleetCompanies;
          this.model.collectionManagers = collectionManagers;
          this.model.paymentTypes = paymentTypes;
          this.model.regularizationPeriods = paymentTypes;
          this.model.policyDurations = policyDurations;
          this.model.bonusTypes = bonusTypes;
          this.model.policyClasses = policyClasses;
          this.model.receiptTargets = receiptTargets;
          this.model.billingModels = billingModels;
          this.model.currencies = currencies;
          this.model.mediationCodes = mediationCodes;
          this.model.products = products;

          PolicyEditMapper.mapForEdit(policy, this.model);

          this.onChanges();
          this.onBillingModelChanged();

          this.uiBlockerSrv.unblock();
        });
      })
    ).subscribe();
  }

  private performPartialInitialization(): void {
    this.uiBlockerSrv.block();

    this.unsubscribe();
    this.model.reset();

    forkJoin([
      this.policySrv.get(this.policyId)
    ]).pipe(
      tap(([policy]) => {
        forkJoin([
          this.fleetSrv.getInsuranceCompanies(policy.fleetId),
          this.fleetSrv.getIndustries(policy.fleetId),
          this.fleetSrv.getCompanies(policy.fleetId),
          this.insuranceCompanySrv.getMediationCodes(policy.insuranceCompanyId),
          this.productSrv.getProducts(policy.insuranceCompanyId, policy.industryId)
        ]).subscribe(([
          insuranceCompanies,
          industries,
          fleetCompanies,
          mediationCodes,
          products
        ]) => {
          this.model.policyId = this.policyId;
          this.model.insuranceCompanies = insuranceCompanies;
          this.model.industries = industries;
          this.model.fleetCompanies = fleetCompanies;
          this.model.mediationCodes = mediationCodes;
          this.model.products = products;

          PolicyEditMapper.mapForEdit(policy, this.model);

          this.onChanges();
          this.onBillingModelChanged();

          this.uiBlockerSrv.unblock();
        });
      })
    ).subscribe();
  }

  private unsubscribe(): void {
    this._subscriptions.unsubscribe();
  }
}
