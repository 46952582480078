import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import {
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { BookingModeService, IBookingModeService } from 'src/app/core/services/api/booking-modes';
import { CirculationAreaService, ICirculationAreaService } from 'src/app/core/services/api/circulation-area';
import { FleetService, IFleetService } from 'src/app/core/services/api/fleets';
import { FunctionalTypeService, IFunctionalTypeService } from 'src/app/core/services/api/functional-type';
import { IUnitService, UnitService } from 'src/app/core/services/api/units';
import { ISessionService, SessionService } from 'src/app/core/services/storage/session';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { ClaimsModule } from '../claims/claims.module';
import { UpsertCirculationAreaDialogComponent } from './circulation-areas/dialogs/upsert-functional-type-dialog/pages/upsert-circulation-area-dialog.component';
import { CirculationAreaSearchComponent } from './circulation-areas/pages/circulation-area-search.component';
import { UpsertFunctionalTypeDialogComponent } from './functional-types/dialogs/upsert-functional-type-dialog/pages/upsert-functional-type-dialog.component';
import { FunctionalTypeSearchComponent } from './functional-types/pages/functional-type-search.component';
import { UnitCancelRisksDialogComponent } from './unit-cancel-risks-dialog/pages/unit-cancel-risks-dialog.component';
import { UnitCreateNewComponent } from './unit-create-new/pages/unit-create-new.component';
import { UnitEditPoliciesComponent } from './unit-edit/components/unit-edit-policies/pages/unit-edit-policies.component';
import { UnitEditComponent } from './unit-edit/pages/unit-edit.component';
import { UnitFleetTransferDialogComponent } from './unit-fleet-transfer-dialog/pages/unit-fleet-transfer-dialog.component';
import { UnitLoaderComponent } from './unit-loader/pages/unit-loader.component';
import { UnitPlateNumberChangeDialogComponent } from './unit-plate-number-change-dialog/pages/unit-plate-number-change-dialog.component';
import { UnitSearchComponent } from './unit-search/pages/unit-search.component';
import { UnitSummaryDialogComponent } from './unit-summary-dialog/pages/unit-summary-dialog.component';
import { UnitUpdaterComponent } from './unit-updater/pages/unit-updater.component';
import { UnitsRoutingModule } from './units-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatStepperModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    HowdenAutocompleteModule,
    HowdenFileUploadModule,
    ClaimsModule,
    UnitsRoutingModule
  ],
  declarations: [
    FunctionalTypeSearchComponent,
    UpsertFunctionalTypeDialogComponent,
    CirculationAreaSearchComponent,
    UpsertCirculationAreaDialogComponent,
    UnitSearchComponent,
    UnitEditComponent,
    UnitPlateNumberChangeDialogComponent,
    UnitFleetTransferDialogComponent,
    UnitCancelRisksDialogComponent,
    UnitSummaryDialogComponent,
    UnitCreateNewComponent,
    UnitLoaderComponent,
    UnitUpdaterComponent,
    UnitEditPoliciesComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: ISessionService, useClass: SessionService },
    { provide: IFleetService, useClass: FleetService },
    { provide: IBookingModeService, useClass: BookingModeService },
    { provide: IUnitService, useClass: UnitService },
    { provide: IFunctionalTypeService, useClass: FunctionalTypeService },
    { provide: ICirculationAreaService, useClass: CirculationAreaService }
  ]
})
export class UnitsModule {
}
