import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { Limits } from 'src/app/shared/utils';

export class FleetEditTaxViewModel {
  private _form: FormGroup;
  private _paymentTypes: Array<IMasterValue> = [];
  private _valueTypes: Array<IKeyValuePair<string, string>> = [
    { key: '0', value: $localize`:@@app.fleets.edit.taxes.value.types.percentage:Porcentaje` },
    { key: '1', value: $localize`:@@app.fleets.edit.taxes.value.types.amount:Importe` }
  ];

  readonly C_NAME = 'name';
  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';
  readonly C_PAYMENT_TYPE_ID = 'paymentTypeId';
  readonly C_VALUE_TYPE = 'valueType';
  readonly C_VALUE = 'value';
  readonly C_RCSO = 'rcso';
  readonly C_RC = 'rc';
  readonly C_SOVI = 'sovi';

  constructor() {
    this._form = new FormGroup({
      [this.C_NAME]: new FormControl(null, [Validators.required]),
      [this.C_START_DATE]: new FormControl(null, []),
      [this.C_END_DATE]: new FormControl(null, []),
      [this.C_PAYMENT_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_VALUE_TYPE]: new FormControl(null, [Validators.required]),
      [this.C_VALUE]: new FormControl(null, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RCSO]: new FormControl(false, []),
      [this.C_RC]: new FormControl(false, []),
      [this.C_SOVI]: new FormControl(false, [])
    });
  }

  get valueTypes(): Array<IKeyValuePair<string, string>> {
    return this._valueTypes;
  }

  get paymentTypes(): Array<IMasterValue> {
    return this._paymentTypes;
  }

  set paymentTypes(value: Array<IMasterValue>) {
    this._paymentTypes = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get name(): string | null {
    return this.getControl(this.C_NAME).value;
  }

  set name(value: string | null) {
    this.getControl(this.C_NAME).setValue(value);
  }

  get startDate(): Date | null {
    return this.getControl(this.C_START_DATE).value;
  }

  set startDate(value: Date | null) {
    this.getControl(this.C_START_DATE).setValue(value);
  }

  get endDate(): Date | null {
    return this.getControl(this.C_END_DATE).value;
  }

  set endDate(value: Date | null) {
    this.getControl(this.C_END_DATE).setValue(value);
  }

  get paymentTypeId(): string | null {
    return this.getControl(this.C_PAYMENT_TYPE_ID).value;
  }

  set paymentTypeId(value: string | null) {
    this.getControl(this.C_PAYMENT_TYPE_ID).setValue(value);
  }

  get valueType(): string | null {
    return this.getControl(this.C_VALUE_TYPE).value;
  }

  set valueType(value: string | null) {
    this.getControl(this.C_VALUE_TYPE).setValue(value);
  }

  get value(): number | null {
    return this.getControl(this.C_VALUE).value;
  }

  set value(value: number | null) {
    this.getControl(this.C_VALUE).setValue(value);
  }

  get rcso(): boolean {
    return this.getControl(this.C_RCSO).value;
  }

  set rcso(value: boolean) {
    this.getControl(this.C_RCSO).setValue(value);
  }

  get rc(): boolean {
    return this.getControl(this.C_RC).value;
  }

  set rc(value: boolean) {
    this.getControl(this.C_RC).setValue(value);
  }

  get sovi(): boolean {
    return this.getControl(this.C_SOVI).value;
  }

  set sovi(value: boolean) {
    this.getControl(this.C_SOVI).setValue(value);
  }
}
