<div class="flex f-fd--column">

  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">

    <div class="flex margin-bottom-25">

      <button type="button" class="f-basis--4" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onGenerateReport()">
        <span i18n="@@app.core.actions.generateReport">Generar informe</span>
      </button>

    </div>

    <div class="flex f-gap--10px">

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.reports.bonus.history.query.label">Filtro</mat-label>
        <input matInput [formControlName]="model.C_QUERY" />
      </mat-form-field>

      <mat-form-field class="f-basis--4">

        <mat-label i18n="@@app.reports.bonus.history.label">Fecha efecto</mat-label>
        <input matInput [matDatepicker]="effectDatePicker" [formControlName]="model.C_EFFECT_DATE"
          [howdenControlError]="effectDateError" [min]="minDate" />
        <mat-error #effectDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="effectDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #effectDatePicker></mat-datepicker>

      </mat-form-field>

      <mat-form-field class="f-basis--4">

        <mat-label i18n="@@app.reports.bonus.history.expirationDate.label">Fecha vencimiento</mat-label>
        <input matInput [matDatepicker]="expirationDatePicker" [formControlName]="model.C_EXPIRATION_DATE"
          [howdenControlError]="expirationDateError" [min]="minDate" />
        <mat-error #expirationDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expirationDatePicker></mat-datepicker>

      </mat-form-field>

    </div>

    <div class="flex f-gap--10px">

      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.bonus.history.insuranceCompanyId.label"
        label="Compañía"
        displayField="name"
        keyField="insuranceCompanyId"
        [selectableItems]="model.insuranceCompanies"
        [formControlName]="model.C_INSURANCE_COMPANY_ID"
      >
      </howden-autocomplete>

      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.bonus.history.holderId.label"
        label="Tomador"
        displayField="clientName"
        keyField="companyId"
        [selectableItems]="model.fleetCompanies"
        [formControlName]="model.C_HOLDER_ID"
      >
      </howden-autocomplete>

      <howden-autocomplete
        class="f-basis--4"
        i18n-label="@@app.reports.bonus.history.insuredId.label"
        label="Asegurado"
        displayField="clientName"
        keyField="companyId"
        [selectableItems]="model.fleetCompanies"
        [formControlName]="model.C_INSURED_ID"
      >
      </howden-autocomplete>

    </div>

  </form>

</div>
