import { IRegularizationReportRequest } from 'src/app/core/models/api/reports';
import { RegularizationReportViewModel } from '../models';

export abstract class RegularizationReportMapper {
  static mapForGeneration(activeFleet: string, source: RegularizationReportViewModel): IRegularizationReportRequest {
    return {
      fleetId: activeFleet,
      startDate: source.startDate,
      endDate: source.endDate,
      rootPolicies: source.rootPolicies.filter(x => x.selected === true).map(x => x.id),
      vehicleUsages: source.vehicleUsages.filter(x => x.selected === true).map(x => x.id),
      vehicleTypes: source.vehicleTypes.filter(x => x.selected === true).map(x => x.id)
    } as IRegularizationReportRequest;
  }
}
