@if (hasWaitingProcesses === true) {
  <div class="flex margin-top-10 margin-bottom-10">
    <mat-card appearance="outlined" i18n="@@app.policies.edit.header.has.waiting.processes.alert">
      <mat-card-content>Hay procesos de de actualización pendientes para la póliza actual</mat-card-content>
    </mat-card>
  </div>
}

<div class="flex f-ai--center f-jc--spacearound f-gap--10px margin-bottom-10">
  <howden-label-value
    labelText="Nº. Póliza"
    i18n-labelText="@@app.policies.edit.header.policyNumber.label"
    value="{{policySummary?.policyNumber}}"
  ></howden-label-value>
  <mat-divider [vertical]="true"></mat-divider>

  <howden-label-value
    class="ellipsis-text"
    labelText="Tomador"
    i18n-labelText="@@app.policies.edit.header.holder.label"
    value="{{policySummary?.holder}}"
  ></howden-label-value>

  <howden-label-value
    labelText="Oficina"
    i18n-labelText="@@app.policies.edit.header.office.label"
    value="{{policySummary?.office}}"
  ></howden-label-value>

  @if (policySummary?.replacesPolicyId) {
    <div class="label-value-container">
      <div>
        <label i18n="@@app.policies.edit.header.replacesPolicy.label">Reemplaza a</label>
      </div>
      <div>
        <a [routerLink]="['/', 'policies', 'edit', policySummary?.replacesPolicyId]">
          {{ policySummary?.replacesPolicy }}
        </a>
      </div>
    </div>

    <mat-divider [vertical]="true"></mat-divider>
  }

  @if (policySummary?.replacedByPolicyId) {
    <div class="label-value-container">
      <div>
        <label i18n="@@app.policies.edit.replacedByPolicy.label">Reemplazada por</label>
      </div>
      <div>
        <a [routerLink]="['/', 'policies', 'edit', policySummary?.replacedByPolicyId]">
          {{ policySummary?.replacedByPolicy }}
        </a>
      </div>
    </div>
  }

  <howden-label-value
    labelText="Tipo póliza"
    i18n-labelText="@@app.policies.edit.header.policyType.label"
    value="{{policySummary?.policyClass}}"
  ></howden-label-value>

  <howden-label-value
    labelText="Tipo prima"
    i18n-labelText="@@app.policies.edit.header.policyBonusType.label"
    value="{{policySummary?.policyBonusType}}"
  ></howden-label-value>

  <div>
    <howden-label-value
      labelText="Estado"
      i18n-labelText="@@app.policies.edit.header.status.label"
      value="{{policySummary?.policyStatus}} {{policySummary?.cancellationDate | date}}"
    ></howden-label-value>

    <small>{{policySummary?.cancellationReason}}</small>
  </div>

  <button
    type="button"
    mat-icon-button
    color="accent"
    i18n-matTooltip="@@app.policies.edit.header.policy.info.tooltip"
    matTooltip="Información póliza"
    i18n-aria-label="@@app.policies.edit.header.policy.info.label"
    aria-label="Información póliza"
    (click)="onShowInfo()"
  >
    <mat-icon>info</mat-icon>
  </button>
</div>
